import { MooGraphQLList } from "../moo-graphql-list";
import { QuerySlice } from "../moo-hooks";
import { Permission, usePermissions } from "../moo-me-provider";
import { useListGraphQLVariables } from "../moo-shared";
import { Query } from "./@types";
import { mapData } from "./mapData";
import { useCells } from "./useCells";
import { useGraphQLQuery } from "./useGraphQLQuery";
import { useHeaders } from "./useHeaders";
import { useWhere } from "./useWhere";

export const rowsPerPage = 10;

type RoleListProps = {
	store: QuerySlice<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const RoleList = ({ store, onAdd, onRowClick }: RoleListProps) => {
	const permissions = usePermissions();
	const { query, setQuery, clear } = store;
	const cells = useCells();
	const headers = useHeaders();
	const graphQLQuery = useGraphQLQuery();
	const where = useWhere({ query });
	const graphQLVariables = useListGraphQLVariables(rowsPerPage, query.page, where);

	return (
		<MooGraphQLList
			showAddButton={permissions.includes(Permission.RoleAdd)}
			filterNode={null}
			clear={clear}
			query={query}
			setQuery={setQuery}
			onRowClick={onRowClick}
			addLabel="新增角色"
			onAdd={onAdd}
			headers={headers}
			cells={cells}
			rowsPerPage={rowsPerPage}
			operationName=""
			mapData={mapData}
			graphQLQuery={graphQLQuery}
			variables={graphQLVariables}
		/>
	);
};
