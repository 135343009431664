import { MooGqlTable } from "../../moo-gql";
import { Order } from "../findOne/Order";
import { queryField } from "../findOne/query";

export class OrderTable extends MooGqlTable<Order> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "orders";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "state":
			case "state_manager":
			case "state_accountant":
			case "state_warehouse":
			case "order_date":
			case "order_note":
			case "customer_id":
			case "sales_id":
			case "assistant_id":
			case "deliver_date":
			case "is_include_tax":
			case "quotation_id":
			case "type_id":
			case "payee_id":
			case "deliver_address":
			case "note":
			case "memo":
			case "description":
			case "tax_price":
			case "total_price":
			case "received":
			case "handling_fee":
			case "freight":
			case "miscellaneous_charge":
			case "commission_refund":
				return true;
			default:
				return false;
		}
	}
}
