import { FC } from "react";
import type { FieldType as ItemSpecCustomRowFieldType } from "../moo-item-spec-and-custom-row";
import { MooItemSpecAndCustomRow } from "../moo-item-spec-and-custom-row";
import { ItemBound, ItemSpecOrCustomBound } from "../moo-types";

export type MooItemRowListProps = {
	errors?: string | undefined | (string | undefined)[];
	emptyMessage?: string;
	items: ItemBound[];
	price_total?: number;
	onCountChange?: (id: string, count: number) => void;
	onNoteChange?: (id: string, note: string) => void;
	onRemove?: (id: string) => void;
	onReturnCountChange?: (id: string, returnCount: number) => void;
	onWarehouseIdChange?: (id: string, warehouseId: string) => void;
	onTransferCountChange?: (id: ItemSpecOrCustomBound["id"], value: ItemSpecOrCustomBound["transfer_count"]) => void;
	onPriceFinalChange?: (id: ItemSpecOrCustomBound["id"], value: ItemSpecOrCustomBound["price_final"]) => void;
	specCustomFields?: Set<ItemSpecCustomRowFieldType>;
	disabledSpecCustomFields?: Set<ItemSpecCustomRowFieldType>;
};

export const MooItemRowList: FC<MooItemRowListProps> = ({
	errors = undefined,
	items,
	onNoteChange,
	onCountChange,
	onRemove,
	onReturnCountChange,
	onWarehouseIdChange,
	onTransferCountChange,
	onPriceFinalChange,
	specCustomFields = new Set(),
	disabledSpecCustomFields = new Set(),
}) => {
	if (typeof errors === "string" && errors !== "") {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					padding: "1.5rem 0 0 0",
					color: "#C4C4C4",
				}}
			>
				{errors}
			</div>
		);
	} else {
		return (
			<>
				{items.map((item, index) => {
					const key = `${item.type}_${item.id}`;
					let error = {};
					if (errors) {
						// @ts-ignore
						error = errors[index];
					}
					return (
						<MooItemSpecAndCustomRow
							key={key}
							{...item}
							index={index}
							count={item.count}
							error={error}
							note={item.note}
							onCountChange={onCountChange}
							onNoteChange={onNoteChange}
							onReturnCountChange={onReturnCountChange}
							onWarehouseIdChange={onWarehouseIdChange}
							onTransferCountChange={onTransferCountChange}
							onPriceFinalChange={onPriceFinalChange}
							onRemove={onRemove}
							fields={specCustomFields}
							disabledFields={disabledSpecCustomFields}
						/>
					);
				})}
			</>
		);
	}
};

export default MooItemRowList;
