import { useEffect, useState } from "react";
import { useError, useSuccess } from "../moo-notification-provider";
import { getLocaleString } from "../moo-shared";
import { Form, FormData, MapperInitialValues, ModelBase, OnSubmitType } from "./moo-update-form-new";

type Props<T extends ModelBase, U extends FormData> = {
	id: string;
	initialValues: U;
	isLoading: boolean;
	onCancel: () => void;
	record: ModelBase;
	Form: Form<U>;
	onSubmit: OnSubmitType<T, U>;
	mapperInitialValues: MapperInitialValues<T, U>;
};

export function Content<T extends ModelBase, U extends FormData>({
	initialValues: propsInitialValues,
	id,
	isLoading,
	onCancel,
	Form,
	onSubmit,
	mapperInitialValues,
}: Props<T, U>) {
	const success = useSuccess();
	const error = useError();
	const [initialValues, setInitialValues] = useState<U>(propsInitialValues);

	useEffect(() => {
		setInitialValues(propsInitialValues);
	}, [id, propsInitialValues.update_date]);

	return (
		<Form
			id={id}
			isLoading={isLoading}
			isEdit
			onCancel={onCancel}
			initialValues={initialValues}
			onSubmit={async (updatedOne, helpers) => {
				try {
					helpers.setSubmitting(true);
					const { affected_rows, returning } = await onSubmit(initialValues, {
						...updatedOne,
						update_date: getLocaleString(new Date()),
					});
					if (affected_rows === 0) {
						error("更新失敗，已有更新的資料，請重新整理頁面。");
					} else {
						// @ts-ignore
						const returned = returning[0];
						const newInitialValues = mapperInitialValues(returned);
						setInitialValues(newInitialValues);

						helpers.setValues(newInitialValues);
						success("儲存成功。");
					}
				} catch (e) {
					console.error(e);
					// @ts-ignore
					e("遇到錯誤，請聯絡管理員");
				} finally {
					helpers.setSubmitting(false);
				}
			}}
			isCancelDisabled={false}
			isOkDisabled={false}
		/>
	);
}
