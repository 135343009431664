import { Status } from "../../moo-enums";
import { One } from "../../moo-return-goods-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";
import { mapItems } from "./mapItems";

export function mapperInitialValues(record: Row): One {
	const items = mapItems(record);

	return {
		status: Status.Present,
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		state_warehouse: record.state_warehouse,
		state_accountant: record.state_accountant,
		return_date: record.return_date,
		order_id: record.order_id,
		type_id: record.type_id,
		type_name: record.quotation_type.name,
		customer_id: record.customer_id,
		customer_name: record.customer.name,
		sales_id: record.sales_id,
		sales_name: record.sales.name,
		sales_tel: record.sales.tel,
		deliver_date: record.deliver_date,
		is_include_tax: record.is_include_tax,
		payee_id: record.payee_id,
		payee_name: record.payee.name,
		payee_bank: record.payee.bank_name,
		payee_account_number: record.payee.account_number,
		deliver_address: record.deliver_address,
		description: record.description,
		order_note: record.order_note,
		tax_price: record.tax_price,
		total_price: record.total_price,
		received: record.received,
		receivables: record.receivables,
		handling_fee: record.handling_fee,
		freight: record.freight,
		miscellaneous_charge: record.miscellaneous_charge,
		commission_refund: record.commission_refund,
		note: record.note,
		memo: record.memo,
		items,
		voucher_rows: record.voucher_rows,
	};
}
