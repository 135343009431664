import { KodenBinIcon } from "@yachelee/koden-components/koden-bin-icon";
import type { CellType } from "@yachelee/koden-components/koden-table";
import { useOnArchived } from "../../moo-archive-provider";
import { QuerySlice, useArchiveResource } from "../../moo-hooks";
import { Query, Row } from "../@types";

export function useCells(resource: string, store: QuerySlice<Query>): CellType[] {
	const archiveResource = useArchiveResource(resource);
	const onArchived = useOnArchived();

	return [
		{
			id: "number",
			align: "left",
			component: ({ number }: Row) => {
				return <div>{number}</div>;
			},
		},
		{
			id: "type",
			align: "left",
			component: ({ type }: Row) => {
				return <div>{type}</div>;
			},
		},
		{
			id: "name",
			align: "left",
			component: ({ name }: Row) => {
				return <div>{name}</div>;
			},
		},
		{
			id: "tel",
			align: "left",
			component: ({ tel }: Row) => {
				return <div>{tel}</div>;
			},
		},
		{
			id: "email",
			align: "left",
			component: ({ email }: Row) => {
				return <div>{email}</div>;
			},
		},
		{
			id: "address",
			align: "left",
			component: ({ address }: Row) => {
				return <div>{address}</div>;
			},
		},
		{
			id: "tax",
			align: "left",
			component: ({ tax }: Row) => {
				return <div>{tax}</div>;
			},
		},
		{
			id: "delete",
			align: "center",
			component: ({ id, name }: Row) => {
				return (
					<KodenBinIcon
						id={`DELETE_${id}`}
						disabled={false}
						onClick={async (e) => {
							e.stopPropagation();
							if (await archiveResource({ id, name })) {
								store.setQuery({ page: 0 });
								onArchived(resource);
							}
						}}
					/>
				);
			},
		},
	];
}
