import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "進貨單號",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "sales_name",
			label: "業務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "manufacturer",
			label: "廠商",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "import_date",
			label: "進貨日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state",
			label: "狀態",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "state_accountant",
			label: "會計狀態",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "name",
			label: "收貨倉庫",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "link_id",
			label: "連結訂貨單/國外採購單",
			align: "left",
			style: {
				minWidth: 125,
			},
		},
	];
}
