import { useBranch } from "../../moo-branch-provider";
import { useListGraphQLVariables } from "../../moo-shared";
import { rowsPerPage } from "../ImportGoodsList";
import { useQuery } from "./useQuery";

function useConditions() {
	const { value: branch } = useBranch();
	const { search, manufacturer, accountant_state } = useQuery();

	const conditions: Record<string, any> = {
		branch_id: {
			_eq: branch,
		},
	};

	if (manufacturer !== null) {
		// @ts-ignore
		conditions.manufacturer = {
			id: {
				_eq: manufacturer,
			},
		};
	}

	if (accountant_state !== null) {
		// @ts-ignore
		conditions.state_accountant = {
			_eq: accountant_state,
		};
	}

	if (search !== null && search.trim() !== "") {
		const _similar = `%(${search.trim().split(" ").join("|")})%`;
		conditions._or = [
			{
				id: {
					_similar,
				},
			},
		];
	}

	return conditions;
}

export function useGraphQLVariables() {
	const { page } = useQuery();
	const conditions = useConditions();
	return useListGraphQLVariables(rowsPerPage, page, conditions);
}
