import { object, SchemaOf } from "yup";
import { returnGoods, Status } from "../../moo-enums";
import {
	arrayOptionalSchema,
	booleanOptional,
	enumRequired,
	numberOptional,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { itemsSchemas } from "./itemSchemas";
import { voucherRowSchema } from "./voucherRowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: enumRequired(Status),
	state_warehouse: enumRequired(returnGoods.StateWarehouse.Value),
	state_accountant: enumRequired(returnGoods.StateAccountant.Value),
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	return_date: stringRequired("return_date"),
	order_id: stringOptional,
	type_id: stringOptional,
	type_name: stringOptional,
	customer_id: stringRequired("customer_id"),
	customer_name: stringOptional,
	sales_id: stringRequired("sales_id"),
	sales_name: stringOptional,
	sales_tel: stringOptional,
	deliver_date: stringRequired("deliver_date"),
	is_include_tax: booleanOptional,
	payee_id: stringRequired("payee_id"),
	payee_name: stringOptional,
	payee_bank: stringOptional,
	payee_account_number: stringOptional,
	deliver_address: stringOptional,
	description: stringOptional,
	order_note: stringOptional,
	items: itemsSchemas,
	tax_price: numberOptional,
	total_price: numberOptional,
	received: numberOptional,
	receivables: numberOptional,
	handling_fee: numberOptional,
	freight: numberOptional,
	miscellaneous_charge: numberOptional,
	commission_refund: numberOptional,
	note: stringOptional,
	memo: stringOptional,
	voucher_rows: arrayOptionalSchema(voucherRowSchema),
});
