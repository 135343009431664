export const graphQLColumns = `{
			id
			type {
				name
			}
			customer {
				name
			}
			sales {
				name
			}
			quotation_date
			state
			state_manager
			state_accountant
			orders {
				id
			}
			items {
				count
				price_final
			}
	}
`;
