import { Cell } from "./Cell";
import { GraphQLResult, Item } from "./GraphQLResult";

export function mapResults(graphQLResult: GraphQLResult): Cell[] {
	const warehouseId = graphQLResult.warehouses_by_pk.id;
	const results: Cell[] = [];
	const importedGoodsItemsCountMap: Map<string, number> = new Map();
	const exportedGoodsItemsCountMap: Map<string, number> = new Map();
	const returnedGoodsItemsCountMap: Map<string, number> = new Map();
	const transferredInCountMap: Map<string, number> = new Map();
	const transferredOutCountMap: Map<string, number> = new Map();
	const maintainingItemsCountMap: Map<string, number> = new Map();
	const maintainedItemsCountMap: Map<string, number> = new Map();

	const itemMap: Map<string, Item> = new Map();

	graphQLResult.imported_good_items.forEach(({ id: itemId, count, item_spec, item_custom }) => {
		if (!importedGoodsItemsCountMap.has(itemId)) {
			importedGoodsItemsCountMap.set(itemId, count || 0);
		} else {
			importedGoodsItemsCountMap.set(itemId, importedGoodsItemsCountMap.get(itemId)! + (count || 0));
		}
		const item = item_spec || item_custom;
		if (item) {
			itemMap.set(itemId, item);
		}
	});

	graphQLResult.exported_good_item_stocks.forEach(
		({ count, export_good_item: { id: itemId, item_spec, item_custom } }) => {
			if (!exportedGoodsItemsCountMap.has(itemId)) {
				exportedGoodsItemsCountMap.set(itemId, count || 0);
			} else {
				exportedGoodsItemsCountMap.set(itemId, exportedGoodsItemsCountMap.get(itemId)! + (count || 0));
			}
			const item = item_spec || item_custom;
			if (item) {
				itemMap.set(itemId, item);
			}
		},
	);

	graphQLResult.returned_goods_items.forEach(({ return_count: count, id: itemId, item_spec, item_custom }) => {
		if (!returnedGoodsItemsCountMap.has(itemId)) {
			returnedGoodsItemsCountMap.set(itemId, count || 0);
		} else {
			returnedGoodsItemsCountMap.set(itemId, returnedGoodsItemsCountMap.get(itemId)! + (count || 0));
		}

		const item = item_spec || item_custom;
		if (item) {
			itemMap.set(itemId, item);
		}
	});

	graphQLResult.transferred_good_item_stocks.forEach(
		({
			id: itemId,
			transfer_count: count,
			item_spec,
			item_custom,
			stock_transfer: { source_warehouse_id, target_warehouse_id },
		}) => {
			//OUT
			if (source_warehouse_id === warehouseId) {
				if (!transferredOutCountMap.has(itemId)) {
					transferredOutCountMap.set(itemId, count || 0);
				} else {
					transferredOutCountMap.set(itemId, transferredOutCountMap.get(itemId)! + (count || 0));
				}

				const item = item_spec || item_custom;
				if (item) {
					itemMap.set(itemId, item);
				}
			}

			//IN
			if (target_warehouse_id === warehouseId) {
				if (!transferredInCountMap.has(itemId)) {
					transferredInCountMap.set(itemId, count || 0);
				} else {
					transferredInCountMap.set(itemId, transferredInCountMap.get(itemId)! + (count || 0));
				}

				const item = item_spec || item_custom;
				if (item) {
					itemMap.set(itemId, item);
				}
			}
		},
	);

	graphQLResult.maintaining_defective_items.forEach(({ count, id: itemId, item_spec, item_custom }) => {
		if (!maintainingItemsCountMap.has(itemId)) {
			maintainingItemsCountMap.set(itemId, count || 0);
		} else {
			maintainingItemsCountMap.set(itemId, maintainingItemsCountMap.get(itemId)! + (count || 0));
		}

		const item = item_spec || item_custom;
		if (item) {
			itemMap.set(itemId, item);
		}
	});

	graphQLResult.maintained_defective_items.forEach(({ count, id: itemId, item_spec, item_custom }) => {
		if (!maintainedItemsCountMap.has(itemId)) {
			maintainedItemsCountMap.set(itemId, count || 0);
		} else {
			maintainedItemsCountMap.set(itemId, maintainedItemsCountMap.get(itemId)! + (count || 0));
		}

		const item = item_spec || item_custom;
		if (item) {
			itemMap.set(itemId, item);
		}
	});

	itemMap.forEach((item, itemId) => {
		const imported_count = importedGoodsItemsCountMap.get(itemId) || 0;
		const exported_count = exportedGoodsItemsCountMap.get(itemId) || 0;
		const returned_count = returnedGoodsItemsCountMap.get(itemId) || 0;
		const transferred_in_count = transferredInCountMap.get(itemId) || 0;
		const transferred_out_count = transferredOutCountMap.get(itemId) || 0;
		const maintained_count = maintainedItemsCountMap.get(itemId) || 0;
		const maintaining_count = maintainingItemsCountMap.get(itemId) || 0;

		const available_count =
			imported_count +
			transferred_in_count +
			returned_count +
			maintained_count -
			maintaining_count -
			exported_count -
			transferred_out_count;

		results.push({
			id: itemId,
			item_id: itemId,
			item_name: item.name,
			image: item.image,
			image_thumbnail: item.image_cut,
			imported_count,
			exported_count,
			returned_count,
			transferred_in_count,
			transferred_out_count,
			available_count,
			maintained_count,
			maintaining_count,
		});
	});

	return results.sort((a, b) => parseInt(b.id.substring(1)) - parseInt(a.id.substring(1)));
}
