import * as _ from "lodash";
import { usePermissions } from "../moo-me-provider";
import { Condition } from "./useFindOne";

function isObjectLiterals(obj: any): boolean {
	return typeof obj === "object" && obj !== null && Object.getPrototypeOf(obj) === Object.prototype;
}

function getObjectQuery(shape: any, parent: string): string {
	const objKeys = Object.keys(shape).map((e) => {
		if (isObjectLiterals(shape[e])) {
			return getObjectQuery(shape[e], e);
		} else {
			return e;
		}
	});
	return `
		${parent} {
			${objKeys.join("\n")}
		}
	`;
}

export function useReadFields<T extends { [key: string]: any }>(
	one: T,
	readCondition: Condition<T>,
	branch: boolean = true,
): string {
	const permissions = usePermissions();
	const permissionSet = new Set(permissions);
	const fields: string[] = Object.keys(one);

	const newFields = [];
	if (branch) {
		newFields.push("branch_id");
		newFields.push("branch { name }");
	}

	fields.forEach((field) => {
		if (Object.prototype.hasOwnProperty.call(readCondition, field)) {
			const permission = readCondition[field];
			// @ts-ignore
			if (permissionSet.has(permission)) {
				newFields.push(field);
			}
		} else if (isObjectLiterals(one[field])) {
			const arr: string[] = [];
			Object.keys(one[field]).forEach((key) => {
				if (_.isArray(one[field][key])) {
					arr.push(getObjectQuery(one[field][key][0], key));
				} else if (isObjectLiterals(one[field][key])) {
					arr.push(getObjectQuery(one[field][key], key));
				} else {
					arr.push(key);
				}
			});
			newFields.push(`${field} { ${arr.join("\n")} }`);
		} else if (_.isArray(one[field])) {
			const arr: string[] = one[field];
			const fields: string[] = [];
			arr.forEach((obj) => {
				Object.keys(obj).forEach((key) => {
					// @ts-ignore
					if (_.isArray(obj[key])) {
						const nestedFields: string[] = [];
						// @ts-ignore
						obj[key].forEach((arrayObject: string) => {
							Object.keys(arrayObject).forEach((k) => {
								nestedFields.push(k);
							});
						});
						fields.push(`${key} { ${nestedFields.join("\n")} }`);
						// @ts-ignore
					} else if (_.isObject(obj[key])) {
						const nestedFields: string[] = [];
						// @ts-ignore
						_.keys(obj[key]).forEach((k) => {
							// @ts-ignore
							if (_.isArray(obj[key][k]) && obj[key][k].length > 0) {
								// @ts-ignore
								const o = obj[key][k][0];
								const a = Object.keys(o);
								nestedFields.push(`${k} { ${a.join("\n")} }`);
							} else {
								nestedFields.push(k);
							}
						});
						fields.push(`${key} { ${nestedFields.join("\n")} }`);
					} else {
						fields.push(key);
					}
				});
			});
			newFields.push(`${field} { ${fields.join("\n")} }`);
		} else {
			newFields.push(field);
		}
	});
	return newFields.join("\n");
}
