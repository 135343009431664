import { Status, stockTransfer } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();
	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		state: stockTransfer.State.Value.UnVerified,
		transfer_date: now,
		owner_id: "",
		source_warehouse_id: "",
		target_warehouse_id: "",
		items: [],
	};
}
