import { KodenAvatar } from "@yachelee/koden-components/koden-avatar";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import styled from "styled-components";

export type MooMessageOneProps = {
	id: string;
	userName: string;
	userBackgroundColor: string;
	value: string;
	onChange: (value: string) => void;
};

const Container = styled.div`
	display: flex;
	align-items: center;

	> *:nth-of-type(2) {
		margin-left: 1rem;
	}
`;

export function MooMessageOne({ id, userName, userBackgroundColor, value, onChange }: MooMessageOneProps) {
	return (
		<Container>
			<KodenAvatar backgroundColor={userBackgroundColor} value={userName} />
			<KodenMultilineField
				id={`MooMessageOne_Field_${id}`}
				error=""
				outlined
				label="新增紀錄"
				margin="none"
				rows={2}
				value={value}
				onChange={onChange}
			/>
		</Container>
	);
}
