import usePage from "../../../../hooks/usePage";
import useURLSearchParams from "../../../../hooks/useURLSearchParams";
import Utils from "../../../../utils";
import { manufacturer, Status } from "../../moo-enums";
import { Query } from "../@types";

export function useUrlQuery(): Query {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();
	const fromDate = urlSearchParams.get("from_date");
	const toDate = urlSearchParams.get("to_date");

	return {
		search: urlSearchParams.get("search") || null,
		status: urlSearchParams.get("status") === Status.Archived ? Status.Archived : Status.Present,
		page,
		account_type: urlSearchParams.get("account_type") || null,
		manufacturer_type: (urlSearchParams.get("manufacturer_type") as manufacturer.Value.Value) || null,
		manufacturer_id: (urlSearchParams.get("manufacturer_id") as string) || null,
		from_date: Utils.isDateString(fromDate) ? new Date(fromDate!) : null,
		to_date: Utils.isDateString(toDate) ? new Date(toDate!) : null,
	};
}
