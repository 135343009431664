import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { DEFAULT_ONE, MooReturnGoodsOne, One } from "../moo-return-goods-one";
import { useHasuraAdd } from "../moo-shared";

export type MooReturnGoodsAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooReturnGoodsAdd: FC<MooReturnGoodsAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("return_goods", onSuccess, (e) => {
		return produce(e, (draft) => {
			// @ts-ignore
			delete draft.type_name;
			// @ts-ignore
			delete draft.customer_name;
			// @ts-ignore
			delete draft.sales_name;
			// @ts-ignore
			delete draft.sales_tel;
			// @ts-ignore
			delete draft.payee_name;
			// @ts-ignore
			delete draft.payee_bank;
			// @ts-ignore
			delete draft.payee_account_number;

			draft.items.forEach((item, index) => {
				// @ts-ignore
				draft.items[index] = {
					return_good_item_id: item.return_good_item_id,
					id: item.id,
					return_count: item.return_count,
					warehouse_id: item.warehouse_id,
				};
			});
		});
	});
	const permissions = usePermissions();

	return (
		<MooReturnGoodsOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.ReturnGoodsAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
