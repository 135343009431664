import { MooQuotationOne, One } from "../moo-quotation-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Quotation } from "./findOne/Quotation";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { QuotationItemsTable } from "./updateOne/QuotationItemsTable";
import { QuotationSignedFilesTable } from "./updateOne/QuotationSignedFilesTable";
import { QuotationTable } from "./updateOne/QuotationTable";

export type MooQuotationUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooQuotationUpdate({ id, onCancel }: MooQuotationUpdateProps) {
	return (
		<MooUpdateFormNew<Quotation, One>
			id={id}
			onCancel={onCancel}
			operationName="quotation-one"
			query={query}
			tableName="quotations"
			mapperInitialValues={mapperInitialValues}
			Form={MooQuotationOne}
			tables={[QuotationItemsTable, QuotationSignedFilesTable, QuotationTable]}
		/>
	);
}
