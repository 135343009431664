import { useGetGcsUrl } from "@yachelee/koden-components/koden-gcs-provider";
import styled from "styled-components";

export type ImagesProps = {
	attaches: string[];
};

const Container = styled.div`
	display: grid;
	grid-gap: 6px;
	grid-template-columns: repeat(auto-fill, 75px);
`;

export function Images({ attaches }: ImagesProps) {
	const getGcsUrl = useGetGcsUrl();

	return (
		<Container>
			{attaches.map((value) => (
				<a key={value} href={getGcsUrl(value)} target="_blank" rel="noreferrer">
					<img alt="" src={getGcsUrl(value)} width={75} height={75} style={{ objectFit: "cover" }} />
				</a>
			))}
		</Container>
	);
}

export default Images;
