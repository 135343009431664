import { MooPdfButton } from "../../moo-config";
import { useOnPdfClick } from "../hooks/useOnPdfClick";

type Props = {
	id: string;
	enabled?: boolean;
};

export function PdfButton({ id, enabled = false }: Props) {
	const onPdfClick = useOnPdfClick();

	return <MooPdfButton enabled={enabled} onClick={() => onPdfClick(id)} />;
}
