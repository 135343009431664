import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../config";
import Utils from "../utils";

type OnRequestType = (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>;

export const createAjax = () => {
	return axios.create({ baseURL: API_URL });
};

export const getAuthorizationHeader = (token: string) => {
	return {
		Authorization: `Bearer ${token}`,
	};
};

const useOnRequest = () => {
	const onRequest: OnRequestType = async (config) => {
		const refreshToken = Utils.getRefreshToken();
		const accessToken = Utils.getAccessToken();

		if (accessToken !== null) {
			config.headers = getAuthorizationHeader(accessToken);
			return config;
		}

		if (refreshToken == null) {
			Utils.redirectToLoginPage();
			return config;
		}

		try {
			if (Utils.isRefreshingToken) {
				return new Promise((done) => {
					const timer = window.setInterval(() => {
						if (!Utils.isRefreshingToken && Utils.accessToken !== null) {
							config.headers = getAuthorizationHeader(Utils.accessToken);
							window.clearInterval(timer);
							done(config);
						}
					}, 100);
				});
			} else {
				const newAccessToken = await Utils.getNewAccessToken();
				Utils.setAccessToken(newAccessToken);
				config.headers = getAuthorizationHeader(newAccessToken);
				return config;
			}
		} catch (e) {
			Utils.setAccessToken(null);
			Utils.deleteRefreshToken();
			return config;
		}
	};
	return onRequest;
};

type Error = {
	config: AxiosRequestConfig;
	response: {
		data: {
			errorCode: string;
		} | null;
	} | null;
};

const useOnResponseReject = () => {
	return async (error: Error) => {
		return new Promise((resolve, reject) => {
			const errorConfig = error?.config;
			const errorCode = error?.response?.data?.errorCode;

			if (errorCode === "REF_100004") {
				Utils.getNewAccessToken()
					.then((newAccessToken) => {
						Utils.setAccessToken(newAccessToken);
						errorConfig.headers = getAuthorizationHeader(newAccessToken);

						createAjax().request(errorConfig).then(resolve).catch(reject);
					})
					.catch((e) => {
						Utils.setAccessToken(null);
						Utils.deleteRefreshToken();
						reject(e);
					});
			} else {
				reject(error);
			}
		});
	};
};

const useAjax = () => {
	const ajax = createAjax();
	const onRequest = useOnRequest();
	const onResponseReject = useOnResponseReject();

	// @ts-ignore
	ajax.interceptors.request.use(onRequest, Promise.reject);
	ajax.interceptors.response.use((response) => response, onResponseReject);

	return ajax;
};

export default useAjax;
