import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { DEFAULT_ONE, MooOrderOne, One } from "../moo-order-one";
import { useHasuraAdd } from "../moo-shared";

export type MooOrderAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooOrderAdd: FC<MooOrderAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>(
		"orders",
		onSuccess,
		produce((draft) => {
			// @ts-ignore
			delete draft.type_name;
			// @ts-ignore
			delete draft.customer_name;
			// @ts-ignore
			delete draft.sales_name;
			// @ts-ignore
			delete draft.sales_tel;
			// @ts-ignore
			delete draft.payee_name;
			// @ts-ignore
			delete draft.payee_bank;
			// @ts-ignore
			delete draft.payee_account_number;
			// @ts-ignore
			delete draft.receivables;

			draft.items.forEach((item) => {
				// @ts-ignore
				delete item.type;
				// @ts-ignore
				delete item.category;
				// @ts-ignore
				delete item.manufacturer_type;
				// @ts-ignore
				delete item.manufacturer_id;
				// @ts-ignore
				delete item.manufacturer;
				// @ts-ignore
				delete item.name;
				// @ts-ignore
				delete item.image;
				// @ts-ignore
				delete item.image_cut;
				// @ts-ignore
				delete item.material;
				// @ts-ignore
				delete item.color;
				// @ts-ignore
				delete item.size;
				// @ts-ignore
				delete item.purchase_price;
				// @ts-ignore
				delete item.price;
				// @ts-ignore
				delete item.cost;
				// @ts-ignore
				delete item.spec;
				// @ts-ignore
				delete item.price_total;
			});
		}),
		true,
	);
	const permissions = usePermissions();

	return (
		<MooOrderOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.OrderAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
