import { gql } from "@apollo/client";
import { MooGraphqlSelectField } from "../moo-graphql-select-field";

type Value = string | null;

export type MooEmployeeSelectFieldProps = {
	id: string;
	value: Value;
	label: string;
	error?: string;
	onChange: (value: Value) => void;
};

export function MooEmployeeSelectField({ id, label, value, error = "", onChange }: MooEmployeeSelectFieldProps) {
	return (
		<MooGraphqlSelectField
			id={id}
			label={label}
			value={value}
			error={error}
			onChange={onChange}
			operationName="employees"
			query={gql`
				query ($where: employees_bool_exp!) {
					employees(where: $where) {
						id
						name
					}
				}
			`}
			variables={{
				where: {
					status: {
						_eq: "PRESENT",
					},
				},
			}}
			mapData={(e) => {
				const results: [{ label: string; value: string | null }] = [{ value: null, label: "請選擇" }];

				(e.employees as { id: string; name: string }[]).forEach(({ id, name }) => {
					results.push({
						label: name,
						value: id,
					});
				});

				return {
					results,
				};
			}}
		/>
	);
}
