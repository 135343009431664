import { MooEnumCrud } from "../moo-enum-crud";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export interface MooItemCategoryMainProps {
	onClick: (recordId: string) => void;
}

export function MooItemCategoryMain({ onClick }: MooItemCategoryMainProps) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.ItemCategoryWrite)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooEnumCrud
			resource="item_categories"
			filter={{
				parent: {
					_is_null: true,
				},
			}}
			title="商品主類"
			addColumns={[
				{
					id: "name",
					defaultValue: "",
					label: "名稱",
					autoFocus: true,
					placeholder: "沙發",
					emptyError: "",
				},
			]}
			listBackgroundColor="#EAEAEA"
			listColumns={[
				{
					id: "name",
					defaultValue: "",
				},
			]}
			columnPlaceHolder={(recordId) => {
				return (
					<button
						onClick={() => {
							onClick(recordId);
						}}
					>
						管理
					</button>
				);
			}}
		/>
	);
}
