import { gql } from "@apollo/client";
import { KodenYearAndMonthField } from "@yachelee/koden-components/koden-year-and-month-field";
import { useBranch } from "../moo-branch-provider";
import { MooGraphQLTable } from "../moo-graphql-table";
import { StoreType } from "../moo-hooks";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query, Result, Row } from "./@types";
import { useCells } from "./useCells";
import { useHeaders } from "./useHeaders";

export type MooUserPerformanceListProps = {
	store: StoreType<Query>;
	onRowClick: (row: { year: number; month: number; employee_id: string }, e: MouseEvent) => void;
};

export const MooUserPerformanceList = ({ onRowClick, store }: MooUserPerformanceListProps) => {
	const theStore = store();
	const { value: branch } = useBranch();
	const permissions = usePermissions();
	const cells = useCells();
	const headers = useHeaders();
	const { query, setQuery } = theStore;

	if (!permissions.includes(Permission.PerformanceRead)) {
		return <MooNoAuthPage />;
	}

	const variables = {
		where: {
			order_date_year: {
				_eq: query.year,
			},
			order_date_month: {
				_eq: query.month,
			},
			order_branch_id: {
				_eq: branch,
			},
		},
	};

	return (
		<>
			<div style={{ marginBottom: "1rem" }}>
				<KodenYearAndMonthField
					id="user_performance_year_and_month_field"
					//@ts-ignore
					value={query}
					label="日期"
					error=""
					onChange={setQuery}
				/>
			</div>
			<MooGraphQLTable
				headers={headers}
				cells={cells}
				page={query.page}
				rowsPerPage={Number.MAX_SAFE_INTEGER}
				onPageChange={(page) => {
					setQuery({ page });
				}}
				operationName=""
				onRowClick={(row, e) => {
					onRowClick(
						{
							//@ts-ignore
							year: theStore.query.year,
							//@ts-ignore
							month: theStore.query.month,
							employee_id: (row as unknown as Row).employee_id,
						},
						e,
					);
				}}
				mapData={({ employees }: Result) => {
					const rows: Row[] = [];
					employees.forEach((employee) => {
						const { sum, avg } = employee.account_performances_aggregate.aggregate;
						rows.push({
							id: employee.id,
							employee_id: employee.id,
							employee_name: employee.name,
							total_price: sum.total_price,
							cost: sum.cost,
							driver_fee: sum.driver_fee,
							bonus_percentage: avg.bonus_percentage === null ? null : parseFloat(avg.bonus_percentage.toFixed(2)),
							commission_refund: sum.commission_refund,
							tax_price: sum.tax_price,
							mgt_cost: sum.mgt_cost,
							handling_fee: sum.handling_fee,
							miscellaneous_charge: sum.miscellaneous_charge,
							bonus: sum.bonus,
							custom_bonus: sum.custom_bonus,
							revenue: sum.revenue,
						});
					});

					return {
						results: rows,
						total_count: rows.length,
					};
				}}
				query={gql`
					query ($where: account_performances_bool_exp) {
						employees {
							id
							name
							account_performances_aggregate(where: $where) {
								aggregate {
									sum {
										total_price
										cost
										driver_fee
										commission_refund
										tax_price
										mgt_cost
										handling_fee
										miscellaneous_charge
										revenue
										bonus
										custom_bonus
									}
									avg {
										bonus_percentage
									}
								}
							}
						}
					}
				`}
				variables={variables}
			/>
		</>
	);
};
