import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { FieldButton } from "./FieldButton";
import { GraphQLResult } from "./findOneById/GraphQLResult";
import { query } from "./findOneById/query";
import { useInternalState } from "./useInternalState";

export function Display() {
	const { warehouseId, setIsOpen } = useInternalState();
	const { value: branch } = useBranch();
	const variables = {
		id: warehouseId,
	};
	const { isLoading, data } = useGraphQLQuery<GraphQLResult>({ query, variables, operationName: "" });
	if (isLoading) {
		return null;
	}

	// @ts-ignore
	const record = data.warehouses_by_pk;

	if (record === null) {
		return <div>找不到這筆資料</div>;
	} else if (record.status !== Status.Present) {
		// @ts-ignore
		return <div>此倉庫({data.warehouses_by_pk.name})已被刪除</div>;
	} else if (record.branch.id !== branch) {
		return <div>此資料屬於{record.branch.name}</div>;
	}

	// @ts-ignore
	return <FieldButton id="warehouse" warehouseName={data.warehouses_by_pk.name} onClick={() => setIsOpen(true)} />;
}
