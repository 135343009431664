import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class ItemsTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "stock_transfer_item_id";
	}

	getTableName(): string {
		return "stock_transfer_items";
	}

	getPathName(): string {
		return "items";
	}

	getParentIdName(): string {
		return "stock_transfer_id";
	}

	getToUpdateColumns(): string[] {
		return ["id", "transfer_count"];
	}
}
