import { Branch, useBranch } from "../components/moo-components/moo-branch-provider";

type ReturnType = {
	address: string;
	tel: string;
	fax: string;
};

export function useCompanyInfo(): ReturnType {
	const { value: branchId } = useBranch();

	switch (branchId) {
		case Branch.KAO:
			return {
				address: "高雄市左營區新勝街25號",
				tel: "07-522-7559",
				fax: "07-522-8739",
			};
		case Branch.TPE:
		default:
			return {
				address: "台北市內湖區民權東路六段131號",
				tel: "02-2790-8698",
				fax: "02-2790-6358",
			};
	}
}
