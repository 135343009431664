import { Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import EllipsisText from "./EllipsisText";
import { MooTableProps } from "./MooTableProps";

const StyledTableCell = styled(TableCell)<{
	color: string;
}>`
	&.MuiTableCell-head {
		background-color: #eaeaea;
		color: ${(props) => props.color};
		font-weight: bold;
	}
`;

export function MooTable({ results, headers, headerColor = "black", cells, onRowClick = () => {} }: MooTableProps) {
	const isNotFound = results.length === 0;

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						{headers.map(({ id, align, label, style }) => {
							return (
								<StyledTableCell key={id} color={headerColor} align={align} style={style}>
									{typeof label === "string" ? label : label({})}
								</StyledTableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{isNotFound && (
						<TableRow>
							<TableCell colSpan={headers.length}>找不到資料</TableCell>
						</TableRow>
					)}
					{results.map((result) => (
						<TableRow
							key={result.id}
							hover
							style={{ cursor: onRowClick !== undefined ? "pointer" : "" }}
							onClick={(e: unknown) => {
								if (onRowClick) {
									onRowClick(result, e as MouseEvent);
								}
							}}
						>
							{cells.map(({ align, style, isEllipsis = false, component }, index) => {
								return (
									<TableCell key={`${result.id}_${index}`} style={style} align={align}>
										{isEllipsis ? <EllipsisText>{component(result)}</EllipsisText> : component(result)}
									</TableCell>
								);
							})}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default MooTable;
