import { ItemType, MooItemManufacturerSelect } from "../../moo-item-manufacturer-select";
import { useGraphQLResource } from "../hooks/useGraphQLResource";
import useStore from "../stores/useStore";

export function ItemManufacturerSelect() {
	const {
		query: { manufacturer_type, manufacturer_id },
		setQuery,
	} = useStore();
	const graphQLResource = useGraphQLResource();

	return (
		<MooItemManufacturerSelect
			manufacturerType={manufacturer_type}
			itemType={graphQLResource as ItemType}
			value={manufacturer_id}
			onChange={(manufacturer_id) => setQuery({ manufacturer_id, page: 0 })}
		/>
	);
}
