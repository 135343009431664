import { Item } from "../../moo-defective-product-one";
import { Calculator } from "../../moo-quotation-one";
import { getItemSpec, getItemType } from "../../moo-shared";
import { RowItem } from "./RowItem";

export function mapperItems(items: RowItem[]): Item[] {
	return items.map(({ defective_product_item_id, id, count, item_spec, item_custom }, priority) => {
		const item = item_spec || item_custom;
		const purchase_price = item.purchase_price;

		return {
			defective_product_item_id,
			id,
			manufacturer_type: item.manufacturer.type,
			manufacturer_id: item.manufacturer.id,
			manufacturer: item.manufacturer.name,
			category: item.category.name,
			type: getItemType(id),
			name: item.name,
			cost: item.cost,
			spec: getItemSpec(item),
			material: item.material,
			color: item.color,
			size: item.size,
			count,
			price: item.price,
			purchase_price: item.purchase_price,
			purchase_price_total: Calculator.getItemPurchasePriceTotal({ purchase_price, count }),
			image: item.image,
			image_cut: item.image_cut,
			note: item.note,
			priority,
		};
	});
}
