import { One } from "../@types/one";

export function useSyncTotalPrice() {
	return (one: One) => {
		//總價(price_total) = 售價(price_final) ＊數量
		one.items.forEach((item) => {
			item.price_total = item.price_final * item.count;
		});

		//合計
		one.total_price = one.items.map((item) => item.price_total).reduce((a, b) => a + b, 0);
	};
}
