import { Calculator } from "../moo-quotation-one";
import { QuotationsData, Row } from "./@types";

export function mapResults(quotations: QuotationsData["quotations"]): Row[] {
	return quotations.map(
		({ id, type, customer, sales, quotation_date, state, state_manager, state_accountant, orders, items }) => {
			const theItems: { price_final: number; count: number }[] = items.map(({ count, price_final }) => {
				return {
					price_final,
					count,
				};
			});
			return {
				id,
				type_name: type.name,
				customer_name: customer.name,
				sales_name: sales.name,
				quotation_date,
				state,
				state_manager,
				state_accountant,
				price_total: Calculator.getPriceTotal(theItems),
				order_ids: orders.map((e) => e.id),
			};
		},
	);
}

export function mapData({ quotations, quotations_aggregate }: QuotationsData) {
	return {
		results: mapResults(quotations),
		total_count: quotations_aggregate.aggregate.count,
	};
}
