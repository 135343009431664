import { Stock } from "../../moo-export-goods-one";
import { RowItemStock } from "./RowItemStock";

export function mapperStocks(stocks: RowItemStock[]): Stock[] {
	return stocks.map(({ count, export_good_item_stock_id, warehouse_id }) => {
		return {
			export_good_item_stock_id,
			warehouse_id: warehouse_id,
			count,
		};
	});
}
