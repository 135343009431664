import { Query } from "../@types";
import { DateFilter } from "./DateFilter";

type Props = {
	query: Query;
	setQuery: (query: Partial<Query>) => void;
};

export function DateRangeSelect({ query, setQuery }: Props) {
	return (
		<>
			<DateFilter field="from_date" query={query} setQuery={setQuery} label="傳票開始日期" />
			<DateFilter field="to_date" query={query} setQuery={setQuery} label="傳票結束日期" />
		</>
	);
}
