import create, { GetState, SetState } from "zustand";
import { ChooseStoreSlice, createChooseSlice } from "./createChooseSlice";
import { createQuerySlice, QueryStoreSlice } from "./createQuerySlice";

export type StoreState = QueryStoreSlice & ChooseStoreSlice;

export type StoreSlice<T> = (set: SetState<StoreState>, get: GetState<StoreState>) => T;

export const useStore = create<StoreState>((set, get) => ({
	...createQuerySlice(set, get),
	...createChooseSlice(set, get),
}));

export default useStore;
