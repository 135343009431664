import produce from "immer";
import { FC } from "react";
import { MooItemRowList } from "../../../moo-item-row-list";
import { FieldType as SpecFieldType } from "../../../moo-item-spec-and-custom-row";
import { ItemBound } from "../../../moo-types";
import { One } from "../../@types/one";
import { useForm } from "../../useForm";
import { useSyncPrices } from "../../useSyncPrices";

function useUpdateItemPriceFinal() {
	const { values, setValues } = useForm();
	const syncPrices = useSyncPrices();

	return (id: string, priceFinal: number) => {
		const newValues = produce(values, (one) => {
			const index = one.items.findIndex((e) => e.id === id);
			if (index !== -1) {
				one.items[index].price_final = priceFinal;
				syncPrices(one);
			}
		});
		setValues(newValues);
	};
}

function useUpdateItemCount() {
	const { values, setValues } = useForm();
	const syncPrices = useSyncPrices();

	return (id: string, count: number) => {
		const newValues = produce(values, (one: One) => {
			const index = one.items.findIndex((e) => e.id === id);
			if (index !== -1) {
				one.items[index].count = count;
			}
			syncPrices(one);
		});
		setValues(newValues);
	};
}

function useRemoveItem() {
	const { values, setValues } = useForm();
	const syncPrices = useSyncPrices();

	return (id: string) => {
		const newValues = produce(values, (draft: One) => {
			const index = draft.items.findIndex((e) => e.id === id);
			if (index !== -1) {
				draft.items.splice(index, 1);
			}
			syncPrices(draft);
		});
		setValues(newValues);
	};
}

export const ItemList: FC = () => {
	const { values } = useForm();
	const removeItem = useRemoveItem();
	const updateItemCount = useUpdateItemCount();
	const updateItemPriceFinal = useUpdateItemPriceFinal();

	return (
		<>
			<header
				style={{
					borderBottom: "1px solid #EBEBEB",
					display: "flex",
					justifyContent: "space-between",
					marginBottom: ".3rem",
				}}
			>
				<h1 style={{ fontSize: "1.5rem" }}>退訂商品</h1>
			</header>
			<div
				style={{
					marginBottom: "2rem",
					maxWidth: "1024px",
				}}
			>
				<MooItemRowList
					specCustomFields={
						new Set<SpecFieldType>([
							"id",
							"manufacturer_type",
							"manufacturer",
							"name",
							"material",
							"color",
							"size",
							"count",
							"purchase_price",
							"price",
							"price_total",
							"price_final",
							"remove",
						])
					}
					items={values.items as unknown as ItemBound[]}
					onRemove={removeItem}
					onCountChange={updateItemCount}
					onPriceFinalChange={updateItemPriceFinal}
				/>
			</div>
		</>
	);
};

export default ItemList;
