import produce from "immer";
import { DEFAULT_ONE } from "./DEFAULT_ONE";

export const shape = produce(DEFAULT_ONE(), (draft) => {
	draft.links = [
		{
			blog_link_id: "",
			value: "",
		},
	];
	draft.attaches = [
		{
			blog_attach_id: "",
			value: "",
		},
	];
});
