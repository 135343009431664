import { FC } from "react";
import styled from "styled-components";
import ClearAll from "../ClearAll";

const Container = styled.div`
	display: flex;
	align-items: center;
`;

export interface QueryBoardProps {
	clear: () => void;
}

const QueryBoard: FC<QueryBoardProps> = ({ clear }) => {
	return (
		<Container>
			<ClearAll onClick={clear} />
		</Container>
	);
};

export default QueryBoard;
