import styled from "@emotion/styled";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { useFormikContext } from "formik";
import produce from "immer";
import { SpecOrCustomTableRow, useItemDialogChoose } from "../../../moo-dialog-item-choose";
import { attachItems, ErrorMessage, getItemSpec, getModelItemId, useGetFieldError } from "../../../moo-shared";
import { Item, One } from "../../@types/one";
import { Calculator } from "../../Calculator";
import { ItemList } from "./ItemList";

const Title = styled.h1`
	font-size: 1.5rem;
`;

export function ChooseItemArea() {
	const helpers = useFormikContext<One>();
	const { values } = helpers;
	const { component, open } = useItemDialogChoose(
		"dialog-quotation-add-item",
		function chooseItems(rows: SpecOrCustomTableRow[]) {
			const newOne = produce(values, (draft) => {
				// @ts-ignore
				draft.items = attachItems<SpecOrCustomTableRow>(
					draft.items,
					rows.map((row, index) => {
						const quotation_item_id = getModelItemId({ create_date: values.create_date, item_id: row.id });
						return {
							quotation_item_id,
							id: row.id,
							type: row.type,
							category: row.category,
							manufacturer_type: row.manufacturer_type,
							manufacturer_id: row.manufacturer_id,
							manufacturer: row.manufacturer,
							name: row.name,
							image: row.image,
							image_cut: row.image_cut,
							material: row.material,
							color: row.color,
							size: row.size,
							purchase_price: row.purchase_price,
							price: row.price,
							cost: row.cost,
							spec: getItemSpec(row),
							count: 1,
							price_final: 0,
							price_total: 0,
							note: "",
							priority: index,
						} as Item;
					}),
				);
			});
			helpers.setValues(Calculator.syncPrices(newOne));
		},
	);
	const getFieldError = useGetFieldError<One>();

	return (
		<>
			{component}
			<div style={{ marginBottom: "2rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<Title>報價商品</Title>
					<div>
						<KodenIconTextButton text="新增商品" onClick={() => open()} />
						<ErrorMessage values={[getFieldError("items")]} />
					</div>
				</header>
				<ItemList />
			</div>
		</>
	);
}
