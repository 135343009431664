import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { KodenAvatar } from "@yachelee/koden-components/koden-avatar";
import styled from "styled-components";

export type MooMessageCardProps = {
	id: string;
	userName: string;
	userBackgroundColor: string;
	value: string;
	createDate: string;
	edit: boolean;
	remove: boolean;
	onEditClick?: () => void;
	onRemoveClick?: () => void;
};

const Container = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 1rem;
`;

const Content = styled.div`
	background-color: #f4f4f4;
	border-radius: 6px;
	font-size: 12px;
	padding: 8px;
`;

const Date = styled.div`
	font-size: 10px;
	color: grey;
`;

const ActionBtn = styled.button`
	background: transparent;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;

	& svg {
		margin-right: 0.3rem;
	}

	&:active {
		outline: none;
	}
	&:not(:last-of-type) {
		margin-right: 1rem;
	}
`;

const DeleteBtn = styled(ActionBtn)`
	color: #e66c6c;
	cursor: pointer;
`;

const EditBtn = styled(ActionBtn)`
	color: #6d87c6;
	cursor: pointer;
`;

export function MooMessageCard({
	id,
	userName,
	userBackgroundColor,
	value,
	createDate,
	edit,
	remove,
	onEditClick,
	onRemoveClick,
}: MooMessageCardProps) {
	return (
		<Container id={id}>
			<KodenAvatar backgroundColor={userBackgroundColor} value={userName} />
			<div style={{ width: "100%", wordBreak: "break-word" }}>
				<Content dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, "<br />") }} />
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flexWrap: "wrap",
						flexDirection: "row-reverse",
						alignItems: "center",
						padding: ".5rem 0",
					}}
				>
					<Date>{createDate}</Date>
					<div style={{ display: "flex" }}>
						{remove && (
							<DeleteBtn
								onClick={() => {
									if (onRemoveClick) {
										onRemoveClick();
									}
								}}
							>
								<DeleteIcon fontSize="small" />
								刪除
							</DeleteBtn>
						)}
						{edit && (
							<EditBtn
								onClick={() => {
									if (onEditClick) {
										onEditClick();
									}
								}}
							>
								<EditIcon fontSize="small" />
								編輯
							</EditBtn>
						)}
					</div>
				</div>
			</div>
		</Container>
	);
}
