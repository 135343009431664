import produce from "immer";
import { FC } from "react";
import { DEFAULT_ONE, MooExportGoodsOne, One } from "../moo-export-goods-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooExportGoodsAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooExportGoodsAdd: FC<MooExportGoodsAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("export_goods", onSuccess, (e) => {
		return produce(e, (draft) => {
			// @ts-ignore
			delete draft.type_name;
			// @ts-ignore
			delete draft.customer_name;
			// @ts-ignore
			delete draft.sales_name;
			// @ts-ignore
			delete draft.sales_tel;
			// @ts-ignore
			delete draft.driver_name;
			// @ts-ignore
			delete draft.driver_tel;

			draft.items.forEach((item, index) => {
				const newStocks = item.stocks.map((stock) => {
					return {
						export_good_item_stock_id: stock.export_good_item_stock_id,
						warehouse_id: stock.warehouse_id,
						count: stock.count,
					};
				});

				// @ts-ignore
				draft.items[index] = {
					export_good_item_id: item.export_good_item_id,
					id: item.id,
					// @ts-ignore
					stocks: newStocks,
				};
			});
		});
	});
	const permissions = usePermissions();

	return (
		<MooExportGoodsOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.ExportGoodsAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
		/>
	);
};
