import { MooGqlChildNewTable } from "../../moo-gql";
import { Order } from "../findOne/Order";

export class OrderItemsTable extends MooGqlChildNewTable<Order> {
	getIdName(): string {
		return "order_item_id";
	}

	getTableName(): string {
		return "order_items";
	}

	getPathName(): string {
		return "items";
	}

	getParentIdName(): string {
		return "order_id";
	}

	getToUpdateColumns(): string[] {
		return ["id", "count", "price_final", "note", "priority"];
	}
}
