import { array, object, SchemaOf } from "yup";
import { stockTransfer } from "../../moo-enums";
import { enumRequired, statusSchema, stringOptional, stringRequired } from "../../moo-schemas";
import { One } from "../@types/one";
import { itemSchema } from "./itemSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: statusSchema,
	state: enumRequired(stockTransfer.State.Value),
	create_date: stringOptional,
	update_date: stringOptional,
	transfer_date: stringRequired("transfer_date"),
	owner_id: stringRequired("owner_id", "請選擇負責人"),
	source_warehouse_id: stringRequired("source_warehouse_id", "請選擇發貨倉庫"),
	target_warehouse_id: stringRequired("target_warehouse_id", "請選擇收貨倉庫"),
	items: array(itemSchema).test("items", "尚未匯入庫存商品", (items) => {
		// @ts-ignore
		return items.length >= 1;
	}),
});
