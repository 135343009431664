import { ProxyKodenSelectField } from "../../ProxyKodenSelectField";
import { Branch, useBranch } from "../moo-branch-provider";
import { useMe, UserRolePermission } from "../moo-me-provider";

export function MooMeBranchSelectField() {
	const { value: branch, setValue: setBranch } = useBranch();
	const me = useMe();
	if (me === null) {
		return null;
	}

	const userRolePermissions: UserRolePermission[] = me.role.permissions;
	if (userRolePermissions.length === 0) {
		return null;
	}

	const options = [];

	if (userRolePermissions.some((e) => e.branch_id === Branch.ADMIN)) {
		options.push({
			id: Branch.ADMIN,
			value: Branch.ADMIN,
			label: "系統",
		});
	}

	if (userRolePermissions.some((e) => e.branch_id === Branch.TPE)) {
		options.push({
			id: Branch.TPE,
			value: Branch.TPE,
			label: "台北",
		});
	}

	if (userRolePermissions.some((e) => e.branch_id === Branch.KAO)) {
		options.push({
			id: Branch.KAO,
			value: Branch.KAO,
			label: "高雄",
		});
	}

	return (
		<ProxyKodenSelectField
			id="me-branch"
			variant="outlined"
			value={branch as string}
			options={options}
			onChange={(value) => {
				setBranch(value as Branch);
			}}
		/>
	);
}
