import { KodenButton } from "@yachelee/koden-components/koden-button";
import { Formik } from "formik";
import styled from "styled-components";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooSubmitButton } from "../moo-submit-button";
import { Props } from "./@types/props";
import { AccountantBlock, BasicBlock, StateBlock } from "./blocks";
import { PdfButton } from "./components/PdfButton";
import { useForm } from "./hooks/useForm";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

const Container = styled.form`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

function Form({
	id,
	isEdit = false,
	isCancelDisabled,
	isOkDisabled,
	onCancel,
}: Omit<Props, "onSubmit" | "initialValues">) {
	const { handleSubmit } = useForm();

	return (
		<Container onSubmit={handleSubmit}>
			{isEdit && <StateBlock />}
			<BasicBlock />
			<AccountantBlock />
			<ButtonContainer>
				{isEdit && id && <PdfButton id={id} />}
				{!isOkDisabled && (
					<>
						<KodenButton
							style={{ marginRight: "1rem" }}
							disabled={isCancelDisabled}
							onClick={async () => {
								if (window.confirm("確定取消並且回到上一頁？")) {
									onCancel();
								}
							}}
						>
							取消
						</KodenButton>
						<MooSubmitButton permission={Permission.ImportGoodsUpdate} />
					</>
				)}
			</ButtonContainer>
		</Container>
	);
}

export function MooImportGoodsOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.ImportGoodsRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
			<Form {...props} />
		</Formik>
	);
}
