import { manufacturer as manufacturerType, Status } from "../../moo-enums";
import { One } from "../../moo-order-goods-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Row): One {
	const manufacturer =
		record.manufacturer_type === manufacturerType.Value.Value.Domestic
			? record.manufacturer_domestic
			: record.manufacturer_foreign;

	const items = mapperItems(record.items);

	return {
		status: Status.Present,
		employee: record.employee,
		order: record.order,
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		state: record.state,
		state_warehouse: record.state_warehouse,
		type: record.type,
		sales_id: record.sales.id,
		sales_name: record.sales.name,
		sales_tel: record.sales.tel,
		manufacturer_type: record.manufacturer_type,
		manufacturer_id: manufacturer.id,
		manufacturer_name: manufacturer.name,
		manufacturer_tel: manufacturer.tels.length === 0 ? "" : manufacturer.tels[0].tel,
		manufacturer_fax: manufacturer.fax,
		manufacturer_email: manufacturer.email,
		warehouse_id: record.warehouse_id,
		deliver_goods_date: record.deliver_goods_date,
		order_goods_date: record.order_goods_date,
		order_id: record.order_id,
		note: record.note,
		items,
		instructions: record.instructions,
		voucher_rows: record.voucher_rows,
	};
}
