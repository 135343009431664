import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import {
	MooManufacturerLogisticsList as List,
	Query,
	useCreateStore,
} from "../components/moo-components/moo-manufacturer-logistics-list";
import { useHistoryQuery } from "../hooks/useHistoryQuery";
import useQuery from "../hooks/useQuery";
import { path as newPath } from "./NewLogisticsManufacturerRoute";
import { prefixPath } from "./UpdateLogisticsManufacturerRoute";

export const path = "/manufacturer/logistics";

export const LogisticsManufacturerListRoute = () => {
	const query = useQuery();
	const store = useCreateStore(query, { search: null, type: null, page: 0 });
	const navigate = useNavigate();
	const branch = useBranchInQuery();
	useHistoryQuery<Query>({ path, store });

	return (
		<List
			store={store}
			onAdd={async () => {
				await navigate(`${newPath}?branch=${branch}`);
			}}
			onRowClick={async (record, event) => {
				event.preventDefault();
				await navigate(`${prefixPath}/${record.id}?branch=${branch}`);
			}}
		/>
	);
};

export default LogisticsManufacturerListRoute;
