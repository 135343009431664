import { CellType, HeaderType, MooDialogChoose } from "../moo-dialog-choose";
import { orderGoods } from "../moo-enums";
import { graphQLColumns } from "../moo-order-goods-list";
import { getDateFormat } from "../moo-shared";
import { Row } from "./@types";
import { useStore } from "./stores/useStore";

export type MooDialogOrderGoodsChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
	isMultiple?: boolean;
};

const arr = [
	{ id: "id", label: "編號" },
	{ id: "sales_name", label: "業務" },
	{ id: "manufacturer_name", label: "廠商" },
	{ id: "order_goods_date", label: "訂貨日期" },
];

export const headers = arr
	.map((e) => {
		return { ...e, align: "left", style: { minWidth: 65 } };
	})
	.concat([
		{ id: "state", label: "狀態", align: "left", style: { minWidth: 65 } },
		{ id: "state_warehouse", label: "倉管確認狀態", align: "left", style: { minWidth: 120 } },
	]) as HeaderType[];

export const cells = [
	{
		id: "id",
		label: "編號",
		align: "left",
		component(row: Row) {
			return <div>{row.id}</div>;
		},
	},
	{
		id: "sales_name",
		label: "業務",
		align: "left",
		component(row: Row) {
			return <div>{row.sales || ""}</div>;
		},
	},
	{
		id: "manufacturer_name",
		label: "廠商",
		align: "left",
		component(row: Row) {
			return <div>{row.manufacturer || ""}</div>;
		},
	},
	{
		id: "order_goods_date",
		label: "訂貨日期",
		align: "left",
		component(row: Row) {
			return <div>{getDateFormat(row.order_goods_date)}</div>;
		},
	},
	{
		id: "state",
		align: "left",
		component(row: Row) {
			return <div>{orderGoods.State.getLabel(row.state)}</div>;
		},
	},

	{
		id: "state_warehouse",
		align: "left",
		component(row: Row) {
			return <div>{orderGoods.StateWarehouse.getLabel(row.state_warehouse)}</div>;
		},
	},
] as CellType[];

export function MooDialogOrderGoodsChoose({
	id,
	open,
	onClose,
	onOk,
	isMultiple = false,
}: MooDialogOrderGoodsChooseProps) {
	const store = useStore();

	return (
		<MooDialogChoose
			id={id}
			label=""
			title="訂貨單"
			resource="order_goods"
			open={open}
			onOk={(dataSet) => {
				onOk(dataSet as Row[]);
				onClose();
			}}
			onClose={onClose}
			headers={headers}
			cells={cells}
			store={store}
			graphQLColumns={graphQLColumns}
			isMultiple={isMultiple}
		/>
	);
}
