import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "employee_name",
			label: "姓名",
			align: "left",
			style: {
				minWidth: 75,
			},
		},
		{
			id: "salary_basic",
			label: "底薪",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "perfect_attendance",
			label: "全勤",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "bonus",
			label: "獎金",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "gift_money",
			label: "禮金",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "other_addition",
			label: "其他加項",
			align: "left",
			style: {
				minWidth: 130,
			},
		},
		{
			id: "addition_sum",
			label: "加項小計",
			align: "left",
			style: {
				minWidth: 130,
			},
		},
		{
			id: "labor_fee",
			label: "勞保",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "healthcare_fee",
			label: "健保",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "late",
			label: "遲到",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "pto",
			label: "請假",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "other_deduction",
			label: "其他減項",
			align: "left",
			style: {
				minWidth: 130,
			},
		},
		{
			id: "deduction_sum",
			label: "減項小計",
			align: "left",
			style: {
				minWidth: 130,
			},
		},
		{
			id: "monthly_salary",
			label: "當月薪資",
			align: "left",
			style: {
				minWidth: 130,
			},
		},
		{
			id: "bank",
			label: "帳戶",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "insurance_day",
			label: "投保天數",
			align: "left",
			style: {
				minWidth: 130,
			},
		},
		{
			id: "insurance_unit",
			label: "投保單位",
			align: "left",
			style: {
				minWidth: 130,
			},
		},
		{
			id: "note",
			label: "備註",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "edit",
			label: "",
			align: "center",
			style: {
				minWidth: 165,
			},
		},
	];
}
