import { StoreType } from "../moo-hooks";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query } from "./@types";
import { ImportGoodsList } from "./ImportGoodsList";
import { QueryContext } from "./QueryContext";

export const rowsPerPage = 10;
export const operationName = "import_goods";

export type MooImportGoodsListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooImportGoodsList = ({ onAdd, onRowClick, store }: MooImportGoodsListProps) => {
	const itemStore = store();
	const permissions = usePermissions();

	if (!permissions.includes(Permission.ImportGoodsListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<QueryContext.Provider value={{ query: itemStore.query }}>
			<ImportGoodsList store={itemStore} onAdd={onAdd} onRowClick={onRowClick} />
		</QueryContext.Provider>
	);
};
