import utils from "../utils";
import { createAjax, getAuthorizationHeader } from "./useAjax";

const useLogout = () => {
	return () => {
		const cleanUpLocalTokens = () => {
			utils.deleteRefreshToken();
			utils.setAccessToken(null);
			utils.redirectToLoginPage();
		};

		if (utils.getRefreshToken()) {
			const ajax = createAjax();
			ajax
				.post("/auth/logout", {}, { headers: getAuthorizationHeader(utils.getRefreshToken()!) })
				.finally(cleanUpLocalTokens);
		} else {
			cleanUpLocalTokens();
		}
	};
};

export default useLogout;
