import { Link as NextLink } from "react-router-dom";
import styled from "styled-components";

const A = styled(NextLink)`
	color: #222222;
	font-size: 14px;
	cursor: pointer;
	text-decoration: none;

	&:active,
	&:visited {
		color: #222222;
	}

	&:hover {
		text-decoration: underline;
	}
`;

type Props = {
	href: string;
	text: string;
};

function Link({ href, text }: Props) {
	return <A to={href}>{text}</A>;
}

export default Link;
