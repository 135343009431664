import styled from "styled-components";
import { useOnLinkClick } from "../moo-link-provider";

export type MooDanLinkProps = {
	className?: string;
	children: string | null;
	text?: string;
};

const A = styled.a`
	color: #4672e8;
	cursor: pointer;
	user-select: none;

	:hover {
		text-decoration: underline;
	}
`;

export function MooDanLink({ children, className = "", text }: MooDanLinkProps) {
	const linkClick = useOnLinkClick();
	if (children === null) {
		return null;
	}

	return (
		<A
			className={className}
			title={children}
			onClick={(e: any) => {
				e.preventDefault();
				e.stopPropagation();
				linkClick(children);
			}}
		>
			{text ? text : children}
		</A>
	);
}
