import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooEmployeeList, Query, useCreateStore } from "../components/moo-components/moo-employee-list";
import { useHistoryQuery } from "../hooks/useHistoryQuery";
import usePage from "../hooks/usePage";
import useURLSearchParams from "../hooks/useURLSearchParams";
import Utils from "../utils";
import { path as NewEmployeeRoutePath } from "./NewEmployeeRoute";
import { prefixPath } from "./UpdateEmployeeRoute";

export const path = "/employee";

type Status = "PRESENT" | "ARCHIVED" | null;
const useQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();

	return {
		search: urlSearchParams.get("search") || null,
		type: urlSearchParams.get("type") || null,
		status: (urlSearchParams.get("status") || null) as Status,
		page,
	};
};

const EmployeeListRoute = () => {
	const query = useQuery();
	const store = useCreateStore(query, { search: null, type: null, status: null, page: 0 });
	const branch = useBranchInQuery();
	const navigate = useNavigate();
	useHistoryQuery<Query>({ path, store });

	return (
		<MooEmployeeList
			store={store}
			onAdd={async () => {
				await navigate(`${NewEmployeeRoutePath}?branch=${branch}`);
			}}
			onActivateEmployee={() => {
				window.alert("復原成功！");
				Utils.refreshPage();
			}}
			onRowClick={async (employee, event) => {
				event.preventDefault();
				await navigate(`${prefixPath}/${employee.id}?branch=${branch}`);
			}}
		/>
	);
};

export default EmployeeListRoute;
