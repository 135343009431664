import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { DEFAULT_ONE, MooReturnOrderOne, One } from "../moo-return-order-one";
import { useHasuraAdd } from "../moo-shared";

export type MooReturnOrderAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooReturnOrderAdd: FC<MooReturnOrderAddProps> = ({ onCancel, onSuccess }) => {
	const permissions = usePermissions();
	const onAdd = useHasuraAdd<One>("return_orders", onSuccess, (e) => {
		return produce(e, (draft) => {
			// @ts-ignore
			delete draft.order_type_name;
			// @ts-ignore
			delete draft.order_customer_name;
			// @ts-ignore
			delete draft.order_sales_name;
			// @ts-ignore
			delete draft.order_sales_tel;
			// @ts-ignore
			delete draft.order_deliver_date;
			// @ts-ignore
			delete draft.order_is_include_tax;
			// @ts-ignore
			delete draft.order_payee_name;
			// @ts-ignore
			delete draft.order_payee_bank_name;
			// @ts-ignore
			delete draft.order_payee_account_number;
			// @ts-ignore
			delete draft.order_deliver_address;
			// @ts-ignore
			delete draft.order_description;
			// @ts-ignore
			delete draft.order_order_note;
			draft.items.forEach((item, index) => {
				// @ts-ignore
				draft.items[index] = {
					id: item.id,
					return_order_item_id: item.return_order_item_id,
					count: item.count,
					price_final: item.price_final,
				};
			});
		});
	});

	return (
		<MooReturnOrderOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.ReturnOrderAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
