import * as _ from "lodash";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { ListSelectField } from "../moo-list-select-field";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query } from "./@types";
import { useCells } from "./hooks/useCells";
import { useConditions } from "./hooks/useConditions";
import { useHeaders } from "./hooks/useHeaders";

export type MooManufacturerForeignListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

const resource = "manufacturer_foreigns";

export const MooManufacturerForeignList = ({ onAdd, onRowClick, store }: MooManufacturerForeignListProps) => {
	const itemStore = store();
	const cells = useCells(resource, itemStore);
	const headers = useHeaders();
	const permissions = usePermissions();
	const conditions = useConditions(itemStore.query);

	if (!permissions.includes(Permission.ManufacturerForeignListRead)) {
		return <MooNoAuthPage />;
	}

	const { query, setQuery } = itemStore;

	return (
		<MooList
			showAddButton={permissions.includes(Permission.ManufacturerForeignAdd)}
			resource={resource}
			conditions={conditions}
			graphQLColumns={`{
				id
				number
				manufacturer_foreign_type {
					name
				}
				tels{
					tel
				}
				fax
				name
				email
				country
				currency
			}`}
			mapData={(e) => {
				return {
					...e,
					type: _.get(e, "manufacturer_foreign_type.name", ""),
					tel: _.get(e, "tels.0.tel", ""),
				};
			}}
			addLabel="新增廠商"
			onAdd={onAdd}
			filterNode={
				<>
					<ListSelectField
						id="manufacturer_foreign_types"
						resource="manufacturer_foreign_types"
						label="類型"
						value={query.type}
						onChange={(value) => setQuery({ type: value as string, page: 0 })}
					/>
				</>
			}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
		/>
	);
};
