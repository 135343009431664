import { useFormikContext } from "formik";
import produce from "immer";
import { MooItemRowList } from "../../../moo-item-row-list";
import { FieldType as SpecCustomField } from "../../../moo-item-spec-and-custom-row";
import { ItemBound } from "../../../moo-types";
import { Item, One } from "../../@types/one";
import { Calculator } from "../../Calculator";

function itemsToItemBounds(items: Item[]): ItemBound[] {
	return items.map((e) => {
		const itemBound: ItemBound = {
			id: e.id,
			manufacturer_type: e.manufacturer_type,
			manufacturer_id: e.manufacturer_id,
			manufacturer: e.manufacturer,
			category: e.category,
			type: e.type,
			name: e.name,
			cost: e.cost,
			spec: e.spec,
			material: e.material,
			color: e.color,
			size: e.size,
			count: e.count,
			price: e.price,
			price_final: e.price_final,
			price_total: e.price_total,
			purchase_price: e.purchase_price,
			purchase_price_total: e.purchase_price * e.count,
			note: e.note,
			image: e.image,
			image_cut: e.image_cut,
			priority: e.priority,
		};
		return itemBound;
	});
}

export function ItemList() {
	const helpers = useFormikContext<One>();
	const { values, setValues } = helpers;

	return (
		<MooItemRowList
			emptyMessage="尚未匯入報價商品"
			items={itemsToItemBounds(values.items)}
			onCountChange={function updateItemCount(id, count) {
				helpers.setValues(
					Calculator.syncPrices(
						produce(values, (draft) => {
							const index = draft.items.findIndex((e) => e.id === id);
							if (index !== -1) {
								draft.items[index].count = count;
							}
							return draft;
						}),
					),
				);
			}}
			onNoteChange={function updateItemNote(id, note) {
				setValues({
					...values,
					...produce(values, (draft) => {
						const index = draft.items.findIndex((e) => e.id === id);
						if (index !== -1) {
							draft.items[index].note = note;
						}
						return draft;
					}),
				});
			}}
			onPriceFinalChange={function updatePriceFinal(id, priceFinal) {
				helpers.setValues(
					Calculator.syncPrices(
						produce(values, (draft) => {
							const index = draft.items.findIndex((e) => e.id === id);
							if (index !== -1) {
								draft.items[index].price_final = priceFinal;
							}
							return draft;
						}),
					),
				);
			}}
			onRemove={function deleteItem(id) {
				helpers.setValues(
					Calculator.syncPrices(
						produce(values, (draft) => {
							const index = draft.items.findIndex((e) => e.id === id);
							if (index !== -1) {
								draft.items.splice(index, 1);
							}
							return draft;
						}),
					),
				);
			}}
			specCustomFields={
				new Set<SpecCustomField>([
					"id",
					"name",
					"type",
					"category",
					"spec",
					"count",
					"price",
					"price_final",
					"purchase_price",
					"cost",
					"price_total",
					"note",
					"remove",
				])
			}
		/>
	);
}
