import { object, SchemaOf } from "yup";
import { stringOptional, stringRequired } from "../../moo-schemas";
import { Account } from "../@types/domestic-manufacturer";

// @ts-ignore
export const accountSchema: SchemaOf<Account> = object().shape({
	manufacturer_domestic_account_id: stringRequired("manufacturer_domestic_account_id"),
	bank_account: stringOptional,
	bank: stringOptional,
	bank_account_number: stringOptional,
	tax_id: stringOptional,
});
