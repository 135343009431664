import { object, SchemaOf } from "yup";
import { quotation } from "../../moo-enums";
import {
	arrayOptionalSchema,
	enumRequired,
	items,
	numberOptional,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { signedFileSchema } from "./signedFileSchema";
import { voucherRowSchema } from "./voucherRowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	employee_id: stringOptional,
	status: statusSchema,
	state: enumRequired(quotation.State.Value),
	state_manager: enumRequired(quotation.StateManager.Value),
	state_accountant: enumRequired(quotation.StateAccountant.Value),
	quotation_date: stringRequired("quotation_date"),
	type_id: stringRequired("type_id"),
	type_name: stringOptional,
	customer_id: stringRequired("customer_id"),
	customer_name: stringOptional,
	sales_id: stringRequired("sales_id", "請選擇業務"),
	sales_name: stringOptional,
	sales_tel: stringOptional,
	deliver_date: stringOptional,
	tax: numberOptional,
	payee_id: stringRequired("payee_id"),
	payee_name: stringOptional,
	payee_bank: stringOptional,
	payee_account_number: stringOptional,
	deliver_address: stringOptional,
	items,
	price_total: numberOptional,
	price_discounted: numberOptional,
	note: stringOptional,
	deposit: numberOptional,
	cost: numberOptional,
	remaining_fee: numberOptional,
	memo: stringOptional,
	signed_files: arrayOptionalSchema(signedFileSchema),
	voucher_rows: arrayOptionalSchema(voucherRowSchema),
	create_date: stringOptional,
	update_date: stringOptional,
});
