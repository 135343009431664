import { MooGqlTable } from "../../moo-gql";
import { ReturnOrder } from "../findOne/ReturnOrder";
import { queryField } from "../findOne/query";

export class ReturnOrderTable extends MooGqlTable<ReturnOrder> {
	isColumn(column: string): boolean {
		switch (column) {
			case "note":
			case "tax":
			case "note_price":
			case "return_date":
			case "state_accountant":
			case "order_id":
				return true;
		}
		return false;
	}

	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "return_orders";
	}
}
