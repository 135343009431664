import { MooStockTransferOne, One } from "../moo-stock-transfer-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { ItemsTable } from "./updateOne/ItemsTable";
import { StockTransferTable } from "./updateOne/StockTransferTable";

export type MooStockTransferUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooStockTransferUpdate({ id, onCancel }: MooStockTransferUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="stock-transfer-one"
			query={query}
			tableName="stock_transfers"
			mapperInitialValues={mapperInitialValues}
			//@ts-ignore
			Form={MooStockTransferOne}
			tables={[ItemsTable, StockTransferTable]}
		/>
	);
}
