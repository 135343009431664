import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as OrderGoodsListRoutePath } from "../../../routes/order_goods/ListRoute";
import { path as OrderGoodsNewRoutePath } from "../../../routes/order_goods/NewRoute";
import { path as OrderGoodsUpdateRoutePath } from "../../../routes/order_goods/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useProduces(): ListType[] {
	const permissions = usePermissions();
	const historyPushWithBranch = useHistoryPushWithBranch();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();

	if (!permissions.includes(Permission.OrderGoodsListRead)) {
		return [];
	}

	return [
		{
			id: "produce",
			txt: "國內採購",
			icon: Utils.getAbsoluteStaticUrl("icons/produce.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleProduce();
			},
			open: true,
			openChildren: navigationStore.produce,
			children: [
				{
					id: OrderGoodsListRoutePath,
					txt: getPageTree()[OrderGoodsListRoutePath].useText(),
					icon: "",
					isSelected: isSelected([OrderGoodsListRoutePath, OrderGoodsNewRoutePath, OrderGoodsUpdateRoutePath]),
					async onClick() {
						await historyPushWithBranch(OrderGoodsListRoutePath);
					},
				},
			],
		},
	];
}
