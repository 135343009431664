import { useFormikContext } from "formik";

export function useGetFieldError<T>() {
	const { errors } = useFormikContext<T>();

	return (field: keyof T): string => {
		// @ts-ignore
		return errors[field] || "";
	};
}
