import type { CellType } from "@yachelee/koden-components/koden-table";
import { DateFormat } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	return [
		{
			id: "start_date",
			align: "left",
			component: ({ start_date }: Row) => {
				return <DateFormat>{start_date}</DateFormat>;
			},
		},
		{
			id: "end_date",
			align: "left",
			component: ({ end_date }: Row) => {
				return <DateFormat>{end_date}</DateFormat>;
			},
		},
		{
			id: "content",
			align: "left",
			component: ({ content }: Row) => {
				return <>{content}</>;
			},
		},
		{
			id: "message_count",
			align: "left",
			component: ({ message_count }: Row) => {
				return <div>{message_count}</div>;
			},
		},
	];
}
