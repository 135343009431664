import { useGetGcsPresignedUrl } from "@yachelee/koden-components/koden-gcs-presigned-url-provider";
import * as axios from "axios";
import { uuidv4 } from "../../moo-shared";

export type Props = {
	file: File;
	onUploading: (object: string, progress: number) => void;
	onUploaded: (object: string) => void;
};

export function useUploadToGcs() {
	const getGcsPresignedUrl = useGetGcsPresignedUrl();

	return async ({ file, onUploading, onUploaded }: Props) => {
		const object = uuidv4();
		const contentType = file.type;
		const { url: presignedUrl } = await getGcsPresignedUrl({ object, contentType });
		await axios.default.put(presignedUrl, file, {
			headers: {
				"Content-Type": file.type,
			},
			onUploadProgress(e) {
				if (e.total !== undefined) {
					const percent = Math.round((e.loaded / e.total) * 100);
					onUploading(object, percent);
				}
			},
		});
		onUploaded(object);
	};
}
