export enum LinkType {
	Quotation = "quotations",
	Order = "orders",
	ImportGoods = "import_goods",
	ExportGoods = "export_goods",
	PurchaseOrder = "purchase_orders",
	RequestBuy = "request_buys",
	ReturnGoods = "return_goods",
	OrderGoods = "order_goods",
}

export type Query = {
	type: LinkType;
	search: string | null;
	page: number;
};

export type Row = {
	id: string;
};
