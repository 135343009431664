export class Calculator {
	static getAccountantTotalPrice(items: { purchase_price_total: number }[]): number {
		return items.map((e) => e.purchase_price_total).reduce((a, b) => a + b, 0);
	}

	static getAccountantPayable(one: {
		accountant_total_price: number;
		accountant_tax: number;
		accountant_freight: number;
		accountant_miscellaneous_charge: number;
		accountant_received: number;
	}): number {
		return (
			one.accountant_total_price +
			one.accountant_tax +
			one.accountant_freight +
			one.accountant_miscellaneous_charge -
			one.accountant_received
		);
	}
}
