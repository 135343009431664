import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { Formik } from "formik";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { useOrderDialogChoose, useStore as useChooseStore } from "../moo-dialog-order-choose";
import { returnGoods } from "../moo-enums";
import { MooLinkButton } from "../moo-link-button";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { useGetFieldError } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { MooUseIsEditContext } from "../moo-use-is-edit";
import { MooVoucherLinkList } from "../moo-voucher-link-list";
import { One } from "./@types/one";
import { Props } from "./@types/props";
import ItemList from "./components/ItemList";
import { PdfButton } from "./components/PdfButton";
import SummaryArea from "./components/SummaryArea/SummaryArea";
import { useChooseOrder } from "./hooks/useChooseOrder";
import { useForm } from "./hooks/useForm";
import { useLockStock } from "./hooks/useLockStock";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.form`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

export const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	max-width: ${WEB_BREAKPOINT};
	margin-bottom: 30px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const FileUpload = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 0.3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.6rem;
	align-items: center;
	padding: 14px;
	min-height: 56px;
	text-align: center;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto 1fr;
		text-align: left;
		padding: 0 14px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

export function Form({
	id,
	isEdit = false,
	isCancelDisabled,
	isOkDisabled,
	onCancel,
}: Omit<Props, "initialValues" | "onSubmit">) {
	const { component: orderDialog, open: openOrderDialog } = useOrderDialogChoose(
		"",
		async (rows) => {
			await chooseOrder(rows);
			clearQuotationChoose();
		},
		false,
	);
	const { clearChoose: clearQuotationChoose } = useChooseStore();
	const { values, setFieldValue, handleSubmit } = useForm();
	const getFieldError = useGetFieldError<One>();
	const lockStock = useLockStock();
	const chooseOrder = useChooseOrder();
	const orderImported = values.order_id !== null;

	return (
		<>
			{orderDialog}
			<Container onSubmit={handleSubmit}>
				{isEdit && (
					<FieldContainer>
						<MooTypeableSelect
							id="state_warehouse"
							label="倉庫狀態"
							value={values.state_warehouse}
							disabled={lockStock}
							error={getFieldError("state_warehouse")}
							options={[
								{ value: null, label: "請選擇" },
								{
									label: returnGoods.StateWarehouse.Label.WaitToEnter,
									value: returnGoods.StateWarehouse.Value.WaitToEnter,
								},
								{
									label: returnGoods.StateWarehouse.Label.Entered,
									value: returnGoods.StateWarehouse.Value.Entered,
								},
							]}
							onChange={(value) => {
								setFieldValue("state_warehouse", value);
							}}
						/>
						<MooTypeableSelect
							id="state_accountant"
							label="會計狀態"
							value={values.state_accountant}
							error={getFieldError("state_accountant")}
							options={[
								{ value: null, label: "請選擇" },
								{
									label: returnGoods.StateAccountant.Label.WaitToRefund,
									value: returnGoods.StateAccountant.Value.WaitToRefund,
								},
								{
									label: returnGoods.StateAccountant.Label.Refunded,
									value: returnGoods.StateAccountant.Value.Refunded,
								},
							]}
							onChange={(value) => {
								setFieldValue("state_accountant", value);
							}}
						/>
					</FieldContainer>
				)}
				<FieldContainer>
					<KodenDateField
						id="return_date"
						label="退貨日期"
						error={getFieldError("return_date")}
						value={values.return_date === null ? null : new Date(values.return_date)}
						onChange={(value) => {
							setFieldValue(
								"return_date",
								value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
							);
						}}
					/>
					<FileUpload>
						<KodenButton
							onClick={() => {
								clearQuotationChoose();
								openOrderDialog();
							}}
						>
							{orderImported ? "重匯訂購單" : "匯入訂購單"}
						</KodenButton>
						<MooLinkButton id={values.order_id} name="訂購單" />
					</FileUpload>
				</FieldContainer>
				{orderImported && (
					<>
						<FieldContainer>
							<MooTypeableSelect
								id="type_name"
								label="類型"
								value={values.type_name}
								error=""
								options={[
									{ value: null, label: "請選擇" },
									{ value: values.type_name, label: values.type_name },
								]}
								disabled
								onChange={() => {}}
							/>
							<MooTypeableSelect
								id="customer_name"
								label="客戶"
								value={values.customer_name}
								error=""
								options={[
									{ value: null, label: "請選擇" },
									{ value: values.customer_name, label: values.customer_name },
								]}
								disabled
								onChange={() => {}}
							/>
							<MooTypeableSelect
								id="sales_name"
								label="業務"
								value={values.sales_name || null}
								options={[
									{ value: null, label: "請選擇" },
									{ value: values.sales_name, label: values.sales_name },
								]}
								error=""
								disabled
								onChange={() => {}}
							/>
							<ProxyKodenTextField
								id="sales_tel"
								label="業務電話"
								outlined
								margin="none"
								value={values.sales_tel}
								readOnly
								disabled
								error=""
							/>
							<KodenDateField
								id="deliver_date"
								label="交貨日期"
								value={values.deliver_date === null ? null : new Date(values.deliver_date)}
								error=""
								readOnly
								disabled
								onChange={() => {}}
							/>
							<MooTypeableSelect
								id="is_include_tax"
								label="稅金"
								value={values.is_include_tax?.toString() || null}
								error={getFieldError("is_include_tax")}
								disabled
								options={[
									{ value: null, label: "請選擇" },
									{ label: "否", value: "false" },
									{ label: "是", value: "true" },
								]}
								onChange={() => {}}
							/>
							<MooTypeableSelect
								id="payee_name"
								label="收款人"
								value={values.payee_name}
								options={[
									{ value: null, label: "請選擇" },
									{ value: values.payee_name, label: values.payee_name },
								]}
								error=""
								disabled
								onChange={() => {}}
							/>
							<ProxyKodenTextField
								id="payee_bank_name"
								label="收款銀行"
								outlined
								margin="none"
								value={values.payee_bank}
								readOnly
								disabled
								error=""
							/>
							<ProxyKodenTextField
								id="payee_account_number"
								label="收款帳號"
								outlined
								margin="none"
								value={values.payee_account_number}
								readOnly
								disabled
								error=""
							/>
							<ProxyKodenTextField
								id="deliver_address"
								label="配送地址"
								outlined
								margin="none"
								value={values.deliver_address}
								error=""
								readOnly
								disabled
								onChange={() => {}}
							/>
							<ProxyKodenTextField
								id="description"
								label="訂單簡述"
								outlined
								margin="none"
								value={values.description}
								error=""
								disabled
								onChange={() => {}}
							/>
							<ProxyKodenTextField
								id="order_note"
								label="備註"
								outlined
								margin="none"
								value={values.order_note}
								error=""
								disabled
								onChange={() => {}}
							/>
						</FieldContainer>
						<MooVoucherLinkList values={values.voucher_rows.map((e) => e.voucher_id)} />
						<ItemList />
						<SummaryArea />
					</>
				)}
				<ButtonContainer>
					{isEdit && id && <PdfButton id={id} />}
					{!isOkDisabled && (
						<>
							<KodenButton
								style={{ marginRight: "1rem" }}
								disabled={isCancelDisabled}
								onClick={async () => {
									if (window.confirm("確定取消並且回到上一頁？")) {
										onCancel();
									}
								}}
							>
								取消
							</KodenButton>
							<MooSubmitButton permission={Permission.ReturnGoodsUpdate} />
						</>
					)}
				</ButtonContainer>
			</Container>
		</>
	);
}

export function MooReturnGoodsOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.ReturnGoodsRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<MooUseIsEditContext.Provider value={{ isEdit: props.isEdit || false }}>
				<Form {...props} />
			</MooUseIsEditContext.Provider>
		</Formik>
	);
}
