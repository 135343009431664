import { object, SchemaOf } from "yup";
import { deliverMethod, purchaseOrder } from "../../moo-enums";
import {
	arrayOptionalSchema,
	arrayRequiredSchema,
	enumRequired,
	numberOptional,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { fileSchema, manufacturerFileSchema, otherFileSchema } from "./fileSchema";
import { itemSchema } from "./itemSchema";
import { requestBuyIdSchema } from "./requestBuyIdSchema";
import { voucherRowSchema } from "./voucherRowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	create_date: stringOptional,
	update_date: stringOptional,
	status: statusSchema,
	state: enumRequired(purchaseOrder.State.Value),
	state_manager: enumRequired(purchaseOrder.StateManager.Value),
	state_accountant: enumRequired(purchaseOrder.StateAccountant.Value),
	purchase_date: stringRequired("purchase_date"),
	deliver_date: stringRequired("deliver_date"),
	request_buy_ids: arrayOptionalSchema(requestBuyIdSchema),
	owner_id: stringRequired("owner_id"),
	foreign_manufacturer_id: stringRequired("foreign_manufacturer_id"),
	foreign_manufacturer_tel: stringOptional,
	foreign_manufacturer_fax: stringOptional,
	foreign_manufacturer_email: stringOptional,
	foreign_manufacturer_country: stringOptional,
	foreign_manufacturer_currency: stringOptional,
	foreign_manufacturer_bank_account: stringOptional,
	foreign_manufacturer_swift_code: stringOptional,
	foreign_manufacturer_code_iban: stringOptional,
	deliver_method: enumRequired(deliverMethod.Value.Value),
	warehouse_id: stringRequired("warehouse_id"),
	note: stringOptional,
	items: arrayRequiredSchema(itemSchema, "items", "尚未匯入採購商品"),
	files: arrayOptionalSchema(fileSchema),
	manufacturer_files: arrayOptionalSchema(manufacturerFileSchema),
	other_files: arrayOptionalSchema(otherFileSchema),
	accountant_tax: numberOptional,
	accountant_total_price: numberOptional,
	accountant_received: numberOptional,
	accountant_freight: numberOptional,
	accountant_declare_fee: numberOptional,
	accountant_pickup_charge: numberOptional,
	accountant_miscellaneous_charge: numberOptional,
	accountant_note: stringOptional,
	voucher_rows: arrayOptionalSchema(voucherRowSchema),
	mgt_fee: numberOptional,
});
