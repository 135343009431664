import { ItemType } from "../../moo-enums";
import useStore from "../stores/useStore";

export function useGraphQLAggregateName(): string {
	const {
		query: { type },
	} = useStore();
	switch (type) {
		case ItemType.Spec:
			return "item_specs_aggregate";
		case ItemType.Custom:
			return "item_customs_aggregate";
		case ItemType.Bundle:
			return "item_bundles_aggregate";
	}
}
