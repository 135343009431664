import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";
import { DEFAULT_ONE, MooSvcOne, One } from "../moo-svc-one";

export type MooSvcAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooSvcAdd: FC<MooSvcAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("svcs", onSuccess, (e) => {
		return produce(e, (draft) => {
			// @ts-ignore
			delete draft.customer_name;
			// @ts-ignore
			delete draft.sales_name;
			// @ts-ignore
			delete draft.owner_name;
			// @ts-ignore
			delete draft.driver_tel;
		});
	});
	const permissions = usePermissions();

	return (
		<MooSvcOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.SvcAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
