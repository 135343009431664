import { useBranch } from "../../moo-branch-provider";
import { Permission } from "../permissions";
import { useMe } from "./useMe";

export function usePermissions(): Permission[] {
	const me = useMe();
	const { value: branch } = useBranch();
	if (me === null) return [];

	return me.role.permissions.filter((e) => e.branch_id === branch).map((e) => e.permission) || [];
}
