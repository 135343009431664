import produce from "immer";
import { getTotalPrice } from "../getTotalPrice";
import { useForm } from "./useForm";

export function useUpdateItemReturnCount() {
	const { values, setValues } = useForm();

	return (id: string, value: number) => {
		const newValues = produce(values, (one) => {
			const item = one.items.find((e) => e.id === id);
			if (item) {
				item.return_count = value;
				item.price_total = item.price_final * item.return_count;
			}

			one.total_price = getTotalPrice(one);
		});

		setValues(newValues);
	};
}
