export enum Value {
	UnSettled = "UNSETTLED",
	Settled = "SETTLED",
}

export enum Label {
	UnSettled = "未結清",
	Settled = "結清",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.UnSettled:
			return Label.UnSettled;
		case Value.Settled:
			return Label.Settled;
	}
	return Label.UnSettled;
}
