import { MooGqlChildNewTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";

export class Drivers extends MooGqlChildNewTable<FindOneRecord> {
	getIdName(): string {
		return "manufacturer_logistic_driver_id";
	}

	getTableName(): string {
		return "manufacturer_logistic_drivers";
	}

	getPathName(): string {
		return "drivers";
	}

	getParentIdName(): string {
		return "manufacturer_logistic_id";
	}

	getToUpdateColumns(): string[] {
		return ["name", "tel", "line"];
	}
}
