import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    state
    type
    export_date
    order_id
    type_id
    quotation_type {
      id
      name
    }
    customer {
      id
      name
    }
    sales {
      id
      name
      tel
    }
    address
    order_description
    order_note
    driver {
      manufacturer_logistic_driver_id
      name
      tel
    }
    manufacturer_logistic {
      id
      name
    }
    export_goods_note
    shipping_fee
    voucher_rows {
      voucher_id
    }
    items {
      id
      export_good_item_id
      stocks {
        export_good_item_stock_id
        count
        warehouse_id
      }
      item_spec {
        name
        material
        color
        size
        image
        image_cut
        original_code
        purchase_price
        manufacturer {
          id
          name
          type
        }
      }
      item_custom {
        name
        material
        color
        size
        image
        image_cut
        original_code
        purchase_price
        manufacturer {
          id
          name
          type
        }
      }
    }
`;

export const query = gql`
    query ($id: String!) {
        export_goods_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
