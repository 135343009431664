import produce from "immer";
import { Row } from "../../moo-dialog-choose";
import { Row as ItemRow, SpecOrCustomTableRow } from "../../moo-dialog-item-choose";
import { ChooseType } from "../../moo-dialog-order-goods-and-purchase-goods-choose";
import { useFindEmployeeById } from "../../moo-employee-one";
import { importGoods, manufacturer } from "../../moo-enums";
import { useRequestById as useFindManufacturerDomesticById } from "../../moo-manufacturer-domestic-update";
import { useRequestById as useFindManufacturerForeignById } from "../../moo-manufacturer-foreign-update";
import { Calculator as QuotationCalculator } from "../../moo-quotation-one";
import { attachItems, getModelItemId, uuidv4 } from "../../moo-shared";
import { Item, One } from "../@types/one";
import { Calculator } from "../Calculator";
import { Result as OrderGoodsResult, useFindOrderGoodsById } from "./useFindOrderGoodsById";
import { Result, useFindPurchaseOrderById } from "./useFindPurchaseOrderById";
import { useForm } from "./useForm";

export function useUpdate() {
	const { initialValues, values, setValues } = useForm();
	const findManufacturerForeignById = useFindManufacturerForeignById();
	const findManufacturerDomesticById = useFindManufacturerDomesticById();
	const findEmployeeById = useFindEmployeeById();
	const findPurchaseOrderById = useFindPurchaseOrderById();
	const findOrderGoodsById = useFindOrderGoodsById();

	function syncPrice(one: One) {
		one.accountant_total_price = Calculator.getAccountantTotalPrice(one.items);
		one.accountant_payable = Calculator.getAccountantPayable(one);
	}

	function chooseItems(rows: ItemRow[]) {
		setValues(
			produce(values, (one: One) => {
				one.items = attachItems(
					one.items,
					rows.map((item: SpecOrCustomTableRow, index) => ({
						import_good_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: item.id }),
						id: item.id,
						manufacturer_type: item.manufacturer_type,
						manufacturer_id: item.manufacturer_id,
						manufacturer: item.manufacturer,
						name: item.name,
						material: item.material,
						color: item.color,
						size: item.size,
						purchase_price: item.purchase_price,
						image: item.image,
						image_cut: item.image_cut,
						purchase_price_total: item.purchase_price,
						count: 1,
						priority: index,
					})),
				);
				syncPrice(one);
			}),
		);
	}

	function updateItemCount(id: string, count: number) {
		setValues(
			produce(values, (one: One) => {
				one.items.forEach((purchaseItem) => {
					if (purchaseItem.id === id) {
						purchaseItem.count = count;
						purchaseItem.purchase_price_total = purchaseItem.purchase_price * count;
					}
				});
				syncPrice(one);
			}),
		);
	}

	function deleteItem(id: string) {
		setValues(
			produce(values, (one: One) => {
				const index = one.items.findIndex((e) => e.id === id);
				if (index !== -1) {
					one.items.splice(index, 1);
				}
				syncPrice(one);
			}),
		);
	}

	async function updateSalesId(id: string) {
		const employee = await findEmployeeById(id);

		setValues(
			produce(values, (one: One) => {
				one.sales_id = id;
				one.sales_name = employee.name;
				one.sales_tel = employee.tel;
			}),
		);
	}

	function updateManufacturerType(value: manufacturer.Value.Value) {
		setValues(
			produce(values, (one: One) => {
				one.manufacturer_type = value;
				one.manufacturer_id = null;
				one.manufacturer = null;
			}),
		);
	}

	async function updateManufacturerId(id: string) {
		const record =
			values.manufacturer_type === manufacturer.Value.Value.Domestic
				? (await findManufacturerDomesticById(id)).manufacturer_domestics_by_pk
				: (await findManufacturerForeignById(id)).manufacturer_foreigns_by_pk;
		setValues(
			produce(values, (one: One) => {
				one.manufacturer_id = id;
				one.manufacturer = record.name;
			}),
		);
	}

	async function link(chooseType: ChooseType, rows: Row[]) {
		if (rows.length >= 1) {
			const isOrderGoods = chooseType === ChooseType.OrderGoods;
			const id = rows[0].id;
			const data = isOrderGoods ? await findOrderGoodsById(id) : await findPurchaseOrderById(id);
			setValues(
				produce(values, (one: One) => {
					one.link_id = id;
					one.type = isOrderGoods ? importGoods.Type.OrderGoods : importGoods.Type.PurchaseOrder;

					if (isOrderGoods) {
						const orderGoods = data as OrderGoodsResult;
						one.sales_id = orderGoods.sales.id;
						one.sales_tel = orderGoods.sales.tel;
						one.manufacturer_type = orderGoods.manufacturer.type;
						one.manufacturer_id = orderGoods.manufacturer.id;
						one.manufacturer = orderGoods.manufacturer.name;
						one.warehouse_id = orderGoods.warehouse_id;
						one.order_ids = [orderGoods.order_id]
							.filter((e) => e !== "")
							.map((value) => ({ import_good_order_id_id: uuidv4(), value }));
						one.items = orderGoods.items.map(({ id, item_spec, item_custom }, priority) => {
							const item = item_spec || item_custom;

							return {
								import_good_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: id }),
								id,
								manufacturer_type: item.manufacturer.type,
								manufacturer_id: item.manufacturer.id,
								manufacturer: item.manufacturer.name,
								name: item.name,
								material: item.material,
								color: item.color,
								size: item.size,
								image: item.image,
								image_cut: item.image_cut,
								count: 1,
								priority,
								purchase_price: item.purchase_price,
								purchase_price_total: item.purchase_price,
							};
						});
					} else {
						const purchaseOrder = data as Result;

						let request_buy_order_ids: string[] = [];
						purchaseOrder.request_buy_ids.forEach(({ request_buy }) => {
							request_buy.order_ids.forEach((e) => {
								if (e.value !== null) {
									request_buy_order_ids.push(e.value);
								}
							});
						});
						request_buy_order_ids = Array.from(new Set(request_buy_order_ids));

						one.order_ids = request_buy_order_ids.map((request_buy_order_id) => ({
							import_good_order_id_id: uuidv4(),
							value: request_buy_order_id,
						}));

						one.sales_id = purchaseOrder.owner.id;
						one.sales_tel = purchaseOrder.owner.tel;
						one.manufacturer_type = manufacturer.Value.Value.Foreign;
						one.manufacturer_id = purchaseOrder.foreign_manufacturer.id;
						one.manufacturer = purchaseOrder.foreign_manufacturer.name;
						one.warehouse_id = purchaseOrder.warehouse_id;
						one.items = purchaseOrder.items.map((purchaseOrderItem, priority) => {
							const item = purchaseOrderItem.item_custom || purchaseOrderItem.item_spec;
							const itemId = purchaseOrderItem.id;
							const count = 1;
							const purchase_price = item.purchase_price;

							return {
								import_good_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: itemId }),
								id: itemId,
								manufacturer_type: item.manufacturer.type,
								manufacturer_id: item.manufacturer.id,
								manufacturer: item.manufacturer.name,
								name: item.name,
								material: item.material,
								color: item.color,
								size: item.size,
								count,
								image: item.image,
								image_cut: item.image_cut,
								priority,
								purchase_price,
								purchase_price_total: QuotationCalculator.getItemPurchasePriceTotal({ count, purchase_price }),
							} as Item;
						});
					}
					syncPrice(one);
				}),
			);
		}
	}

	return {
		chooseItems,
		updateItemCount,
		deleteItem,
		updateSalesId,
		updateManufacturerType,
		updateManufacturerId,
		link,
	};
}
