import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { Status } from "../../components/moo-components/moo-enums";
import { MooVoucherList, Query, useCreateStore } from "../../components/moo-components/moo-voucher-list";
import { StoreProvider } from "../../components/moo-components/moo-voucher-list/hooks/useStore";
import { useUrlQuery } from "../../components/moo-components/moo-voucher-list/hooks/useUrlQuery";
import { useHistoryQuery } from "../../hooks/useHistoryQuery";
import { path as NewRoutePath } from "./NewRoute";
import { prefixPath as UpdateRoutePath } from "./UpdateRoute";

export const path = "/voucher";

export const ListRoute = () => {
	const urlQuery = useUrlQuery();
	const store = useCreateStore(urlQuery, {
		search: null,
		page: 0,
		account_type: null,
		manufacturer_type: null,
		manufacturer_id: null,
		from_date: null,
		to_date: null,
		status: Status.Present,
	});
	const branch = useBranchInQuery();
	const navigate = useNavigate();
	useHistoryQuery<Query>({ path, store });
	const storeValue = store();

	return (
		<StoreProvider value={storeValue}>
			<MooVoucherList
				onAdd={async () => {
					await navigate(`${NewRoutePath}?branch=${branch}`);
				}}
				onRowClick={async ({ id }) => {
					await navigate(`${UpdateRoutePath}/${id}?branch=${branch}`);
				}}
			/>
		</StoreProvider>
	);
};

export default ListRoute;
