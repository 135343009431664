import styled from "@emotion/styled";
import { FC } from "react";
import { Instructions } from "./Instructions";
import UploadButton from "./UploadButton";

const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	background-color: #f2f3f5;
	display: grid;
	grid-gap: 0.5rem;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		". ."
		"file file";
	align-items: center;
	padding: 1.5rem;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto auto 1fr;
		grid-template-areas: ". . file";
	}
`;

export const FileUploadArea: FC = () => {
	return (
		<Container>
			<div style={{ marginRight: ".5rem" }}>訂貨單說明書</div>
			<UploadButton />
			<Instructions />
		</Container>
	);
};

export default FileUploadArea;
