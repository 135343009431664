import { useGetGcsUrl } from "@yachelee/koden-components/koden-gcs-provider";

export type MooFileLinkProps = {
	fileName: string;
};

export function MooFileLink({ fileName }: MooFileLinkProps) {
	const getGcsUrl = useGetGcsUrl();

	return (
		<a href={getGcsUrl(fileName)} target="_blank" rel="noreferrer">
			{fileName}
		</a>
	);
}
