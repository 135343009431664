import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import { Formik, useFormikContext } from "formik";
import { useState } from "react";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { useFindCustomerById } from "../moo-customer-one";
import { MooDialogCustomerChoose, useStore as useCustomerChooseStore } from "../moo-dialog-customer-choose";
import { useFindEmployeeById } from "../moo-employee-one";
import { MooEmployeeSelectField } from "../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { quotation } from "../moo-enums";
import MooFilesUploadField from "../moo-files-upload-field";
import { useFindEnumById } from "../moo-hooks";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { useFindPayeeById } from "../moo-payee-crud";
import { useGetFieldError, uuidv4 } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { MooVoucherLinkList } from "../moo-voucher-link-list";
import { One, SignedFile } from "./@types/one";
import { Props } from "./@types/props";
import { ChooseItemArea } from "./components/ChooseItemArea";
import { PdfButton } from "./components/PdfButton";
import SummaryArea from "./components/SummaryArea";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

const Container = styled.div`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	margin-bottom: 30px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

export function Form({ id, isEdit = false, isCancelDisabled, isOkDisabled, onCancel }: Partial<Props>) {
	const [open, setOpen] = useState(false);
	const { clearChoose: clearCustomerChoose } = useCustomerChooseStore();
	const permissions = usePermissions();
	const { handleSubmit, values, setFieldValue, setValues, isValid } = useFormikContext<One>();
	const findCustomerById = useFindCustomerById();
	const findEmployeeById = useFindEmployeeById();
	const salesFilled = values.sales_id !== null;
	const payeeFilled = values.payee_id !== null;
	const customerFilled = values.customer_id !== null;
	const getFieldError = useGetFieldError<One>();
	const findQuotationTypeById = useFindEnumById("quotation_types");
	const findPayeeById = useFindPayeeById();

	return (
		<form onSubmit={handleSubmit}>
			<Container>
				<FieldContainer>
					<KodenDateField
						id="quotationDate"
						label="報價日期"
						error={getFieldError("quotation_date")}
						value={values.quotation_date === null ? null : new Date(values.quotation_date)}
						onChange={(date) => {
							setFieldValue(
								"quotation_date",
								date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
							);
						}}
					/>
					{isEdit && (
						<>
							<MooTypeableSelect
								id="state"
								label="狀態"
								value={values.state}
								error={getFieldError("state")}
								disabled={!permissions.includes(Permission.QuotationStateWrite)}
								options={[
									{ value: null, label: "請選擇" },
									{
										label: quotation.State.Label.WAIT_TO_PAY,
										value: quotation.State.Value.WAIT_TO_PAY,
									},
									{
										label: quotation.State.Label.PAID,
										value: quotation.State.Value.PAID,
									},
									{
										label: quotation.State.Label.UNPAID_LET_GO,
										value: quotation.State.Value.UNPAID_LET_GO,
									},
									{
										label: quotation.State.Label.SPECIAL,
										value: quotation.State.Value.SPECIAL,
									},
								]}
								onChange={(value) => {
									setFieldValue("state", value as quotation.State.Value);
								}}
							/>
							<MooTypeableSelect
								id="stateManager"
								label="主管簽核"
								value={values.state_manager}
								error={getFieldError("state_manager")}
								disabled={!permissions.includes(Permission.QuotationStateManagerWrite)}
								options={[
									{ value: null, label: "請選擇" },
									{
										label: quotation.StateManager.Label.NO_NEED_TO_APPROVE,
										value: quotation.StateManager.Value.NO_NEED_TO_APPROVE,
									},
									{
										label: quotation.StateManager.Label.UNAPPROVED,
										value: quotation.StateManager.Value.UNAPPROVED,
									},
									{
										label: quotation.StateManager.Label.APPROVED,
										value: quotation.StateManager.Value.APPROVED,
									},
									{
										label: quotation.StateManager.Label.RETURN,
										value: quotation.StateManager.Value.RETURN,
									},
								]}
								onChange={(value) => {
									setFieldValue("state_manager", value as quotation.StateManager.Value);
								}}
							/>
							<MooTypeableSelect
								id="stateAccountant"
								label="會計狀態"
								value={values.state_accountant}
								error={getFieldError("state_accountant")}
								disabled={!permissions.includes(Permission.QuotationStateAccountantWrite)}
								options={[
									{ value: null, label: "請選擇" },
									{
										label: quotation.StateAccountant.Label.PENDING,
										value: quotation.StateAccountant.Value.PENDING,
									},
									{
										label: quotation.StateAccountant.Label.CONFIRMED,
										value: quotation.StateAccountant.Value.CONFIRMED,
									},
								]}
								onChange={(value) => setFieldValue("state_accountant", value as quotation.StateAccountant.Value)}
							/>
						</>
					)}
				</FieldContainer>
				<FieldContainer>
					<MooEnumTypeableSelectField
						id="quotation_types"
						resource="quotation_types"
						label="類型"
						value={values.type_id}
						error={getFieldError("type_id")}
						onChange={async (type_id) => {
							if (type_id === null) {
								setValues({
									...values,
									type_id: null,
									type_name: "",
								});
							} else {
								const type = await findQuotationTypeById(type_id);
								setValues({
									...values,
									type_id,
									type_name: type?.name ?? "",
								});
							}
						}}
					/>
					<MooDialogCustomerChoose
						id="dialog-open-choose-customer"
						open={open}
						onClose={() => {
							setOpen(false);
							clearCustomerChoose();
						}}
						onOk={async (rows) => {
							setOpen(false);
							if (rows.length > 0) {
								const customer_id = rows[0].id;
								const customer = await findCustomerById(customer_id);
								if (customer !== null) {
									setValues({
										...values,
										customer_id,
										customer_name: customer.name ?? "",
										deliver_address: customer.address ?? "",
									});
								}
							}
							clearCustomerChoose();
						}}
					/>
					<ProxyKodenTextField
						id="customer"
						label="客戶"
						onClick={() => setOpen(true)}
						value={values.customer_name}
						style={{ cursor: "pointer" }}
						outlined
						readOnly
						margin="none"
						error={getFieldError("customer_id")}
					/>
					<MooEmployeeSelectField
						id="sales_id"
						label="業務"
						value={values.sales_id}
						error={getFieldError("sales_id")}
						onChange={async (sales_id) => {
							if (sales_id === null) {
								setValues({
									...values,
									sales_id,
									sales_name: "",
									sales_tel: "",
								});
							} else {
								const sales = await findEmployeeById(sales_id);
								setValues({
									...values,
									sales_id,
									sales_name: sales.name ?? "",
									sales_tel: sales.tel ?? "",
								});
							}
						}}
					/>
					{salesFilled && (
						<ProxyKodenTextField
							id="sales_tel"
							label="業務電話"
							outlined
							margin="none"
							value={values.sales_tel}
							disabled
							error=""
						/>
					)}
					<ProxyKodenTextField
						id="deliver_date"
						label="交貨日期"
						outlined
						style={{ gridColumnStart: 1 }}
						margin="none"
						defaultValue={values.deliver_date}
						error={getFieldError("deliver_date")}
						onChange={(value) => {
							setFieldValue("deliver_date", value);
						}}
					/>
					{customerFilled && (
						<TextFieldEnd
							id="deliver_address"
							label="配送地址"
							outlined
							margin="none"
							value={values.deliver_address}
							error={getFieldError("deliver_address")}
							onChange={(deliver_address) => setFieldValue("deliver_address", deliver_address)}
						/>
					)}
					<MooEmployeeSelectField
						id="assistant_id"
						label="助理"
						value={values.assistant_id}
						error={getFieldError("assistant_id")}
						onChange={async (assistant_id) => {
							setValues({
								...values,
								assistant_id,
							});
						}}
					/>
				</FieldContainer>
				<FieldContainer>
					<MooEnumTypeableSelectField
						id="payees"
						label="收款戶名"
						style={{ gridColumnStart: 1 }}
						value={values.payee_id}
						resource="payees"
						error={getFieldError("payee_id")}
						onChange={async (payee_id) => {
							if (payee_id === null) {
								setValues({
									...values,
									payee_id,
									payee_name: "",
									payee_bank: "",
									payee_account_number: "",
								});
							} else {
								const payee = await findPayeeById(payee_id);
								if (payee === null) {
									setValues({
										...values,
										payee_id,
										payee_name: "",
										payee_bank: "",
										payee_account_number: "",
									});
								} else {
									setValues({
										...values,
										payee_id,
										payee_name: payee?.name ?? "",
										payee_bank: payee?.bank_name ?? "",
										payee_account_number: payee?.account_number ?? "",
									});
								}
							}
						}}
					/>
					{payeeFilled && (
						<>
							<ProxyKodenTextField
								id="payee_bank_name"
								label="收款銀行"
								outlined
								margin="none"
								value={values.payee_bank}
								disabled
								error=""
							/>
							<TextFieldEnd
								id="payee_account_number"
								label="收款帳號"
								outlined
								margin="none"
								value={values.payee_account_number}
								disabled
								error=""
							/>
						</>
					)}
				</FieldContainer>
				{isEdit && <MooVoucherLinkList values={values.voucher_rows.map((e) => e.voucher_id)} />}
				<ChooseItemArea />
				<SummaryArea />
				<div style={{ margin: "2rem 0" }}>
					<KodenMultilineField
						id="memo"
						label="備註"
						outlined
						rows={2}
						margin="none"
						defaultValue={values.memo}
						error={getFieldError("memo")}
						onChange={(value) => setFieldValue("memo", value)}
					/>
				</div>
				<MooFilesUploadField
					label="客戶簽名上傳"
					acceptAny
					values={values.signed_files.map((e) => e.value)}
					maxInMB={20}
					tooBigMessage="請上傳小於20MB的檔案"
					onChange={(urls) => {
						setFieldValue(
							"signed_files",
							urls.map((value) => ({ quotation_signed_file_id: uuidv4(), value })) as SignedFile[],
						);
					}}
				/>
				<ButtonContainer>
					{isEdit && id && <PdfButton id={id} enabled={isValid || (!isValid && values.payee_id === null)} />}
					{!isOkDisabled && (
						<>
							<KodenButton
								style={{ marginRight: "1rem" }}
								disabled={isCancelDisabled}
								onClick={async () => {
									if (window.confirm("確定取消並且回到上一頁？")) {
										if (onCancel) {
											onCancel();
										}
									}
								}}
							>
								取消
							</KodenButton>
							<MooSubmitButton permission={Permission.QuotationUpdate} />
						</>
					)}
				</ButtonContainer>
			</Container>
		</form>
	);
}

export function MooQuotationOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.QuotationRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik enableReinitialize validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit}>
			<Form {...props} />
		</Formik>
	);
}
