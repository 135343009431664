import { One } from "..";
import { exportGoods } from "../../moo-enums";
import { Permission, usePermissions } from "../../moo-me-provider";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import FieldContainer from "../components/FieldContainer";
import { useForm } from "../hooks/useForm";
import { useGetError } from "../hooks/useGetError";

type ItemOption = {
	id: exportGoods.State.Value;
	label: exportGoods.State.Label;
};

type Item = {
	id: keyof One;
	label: string;
	permission: Permission;
	options: ItemOption[];
};

const arr: Item[] = [
	{
		id: "state",
		label: "狀態",
		permission: Permission.ExportGoodsStateWrite,
		options: [
			{
				id: exportGoods.State.Value.NotYetShipped,
				label: exportGoods.State.Label.NotYetShipped,
			},
			{
				id: exportGoods.State.Value.Delivering,
				label: exportGoods.State.Label.Delivering,
			},
			{
				id: exportGoods.State.Value.Delivered,
				label: exportGoods.State.Label.Delivered,
			},
		],
	},
];

export function StateBlock() {
	const { values, setFieldValue } = useForm();
	const getError = useGetError();
	const permissions = usePermissions();

	return (
		<FieldContainer>
			{arr.map(({ id, label, options, permission }) => (
				<MooTypeableSelect
					key={id}
					id={id}
					label={label}
					value={values[id] as string}
					error={getError(id)}
					disabled={!permissions.includes(permission)}
					options={options.map((e) => {
						return { ...e, value: e.id };
					})}
					onChange={(value) => {
						setFieldValue(id, value);
					}}
				/>
			))}
		</FieldContainer>
	);
}
