import { OrderBy } from "../@types";
import useStore from "../stores/useStore";

export function useGraphQLOrderBy(): any[] {
	const { query } = useStore();

	const arr: { id: string }[] = [];
	query.order_by.forEach((order) => {
		switch (order) {
			case OrderBy.ID_ASC:
				arr.push({
					id: "asc",
				});
				break;
			case OrderBy.ID_DESC:
				arr.push({
					id: "desc",
				});
				break;
		}
	});

	return arr;
}
