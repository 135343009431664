export function getSearchCondition(search: string | null) {
	if (search !== null && search.trim() !== "") {
		const _similar = `%(${search.trim().split(" ").join("|")})%`;
		return {
			_or: [
				{
					id: {
						_similar,
					},
				},
			],
		};
	}

	return {};
}
