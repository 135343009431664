import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as employeeListPath } from "../../../routes/EmployeeListRoute";
import { path as employeeTypesPath } from "../../../routes/EmployeeTypesRoute";
import { path as NewEmployeeRoutePath } from "../../../routes/NewEmployeeRoute";
import { path as UpdateEmployeeRoutePath } from "../../../routes/UpdateEmployeeRoute";
import { path as roleListPath } from "../../../routes/role/ListRoute";
import { path as newRoleRoutePath } from "../../../routes/role/NewRoute";
import { path as updateRoleRoutePath } from "../../../routes/role/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useEmployees(): ListType[] {
	const historyPushWithBranch = useHistoryPushWithBranch();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();
	const permissions = usePermissions();

	const children: any = [];

	if (permissions.includes(Permission.RoleListRead)) {
		children.push({
			id: roleListPath,
			txt: getPageTree()[roleListPath].useText(),
			icon: "",
			isSelected: isSelected([roleListPath, newRoleRoutePath, updateRoleRoutePath]),
			async onClick() {
				await historyPushWithBranch(roleListPath);
			},
		});
	}

	if (permissions.includes(Permission.EmployeeListRead)) {
		children.push({
			id: employeeListPath,
			txt: getPageTree()[employeeListPath].useText(),
			icon: "",
			isSelected: isSelected([employeeListPath, NewEmployeeRoutePath, UpdateEmployeeRoutePath]),
			async onClick() {
				await historyPushWithBranch(employeeListPath);
			},
		});
	}

	if (permissions.includes(Permission.EmployeeTypeWrite)) {
		children.push({
			id: employeeTypesPath,
			txt: getPageTree()[employeeTypesPath].useText(),
			icon: "",
			isSelected: isSelected([employeeTypesPath]),
			async onClick() {
				await historyPushWithBranch(employeeTypesPath);
			},
		});
	}

	if (children.length === 0) {
		return [];
	}

	return [
		{
			id: "employee",
			txt: "員工",
			icon: Utils.getAbsoluteStaticUrl("icons/employee.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleEmployee();
			},
			open: true,
			openChildren: navigationStore.employee,
			children,
		},
	];
}
