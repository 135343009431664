import { gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { ValueType } from "../moo-graphql-select-field/moo-graphql-select-field";
import { MooTypeableSelect, Option } from "../moo-typable-select/MooTypeableSelect";

type Props = {
	resource: string;
	name: string;
	label: string;
	value: string | number | null;
	onChange: (value: string | number) => void;
};

export function QueryField({ resource, name, label, value, onChange }: Props) {
	const { value: branch } = useBranch();
	const query = gql`
					query($distinct: [${resource}_select_column!], $where: ${resource}_bool_exp) {
							${resource}(distinct_on: $distinct, where: $where) {
								${name}
							}
					}				
				`;
	const variables = {
		distinct: [name],
		where: {
			[name]: { _neq: "" },
			branch_id: {
				_eq: branch,
			},
		},
	};
	const { isLoading, data } = useGraphQLQuery({ operationName: resource, query, variables });

	const options: Option[] = [
		{
			value: null as string | null,
			label: "請選擇",
		},
	].concat(
		(((data as any)?.[resource] || []) as Record<string, string>[]).map((e) => ({
			value: e[name],
			label: e[name],
		})),
	);

	return (
		<MooTypeableSelect
			isLoading={isLoading}
			id={`${resource}-${name}-select-field`}
			label={label}
			value={value as ValueType}
			onChange={onChange as (value: ValueType) => void}
			options={options}
		/>
	);
}
