import { gql } from "@apollo/client";
import { useGraphQLMutation } from "../moo-graphql-provider";

export function useUpdateUserRole() {
	const mutation = gql`
		mutation ($where: users_bool_exp!, $set: users_set_input) {
			update_users(where: $where, _set: $set) {
				returning {
					id
					email
				}
			}
		}
	`;

	const graphQLMutation = useGraphQLMutation({ mutation });
	return (email: string, role_id: string) => {
		return new Promise((onSuccess, onError) => {
			const variables = {
				where: {
					email: {
						_eq: email,
					},
				},
				set: { role_id },
			};

			graphQLMutation.mutate(variables, {
				onSuccess,
				onError,
			});
		});
	};
}
