import { array, mixed, object, SchemaOf, string } from "yup";
import { Status } from "../../moo-enums";
import { stringRequired } from "../../moo-schemas";
import { One } from "../@types/one";
import { permissionSchema } from "./permissionSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: mixed<Status>().oneOf(Object.values(Status)).required(),
	name: string().required("請提供角色名稱"),
	permissions: array(permissionSchema),
});
