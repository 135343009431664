import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooTable } from "../moo-table";
import { GraphQLResult } from "./GraphQLResult";
import { QueryBoard } from "./QueryBoard";
import { headers } from "./headers";
import { mapResults } from "./mapResults";
import { query } from "./query";
import { useCells } from "./useCells";
import { useVariables } from "./useVariables";

export type MooWarehouseOneProps = {
	id: string;
	itemIds?: string[];
	showItemIds?: boolean;
};

const operationName = "warehouses_by_pk";

export function MooWarehouseOne({ id, showItemIds = true, itemIds: propsItemIds = [] }: MooWarehouseOneProps) {
	const permissions = usePermissions();
	const { value: branch } = useBranch();
	const cells = useCells();
	const [itemIds, setItemIds] = useState(propsItemIds || []);
	const variables = useVariables({ id, itemIds });
	const record = useGraphQLQuery<GraphQLResult>({
		operationName,
		query,
		variables,
	});

	useEffect(() => {
		record.refetch();
	}, [id]);

	useEffect(() => {
		setItemIds(propsItemIds || []);
	}, [JSON.stringify(propsItemIds)]);

	if (!permissions.includes(Permission.WarehouseWrite)) {
		return <MooNoAuthPage />;
	}

	if (record.isLoading || record.isError) {
		return null;
	} else {
		const graphQLResult = record.data;
		// @ts-ignore
		if (graphQLResult.warehouses_by_pk.status !== Status.Present) {
			return <div>此資料已被刪除</div>;
			// @ts-ignore
		} else if (graphQLResult.warehouses_by_pk.branch_id !== branch) {
			// @ts-ignore
			return <div>此資料屬於{_.get(graphQLResult.warehouses_by_pk, `data.${operationName}.branch.name`, "")}</div>;
		}
		return (
			<>
				{showItemIds && <QueryBoard itemIds={itemIds} onChange={setItemIds} />}
				<MooTable headers={headers} cells={cells} onRowClick={console.log} results={mapResults(graphQLResult!)} />
			</>
		);
	}
}
