export enum Value {
	MANUFACTURER_MAINTAIN = "MANUFACTURER_MAINTAIN",
	RETURN_TO_ORIGINAL_MANUFACTURER = "RETURN_TO_ORIGINAL_MANUFACTURER",
	SCRAP = "SCRAP",
	OTHERS = "OTHERS",
}

export enum Label {
	MANUFACTURER_MAINTAIN = "送廠商維修",
	RETURN_TO_ORIGINAL_MANUFACTURER = "退回原廠",
	SCRAP = "報廢",
	OTHERS = "其他",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.MANUFACTURER_MAINTAIN:
			return Label.MANUFACTURER_MAINTAIN;
		case Value.RETURN_TO_ORIGINAL_MANUFACTURER:
			return Label.RETURN_TO_ORIGINAL_MANUFACTURER;
		case Value.SCRAP:
			return Label.SCRAP;
		case Value.OTHERS:
			return Label.OTHERS;
	}
	return Label.MANUFACTURER_MAINTAIN;
}
