import { manufacturer, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./index";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();

	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		manufacturer_type: manufacturer.Value.Value.Domestic,
		manufacturer_id: null,
		owner_id: null,
		category_id: null,
		sub_category_id: null,
		buy_year: new Date().getFullYear(),
		name: "",
		material: "",
		color: "",
		size: "",
		note: "",
		purchase_price: 0,
		cost: 0,
		price: 0,
		original_code: "",
		image: null,
		image_cut: null,
		country: "",
	};
}
