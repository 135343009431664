import { allowance, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();

	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		state_accountant_id: null,
		state_accountant: allowance.StateAccountant.Value.UNAPPROVED,
		state_manager: allowance.StateManager.Value.UNAPPROVED,
		state_requester: allowance.StateRequester.Value.UNSIGNED,
		request_date: now,
		requester_id: null,
		reason: "",
		order_id: null,
		amount: 0,
	};
}
