import type { CellType } from "@yachelee/koden-components/koden-table";
import { orderGoods } from "../moo-enums";
import { useOnLinkClick } from "../moo-link-provider";
import { ClickableColumn, DateFormat } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	const onLinkClick = useOnLinkClick();
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "nick_name",
			align: "left",
			component: ({ creator_name }: Row) => {
				return <div>{creator_name}</div>;
			},
		},
		{
			id: "sales_name",
			align: "left",
			component: ({ sales_name }: Row) => {
				return <div>{sales_name}</div>;
			},
		},
		{
			id: "manufacturer",
			align: "left",
			component: ({ manufacturer }: Row) => {
				return <div>{manufacturer}</div>;
			},
		},
		{
			id: "order_goods_date",
			align: "left",
			component: ({ order_goods_date }: Row) => {
				return <DateFormat>{order_goods_date}</DateFormat>;
			},
		},
		{
			id: "deliver_goods_date",
			align: "left",
			component: ({ deliver_goods_date }: Row) => {
				return <DateFormat>{deliver_goods_date}</DateFormat>;
			},
		},
		{
			id: "state",
			align: "left",
			component: ({ state }: Row) => {
				return <div>{orderGoods.State.getLabel(state)}</div>;
			},
		},
		{
			id: "state_warehouse",
			align: "left",
			component: ({ state_warehouse }: Row) => {
				return <div>{orderGoods.StateWarehouse.getLabel(state_warehouse)}</div>;
			},
		},
		{
			id: "warehouse_name",
			align: "left",
			component: ({ warehouse_name }: Row) => {
				return <div>{warehouse_name}</div>;
			},
		},
		{
			id: "order_id",
			align: "left",
			component: ({ order_id }: Row) => {
				if (order_id === "") {
					return null;
				}
				return <ClickableColumn onClick={() => onLinkClick(order_id)}>{order_id}</ClickableColumn>;
			},
		},
	];
}
