import type { orderGoods } from "../moo-enums";

export type GraphQLColumnsResult = {
	id: string;
	sales: {
		name: string;
	} | null;
	manufacturer: {
		id: string;
		name: string;
	} | null;
	order_goods_date: string | null;
	deliver_goods_date: string | null;
	state: orderGoods.State.Value;
	state_warehouse: orderGoods.StateWarehouse.Value;
	order_id: string | null;
	warehouse: {
		name: string;
	} | null;
};

export const graphQLColumns = `{
	id
	sales {
		name
	}
	manufacturer {
		id
		name
	}
	order_goods_date
	deliver_goods_date
	state
	state_warehouse
	order_id
	warehouse{
		name
	}
	employee {
		nick_name
	}
}`;
