import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../../moo-graphql-provider";

const query = gql`
	query ($id: uuid!) {
		employees_by_pk(id: $id) {
			status
			type_id
			default_branch_id
			name
			nick_name
			birthday
			tel
			email
			line
			contact_name
			contact_tel
			address
			onboard_date
			salary_basic
			salary_increased_date
			bank_account
			bank
			bank_account_number
			labor_fee
			healthcare_fee
			insurance_day
			insurance_unit
			insurance_fee
			note
			user {
				role_id
			}
		}
	}
`;

export function useFindEmployeeById() {
	const request = useGraphQLRequest();

	return (id: string) => {
		const variables = {
			id,
		};

		return request({ query, variables }).then((e) => e.employees_by_pk);
	};
}
