import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class OrderGoodsInstructionsTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "order_good_instruction_id";
	}

	getTableName(): string {
		return "order_good_instructions";
	}

	getPathName(): string {
		return "instructions";
	}

	getParentIdName(): string {
		return "order_good_id";
	}

	getToUpdateColumns(): string[] {
		return ["value"];
	}
}
