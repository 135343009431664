import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class PurchaseOrderManufacturerFilesTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "purchase_order_manufacturer_file_id";
	}

	getTableName(): string {
		return "purchase_order_manufacturer_files";
	}

	getPathName(): string {
		return "manufacturer_files";
	}

	getParentIdName(): string {
		return "purchase_order_id";
	}

	getToUpdateColumns(): string[] {
		return ["value"];
	}
}
