import { Button } from "@mui/material";
import { useIds } from "./hooks/useIds";
import { useOnDelete } from "./hooks/useOnDelete";

export function DeleteButton() {
	const { ids } = useIds();
	const onDelete = useOnDelete();
	const size = ids.length;

	return (
		<Button disabled={size === 0} variant="contained" onClick={onDelete}>
			刪除{size > 0 ? `(${size})` : ""}
		</Button>
	);
}
