import { Item } from "../../moo-export-goods-one";
import { RowItem } from "./RowItem";
import { mapperStocks } from "./mapperStocks";

export function mapperItems(items: RowItem[]): Item[] {
	return items.map(({ export_good_item_id, id, item_custom, item_spec, stocks }, priority) => {
		const item = item_spec || item_custom;

		return {
			export_good_item_id,
			id,
			manufacturer_type: item.manufacturer.type,
			manufacturer_id: item.manufacturer.id,
			manufacturer: item.manufacturer.name,
			name: item.name,
			material: item.material,
			color: item.color,
			size: item.size,
			count: 10000000,
			image: item.image,
			image_cut: item.image_cut,
			priority,
			stocks: mapperStocks(stocks),
			original_code: {
				original_code: item.original_code,
			},
		};
	});
}
