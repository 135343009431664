import { gql } from "@apollo/client";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import type { CellType } from "@yachelee/koden-components/koden-table";
import * as _ from "lodash";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { useGraphQLRequest } from "../moo-graphql-provider";
import { useOnLinkClick } from "../moo-link-provider";
import { useError, useSuccess } from "../moo-notification-provider";
import { ClickableColumn, DollarNumber } from "../moo-shared";
import { Row } from "./@types";

function useUpdateColumn(year: number, month: number, employee_id: string) {
	const success = useSuccess();
	const error = useError();
	const graphQLRequest = useGraphQLRequest();

	return _.debounce(async (link_id: string, field: keyof Row, value: any) => {
		const variables = {
			object: {
				link_id,
				employee_id,
				year,
				month,
				[field]: value,
			},
			onConflict: {
				constraint: "employee_performances_pkey",
				update_columns: [field],
			},
		};

		try {
			await graphQLRequest({
				query: gql`
					mutation ($object: employee_performances_insert_input!, $onConflict: employee_performances_on_conflict) {
						insert_employee_performances_one(object: $object, on_conflict: $onConflict) {
							link_id
							employee_id
							year
							month
							custom_bonus
						}
					}
				`,
				variables,
			});
			success("更新成功");
		} catch (e) {
			error("更新失敗");
		}
	}, 500);
}

export function useCells(year: number, month: number, employee_id: string): CellType[] {
	const onLinkClick = useOnLinkClick();
	const updateColumn = useUpdateColumn(year, month, employee_id);

	return [
		{
			id: "link_id",
			align: "left",
			component: ({ link_id }: Row) => {
				return link_id === "" ? null : (
					<ClickableColumn onClick={() => onLinkClick(link_id)}>{link_id}</ClickableColumn>
				);
			},
		},
		{
			id: "total_price",
			align: "left",
			component: ({ total_price }: Row) => {
				return <DollarNumber>{total_price}</DollarNumber>;
			},
		},
		{
			id: "cost",
			align: "left",
			component: ({ cost }: Row) => {
				return <DollarNumber>{cost}</DollarNumber>;
			},
		},
		{
			id: "driver_fee",
			align: "left",
			component: ({ driver_fee }: Row) => {
				return <DollarNumber>{driver_fee}</DollarNumber>;
			},
		},
		{
			id: "commission_refund",
			align: "left",
			component: ({ commission_refund }: Row) => {
				return <DollarNumber>{commission_refund}</DollarNumber>;
			},
		},
		{
			id: "tax_price",
			align: "left",
			component: ({ tax_price }: Row) => {
				return <DollarNumber>{tax_price}</DollarNumber>;
			},
		},
		{
			id: "mgt_cost",
			align: "left",
			component: ({ mgt_cost }: Row) => {
				return <DollarNumber>{mgt_cost}</DollarNumber>;
			},
		},
		{
			id: "handling_fee",
			align: "left",
			component: ({ handling_fee }: Row) => {
				return <DollarNumber>{handling_fee}</DollarNumber>;
			},
		},
		{
			id: "miscellaneous_charge",
			align: "left",
			component: ({ miscellaneous_charge }: Row) => {
				return <DollarNumber>{miscellaneous_charge}</DollarNumber>;
			},
		},
		{
			id: "revenue",
			align: "left",
			component: ({ revenue }: Row) => {
				return <DollarNumber>{revenue}</DollarNumber>;
			},
		},
		{
			id: "bonus_percentage",
			align: "left",
			component: ({ bonus_percentage }: Row) => {
				return parseFloat(bonus_percentage.toFixed(2));
			},
		},
		{
			id: "employee_percentage",
			align: "left",
			component: ({ employee_percentage, link_id }: Row) => {
				return (
					<KodenNumberField
						id={`employee_percentage_${link_id}`}
						value={employee_percentage * 100}
						decimalPlaces={2}
						step={0.01}
						min={0}
						max={100}
						label=""
						error=""
						onChange={(value) => {
							if (value !== employee_percentage) {
								updateColumn(link_id, "employee_percentage", value / 100);
							}
						}}
					/>
				);
			},
		},
		{
			id: "bonus",
			align: "left",
			component: ({ bonus }: Row) => {
				return <DollarNumber>{bonus}</DollarNumber>;
			},
		},
		{
			id: "custom_bonus",
			align: "left",
			component: ({ link_id, custom_bonus }: Row) => {
				return (
					<KodenNumberField
						id={`custom_bonus_${link_id}`}
						value={custom_bonus}
						step={1}
						min={0}
						hasDollar
						max={Number.MAX_SAFE_INTEGER}
						label=""
						error=""
						onChange={(value) => {
							if (value !== custom_bonus) {
								updateColumn(link_id, "custom_bonus", value);
							}
						}}
					/>
				);
			},
		},
		{
			id: "note",
			align: "left",
			component: ({ link_id, note }: Row) => {
				return (
					<ProxyKodenTextField
						id={`note_${link_id}`}
						defaultValue={note}
						label=""
						error=""
						onChange={(value) => {
							updateColumn(link_id, "note", value);
						}}
					/>
				);
			},
		},
	];
}
