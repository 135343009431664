import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";
import { DEFAULT_ONE, MooStockTransferOne, One } from "../moo-stock-transfer-one";

export type MooStockTransferAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooStockTransferAdd: FC<MooStockTransferAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("stock_transfers", onSuccess, (e) => {
		return produce(e, (draft) => {
			draft.items.forEach((item, index) => {
				// @ts-ignore
				draft.items[index] = {
					stock_transfer_item_id: item.stock_transfer_item_id,
					id: item.id,
					priority: item.priority,
					transfer_count: item.transfer_count,
				};
			});
		});
	});
	const permissions = usePermissions();

	return (
		<MooStockTransferOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.StockTransferAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
