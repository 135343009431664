import { Employee, Form } from "../moo-employee-one";

export function mapEmployeeToForm(employee: Employee): Form {
	return {
		default_branch_id: employee.default_branch_id,
		role_id: employee.user.role_id,
		status: employee.status,
		type_id: employee.type_id,
		name: employee.name,
		nick_name: employee.nick_name,
		birthday: employee.birthday,
		tel: employee.tel,
		email: employee.email,
		line: employee.line,
		contact_name: employee.contact_name,
		contact_tel: employee.contact_tel,
		address: employee.address,
		onboard_date: employee.onboard_date,
		salary_basic: employee.salary_basic,
		salary_increased_date: employee.salary_increased_date,
		bank_account: employee.bank_account,
		bank: employee.bank,
		bank_account_number: employee.bank_account_number,
		labor_fee: employee.labor_fee,
		healthcare_fee: employee.healthcare_fee,
		insurance_day: employee.insurance_day,
		insurance_unit: employee.insurance_unit,
		insurance_fee: employee.insurance_fee,
		note: employee.note,
	};
}
