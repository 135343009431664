import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import { Formik } from "formik";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { useQuotationDialogChoose, useStore as useQuotationChooseStore } from "../moo-dialog-quotation-choose";
import { useFindEmployeeById } from "../moo-employee-one";
import { MooEmployeeSelectField } from "../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { order, quotation } from "../moo-enums";
import MooFilesUploadField from "../moo-files-upload-field";
import { MooLinkButton } from "../moo-link-button";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooOrderStateAccountantSelect } from "../moo-order-state-accountant-select";
import { useFindPayeeById } from "../moo-payee-crud";
import { ErrorMessage, useGetFieldError, uuidv4 } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { MooVoucherLinkList } from "../moo-voucher-link-list";
import { One } from "./@types/one";
import { Props } from "./@types/props";
import { ChooseItemArea } from "./components/ChooseItemArea";
import { PdfButton } from "./components/PdfButton";
import SummaryArea from "./components/SummaryArea/SummaryArea";
import { useFindQuotationTypeById } from "./hooks/useFindQuotationTypeById";
import { useForm } from "./hooks/useForm";
import { schema } from "./schemas/schema";
import { useChooseQuotation } from "./useChooseQuotation";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	max-width: ${WEB_BREAKPOINT};
	margin-bottom: 30px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const FileUpload = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 0.3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.6rem;
	align-items: center;
	padding: 14px;
	min-height: 56px;
	text-align: center;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto 1fr;
		text-align: left;
		padding: 0 14px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

function Form({ id, isEdit = false, isCancelDisabled, isOkDisabled, onCancel }: Partial<Props>) {
	const permissions = usePermissions();
	const findPayeeById = useFindPayeeById();
	const findEmployeeById = useFindEmployeeById();
	const findQuotationTypeById = useFindQuotationTypeById();
	const { clearChoose: clearQuotationChoose } = useQuotationChooseStore();
	const { handleSubmit, values, setFieldValue, setValues } = useForm();
	const quotationImported = values.quotation_id !== null;
	const getFieldError = useGetFieldError<One>();
	const chooseQuotation = useChooseQuotation();
	const { component, open } = useQuotationDialogChoose(
		"MooDialogQuotationChoose",
		async (rows) => {
			if (rows.length >= 1) {
				await chooseQuotation(rows, setValues);
				clearQuotationChoose();
			}
		},
		false,
		quotation.State.getValues(),
		[quotation.StateManager.Value.APPROVED, quotation.StateManager.Value.NO_NEED_TO_APPROVE],
		[quotation.StateAccountant.Value.CONFIRMED],
	);
	const salesFilled = values.sales_id !== null;
	const payeeFilled = values.payee_id !== null;
	const customerFilled = values.customer_id !== null;

	return (
		<form onSubmit={handleSubmit}>
			<Container>
				{isEdit && (
					<FieldContainer>
						<MooTypeableSelect
							id="state"
							label="狀態"
							value={values.state}
							error={getFieldError("state")}
							disabled={!permissions.includes(Permission.OrderStateWrite)}
							options={[
								{
									label: "請選擇",
									value: null,
								},
								{
									label: order.State.Label.Customizing,
									value: order.State.Value.Customizing,
								},
								{
									label: order.State.Label.ReadyToShip,
									value: order.State.Value.ReadyToShip,
								},
								{
									label: order.State.Label.Shipped,
									value: order.State.Value.Shipped,
								},
								{
									label: order.State.Label.Returned,
									value: order.State.Value.Returned,
								},
								{
									label: order.State.Label.ShippedPartially,
									value: order.State.Value.ShippedPartially,
								},
							]}
							onChange={(value) => {
								setFieldValue("state", value);
							}}
						/>
						<MooTypeableSelect
							id="stateManager"
							label="主管簽核"
							value={values.state_manager}
							error={getFieldError("state_manager")}
							disabled={!permissions.includes(Permission.OrderStateManagerWrite)}
							options={[
								{
									label: "請選擇",
									value: null,
								},
								{
									label: order.StateManager.Label.Unapproved,
									value: order.StateManager.Value.Unapproved,
								},
								{
									label: order.StateManager.Label.Approved,
									value: order.StateManager.Value.Approved,
								},
								{
									label: order.StateManager.Label.Return,
									value: order.StateManager.Value.Return,
								},
							]}
							onChange={(value) => {
								setFieldValue("state_manager", value);
							}}
						/>
						<MooOrderStateAccountantSelect
							label="會計確認"
							value={values.state_accountant}
							disabled={!permissions.includes(Permission.OrderStateAccountantWrite)}
							error={getFieldError("state_accountant")}
							onChange={(value) => {
								setFieldValue("state_accountant", value);
							}}
						/>
						<MooTypeableSelect
							id="stateWarehouse"
							label="倉管狀態"
							value={values.state_warehouse}
							disabled={!permissions.includes(Permission.OrderStateWarehouseWrite)}
							error={getFieldError("state_warehouse")}
							options={[
								{
									label: "請選擇",
									value: null,
								},
								{
									label: order.StateWarehouse.Label.UnConfirmed,
									value: order.StateWarehouse.Value.UnConfirmed,
								},
								{
									label: order.StateWarehouse.Label.Confirmed,
									value: order.StateWarehouse.Value.Confirmed,
								},
							]}
							onChange={(value) => {
								setFieldValue("state_warehouse", value);
							}}
						/>
					</FieldContainer>
				)}
				<FieldContainer>
					<KodenDateField
						id="orderDate"
						label="訂購日期"
						error={getFieldError("order_date")}
						value={values.order_date === null ? null : new Date(values.order_date)}
						onChange={(date) => {
							setFieldValue(
								"order_date",
								date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
							);
						}}
					/>
					{component}
					<FileUpload>
						<KodenButton onClick={() => open()}>{quotationImported ? "重匯報價單" : "匯入報價單"}</KodenButton>
						<MooLinkButton id={values.quotation_id} name="報價單" />
						<ErrorMessage values={[getFieldError("quotation_id")]} />
					</FileUpload>
					<ProxyKodenTextField
						id="customer_name"
						outlined
						disabled
						readOnly
						label="創建人"
						value={values.employee?.nick_name || "未知"}
						error=""
					/>
				</FieldContainer>
				{quotationImported && (
					<>
						<FieldContainer>
							<MooEnumTypeableSelectField
								id="type_id"
								resource="quotation_types"
								label="類型"
								value={values.type_id}
								error={getFieldError("type_id")}
								onChange={async (type_id) => {
									const quotationType = await findQuotationTypeById(type_id!);
									setValues({
										...values,
										type_id,
										type_name: quotationType?.name ?? "",
									});
								}}
							/>
							<ProxyKodenTextField
								id="customer_name"
								outlined
								disabled
								readOnly
								label="客戶"
								value={values.customer_name}
								error=""
							/>
							<MooEmployeeSelectField
								id="assistant_id"
								label="助理"
								value={values.assistant_id}
								error={getFieldError("assistant_id")}
								onChange={async (assistant_id) => {
									setValues({
										...values,
										assistant_id,
									});
								}}
							/>
							<MooEmployeeSelectField
								id="sales_id"
								label="業務"
								value={values.sales_id}
								error={getFieldError("sales_id")}
								onChange={async (sales_id) => {
									if (sales_id === null) {
										setValues({
											...values,
											sales_id,
											sales_name: "",
											sales_tel: "",
										});
									} else {
										const sales = await findEmployeeById(sales_id);
										setValues({
											...values,
											sales_id,
											sales_name: sales?.name ?? "",
											sales_tel: sales?.tel ?? "",
										});
									}
								}}
							/>
							{salesFilled && (
								<ProxyKodenTextField
									id="sales_tel"
									label="業務電話"
									outlined
									margin="none"
									value={values.sales_tel}
									readOnly
									disabled
									error=""
								/>
							)}
							<KodenDateField
								id="deliver_date"
								label="交貨日期"
								value={values.deliver_date === null ? null : new Date(values.deliver_date)}
								error=""
								onChange={(date) => {
									setFieldValue(
										"deliver_date",
										date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
									);
								}}
							/>
							<MooTypeableSelect
								id="is_include_tax"
								label="稅金"
								value={(values.is_include_tax?.toString() || null) as string | null}
								error={getFieldError("is_include_tax")}
								options={[
									{ label: "請選擇", value: null },
									{ label: "否", value: "false" },
									{ label: "是", value: "true" },
								]}
								onChange={(value) => {
									if (value === null) {
										setFieldValue("is_include_tax", null);
									} else {
										setFieldValue("is_include_tax", value === "true");
									}
								}}
							/>
							<MooEnumTypeableSelectField
								id="payees"
								label="收款戶名"
								style={{ gridColumnStart: 1 }}
								value={values.payee_id}
								resource="payees"
								error={getFieldError("payee_id")}
								onChange={async (payee_id) => {
									if (payee_id === null) {
										setValues({
											...values,
											payee_id,
											payee_name: "",
											payee_bank: "",
											payee_account_number: "",
										});
									} else {
										const payee = await findPayeeById(payee_id);
										if (payee === null) {
											setValues({
												...values,
												payee_id,
												payee_name: "",
												payee_bank: "",
												payee_account_number: "",
											});
										} else {
											setValues({
												...values,
												payee_id,
												payee_name: payee?.name ?? "",
												payee_bank: payee?.bank_name ?? "",
												payee_account_number: payee?.account_number ?? "",
											});
										}
									}
								}}
							/>
							{payeeFilled && (
								<>
									<ProxyKodenTextField
										id="payee_bank_name"
										label="收款銀行"
										outlined
										margin="none"
										value={values.payee_bank}
										readOnly
										disabled
										error=""
									/>
									<ProxyKodenTextField
										id="payee_account_number"
										label="收款帳號"
										outlined
										margin="none"
										value={values.payee_account_number}
										readOnly
										disabled
										error=""
									/>
								</>
							)}
							{customerFilled && (
								<ProxyKodenTextField
									id="deliver_address"
									label="配送地址"
									outlined
									margin="none"
									value={values.deliver_address}
									error=""
									onChange={(deliver_address) => {
										setFieldValue("deliver_address", deliver_address);
									}}
								/>
							)}
							<ProxyKodenTextField
								id="description"
								label="訂單簡述"
								outlined
								margin="none"
								value={values.description}
								error=""
								onChange={(description) => setFieldValue("description", description)}
							/>
							<TextFieldEnd
								id="order_note"
								label="備註"
								outlined
								margin="none"
								value={values.order_note}
								error=""
								onChange={(value) => setFieldValue("order_note", value)}
							/>
						</FieldContainer>
						{isEdit && <MooVoucherLinkList values={values.voucher_rows.map((e) => e.voucher_id)} />}
						<ChooseItemArea />
						<SummaryArea />
						<div style={{ margin: "2rem 0" }}>
							<KodenMultilineField
								id="memo"
								label=""
								outlined
								rows={2}
								margin="none"
								defaultValue={values.memo}
								error=""
								onChange={(value) => setFieldValue("memo", value)}
							/>
						</div>
						<MooFilesUploadField
							label="家具說明書"
							values={values.instructions.map((e) => e.value)}
							onChange={(urls) =>
								setFieldValue(
									"instructions",
									urls.map((value) => ({ order_instruction_id: uuidv4(), value })),
								)
							}
						/>
					</>
				)}
				<ButtonContainer>
					{isEdit && id && <PdfButton id={id} />}
					{!isOkDisabled && (
						<>
							<KodenButton
								style={{ marginRight: "1rem" }}
								disabled={isCancelDisabled}
								onClick={async () => {
									if (window.confirm("確定取消並且回到上一頁？")) {
										if (onCancel) {
											onCancel();
										}
									}
								}}
							>
								取消
							</KodenButton>
							<MooSubmitButton permission={Permission.OrderUpdate} />
						</>
					)}
				</ButtonContainer>
			</Container>
		</form>
	);
}

export function MooOrderOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.OrderRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
