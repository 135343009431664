import type { CellType } from "@yachelee/koden-components/koden-table";
import { getGenderString, getVipString } from "../../moo-customer-one";
import { DateFormat } from "../../moo-shared";
import { Row } from "../@types";

export function useCells(): CellType[] {
	return [
		{
			id: "number",
			align: "left",
			component: ({ number }: Row) => {
				return <div>{number}</div>;
			},
		},
		{
			id: "type",
			align: "left",
			component: ({ type }: Row) => {
				return <div>{type}</div>;
			},
		},
		{
			id: "name",
			align: "left",
			component: ({ name }: Row) => {
				return <div>{name}</div>;
			},
		},
		{
			id: "gender",
			align: "left",
			component: ({ gender }: Row) => {
				return <div>{getGenderString(gender)}</div>;
			},
		},
		{
			id: "birthday",
			align: "left",
			component: ({ birthday }: Row) => {
				return <DateFormat year={false}>{birthday}</DateFormat>;
			},
		},
		{
			id: "vip",
			align: "left",
			component: ({ vip }: Row) => {
				return <div>{getVipString(vip)}</div>;
			},
		},
		{
			id: "tel",
			align: "left",
			component: ({ tel }: Row) => {
				return <div>{tel}</div>;
			},
		},
		{
			id: "email",
			align: "left",
			component: ({ email }: Row) => {
				return <div>{email}</div>;
			},
		},
		{
			id: "address",
			align: "left",
			component: ({ address }: Row) => {
				return <div>{address}</div>;
			},
		},
		{
			id: "line",
			align: "left",
			component: ({ line }: Row) => {
				return <div>{line}</div>;
			},
		},
	];
}
