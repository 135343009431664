import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import { Formik } from "formik";
import * as _ from "lodash";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { useGetFieldError } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { Props } from "./@types/Props";
import { ForeignManufacturer } from "./@types/foreign-manufacturer";
import { useForm } from "./hooks/useForm";
import { useUpdate } from "./hooks/useUpdate";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 2rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const TextFieldRow = styled(ProxyKodenTextField)`
	width: 100%;
`;

const TextFieldStartSpan = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column: 1 / -1;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column: 1 / span 2;
	}
`;

const TelTextField = styled(ProxyKodenTextField)`
	& .MuiOutlinedInput-input {
		padding-right: 35px;
	}
`;

const TelDeleteBtn = styled(CancelIcon)`
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translateY(-50%);
	cursor: pointer;
`;

const AccountDeleteBtn = styled(CloseIcon)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	cursor: pointer;
`;

const AccountContainer = styled.div`
	grid-column: 1 / -1;
	display: grid;
	grid-gap: 30px 15px;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-auto-flow: row;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: 1fr 1fr;
	}
`;

const Account = styled.div`
	position: relative;
	display: grid;
	grid-gap: 10px;
	border: 1px solid #c4c4c4;
	padding: 12px;
	border-radius: 3px;
`;

const SubTitle = styled.div`
	position: relative;
	font-size: 0.8rem;
	color: #858585;
`;

const TextIconBtn = styled.div`
	border: 1px dashed #c4c4c4;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	& .top-bar {
		width: 100%;
		padding: 10.5px 14px;
	}
`;

function Form({
	isLoading = false,
	isCancelDisabled,
	isOkDisabled,
	onCancel,
}: Omit<Props, "initialValues" | "onSubmit">) {
	const { errors, values, setFieldValue, handleSubmit } = useForm();
	const { addTel, delTel, updateTel, addAccount, delAccount, updateAccount } = useUpdate();
	const permissions = usePermissions();
	const getFieldError = useGetFieldError<ForeignManufacturer>();
	if (!permissions.includes(Permission.ManufacturerForeignRead)) {
		return <MooNoAuthPage />;
	}
	return (
		<form onSubmit={handleSubmit}>
			<FieldContainer>
				<MooEnumTypeableSelectField
					id="manufacturer_foreign_types"
					resource="manufacturer_foreign_types"
					label="類型"
					value={values.type_id}
					error={getFieldError("type_id")}
					onChange={(value) => {
						setFieldValue("type_id", value);
					}}
				/>
				<ProxyKodenTextField
					id="name"
					label="廠商名稱"
					disabled={isLoading}
					outlined
					margin="none"
					value={values.name}
					error={getFieldError("name")}
					onChange={(value) => {
						setFieldValue("name", value);
					}}
				/>
				<TextFieldEnd
					id="email"
					label="Email"
					outlined
					margin="none"
					value={values.email}
					error={getFieldError("email")}
					onChange={(value) => {
						setFieldValue("email", value);
					}}
				/>
				<ProxyKodenTextField
					id="country"
					label="國家"
					outlined
					margin="none"
					value={values.country}
					error={getFieldError("country")}
					onChange={(value) => {
						setFieldValue("country", value);
					}}
				/>
				<ProxyKodenTextField
					id="currency"
					label="幣別"
					outlined
					margin="none"
					value={values.currency}
					error={getFieldError("currency")}
					onChange={(value) => setFieldValue("currency", value)}
				/>
				<ProxyKodenTextField
					id="fax"
					label="傳真"
					outlined
					margin="none"
					value={values.fax}
					error={getFieldError("fax")}
					onChange={(value) => setFieldValue("fax", value)}
				/>
				{values.tels.map(({ manufacturer_foreign_tel_id, tel }, index) => {
					const telError = _.get(errors, `tels.${index}.tel`, "");

					return (
						<div style={{ position: "relative" }} key={manufacturer_foreign_tel_id}>
							<TelTextField
								id="tel"
								label={`電話${index + 1}`}
								outlined
								margin="none"
								value={tel}
								error={telError}
								onChange={(value) => {
									updateTel(index, value);
								}}
							/>
							<TelDeleteBtn fontSize="small" color="disabled" onClick={() => delTel(index)}>
								x
							</TelDeleteBtn>
						</div>
					);
				})}
				<TextIconBtn>
					<KodenIconTextButton textColor="#757575" text="新增電話" onClick={addTel} />
				</TextIconBtn>
				<AccountContainer>
					{values.accounts.map((account, index) => {
						const bankAccountError = _.get(errors, `accounts.${index}.bank_account`, "");

						return (
							<Account key={account.manufacturer_foreign_account_id}>
								<SubTitle>
									帳戶 {index + 1}
									<AccountDeleteBtn
										fontSize="small"
										color="disabled"
										onClick={() => delAccount(account.manufacturer_foreign_account_id)}
									>
										x
									</AccountDeleteBtn>
								</SubTitle>
								<TextFieldRow
									id="bank_account"
									label="戶名"
									outlined
									margin="none"
									value={account.bank_account}
									error={bankAccountError}
									onChange={(value) => updateAccount(account.manufacturer_foreign_account_id, "bank_account", value)}
								/>
								<TextFieldRow
									id="SwiftCode"
									label="Swift Code"
									outlined
									margin="none"
									value={account.swift_code}
									error=""
									onChange={(value) => updateAccount(account.manufacturer_foreign_account_id, "swift_code", value)}
								/>
								<TextFieldRow
									id="code_iban"
									label="Code Iban"
									outlined
									margin="none"
									value={account.code_iban}
									error=""
									onChange={(value) => updateAccount(account.manufacturer_foreign_account_id, "code_iban", value)}
								/>
							</Account>
						);
					})}
					<TextIconBtn>
						<KodenIconTextButton textColor="#757575" text="新增帳戶" onClick={() => addAccount()} />
					</TextIconBtn>
				</AccountContainer>
				<TextFieldStartSpan
					id="address"
					label="地址"
					outlined
					margin="none"
					value={values.address}
					error={getFieldError("address")}
					onChange={(value) => setFieldValue("address", value)}
				/>

				<TextFieldEnd
					id="website"
					label="網站"
					outlined
					margin="none"
					value={values.website}
					error={getFieldError("website")}
					onChange={(value) => setFieldValue("website", value)}
				/>
				<KodenMultilineField
					id="note"
					label="備註"
					disabled={isLoading}
					style={{ gridColumn: "1 / -1" }}
					outlined
					margin="none"
					rows={2}
					value={values.note}
					error={getFieldError("note")}
					onChange={(note) => setFieldValue("note", note)}
				/>
			</FieldContainer>
			{!isOkDisabled && (
				<ButtonContainer>
					<KodenButton
						style={{ marginRight: "1rem" }}
						disabled={isCancelDisabled}
						onClick={async () => {
							if (window.confirm("確定取消並且回到上一頁？")) {
								onCancel();
							}
						}}
					>
						取消
					</KodenButton>
					<MooSubmitButton permission={Permission.ManufacturerForeignUpdate} />
				</ButtonContainer>
			)}
		</form>
	);
}

export function MooManufacturerForeignOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.ManufacturerForeignRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
