import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    number
    type_id
    name
    gender
    birthday
    email
    line
    job
    vip
    address
    bank_account
    bank
    bank_account_number
    tax_id
    note
    tels {
      customer_tel_id
      tel
    }
`;

export const query = gql`
    query ($id: uuid!) {
        customers_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
