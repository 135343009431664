import { gql } from "@apollo/client";
import * as _ from "lodash";
import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { useArchiveResource } from "../moo-hooks";
import { useMe } from "../moo-me-provider";
import { MooMessageCard } from "../moo-message-card";
import { useSuccess } from "../moo-notification-provider";

export type MooMessageViewProps = {
	resource: string;
	id: string;
	messageIdName: string;
};

type User = {
	id: string;
	color: string;
};

type Employee = {
	id: string;
	name: string;
	user: User;
};

type Message = {
	content: string;
	create_date: string;
	employee: Employee;
};

type Schedule = {
	id: string;
	status: string;
	branch_id: string;
	content: string;
	create_date: string;
	messages: Message[];
};

export function getQuery(resource: string, messageIdName: string) {
	const operationName = `${resource}_by_pk`;
	const singular = resource.slice(0, -1);
	const childResource = `${singular}_messages`;

	return gql`
      query($id: uuid!, $messageWhere: ${childResource}_bool_exp, $messageOrderBy: [${childResource}_order_by!]){
				${operationName}(id: $id){
					id
					status
					branch_id
					branch {
						name
					}
					content
					employee_id
					create_date
					messages(where: $messageWhere, order_by: $messageOrderBy){
						${messageIdName}
						content
						create_date
						employee {
							id
      				name
							user {
								id
								color
							}
						}
					}
				}
      }
	`;
}

export function getVariables(id: string) {
	return {
		id,
		messageWhere: {
			status: {
				_eq: "PRESENT",
			},
		},
		messageOrderBy: {
			create_date: "desc",
		},
	};
}

export function getOperationName(resource: string) {
	return `${resource}_by_pk`;
}

export function MooMessageView({ resource, id, messageIdName }: MooMessageViewProps) {
	const operationName = getOperationName(resource);
	const singular = resource.slice(0, -1);
	const childResource = `${singular}_messages`;
	const success = useSuccess();
	const archiveResource = useArchiveResource(childResource);
	const me = useMe()!;
	const { value: branch } = useBranch();

	const record = useGraphQLQuery({
		query: getQuery(resource, messageIdName),
		variables: getVariables(id),
		operationName,
	});

	if (record.isLoading || record.isError) {
		return null;
	} else {
		// @ts-ignore
		const schedule: Schedule = record.data![operationName];
		if (schedule.status !== Status.Present) {
			return <div>此資料已被刪除</div>;
		} else if (schedule.branch_id !== branch) {
			return <div>此資料屬於{_.get(record, `data.${operationName}.branch.name`, "")}</div>;
		}
		return (
			<>
				{schedule.messages.map(({ employee, content, create_date, ...message }) => {
					// @ts-ignore
					const id = message[messageIdName];
					const owner = employee.id === me.employee.id;

					return (
						<MooMessageCard
							key={id}
							id={id}
							userName={employee.name}
							userBackgroundColor={employee.user.color}
							value={content}
							createDate={create_date}
							edit={false}
							remove={owner}
							onRemoveClick={async () => {
								if (await archiveResource({ id, idName: messageIdName })) {
									await record.refetch();
									success("刪除成功！");
								}
							}}
						/>
					);
				})}
			</>
		);
	}
}
