import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "type",
			label: "類型",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "name",
			label: "廠商名稱",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "contact",
			label: "聯絡人",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
		{
			id: "tel",
			label: "電話",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "email",
			label: "Email",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "delete",
			label: "刪除",
			align: "center",
		},
	];
}
