import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { Fragment, useState } from "react";
import styled from "styled-components";
import { One } from "..";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { useItemDialogChoose } from "../../moo-dialog-item-choose";
import { MooDialogOrderGoodsAndPurchaseGoodsChoose } from "../../moo-dialog-order-goods-and-purchase-goods-choose";
import { MooEmployeeSelectField } from "../../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../../moo-enum-typeable-select-field";
import { importGoods } from "../../moo-enums";
import { MooLinkButton } from "../../moo-link-button";
import { MooLinkField } from "../../moo-link-field";
import { ManufacturerIdSelectField, ManufacturerTypeSelectField, useGetFieldError } from "../../moo-shared";
import { MooVoucherLinkList } from "../../moo-voucher-link-list";
import FieldContainer from "../components/FieldContainer";
import ItemList from "../components/ItemList";
import { useForm } from "../hooks/useForm";
import { useLockStock } from "../hooks/useLockStock";
import { useUpdate } from "../hooks/useUpdate";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const FileUpload = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 0.3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.6rem;
	align-items: center;
	padding: 14px;
	min-height: 56px;
	text-align: center;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto 1fr;
		grid-column-end: span 2;
		text-align: left;
		padding: 0 14px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

export function BasicBlock() {
	const [openDoubleDialog, setDoubleDialogOpen] = useState(false);
	const { link, updateSalesId, updateManufacturerType, updateManufacturerId, chooseItems } = useUpdate();
	const { values, setFieldValue } = useForm();
	const { component, open } = useItemDialogChoose("order-item-dialog-choose", chooseItems);
	const lockStock = useLockStock();
	const danLabel = values.type === importGoods.Type.OrderGoods ? "訂貨單" : "國外採購單";
	const getFieldError = useGetFieldError<One>();

	return (
		<>
			{component}
			<MooDialogOrderGoodsAndPurchaseGoodsChoose
				id="MooDialogOrderGoodsAndPurchaseGoodsChoose"
				open={openDoubleDialog}
				onOk={async (chooseType, dataSet) => {
					await link(chooseType, dataSet);
					setDoubleDialogOpen(false);
				}}
				onClose={() => {
					setDoubleDialogOpen(false);
				}}
			/>
			<FieldContainer>
				<KodenDateField
					id="import_date"
					label="進貨日期"
					error={getFieldError("import_date")}
					value={new Date(values.import_date)}
					onChange={(value) => {
						setFieldValue(
							"import_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						);
					}}
				/>
				{values.order_ids.length !== 0 && (
					<MooLinkField clickButton={<Fragment />} label="連結訂單" values={values.order_ids.map((e) => e.value)} />
				)}

				<FileUpload>
					<KodenButton
						disabled={false}
						onClick={async () => {
							setDoubleDialogOpen(true);
						}}
					>
						匯入訂貨單/國外採購單
					</KodenButton>
					{values.type !== importGoods.Type.Direct && values.link_id !== "" && (
						<div style={{ marginLeft: "1rem" }}>
							<MooLinkButton id={values.link_id} name={danLabel} />
						</div>
					)}
				</FileUpload>
			</FieldContainer>
			<FieldContainer>
				<MooEmployeeSelectField
					id="sales_id"
					label="業務"
					value={values.sales_id}
					error={getFieldError("sales_id")}
					onChange={async (id) => {
						if (id) {
							await updateSalesId(id);
						}
					}}
				/>
				<ProxyKodenTextField
					id="sales_tel"
					label="業務電話"
					value={values.sales_tel}
					readOnly
					disabled
					outlined
					margin="none"
					error=""
					onChange={() => {}}
				/>
				<ManufacturerTypeSelectField
					id="manufacturer_type"
					value={values.manufacturer_type}
					error={getFieldError("manufacturer_type")}
					onChange={updateManufacturerType}
				/>
				<ManufacturerIdSelectField
					id="manufacturer_name"
					type={values.manufacturer_type}
					label="廠商"
					value={values.manufacturer_id}
					error={getFieldError("manufacturer_id")}
					onChange={async (id) => {
						if (id) {
							await updateManufacturerId(id);
						}
					}}
				/>
			</FieldContainer>
			<FieldContainer>
				<MooEnumTypeableSelectField
					id="warehouse_id"
					resource="warehouses"
					label="收貨倉庫"
					value={values.warehouse_id}
					disabled={lockStock}
					error={getFieldError("warehouse_id")}
					onChange={(warehouse_id) => {
						setFieldValue("warehouse_id", warehouse_id);
					}}
				/>
				<TextFieldEnd
					id="note"
					label="備註"
					outlined
					margin="none"
					value={values.note}
					error=""
					onChange={(note) => setFieldValue("note", note || "")}
				/>
			</FieldContainer>
			<MooVoucherLinkList values={values.voucher_rows.map((e) => e.voucher_id)} />
			<div style={{ marginBottom: "2rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>進貨商品</h1>
					<KodenIconTextButton
						disabled={values.type !== importGoods.Type.Direct || lockStock}
						text="新增商品"
						onClick={() => open()}
					/>
				</header>
				<ItemList />
			</div>
		</>
	);
}
