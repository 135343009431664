import { gql } from "@apollo/client";
import { useGraphQLMutation } from "../../../moo-graphql-provider";
import { useError, useSuccess } from "../../../moo-notification-provider";

export function useUpdateRecordMutation(resource: string) {
	const success = useSuccess();
	const error = useError();

	const mutation = gql`
      mutation ($set: ${resource}_set_input, $where: ${resource}_bool_exp!) {
				update_${resource}(_set: $set, where: $where) {
					affected_rows
				}
      }
	`;

	const graphQLMutation = useGraphQLMutation({ mutation });

	return async (id: string, columnId: string, value: string) => {
		return graphQLMutation.mutate(
			{
				set: {
					[columnId]: value,
				},
				where: {
					id: {
						_eq: id,
					},
				},
			},
			{
				onSuccess() {
					success("更新成功");
				},
				onError() {
					error("更新失敗");
				},
			},
		);
	};
}
