import { object, SchemaOf } from "yup";
import { requestBuy } from "../../moo-enums";
import {
	arrayOptionalSchema,
	arrayRequiredSchema,
	enumRequired,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { itemInfoSchema } from "./itemInfoSchema";
import { itemSchema } from "./itemSchema";
import { orderIdSchema } from "./orderIdSchema";
import { voucherRowSchema } from "./voucherRowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: statusSchema,
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	state_purchase: enumRequired(requestBuy.StatePurchase.Value),
	propose_date: stringRequired("propose_date"),
	order_ids: arrayOptionalSchema(orderIdSchema),
	sales_id: stringRequired("sales_id"),
	sales_tel: stringOptional,
	foreign_manufacturer_id: stringRequired("foreign_manufacturer_id"),
	foreign_manufacturer_tel: stringOptional,
	foreign_manufacturer_fax: stringOptional,
	foreign_manufacturer_email: stringOptional,
	// @ts-ignore
	item_infos: arrayRequiredSchema(itemInfoSchema),
	note: stringOptional,
	items: arrayRequiredSchema(itemSchema, "items", "尚未匯入請購商品"),
	voucher_rows: arrayOptionalSchema(voucherRowSchema),
});
