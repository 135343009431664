import { generatePath, useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooRequestBuyAdd } from "../../components/moo-components/moo-request-buy-add";
import { path as listPath } from "./ListRoute";
import { path as onePath } from "./UpdateRoute";

export const path = "/new-request-buy";

export const NewRoute = () => {
	const navigate = useNavigate();
	const branch = useBranchInQuery();

	return (
		<MooRequestBuyAdd
			onSuccess={async (id) => {
				await navigate(`${generatePath(onePath, { id })}?branch=${branch}`);
			}}
			onCancel={async () => {
				await navigate(`${listPath}?branch=${branch}`);
			}}
		/>
	);
};

export default NewRoute;
