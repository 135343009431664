import { KodenDialog } from "@yachelee/koden-components/koden-dialog";
import { useEffect, useState } from "react";
import { MooWarehouseStockList } from "../moo-warehouse-stock-list";
import { useInternalState } from "./useInternalState";

export function WarehouseItemListDialog() {
	const { warehouseId, itemIds, isOpen, setIsOpen, onChange } = useInternalState();
	const [dialogWarehouseId, setDialogWarehouseId] = useState<string | null>(warehouseId);
	useEffect(() => {
		setDialogWarehouseId(warehouseId);
	}, [warehouseId]);

	return (
		<KodenDialog
			id="moo-warehouse-field-dialog"
			title="倉庫選擇"
			showOkButton
			showCancelButton
			open={isOpen}
			onClose={() => setIsOpen(false)}
			disabledOk={false}
			isLoading={false}
			maxWidth={false}
			onOk={() => {
				setIsOpen(false);
				onChange(dialogWarehouseId);
			}}
		>
			<div style={{ minWidth: 500, minHeight: 500, paddingTop: 12 }}>
				<MooWarehouseStockList
					id={dialogWarehouseId}
					itemIds={itemIds}
					onIdChange={setDialogWarehouseId}
					showItemIds={false}
				/>
			</div>
		</KodenDialog>
	);
}
