import produce from "immer";
import { useForm } from "../useForm";

export function useUpdateItemCount() {
	const { setValues, values } = useForm();

	return (id: string, count: number) => {
		setValues(
			produce(values, (draft) => {
				draft.items.forEach((item) => {
					if (item.id === id) {
						item.count = count;
						item.purchase_price_total = item.purchase_price * item.count;
					}
				});
			}),
		);
	};
}
