import { createContext, useContext } from "react";
import { Props } from "../@types/props";
import { DEFAULT_ONE } from "../DEFAULT_ONE";

export const context = createContext<Props>({
	isEdit: false,
	isCancelDisabled: false,
	isOkDisabled: false,
	onCancel() {},
	initialValues: DEFAULT_ONE(),
	onSubmit() {},
	okText: "",
});

export function useProps() {
	return useContext(context);
}
