import { PdfStyle } from "./useOnBalanceSheetButtonClick";

export function updateHeaderRatio({ headerData, totalSum }: { headerData: any[]; totalSum: number }) {
	const text = headerData[2].text as string;
	const number = Number(text.replace(/,/g, ""));
	const ratio = (number / totalSum) * 100;

	headerData[4] = {
		text: number === 0 ? "0%" : ratio.toFixed(2) + "%",
		style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
	};

	if (number < 0) {
		headerData[4].style.push(PdfStyle.ColorRed);
	}
}
