import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class PurchaseOtherFilesTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "purchase_order_other_file_id";
	}

	getTableName(): string {
		return "purchase_order_other_files";
	}

	getPathName(): string {
		return "other_files";
	}

	getParentIdName(): string {
		return "purchase_order_id";
	}

	getToUpdateColumns(): string[] {
		return ["value"];
	}
}
