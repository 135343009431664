import { useListGraphQLQuery } from "../moo-shared";

export function useGraphQLQuery() {
	return useListGraphQLQuery(
		"roles",
		`{
				id
				name
		}
	`,
	);
}
