import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class ExportGoodsTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "export_goods";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "state":
			case "type":
			case "export_date":
			case "order_id":
			case "type_id":
			case "customer_id":
			case "sales_id":
			case "address":
			case "order_description":
			case "order_note":
			case "export_goods_note":
			case "shipping_fee":
				return true;
		}
		return false;
	}
}
