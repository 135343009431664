export enum Value {
	MAINTAINING = "MAINTAINING",
	MAINTAINED_AND_IMPORTED = "MAINTAINED_AND_IMPORTED",
	DONE = "DONE",
}

export enum Label {
	MAINTAINING = "維修中",
	MAINTAINED_AND_IMPORTED = "維修完成並已入庫",
	DONE = "處理完成",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.MAINTAINING:
			return Label.MAINTAINING;
		case Value.MAINTAINED_AND_IMPORTED:
			return Label.MAINTAINED_AND_IMPORTED;
		case Value.DONE:
			return Label.DONE;
	}
	return Label.MAINTAINING;
}
