import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import produce from "immer";
import { Branch } from "../moo-branch-provider";
import { Permission } from "../moo-me-provider";
import { uuidv4 } from "../moo-shared";
import { useForm } from "./useForm";

type Props = {
	permission: Permission;
	label: string;
	branch_id: Branch;
};

export function PermissionCheckboxField({ permission, label, branch_id }: Props) {
	const { values: one, setFieldValue } = useForm();
	const branchPermissions: Permission[] = one.permissions
		.filter((e) => e.branch_id === branch_id)
		.map((e) => e.permission);

	return (
		<FormControlLabel
			label={label}
			control={
				<Checkbox
					checked={branchPermissions.includes(permission)}
					onChange={({ target: { checked } }) => {
						setFieldValue(
							"permissions",
							produce(one.permissions, (draft) => {
								if (checked) {
									draft.push({
										role_permission_id: uuidv4(),
										branch_id,
										permission,
									});
								} else {
									const index = draft.findIndex((e) => e.permission === permission);
									draft.splice(index, 1);
								}
							}),
						);
					}}
				/>
			}
		/>
	);
}
