import type { HeaderType } from "@yachelee/koden-components/koden-table";
import { useArrayItems } from "./useArrayItems";

function useCheckbox(): HeaderType {
	return {
		id: "checkbox",
		label: "",
		align: "left",
		style: { minWidth: 30 },
	};
}

export function useHeaders(): HeaderType[] {
	const checkbox = useCheckbox();
	return [
		checkbox,
		...useArrayItems().map((e) => ({ ...e, align: "left", style: { minWidth: 65 } })),
		{
			id: "image",
			label: "",
			align: "left",
			style: { minWidth: 30 },
		},
	] as HeaderType[];
}
