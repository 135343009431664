import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as customerTypesPath } from "../../../routes/customer/CustomerTypesRoute";
import { path as customerListPath } from "../../../routes/customer/ListRoute";
import { path as NewCustomerRoutePath } from "../../../routes/customer/NewRoute";
import { path as UpdateCustomerRoute } from "../../../routes/customer/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useCustomers(): ListType[] {
	const historyPushWithBranch = useHistoryPushWithBranch();
	const permissions = usePermissions();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();
	if (!permissions.includes(Permission.CustomerListRead) && !permissions.includes(Permission.CustomerTypeWrite)) {
		return [];
	}

	const children: any[] = [];
	if (permissions.includes(Permission.CustomerListRead)) {
		children.push({
			id: customerListPath,
			txt: getPageTree()[customerListPath].useText(),
			icon: "",
			isSelected: isSelected([customerListPath, NewCustomerRoutePath, UpdateCustomerRoute]),
			async onClick() {
				await historyPushWithBranch(customerListPath);
			},
		});
	}

	if (permissions.includes(Permission.CustomerTypeWrite)) {
		children.push({
			id: customerTypesPath,
			txt: getPageTree()[customerTypesPath].useText(),
			icon: "",
			isSelected: isSelected([customerTypesPath]),
			async onClick() {
				await historyPushWithBranch(customerTypesPath);
			},
		});
	}

	if (children.length === 0) {
		return [];
	}

	return [
		{
			id: "customer",
			txt: "客戶",
			icon: Utils.getAbsoluteStaticUrl("icons/customer.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleCustomer();
			},
			open: true,
			openChildren: navigationStore.customer,
			children,
		},
	];
}
