import { Calculator } from "../../moo-quotation-one";
import { One } from "../../moo-return-goods-one";
import { getItemSpec, getItemType } from "../../moo-shared";
import { Row } from "./Row";
import { RecordOrderItem } from "./RowOrder";

export function mapItems(record: Row): One["items"] {
	const orderItemMap: Map<string, RecordOrderItem> = record.order.items.reduce((prev, orderItem) => {
		prev.set(orderItem.id, orderItem);
		return prev;
	}, new Map());

	return record.items
		.filter((e) => orderItemMap.has(e.id))
		.map((recordItem) => {
			const id = recordItem.id;
			const item = recordItem.item_spec || recordItem.item_custom;
			const itemType = getItemType(recordItem.id);
			const orderItem = orderItemMap.get(id)!;
			const purchase_price = item.purchase_price;
			const count = orderItem.count || 0;
			const price_final = orderItem.price_final;
			const price_total = Calculator.getItemPriceTotal({ price_final, count });

			return {
				return_good_item_id: recordItem.return_good_item_id,
				id,
				manufacturer_type: item.manufacturer.type,
				manufacturer_id: item.manufacturer.id,
				manufacturer: item.manufacturer.name,
				category: item.category.name,
				type: itemType,
				name: item.name,
				cost: item.cost,
				spec: getItemSpec(item),
				material: item.material,
				color: item.color,
				size: item.size,
				count,
				price: item.price,
				price_final,
				price_total,
				purchase_price,
				purchase_price_total: Calculator.getItemPurchasePriceTotal({ purchase_price, count }),
				note: item.note,
				image: item.image,
				image_cut: item.image_cut,
				return_count: recordItem.return_count,
				warehouse_id: recordItem.warehouse_id,
				priority: recordItem.priority,
			};
		});
}
