import type { AxiosInstance } from "axios";
import { createContext, ReactNode } from "react";

export type MooGraphqlProviderProps = {
	url: string;
	ajax: AxiosInstance;
	children: ReactNode;
};

export const Context = createContext({
	url: "",
	ajax: null as unknown as AxiosInstance,
});

export function MooGraphqlProvider({ url, ajax, children }: MooGraphqlProviderProps) {
	return <Context.Provider value={{ url, ajax }}>{children}</Context.Provider>;
}
