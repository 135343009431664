import { One } from "../../moo-allowance-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";

export function mapperInitialValues(record: Row): One {
	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		state_accountant_id: record.state_accountant_id,
		state_accountant: record.state_accountant,
		state_manager: record.state_manager,
		state_requester: record.state_requester,
		request_date: record.request_date,
		requester_id: record.requester_id,
		reason: record.reason,
		amount: record.amount,
		order_id: record.order_id,
	};
}
