import { ReactNode, useState } from "react";
import { MooDialogItemChoose, Row } from "../index";
import useStore from "../stores/useStore";

type ReturnType = {
	component: ReactNode;
	open: () => void;
	close: () => void;
};

export function useItemDialogChoose(id: string, onOk: (rows: Row[]) => void): ReturnType {
	const { clearChoose } = useStore();
	const [open, setOpen] = useState(false);
	const close = () => {
		setOpen(false);
	};

	const component = (
		<MooDialogItemChoose
			id={id}
			open={open}
			onClose={close}
			onOk={async (rows) => {
				onOk(rows);
				close();
			}}
		/>
	);

	return {
		component,
		open: () => {
			clearChoose();
			setOpen(true);
		},
		close,
	};
}
