import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "訂購單號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "type_name",
			label: "類型",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "creator_name",
			label: "創建人",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "customer_name",
			label: "客戶",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "sales_name",
			label: "業務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "description",
			label: "訂單描述",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state",
			label: "狀態",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "state_manager",
			label: "主管簽核",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state_accountant",
			label: "會計確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state_warehouse",
			label: "倉管確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "total_price",
			label: "訂單金額",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "return_goods_ids",
			label: "退貨單",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
	];
}
