import { object, SchemaOf } from "yup";
import { manufacturer, orderGoods } from "../../moo-enums";
import {
	arrayOptionalSchema,
	arrayRequiredSchema,
	enumRequired,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One, Type } from "../@types/one";
import { instructionSchema } from "./instructionSchema";
import { itemSchema } from "./itemSchema";
import { voucherRowSchema } from "./voucherRowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: statusSchema,
	state: enumRequired(orderGoods.State.Value),
	state_warehouse: enumRequired(orderGoods.StateWarehouse.Value),
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	type: enumRequired(Type),
	order_goods_date: stringRequired("order_goods_date"),
	deliver_goods_date: stringRequired("deliver_goods_date"),
	order_id: stringOptional,
	sales_id: stringRequired("sales_id"),
	sales_name: stringOptional,
	sales_tel: stringOptional,
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer_id: stringRequired("manufacturer_id"),
	manufacturer_name: stringOptional,
	manufacturer_tel: stringOptional,
	manufacturer_fax: stringOptional,
	manufacturer_email: stringOptional,
	warehouse_id: stringRequired("warehouse_id"),
	note: stringOptional,
	items: arrayRequiredSchema(itemSchema, "items", "尚未匯入訂貨商品"),
	instructions: arrayOptionalSchema(instructionSchema),
	voucher_rows: arrayOptionalSchema(voucherRowSchema),
});
