import produce from "immer";
import { uuidv4 } from "../../moo-shared";
import { One } from "../@types/one";
import { useForm } from "./useForm";

export function useUpdate() {
	const { values, setFieldValue } = useForm();

	function add(fieldName: keyof One, toAddValues: string[], idName: string) {
		setFieldValue(
			fieldName,
			produce(values[fieldName], (draft) => {
				// @ts-ignore
				const existingSet = new Set([...draft.map((e) => e.value)]);

				toAddValues.forEach((value) => {
					if (!existingSet.has(value)) {
						// @ts-ignore
						draft.push({
							[idName]: uuidv4(),
							value,
						});
					}
				});
			}),
		);
	}

	function remove(fieldName: keyof One, id: string) {
		setFieldValue(
			fieldName,
			produce(values[fieldName], (draft) => {
				// @ts-ignore
				const index = draft.findIndex((e) => e.value === id);
				if (index !== -1) {
					// @ts-ignore
					draft.splice(index, 1);
				}
			}),
		);
	}

	function addLinks(ids: string[]) {
		add("links", ids, "blog_link_id");
	}

	function removeLink(id: string) {
		remove("links", id);
	}

	function addAttach(fileName: string) {
		add("attaches", [fileName], "blog_attach_id");
	}

	function deleteAttach(fileName: string) {
		remove("attaches", fileName);
	}

	return {
		addLinks,
		removeLink,
		addAttach,
		deleteAttach,
	};
}
