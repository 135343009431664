import { object, SchemaOf } from "yup";
import {
	arrayOptionalSchema,
	arrayRequiredSchema,
	dateRequired,
	statusSchema,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { attachSchema } from "./attachSchema";
import { linkSchema } from "./linkSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: statusSchema,
	schedule_id: stringRequired("schedule_id"),
	schedule_start_date: dateRequired("schedule_start_date"),
	schedule_end_date: dateRequired("schedule_end_date"),
	date: dateRequired("date"),
	// @ts-ignore
	links: arrayRequiredSchema(linkSchema),
	attaches: arrayOptionalSchema(attachSchema),
	content: stringRequired("content"),
});
