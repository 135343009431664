import { MooItemSpecOne, One } from "../moo-item-spec-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { Table } from "./updateOne/Table";

export type MooItemSpecUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooItemSpecUpdate({ id, onCancel }: MooItemSpecUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="item-spec-one"
			query={query}
			tableName="item_specs"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooItemSpecOne}
			tables={[Table]}
		/>
	);
}
