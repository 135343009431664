import type { CellType } from "@yachelee/koden-components/koden-table";
import { useOnLinkClick } from "../../moo-link-provider";
import { ClickableColumn } from "../../moo-shared";
import { Row } from "../@types";

export function useCells(): CellType[] {
	const onLinkClick = useOnLinkClick();
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "export_date",
			align: "left",
			component: ({ export_date }: Row) => {
				return <div>{export_date}</div>;
			},
		},
		{
			id: "export_time",
			align: "left",
			component: ({ export_time }: Row) => {
				return <div>{export_time}</div>;
			},
		},
		{
			id: "customer_name",
			align: "left",
			component: ({ customer }: Row) => {
				return <div>{customer.name}</div>;
			},
		},
		{
			id: "driver_name",
			align: "left",
			component: ({ driver }: Row) => {
				return <div>{driver.name}</div>;
			},
		},
		{
			id: "sales_name",
			align: "left",
			component: ({ sales }: Row) => {
				return <div>{sales.name}</div>;
			},
		},
		{
			id: "address",
			align: "left",
			component: ({ address }: Row) => {
				return <div>{address}</div>;
			},
		},
		{
			id: "warehouse_names",
			align: "left",
			component: ({ warehouse_names }: Row) => {
				if (warehouse_names.length === 0) {
					return null;
				}
				return (
					<>
						{warehouse_names.map((name) => (
							<div key={name}>{name}</div>
						))}
					</>
				);
			},
		},
		{
			id: "order_id",
			align: "left",
			component: ({ order_id }: Row) => {
				if (order_id === "") {
					return null;
				}
				return <ClickableColumn onClick={() => onLinkClick(order_id)}>{order_id}</ClickableColumn>;
			},
		},
	];
}
