import { MooGqlChildNewTable } from "../../moo-gql";
import { Order } from "../findOne/Order";

export class OrderInstructionsTable extends MooGqlChildNewTable<Order> {
	getIdName(): string {
		return "order_instruction_id";
	}

	getTableName(): string {
		return "order_instructions";
	}

	getPathName(): string {
		return "instructions";
	}

	getParentIdName(): string {
		return "order_id";
	}

	getToUpdateColumns(): string[] {
		return ["value"];
	}
}
