import produce from "immer";
import { uuidv4 } from "../../moo-shared";
import { useForm } from "./useForm";

export function useUpdate() {
	const { setFieldValue, values } = useForm();

	function addLinks(toAddTags: string[]) {
		setFieldValue(
			"links",
			produce(values.links, (draft) => {
				const existingTagSet = new Set([...draft.map((e) => e.value)]);

				toAddTags.forEach((tag) => {
					if (!existingTagSet.has(tag)) {
						draft.push({
							schedule_link_id: uuidv4(),
							value: tag,
						});
					}
				});
			}),
		);
	}

	function removeLink(tag: string) {
		setFieldValue(
			"links",
			produce(values.links, (draft) => {
				const index = draft.findIndex((e) => e.value === tag);
				if (index !== -1) {
					draft.splice(index, 1);
				}
			}),
		);
	}

	return {
		addLinks,
		removeLink,
	};
}
