import type { CellType } from "@yachelee/koden-components/koden-table";
import { importGoods } from "../../moo-enums";
import { MooImportGoodsStateAccountantSelect } from "../../moo-import-goods-state-accountant-select";
import { useOnLinkClick } from "../../moo-link-provider";
import { ClickableColumn, DateFormat } from "../../moo-shared";
import { Row } from "../@types";
import { useUpdateAccountantState } from "./useUpdateAccountantState";

export function useCells({ crud }: { crud: boolean }): CellType[] {
	const onLinkClick = useOnLinkClick();
	const updateAccountantState = useUpdateAccountantState();

	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "sales_name",
			align: "left",
			component: ({ sales_name }: Row) => {
				return <div>{sales_name}</div>;
			},
		},
		{
			id: "manufacturer",
			align: "left",
			component: ({ manufacturer }: Row) => {
				return <div>{manufacturer}</div>;
			},
		},
		{
			id: "import_date",
			align: "left",
			component: ({ import_date }: Row) => {
				return <DateFormat>{import_date}</DateFormat>;
			},
		},
		{
			id: "state",
			align: "left",
			component: ({ state }: Row) => {
				return <div>{importGoods.State.getLabel(state)}</div>;
			},
		},
		{
			id: "state_accountant",
			align: "left",
			component: ({ id, state_accountant }: Row) => {
				if (crud) {
					return (
						<MooImportGoodsStateAccountantSelect
							value={state_accountant}
							onChange={(value) => updateAccountantState({ id, value })}
						/>
					);
				}
				return <div>{importGoods.StateAccountant.getLabel(state_accountant)}</div>;
			},
		},
		{
			id: "name",
			align: "left",
			component: ({ name }: Row) => {
				return <div>{name}</div>;
			},
		},
		{
			id: "link_id",
			align: "left",
			component: ({ link_id }: Row) => {
				if (link_id === "") {
					return null;
				}
				return <ClickableColumn onClick={() => onLinkClick(link_id)}>{link_id}</ClickableColumn>;
			},
		},
	];
}
