import { useParams } from "react-router-dom";
import { MooItemCategorySub } from "../components/moo-components/moo-item-category-sub";

export const prefixPath = "/item/category";
export const path = prefixPath + "/:id";

const ItemSubCategoryRoute = () => {
	const { id } = useParams<{ id: string }>();

	return <MooItemCategorySub parent={id!} />;
};

export default ItemSubCategoryRoute;
