export enum Value {
	NotConfirmed = "NOT_CONFIRMED",
	Confirmed = "CONFIRMED",
}

export enum Label {
	NotConfirmed = "未確認",
	Confirmed = "已確認",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.NotConfirmed:
			return Label.NotConfirmed;
		case Value.Confirmed:
			return Label.Confirmed;
	}
	return Label.NotConfirmed;
}
