import { Fragment } from "react";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { getDateFormat } from "../moo-shared";
import { Query } from "./@types";
import { useCells } from "./useCells";
import { useConditions } from "./useConditions";
import { useHeaders } from "./useHeaders";

export type MooAllowanceListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooAllowanceList = ({ onAdd, onRowClick, store }: MooAllowanceListProps) => {
	const allowanceStore = store();
	const cells = useCells();
	const headers = useHeaders();
	const permissions = usePermissions();
	const conditions = useConditions(allowanceStore.query);

	if (!permissions.includes(Permission.AllowanceListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooList
			showAddButton={permissions.includes(Permission.AllowanceAdd)}
			addLabel="申請零用金"
			onAdd={onAdd}
			filterNode={<Fragment />}
			headers={headers}
			cells={cells}
			clear={allowanceStore.clear}
			query={allowanceStore.query}
			setQuery={allowanceStore.setQuery}
			onRowClick={onRowClick}
			resource="allowances"
			conditions={conditions}
			graphQLColumns={`
				{
					id
					request_date
					requester{
						name
					}
					reason
					amount
					state_accountant
					state_manager
					state_requester
				}
			`}
			mapData={(e) => {
				return { ...e, request_date: getDateFormat(e.request_date), requester: e.requester?.name || "" };
			}}
		/>
	);
};
