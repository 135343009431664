import { MooPurchaseOrderOne, One } from "../moo-purchase-order-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { PurchaseOrderFilesTable } from "./updateOne/PurchaseOrderFilesTable";
import { PurchaseOrderItemsTable } from "./updateOne/PurchaseOrderItemsTable";
import { PurchaseOrderManufacturerFilesTable } from "./updateOne/PurchaseOrderManufacturerFilesTable";
import { PurchaseOrderTable } from "./updateOne/PurchaseOrderTable";
import { PurchaseOtherFilesTable } from "./updateOne/PurchaseOtherFilesTable";

export type MooPurchaseOrderUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooPurchaseOrderUpdate({ id, onCancel }: MooPurchaseOrderUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="purchase-order-one"
			query={query}
			tableName="purchase_orders"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooPurchaseOrderOne}
			tables={[
				PurchaseOtherFilesTable,
				PurchaseOrderManufacturerFilesTable,
				PurchaseOrderFilesTable,
				PurchaseOrderItemsTable,
				PurchaseOrderTable,
			]}
		/>
	);
}
