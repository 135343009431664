import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { Query } from "../@types";

export function useConditions(query: Query) {
	const { value: branch } = useBranch();
	const conditions: Record<string, any> = {
		branch_id: {
			_eq: branch,
		},
		status: {
			_eq: Status.Present,
		},
	};

	if (query.owner_id !== null) {
		conditions.owner_id = {
			_eq: query.owner_id,
		};
	}

	if (query.category !== null) {
		conditions.category_id = {
			_eq: query.category,
		};
	}

	if (query.sub_category !== null) {
		conditions.sub_category_id = {
			_eq: query.sub_category,
		};
	}

	if (query.color !== null) {
		conditions.color = {
			_eq: query.color,
		};
	}

	if (query.material !== null) {
		conditions.material = {
			_eq: query.material,
		};
	}

	if (query.manufacturer_type !== null) {
		conditions.manufacturer_type = {
			_eq: query.manufacturer_type,
		};

		if (query.manufacturer_id !== null) {
			conditions.manufacturer_id = {
				_eq: query.manufacturer_id,
			};
		}
	}

	if (query.search !== null && query.search.trim() !== "") {
		const _similar = `%(${query.search.trim().split(" ").join("|")})%`;
		conditions._or = [
			{
				id: {
					_similar,
				},
			},
			{
				name: {
					_similar,
				},
			},
			{
				color: {
					_similar,
				},
			},
			{
				material: {
					_similar,
				},
			},
			{
				size: {
					_similar,
				},
			},
			{
				category: {
					name: {
						_similar,
					},
				},
			},
			{
				manufacturer: {
					name: {
						_similar,
					},
				},
			},
		];

		if (query.search === "國內" || query.search === "國外") {
			conditions._or.push({
				manufacturer: {
					type: {
						_eq: query.search === "國內" ? "DOMESTIC" : "FOREIGN",
					},
				},
			});
		}
	}

	return conditions;
}
