import { useFormikContext } from "formik";
import * as pdfMake from "pdfmake/build/pdfmake";
import { useCompanyInfo } from "../../../../hooks/useCompanyInfo";
import { pdfFonts, useFindBranchWarehouses, useImageMap } from "../../moo-config";
import { Customer, DEFAULT_ONE } from "../../moo-customer-one";
import { useMeNickName } from "../../moo-me-provider/hooks/useMeNickName";
import { getDateFormat, getDollarNumberWithCommas, useFindOneById } from "../../moo-shared";
import { One } from "../@types/one";

function useGetItemBody() {
	const { values } = useFormikContext<One>();
	const getWarehouses = useFindBranchWarehouses();

	return async (): Promise<any[][]> => {
		const warehouses = await getWarehouses();

		return values.items.map((item, index) => {
			return [
				(index + 1).toString(),
				item.id,
				{ image: item.id, width: 80 },
				item.name,
				item.spec,
				item.count,
				getDollarNumberWithCommas(item.price_final),
				getDollarNumberWithCommas(item.price_total),
				warehouses.find((e) => e.id === item.warehouse_id)?.name || "",
			];
		});
	};
}

function useGetBody() {
	const { values } = useFormikContext<One>();
	const getItemBody = useGetItemBody();

	return async () => {
		let arr: any[][] = [
			[{ text: "退貨商品", style: "header", colSpan: 9 }, {}, {}, "", "", "", "", "", ""],
			["編號", "商品編號", "圖片", "品名", "規格", "退貨數量", "售價", "總價", "收貨倉庫"],
		];

		arr = arr.concat(await getItemBody());

		arr.push([
			{},
			{},
			{},
			"",
			"",
			"",
			"",
			{
				table: {
					widths: ["*", "*"],
					body: [
						[
							{
								stack: [
									{ text: "合計" },
									{ text: "稅金" },
									{ text: "已退款" },
									{ text: "應退款" },
									{ text: "手續費" },
									{ text: "運費" },
									{ text: "雜項" },
								],
							},
							{
								stack: [
									{ text: getDollarNumberWithCommas(values.total_price) },
									{ text: getDollarNumberWithCommas(values.tax_price) },
									{ text: getDollarNumberWithCommas(values.received) },
									{ text: getDollarNumberWithCommas(values.receivables) },
									{ text: getDollarNumberWithCommas(values.handling_fee) },
									{ text: getDollarNumberWithCommas(values.freight) },
									{ text: getDollarNumberWithCommas(values.miscellaneous_charge) },
								],
							},
						],
					],
				},
				colSpan: 2,
				layout: "lightHorizontalLines",
			},
			"",
		]);

		arr.push([
			{
				table: {
					body: [
						[
							{
								text: "備註",
								bold: true,
								margin: [0, 2],
							},
						],
					],
				},
				colSpan: 7,
				layout: "noBorders",
			},
			{},
			{},
			"",
			"",
			"",
			"",
			{
				table: {
					widths: ["*"],
					body: [
						[
							{
								text: "客戶確認簽名",
								margin: [0, 3],
								bold: true,
								border: [false, false, false, false],
							},
						],
						[
							{
								text: "",
								margin: [0, 30],
								border: [false, false, false, true],
							},
						],
						[
							{
								text: "倉管簽名",
								margin: [0, 15, 0, 3],
								bold: true,
								border: [false, false, false, false],
							},
						],
						[
							{
								text: "",
								margin: [0, 30],
								border: [false, false, false, true],
							},
						],
					],
				},
				colSpan: 2,
				layout: {
					defaultBorder: false,
				},
			},
			{},
		]);
		return arr;
	};
}

export function useOnPdfClick() {
	const getBody = useGetBody();
	const { values, isValid } = useFormikContext<One>();
	const imageMap = useImageMap({ items: values.items });
	const findCustomerById = useFindOneById<Customer>(DEFAULT_ONE(), "customers");
	const { address, tel, fax } = useCompanyInfo();
	const nickName = useMeNickName();

	return async (id: string) => {
		if (isValid) {
			const FILE_NAME = `退貨單-${id}`;
			const customer = await findCustomerById(values.customer_id);

			const content = [
				{
					style: "defaultTable",
					table: {
						headerRows: 1,
						widths: [80, "*", "auto"],
						body: [
							[
								{ image: "logo", width: 51, height: 64, margin: [0, 10, 0, 0] },
								[
									{ text: address, margin: [0, 18, 0, 3] },
									{ text: `TEL:${tel}`, margin: [0, 3] },
									{ text: `FAX:${fax}`, margin: [0, 3] },
								],
								[
									{
										text: `退貨單${id}${nickName}`,
										fontSize: 16,
										bold: true,
										margin: [0, 11, 0, 3],
									},
									{
										columns: [
											{ text: "業務", width: "auto", margin: [0, 3] },
											{ text: values.sales_name, width: "*", margin: [0, 3] },
										],
									},
									{
										columns: [
											{ text: "電話", width: "auto", margin: [0, 3] },
											{ text: values.sales_tel, width: "*", margin: [0, 3] },
										],
									},
								],
							],
							[
								{
									stack: [
										{ text: "客戶", margin: [0, 3, 0, 3] },
										{ text: "地址", margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{ text: customer?.name || "", margin: [0, 3, 0, 3] },
										{ text: values.deliver_address, margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{
											columns: [
												{ text: "退貨日期", width: "auto", margin: [0, 3, 0, 3] },
												{ text: getDateFormat(values.return_date), width: "*", margin: [0, 3] },
											],
										},
									],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [{ text: "訂購單號", margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [{ text: values.order_id, margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
						],
					},
					layout: {
						defaultBorder: false,
						hLineColor: "silver",
					},
				},
				{
					style: "defaultTable",
					color: "#444",
					table: {
						widths: [20, 40, 80, 20, "*", 22, 50, 50, 22],
						headerRows: 1,
						body: await getBody(),
					},
					layout: "lightHorizontalLines",
				},
			];

			pdfMake
				.createPdf(
					{
						content,
						images: imageMap,
						styles: {
							header: {
								fontSize: 16,
								bold: true,
							},
							warning: {
								color: "red",
							},
							bigger: {
								fontSize: 15,
								italics: true,
							},
							defaultTable: {
								margin: [0, 5, 0, 5],
							},
							tableHeader: {
								bold: true,
								fontSize: 13,
								color: "grey",
							},
						},
						defaultStyle: {
							columnGap: 15,
							fontSize: 10,
							font: "chineseFont",
						},
					},
					undefined,
					pdfFonts,
				)
				.download(FILE_NAME);
		}
	};
}
