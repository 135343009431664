import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { prefixPath as defective_product } from "../routes/defective_product/UpdateRoute";
import { prefixPath as export_goods } from "../routes/export_goods/UpdateRoute";
import { prefixPath as import_goods } from "../routes/import_goods/UpdateRoute";
import { prefixPath as item_custom } from "../routes/item_custom/UpdateRoute";
import { prefixPath as item_spec } from "../routes/item_spec/UpdateRoute";
import { prefixPath as order } from "../routes/order/UpdateRoute";
import { prefixPath as order_goods } from "../routes/order_goods/UpdateRoute";
import { prefixPath as purchase_order } from "../routes/purchase_order/UpdateRoute";
import { prefixPath as quotation } from "../routes/quotation/UpdateRoute";
import { prefixPath as request_buy } from "../routes/request_buy/UpdateRoute";
import { prefixPath as return_goods } from "../routes/return_goods/UpdateRoute";
import { prefixPath as return_order } from "../routes/return_order/UpdateRoute";
import { prefixPath as voucher } from "../routes/voucher/UpdateRoute";

function getLinkPrefix(id = ""): string {
	if (id.indexOf("PO") === 0) {
		return purchase_order;
	} else if (id.indexOf("RO") === 0) {
		return return_order;
	} else if (id.indexOf("OG") === 0) {
		return order_goods;
	} else if (id.indexOf("RB") === 0) {
		return request_buy;
	} else if (id.indexOf("D") === 0) {
		return defective_product;
	} else if (id.indexOf("I") === 0) {
		return import_goods;
	} else if (id.indexOf("E") === 0) {
		return export_goods;
	} else if (id.indexOf("R") === 0) {
		return return_goods;
	} else if (id.indexOf("Q") === 0) {
		return quotation;
	} else if (id.indexOf("O") === 0) {
		return order;
	} else if (id.indexOf("S") === 0) {
		return item_spec;
	} else if (id.indexOf("C") === 0) {
		return item_custom;
	} else if (id.split("-").length === 4) {
		return voucher;
	}
	return "";
}

export function useOnLinkClick() {
	const branch = useBranchInQuery();

	return (id: string) => {
		window.location.href = `${getLinkPrefix(id)}/${id}?branch=${branch}`;
	};
}
