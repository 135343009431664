import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class ImportGoodsItemsTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "import_good_item_id";
	}

	getTableName(): string {
		return "import_good_items";
	}

	getPathName(): string {
		return "items";
	}

	getParentIdName(): string {
		return "import_good_id";
	}

	getToUpdateColumns(): string[] {
		return ["id", "count"];
	}
}
