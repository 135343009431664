import { DocumentNode, gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { manufacturer, Status } from "../moo-enums";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

export enum ItemType {
	ItemSpec = "item_specs",
	ItemCustom = "item_customs",
}

export type Value = string | null;

export type MooItemManufacturerSelectProps = {
	manufacturerType: manufacturer.Value.Value | null;
	itemType: ItemType;
	value: Value;
	onChange: (value: Value) => void;
	where?: any;
};

function getGraphQLQuery(itemType: ItemType): DocumentNode {
	const graphQLDistinct = `[${itemType}_select_column!]`;

	return gql`
    query($distinct: ${graphQLDistinct}, $where: ${itemType}_bool_exp) {
      ${itemType}(distinct_on: $distinct, where: $where) {
        manufacturer_type
        manufacturer_id
      }
      manufacturer_domestics {
        id
        name
      }
      manufacturer_foreigns{
        id
        name
      }
    }
  `;
}

export function MooItemManufacturerSelect({
	itemType,
	manufacturerType,
	value,
	onChange,
	where,
}: MooItemManufacturerSelectProps) {
	const { value: branch } = useBranch();
	const query = getGraphQLQuery(itemType);
	const variables = {
		distinct: ["manufacturer_type", "manufacturer_id"],
		where: where || {
			status: { _eq: Status.Present },
			branch_id: { _eq: branch },
		},
	};
	const { isLoading, data } = useGraphQLQuery<Record<string, any>>({
		operationName: "MooItemManufacturerSelect",
		query,
		variables,
	});
	if (isLoading || manufacturerType === null) {
		return null;
	}

	const manufacturerResource =
		manufacturerType === manufacturer.Value.Value.Domestic ? "manufacturer_domestics" : "manufacturer_foreigns";
	// @ts-ignore
	const manufacturerMap = data[manufacturerResource].reduce((prev, cur) => {
		return { ...prev, [cur.id]: cur.name };
	}, {});

	const options: { value: string | null; label: string }[] = isLoading
		? []
		: [
				{
					value: null,
					label: "請選擇",
				},
		  ].concat(
				((data?.[itemType] || []) as any[])
					.filter((e) => e.manufacturer_type === manufacturerType)
					.map(({ manufacturer_id }) => {
						return {
							id: manufacturer_id,
							value: manufacturer_id,
							label: manufacturerMap[manufacturer_id],
						};
					}) || [],
		  );

	return (
		<MooTypeableSelect
			id="item-manufacturer-select-field"
			label="廠商名稱"
			options={options}
			value={value}
			onChange={onChange}
		/>
	);
}
