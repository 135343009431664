import { KodenBinIcon } from "@yachelee/koden-components/koden-bin-icon";
import { KodenInlineEditText } from "@yachelee/koden-components/koden-inline-edit-text";
import { FC, Fragment, ReactElement } from "react";
import styled from "styled-components";
import { useArchiveResource } from "../../../../moo-hooks";
import { useError, useSuccess } from "../../../../moo-notification-provider";
import ColumnType from "../../@types/columnTypes";
import { OptionResponseType } from "../../@types/optionTypes";
import { useGraphQLQueryList } from "../../PanelList";
import useStateMap from "../../hooks/useStateMap";
import { useUpdateRecordMutation } from "../../hooks/useUpdateRecordMutation";

const Container = styled.div`
	display: grid;
	align-items: center;
	grid-auto-flow: column;
	grid-gap: 0.5rem;
`;

type Props = {
	resource: string;
	columns: ColumnType[];
	option: OptionResponseType;
	columnPlaceHolder?: (data: any) => ReactElement;
	filter: Record<string, any>;
};

const PanelListRow: FC<Props> = ({ resource, columns, option, columnPlaceHolder, filter }) => {
	const recordId = option.id;
	const success = useSuccess();
	const error = useError();
	const { refetch } = useGraphQLQueryList(resource, columns, filter);
	const [errorMap] = useStateMap(columns);
	const updateRecordMutation = useUpdateRecordMutation(resource);
	const removeMutation = useArchiveResource(resource);

	async function onRemoteUpdate(columnId: string, value: string, defaultValue: string) {
		if (value !== defaultValue) {
			await updateRecordMutation(recordId.toString(), columnId, value);
			window.setTimeout(async () => {
				await refetch();
			}, 500);
		}
	}

	return (
		<Container>
			{columns.map((column) => {
				const { id } = column;
				const key = `category_one_item_${resource}_${id}_${recordId}`;
				if ("type" in column && column.type === "ComponentType") {
					return (
						<Fragment key={key}>
							<column.component id={id} record={option} />
						</Fragment>
					);
				} else {
					// @ts-ignore
					const defaultValue = option[id] as string;

					return (
						<Fragment key={key}>
							<KodenInlineEditText
								variant="standard"
								id={`category_one_item_${resource}_${id}_${recordId}`}
								defaultValue={defaultValue}
								error={errorMap[id] as string}
								onConfirm={async (value) => {
									await onRemoteUpdate(id, value, defaultValue);
								}}
							/>
							{columnPlaceHolder && columnPlaceHolder(recordId)}
						</Fragment>
					);
				}
			})}
			<KodenBinIcon
				id="bin-button"
				onClick={async () => {
					try {
						if (await removeMutation({ id: recordId.toString() })) {
							await refetch();
							success("刪除成功!");
						}
					} catch (e) {
						error("刪除失敗");
					}
				}}
			/>
		</Container>
	);
};

export default PanelListRow;
