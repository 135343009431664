import { DocumentNode } from "@apollo/client";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

export type ValueType = string | null;

export type MooGraphqlSelectFieldProps = {
	id: string;
	label?: string;
	operationName: string;
	query: DocumentNode;
	variables: Record<any, any>;
	mapData: (data: any) => { results: { value: ValueType; label: string }[] };
	value: ValueType;
	onChange: (value: ValueType) => void;
	error?: string;
	disabled?: boolean;
};

export function MooGraphqlSelectField({
	id,
	disabled = false,
	label = "",
	error = "",
	value,
	onChange,
	operationName,
	query,
	variables,
	mapData,
}: MooGraphqlSelectFieldProps) {
	const { isLoading, data } = useGraphQLQuery({ operationName, query, variables });
	if (isLoading) {
		return null;
	} else {
		const { results } = mapData(data);
		return (
			<MooTypeableSelect
				id={id}
				label={label}
				error={error}
				// @ts-ignore
				options={results}
				value={value}
				onChange={(value) => {
					onChange(value || null);
				}}
				disabled={disabled}
			/>
		);
	}
}
