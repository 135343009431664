import { stockTransfer } from "../moo-enums";
import { Permission, usePermissions } from "../moo-me-provider";
import { useGetFieldError } from "../moo-shared";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { One } from "./@types/one";
import { useForm } from "./hooks/useForm";
import { useLockStock } from "./hooks/useLockStock";
import { FieldContainer } from "./moo-stock-transfer-one";

type ItemOption = {
	id: stockTransfer.State.Value.UnVerified | stockTransfer.State.Value.Verified;
	label: stockTransfer.State.Label.UnVerified | stockTransfer.State.Label.Verified;
};

type Item = {
	id: keyof One;
	label: string;
	permission: Permission;
	options: ItemOption[];
};

const arr: Item[] = [
	{
		id: "state",
		label: "狀態",
		permission: Permission.StockTransferStateWrite,
		options: [
			{
				id: stockTransfer.State.Value.UnVerified,
				label: stockTransfer.State.Label.UnVerified,
			},
			{
				id: stockTransfer.State.Value.Verified,
				label: stockTransfer.State.Label.Verified,
			},
		],
	},
];

export function StateBlock() {
	const { values, setFieldValue } = useForm();
	const lockStock = useLockStock();
	const getFieldError = useGetFieldError<One>();
	const permissions = usePermissions();

	return (
		<FieldContainer>
			{arr.map(({ id, label, options, permission }) => (
				<MooTypeableSelect
					key={id}
					id={id}
					label={label}
					disabled={(id === "state" && lockStock) || !permissions.includes(permission)}
					value={values[id] as string}
					error={getFieldError(id)}
					options={options.map((e) => {
						return { ...e, value: e.id };
					})}
					onChange={(value) => {
						setFieldValue(id, value);
					}}
				/>
			))}
		</FieldContainer>
	);
}
