import produce from "immer";
import { DEFAULT_ONE } from "./DEFAULT_ONE";

export const shape = produce(DEFAULT_ONE(), (draft) => {
	draft.items = [
		{
			svc_item_id: "",
			name: "",
			count: 1,
			price: 1,
		},
	];
});
