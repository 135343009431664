import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as PurchaseOrderListRoutePath } from "../../../routes/purchase_order/ListRoute";
import { path as PurchaseOrderNewRoutePath } from "../../../routes/purchase_order/NewRoute";
import { path as PurchaseOrderUpdateRoutePath } from "../../../routes/purchase_order/UpdateRoute";
import { path as RequestBuyListRoutePath } from "../../../routes/request_buy/ListRoute";
import { path as RequestBuyNewRoutePath } from "../../../routes/request_buy/NewRoute";
import { path as RequestBuyUpdateRoutePath } from "../../../routes/request_buy/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function usePurchases(): ListType[] {
	const permissions = usePermissions();
	const historyPushWithBranch = useHistoryPushWithBranch();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();

	const children = [];

	if (permissions.includes(Permission.RequestBuyListRead)) {
		children.push({
			id: RequestBuyListRoutePath,
			txt: getPageTree()[RequestBuyListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([RequestBuyListRoutePath, RequestBuyNewRoutePath, RequestBuyUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(RequestBuyListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.PurchaseOrderListRead)) {
		children.push({
			id: PurchaseOrderListRoutePath,
			txt: getPageTree()[PurchaseOrderListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([PurchaseOrderListRoutePath, PurchaseOrderNewRoutePath, PurchaseOrderUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(PurchaseOrderListRoutePath);
			},
		});
	}

	if (children.length === 0) {
		return [];
	}

	return [
		{
			id: "purchase",
			txt: "採購",
			icon: Utils.getAbsoluteStaticUrl("icons/purchase.svg"),
			isLine: false,
			async onClick() {
				navigationStore.togglePurchase();
			},
			open: true,
			openChildren: navigationStore.purchase,
			children,
		},
	];
}
