import { manufacturer, orderGoods, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One, Type } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();
	return {
		status: Status.Present,
		employee: null,
		state: orderGoods.State.Value.Customizing,
		state_warehouse: orderGoods.StateWarehouse.Value.UnConfirmed,
		type: Type.Directly,
		order_goods_date: now,
		deliver_goods_date: now,
		create_date: now,
		update_date: now,
		order_id: null,
		sales_id: null,
		sales_name: "",
		sales_tel: "",
		manufacturer_type: manufacturer.Value.Value.Domestic,
		manufacturer_id: null,
		manufacturer_name: "",
		manufacturer_tel: "",
		manufacturer_fax: "",
		manufacturer_email: "",
		warehouse_id: null,
		note: "",
		order: null,
		items: [],
		instructions: [],
		voucher_rows: [],
	};
}
