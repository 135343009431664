import { MooUpdateFormNew } from "../moo-update-form-new";
import { MooVoucherOne, One } from "../moo-voucher-one";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { Rows } from "./updateOne/Rows";
import { Table } from "./updateOne/Table";

export type MooVoucherUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooVoucherUpdate({ id, onCancel }: MooVoucherUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="voucher-one"
			query={query}
			tableName="vouchers"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooVoucherOne}
			tables={[Rows, Table]}
		/>
	);
}
