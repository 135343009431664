import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { DEFAULT_ONE, MooRequestBuyOne, One } from "../moo-request-buy-one";
import { useHasuraAdd } from "../moo-shared";

export type MooSvcAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooRequestBuyAdd: FC<MooSvcAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("request_buys", onSuccess, (e) => {
		return produce(e, (draft) => {
			// @ts-ignore
			delete draft.sales_tel;
			// @ts-ignore
			delete draft.foreign_manufacturer_tel;
			// @ts-ignore
			delete draft.foreign_manufacturer_fax;
			// @ts-ignore
			delete draft.foreign_manufacturer_email;

			draft.items.forEach((e, index) => {
				// @ts-ignore
				draft.items[index] = {
					request_buy_item_id: e.request_buy_item_id,
					id: e.id,
					count: e.count,
				};
			});
		});
	});
	const permissions = usePermissions();

	return (
		<MooRequestBuyOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.RequestBuyAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
