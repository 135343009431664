import { generatePath, useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooManufacturerLogisticsAdd } from "../components/moo-components/moo-manufacturer-logistics-add";
import { path as listPath } from "./LogisticsManufacturerListRoute";
import { path as onePath } from "./UpdateLogisticsManufacturerRoute";

export const path = "/new-logistics-manufacturer";

const NewLogisticsManufacturer = () => {
	const navigate = useNavigate();
	const branch = useBranchInQuery();

	return (
		<MooManufacturerLogisticsAdd
			onSuccess={async (id) => {
				await navigate(`${generatePath(onePath, { id })}?branch=${branch}`);
			}}
			onCancel={async () => {
				await navigate(`${listPath}?branch=${branch}`);
			}}
		/>
	);
};

export default NewLogisticsManufacturer;
