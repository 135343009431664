import { Status } from "../moo-enums";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	return {
		status: Status.Present,
		start_date: new Date(),
		end_date: new Date(),
		links: [],
		content: "",
		create_date: new Date(),
	};
}
