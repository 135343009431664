import { DocumentNode, gql } from "@apollo/client";
import { UseQueryResult } from "react-query";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { Permission } from "../moo-me-provider";
import { useReadFields } from "./useReadFields";

export type Condition<T> = Partial<Record<keyof T, Permission>> & object;

export function useFindOneQuery<T extends { [key: string]: any }>(
	shape: T,
	idType: string,
	operationName: string,
	readCondition: Condition<T>,
): DocumentNode {
	const readFields = useReadFields(shape, readCondition);

	return gql`
      query ($id: ${idType}) {
          ${operationName}(id: $id){
						${readFields}
				}
      }
	`;
}

export function useFindOne<T extends { [key: string]: any }>(
	id: string | number,
	idType: string,
	shape: T,
	operationName: string,
	readCondition: Condition<T>,
): UseQueryResult<T> {
	const variables = { id };
	const query = useFindOneQuery<T>(shape, idType, operationName, readCondition);
	return useGraphQLQuery({ operationName, query, variables });
}
