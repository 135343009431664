import { importGoods } from "../../moo-enums";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";

type Props = {
	value: string | null;
	onChange: (value: string | null) => void;
};

export function AccountantStateSelect({ value, onChange }: Props) {
	return (
		<MooTypeableSelect
			id="accountant_state"
			value={value}
			onChange={onChange}
			label="會計狀態"
			options={[
				{
					value: null,
					label: "請選擇",
				},
				{
					value: importGoods.StateAccountant.Value.UnSettled,
					label: importGoods.StateAccountant.Label.UnSettled,
				},
				{
					value: importGoods.StateAccountant.Value.Settled,
					label: importGoods.StateAccountant.Label.Settled,
				},
			]}
		/>
	);
}
