import { Row, useFindItemCustomsByIds } from "./useFindItemCustomsByIds";

export function useFindItemCustomsMapByIds() {
	const findItemCustomsByIds = useFindItemCustomsByIds();

	return async (ids: string[]): Promise<Map<string, Row>> => {
		return (await findItemCustomsByIds(ids)).reduce((acc, curr) => {
			acc.set(curr.id, curr);
			return acc;
		}, new Map());
	};
}
