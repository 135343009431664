import produce from "immer";
import { FC } from "react";
import { Customer, DEFAULT_ONE, MooCustomerOne, useGetCustomersByEmail } from "../moo-customer-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useError } from "../moo-notification-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooCustomerAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooCustomerAdd: FC<MooCustomerAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<Customer>(
		"customers",
		onSuccess,
		(e) => {
			return produce(e, () => {});
		},
		false,
	);
	const permissions = usePermissions();
	const getCustomersByEmail = useGetCustomersByEmail();
	const notifyError = useError();

	return (
		<MooCustomerOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.CustomerAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={async (toUpdateValues, helpers) => {
				const customers = await getCustomersByEmail(toUpdateValues.email);
				if (customers.length > 0) {
					notifyError("這組email已被使用！");
				} else {
					onAdd(toUpdateValues, helpers);
				}
			}}
			okText="送出"
		/>
	);
};
