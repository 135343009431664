import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { FunctionComponent } from "react";
import { manufacturer } from "../../moo-enums";
import { DollarNumber } from "../../moo-shared";
import { OrderBy, Row } from "../@types";
import useStore from "../stores/useStore";

type ArrayItem = {
	id: string;
	label: string | FunctionComponent;
	component: any;
};

function IdHeader() {
	const { query, setQuery } = useStore();
	const isUp = query.order_by[0] === OrderBy.ID_ASC;

	return (
		<div
			style={{
				cursor: "pointer",
				userSelect: "none",
				display: "flex",
				justifyContent: "center",
				justifyItems: "center",
			}}
			onClick={() => {
				setQuery({ order_by: [isUp ? OrderBy.ID_DESC : OrderBy.ID_ASC], page: 0 });
			}}
		>
			商品編號
			{isUp ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
		</div>
	);
}

export function useArrayItems(): ArrayItem[] {
	return [
		{
			id: "id",
			label() {
				return <IdHeader />;
			},
			component: (row: Row) => row["id"],
		},
		{
			id: "owner_id",
			label: "負責人",
			component: (row: Row) => row.owner?.name || "無",
		},
		{
			id: "manufacturer_type",
			label: "廠商類型",
			component(row: Row) {
				return <div>{manufacturer.Label.get(row.manufacturer_type)}</div>;
			},
		},
		{
			id: "manufacturer",
			label: "廠商",
			component(row: Row) {
				return <div>{row.manufacturer}</div>;
			},
		},
		{ id: "category", label: "商品種類", component: (row: Row) => row["category"] },
		{ id: "name", label: "商品名", component: (row: Row) => row["name"] },
		{ id: "country", label: "國家", component: (row: Row) => row["country"] },
		{ id: "material", label: "材質", component: (row: Row) => row["material"] },
		{ id: "size", label: "尺寸", component: (row: Row) => row["size"] },
		{ id: "color", label: "顏色", component: (row: Row) => row["color"] },
		{
			id: "purchase_price",
			label: "進貨成本",
			component: (row: Row) => <DollarNumber>{row["purchase_price"]}</DollarNumber>,
		},
		{
			id: "price",
			label: "建議售價",
			component: (row: Row) => <DollarNumber>{row["price"]}</DollarNumber>,
		},
	];
}
