import Select from "react-select";

type Option = {
	value: string;
	label: string;
};

type Props = {
	itemIds: string[];
	options: Option[];
	onChange: (options: Option[]) => void;
	isLoading: boolean;
};

export function MultiSelect({ itemIds, options, onChange, isLoading }: Props) {
	const values = options.filter(({ value }) => itemIds.includes(value));

	return (
		<div style={{ marginBottom: 12 }}>
			<div>商品</div>
			<Select
				id="query-field"
				isMulti
				styles={{
					control: (baseStyles) => ({
						...baseStyles,
						height: "56px",
						cursor: "pointer",
					}),
					menu: (baseStyles) => ({
						...baseStyles,
						zIndex: 2,
					}),
				}}
				placeholder=""
				noOptionsMessage={() => "沒有資料"}
				value={values}
				isOptionSelected={(option: Option) => {
					return itemIds.includes(option.value);
				}}
				isLoading={isLoading}
				options={options}
				onChange={(arr) => {
					onChange(arr as Option[]);
				}}
			/>
		</div>
	);
}
