import { useOnLinkClick } from "../moo-link-provider";
import { MooTags } from "../moo-tags";

export type MooVoucherLinkListProps = {
	values: string[];
};

export function MooVoucherLinkList({ values }: MooVoucherLinkListProps) {
	const onLinkClick = useOnLinkClick();

	return <MooTags tags={Array.from(new Set(values))} onClick={onLinkClick} emptyMsg="" />;
}
