import { DocumentNode, gql } from "@apollo/client";
import { useGraphQLAggregateName } from "../useGraphQLAggregateName";
import { useGraphQLResource } from "../useGraphQLResource";
import { useGraphQLOrderByName } from "./useGraphQLOrderByName";
import { useGraphQLQueryFields } from "./useGraphQLQueryFields";
import { useGraphQLWhereName } from "./useGraphQLWhereName";

export function useGraphQLQuery(): DocumentNode {
	const graphQLAggregateName = useGraphQLAggregateName();
	const graphQLResource = useGraphQLResource();
	const whereName = useGraphQLWhereName();
	const orderByName = useGraphQLOrderByName();
	const graphQLQueryFields = useGraphQLQueryFields();

	return gql`
        query query($where: ${whereName}, $order_by: ${orderByName}, $limit: Int, $offset: Int) {
            ${graphQLAggregateName}(where: $where) {
                aggregate {
                    count
                }
            }
            ${graphQLResource}(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
                ${graphQLQueryFields}
            }
            manufacturer_foreigns {
                id
                name
            }
            manufacturer_domestics {
                id
                name
            }
            item_categories {
                id
                name
            }
        }
    `;
}
