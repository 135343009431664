import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "name",
			label: "名稱",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
	];
}
