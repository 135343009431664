import { deliverMethod, purchaseOrder, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();
	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		state: purchaseOrder.State.Value.NotConfirmed,
		state_manager: purchaseOrder.StateManager.Value.UnApproved,
		state_accountant: purchaseOrder.StateAccountant.Value.UnSettled,
		purchase_date: now,
		deliver_date: now,
		request_buy_ids: [],
		owner_id: null,
		foreign_manufacturer_id: null,
		foreign_manufacturer_tel: null,
		foreign_manufacturer_fax: null,
		foreign_manufacturer_email: null,
		foreign_manufacturer_country: null,
		foreign_manufacturer_currency: null,
		foreign_manufacturer_bank_account: null,
		foreign_manufacturer_swift_code: null,
		foreign_manufacturer_code_iban: null,
		deliver_method: deliverMethod.Value.Value.Air,
		warehouse_id: null,
		note: "",
		items: [],
		files: [],
		manufacturer_files: [],
		other_files: [],
		accountant_tax: 0,
		accountant_total_price: 0,
		accountant_received: 0,
		accountant_freight: 0,
		accountant_declare_fee: 0,
		accountant_pickup_charge: 0,
		accountant_miscellaneous_charge: 0,
		accountant_note: "",
		voucher_rows: [],
		mgt_fee: 0,
	};
}
