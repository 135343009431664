import { Display } from "./Display";
import { FieldButton } from "./FieldButton";
import { WarehouseItemListDialog } from "./WarehouseItemListDialog";
import { Provider, useInternalState } from "./useInternalState";

export type MooWarehouseFieldProps = {
	warehouseId: string | null;
	itemIds: string[];
	onChange: (warehouseId: string | null) => void;
	label: string;
	disabled: boolean;
	error?: string;
};

function Content() {
	const { warehouseId, setIsOpen } = useInternalState();
	if (warehouseId === null) {
		return <FieldButton id="warehouse" warehouseName="選擇倉庫" onClick={() => setIsOpen(true)} />;
	} else {
		return <Display />;
	}
}

export function MooWarehouseField({ warehouseId, itemIds, onChange, label, disabled, error }: MooWarehouseFieldProps) {
	return (
		<Provider
			warehouseId={warehouseId}
			itemIds={itemIds}
			onChange={onChange}
			label={label}
			error={error}
			disabled={disabled}
		>
			<WarehouseItemListDialog />
			<Content />
		</Provider>
	);
}
