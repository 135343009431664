import { getLocaleString } from "../../moo-shared";
import { One } from "../../moo-svc-one";
import { Row } from "./Row";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Row): One {
	const items = mapperItems(record.items);

	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		state_accountant: record.state_accountant,
		type: record.type,
		from_date: getLocaleString(record?.from_date ? new Date(record.from_date) : new Date()),
		to_date: getLocaleString(record?.to_date ? new Date(record.to_date) : new Date()),
		customer_id: record.customer.id,
		customer_name: record.customer.name,
		sales_id: record.sales.id,
		sales_name: record.sales.name,
		owner_id: record.owner.id,
		owner_name: record.owner.name,
		address: record.address,
		description: record.description,
		note: record.note,
		driver_logistics_manufacturer_id: record.driver_logistics_manufacturer_id,
		driver_id: record.manufacturer_logistic_driver.manufacturer_logistic_driver_id,
		driver_tel: record.manufacturer_logistic_driver.tel,
		items,
		price: record.price,
		cost: record.cost,
		receivables: record.receivables,
		received: record.received,
	};
}
