import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../../moo-graphql-provider";
import { useIdName } from "./useIdName";
import { Image } from "./useListData";
import { useProps } from "./useProps";

export function useUpdateThumbnail() {
	const { resource } = useProps();
	const idName = useIdName();
	const graphQLRequest = useGraphQLRequest();

	const query = gql`
      mutation ($where: ${resource}_bool_exp!, $set: ${resource}_set_input) {
          update_${resource}(where: $where, _set: $set) {
              returning {
      					${idName}
              }
          }
      }
	`;

	return async (image: Omit<Image, "src">) => {
		const variables = {
			where: {
				[idName]: {
					_eq: image.id,
				},
			},
			set: {
				thumbnail: image.thumbnail,
				thumbnail_x: image.thumbnail_x,
				thumbnail_y: image.thumbnail_y,
				thumbnail_zoom: image.thumbnail_zoom,
				thumbnail_rotation: image.thumbnail_rotation,
			},
		};

		return graphQLRequest({ query, variables });
	};
}
