import { gql } from "@apollo/client";

const itemBlock = `
		id
		name
		image
		image_cut
		material
		color
		size
		purchase_price
		price
		note
		cost
		manufacturer {
			id
			type
			name
		}
		category {
			id
			name
		}
`;

export const queryField = `
    branch {
      id
      name
    }
    create_date
    update_date
    status
    state
    state_manager
    state_accountant
    state_warehouse
    order_date
    order_note
    description
    type_id
    quotation_type {
        id
        name
    }
    quotation_id
    quotation {
        id
        create_date
    }
    customer_id
    customer {
      id
      name
    }
    sales_id
    sales {
      id
      name
      tel
    }
    deliver_date
    is_include_tax
    assistant_id
    payee_id
    payee {
      id
      name
      bank_name
      account_number
    }
    employee {
      nick_name
    }
    deliver_address
    note
    memo
		tax_price
    total_price
    received
    handling_fee
    freight
    miscellaneous_charge
    commission_refund
    voucher_rows {
      voucher_id
    }
    instructions {
      order_instruction_id
      value
    }
    items(order_by: {priority: asc}) {
        order_item_id
        id
        count
        price_final
        note
        item_spec {
            ${itemBlock}
        }
        item_custom {
            ${itemBlock}
        }
    }
`;

export const query = gql`
    query ($id: String!) {
        orders_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
