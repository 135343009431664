import { useEffect, useState } from "react";
import { MooEnumCrud } from "../moo-enum-crud";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export type IdType = "domestic" | "foreign";

export type MooManufacturerTypeProps = {
	id: IdType;
};

export function MooManufacturerType({ id }: MooManufacturerTypeProps) {
	const [, forceUpdate] = useState(0);
	const permissions = usePermissions();
	const isDomestic = id === "domestic";
	const resource = isDomestic ? "manufacturer_domestic_types" : "manufacturer_foreign_types";
	const title = isDomestic ? "國內廠商類型" : "國外廠商類型";

	useEffect(() => {
		forceUpdate((e) => e + 1);
	}, [id]);

	if (isDomestic && !permissions.includes(Permission.ManufacturerDomesticTypeWrite)) {
		return <MooNoAuthPage />;
	} else if (!isDomestic && !permissions.includes(Permission.ManufacturerForeignTypeWrite)) {
		return <MooNoAuthPage />;
	} else {
		return (
			<MooEnumCrud
				resource={resource}
				title={title}
				addColumns={[
					{
						id: "name",
						defaultValue: "",
						label: "類型",
						autoFocus: true,
						placeholder: "沙發廠",
						emptyError: "",
					},
				]}
				listBackgroundColor="#EAEAEA"
				listColumns={[
					{
						id: "name",
						defaultValue: "",
					},
				]}
			/>
		);
	}
}
