import produce from "immer";
import { useForm } from "./useForm";

export function useUpdateItemInfoImage() {
	const { setFieldValue, values } = useForm();

	return (request_buy_item_info_id: string, value: string) => {
		setFieldValue(
			"item_infos",
			produce(values.item_infos, (draft) => {
				draft.find((e) => e.request_buy_item_info_id === request_buy_item_info_id)!.image = value;
			}),
		);
	};
}
