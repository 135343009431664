import { MooGqlChildNewTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";

export class Accounts extends MooGqlChildNewTable<FindOneRecord> {
	getIdName(): string {
		return "manufacturer_logistic_account_id";
	}

	getTableName(): string {
		return "manufacturer_logistic_accounts";
	}

	getPathName(): string {
		return "accounts";
	}

	getParentIdName(): string {
		return "manufacturer_logistic_id";
	}

	getToUpdateColumns(): string[] {
		return ["name", "bank", "bank_account", "tax_id"];
	}
}
