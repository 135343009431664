import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { ColumnType as AddDialogColumnType, MooEnumAddDialog } from "../moo-enum-add-dialog";
import { ColumnType as PanelListColumnType } from "./PanelList/@types/columnTypes";
import { OptionResponseType } from "./PanelList/@types/optionTypes";
import { PanelList, useGraphQLQueryList } from "./PanelList/PanelList";

export type MooEnumCrudProps = {
	resource: string;
	title: string;
	filter?: Record<string, any>;
	addColumns: AddDialogColumnType[];
	listBackgroundColor: string;
	listColumns: PanelListColumnType[];
	columnPlaceHolder?: (data: OptionResponseType["id"]) => ReactElement;
	mutation?: Record<string, any>;
};

const Container = styled.div`
	> *:nth-of-type(2) {
		margin-top: 16px;
	}
`;

export function MooEnumCrud({
	resource,
	title,
	addColumns,
	filter = {},
	listBackgroundColor,
	listColumns,
	columnPlaceHolder,
	mutation = {},
}: MooEnumCrudProps) {
	const { refetch } = useGraphQLQueryList(resource, listColumns, filter);
	const [open, setOpen] = useState(false);

	return (
		<Container>
			<div>
				<KodenIconTextButton text="新增" onClick={() => setOpen(true)} />
			</div>
			<MooEnumAddDialog
				title={`新增${title}`}
				resource={resource}
				open={open}
				columns={addColumns}
				mutation={mutation}
				onClose={() => {
					setOpen(false);
				}}
				onAddSuccess={async () => {
					await refetch();
				}}
			/>
			<PanelList
				title={title}
				filter={filter}
				headerBackgroundColor={listBackgroundColor}
				resource={resource}
				columns={listColumns}
				columnPlaceHolder={columnPlaceHolder}
			/>
		</Container>
	);
}
