import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { useIsEdit } from "../../../moo-use-is-edit";
import { MooWarehouseField } from "../../../moo-warehouse-field";
import { Stock, Stock as StockType } from "../../@types/one";
import { useUpdate } from "../../hooks/useUpdate";

type Props = {
	id: string;
	count: number;
	errors: Record<keyof Stock, string>[];
	values: StockType[];
};

export function Stocks({ id, values, errors }: Props) {
	const { updateItemStock, addItemStock, delItemStock, updateWarehouseId } = useUpdate();
	const isEdit = useIsEdit();

	return (
		<ul
			style={{
				display: "grid",
				gridGap: "1rem",
				gridArea: "stock",
				padding: ".6rem",
				margin: 0,
				listStyleType: "none",
				borderRadius: ".2rem",
				background: "#f2f3f5",
			}}
		>
			{values.map(({ export_good_item_stock_id, warehouse_id, count }, index) => {
				const isFirst = index === 0;
				const error = errors[index];

				return (
					<li
						key={export_good_item_stock_id}
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(3, 1fr) auto",
							gridGap: "1rem",
						}}
					>
						<MooWarehouseField
							warehouseId={warehouse_id}
							itemIds={[id]}
							label="出貨倉庫"
							error={error?.warehouse_id}
							disabled={isEdit}
							onChange={(warehouse_id) => updateWarehouseId(id, export_good_item_stock_id, warehouse_id!)}
						/>
						<KodenNumberField
							id={`count_${export_good_item_stock_id}`}
							label="出貨數量"
							margin="none"
							value={count}
							readOnly={isEdit}
							disabled={isEdit}
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							step={1}
							error={error?.count || ""}
							onChange={(value) => updateItemStock(id, export_good_item_stock_id, "count", value)}
						/>
						{isFirst && !isEdit && (
							<AddCircleOutlineIcon
								style={{
									alignSelf: "center",
									cursor: "pointer",
								}}
								color="disabled"
								onClick={() => {
									addItemStock(id, export_good_item_stock_id);
								}}
							/>
						)}

						{!isFirst && (
							<HighlightOffIcon
								style={{
									alignSelf: "center",
									cursor: "pointer",
								}}
								color="disabled"
								id={`bin-button_${export_good_item_stock_id}`}
								onClick={() => {
									delItemStock(id, export_good_item_stock_id);
								}}
							/>
						)}
					</li>
				);
			})}
		</ul>
	);
}

export default Stocks;
