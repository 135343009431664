export enum Value {
	WaitToRefund = "WAIT_TO_REFUND",
	Refunded = "REFUNDED",
}

export enum Label {
	WaitToRefund = "未退費",
	Refunded = "已退費",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.WaitToRefund:
			return Label.WaitToRefund;
		case Value.Refunded:
			return Label.Refunded;
	}
	return Label.WaitToRefund;
}
