import { ItemType, manufacturer } from "../../moo-enums";
import { MooGraphQLTableProps } from "../../moo-graphql-table";
import { SpecOrCustomTableRow } from "../@types";
import { useGraphQLAggregateName } from "./useGraphQLAggregateName";
import { useGraphQLResource } from "./useGraphQLResource";

function getDomesticManufactureMap(data: any): Record<string, string> {
	// @ts-ignore
	return data.manufacturer_domestics.reduce((ori, cur) => {
		return {
			...ori,
			[cur.id]: cur.name,
		};
	}, {});
}

function getForeignManufactureMap(data: any): Record<string, string> {
	// @ts-ignore
	return data.manufacturer_foreigns.reduce((ori, cur) => {
		return {
			...ori,
			[cur.id]: cur.name,
		};
	}, {});
}

function getItemCategoryMap(data: any): Record<string, string> {
	// @ts-ignore
	return data.item_categories.reduce((ori, cur) => {
		return {
			...ori,
			[cur.id]: cur.name,
		};
	}, {});
}

function getCategory(
	itemCategoryMap: Record<string, string>,
	categoryId: string,
	subCategoryId: string | null,
): string {
	return itemCategoryMap[subCategoryId !== null ? subCategoryId : categoryId];
}

export function useGraphQLMapData(): MooGraphQLTableProps["mapData"] {
	const graphQLAggregateName = useGraphQLAggregateName();
	const graphQLResource = useGraphQLResource();

	return (data: Record<string, any[]>) => {
		const domesticManufactureMap = getDomesticManufactureMap(data);
		const foreignManufactureMap = getForeignManufactureMap(data);
		const itemCategoryMap = getItemCategoryMap(data);

		const results = data[graphQLResource].map(
			({
				id,
				manufacturer_type,
				name,
				manufacturer_id,
				category_id,
				sub_category_id,
				material,
				size,
				color,
				color_image,
				cost,
				country,
				purchase_price,
				price,
				original_code,
				image,
				image_cut,
				owner,
			}) => {
				const row: SpecOrCustomTableRow = {
					id,
					type: ItemType.Spec,
					category: getCategory(itemCategoryMap, category_id, sub_category_id),
					manufacturer_type: manufacturer_type as SpecOrCustomTableRow["manufacturer_type"],
					manufacturer_id,
					manufacturer: (manufacturer_type === manufacturer.Value.Value.Domestic
						? domesticManufactureMap
						: foreignManufactureMap)[manufacturer_id],
					name,
					material,
					image,
					image_cut,
					color,
					color_image,
					size,
					purchase_price,
					price,
					cost,
					country,
					original_code,
					owner,
				};
				return row;
			},
		);

		return {
			results,
			// @ts-ignore
			total_count: data[graphQLAggregateName].aggregate.count,
		};
	};
}
