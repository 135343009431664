import styled from "styled-components";
import MooFilesUploadField from "../../moo-files-upload-field";
import { uuidv4 } from "../../moo-shared";
import { File, ManufacturerFile, OtherFile } from "../@types/one";
import { useForm } from "../hooks/useForm";

const FileUpload = styled(MooFilesUploadField)`
	margin-bottom: 1rem;
`;

export function DocumentsBlock() {
	const { values: one, setFieldValue: updateOne } = useForm();
	return (
		<>
			<div style={{ marginBottom: "1rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
						fontSize: "1.5rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>文件上傳</h1>
				</header>
			</div>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr",
					gridGap: ".5rem",
					marginBottom: "2rem",
				}}
			>
				<FileUpload
					values={one.files.map((e) => e.value)}
					label="採購 PDF"
					video={false}
					onChange={(values) => {
						updateOne(
							"files",
							values.map((value) => {
								return {
									purchase_order_file_id: uuidv4(),
									value,
								} as File;
							}),
						);
					}}
				/>
				<FileUpload
					values={one.manufacturer_files.map((e) => e.value)}
					label="廠商 PI"
					video={false}
					onChange={(values) => {
						updateOne(
							"manufacturer_files",
							values.map((value) => {
								return {
									purchase_order_manufacturer_file_id: uuidv4(),
									value,
								} as ManufacturerFile;
							}),
						);
					}}
				/>
				<FileUpload
					values={one.other_files.map((e) => e.value)}
					label="其他資料"
					video={false}
					onChange={(values) => {
						updateOne(
							"other_files",
							values.map((value) => {
								return {
									purchase_order_other_file_id: uuidv4(),
									value,
								} as OtherFile;
							}),
						);
					}}
				/>
			</div>
		</>
	);
}
