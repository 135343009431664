import { gql } from "@apollo/client";
import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { useGraphQLRequest } from "../../moo-graphql-provider";
import { useUpdateWhereForDateRanges } from "../hooks/useWhere";

export type ReturnedData = {
	voucher_rows_aggregate: {
		aggregate: {
			sum: {
				credit_amount: number | null;
				debit_amount: number | null;
			};
		};
	};
};

const query = gql`
	query ($where: voucher_rows_bool_exp) {
		voucher_rows_aggregate(where: $where) {
			aggregate {
				sum {
					credit_amount
					debit_amount
				}
			}
		}
	}
`;

export function useGetData() {
	const { value: branchId } = useBranch();
	const graphQLRequest = useGraphQLRequest();
	const updateWhereForDateRanges = useUpdateWhereForDateRanges();

	return async ({ id_number }: { id_number: string }): Promise<ReturnedData> => {
		const where = {
			voucher: {
				status: {
					_eq: Status.Present,
				},
				branch_id: {
					_eq: branchId,
				},
			},
			account_type: {
				id_number: {
					_eq: id_number,
				},
				branch_id: {
					_eq: branchId,
				},
				status: {
					_eq: Status.Present,
				},
			},
		};
		updateWhereForDateRanges({ where });

		const data = (await graphQLRequest({
			query,
			variables: {
				where,
			},
		})) as ReturnedData;
		return data;
	};
}
