import { number, object, SchemaOf } from "yup";
import { stringOptional, stringRequired } from "../../moo-schemas";
import { Stock } from "../@types/one";

// @ts-ignore
export const stockSchema: SchemaOf<Stock> = object().shape({
	export_good_item_stock_id: stringOptional,
	warehouse_id: stringRequired("warehouse_id"),
	count: number()
		.nullable(true)
		.test("count_value", "請提供", (value) => {
			return value !== null && value !== undefined && value > 0;
		}),
});
