import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    state
    found_date
    owner_id
    found_warehouse_id
    handle_method
    note
    items {
      defective_product_item_id
      id
      count
      item_spec {
        name
        manufacturer {
          type
          id
          name
        }
        category {
          name
        }
        cost
        material
        color
        size
        price
        purchase_price
        image
        image_cut
        note
      }
      
      item_custom {
        name
        manufacturer {
          type
          id
          name
        }
        category {
          name
        }
        cost
        material
        color
        size
        price
        purchase_price
        image
        image_cut
        note
      }
    }
`;

export const query = gql`
    query ($id: String!) {
        defective_products_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
