import FirstPage from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { FC, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Left = styled.div`
	display: flex;
	color: rgba(0, 0, 0, 0.54);
	font-size: 0.75rem;
	font-weight: 400;
`;

type Props = {
	data: any;
	page: number;
	rowsPerPage: number;
	totalCount: number;
	onChange: (page: number) => void;
	aggregateNode?: (data: any) => ReactNode;
};

const getLastPage = (totalCount: number, rowsPerPage: number) => {
	return Math.ceil(totalCount / rowsPerPage) - 1;
};

const Pagination: FC<Props> = ({ data, page, rowsPerPage, totalCount, onChange, aggregateNode = () => null }) => {
	const noRecords = totalCount === 0;
	const fromRecord = page * rowsPerPage + 1;
	const toRecord = Math.min((page + 1) * rowsPerPage, totalCount);
	const lastPage = getLastPage(totalCount, rowsPerPage);
	const isLastPage = page === lastPage;

	return (
		<>
			<Container>{aggregateNode(data)}</Container>
			<Container>
				<Left>
					{fromRecord}-{toRecord}
					&nbsp;of&nbsp;
					{totalCount}
				</Left>
				<IconButton onClick={() => onChange(0)} disabled={noRecords || page === 0}>
					<FirstPage />
				</IconButton>
				<IconButton onClick={() => onChange(page - 1)} disabled={noRecords || page === 0}>
					<KeyboardArrowLeft />
				</IconButton>

				<IconButton onClick={() => onChange(page + 1)} disabled={noRecords || isLastPage}>
					<KeyboardArrowRight />
				</IconButton>
				<IconButton onClick={() => onChange(lastPage)} disabled={noRecords || isLastPage}>
					<LastPageIcon />
				</IconButton>
			</Container>
		</>
	);
};

export default Pagination;
