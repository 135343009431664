import { One } from "../../moo-item-custom-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";

export function mapperInitialValues(record: Row): One {
	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		manufacturer_type: record.manufacturer_type,
		manufacturer_id: record.manufacturer_id,
		category_id: record.category_id,
		sub_category_id: record.sub_category_id,
		buy_year: record.buy_year,
		name: record.name,
		material: record.material,
		color: record.color,
		size: record.size,
		owner_id: record.owner_id,
		sell_deadline: record.sell_deadline,
		note: record.note,
		purchase_price: record.purchase_price,
		cost: record.cost,
		price: record.price,
		original_code: record.original_code,
		image: record.image,
		image_cut: record.image_cut,
		country: record.country,
	};
}
