import { order, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./index";

export { DEFAULT_ITEM, DEFAULT_SIGNED_FILE as DEFAULT_INSTRUCTION } from "../moo-quotation-one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();

	return {
		status: Status.Present,
		state: order.State.Value.Customizing,
		state_manager: order.StateManager.Value.Unapproved,
		state_accountant: order.StateAccountant.Value.UnSettled,
		state_warehouse: order.StateWarehouse.Value.UnConfirmed,
		order_date: now,
		quotation_id: null,
		type_id: null,
		type_name: "",
		customer_id: null,
		customer_name: "",
		assistant_id: null,
		sales_id: null,
		sales_name: "",
		sales_tel: "",
		deliver_date: now,
		is_include_tax: false,
		payee_id: null,
		payee_name: "",
		payee_bank: "",
		payee_account_number: "",
		deliver_address: "",
		description: "",
		order_note: "",
		items: [],
		tax_price: 0,
		total_price: 0,
		received: 0,
		receivables: 0,
		handling_fee: 0,
		freight: 0,
		miscellaneous_charge: 0,
		commission_refund: 0,
		note: "",
		memo: "",
		employee: null,
		instructions: [],
		voucher_rows: [],
		create_date: now,
		update_date: now,
	};
}
