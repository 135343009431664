import { FC } from "react";
import { MooEmployeeSelectField } from "../moo-employee-select-field";
import { ItemType, manufacturer } from "../moo-enums";
import { QuerySlice } from "../moo-hooks";
import { ItemCategory } from "../moo-item";
import { ItemType as ItemManufacturerSelectItemType } from "../moo-item-manufacturer-select";
import { MooStyledManufacturerSelect } from "../moo-styled-manufacturer-select/MooStyledManufacturerSelect";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { Query } from "./@types";
import { QueryField } from "./QueryField";

type FilterType = {
	type: "spec" | "custom";
	store: QuerySlice<Query>;
};

export const Filter: FC<FilterType> = ({ type, store }) => {
	const { query, setQuery } = store;
	const { owner_id, category, sub_category, material, color } = query;
	const resource = type === ItemType.Spec ? "item_specs" : "item_customs";

	return (
		<>
			<MooEmployeeSelectField
				id="owner_id"
				label="負責人"
				value={owner_id}
				onChange={(owner_id) => {
					setQuery({ owner_id, page: 0 });
				}}
			/>
			<ItemCategory
				parentId={null}
				label="商品種類"
				value={category}
				onChange={(category) => {
					setQuery({ category, sub_category: null, page: 0 });
				}}
			/>
			{category !== null && (
				<ItemCategory
					parentId={category}
					label="商品子類"
					value={sub_category}
					onChange={(sub_category) => {
						setQuery({ sub_category, page: 0 });
					}}
				/>
			)}
			<MooTypeableSelect
				id="item-manufacturer-type-field"
				label="廠商類型"
				value={query.manufacturer_type}
				options={[
					{ value: null, label: "請選擇" },
					{ value: manufacturer.Value.Value.Domestic, label: "國內" },
					{ value: manufacturer.Value.Value.Foreign, label: "國外" },
				]}
				onChange={(manufacturerType) =>
					setQuery({
						manufacturer_type: manufacturerType as Query["manufacturer_type"],
						manufacturer_id: null,
						page: 0,
					})
				}
			/>
			{query.manufacturer_type !== null && (
				<MooStyledManufacturerSelect
					itemType={resource as ItemManufacturerSelectItemType}
					manufacturerType={query.manufacturer_type as manufacturer.Value.Value | null}
					value={query.manufacturer_id}
					onChange={(manufacturer_id) => setQuery({ manufacturer_id, page: 0 })}
				/>
			)}
			<QueryField
				resource={resource}
				name="color"
				label="顏色"
				value={color}
				onChange={(value) => setQuery({ color: value as string, page: 0 })}
			/>

			<QueryField
				resource={resource}
				name="material"
				label="材質"
				value={material}
				onChange={(value) => setQuery({ material: value as string, page: 0 })}
			/>
		</>
	);
};
