import { gql } from "@apollo/client";
import { KodenDialog } from "@yachelee/koden-components/koden-dialog";
import produce from "immer";
import { FC, useState } from "react";
import { useBranch } from "../moo-branch-provider";
import { useGraphQLMutation } from "../moo-graphql-provider";
import ColumnType from "./@types/columnType";
import DialogColumns from "./components/DialogColumns";
import getDisabledOk from "./getDisabledOk";
import getErrors from "./getErrors";
import getHasError from "./getHasError";
import useStateMap, { getEmptyStateMap } from "./hooks/useStateMap";

export type MooEnumAddDialogProps = {
	title: string;
	resource: string;
	columns: ColumnType[];
	open: boolean;
	onClose: () => void;
	onAddSuccess?: (data: any) => void;
	mutation: Record<string, any>;
};

export const MooEnumAddDialog: FC<MooEnumAddDialogProps> = ({
	title,
	columns,
	resource,
	open,
	onClose,
	onAddSuccess,
	mutation,
}) => {
	const { value: branch } = useBranch();
	const addMutation = useGraphQLMutation({
		mutation: gql`
			mutation($object: ${resource}_insert_input!){
					insert_${resource}_one(object: $object){
							id
					}
			}
	`,
	});

	const [dirty, setDirty] = useState(false);
	const [stateMap, setStateMap] = useStateMap(columns);
	const [errorMsg, setErrorMsg] = useState("");
	const errors = getErrors(stateMap, columns);
	const hasError = getHasError(errors);
	const disabledOk = getDisabledOk({
		hasError,
		dirty,
		isLoading: addMutation.isLoading,
	});

	const onDialogClose = () => {
		setDirty(false);
		setStateMap(getEmptyStateMap(columns));
		setErrorMsg("");
		onClose();
	};

	return (
		<KodenDialog
			id={`Dialog_${resource}_add`}
			title={title}
			open={open}
			onClose={onDialogClose}
			onOk={() => {
				setDirty(true);
				if (!hasError) {
					let object = { ...stateMap, branch_id: branch };

					object = produce(object, (obj) => {
						obj = { ...obj, ...mutation };
						return obj;
					});

					addMutation.mutate(
						{ object },
						{
							onSuccess(data) {
								onDialogClose();

								if (onAddSuccess) {
									onAddSuccess(data);
								}
							},
							onError(data) {
								console.error(data);
								setErrorMsg("發生錯誤，請聯絡系統管理員。");
							},
						},
					);
				}
			}}
			disabledOk={disabledOk}
			isLoading={addMutation.isLoading}
		>
			<DialogColumns
				columns={columns}
				setStateMap={setStateMap}
				disabled={addMutation.isLoading}
				dirty={dirty}
				errors={errors}
				errorMsg={errorMsg}
				stateMap={stateMap}
			/>
		</KodenDialog>
	);
};

export default MooEnumAddDialog;
