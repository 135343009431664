import produce from "immer";
import { uuidv4 } from "../../moo-shared";
import { DEFAULT_ONE } from "../DEFAULT_ONE";
import { useForm } from "./useForm";

export function useAddItemInfo() {
	const { setFieldValue, values } = useForm();

	return () => {
		setFieldValue(
			"item_infos",
			produce(values.item_infos, (draft) => {
				draft.push({ ...DEFAULT_ONE().item_infos[0], request_buy_item_info_id: uuidv4() });
			}),
		);
	};
}
