import { useMutateGraphQLField } from "../../moo-shared";
import { operationName } from "../ImportGoodsList";
import { useGraphQLQuery } from "./useGraphQLQuery";
import { useGraphQLVariables } from "./useGraphQLVariables";

export function useUpdateAccountantState() {
	const graphQLQuery = useGraphQLQuery();
	const graphQLVariables = useGraphQLVariables();
	return useMutateGraphQLField("import_goods", operationName, graphQLQuery, graphQLVariables, "state_accountant");
}
