import produce from "immer";
import { FC } from "react";
import { DEFAULT_ONE, MooItemSpecOne, One } from "../moo-item-spec-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooItemSpecAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooItemSpecAdd: FC<MooItemSpecAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("item_specs", onSuccess, (e) =>
		produce(e, (draft) => {
			// @ts-ignore
			delete draft.image;
			// @ts-ignore
			delete draft.image_cut;
		}),
	);
	const permissions = usePermissions();

	return (
		<MooItemSpecOne
			isEdit={false}
			initialValues={DEFAULT_ONE()}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.ItemSpecAdd)}
			onCancel={onCancel}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
