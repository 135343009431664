import { useBranch } from "../../moo-branch-provider";
import { isNumber } from "../../moo-shared";
import { Query } from "../@types";

export function useConditions(query: Query) {
	const { value: branch } = useBranch();
	const conditions: Record<string, any> = {
		branch_id: {
			_eq: branch,
		},
	};

	if (query.type !== null) {
		conditions.type_id = {
			_eq: query.type,
		};
	}

	if (query.search !== null) {
		const _like = `%${query.search}%`;

		if (isNumber(query.search)) {
			conditions.number = {
				_eq: parseInt(query.search),
			};
		} else {
			conditions.name = {
				_like,
			};
		}
	}

	if (query.status !== null) {
		conditions.status = {
			_eq: query.status,
		};
	}

	return conditions;
}
