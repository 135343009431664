import NumbersIcon from "@mui/icons-material/Numbers";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { Formik } from "formik";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { useLinkDialogChoose } from "../moo-dialog-link-choose";
import { MooSubmitButton } from "../moo-submit-button";
import { MooTags } from "../moo-tags";
import { Props } from "./@types/props";
import { useForm } from "./hooks/useForm";
import { useGetError } from "./hooks/useGetError";
import { useUpdate } from "./hooks/useUpdate";
import { schema } from "./schemas/schema";
import { ButtonContainer, Container, FieldContainer } from "./styled";

const Header = styled.header`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 1rem;
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 0.5rem;
`;

function Form({ isCancelDisabled, isOkDisabled, onCancel }: Omit<Props, "initialValues" | "onSubmit">) {
	const { addLinks, removeLink } = useUpdate();
	const getError = useGetError();
	const { values, setFieldValue, handleSubmit } = useForm();
	const { component, open } = useLinkDialogChoose(`link-dialog-schedule`, (rows) => {
		addLinks(rows.map((e) => e.id));
	});

	return (
		<form onSubmit={handleSubmit}>
			{component}
			<Container>
				<FieldContainer>
					<KodenDateField
						id="start_date"
						label="起始日期"
						error={getError("start_date")}
						value={values.start_date}
						onChange={(date) =>
							setFieldValue(
								"start_date",
								date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
							)
						}
					/>
					<KodenDateField
						id="end_date"
						label="結束日期"
						error={getError("end_date")}
						value={values.end_date}
						onChange={(date) =>
							setFieldValue(
								"end_date",
								date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
							)
						}
					/>
				</FieldContainer>
				<div>
					<Header>
						<h2 style={{ margin: "0" }}>連結訂單</h2>
						<KodenIconTextButton text="選擇單號" onClick={open} />
					</Header>
					<div style={{ marginBottom: "2rem" }}>
						<MooTags
							emptyMsg="尚無連結訂單"
							tags={values.links.map((e) => e.value)}
							onClick={removeLink}
							tagIcon={<NumbersIcon fontSize="inherit" />}
						/>
					</div>
					<ProxyKodenTextField
						id="content"
						value={values.content}
						error={getError("content")}
						outlined
						label="新增紀錄"
						rows={2}
						multiline
						onChange={(content) => setFieldValue("content", content)}
					/>
				</div>
				{!isOkDisabled && (
					<ButtonContainer>
						<KodenButton
							style={{ marginRight: "1rem" }}
							disabled={isCancelDisabled}
							onClick={async () => {
								if (window.confirm("確定取消並且回到上一頁？")) {
									onCancel();
								}
							}}
						>
							取消
						</KodenButton>
						<MooSubmitButton />
					</ButtonContainer>
				)}
			</Container>
		</form>
	);
}

export function MooScheduleOne({ initialValues, onSubmit, ...props }: Props) {
	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit}>
			<Form {...props} />
		</Formik>
	);
}
