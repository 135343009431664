import styled from "styled-components";
import { useOnLinkClick } from "../moo-link-provider";

export type MooLinkButtonProps = {
	id: string | null;
	name: string;
};

const Button = styled.div`
	color: blue;
	cursor: pointer;
	user-select: none;
	:hover {
		text-decoration: underline;
	}
`;

export function MooLinkButton({ id, name }: MooLinkButtonProps) {
	const onLinkClick = useOnLinkClick();
	if (id === "" || id === null) {
		return null;
	}
	return (
		<Button
			onClick={() => {
				onLinkClick(id);
			}}
		>
			已連結{name}
			{id}
		</Button>
	);
}
