import { object, SchemaOf } from "yup";
import { manufacturer } from "../../moo-enums";
import { enumRequired, numberOptional, numberRequired, stringOptional, stringRequired } from "../../moo-schemas";
import { ReturnOrderItem } from "../@types/ReturnOrderItem";

// @ts-ignore
export const itemSchema: SchemaOf<ReturnOrderItem> = object().shape({
	return_order_item_id: stringRequired("return_order_item_id"),
	id: stringRequired("id"),
	name: stringOptional,
	spec: stringOptional,
	material: stringOptional,
	color: stringOptional,
	size: stringOptional,
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer: stringOptional,
	count: numberRequired("count"),
	price_final: numberRequired("price_final"),
	price_total: numberOptional,
	purchase_price: numberOptional,
	image: stringOptional,
	image_cut: stringOptional,
	price: numberOptional,
});
