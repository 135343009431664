import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useOnArchived } from "../moo-archive-provider";
import { MooBlogCard } from "../moo-blog-card";
import { MooBlogOne, One } from "../moo-blog-one";
import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useArchiveResource } from "../moo-hooks";
import { useMe } from "../moo-me-provider";
import { useFindOne } from "../moo-shared";

export type MooBlogViewProps = {
	id: string;
};

type Employee = {
	id: string;
	name: string;
	user: {
		id: string;
		color: string;
	};
};

type Schedule = {
	start_date: string;
	end_date: string;
	links: { schedule_link_id: string; value: string }[];
	content: string;
	create_date: string;
	employee: Employee;
};

type Blog = {
	branch_id: string;
	branch: {
		name: string;
	};
	status: Status;
	schedule_id: string;
	schedule: Schedule;
	date: string;
	links: { blog_link_id: string; value: string }[];
	attaches: { blog_attach_id: string; value: string }[];
	content: string;
	create_date: string;
	employee_id: string;
	employee: Employee;
};

const resource = "blogs";
const operationName = `${resource}_by_pk`;

const shape: Blog = {
	branch_id: "",
	branch: {
		name: "",
	},
	status: Status.Present,
	schedule_id: "",
	schedule: {
		start_date: "",
		end_date: "",
		links: [{ schedule_link_id: "", value: "" }],
		content: "",
		create_date: "",
		employee: {
			id: "",
			name: "",
			user: {
				id: "",
				color: "",
			},
		},
	},
	date: "",
	links: [{ blog_link_id: "", value: "" }],
	attaches: [{ blog_attach_id: "", value: "" }],
	content: "",
	create_date: "",
	employee_id: "",
	employee: {
		id: "",
		name: "",
		user: {
			id: "",
			color: "",
		},
	},
};

export function MooBlogView({ id }: MooBlogViewProps) {
	const { value: branch } = useBranch();
	const [isEditMode, setEditMode] = useState(false);
	const onArchived = useOnArchived();
	const me = useMe();
	const record = useFindOne(id, "uuid!", shape, operationName, {});
	const archiveResource = useArchiveResource(resource);

	useEffect(() => {
		record.refetch();
	}, [id]);

	if (record.isLoading || record.isError) {
		return null;
	} else {
		// @ts-ignore
		const blog: Blog = record.data![operationName];

		if (blog.status !== Status.Present) {
			return <div>此資料已被刪除</div>;
		} else if (blog.branch_id !== branch) {
			return <div>此資料屬於{_.get(record, `data.${operationName}.branch.name`, "")}</div>;
		}
		const owner = me?.employee.id === blog.employee_id;

		if (isEditMode) {
			const initialValues: One = {
				status: blog.status,
				schedule_id: blog.schedule_id,
				schedule_start_date: new Date(blog.schedule.start_date),
				schedule_end_date: new Date(blog.schedule.end_date),
				date: new Date(blog.date),
				links: blog.links,
				attaches: blog.attaches,
				content: blog.content,
				create_date: new Date().toISOString(),
			};

			return (
				<MooBlogOne
					isLoading={false}
					isCancelDisabled={false}
					isOkDisabled={false}
					okText="修改"
					initialValues={initialValues}
					onSubmit={async (toUpdate, helpers) => {
						// @ts-ignore
						await updateOne(initialValues, toUpdate, helpers);
						setEditMode(false);
					}}
					onCancel={() => {
						setEditMode(false);
					}}
				/>
			);
		} else {
			return (
				<MooBlogCard
					schedule_id={blog.schedule_id}
					schedule_start_date={new Date(blog.schedule.start_date)}
					schedule_end_date={new Date(blog.schedule.end_date)}
					schedule_links={blog.schedule.links}
					schedule_content={blog.schedule.content}
					schedule_create_date={new Date(blog.schedule.create_date)}
					schedule_employee_id={blog.schedule.employee.id}
					schedule_employee_name={blog.schedule.employee.name}
					schedule_user_color={blog.schedule.employee.user.color}
					links={blog.links}
					attaches={blog.attaches}
					user_color={blog.employee.user.color}
					user_name={blog.employee.name}
					user_id={blog.employee.id}
					content={blog.content}
					create_date={blog.create_date}
					date={new Date(blog.date)}
					edit={owner}
					remove={owner}
					onEditClick={() => {
						setEditMode(true);
					}}
					onRemoveClick={async () => {
						if (await archiveResource({ id })) {
							onArchived(resource);
						}
					}}
				/>
			);
		}
	}
}
