import { ReactNode, useState } from "react";
import { MooDialogRequestBuyChoose, useStore } from "./";
import { Row } from "./@types";

type ReturnType = {
	component: ReactNode;
	open: () => void;
	close: () => void;
};

export function useRequestBuyDialogChoose(id: string, onOk: (rows: Row[]) => void, isMultiple: boolean): ReturnType {
	const { clearChoose } = useStore();
	const [open, setOpen] = useState(false);
	const close = () => {
		setOpen(false);
	};

	const component = (
		<MooDialogRequestBuyChoose
			id={id}
			open={open}
			isMultiple={isMultiple}
			onClose={close}
			onOk={async (rows) => {
				onOk(rows);
				close();
			}}
		/>
	);

	return {
		component,
		open() {
			clearChoose();
			setOpen(true);
		},
		close,
	};
}
