import { FC } from "react";
import { manufacturer } from "../../../moo-enums";
import { MooTypeableSelect } from "../../../moo-typable-select/MooTypeableSelect";

type Props = {
	id?: string;
	label?: string;
	value: manufacturer.Value.Value | null;
	error?: string;
	onChange: (value: manufacturer.Value.Value) => void;
};

export const ManufacturerTypeSelectField: FC<Props> = ({
	id = "ManufacturerTypeSelect",
	label = "類型",
	value,
	error = "",
	onChange,
}) => {
	return (
		<MooTypeableSelect
			id={id}
			label={label}
			options={[
				{
					value: null,
					label: "請選擇",
				},
				{
					value: manufacturer.Value.Value.Domestic,
					label: manufacturer.Label.Label.Domestic,
				},
				{
					value: manufacturer.Value.Value.Foreign,
					label: manufacturer.Label.Label.Foreign,
				},
			]}
			value={value}
			error={error}
			// @ts-ignore
			onChange={onChange}
		/>
	);
};
