import { Calculator, One } from "../../moo-purchase-order-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Row): One {
	const items = mapperItems(record.items);

	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		state: record.state,
		state_manager: record.state_manager,
		state_accountant: record.state_accountant,
		purchase_date: record.purchase_date,
		deliver_date: record.deliver_date,
		request_buy_ids: record.request_buy_ids,
		owner_id: record.owner_id,
		foreign_manufacturer_id: record.foreign_manufacturer.id,
		foreign_manufacturer_tel:
			record.foreign_manufacturer.tels.length === 0 ? "" : record.foreign_manufacturer.tels[0].tel || "",
		foreign_manufacturer_fax: record.foreign_manufacturer.fax,
		foreign_manufacturer_email: record.foreign_manufacturer.email,
		foreign_manufacturer_country: record.foreign_manufacturer.country,
		foreign_manufacturer_currency: record.foreign_manufacturer.currency,
		foreign_manufacturer_bank_account:
			record.foreign_manufacturer.accounts.length === 0
				? ""
				: record.foreign_manufacturer.accounts[0].bank_account || "",
		foreign_manufacturer_swift_code:
			record.foreign_manufacturer.accounts.length === 0 ? "" : record.foreign_manufacturer.accounts[0].swift_code || "",
		foreign_manufacturer_code_iban:
			record.foreign_manufacturer.accounts.length === 0 ? "" : record.foreign_manufacturer.accounts[0].code_iban || "",
		deliver_method: record.deliver_method,
		warehouse_id: record.warehouse_id,
		note: record.note,
		items,
		files: record.files,
		manufacturer_files: record.manufacturer_files,
		other_files: record.other_files,
		accountant_tax: record.accountant_tax,
		accountant_total_price: Calculator.getAccountantTotalPrice(items),
		accountant_received: record.accountant_received,
		accountant_freight: record.accountant_freight,
		accountant_declare_fee: record.accountant_declare_fee,
		accountant_pickup_charge: record.accountant_pickup_charge,
		accountant_miscellaneous_charge: record.accountant_miscellaneous_charge,
		accountant_note: record.accountant_note,
		voucher_rows: record.voucher_rows,
		mgt_fee: record.mgt_fee,
	};
}
