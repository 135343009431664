import { object, SchemaOf } from "yup";
import { arrayRequiredSchema, dateRequired, statusSchema, stringOptional } from "../../moo-schemas";
import { One } from "../@types/one";
import { linkSchema } from "./linkSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: statusSchema,
	start_date: dateRequired("start_date"),
	end_date: dateRequired("start_date"),
	// @ts-ignore
	links: arrayRequiredSchema(linkSchema),
	content: stringOptional,
	create_date: dateRequired("create_date"),
});
