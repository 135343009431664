import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "sales",
			label: "業務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "manufacturer",
			label: "廠商",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "state_purchase",
			label: "採購確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "order_ids",
			label: "連結訂單",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
	];
}
