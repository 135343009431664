import { DocumentNode, gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { manufacturer, Status } from "../moo-enums";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

type Value = string | null;

type Props = {
	type: manufacturer.Value.Value;
	value: Value;
	error: string;
	onChange: (value: Value) => void;
};

function getQuery(resource: string): DocumentNode {
	return gql`
      query ($where: ${resource}_bool_exp){
				${resource}(where: $where) {
					id
					name
				}
      }
	`;
}

function useVariables(): Record<string, any> {
	const { value } = useBranch();
	const where: Record<string, any> = {
		status: {
			_eq: Status.Present,
		},
		branch_id: {
			_eq: value,
		},
	};

	return {
		where,
	};
}

export function ManufacturerIdTypeableSelectField({ type, value, error, onChange }: Props) {
	const resource = type === manufacturer.Value.Value.Domestic ? "manufacturer_domestics" : "manufacturer_foreigns";
	const query = getQuery(resource);
	const variables = useVariables();
	const { isLoading, data } = useGraphQLQuery({ operationName: "ManufacturerIdTypeableSelectField", query, variables });

	const options = isLoading
		? []
		: // @ts-ignore
		  data[resource].map((e) => {
				return {
					value: e.id,
					label: e.name,
				};
		  });

	return (
		<MooTypeableSelect
			id="ManufacturerIdTypeableSelectField"
			isLoading={isLoading}
			options={options}
			label="廠商"
			value={value || ""}
			error={error}
			onChange={onChange}
		/>
	);
}
