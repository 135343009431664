import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "start_date",
			label: "開始日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "end_date",
			label: "結束日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "content",
			label: "內容",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "message_count",
			label: "留言數量",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
	];
}
