import { requestBuy, Status } from "../moo-enums";
import { getNowLocaleString, uuidv4 } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();
	return {
		status: Status.Present,
		create_date: now,
		update_date: now,
		state_purchase: requestBuy.StatePurchase.Value.NotConfirmed,
		propose_date: now,
		order_ids: [],
		sales_id: null,
		sales_tel: "",
		foreign_manufacturer_id: null,
		foreign_manufacturer_tel: "",
		foreign_manufacturer_fax: "",
		foreign_manufacturer_email: "",
		item_infos: [
			{
				request_buy_item_info_id: uuidv4(),
				image: "",
				website: "",
			},
		],
		note: "",
		items: [],
		voucher_rows: [],
	};
}
