import { MooGqlChildNewTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";

export class RolePermissionTable extends MooGqlChildNewTable<FindOneRecord> {
	getIdName(): string {
		return "role_permission_id";
	}

	getTableName(): string {
		return "role_permissions";
	}

	getPathName(): string {
		return "permissions";
	}

	getParentIdName(): string {
		return "role_id";
	}

	getToUpdateColumns(): string[] {
		return ["permission", "branch_id"];
	}
}
