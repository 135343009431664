import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import useLogout from "../../../hooks/useLogout";
import Utils from "../../../utils";
import { useAccountants } from "./useAccountants";
import { useBlogs } from "./useBlogs";
import { useCustomers } from "./useCustomers";
import { useDans } from "./useDans";
import { useEmployees } from "./useEmployees";
import { useItems } from "./useItems";
import { useManufacturers } from "./useManufacturers";
import { useProduces } from "./useProduces";
import { usePurchases } from "./usePurchases";
import { useServices } from "./useServices";
import { useStocks } from "./useStocks";

function useNavigations(): ListType[] {
	const customers = useCustomers();
	const manufacturers = useManufacturers();
	const items = useItems();
	const dans = useDans();
	const produces = useProduces();
	const purchases = usePurchases();
	const services = useServices();
	const stocks = useStocks();
	const accountants = useAccountants();
	const blogs = useBlogs();
	const employees = useEmployees();
	const logout = useLogout();

	return [
		...customers,
		...manufacturers,
		...items,
		...dans,
		...produces,
		...purchases,
		...services,
		...stocks,
		...accountants,
		...blogs,
		...employees,
		{
			id: "line",
			isLine: true,
		},
		{
			id: "logout",
			txt: "登出",
			icon: Utils.getAbsoluteStaticUrl("icons/logout.svg"),
			isLine: false,
			async onClick() {
				if (window.confirm("確定登出嗎?")) {
					await logout();
				}
			},
			open: true,
			openChildren: false,
			children: [],
		},
	];
}

export default useNavigations;
