import { FC } from "react";
import { DEFAULT_ONE, MooAllowanceOne, One } from "../moo-allowance-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooAllowanceAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooAllowanceAdd: FC<MooAllowanceAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("allowances", onSuccess);
	const permissions = usePermissions();

	return (
		<MooAllowanceOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.AllowanceAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
