import { gql } from "@apollo/client";

export const queryField = `
    id
    status
    name
    create_date
    update_date
    branch {
        id
        name
    }
    permissions {
      role_permission_id
      branch_id
      permission
    }
`;

export const query = gql`
    query ($id: uuid!) {
        roles_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
