import produce from "immer";
import { DEFAULT_ONE } from "./DEFAULT_ONE";

export const shape = produce(DEFAULT_ONE(), (draft) => {
	draft.items.push({
		// @ts-ignore
		order_good_item_id: null,
		// @ts-ignore
		id: null,
		// @ts-ignore
		manufacturer_type: null,
		manufacturer_id: null,
		// @ts-ignore
		manufacturer: null,
		// @ts-ignore
		category: null,
		// @ts-ignore
		type: null,
		// @ts-ignore
		name: null,
		// @ts-ignore
		cost: null,
		// @ts-ignore
		spec: null,
		// @ts-ignore
		material: null,
		// @ts-ignore
		color: null,
		// @ts-ignore
		size: null,
		// @ts-ignore
		count: null,
		// @ts-ignore
		price: null,
		// @ts-ignore
		price_final: null,
		// @ts-ignore
		price_total: null,
		// @ts-ignore
		purchase_price: null,
		// @ts-ignore
		purchase_price_total: null,
		// @ts-ignore
		note: null,
		// @ts-ignore
		image: null,
		image_cut: null,
		// @ts-ignore
		priority: null,
	});

	// @ts-ignore
	draft.instructions = [{ order_good_instruction_id: null, value: null }];
	// @ts-ignore
	draft.voucher_rows = [{ voucher_id: null }];
});
