import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooManufacturerTypeList } from "../components/moo-components/moo-manufacturer-type-list";

export const path = "/manufacturer/types";

const ManufacturerTypesRoute = () => {
	const branch = useBranchInQuery();
	const navigate = useNavigate();

	return (
		<MooManufacturerTypeList
			onClick={async (id: string) => {
				await navigate(`/manufacturer/types/${id}?branch=${branch}`);
			}}
		/>
	);
};

export default ManufacturerTypesRoute;
