import { object, SchemaOf } from "yup";
import { stringOptional, stringRequired } from "../../moo-schemas";
import { Account } from "../@types/foreign-manufacturer";

// @ts-ignore
export const accountSchema: SchemaOf<Account> = object().shape({
	manufacturer_foreign_account_id: stringRequired("manufacturer_foreign_account_id"),
	bank_account: stringOptional,
	swift_code: stringOptional,
	code_iban: stringOptional,
});
