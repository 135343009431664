import { Row, useFindItemSpecsByIds } from "./useFindItemSpecsByIds";

export function useFindItemSpecsMapByIds() {
	const findItemSpecsByIds = useFindItemSpecsByIds();

	return async (ids: string[]): Promise<Map<string, Row>> => {
		return (await findItemSpecsByIds(ids)).reduce((acc, curr) => {
			acc.set(curr.id, curr);
			return acc;
		}, new Map());
	};
}
