import { Calculator } from "../../moo-quotation-one";
import { Item } from "../../moo-request-buy-one";
import { RowItem } from "./RowItem";

export function mapperItems(items: RowItem[]): Item[] {
	return items.map(({ id, request_buy_item_id, count, item_spec, item_custom }, priority) => {
		const item = item_spec || item_custom;
		const purchase_price = item.purchase_price;

		return {
			request_buy_item_id,
			id,
			name: item.name,
			material: item.material,
			color: item.color,
			size: item.size,
			image_cut: item.image_cut,
			manufacturer_type: item.manufacturer.type,
			manufacturer_id: item.manufacturer.id,
			manufacturer: item.manufacturer.name,
			count,
			purchase_price,
			purchase_price_total: Calculator.getItemPurchasePriceTotal({ count, purchase_price }),
			priority,
		};
	});
}
