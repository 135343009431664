import { gql } from "@apollo/client";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { useState } from "react";
import { useGraphQLRequest } from "../moo-graphql-provider";
import { useMe } from "../moo-me-provider";
import { MooMessageOne } from "../moo-message-one";

export type MooMessageAddProps = {
	id: string;
	resource: string;
	userName: string;
	userBackgroundColor: string;
	onSuccess: (data: any) => void;
	onError: (data: any) => void;
};

export function MooMessageAdd({ id, resource, userName, userBackgroundColor, onSuccess, onError }: MooMessageAddProps) {
	const [content, setContent] = useState("");
	const graphQLRequest = useGraphQLRequest();
	const me = useMe()!;

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<MooMessageOne
				id={id}
				userName={userName}
				userBackgroundColor={userBackgroundColor}
				value={content}
				onChange={setContent}
			/>
			<KodenButton
				style={{ margin: "1rem 0 0 auto" }}
				onClick={async () => {
					try {
						const data = await graphQLRequest({
							query: gql`
                mutation($object: ${resource}_messages_insert_input!){
                  insert_${resource}_messages_one(object: $object){
                    ${resource}_message_id
                    content
                  }
                }
              `,
							variables: {
								object: {
									[`${resource}_id`]: id,
									employee_id: me.employee.id,
									content,
								},
							},
						});
						setContent("");
						onSuccess(data);
					} catch (e) {
						setContent("");
						onError("發生錯誤！");
					}
				}}
			>
				送出
			</KodenButton>
		</div>
	);
}
