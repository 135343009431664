import { useListGraphQLQuery } from "../moo-shared";
import { graphQLColumns } from "./graphQLColumns";

export function useGraphQLQuery() {
	return useListGraphQLQuery(
		"orders",
		graphQLColumns,
		`
      sum {
        total_price
      }
	`,
	);
}
