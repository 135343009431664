import type { CellType } from "@yachelee/koden-components/koden-table";
import { allowance } from "../moo-enums";
import { DateFormat } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "request_date",
			align: "left",
			component: ({ request_date }: Row) => {
				return <DateFormat>{request_date}</DateFormat>;
			},
		},
		{
			id: "requester",
			align: "left",
			component: ({ requester }: Row) => {
				return <div>{requester}</div>;
			},
		},
		{
			id: "reason",
			align: "left",
			component: ({ reason }: Row) => {
				return <div>{reason}</div>;
			},
		},
		{
			id: "amount",
			align: "left",
			component: ({ amount }: Row) => {
				return <div>{amount}</div>;
			},
		},
		{
			id: "accountant",
			align: "left",
			component: ({ accountant }: Row) => {
				return <div>{accountant}</div>;
			},
		},
		{
			id: "state_accountant",
			align: "left",
			component: ({ state_accountant }: Row) => {
				return <div>{allowance.StateAccountant.getLabel(state_accountant)}</div>;
			},
		},
		{
			id: "state_manager",
			align: "left",
			component: ({ state_manager }: Row) => {
				return <div>{allowance.StateManager.getLabel(state_manager)}</div>;
			},
		},
		{
			id: "state_requester",
			align: "left",
			component: ({ state_requester }: Row) => {
				return <div>{allowance.StateRequester.getLabel(state_requester)}</div>;
			},
		},
	];
}
