import { Fragment } from "react";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query } from "./@types";
import { graphQLColumns } from "./graphQLColumns";
import { mapData } from "./mapData";
import { useCells } from "./useCells";
import { useConditions } from "./useConditions";
import { useHeaders } from "./useHeaders";

export type MooReturnGoodsListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooReturnGoodsList = ({ onAdd, onRowClick, store }: MooReturnGoodsListProps) => {
	const itemStore = store();
	const cells = useCells();
	const headers = useHeaders();
	const permissions = usePermissions();
	const conditions = useConditions(itemStore.query);

	if (!permissions.includes(Permission.ReturnGoodsListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooList
			showAddButton={permissions.includes(Permission.ReturnGoodsAdd)}
			resource="return_goods"
			graphQLColumns={graphQLColumns}
			addLabel="新增退貨單"
			onAdd={onAdd}
			filterNode={<Fragment />}
			headers={headers}
			mapData={mapData}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
			conditions={conditions}
		/>
	);
};
