import { KodenBreadcrumbs } from "@yachelee/koden-components/koden-breadcrumbs";
import { generatePath, useParams } from "react-router-dom";
import { useMatchedPath } from "../../hooks/useMatchedPath";
import useURLSearchParams from "../../hooks/useURLSearchParams";
import { useBranchInQuery } from "../MainLayout/hooks/useBranchInQuery";
import Link from "../elements/Link";
import { useFindOne } from "../moo-components/moo-shared";
import getPageTree, { PageType } from "./getPageTree";

function useHomePageData() {
	const branch = useBranchInQuery();

	return {
		id: "/",
		text: "首頁",
		isSelected: false,
		link: `/?branch=${branch}`,
	};
}

function useGetData(link: string) {
	const homePageData = useHomePageData();
	const urlSearchParams = useURLSearchParams();
	const params = useParams();
	const pageTree = getPageTree();
	if (link === "/") return [{ ...homePageData, isSelected: true }];

	let page = pageTree[link];
	const data = [];
	while (page != null && page.parent !== null) {
		const text = page.useText();
		const theLink = `${generatePath(link, params)}?${urlSearchParams.toString()}`;

		data.push({
			id: text,
			text,
			link: theLink,
			isSelected: false,
		});
		link = page.parent;
		page = pageTree[page.parent];
	}

	if (data.length > 0) {
		data[0].isSelected = true;
		data.reverse();
		data.unshift({ ...homePageData });
	}
	return data;
}

function SimpleBreadcrumbs() {
	const path = useMatchedPath();
	const data = useGetData(path);

	return <KodenBreadcrumbs Link={Link} data={data} />;
}

function getOperationName(resource: string): string {
	return `${resource!}_by_pk`;
}

function usePageData(id: string, page: PageType) {
	let one: Record<string, unknown> = { id: "" };
	if (page.fields) {
		one = {};
		page.fields.forEach((e) => {
			one[e] = "";
		});
	}
	const idType = page?.idType || "uuid!";
	return useFindOne(id, idType, one, getOperationName(page.resource!), {});
}

function ResourceBreadcrumbs({ page }: { page: PageType }) {
	const path = useMatchedPath();
	const { id } = useParams<{ id: string }>();
	const data = useGetData(page.parent!);
	const record = usePageData(id || "", page);
	const operationName = getOperationName(page.resource!);

	//if the data is not fetched yet, we return nothing
	if (record.isFetching) return <KodenBreadcrumbs Link={Link} data={[]} />;

	data[data.length - 1].isSelected = false;

	data.push({
		id: path,
		text: page.useText((record?.data as Record<string, unknown>)[operationName] || ""),
		link: "page",
		isSelected: true,
	});

	return <KodenBreadcrumbs Link={Link} data={data} />;
}

function Breadcrumbs() {
	const pageTree = getPageTree();
	const path = useMatchedPath();
	const page = path in pageTree ? pageTree[path] : null;
	if (page?.resource) {
		return <ResourceBreadcrumbs page={page} />;
	} else {
		return <SimpleBreadcrumbs />;
	}
}

export default Breadcrumbs;
