import { createContext, useContext } from "react";

type ContextType = {
	isEdit: boolean;
};

export const MooUseIsEditContext = createContext<ContextType>({ isEdit: false });

export function useIsEdit(): boolean {
	return useContext(MooUseIsEditContext).isEdit;
}
