import CloseIcon from "@mui/icons-material/Close";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import { Formik } from "formik";
import * as _ from "lodash";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { manufacturer } from "../moo-enums";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { useGetFieldError } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { LogisticsManufacturer } from "./@types/logistics-manufacturer";
import { Props } from "./@types/props";
import { useForm } from "./hooks/useForm";
import { useUpdate } from "./hooks/useUpdate";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 2rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const TextFieldRow = styled(ProxyKodenTextField)`
	width: 100%;
`;

const DataSetDeleteBtn = styled(CloseIcon)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	cursor: pointer;
`;

const RowContainer = styled.div`
	grid-column: 1 / -1;
	display: grid;
	grid-gap: 30px 15px;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-auto-flow: row;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: 1fr 1fr;
	}
`;

const DataSetContainer = styled.div`
	position: relative;
	display: grid;
	grid-gap: 10px;
	border: 1px solid #c4c4c4;
	padding: 12px;
	border-radius: 3px;
`;

const SubTitle = styled.div`
	position: relative;
	font-size: 0.8rem;
	color: #858585;
`;

const TextIconBtn = styled.div`
	border: 1px dashed #c4c4c4;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	& .top-bar {
		width: 100%;
		padding: 10.5px 14px;
	}
`;

function Form({
	isLoading = false,
	isCancelDisabled,
	isOkDisabled,
	onCancel,
}: Omit<Props, "initialValues" | "onSubmit">) {
	const { addAccount, addDriver, delAccount, delDriver, updateAccount, updateDriver } = useUpdate();
	const { values, setFieldValue, errors, handleSubmit } = useForm();
	const permissions = usePermissions();
	const getFieldError = useGetFieldError<LogisticsManufacturer>();

	if (!permissions.includes(Permission.ManufacturerLogisticsRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<form onSubmit={handleSubmit}>
			<FieldContainer>
				<MooTypeableSelect
					id="type"
					label="類型"
					options={[
						{
							value: null,
							label: "請選擇",
						},
						{
							value: manufacturer.Value.Value.Domestic,
							label: manufacturer.Label.Label.Domestic,
						},
						{
							value: manufacturer.Value.Value.Foreign,
							label: manufacturer.Label.Label.Foreign,
						},
					]}
					value={values.type}
					error={getFieldError("type")}
					onChange={(value) => setFieldValue("type", value)}
				/>
				<ProxyKodenTextField
					id="name"
					label="廠商名稱"
					disabled={isLoading}
					outlined
					margin="none"
					value={values.name}
					error={getFieldError("name")}
					onChange={(value) => setFieldValue("name", value)}
				/>
				<TextFieldEnd
					id="email"
					label="Email"
					disabled={isLoading}
					outlined
					margin="none"
					value={values.email}
					error={getFieldError("email")}
					onChange={(value) => setFieldValue("email", value)}
				/>
				<ProxyKodenTextField
					id="contact"
					label="聯絡人"
					disabled={isLoading}
					outlined
					margin="none"
					value={values.contact}
					error={getFieldError("contact")}
					onChange={(value) => setFieldValue("contact", value)}
				/>
				<ProxyKodenTextField
					id="tel"
					label="電話"
					disabled={isLoading}
					outlined
					margin="none"
					value={values.tel}
					error={getFieldError("tel")}
					onChange={(value) => setFieldValue("tel", value)}
				/>
				<ProxyKodenTextField
					id="fax"
					label="傳真"
					disabled={isLoading}
					outlined
					margin="none"
					value={values.fax}
					error={getFieldError("fax")}
					onChange={(value) => setFieldValue("fax", value)}
				/>
				<RowContainer>
					{values.drivers.map((driver, index) => {
						const nameError = _.get(errors, `drivers.${index}.name`, "");

						return (
							<DataSetContainer key={driver.manufacturer_logistic_driver_id}>
								<SubTitle>
									司機{values.drivers.length > 1 ? ` ${index + 1}` : ""}
									{values.drivers.length > 1 && (
										<DataSetDeleteBtn
											fontSize="small"
											color="disabled"
											onClick={() => delDriver(driver.manufacturer_logistic_driver_id)}
										>
											x
										</DataSetDeleteBtn>
									)}
								</SubTitle>
								<TextFieldRow
									id="name"
									label="姓名"
									outlined
									margin="none"
									value={driver.name}
									error={nameError}
									onChange={(value) => updateDriver(driver.manufacturer_logistic_driver_id, "name", value)}
								/>
								<TextFieldRow
									id="tel"
									label="電話"
									outlined
									margin="none"
									value={driver.tel}
									error=""
									onChange={(value) => updateDriver(driver.manufacturer_logistic_driver_id, "tel", value)}
								/>
								<TextFieldRow
									id="line"
									label="Line"
									outlined
									margin="none"
									value={driver.line}
									error=""
									onChange={(value) => updateDriver(driver.manufacturer_logistic_driver_id, "line", value)}
								/>
							</DataSetContainer>
						);
					})}
					<TextIconBtn>
						<KodenIconTextButton textColor="#757575" text="新增司機" onClick={() => addDriver()} />
					</TextIconBtn>
				</RowContainer>

				<RowContainer>
					{values.accounts.map((account, index) => {
						const nameError = _.get(errors, `accounts.${index}.name`, "");

						return (
							<DataSetContainer key={account.manufacturer_logistic_account_id}>
								<SubTitle>
									帳戶{values.accounts.length > 1 ? ` ${index + 1}` : ""}
									{values.accounts.length > 1 && (
										<DataSetDeleteBtn
											fontSize="small"
											color="disabled"
											onClick={() => delAccount(account.manufacturer_logistic_account_id)}
										>
											x
										</DataSetDeleteBtn>
									)}
								</SubTitle>
								<TextFieldRow
									id="name"
									label="戶名"
									outlined
									margin="none"
									value={account.name}
									error={nameError}
									onChange={(value) => updateAccount(account.manufacturer_logistic_account_id, "name", value)}
								/>
								<TextFieldRow
									id="bank"
									label="銀行"
									outlined
									margin="none"
									value={account.bank}
									error=""
									onChange={(value) => updateAccount(account.manufacturer_logistic_account_id, "bank", value)}
								/>
								<TextFieldRow
									id="bank_account"
									label="銀行帳號"
									outlined
									margin="none"
									value={account.bank_account}
									error=""
									onChange={(value) => updateAccount(account.manufacturer_logistic_account_id, "bank_account", value)}
								/>
								<TextFieldRow
									id="tax_id"
									label="統編"
									outlined
									margin="none"
									value={account.tax_id}
									error=""
									onChange={(value) => updateAccount(account.manufacturer_logistic_account_id, "tax_id", value)}
								/>
							</DataSetContainer>
						);
					})}
					<TextIconBtn>
						<KodenIconTextButton textColor="#757575" text="新增帳戶" onClick={() => addAccount()} />
					</TextIconBtn>
				</RowContainer>
				<KodenMultilineField
					id="note"
					label="備註"
					disabled={isLoading}
					style={{ gridColumn: "1 / -1" }}
					outlined
					margin="none"
					rows={2}
					value={values.note}
					error={getFieldError("note")}
					onChange={(value) => setFieldValue("note", value)}
				/>
			</FieldContainer>
			{!isOkDisabled && (
				<ButtonContainer>
					<KodenButton
						style={{ marginRight: "1rem" }}
						disabled={isCancelDisabled}
						onClick={async () => {
							if (window.confirm("確定取消並且回到上一頁？")) {
								onCancel();
							}
						}}
					>
						取消
					</KodenButton>
					<MooSubmitButton permission={Permission.ManufacturerLogisticsUpdate} />
				</ButtonContainer>
			)}
		</form>
	);
}

export function MooManufacturerLogisticsOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.ManufacturerLogisticsRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
