import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import * as _ from "lodash";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { useItemDialogChoose } from "../../moo-dialog-item-choose";
import { useRequestBuyDialogChoose } from "../../moo-dialog-request-buy-choose";
import { MooEmployeeSelectField } from "../../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../../moo-enum-typeable-select-field";
import { deliverMethod, manufacturer } from "../../moo-enums";
import { MooLinkField } from "../../moo-link-field";
import { useRequestById } from "../../moo-manufacturer-foreign-update";
import { ManufacturerIdSelectField, useGetFieldError, uuidv4 } from "../../moo-shared";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import { MooVoucherLinkList } from "../../moo-voucher-link-list";
import { One, RequestBuyId } from "../@types/one";
import FieldContainer from "../components/FieldContainer";
import { useChooseItems } from "../hooks/useChooseItems";
import { useForm } from "../hooks/useForm";

const TABLET_BREAKPOINT = "768px";

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

export function BasicBlock() {
	const { values: one, setFieldValue: updateOne, setValues } = useForm();
	const findForeignManufacturerOneById = useRequestById();
	const getFieldError = useGetFieldError<One>();
	const { component: requestBuyDialogChoose, open: openRequestBuyDialog } = useRequestBuyDialogChoose(
		"requestBuyDialogChoose",
		(rows) => {
			const requestBuyIds: RequestBuyId[] = rows.map((e) => ({
				purchase_order_request_buy_id_id: uuidv4(),
				value: e.id,
			}));
			updateOne("request_buy_ids", requestBuyIds);
		},
		true,
	);
	const chooseItems = useChooseItems();

	const { component: itemDialogChooseComponent } = useItemDialogChoose("item-dialog-choose", chooseItems);

	return (
		<>
			{requestBuyDialogChoose}
			{itemDialogChooseComponent}
			<FieldContainer>
				<KodenDateField
					id="purchase_date"
					label="採購日期"
					error={getFieldError("purchase_date")}
					value={new Date(one.purchase_date!)}
					onChange={(value) => {
						updateOne(
							"purchase_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						);
					}}
				/>
				<KodenDateField
					id="deliver_date"
					label="交貨日期"
					error={getFieldError("deliver_date")}
					value={new Date(one.deliver_date!)}
					onChange={(value) => {
						updateOne(
							"deliver_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						);
					}}
				/>
				<MooLinkField
					clickButton={
						<KodenButton
							disabled={false}
							onClick={async () => {
								openRequestBuyDialog();
							}}
						>
							連結國外請購單
						</KodenButton>
					}
					label="已連結國外請購單"
					values={one.request_buy_ids.map((e) => e.value)}
				/>
			</FieldContainer>
			<FieldContainer>
				<MooEmployeeSelectField
					id="owner_id"
					label="承辦人"
					value={one.owner_id}
					error={getFieldError("owner_id")}
					onChange={(value) => {
						updateOne("owner_id", value);
					}}
				/>
				<ManufacturerIdSelectField
					id="foreign_manufacturer_id"
					type={manufacturer.Value.Value.Foreign}
					label="廠商"
					value={one.foreign_manufacturer_id}
					error={getFieldError("foreign_manufacturer_id")}
					onChange={async (foreign_manufacturer_id) => {
						if (foreign_manufacturer_id === null) {
							setValues({
								...one,
								foreign_manufacturer_id: null,
								foreign_manufacturer_tel: null,
								foreign_manufacturer_fax: null,
								foreign_manufacturer_email: null,
								foreign_manufacturer_country: null,
								foreign_manufacturer_currency: null,
							});
						} else {
							const data = (await findForeignManufacturerOneById(foreign_manufacturer_id)).manufacturer_foreigns_by_pk;
							setValues({
								...one,
								foreign_manufacturer_id,
								foreign_manufacturer_tel: _.get(data, "tels.0.tel", ""),
								foreign_manufacturer_fax: data.fax,
								foreign_manufacturer_email: data.email,
								foreign_manufacturer_country: data.country,
								foreign_manufacturer_currency: data.currency,
								foreign_manufacturer_bank_account: _.get(data, "accounts.0.bank_account", ""),
								foreign_manufacturer_swift_code: _.get(data, "accounts.0.swift_code", ""),
								foreign_manufacturer_code_iban: _.get(data, "accounts.0.code_iban", ""),
							});
						}
					}}
				/>
				<ProxyKodenTextField
					id="manufacturer_tel"
					label="電話"
					outlined
					margin="none"
					value={one.foreign_manufacturer_tel}
					disabled
					error=""
				/>
				<ProxyKodenTextField
					id="manufacturer_fax"
					label="傳真"
					outlined
					margin="none"
					value={one.foreign_manufacturer_fax}
					disabled
					error=""
				/>
				<ProxyKodenTextField
					id="foreign_manufacturer_email"
					label="Email"
					outlined
					margin="none"
					value={one.foreign_manufacturer_email}
					disabled
					error=""
				/>
				<ProxyKodenTextField
					id="foreign_manufacturer_country"
					label="國家"
					outlined
					margin="none"
					value={one.foreign_manufacturer_country}
					disabled
					error=""
				/>
				<ProxyKodenTextField
					id="foreign_manufacturer_currency"
					label="幣別"
					outlined
					margin="none"
					value={one.foreign_manufacturer_currency}
					disabled
					error=""
				/>
			</FieldContainer>
			<FieldContainer>
				<ProxyKodenTextField
					id="foreign_manufacturer_bank_account"
					label="戶名"
					margin="none"
					outlined
					value={one.foreign_manufacturer_bank_account}
					error=""
					disabled
					readOnly
				/>
				<ProxyKodenTextField
					id="foreign_manufacturer_swift_code"
					label="Swift Code"
					margin="none"
					outlined
					value={one.foreign_manufacturer_swift_code}
					error=""
					disabled
					readOnly
				/>
				<TextFieldEnd
					id="foreign_manufacturer_code_iban"
					label="Code Iban"
					disabled
					margin="none"
					outlined
					value={one.foreign_manufacturer_code_iban}
					error=""
					readOnly
				/>
			</FieldContainer>
			<FieldContainer>
				<MooTypeableSelect
					id="deliver_method"
					label="類型"
					options={[
						{
							value: deliverMethod.Value.Value.Sea,
							label: deliverMethod.Label.Label.Sea,
						},
						{
							value: deliverMethod.Value.Value.Air,
							label: deliverMethod.Label.Label.Air,
						},
					]}
					value={one.deliver_method}
					error={getFieldError("deliver_method")}
					onChange={(value) => {
						updateOne("deliver_method", value);
					}}
				/>
				<MooEnumTypeableSelectField
					id="warehouse_id"
					resource="warehouses"
					label="收貨倉庫"
					value={one.warehouse_id}
					error={getFieldError("warehouse_id")}
					onChange={(warehouse_id) => {
						updateOne("warehouse_id", warehouse_id);
					}}
				/>
				<TextFieldEnd
					id="note"
					label="備註"
					outlined
					margin="none"
					value={one.note}
					error=""
					onChange={(note) => {
						updateOne("note", note);
					}}
				/>
				<KodenNumberField
					id="mgt_fee"
					label="管銷費"
					outlined
					margin="none"
					hasDollar
					value={one.mgt_fee}
					error={getFieldError("mgt_fee")}
					step={1}
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					onChange={(value) => updateOne("mgt_fee", value)}
				/>
			</FieldContainer>
			<MooVoucherLinkList values={one.voucher_rows.map((e) => e.voucher_id)} />
		</>
	);
}
