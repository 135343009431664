import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NumbersIcon from "@mui/icons-material/Numbers";
import { KodenAvatar } from "@yachelee/koden-components/koden-avatar";
import styled from "styled-components";
import { One } from "../moo-blog-one";
import { useOnEmployeeIdClick } from "../moo-employee-id-provider";
import { useOnLinkClick } from "../moo-link-provider";
import { MooScheduleCard, MooScheduleCardProps } from "../moo-schedule-card";
import { getDateFormat } from "../moo-shared";
import { MooTags } from "../moo-tags";
import Images from "./Images";

export type MooBlogCardProps = {
	user_id: string;
	user_name: string;
	user_color: string;
	schedule_id: string;
	schedule_start_date: MooScheduleCardProps["start_date"];
	schedule_end_date: MooScheduleCardProps["end_date"];
	schedule_links: MooScheduleCardProps["links"];
	schedule_content: MooScheduleCardProps["content"];
	schedule_create_date: MooScheduleCardProps["create_date"];
	schedule_employee_id: MooScheduleCardProps["user_id"];
	schedule_employee_name: MooScheduleCardProps["user_name"];
	schedule_user_color: MooScheduleCardProps["user_color"];
	date: One["date"];
	links: One["links"];
	attaches: One["attaches"];
	content: One["content"];
	create_date: string;
	edit: boolean;
	remove: boolean;
	onEditClick: () => void;
	onRemoveClick: () => void;
};

const TABLET_BREAKPOINT = "768px";

const Header = styled.header`
	font-size: 1.8rem;
	margin-bottom: 0.5rem;
	font-weight: bold;
`;

const Date = styled.div`
	font-size: 0.7rem;
	color: #707070;
`;

const Card = styled.div`
	border-radius: 4px;
	border: 0.5px solid #bcbcbc;
	padding: 1.3rem;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const Container = styled.div`
	display: grid;
	grid-gap: 1rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

const ActionBtn = styled.button`
	background: transparent;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;

	& svg {
		margin-right: 0.3rem;
	}

	&:active {
		outline: none;
	}
	&:not(:last-of-type) {
		margin-right: 1rem;
	}
`;

const DeleteBtn = styled(ActionBtn)`
	color: #e66c6c;
	cursor: pointer;
`;

const EditBtn = styled(ActionBtn)`
	color: #6d87c6;
	cursor: pointer;
`;

export function MooBlogCard({
	schedule_start_date,
	schedule_end_date,
	schedule_links,
	schedule_content,
	schedule_create_date,
	schedule_employee_id,
	schedule_employee_name,
	schedule_user_color,
	links,
	attaches,
	user_color,
	user_name,
	user_id,
	content,
	date,
	edit,
	remove,
	onEditClick,
	onRemoveClick,
}: MooBlogCardProps) {
	const onEmployeeIdClick = useOnEmployeeIdClick();
	const onLinkClick = useOnLinkClick();

	return (
		<Container>
			<div>
				<Header>日誌</Header>
				<Card>
					<Row>
						<AvatarContainer onClick={() => onEmployeeIdClick(user_id)}>
							<KodenAvatar backgroundColor={user_color} value={user_name} />
							<div style={{ marginLeft: ".5rem", fontWeight: "bold" }}>{user_name}</div>
						</AvatarContainer>
						<Date>{getDateFormat(date)}</Date>
					</Row>
					<div style={{ margin: "1.5rem 0 0 0" }}>
						<MooTags
							tags={links.map((e) => e.value)}
							onClick={onLinkClick}
							tagIcon={<NumbersIcon fontSize="inherit" />}
						/>
					</div>
					<div style={{ margin: ".5rem 0 2rem 0" }}>
						<Images attaches={attaches.map((e) => e.value)} />
					</div>
					<div style={{ marginBottom: "2rem" }}>{content}</div>
					{(remove || edit) && (
						<div style={{ display: "flex" }}>
							{remove && (
								<DeleteBtn onClick={onRemoveClick}>
									<DeleteIcon fontSize="small" />
									刪除
								</DeleteBtn>
							)}
							{edit && (
								<EditBtn onClick={onEditClick}>
									<EditIcon fontSize="small" />
									編輯
								</EditBtn>
							)}
						</div>
					)}
				</Card>
			</div>
			<div>
				<Header>參照週計畫</Header>
				<div>
					<MooScheduleCard
						start_date={schedule_start_date}
						end_date={schedule_end_date}
						links={schedule_links}
						content={schedule_content}
						create_date={schedule_create_date}
						user_id={schedule_employee_id}
						user_name={schedule_employee_name}
						user_color={schedule_user_color}
						edit={false}
						remove={false}
					/>
				</div>
			</div>
		</Container>
	);
}

export default MooBlogCard;
