import produce from "immer";
import * as _ from "lodash";
import { Row } from "../@types";
import { StoreSlice } from "./useStore";

export type ChooseStoreSlice = {
	chooseData: Record<string, Row>;
	getChooseSet: () => Row[];
	toggleChoose: (row: Row) => void;
	hasChosen: (row: Row) => boolean;
	clearChoose: () => void;
};

export const createChooseSlice: StoreSlice<ChooseStoreSlice> = (set, get) => ({
	chooseData: {},
	getChooseSet: () => _.values(get().chooseData),
	toggleChoose: (row) =>
		set(
			produce((draft) => {
				const choosing = get().hasChosen(row);
				if (choosing) {
					delete draft.chooseData[row.id.toString()];
				} else {
					draft.chooseData[row.id.toString()] = row;
				}
			}),
		),
	hasChosen: (row) => _.has(get().chooseData, row.id.toString()),
	clearChoose: () =>
		set(
			produce((draft) => {
				draft.chooseData = {};
			}),
		),
});
