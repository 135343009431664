import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    
    manufacturer_type
    manufacturer_id
    category_id
    sub_category_id
    buy_year
    name
    material
    color
    color_image
    size
    owner_id
    sell_deadline
    note
    purchase_price
    cost
    price
    original_code
    country
    image
    image_cut
`;

export const query = gql`
    query ($id: String!) {
        item_customs_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
