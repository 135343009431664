import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class PurchaseOrderItemsTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "purchase_order_item_id";
	}

	getTableName(): string {
		return "purchase_order_items";
	}

	getPathName(): string {
		return "items";
	}

	getParentIdName(): string {
		return "purchase_order_id";
	}

	getToUpdateColumns(): string[] {
		return ["id", "count", "priority"];
	}
}
