import { defectiveProduct } from "../../moo-enums";
import { useIsEdit } from "../../moo-use-is-edit";
import { useForm } from "./useForm";

export function useLockStock(): boolean {
	const { initialValues } = useForm();
	const isEdit = useIsEdit();

	return (
		isEdit &&
		(initialValues.state === defectiveProduct.State.Value.MAINTAINED_AND_IMPORTED ||
			initialValues.state === defectiveProduct.State.Value.DONE)
	);
}
