import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import * as _ from "lodash";
import { Branch } from "../moo-branch-provider";
import { Permission } from "../moo-me-provider";
import { CheckAll } from "./CheckAll";
import { PermissionCheckboxField } from "./PermissionCheckField";
import { allAdminPermissions } from "./allAdminPermissions";
import { allBranchPermissions } from "./allBranchPermissions";
import { useForm } from "./useForm";

type Props = {
	branch_id: Branch;
};

export function PermissionTree({ branch_id }: Props) {
	const { errors } = useForm();
	const error = _.get(errors, `permission.${branch_id}`, "");

	return (
		<>
			<FormControl
				sx={{ m: 3 }}
				component="fieldset"
				variant="standard"
				style={{ margin: 0, marginTop: 16 }}
				error={error !== ""}
			>
				{branch_id === Branch.ADMIN ? (
					<>
						<CheckAll branch_id={branch_id} all={allAdminPermissions} />
						<FormLabel component="legend">角色</FormLabel>
						<FormGroup>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.RoleListRead} label="列表-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.RoleRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.RoleAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.RoleUpdate} label="更新" />
						</FormGroup>

						<FormLabel component="legend">員工</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.EmployeeListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.EmployeeRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.EmployeeAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.EmployeeUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.EmployeeSalaryRead}
								label="薪水-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.EmployeeSalaryWrite}
								label="薪水-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.EmployeeAssignRole}
								label="指派角色"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.EmployeeTypeWrite}
								label="類型更新"
							/>
						</FormGroup>
					</>
				) : (
					<>
						<CheckAll branch_id={branch_id} all={allBranchPermissions} />
						<FormLabel component="legend">客戶</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.CustomerListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.CustomerRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.CustomerAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.CustomerUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.CustomerTypeWrite}
								label="類型更新"
							/>
						</FormGroup>

						<FormLabel component="legend">國內廠商</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerDomesticListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerDomesticRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerDomesticAdd}
								label="新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerDomesticUpdate}
								label="更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerDomesticTypeWrite}
								label="類型更新"
							/>
						</FormGroup>

						<FormLabel component="legend">國外廠商</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerForeignListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerForeignRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerForeignAdd}
								label="新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerForeignUpdate}
								label="更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerForeignTypeWrite}
								label="類型更新"
							/>
						</FormGroup>

						<FormLabel component="legend">物流廠商</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerLogisticsListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerLogisticsRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerLogisticsAdd}
								label="新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ManufacturerLogisticsUpdate}
								label="更新"
							/>
						</FormGroup>

						<FormLabel component="legend">商品類型</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCategoryWrite}
								label="列表-瀏覽"
							/>
						</FormGroup>

						<FormLabel component="legend">規格商品</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ItemSpecRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ItemSpecAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ItemSpecUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecPurchasePriceRead}
								label="進貨成本-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecPurchasePriceAdd}
								label="進貨成本-新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecPurchasePriceUpdate}
								label="進貨成本-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecCostRead}
								label="總成本-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecCostAdd}
								label="總成本-新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecCostUpdate}
								label="總成本-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecPriceRead}
								label="建議售價-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecPriceAdd}
								label="建議售價-新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemSpecPriceUpdate}
								label="建議售價-更新"
							/>
						</FormGroup>

						<FormLabel component="legend">訂製商品</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ItemCustomRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ItemCustomAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ItemCustomUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomPurchasePriceRead}
								label="進貨成本-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomPurchasePriceAdd}
								label="進貨成本-新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomPurchasePriceUpdate}
								label="進貨成本-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomCostRead}
								label="總成本-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomCostAdd}
								label="總成本-新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomCostUpdate}
								label="總成本-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomPriceRead}
								label="建議售價-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomPriceAdd}
								label="建議售價-新增"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ItemCustomPriceUpdate}
								label="建議售價-更新"
							/>
						</FormGroup>
						<FormLabel component="legend">收款人</FormLabel>
						<FormGroup>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.PayeeWrite} label="列表-瀏覽" />
						</FormGroup>

						<FormLabel component="legend">報價單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.QuotationTypeWrite}
								label="類型管理"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.QuotationListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.QuotationRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.QuotationAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.QuotationUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.QuotationStateWrite}
								label="狀態-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.QuotationStateManagerWrite}
								label="主管簽核-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.QuotationStateAccountantWrite}
								label="會計狀態-更新"
							/>
						</FormGroup>

						<FormLabel component="legend">訂購單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.OrderListRead} label="列表-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.OrderRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.OrderAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.OrderUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.OrderStateWrite}
								label="狀態-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.OrderStateManagerWrite}
								label="主管簽核-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.OrderStateAccountantWrite}
								label="會計確認-更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.OrderStateWarehouseWrite}
								label="倉管確認-更新"
							/>
						</FormGroup>

						<FormLabel component="legend">退訂單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ReturnOrderListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ReturnOrderRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ReturnOrderAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ReturnOrderUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ReturnOrderStateWrite}
								label="狀態更新"
							/>
						</FormGroup>

						<FormLabel component="legend">退貨單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ReturnGoodsListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ReturnGoodsRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ReturnGoodsAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ReturnGoodsUpdate} label="更新" />
						</FormGroup>

						<FormLabel component="legend">訂貨單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.OrderGoodsListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.OrderGoodsRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.OrderGoodsAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.OrderGoodsUpdate} label="更新" />
						</FormGroup>

						<FormLabel component="legend">國外採購單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.PurchaseOrderListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.PurchaseOrderRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.PurchaseOrderAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.PurchaseOrderUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.PurchaseOrderStateWrite}
								label="狀態更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.PurchaseOrderStateManagerWrite}
								label="主管簽核更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.PurchaseOrderStateAccountantWrite}
								label="會計狀態更新"
							/>
						</FormGroup>

						<FormLabel component="legend">國外請購單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.RequestBuyListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.RequestBuyRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.RequestBuyAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.RequestBuyUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.RequestBuyStatePurchaseWrite}
								label="採購確認更新"
							/>
						</FormGroup>

						<FormLabel component="legend">服務</FormLabel>
						<FormGroup>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.SvcListRead} label="列表-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.SvcRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.SvcAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.SvcUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.SvcStateAccountantWrite}
								label="會計確認更新"
							/>
						</FormGroup>

						<FormLabel component="legend">庫存</FormLabel>
						<FormGroup>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.WarehouseWrite} label="倉庫管理" />
						</FormGroup>

						<FormLabel component="legend">庫存調撥</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.StockTransferListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.StockTransferRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.StockTransferAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.StockTransferUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.StockTransferStateWrite}
								label="狀態更新"
							/>
						</FormGroup>

						<FormLabel component="legend">瑕疵品</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.DefectiveProductListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.DefectiveProductRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.DefectiveProductAdd} label="新增" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.DefectiveProductUpdate}
								label="更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.DefectiveProductStateWrite}
								label="狀態更新"
							/>
						</FormGroup>

						<FormLabel component="legend">進貨單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ImportGoodsListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ImportGoodsRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ImportGoodsAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ImportGoodsUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ImportGoodsStateWrite}
								label="狀態更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ImportGoodsStateAccountantWrite}
								label="會計更新"
							/>
						</FormGroup>

						<FormLabel component="legend">出貨單</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ExportGoodsListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ExportGoodsRead}
								label="資料-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ExportGoodsAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.ExportGoodsUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.ExportGoodsStateWrite}
								label="狀態更新"
							/>
						</FormGroup>

						<FormLabel component="legend">傳票</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.VoucherListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.VoucherRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.VoucherAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.VoucherUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.BalanceSheetPdfDownload}
								label="資產負債表下載"
							/>
						</FormGroup>

						<FormLabel component="legend">零用金</FormLabel>
						<FormGroup>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.AllowanceListRead}
								label="列表-瀏覽"
							/>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.AllowanceRead} label="資料-瀏覽" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.AllowanceAdd} label="新增" />
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.AllowanceUpdate} label="更新" />
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.AllowanceStateManagerWrite}
								label="主管狀態更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.AllowanceStateAccountantWrite}
								label="會計狀態更新"
							/>
							<PermissionCheckboxField
								branch_id={branch_id}
								permission={Permission.AllowanceStateRequesterWrite}
								label="請款人簽收"
							/>
						</FormGroup>

						<FormLabel component="legend">業績表</FormLabel>
						<FormGroup>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.PerformanceRead} label="瀏覽" />
						</FormGroup>

						<FormLabel component="legend">薪資</FormLabel>
						<FormGroup>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.SalaryRead} label="瀏覽" />
						</FormGroup>

						<FormLabel component="legend">科目管理</FormLabel>
						<FormGroup>
							<PermissionCheckboxField branch_id={branch_id} permission={Permission.AccountSubjectWrite} label="瀏覽" />
						</FormGroup>
					</>
				)}
				<FormHelperText>{error}</FormHelperText>
			</FormControl>
		</>
	);
}
