import { Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { ForeignManufacturer } from "./@types/foreign-manufacturer";

export function DEFAULT_ONE(): ForeignManufacturer {
	const now = getNowLocaleString();
	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		type_id: "",
		name: "",
		fax: "",
		email: "",
		country: "",
		currency: "",
		tels: [],
		accounts: [],
		address: "",
		website: "",
		note: "",
	};
}
