import { manufacturer } from "../moo-enums";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { Query } from "./@types";
import { useCells } from "./hooks/useCells";
import { useConditions } from "./hooks/useConditions";
import { useHeaders } from "./hooks/useHeaders";

export type MooManufacturerLogisticsListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

const resource = "manufacturer_logistics";

export const MooManufacturerLogisticsList = ({ onAdd, onRowClick, store }: MooManufacturerLogisticsListProps) => {
	const { query, setQuery, clear } = store();
	const cells = useCells(resource);
	const headers = useHeaders();
	const permissions = usePermissions();
	const conditions = useConditions(query);

	if (!permissions.includes(Permission.ManufacturerForeignListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooList
			showAddButton={permissions.includes(Permission.ManufacturerLogisticsAdd)}
			resource={resource}
			conditions={conditions}
			graphQLColumns={`{
				id
				number
				fax
				email
				type
				name
				contact
				tel
			}`}
			addLabel="新增廠商"
			onAdd={onAdd}
			filterNode={
				<>
					<MooTypeableSelect
						label="類型"
						error=""
						id="manufacturer_type"
						options={[
							{
								value: null,
								label: "請選擇",
							},
							{
								value: manufacturer.Value.Value.Domestic,
								label: manufacturer.Label.Label.Domestic,
							},
							{
								value: manufacturer.Value.Value.Foreign,
								label: manufacturer.Label.Label.Foreign,
							},
						]}
						value={query.type}
						onChange={(type) => setQuery({ type, page: 0 })}
					/>
				</>
			}
			headers={headers}
			cells={cells}
			clear={clear}
			query={query}
			setQuery={setQuery}
			onRowClick={onRowClick}
		/>
	);
};
