import { object, SchemaOf } from "yup";
import { allowance } from "../../moo-enums";
import { enumRequired, numberRequired, statusSchema, stringOptional, stringRequired } from "../../moo-schemas";
import { One } from "../@types/one";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: statusSchema,
	state_accountant_id: stringOptional,
	state_accountant: enumRequired(allowance.StateAccountant.Value),
	state_manager: enumRequired(allowance.StateManager.Value),
	state_requester: enumRequired(allowance.StateRequester.Value),
	request_date: stringRequired("request_date"),
	requester_id: stringRequired("requester_id"),
	reason: stringOptional,
	amount: numberRequired("amount"),
	order_id: stringOptional,
});
