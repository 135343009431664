import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class DefectiveProductTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "defective_products";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "state":
			case "found_date":
			case "owner_id":
			case "found_warehouse_id":
			case "handle_method":
			case "note":
				return true;
		}
		return false;
	}
}
