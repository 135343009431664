import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";
import { DEFAULT_ONE, MooVoucherOne, One } from "../moo-voucher-one";

export type MooVoucherAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooVoucherAdd: FC<MooVoucherAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>(
		"vouchers",
		onSuccess,
		(e) => {
			return produce(e, (draft) => {
				const date = draft.voucher_date;
				draft.voucher_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` as unknown as Date;

				draft.rows.forEach((row) => {
					// @ts-ignore
					delete row.subject_id_number;
				});
			});
		},
		true,
	);
	const permissions = usePermissions();

	return (
		<MooVoucherOne
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.VoucherAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
