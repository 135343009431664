import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    
    type
    name
    contact
    tel
    fax
    email
    note
    drivers {
      manufacturer_logistic_driver_id
      line
      tel
      name
    }
    accounts {
      manufacturer_logistic_account_id
      name
      bank
      bank_account
      tax_id
    }
`;

export const query = gql`
    query ($id: uuid!) {
        manufacturer_logistics_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
