import { Item } from "../../moo-purchase-order-one";
import { Calculator } from "../../moo-quotation-one";
import { RowItem } from "./RowItem";

export function mapperItems(items: RowItem[]): Item[] {
	return items.map(({ id, purchase_order_item_id, count, priority, item_spec, item_custom }) => {
		const item = item_spec || item_custom;
		const purchase_price = item.purchase_price;

		return {
			purchase_order_item_id,
			id,
			manufacturer_type: item.manufacturer.type,
			manufacturer_id: item.manufacturer.id,
			manufacturer: item.manufacturer.name,
			name: item.name,
			material: item.material,
			color: item.color,
			size: item.size,
			count,
			image: item.image,
			image_cut: item.image_cut,
			priority,
			purchase_price: item.purchase_price,
			purchase_price_total: Calculator.getItemPurchasePriceTotal({ purchase_price, count }),
		};
	});
}
