export enum Value {
	NotYetShipped = "NOT_YET_SHIPPED",
	Delivering = "DELIVERING",
	Delivered = "DELIVERED",
}

export enum Label {
	NotYetShipped = "尚未出貨",
	Delivering = "出貨中",
	Delivered = "已到達",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.NotYetShipped:
			return Label.NotYetShipped;
		case Value.Delivering:
			return Label.Delivering;
		case Value.Delivered:
			return Label.Delivered;
	}
	return Label.Delivering;
}
