import { ItemType } from "../../moo-enums";
import { OrderBy, Query } from "../@types";

export const DEFAULT_QUERY: Query = {
	type: ItemType.Spec,
	owner_id: null,
	search: null,
	category: null,
	sub_category: null,
	material: null,
	color: null,
	page: 0,
	manufacturer_type: null,
	manufacturer_id: null,
	order_by: [OrderBy.ID_DESC],
};
