export const graphQLColumns = `{
	id
	return_date
	state_accountant
	note
	order_id
	order {
		sales {
			name		
		}
		customer {
			name		
		}
	}
}`;
