import { ItemCategory } from "../../moo-item";
import useStore from "../stores/useStore";

export function ItemCategorySelect() {
	const { query, setQuery } = useStore();
	const { category, sub_category } = query;

	return (
		<>
			<ItemCategory
				parentId={null}
				label="商品主類"
				value={query.category}
				error=""
				onChange={(category) => {
					setQuery({ category, sub_category: null, page: 0 });
				}}
			/>
			{category !== null && (
				<ItemCategory
					parentId={category}
					label="商品次類"
					value={sub_category}
					error=""
					onChange={(sub_category) => {
						setQuery({ sub_category, page: 0 });
					}}
				/>
			)}
		</>
	);
}
