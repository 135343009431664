import type { CellType } from "@yachelee/koden-components/koden-table";
import { quotation } from "../moo-enums";
import { useOnLinkClick } from "../moo-link-provider";
import { ClickableColumn, DateFormat, DollarNumber } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	const onLinkClick = useOnLinkClick();
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "type_name",
			align: "left",
			component: ({ type_name }: Row) => {
				return <div>{type_name}</div>;
			},
		},
		{
			id: "customer_name",
			align: "left",
			component: ({ customer_name }: Row) => {
				return <div>{customer_name}</div>;
			},
		},
		{
			id: "sales_name",
			align: "left",
			component: ({ sales_name }: Row) => {
				return <div>{sales_name}</div>;
			},
		},
		{
			id: "quotation_date",
			align: "left",
			component: ({ quotation_date }: Row) => {
				return <DateFormat>{quotation_date}</DateFormat>;
			},
		},
		{
			id: "state",
			align: "left",
			component: ({ state }: Row) => {
				return <div>{quotation.State.getLabel(state)}</div>;
			},
		},
		{
			id: "state_manager",
			align: "left",
			component: ({ state_manager }: Row) => {
				return <div>{quotation.StateManager.getLabel(state_manager)}</div>;
			},
		},
		{
			id: "state_accountant",
			align: "left",
			component: ({ state_accountant }: Row) => {
				return <div>{quotation.StateAccountant.getLabel(state_accountant)}</div>;
			},
		},
		{
			id: "price_total",
			align: "left",
			component: ({ price_total }: Row) => {
				return <DollarNumber>{price_total}</DollarNumber>;
			},
		},
		{
			id: "order_ids",
			align: "left",
			component: ({ order_ids }: Row) => {
				return (
					<>
						{order_ids.map((order_id) => {
							return (
								<ClickableColumn key={order_id} onClick={() => onLinkClick(order_id)}>
									{order_id}
								</ClickableColumn>
							);
						})}
					</>
				);
			},
		},
	];
}
