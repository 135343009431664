import { importGoods } from "../moo-enums";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

export type MooImportGoodsStateAccountantSelectProps = {
	value: importGoods.StateAccountant.Value;
	onChange: (value: importGoods.StateAccountant.Value) => void;
	label?: string;
	error?: string;
};

export function MooImportGoodsStateAccountantSelect({
	value,
	label = "",
	error = "",
	onChange,
}: MooImportGoodsStateAccountantSelectProps) {
	return (
		<div
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<MooTypeableSelect
				id="state_accountant"
				label={label}
				value={value}
				error={error}
				options={[
					{ value: null, label: "請選擇" },
					{
						label: importGoods.StateAccountant.Label.UnSettled,
						value: importGoods.StateAccountant.Value.UnSettled,
					},
					{
						label: importGoods.StateAccountant.Label.Settled,
						value: importGoods.StateAccountant.Value.Settled,
					},
				]}
				onChange={(value) => {
					onChange(value as importGoods.StateAccountant.Value);
				}}
			/>
		</div>
	);
}
