import produce from "immer";
import { FC } from "react";
import { DEFAULT_ONE, MooBlogOne, One } from "../moo-blog-one";
import { useHasuraAdd } from "../moo-shared";

export type MooBlogAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooBlogAdd: FC<MooBlogAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>(
		"blogs",
		onSuccess,
		(e) =>
			produce(e, (draft) => {
				// @ts-ignore
				delete draft.schedule_start_date;
				// @ts-ignore
				delete draft.schedule_end_date;
			}),
		true,
	);

	return (
		<MooBlogOne
			isCancelDisabled={false}
			isOkDisabled={false}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
