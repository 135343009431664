import { gql } from "@apollo/client";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { CircularProgress } from "@mui/material";
import produce from "immer";
import { FC, ReactElement } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useQueryClient } from "react-query";
import { useBranch } from "../../moo-branch-provider";
import { getGraphQLQueryKey, useGraphQLQuery } from "../../moo-graphql-provider";
import { useError, useSuccess } from "../../moo-notification-provider";
import ColumnType from "./@types/columnTypes";
import { OptionResponseType } from "./@types/optionTypes";
import Panel from "./components/Panel";
import PanelListRow from "./components/PanelListRow";
import { useUpdatePriorities } from "./hooks/useUpdatePriorities";

export type PanelListProps = {
	title: string;
	resource: string;
	columns: ColumnType[];
	headerBackgroundColor: string;
	columnPlaceHolder?: (data: OptionResponseType["id"]) => ReactElement;
	filter: Record<string, any>;
};

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

function useGraphQLInfo(resource: string, columns: PanelListProps["columns"], filter: Record<string, any>) {
	const { value: branch } = useBranch();

	const graphQLColumns: string = columns
		// @ts-ignore
		.filter((e) => e?.type !== "ComponentType")
		.map((e) => e.id)
		.join("\n");

	const query = gql`
      query($where: ${resource}_bool_exp) {
				${resource}(where: $where, order_by: [{priority: asc}]){
					id
      		${graphQLColumns}
					priority
				}
      }
	`;

	const variables = {
		where: {
			status: {
				_eq: "PRESENT",
			},
			branch_id: {
				_eq: branch,
			},
		},
	};

	// @ts-ignore
	variables.where = produce(variables.where, (where) => {
		where = { ...where, ...filter };
		return where;
	});

	//parent is some id

	const operationName = `panel-list-${resource}`;

	return { query, variables, operationName };
}

export function useGraphQLQueryList(resource: string, columns: PanelListProps["columns"], filter: Record<string, any>) {
	return useGraphQLQuery<Record<string, any>>(useGraphQLInfo(resource, columns, filter));
}

export const PanelList: FC<PanelListProps> = ({
	title,
	resource,
	headerBackgroundColor,
	columns,
	columnPlaceHolder,
	filter,
}) => {
	const error = useError();
	const success = useSuccess();
	const queryClient = useQueryClient();
	const updatePriorities = useUpdatePriorities(resource);
	const { operationName, query, variables } = useGraphQLInfo(resource, columns, filter);
	const { isLoading, data: returnData } = useGraphQLQueryList(resource, columns, filter);

	const queryKey = getGraphQLQueryKey({ operationName, query, variables });

	if (isLoading) {
		return <CircularProgress color="info" />;
	}
	if (!returnData) {
		return <div>沒有這筆資料</div>;
	}

	const data = returnData[resource] as null | OptionResponseType[];
	if (data === null) {
		return <div>沒有這筆資料</div>;
	}

	return (
		<Panel headerBackgroundColor={headerBackgroundColor} title={<>{title}</>}>
			<DragDropContext
				onDragEnd={async ({ source, destination }) => {
					if (destination) {
						const sourceRecord = data![source.index];
						const destinationRecord = data![destination.index];
						if (sourceRecord.id !== destinationRecord.id) {
							const reorderedData = reorder(data as any[], source.index, destination.index);

							queryClient.setQueryData(queryKey, () => {
								return {
									[resource]: reorderedData,
								};
							});

							try {
								await updatePriorities(
									reorderedData.map(({ id }, priority) => {
										return { id, priority };
									}),
								);
								success("順序更新成功！");
							} catch (e) {
								error("順序更新失敗");
							}
						}
					}
				}}
			>
				<Droppable droppableId="droppable">
					{(droppableProvided) => (
						<div ref={droppableProvided.innerRef}>
							{data.map((option, index) => {
								const key = option.id;
								const draggableId = `id_${option.id}`;

								return (
									// @ts-ignore
									<Draggable key={key} draggableId={draggableId} index={index}>
										{(draggableProvided) => (
											<div
												ref={draggableProvided.innerRef}
												{...draggableProvided.draggableProps}
												{...draggableProvided.dragHandleProps}
											>
												<div style={{ display: "flex" }}>
													<PanelListRow
														key={option.id}
														resource={resource}
														columns={columns}
														option={option}
														columnPlaceHolder={columnPlaceHolder}
														filter={filter}
													/>
													<div style={{ display: "flex", alignItems: "center" }}>
														<DragHandleIcon />
													</div>
												</div>
											</div>
										)}
									</Draggable>
								);
							})}
							{droppableProvided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Panel>
	);
};
