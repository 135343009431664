import { One } from "..";
import { svc } from "../../moo-enums";
import { Permission, usePermissions } from "../../moo-me-provider";
import { useGetFieldError } from "../../moo-shared";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import FieldContainer from "../components/FieldContainer";
import { useForm } from "../hooks/useForm";

type ItemOption = {
	id: svc.StateAccountant.Value;
	label: svc.StateAccountant.Label;
};

type Item = {
	id: keyof One;
	label: string;
	options: ItemOption[];
	permission: Permission;
};

const arr: Item[] = [
	{
		id: "state_accountant",
		label: "會計確認",
		permission: Permission.SvcStateAccountantWrite,
		options: [
			{
				id: svc.StateAccountant.Value.UnSettled,
				label: svc.StateAccountant.Label.UnSettled,
			},
			{
				id: svc.StateAccountant.Value.Settled,
				label: svc.StateAccountant.Label.Settled,
			},
		],
	},
];

export function StateBlock() {
	const { values: one, setFieldValue: updateOne } = useForm();
	const permissions = usePermissions();
	const getFieldError = useGetFieldError<One>();

	return (
		<FieldContainer>
			{arr.map(({ id, label, options }) => {
				let error = "";
				switch (id) {
					case "state_accountant":
						error = getFieldError(id);
						break;
				}
				return (
					<MooTypeableSelect
						key={id}
						id={id}
						label={label}
						disabled={!permissions.includes(Permission.SvcStateAccountantWrite)}
						value={one[id] as string}
						error={error}
						options={options.map((e) => {
							return { ...e, value: e.id };
						})}
						onChange={(value) => {
							updateOne(id, value);
						}}
					/>
				);
			})}
		</FieldContainer>
	);
}
