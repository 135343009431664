import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import produce from "immer";
import { Branch } from "../moo-branch-provider";
import { Permission } from "../moo-me-provider";
import { uuidv4 } from "../moo-shared";
import { useForm } from "./useForm";

type Props = {
	branch_id: Branch;
	all: Permission[];
};

export function CheckAll({ branch_id, all }: Props) {
	const { values: one, setFieldValue } = useForm();
	const oneRolePermissionSet = new Set(
		one.permissions.filter((e) => e.branch_id === branch_id).map((e) => e.permission),
	);

	return (
		<>
			<FormLabel component="legend">全選</FormLabel>
			<FormControlLabel
				label="全選"
				control={
					<Checkbox
						checked={all.every((e) => oneRolePermissionSet.has(e))}
						onChange={({ target: { checked } }) => {
							setFieldValue(
								"permissions",
								produce(one.permissions, (draft) => {
									const set: Set<Permission> = new Set(
										draft.filter((e) => e.branch_id === branch_id).map((e) => e.permission),
									);

									if (checked) {
										all
											.filter((e) => !set.has(e))
											.forEach((permission) => {
												draft.push({
													role_permission_id: uuidv4(),
													branch_id,
													permission,
												});
											});
									} else {
										return draft.filter((e) => e.branch_id !== branch_id);
									}
								}),
							);
						}}
					/>
				}
			/>
		</>
	);
}
