import { gql } from "@apollo/client";
import { MooGqlBuilder, One } from "../moo-gql";
import { useGraphQLRequest } from "../moo-graphql-provider";

type OnSubmit<T> = (initialValues: One, toUpdateOne: One) => Promise<{ affected_rows: number; returning: T }>;

export function useOnSubmit<T>(id: string, tableName: string, tables: any[]): OnSubmit<T> {
	const graphQLRequest = useGraphQLRequest();

	return async (initialValues, toUpdateOne) => {
		const builder = new MooGqlBuilder({ id, initialValues, toUpdateOne });
		tables.forEach((table) => builder.addTable(table));

		const { query, variables } = builder.build();

		return (
			await graphQLRequest({
				query: gql`
					${query}
				`,
				variables,
			})
		)[`update_${tableName}`];
	};
}
