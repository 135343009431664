import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "商品編號",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "owner_id",
			label: "負責人",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "image",
			label: "商品圖片",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "manufacturer_type",
			label: "廠商類型",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "manufacturer",
			label: "廠商",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "category",
			label: "商品種類",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "name",
			label: "商品名",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
		{
			id: "country",
			label: "國家",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
		{
			id: "material",
			label: "材質",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "size",
			label: "尺寸",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "color",
			label: "顏色",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "purchase_price",
			label: "進貨成本",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "price",
			label: "建議售價",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "delete",
			label: "刪除",
			align: "center",
		},
		{
			id: "clone",
			label: "複製",
			align: "center",
			style: {
				minWidth: 90,
			},
		},
	];
}
