import { Calculator } from "../../moo-quotation-one";
import { One } from "../../moo-return-order-one";
import { getLocaleString } from "../../moo-shared";
import { ReturnOrder } from "./ReturnOrder";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: ReturnOrder): One {
	const items = mapperItems(record.items);

	return {
		status: record.status,
		state_accountant: record.state_accountant,
		return_date: record.return_date,
		order_id: record.order_id,
		order_type_name: record.order.quotation_type.name,
		order_customer_name: record.order.customer.name,
		order_sales_name: record.order.sales.name,
		order_sales_tel: record.order.sales.tel,
		order_deliver_date: record.order.deliver_date,
		order_is_include_tax: record.order.is_include_tax,
		order_payee_name: record.order.payee.name,
		order_payee_bank_name: record.order.payee.bank_name,
		order_payee_account_number: record.order.payee.account_number,
		order_deliver_address: record.order.deliver_address,
		order_description: record.order.description,
		order_order_note: record.order.order_note,
		note: record.note,
		total_price: Calculator.getPriceTotal(record.items),
		tax: record.tax,
		note_price: record.note_price,
		items,
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
	};
}
