export const graphQLColumns = `{
				id
				import_date
				link_id
				manufacturer{
					name
				}
				sales {
					id
					name
					tel
				}
				state
				state_accountant
				warehouse {
					id
					name
				}
			}`;
