import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { Formik } from "formik";
import * as _ from "lodash";
import styled from "styled-components";
import { useAltOneEvent } from "../../../hooks/useAltOneEvent";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { DollarNumber } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { Props } from "./@types/props";
import type { Props as VoucherRowProps } from "./VoucherRow";
import VoucherRow from "./VoucherRow";
import { useForm } from "./hooks/useForm";
import { useGetError } from "./hooks/useGetError";
import { useIsBalanced } from "./hooks/useIsBalanced";
import { useUpdate } from "./hooks/useUpdate";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const addVoucherBtnId = "add_voucher_btn";

const Container = styled.form`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

export const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	max-width: ${WEB_BREAKPOINT};
	margin-bottom: 30px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const TotalNumberInfo = styled.div`
	display: grid;
	grid-gap: 0.2rem;
	justify-content: center;
	font-weight: bold;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-gap: 1rem;
		grid-auto-flow: column;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		justify-content: end;
		justify-items: start;
	}
`;

const EmptyMessage = (
	<div
		style={{
			display: "flex",
			justifyContent: "center",
			color: "#C4C4C4",
		}}
	>
		尚無傳票
	</div>
);

function Form({
	isLoading = false,
	isCancelDisabled,
	isOkDisabled,
	onCancel,
	isEdit,
}: Omit<Props, "initialValues" | "onSubmit">) {
	const { errors, values, setFieldValue, handleSubmit } = useForm();
	const { addVoucherRow } = useUpdate();
	const isBalanced = useIsBalanced();
	const getError = useGetError();
	useAltOneEvent(() => {
		window.document.getElementById(addVoucherBtnId)?.click();
	});

	return (
		<Container onSubmit={handleSubmit}>
			<FieldContainer>
				<KodenDateField
					id="voucher_date"
					label="傳票日期"
					disabled={isLoading || isEdit}
					error={getError("voucher_date")}
					value={values.voucher_date}
					onChange={(value) => setFieldValue("voucher_date", value)}
				/>
			</FieldContainer>
			<div style={{ marginBottom: "2rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>傳票列表</h1>
					<div id={addVoucherBtnId} onClick={addVoucherRow}>
						<KodenIconTextButton text="新增" onClick={() => {}} />
					</div>
				</header>
			</div>
			<div>
				<div
					style={{
						display: "grid",
						gridGap: "1rem",
						margin: "1.5rem 0",
					}}
				>
					{values.rows && values.rows.length > 0
						? values.rows
								.slice()
								.sort((a, b) => a.order - b.order)
								.map((e, index) => {
									let error: VoucherRowProps["error"] = {
										voucher_row_id: "",
										account_type_id: "",
										subject_id_number: "",
										type: "",
										link_id: "",
										summary: "",
										debit_amount: "",
										credit_amount: "",
										manufacturer_type: "",
										manufacturer_id: "",
										order: "",
									};

									if (_.isArray(errors.rows)) {
										const yupError = errors.rows[index] as Partial<VoucherRowProps["error"]>;
										error = {
											...error,
											...yupError,
										};
									}

									return (
										<div key={e.voucher_row_id}>
											<VoucherRow {...e} index={index} error={error} />
										</div>
									);
								})
						: EmptyMessage}
				</div>
				{values.rows && values.rows.length > 0 && (
					<TotalNumberInfo>
						<div>
							合計借方金額：
							<DollarNumber>{values.debit_total}</DollarNumber>
						</div>
						<div>
							合計貸方金額：
							<DollarNumber>{values.credit_total}</DollarNumber>
						</div>
					</TotalNumberInfo>
				)}
			</div>
			{!isOkDisabled && (
				<ButtonContainer>
					<KodenButton
						style={{ marginRight: "1rem" }}
						disabled={isCancelDisabled}
						onClick={async () => {
							if (window.confirm("確定取消並且回到上一頁？")) {
								onCancel();
							}
						}}
					>
						取消
					</KodenButton>
					<MooSubmitButton disabled={!isBalanced} permission={Permission.VoucherUpdate} />
				</ButtonContainer>
			)}
		</Container>
	);
}

export function MooVoucherOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.VoucherRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
