import { useContext } from "react";
import { useListGraphQLVariables, useMutateGraphQLField } from "../moo-shared";
import { QueryContext } from "./QueryContext";
import { operationName, rowsPerPage } from "./moo-order-list";
import { useConditions } from "./useConditions";
import { useGraphQLQuery } from "./useGraphQLQuery";

export function useUpdateAccountantState() {
	const { query } = useContext(QueryContext);
	const conditions = useConditions();
	const variables = useListGraphQLVariables(rowsPerPage, query.page, conditions);
	return useMutateGraphQLField("orders", operationName, useGraphQLQuery(), variables, "state_accountant");
}
