import { useBranch } from "../../moo-branch-provider";
import { manufacturer, Status } from "../../moo-enums";
import { ItemType as ItemManufacturerSelectItemType } from "../../moo-item-manufacturer-select";
import { MooStyledManufacturerSelect } from "../../moo-styled-manufacturer-select/MooStyledManufacturerSelect";
import { Query } from "../@types";

type Props = {
	query: Query;
	setQuery: (query: Partial<Query>) => void;
};

export function ManufacturerIdSelect({ query, setQuery }: Props) {
	const { value: branch } = useBranch();
	if (query.manufacturer_type === null) return null;

	return (
		<MooStyledManufacturerSelect
			itemType={"voucher_rows" as ItemManufacturerSelectItemType}
			manufacturerType={query.manufacturer_type as manufacturer.Value.Value | null}
			where={{
				voucher: {
					status: { _eq: Status.Present },
					branch_id: { _eq: branch },
				},
			}}
			value={query.manufacturer_id}
			onChange={(manufacturer_id) => setQuery({ manufacturer_id, page: 0 })}
		/>
	);
}
