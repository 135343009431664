import { ItemType } from "../../moo-enums";
import useStore from "../stores/useStore";

export function useGraphQLResource(): string {
	const {
		query: { type },
	} = useStore();
	switch (type) {
		case ItemType.Spec:
			return "item_specs";
		case ItemType.Custom:
			return "item_customs";
		case ItemType.Bundle:
			return "item_bundles";
	}
}
