import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { KodenYearField } from "@yachelee/koden-components/koden-year-field";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { MooEmployeeSelectField } from "../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { manufacturer } from "../moo-enums";
import { MooImageListCrud } from "../moo-image-list-crud";
import { ItemCategory, ManufacturerTypeField, useIsSubCategoryHide } from "../moo-item";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { useGetFieldError } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { One } from "./@types/one";
import { useValidationSchema } from "./useValidationSchema";

type BaseProps = {
	isEdit?: boolean;
	isCancelDisabled: boolean;
	isOkDisabled: boolean;
	onCancel: () => void;
	onSubmit: (one: One, helpers: FormikHelpers<One>) => void;
	initialValues: One;
	okText: string;
};

type Edit = BaseProps & {
	isEdit: true;
	id: string;
};

type Add = BaseProps & {
	isEdit: false;
};

export type Props = Edit | Add;

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 2rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const HighlightFieldOuterContainer = styled.div`
	padding: 1rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const HighlightFieldInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};
	background: #f2f3f5;
	border-radius: 0.2rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.5rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const Name = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column: 1 / -1;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column: 1 / span 2;
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

export function Form({ isEdit, isCancelDisabled, onCancel, ...e }: Partial<Props>) {
	const permissions = usePermissions();
	const { handleSubmit, values, setFieldValue, setValues } = useFormikContext<One>();
	const isSubCategoryHide = useIsSubCategoryHide(values.category_id);
	const getFieldError = useGetFieldError<One>();

	return (
		<form onSubmit={handleSubmit}>
			<FieldContainer>
				<ManufacturerTypeField
					value={values.manufacturer_type}
					error={getFieldError("manufacturer_type")}
					onChange={(value) => {
						if (value) {
							setValues({
								...values,
								manufacturer_type: value,
								manufacturer_id: null,
							});
						}
					}}
				/>
				<MooEnumTypeableSelectField
					id="manufacturer"
					resource={
						values.manufacturer_type === manufacturer.Value.Value.Domestic
							? "manufacturer_domestics"
							: "manufacturer_foreigns"
					}
					label="廠商"
					value={values.manufacturer_id}
					error={getFieldError("manufacturer_id")}
					onChange={(value) => {
						setFieldValue("manufacturer_id", value);
					}}
				/>
				<ItemCategory
					parentId={null}
					label="商品主類"
					value={values.category_id}
					error={getFieldError("category_id")}
					onChange={(value) => {
						setValues({ ...values, category_id: value, sub_category_id: null });
					}}
				/>
				{!isSubCategoryHide && (
					<ItemCategory
						parentId={values.category_id}
						label="商品次類"
						value={values.sub_category_id}
						error={getFieldError("sub_category_id")}
						onChange={(sub_category_id) => setFieldValue("sub_category_id", sub_category_id)}
					/>
				)}
				<KodenYearField
					id="buy_year"
					label="進貨年份"
					value={values.buy_year}
					error={getFieldError("buy_year")}
					onChange={(buy_year) => setFieldValue("buy_year", buy_year)}
				/>
				<Name
					id="name"
					label="商品名稱"
					outlined
					margin="none"
					value={values.name}
					error={getFieldError("name")}
					onChange={(name) => setFieldValue("name", name)}
				/>
				<TextFieldEnd
					id="material"
					label="材質"
					outlined
					margin="none"
					value={values.material}
					error={getFieldError("material")}
					onChange={(material) => setFieldValue("material", material)}
				/>
				<ProxyKodenTextField
					id="color"
					label="顏色"
					outlined
					margin="none"
					value={values.color}
					error={getFieldError("color")}
					onChange={(value) => setFieldValue("color", value)}
				/>
				<ProxyKodenTextField
					id="size"
					label="尺寸"
					outlined
					margin="none"
					value={values.size}
					error={getFieldError("size")}
					onChange={(value) => setFieldValue("size", value)}
				/>
				<ProxyKodenTextField
					id="country"
					label="國家"
					outlined
					margin="none"
					value={values.country}
					error={getFieldError("country")}
					onChange={(value) => setFieldValue("country", value)}
				/>
				<ProxyKodenTextField
					id="original_code"
					label="原廠編碼"
					outlined
					margin="none"
					value={values.original_code}
					error={getFieldError("original_code")}
					onChange={(value) => setFieldValue("original_code", value)}
				/>
				<MooEmployeeSelectField
					id="owner_id"
					value={values.owner_id}
					label="負責人"
					error={getFieldError("owner_id")}
					onChange={(value) => setFieldValue("owner_id", value)}
				/>
				<KodenDateField
					id="sell_deadline"
					label="售出期限"
					error={getFieldError("sell_deadline")}
					value={values.sell_deadline === null ? null : new Date(values.sell_deadline)}
					onChange={(date) =>
						setFieldValue(
							"sell_deadline",
							date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
						)
					}
				/>
				<KodenMultilineField
					id="note"
					label="備註"
					style={{ gridColumn: "1 / -1" }}
					outlined
					margin="none"
					rows={2}
					value={values.note}
					error={getFieldError("note")}
					onChange={(value) => setFieldValue("note", value)}
				/>
				{isEdit && (
					<div style={{ gridColumn: "1 / -1" }}>
						<MooImageListCrud parentId={(e as Edit).id} resource="item_custom_images" />
					</div>
				)}
			</FieldContainer>
			<HighlightFieldOuterContainer>
				<HighlightFieldInnerContainer>
					{permissions.includes(Permission.ItemCustomPurchasePriceRead) && (
						<KodenNumberField
							id="purchase_price"
							label="進貨成本"
							outlined
							margin="none"
							hasDollar
							value={values.purchase_price || 0}
							error={getFieldError("purchase_price")}
							step={1}
							min={0}
							max={2147483647}
							readOnly={
								(isEdit && !permissions.includes(Permission.ItemCustomPurchasePriceUpdate)) ||
								(!isEdit && !permissions.includes(Permission.ItemCustomPurchasePriceAdd))
							}
							onChange={(value) => setFieldValue("purchase_price", value)}
						/>
					)}
					{permissions.includes(Permission.ItemCustomCostRead) && (
						<KodenNumberField
							id="cost"
							label="總成本"
							outlined
							margin="none"
							hasDollar
							value={values.cost}
							error={getFieldError("cost")}
							step={1}
							min={0}
							max={2147483647}
							readOnly={
								(isEdit && !permissions.includes(Permission.ItemCustomCostUpdate)) ||
								(!isEdit && !permissions.includes(Permission.ItemCustomCostAdd))
							}
							onChange={(value) => setFieldValue("cost", value)}
						/>
					)}
					{permissions.includes(Permission.ItemCustomPriceRead) && (
						<KodenNumberField
							id="price"
							label="建議售價"
							outlined
							margin="none"
							hasDollar
							value={values.price}
							error={getFieldError("price")}
							step={1}
							min={0}
							max={2147483647}
							readOnly={
								(isEdit && !permissions.includes(Permission.ItemCustomPriceUpdate)) ||
								(!isEdit && !permissions.includes(Permission.ItemCustomPriceAdd))
							}
							onChange={(value) => setFieldValue("price", value)}
						/>
					)}
				</HighlightFieldInnerContainer>
			</HighlightFieldOuterContainer>
			<ButtonContainer>
				<KodenButton
					style={{ marginRight: "1rem" }}
					disabled={isCancelDisabled}
					onClick={async () => {
						if (window.confirm("確定取消並且回到上一頁？")) {
							if (onCancel) {
								onCancel();
							}
						}
					}}
				>
					取消
				</KodenButton>
				<MooSubmitButton permission={Permission.ItemCustomUpdate} />
			</ButtonContainer>
		</form>
	);
}

export function MooItemCustomOne({ initialValues, onSubmit, ...props }: Props) {
	const validationSchema = useValidationSchema();
	const permissions = usePermissions();

	if (!permissions.includes(Permission.ItemCustomRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
