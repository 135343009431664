export enum Value {
	WaitToEnter = "WAIT_TO_ENTER",
	Entered = "ENTERED",
}

export enum Label {
	WaitToEnter = "未入倉",
	Entered = "已入倉",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.WaitToEnter:
			return Label.WaitToEnter;
		case Value.Entered:
			return Label.Entered;
	}
	return Label.WaitToEnter;
}
