import { quotation, Status } from "../../moo-enums";
import { Calculator, One } from "../../moo-quotation-one";
import { getLocaleString } from "../../moo-shared";
import { Quotation } from "./Quotation";
import { mapperItem } from "./mapperItem";

export function mapperInitialValues(record: Quotation): One {
	const items = mapperItem(record.items);
	const totalCost = Calculator.getTotalCost(items);
	const priceTotal = Calculator.getPriceTotal(items);

	return {
		status: record.status as Status,
		employee_id: record.employee_id,
		state: record.state as quotation.State.Value,
		state_manager: record.state_manager as quotation.StateManager.Value,
		state_accountant: record.state_accountant as quotation.StateAccountant.Value,
		quotation_date: getLocaleString(new Date(record.quotation_date)),
		type_id: record.type.id,
		type_name: record.type.name || "",
		customer_id: record.customer.id,
		customer_name: record.customer.name || "",
		assistant_id: record.assistant_id,
		assistant_name: record.assistant?.name || "",
		assistant_tel: record.assistant?.tel || "",
		sales_id: record.sales.id || "",
		sales_name: record.sales.name || "",
		sales_tel: record.sales.tel || "",
		deliver_date: record.deliver_date,
		tax: record.tax,
		payee_id: record.payee.id,
		payee_name: record.payee.name,
		payee_bank: record.payee.bank_name,
		payee_account_number: record.payee.account_number,
		deliver_address: record.deliver_address || "",
		items,
		price_total: priceTotal,
		price_discounted: record.price_discounted,
		note: record.note,
		deposit: record.deposit,
		cost: totalCost,
		remaining_fee: record.price_discounted - record.deposit,
		memo: record.memo,
		signed_files: record.signed_files,
		voucher_rows: record.voucher_rows,
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
	};
}
