import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NumbersIcon from "@mui/icons-material/Numbers";
import { KodenAvatar } from "@yachelee/koden-components/koden-avatar";
import styled from "styled-components";
import { useOnEmployeeIdClick } from "../moo-employee-id-provider";
import { useOnLinkClick } from "../moo-link-provider";
import { One } from "../moo-schedule-one";
import { getDateFormat, getTimeFormat } from "../moo-shared";
import { MooTags } from "../moo-tags";

export type MooScheduleCardProps = {
	start_date: One["start_date"];
	end_date: One["end_date"];
	links: One["links"];
	content: One["content"];
	create_date: Date;
	user_id: string;
	user_name: string;
	user_color: string;
	edit: boolean;
	remove: boolean;
	onEditClick?: () => void;
	onRemoveClick?: () => void;
};

const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const Card = styled.div`
	border-radius: 4px;
	border: 0.5px solid #bcbcbc;
	padding: 1.3rem;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const CreateDate = styled.div`
	font-size: 0.7rem;
	color: #c4c4c4;
`;

const DateRange = styled.div`
	font-size: 0.7rem;
	color: #707070;
`;

const ActionBtn = styled.button`
	background: transparent;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;

	& svg {
		margin-right: 0.3rem;
	}

	&:active {
		outline: none;
	}
	&:not(:last-of-type) {
		margin-right: 1rem;
	}
`;

const DeleteBtn = styled(ActionBtn)`
	color: #e66c6c;
	cursor: pointer;
`;

const EditBtn = styled(ActionBtn)`
	color: #6d87c6;
	cursor: pointer;
`;

export function MooScheduleCard({
	start_date,
	end_date,
	links,
	user_id,
	content,
	create_date,
	user_color,
	user_name,
	edit,
	remove,
	onEditClick,
	onRemoveClick,
}: MooScheduleCardProps) {
	const onEmployeeIdClick = useOnEmployeeIdClick();
	const onLinkClick = useOnLinkClick();

	return (
		<div>
			<Card>
				<Row>
					<AvatarContainer onClick={() => onEmployeeIdClick(user_id)}>
						<KodenAvatar backgroundColor={user_color} value={user_name} />
						<div style={{ marginLeft: ".5rem", fontWeight: "bold" }}>{user_name}</div>
					</AvatarContainer>
					<DateRange>
						{getDateFormat(start_date)} - {getDateFormat(end_date)}
					</DateRange>
				</Row>
				<div style={{ margin: "1.5rem 0 2rem 0" }}>
					<MooTags
						tags={links.map((e) => e.value)}
						onClick={onLinkClick}
						tagIcon={<NumbersIcon fontSize="inherit" />}
					/>
				</div>
				<div style={{ marginBottom: "2rem" }}>{content}</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flexWrap: "wrap",
						flexDirection: "row-reverse",
						alignItems: "center",
						padding: ".5rem 0",
					}}
				>
					<CreateDate>{`建立時間：${getDateFormat(create_date)} ${getTimeFormat(create_date)}`}</CreateDate>
					<div style={{ display: "flex" }}>
						{remove && (
							<DeleteBtn
								onClick={() => {
									if (onRemoveClick) {
										onRemoveClick();
									}
								}}
							>
								<DeleteIcon fontSize="small" />
								刪除
							</DeleteBtn>
						)}
						{edit && (
							<EditBtn
								onClick={() => {
									if (onEditClick) {
										onEditClick();
									}
								}}
							>
								<EditIcon fontSize="small" />
								編輯
							</EditBtn>
						)}
					</div>
				</div>
			</Card>
		</div>
	);
}

export default MooScheduleCard;
