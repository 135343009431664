import { Vip } from "./@types/customer";

export function getVipString(vip: Vip): string {
	switch (vip) {
		case Vip.KIND:
			return "陶瓷(親近會員)";
		case Vip.ACTIVE:
			return "水晶(活躍會員)";
		case Vip.SUPER:
			return "磐石(超級會員)";
		case Vip.NORMAL:
		default:
			return "鐵塔(一般會員)";
	}
}
