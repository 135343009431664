import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { Query } from "../@types";

export function useConditions({ type, search }: Query) {
	const { value: branch } = useBranch();
	const conditions: Record<string, any> = {
		status: {
			_eq: Status.Present,
		},
		branch_id: {
			_eq: branch,
		},
	};

	if (type !== null) {
		conditions.type_id = {
			_eq: type,
		};
	}

	if (search !== null && search.trim() !== "") {
		let or: Record<string, any>[] = [];
		search
			.trim()
			.split(" ")
			.forEach((keyword) => {
				const _ilike = `%${keyword}%`;
				or = or.concat([
					{
						name: {
							_ilike,
						},
					},
					{
						email: {
							_ilike,
						},
					},
					{
						address: {
							_ilike,
						},
					},
					{
						country: {
							_ilike,
						},
					},
					{
						currency: {
							_ilike,
						},
					},
					{
						website: {
							_ilike,
						},
					},
					{
						fax: {
							_ilike,
						},
					},
					{
						tels: {
							tel: {
								_ilike,
							},
						},
					},
					{
						accounts: {
							_or: [
								{
									bank_account: {
										_ilike,
									},
								},
								{
									swift_code: {
										_ilike,
									},
								},
								{
									code_iban: {
										_ilike,
									},
								},
							],
						},
					},
					{
						manufacturer_foreign_type: {
							name: {
								_ilike,
							},
						},
					},
				]);
			});
		conditions._or = or;
	}

	return conditions;
}
