import produce from "immer";
import { DEFAULT_QUERY } from "./DEFAULT_QUERY";
import { StoreSlice } from "./useStore";

export type QueryStoreSlice = {
	search: string | null;
	page: number;
	setSearch: (value: string) => void;
	setPage: (value: number) => void;
};

export const createQuerySlice: StoreSlice<QueryStoreSlice> = (set, get) => ({
	...DEFAULT_QUERY,
	reset: () => set(DEFAULT_QUERY),
	setSearch: (value) => {
		set(
			produce((draft) => {
				draft.search = value;
				draft.page = DEFAULT_QUERY.page;
			}),
		);
		get().clearChoose();
	},
	setPage: (value) =>
		set(
			produce((draft) => {
				draft.page = value;
			}),
		),
});
