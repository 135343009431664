import { gql } from "@apollo/client";

export function useListGraphQLQuery(resource: string, columns: string, aggregate_columns = "") {
	return gql`
		query query(
			$where: ${resource}_bool_exp
			$order_by: [${resource}_order_by!]
			$limit: Int
			$offset: Int
		) {
      ${resource}_aggregate(where: $where) {
				aggregate {
					count
    			${aggregate_columns}
				}
			}
    	${resource}(
				where: $where
				order_by: $order_by
				limit: $limit
				offset: $offset
			) ${columns}
		}
	`;
}
