import NumbersIcon from "@mui/icons-material/Numbers";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { Formik } from "formik";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { useLinkDialogChoose } from "../moo-dialog-link-choose";
import { useChoose as useWeeklyScheduleChoose } from "../moo-dialog-weekly-schedule-choose";
import { getDateFormat } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { MooTags } from "../moo-tags";
import { Row as ScheduleListRow } from "../moo-weekly-schedule-list";
import { Props } from "./@types/props";
import { Attaches } from "./Attaches";
import UploadButton from "./UploadButton";
import { useForm } from "./hooks/useForm";
import { useGetError } from "./hooks/useGetError";
import { useUpdate } from "./hooks/useUpdate";
import { schema } from "./schemas/schema";
import { ButtonContainer, Container } from "./styled";

const Header = styled.header`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 1rem;
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 0.5rem;
`;

const EmptyMessage = styled.div`
	color: #c4c4c4;
`;

function Form({ isCancelDisabled, isOkDisabled, onCancel }: Omit<Props, "initialValues" | "onSubmit">) {
	const { addLinks, removeLink } = useUpdate();
	const { values, setFieldValue, setValues, handleSubmit } = useForm();
	const getError = useGetError();

	const { component: linkComponent, open: linkOpen } = useLinkDialogChoose(`link-dialog-schedule`, (rows) => {
		addLinks(rows.map((e) => e.id));
	});

	const { component: scheduleComponent, open: scheduleOpen } = useWeeklyScheduleChoose(
		"useWeeklyScheduleChoose",
		(rows) => {
			if (rows.length === 0) {
				setValues({ ...values, schedule_id: null, schedule_end_date: new Date(), schedule_start_date: new Date() });
			} else {
				const row = rows[0] as ScheduleListRow;
				setValues({
					...values,
					schedule_id: row.id,
					schedule_start_date: new Date(row.start_date),
					schedule_end_date: new Date(row.end_date),
				});
			}
		},
	);

	return (
		<form onSubmit={handleSubmit}>
			{linkComponent}
			{scheduleComponent}
			<Container>
				<div style={{ marginBottom: "2rem" }}>
					<KodenDateField
						id="date"
						label="日期"
						error={getError("date")}
						value={values.date === null ? null : new Date(values.date)}
						onChange={(date) => {
							setFieldValue(
								"date",
								date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
							);
						}}
					/>
				</div>
				<div style={{ marginBottom: "2rem" }}>
					<Header>
						<h2 style={{ margin: "0" }}>週計畫</h2>
						{values.schedule_id === null && <KodenIconTextButton text="選擇週計畫" onClick={scheduleOpen} />}
					</Header>
					{values.schedule_id === null ? (
						<EmptyMessage>尚未連結週計畫</EmptyMessage>
					) : (
						<div>
							時間：{getDateFormat(values.schedule_start_date)} - {getDateFormat(values.schedule_end_date)}
						</div>
					)}
				</div>
				<div>
					<Header>
						<h2 style={{ margin: "0" }}>訂單</h2>
						<KodenIconTextButton text="連結訂單" onClick={linkOpen} />
					</Header>
					<div style={{ marginBottom: "2rem" }}>
						<MooTags
							emptyMsg="尚無連結訂單"
							tags={values.links.map((e) => e.value)}
							onClick={removeLink}
							tagIcon={<NumbersIcon fontSize="inherit" />}
						/>
					</div>
					<Header>
						<h2 style={{ margin: "0" }}>附件圖片</h2>
						<UploadButton />
					</Header>
					<div style={{ marginBottom: "3rem" }}>
						<Attaches />
					</div>
					<ProxyKodenTextField
						id="content"
						value={values.content}
						error={getError("content")}
						outlined
						label="新增紀錄"
						rows={2}
						multiline
						onChange={(content) => setFieldValue("content", content)}
					/>
				</div>
				{!isOkDisabled && (
					<ButtonContainer>
						<KodenButton
							style={{ marginRight: "1rem" }}
							disabled={isCancelDisabled}
							onClick={async () => {
								if (window.confirm("確定取消並且回到上一頁？")) {
									onCancel();
								}
							}}
						>
							取消
						</KodenButton>
						<MooSubmitButton />
					</ButtonContainer>
				)}
			</Container>
		</form>
	);
}

export function MooBlogOne({ initialValues, onSubmit, ...props }: Props) {
	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit}>
			<Form {...props} />
		</Formik>
	);
}
