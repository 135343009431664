import * as jsonPatch from "fast-json-patch";
import { convertArrayToKeyValues } from "./convertArrayToKeyValues";
import { OneBase, OperationType } from "./moo-gql-table";

export abstract class MooGqlChildNewTable<One extends OneBase> {
	public readonly id: string | null = null;
	public readonly initialValues: One = null as unknown as One;
	public readonly toUpdateOne: One = null as unknown as One;

	public readonly operations: OperationType[] = [];

	constructor({ id, initialValues, toUpdateOne }: { id: string; initialValues: One; toUpdateOne: One }) {
		this.id = id;
		this.initialValues = initialValues;
		this.toUpdateOne = toUpdateOne;
		this.operations = jsonPatch.compare(
			convertArrayToKeyValues(initialValues, this.getPathName(), this.getIdName()),
			convertArrayToKeyValues(toUpdateOne, this.getPathName(), this.getIdName()),
		) as OperationType[];
	}

	abstract getIdName(): string;

	abstract getTableName(): string;

	abstract getPathName(): string;
	abstract getToUpdateColumns(): string[];
	abstract getParentIdName(): string;

	getAddOperations(): OperationType[] {
		return this.operations.filter(({ path, op }) => {
			const arr = path.split("/").slice(1);
			return arr.length === 2 && arr[0] === this.getPathName() && op === "add";
		});
	}

	getRemoveOperations(): OperationType[] {
		return this.operations.filter(({ path, op }) => {
			const arr = path.split("/").slice(1);
			return arr.length === 2 && arr[0] === this.getPathName() && op === "remove";
		});
	}

	getReplaceOperations(): OperationType[] {
		return this.operations.filter(({ path, op }) => {
			const arr = path.split("/").slice(1);
			return arr.length === 3 && arr[0] === this.getPathName() && op === "replace";
		});
	}

	public getQuery(): string {
		const arr = [];
		if (this.getReplaceOperations().length !== 0) {
			arr.push(`
				update_${this.getTableName()}_many(updates: $update_${this.getTableName()}_many) {
					affected_rows
				}
			`);
		}

		if (this.getAddOperations().length !== 0) {
			arr.push(`
					insert_${this.getTableName()}(objects: $insert_${this.getTableName()}_objects){
						affected_rows
					}
			`);
		}

		if (this.getRemoveOperations().length !== 0) {
			arr.push(`
				delete_${this.getTableName()}(where: $delete_${this.getTableName()}_where){
					affected_rows
				}
			`);
		}

		return arr.join("\n");
	}

	public getMutationVariables(): string[] {
		const arr = [];
		if (this.getReplaceOperations().length !== 0) {
			arr.push(`$update_${this.getTableName()}_many: [${this.getTableName()}_updates!]!`);
		}

		if (this.getAddOperations().length !== 0) {
			arr.push(`$insert_${this.getTableName()}_objects: [${this.getTableName()}_insert_input!]!`);
		}

		if (this.getRemoveOperations().length !== 0) {
			arr.push(`$delete_${this.getTableName()}_where: ${this.getTableName()}_bool_exp!`);
		}

		return arr;
	}

	public getVariables(): Record<string, any> {
		const variables: Record<string, any> = {};

		if (this.getReplaceOperations().length !== 0) {
			const arr: any[] = [];
			this.getReplaceOperations().forEach(({ path, value }) => {
				// path = /accounts/${id}/count
				const components = path.split("/").slice(1);
				const id = components[1];
				const fieldName = components[2];
				if (this.getToUpdateColumns().indexOf(fieldName) !== -1) {
					arr.push({
						_set: {
							[fieldName]: value,
						},
						where: {
							[this.getIdName()]: {
								_eq: id,
							},
						},
					});
				}
			});

			variables[`update_${this.getTableName()}_many`] = arr;
		}

		if (this.getAddOperations().length !== 0) {
			const arr: any[] = [];
			this.getAddOperations().forEach(({ path }) => {
				// path = /accounts/${id}
				const components = path.split("/").slice(1);
				const id = components[1];
				// @ts-ignore
				const child = this.toUpdateOne[this.getPathName()].find((e) => e[this.getIdName()] === id);

				const object = {
					[this.getIdName()]: id,
					[this.getParentIdName()]: this.id,
				};

				this.getToUpdateColumns().forEach((column) => {
					object[column] = child[column];
				});

				arr.push(object);
			});
			variables[`insert_${this.getTableName()}_objects`] = arr;
		}

		if (this.getRemoveOperations().length !== 0) {
			const arr: any[] = [];
			this.getRemoveOperations().forEach(({ path }) => {
				// path = /accounts/${id}
				const components = path.split("/").slice(1);
				const id = components[1];

				arr.push({
					[this.getIdName()]: {
						_eq: id,
					},
				});
			});
			variables[`delete_${this.getTableName()}_where`] = {
				_or: arr,
			};
		}
		return variables;
	}
}
