import { gql } from "@apollo/client";
import { Row } from "../../moo-dialog-item-choose";
import { ItemType, manufacturer } from "../../moo-enums";
import { useGraphQLRequest } from "../../moo-graphql-provider";
import { useFindItemCustomsByIds } from "../../moo-item-custom-one";
import { useFindItemSpecsByIds } from "../../moo-item-spec-one";
import { getModelItemId } from "../../moo-shared";
import { Item } from "../@types/one";
import { useForm } from "./useForm";

function useFindManufacturerMap() {
	const graphQLRequest = useGraphQLRequest();

	return async (): Promise<Record<manufacturer.Value.Value, { id: number; name: string }>> => {
		const query = gql`
			{
				manufacturer_foreigns {
					id
					name
				}
				manufacturer_domestics {
					id
					name
				}
			}
		`;

		return graphQLRequest({ query, variables: {} }).then((data) => {
			// @ts-ignore
			const foreign = (data.manufacturer_foreigns ?? []).reduce((obj, { id, name }) => {
				return {
					...obj,
					[id]: name,
				};
			}, {});

			// @ts-ignore
			const domestic = (data.manufacturer_domestics ?? []).reduce((obj, { id, name }) => {
				return {
					...obj,
					[id]: name,
				};
			}, {});

			return {
				[manufacturer.Value.Value.Domestic]: domestic,
				[manufacturer.Value.Value.Foreign]: foreign,
			};
		});
	};
}

export function useChooseItems() {
	const findManufacturerMap = useFindManufacturerMap();
	const { setFieldValue, initialValues } = useForm();
	const findItemSpecListByIds = useFindItemSpecsByIds();
	const findItemCustomListByIds = useFindItemCustomsByIds();

	return async (rows: Row[]) => {
		const manufacturerMap = await findManufacturerMap();
		const itemSpecIds = rows.filter((e) => e.type === ItemType.Spec).map((e) => e.id);
		const itemCustomIds = rows.filter((e) => e.type === ItemType.Custom).map((e) => e.id);

		// @ts-ignore
		const itemSpecs: Item[] = (await findItemSpecListByIds(itemSpecIds)).map((e, priority) => {
			const itemId = e.id as string;
			const defective_product_item_id = getModelItemId({ create_date: initialValues.create_date, item_id: itemId });
			return {
				defective_product_item_id,
				id: itemId,
				manufacturer_type: e.manufacturer_type,
				manufacturer_id: e.manufacturer_id,
				name: e.name,
				cost: e.cost,
				material: e.material,
				color: e.color,
				size: e.size,
				price: e.price,
				purchase_price: e.purchase_price as number,
				note: e.note,
				image: e.image,
				image_cut: e.image_cut,
				manufacturer: manufacturerMap[e.manufacturer_type].name,
				category: "",
				type: ItemType.Spec,
				spec: "",
				count: 1,
				priority,
				price_final: 0,
				price_total: 0,
				purchase_price_total: 0,
			};
		});

		// @ts-ignore
		const itemCustoms: Item[] = (await findItemCustomListByIds(itemCustomIds)).map((e, priority) => {
			return {
				defective_product_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: e.id as string }),
				id: e.id as string,
				manufacturer_type: e.manufacturer_type,
				manufacturer_id: e.manufacturer_id,
				name: e.name,
				cost: e.cost,
				material: e.material,
				color: e.color,
				size: e.size,
				price: e.price,
				purchase_price: e.purchase_price as number,
				note: e.note,
				image: e.image,
				image_cut: e.image_cut,
				manufacturer: manufacturerMap[e.manufacturer_type].name,
				category: "",
				type: ItemType.Spec,
				spec: "",
				count: 1,
				priority,
				price_final: 0,
				price_total: 0,
				purchase_price_total: 0,
			};
		});

		setFieldValue("items", [...itemSpecs, ...itemCustoms]);
	};
}
