import { DocumentNode, gql } from "@apollo/client";
import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { MooGraphqlSelectField } from "../../moo-graphql-select-field";
import { useGraphQLDistinct } from "../hooks/useGraphQLDistinct";
import { useGraphQLResource } from "../hooks/useGraphQLResource";
import useStore from "../stores/useStore";

function useGraphQLQuery(): DocumentNode {
	const resource = useGraphQLResource();
	const graphQLDistinct = useGraphQLDistinct();

	return gql`
      query($distinct: ${graphQLDistinct}, $where: ${resource}_bool_exp) {
          ${resource}(distinct_on: $distinct, where: $where) {
						color
      		}
      }
	`;
}

export function ItemColorSelect() {
	const { value: branch } = useBranch();
	const {
		query: { color },
		setQuery,
	} = useStore();
	const graphQLResource = useGraphQLResource();
	const graphQLQuery = useGraphQLQuery();

	return (
		<MooGraphqlSelectField
			id="item-color-select-field"
			label="顏色"
			operationName={graphQLResource}
			query={graphQLQuery}
			variables={{
				distinct: ["color"],
				where: {
					color: { _neq: "" },
					status: { _eq: Status.Present },
					branch_id: { _eq: branch },
				},
			}}
			mapData={(data) => {
				const results: { id: string; value: string | null; label: string }[] = [
					{
						id: "all",
						value: null,
						label: "請選擇",
					},
				].concat(
					(data[graphQLResource] as { color: string | null }[]).map(({ color }) => {
						return {
							id: color || "",
							value: color,
							label: color || "",
						};
					}) as any,
				);
				return {
					results,
				};
			}}
			value={color}
			onChange={(color) => setQuery({ color, page: 0 })}
		/>
	);
}
