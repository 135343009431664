import { manufacturer, Status } from "../../moo-enums";

export enum Type {
	Debit = "DEBIT",
	Credit = "CREDIT",
}

export type VoucherRow = {
	voucher_row_id: string;
	account_type_id: string | null;
	subject_id_number: string;
	type: Type;
	link_id: string;
	summary: string;
	debit_amount: number;
	credit_amount: number;
	manufacturer_type: manufacturer.Value.Value | null;
	manufacturer_id: string | null;
	order: number;
};

export type One = {
	create_date: string;
	update_date: string;
	status: Status;
	voucher_date: Date;
	debit_total: number;
	credit_total: number;
	rows: VoucherRow[];
};
