import { FC } from "react";
import { MooDialogChoose } from "../moo-dialog-choose";
import { graphQLColumns, mapData, useCells, useHeaders } from "../moo-weekly-schedule-list";
import { Row } from "./@types";
import { useStore } from "./stores/useStore";

export type MooDialogWeeklyScheduleChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
	isMultiple?: boolean;
};

export const MooDialogWeeklyScheduleChoose: FC<MooDialogWeeklyScheduleChooseProps> = ({
	id,
	open,
	onClose,
	onOk,
	isMultiple = false,
}) => {
	const store = useStore();
	const headers = useHeaders();
	const cells = useCells();

	return (
		<MooDialogChoose
			id={id}
			label=""
			title="週計畫"
			resource="schedules"
			graphQLColumns={graphQLColumns}
			mapResults={(results) => {
				return results.map(mapData);
			}}
			open={open}
			onOk={(rows) => {
				if (rows.length > 0) {
					onOk(rows as Row[]);
				}
				onClose();
			}}
			onClose={onClose}
			headers={headers}
			cells={cells}
			store={store}
			isMultiple={isMultiple}
			hasSearch={false}
		/>
	);
};
