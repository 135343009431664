import { Fragment } from "react";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query } from "./@types";
import { useCells } from "./useCells";
import { useConditions } from "./useConditions";
import { useHeaders } from "./useHeaders";

export type MooDefectiveProductListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooDefectiveProductList = ({ onAdd, onRowClick, store }: MooDefectiveProductListProps) => {
	const itemStore = store();
	const cells = useCells();
	const headers = useHeaders();
	const conditions = useConditions(itemStore.query);

	const permissions = usePermissions();
	if (!permissions.includes(Permission.DefectiveProductListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooList
			showAddButton={permissions.includes(Permission.DefectiveProductAdd)}
			resource="defective_products"
			addLabel="新增瑕疵品"
			onAdd={onAdd}
			filterNode={<Fragment />}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
			conditions={conditions}
			graphQLColumns={`
				{
					id
					state
				}
			`}
		/>
	);
};
