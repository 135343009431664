import { returnOrder, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./index";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();

	return {
		status: Status.Present,
		state_accountant: returnOrder.StateAccountant.Value.UnConfirmed,
		return_date: now,
		order_id: null,
		order_type_name: "",
		order_customer_name: "",
		order_sales_name: "",
		order_sales_tel: "",
		order_deliver_date: "",
		order_is_include_tax: false,
		order_payee_name: "",
		order_payee_bank_name: "",
		order_payee_account_number: "",
		order_deliver_address: "",
		order_description: "",
		order_order_note: "",
		note: "",
		total_price: 0,
		tax: 0,
		note_price: "",
		items: [],
		create_date: now,
		update_date: now,
	};
}
