import { Dispatch, SetStateAction, useState } from "react";
import ColumnType from "../@types/columnTypes";

export type DefaultValueType = string | number;
export type StateMapType = Record<string, DefaultValueType>;
export type SetStateMapType = Dispatch<SetStateAction<StateMapType>>;
type UseStateType = (columns: ColumnType[]) => [StateMapType, SetStateMapType];

export const getEmptyStateMap = (columns: ColumnType[]) => {
	const obj: StateMapType = {};
	columns.forEach((column) => {
		if (!("type" in column)) {
			obj[column.id] = column.defaultValue;
		}
	});
	return obj;
};

const useStateMap: UseStateType = (columns) => {
	const [stateMap, setStateMap] = useState<StateMapType>(getEmptyStateMap(columns));
	return [stateMap, setStateMap];
};

export default useStateMap;
