import { useGraphQLQuery } from "../moo-graphql-provider";
import { useMe } from "../moo-me-provider";
import { MooMessageAdd } from "../moo-message-add";
import { getOperationName, getQuery, getVariables, MooMessageView } from "../moo-message-view";
import { useError } from "../moo-notification-provider";
import { MooScheduleView } from "../moo-schedule-view";

export type MooWeeklyScheduleOneProps = {
	id: string;
};

const messageResource = "schedules";
const messageIdName = "schedule_message_id";

export function MooWeeklyScheduleOne({ id }: MooWeeklyScheduleOneProps) {
	const error = useError();
	const me = useMe()!;
	const record = useGraphQLQuery({
		query: getQuery(messageResource, messageIdName),
		variables: getVariables(id),
		operationName: getOperationName(messageResource),
	});

	return (
		<>
			<MooScheduleView id={id} />
			<div style={{ marginTop: 16 }} />
			<MooMessageAdd
				id={id}
				resource="schedule"
				userName={me.employee.name}
				userBackgroundColor={me.color}
				onSuccess={async () => {
					await record.refetch();
				}}
				onError={error}
			/>
			<div style={{ marginTop: 16 }} />
			<MooMessageView resource={messageResource} id={id} messageIdName={messageIdName} />
		</>
	);
}
