import produce from "immer";
import { MooItemRowList } from "../../../moo-item-row-list";
import { FieldType as SpecCustomField } from "../../../moo-item-spec-and-custom-row";
import { One } from "../../@types/one";
import { useForm } from "../../hooks/useForm";
import { useSyncTotalPrice } from "../../hooks/useSyncTotalPrice";

const ItemList = () => {
	const { values, setValues } = useForm();
	const syncTotalPrice = useSyncTotalPrice();

	return (
		<MooItemRowList
			emptyMessage="尚未匯入訂購商品"
			items={values.items.map((e) => ({ ...e, purchase_price_total: e.purchase_price * e.count }))}
			onCountChange={(id, count) => {
				setValues(
					produce(values, (draft: One) => {
						const index = draft.items.findIndex((e) => e.id === id);
						if (index !== -1) {
							draft.items[index].count = count;
						}
						syncTotalPrice(draft);
					}),
				);
			}}
			onRemove={(id) => {
				setValues(
					produce(values, (draft: One) => {
						const index = draft.items.findIndex((e) => e.id === id);
						if (index !== -1) {
							//remove
							draft.items.splice(index, 1);
						}
						syncTotalPrice(draft);
					}),
				);
			}}
			onPriceFinalChange={(id, price_final) => {
				setValues(
					produce(values, (draft: One) => {
						const index = draft.items.findIndex((e) => e.id === id);
						if (index !== -1) {
							draft.items[index].price_final = price_final;
						}
						syncTotalPrice(draft);
					}),
				);
			}}
			specCustomFields={
				new Set<SpecCustomField>([
					"id",
					"name",
					"type",
					"category",
					"spec",
					"count",
					"price",
					"price_final",
					"purchase_price",
					"cost",
					"price_total",
					"remove",
				])
			}
		/>
	);
};

export default ItemList;
