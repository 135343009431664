import produce from "immer";
import { One } from "..";
import { Enum, useFindEnumById } from "../../moo-hooks";
import { uuidv4 } from "../../moo-shared";
import { Type, VoucherRow } from "../@types/one";
import { useForm } from "./useForm";

export function useUpdate() {
	const { values, setValues } = useForm();
	const findAccountTypeById = useFindEnumById<Enum & { id_number: string }>("account_types", ["id_number"], "id");

	function syncPrice(one: One) {
		one.credit_total = one.rows.map((e) => e.credit_amount).reduce((a, b) => a + b, 0);
		one.debit_total = one.rows.map((e) => e.debit_amount).reduce((a, b) => a + b, 0);
	}

	async function updateVoucherRowAccountTypeId(voucher_row_id: string, accountTypeId: string | null) {
		if (accountTypeId !== null) {
			const accountType = await findAccountTypeById(accountTypeId);
			if (accountType !== null) {
				setValues(
					produce(values, (one) => {
						const row = one.rows.find((e) => e.voucher_row_id === voucher_row_id);
						if (row) {
							row.account_type_id = accountType.id;
							row.subject_id_number = accountType.id_number;
							syncPrice(one);
						}
					}),
				);
			}
		}
	}

	function updateVoucherRow(voucher_row_id: VoucherRow["voucher_row_id"], partialVoucher: Partial<VoucherRow>) {
		setValues(
			produce(values, (one) => {
				const voucherIndex = one.rows.findIndex((e) => e.voucher_row_id === voucher_row_id);
				if (voucherIndex !== -1) {
					Object.entries(partialVoucher).forEach(([k, v]) => {
						// @ts-ignore
						one.rows[voucherIndex][k] = v;
					});
				}
				syncPrice(one);
			}),
		);
	}

	function addVoucherRow() {
		setValues(
			produce(values, (one) => {
				const voucherRow: VoucherRow = {
					voucher_row_id: uuidv4(),
					subject_id_number: "",
					account_type_id: null,
					type: Type.Debit,
					link_id: "",
					summary: "",
					debit_amount: 0,
					credit_amount: 0,
					manufacturer_type: null,
					manufacturer_id: null,
					order: one.rows.length,
				};
				one.rows.push(voucherRow);
				syncPrice(one);
			}),
		);
	}

	function updateVoucherRowType(voucher_row_id: VoucherRow["voucher_row_id"], value: VoucherRow["type"]) {
		setValues(
			produce(values, (one) => {
				const voucherIndex = one.rows.findIndex((e) => e.voucher_row_id === voucher_row_id);
				if (voucherIndex !== -1) {
					const row = one.rows[voucherIndex];
					row.type = value;

					if (value === "DEBIT") {
						row.debit_amount = row.credit_amount;
						row.credit_amount = 0;
					} else if (value === "CREDIT") {
						row.credit_amount = row.debit_amount;
						row.debit_amount = 0;
					}
				}
				syncPrice(one);
			}),
		);
	}

	function removeVoucherRow(voucher_row_id: VoucherRow["voucher_row_id"]) {
		setValues(
			produce(values, (one) => {
				const voucherIndex = one.rows.findIndex((e) => e.voucher_row_id === voucher_row_id);
				if (voucherIndex !== -1) {
					one.rows.splice(voucherIndex, 1);
				}
				syncPrice(one);
			}),
		);
	}

	return {
		updateVoucherRowAccountTypeId,
		updateVoucherRow,
		addVoucherRow,
		updateVoucherRowType,
		removeVoucherRow,
	};
}
