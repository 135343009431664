import { One } from "..";
import { requestBuy } from "../../moo-enums";
import { Permission, usePermissions } from "../../moo-me-provider";
import { useGetFieldError } from "../../moo-shared";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import FieldContainer from "../components/FieldContainer";
import { useForm } from "../hooks/useForm";

type ItemOption = {
	id: requestBuy.StatePurchase.Value;
	label: requestBuy.StatePurchase.Label;
};

type Item = {
	id: keyof One;
	label: string;
	options: ItemOption[];
};

const arr: Item[] = [
	{
		id: "state_purchase",
		label: "狀態",
		options: [
			{
				id: requestBuy.StatePurchase.Value.NotConfirmed,
				label: requestBuy.StatePurchase.Label.NotConfirmed,
			},
			{
				id: requestBuy.StatePurchase.Value.Confirmed,
				label: requestBuy.StatePurchase.Label.Confirmed,
			},
		],
	},
];

export function StateBlock() {
	const permissions = usePermissions();
	const { values: one, setFieldValue } = useForm();
	const getFieldError = useGetFieldError<One>();

	return (
		<FieldContainer>
			{arr.map(({ id, label, options }) => {
				let disabled = false;
				switch (id) {
					case "state_purchase":
						disabled = !permissions.includes(Permission.RequestBuyStatePurchaseWrite);
						break;
				}
				return (
					<MooTypeableSelect
						key={id}
						id={id}
						label={label}
						disabled={disabled}
						value={one[id] as string}
						error={getFieldError(id)}
						options={options.map((e) => {
							return { ...e, value: e.id };
						})}
						onChange={(value) => {
							setFieldValue(id, value);
						}}
					/>
				);
			})}
		</FieldContainer>
	);
}
