import { KodenCrudProvider } from "@yachelee/koden-components/koden-crud";
import { KodenGcsPresignedUrlProvider } from "@yachelee/koden-components/koden-gcs-presigned-url-provider";
import { KodenGcsProvider } from "@yachelee/koden-components/koden-gcs-provider";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GCS_BUCKET, GCS_PRESIGNED_URL, GCS_URL, GRAPHQL_API_URL } from "../config";
import useAjax from "../hooks/useAjax";
import AccountTypeRootRoute, { path as AccountTypeRootRoutePath } from "../routes/AccountTypeRootRoute";
import AccountTypeSubRoute, { path as AccountTypeSubRoutePath } from "../routes/AccountTypeSubRoute";
import AccountTypesRoute, { path as AccountTypesRoutePath } from "../routes/AccountTypesRoute";
import DomesticManufacturerListRoute, {
	path as DomesticManufacturerListRoutePath,
} from "../routes/DomesticManufacturerListRoute";
import EmployeeListRoute, { path as EmployeeListPath } from "../routes/EmployeeListRoute";
import EmployeeTypesRoute, { path as EmployeeTypesPath } from "../routes/EmployeeTypesRoute";
import ForeignManufacturerListRoute, {
	path as ForeignManufacturerListRoutePath,
} from "../routes/ForeignManufacturerListRoute";
import HomeRoute from "../routes/HomeRoute";
import ItemMainCategoryRoute, { path as ItemMainCategoryRoutePath } from "../routes/ItemMainCategoryRoute";
import ItemSubCategoryRoute, { path as ItemSubCategoryRoutePath } from "../routes/ItemSubCategoryRoute";
import LoginRoute, { path as LoginPath } from "../routes/LoginRoute";
import LogisticsManufacturerListRoute, {
	path as LogisticsManufacturerListRoutePath,
} from "../routes/LogisticsManufacturerListRoute";
import ManufacturerTypeOneRoute, { path as ManufacturerTypeOnePath } from "../routes/ManufacturerTypeOneRoute";
import ManufacturerTypesRoute, { path as ManufacturerTypesPath } from "../routes/ManufacturerTypesRoute";
import NewDomesticManufacturerRoute, {
	path as NewDomesticManufacturerRoutePath,
} from "../routes/NewDomesticManufacturerRoute";
import NewEmployeeRoute, { path as NewEmployeePath } from "../routes/NewEmployeeRoute";
import NewForeignManufacturerRoute, {
	path as NewForeignManufacturerRoutePath,
} from "../routes/NewForeignManufacturerRoute";
import NewLogisticsManufacturerRoute, {
	path as NewLogisticsManufacturerRoutePath,
} from "../routes/NewLogisticsManufacturerRoute";
import PayeeCrudRoute, { path as PayeeCrudRoutePath } from "../routes/PayeeCrudRoute";
import QuotationTypesRoute, { path as QuotationTypesRoutePath } from "../routes/QuotationTypesRoute";
import SalaryListRoute, { path as SalaryListRoutePath } from "../routes/SalaryListRoute";
import UpdateDomesticManufacturerRoute, {
	path as UpdateDomesticManufacturerRoutePath,
} from "../routes/UpdateDomesticManufacturerRoute";
import EditEmployeeRoute, { path as EditEmployeeRoutePath } from "../routes/UpdateEmployeeRoute";
import UpdateForeignManufacturerRoute, {
	path as UpdateForeignManufacturerRoutePath,
} from "../routes/UpdateForeignManufacturerRoute";
import UpdateLogisticsManufacturerRoute, {
	path as UpdateLogisticsManufacturerRoutePath,
} from "../routes/UpdateLogisticsManufacturerRoute";
import WarehouseCrudRoute, { path as WarehouseCrudRoutePath } from "../routes/WarehouseCrudRoute";
import WarehouseStockListRoute, { path as WarehouseStockListRoutePath } from "../routes/WarehouseStockListRoute";
import AccountPerformanceListRoute, {
	path as AccountPerformanceListRoutePath,
} from "../routes/account/AccountPerformanceListRoute";
import AccountPerformanceSubListRoute, {
	path as AccountPerformanceSubListRoutePath,
} from "../routes/account/AccountPerformanceSubListRoute";
import AllowanceListRoute, { path as AllowanceListRoutePath } from "../routes/allowance/ListRoute";
import AllowanceNewRoute, { path as AllowanceNewRoutePath } from "../routes/allowance/NewRoute";
import AllowanceUpdateRoute, { path as AllowanceUpdateRoutePath } from "../routes/allowance/UpdateRoute";
import BlogListRoute, { path as BlogListRoutePath } from "../routes/blog/ListRoute";
import BlogNewRoute, { path as BlogNewRoutePath } from "../routes/blog/NewRoute";
import BlogOneRoute, { path as BlogOneRoutePath } from "../routes/blog/OneRoute";
import CustomerTypesRoute, { path as CustomerTypesPath } from "../routes/customer/CustomerTypesRoute";
import { ListRoute as CustomerListRoute, path as CustomerListPath } from "../routes/customer/ListRoute";
import { NewRoute as NewCustomerRoute, path as NewCustomerPath } from "../routes/customer/NewRoute";
import { path as EditCustomerRoutePath, UpdateRoute as UpdateCustomerRoute } from "../routes/customer/UpdateRoute";
import DefectiveProductListRoute, {
	path as DefectiveProductListRoutePath,
} from "../routes/defective_product/ListRoute";
import DefectiveProductNewRoute, { path as DefectiveProductNewRoutePath } from "../routes/defective_product/NewRoute";
import DefectiveProductUpdateRoute, {
	path as DefectiveProductUpdateRoutePath,
} from "../routes/defective_product/UpdateRoute";
import ExportGoodsListRoute, { path as ExportGoodsListRoutePath } from "../routes/export_goods/ListRoute";
import ExportGoodsNewRoute, { path as ExportGoodsNewRoutePath } from "../routes/export_goods/NewRoute";
import ExportGoodsUpdateRoute, { path as ExportGoodsUpdateRoutePath } from "../routes/export_goods/UpdateRoute";
import ImportGoodsListRoute, { path as ImportGoodsListRoutePath } from "../routes/import_goods/ListRoute";
import ImportGoodsNewRoute, { path as ImportGoodsNewRoutePath } from "../routes/import_goods/NewRoute";
import ImportGoodsUpdateRoute, { path as ImportGoodsUpdateRoutePath } from "../routes/import_goods/UpdateRoute";
import ItemCustomListRoute, { path as ItemCustomListRoutePath } from "../routes/item_custom/ListRoute";
import ItemCustomNewRoute, { path as ItemCustomNewRoutePath } from "../routes/item_custom/NewRoute";
import ItemCustomUpdateRoute, { path as ItemCustomUpdateRoutePath } from "../routes/item_custom/UpdateRoute";
import ItemSpecListRoute, { path as ItemSpecListRoutePath } from "../routes/item_spec/ListRoute";
import NewItemSpecRoute, { path as NewItemSpecRoutePath } from "../routes/item_spec/NewRoute";
import UpdateItemSpecRoute, { path as UpdateItemSpecRoutePath } from "../routes/item_spec/UpdateRoute";
import OrderListRoute, { path as OrderListRoutePath } from "../routes/order/ListRoute";
import OrderNewRoute, { path as OrderNewRoutePath } from "../routes/order/NewRoute";
import OrderUpdateRoute, { path as OrderUpdateRoutePath } from "../routes/order/UpdateRoute";
import OrderGoodsListRoute, { path as OrderGoodsListRoutePath } from "../routes/order_goods/ListRoute";
import OrderGoodsNewRoute, { path as OrderGoodsNewRoutePath } from "../routes/order_goods/NewRoute";
import OrderGoodsUpdateRoute, { path as OrderGoodsUpdateRoutePath } from "../routes/order_goods/UpdateRoute";
import PurchaseOrderListRoute, { path as PurchaseOrderListRoutePath } from "../routes/purchase_order/ListRoute";
import PurchaseOrderNewRoute, { path as PurchaseOrderNewRoutePath } from "../routes/purchase_order/NewRoute";
import PurchaseOrderUpdateRoute, { path as PurchaseOrderUpdateRoutePath } from "../routes/purchase_order/UpdateRoute";
import QuotationListRoute, { path as QuotationListRoutePath } from "../routes/quotation/ListRoute";
import QuotationNewRoute, { path as QuotationNewRoutePath } from "../routes/quotation/NewRoute";
import QuotationUpdateRoute, { path as QuotationUpdateRoutePath } from "../routes/quotation/UpdateRoute";
import RequestBuyListRoute, { path as RequestBuyListRoutePath } from "../routes/request_buy/ListRoute";
import RequestBuyNewRoute, { path as RequestBuyNewRoutePath } from "../routes/request_buy/NewRoute";
import RequestBuyUpdateRoute, { path as RequestBuyUpdateRoutePath } from "../routes/request_buy/UpdateRoute";
import ReturnGoodsListRoute, { path as ReturnGoodsListRoutePath } from "../routes/return_goods/ListRoute";
import ReturnGoodsNewRoute, { path as ReturnGoodsNewRoutePath } from "../routes/return_goods/NewRoute";
import ReturnGoodsUpdateRoute, { path as ReturnGoodsUpdateRoutePath } from "../routes/return_goods/UpdateRoute";
import ReturnOrderListRoute, { path as ReturnOrderListRoutePath } from "../routes/return_order/ListRoute";
import ReturnOrderNewRoute, { path as ReturnOrderNewRoutePath } from "../routes/return_order/NewRoute";
import ReturnOrderUpdateRoute, { path as ReturnOrderUpdateRoutePath } from "../routes/return_order/UpdateRoute";
import RoleListRoute, { path as RoleListRoutePath } from "../routes/role/ListRoute";
import RoleNewRoute, { path as RoleNewRoutePath } from "../routes/role/NewRoute";
import RoleUpdateRoute, { path as RoleUpdateRoutePath } from "../routes/role/UpdateRoute";
import ScheduleListRoute, { path as ScheduleListRoutePath } from "../routes/schedule/ListRoute";
import ScheduleNewRoute, { path as ScheduleNewRoutePath } from "../routes/schedule/NewRoute";
import ScheduleOneRoute, { path as ScheduleOneRoutePath } from "../routes/schedule/OneRoute";
import StockTransferListRoute, { path as StockTransferListRoutePath } from "../routes/stock_transfer/ListRoute";
import StockTransferNewRoute, { path as StockTransferNewRoutePath } from "../routes/stock_transfer/NewRoute";
import StockTransferUpdateRoute, { path as StockTransferUpdateRoutePath } from "../routes/stock_transfer/UpdateRoute";
import SvcListRoute, { path as SvcListRoutePath } from "../routes/svc/ListRoute";
import SvcNewRoute, { path as SvcNewRoutePath } from "../routes/svc/NewRoute";
import SvcUpdateRoute, { path as SvcUpdateRoutePath } from "../routes/svc/UpdateRoute";
import VoucherListRoute, { path as VoucherListRoutePath } from "../routes/voucher/ListRoute";
import VoucherNewRoute, { path as VoucherNewRoutePath } from "../routes/voucher/NewRoute";
import VoucherUpdateRoute, { path as VoucherUpdateRoutePath } from "../routes/voucher/UpdateRoute";
import { MooGraphqlProvider } from "./moo-components/moo-graphql-provider";
import { MooNotificationProvider } from "./moo-components/moo-notification-provider";

const router = createBrowserRouter([
	{
		path: "/",
		element: <HomeRoute />,
		children: [
			{
				path: CustomerListPath,
				element: <CustomerListRoute />,
			},
			{
				path: NewCustomerPath,
				element: <NewCustomerRoute />,
			},
			{
				path: EditCustomerRoutePath,
				element: <UpdateCustomerRoute />,
			},
			{
				path: CustomerTypesPath,
				element: <CustomerTypesRoute />,
			},
			{
				path: ManufacturerTypesPath,
				element: <ManufacturerTypesRoute />,
			},
			{
				path: ManufacturerTypeOnePath,
				element: <ManufacturerTypeOneRoute />,
			},
			{
				path: UpdateDomesticManufacturerRoutePath,
				element: <UpdateDomesticManufacturerRoute />,
			},
			{
				path: DomesticManufacturerListRoutePath,
				element: <DomesticManufacturerListRoute />,
			},
			{
				path: NewDomesticManufacturerRoutePath,
				element: <NewDomesticManufacturerRoute />,
			},
			{
				path: ForeignManufacturerListRoutePath,
				element: <ForeignManufacturerListRoute />,
			},
			{
				path: NewForeignManufacturerRoutePath,
				element: <NewForeignManufacturerRoute />,
			},
			{
				path: UpdateForeignManufacturerRoutePath,
				element: <UpdateForeignManufacturerRoute />,
			},
			{
				path: LogisticsManufacturerListRoutePath,
				element: <LogisticsManufacturerListRoute />,
			},
			{
				path: NewLogisticsManufacturerRoutePath,
				element: <NewLogisticsManufacturerRoute />,
			},
			{
				path: UpdateLogisticsManufacturerRoutePath,
				element: <UpdateLogisticsManufacturerRoute />,
			},
			{
				path: ItemSpecListRoutePath,
				element: <ItemSpecListRoute />,
			},
			{
				path: NewItemSpecRoutePath,
				element: <NewItemSpecRoute />,
			},
			{
				path: UpdateItemSpecRoutePath,
				element: <UpdateItemSpecRoute />,
			},
			{
				path: ItemCustomListRoutePath,
				element: <ItemCustomListRoute />,
			},
			{
				path: ItemCustomNewRoutePath,
				element: <ItemCustomNewRoute />,
			},
			{
				path: ItemCustomUpdateRoutePath,
				element: <ItemCustomUpdateRoute />,
			},
			{
				path: ItemMainCategoryRoutePath,
				element: <ItemMainCategoryRoute />,
			},
			{
				path: ItemSubCategoryRoutePath,
				element: <ItemSubCategoryRoute />,
			},
			{
				path: QuotationListRoutePath,
				element: <QuotationListRoute />,
			},
			{
				path: QuotationNewRoutePath,
				element: <QuotationNewRoute />,
			},
			{
				path: QuotationUpdateRoutePath,
				element: <QuotationUpdateRoute />,
			},
			{ path: OrderListRoutePath, element: <OrderListRoute /> },
			{ path: OrderNewRoutePath, element: <OrderNewRoute /> },
			{
				element: <OrderUpdateRoute />,
				path: OrderUpdateRoutePath,
			},
			{ element: <PayeeCrudRoute />, path: PayeeCrudRoutePath },

			{ element: <ReturnOrderListRoute />, path: ReturnOrderListRoutePath },
			{ element: <ReturnOrderNewRoute />, path: ReturnOrderNewRoutePath },
			{ element: <ReturnOrderUpdateRoute />, path: ReturnOrderUpdateRoutePath },

			{ element: <ReturnGoodsListRoute />, path: ReturnGoodsListRoutePath },
			{ element: <ReturnGoodsNewRoute />, path: ReturnGoodsNewRoutePath },
			{ element: <ReturnGoodsUpdateRoute />, path: ReturnGoodsUpdateRoutePath },
			{ element: <QuotationTypesRoute />, path: QuotationTypesRoutePath },

			{ element: <OrderGoodsListRoute />, path: OrderGoodsListRoutePath },
			{ element: <OrderGoodsNewRoute />, path: OrderGoodsNewRoutePath },
			{ element: <OrderGoodsUpdateRoute />, path: OrderGoodsUpdateRoutePath },
			{ element: <RequestBuyListRoute />, path: RequestBuyListRoutePath },
			{ element: <RequestBuyNewRoute />, path: RequestBuyNewRoutePath },
			{ element: <RequestBuyUpdateRoute />, path: RequestBuyUpdateRoutePath },
			{ element: <PurchaseOrderListRoute />, path: PurchaseOrderListRoutePath },
			{ element: <PurchaseOrderNewRoute />, path: PurchaseOrderNewRoutePath },
			{ element: <PurchaseOrderUpdateRoute />, path: PurchaseOrderUpdateRoutePath },
			{ element: <SvcListRoute />, path: SvcListRoutePath },
			{ element: <SvcNewRoute />, path: SvcNewRoutePath },
			{ element: <SvcUpdateRoute />, path: SvcUpdateRoutePath },

			{
				element: <WarehouseCrudRoute />,
				path: WarehouseCrudRoutePath,
			},
			{
				element: <WarehouseStockListRoute />,
				path: WarehouseStockListRoutePath,
			},
			{
				element: <StockTransferListRoute />,
				path: StockTransferListRoutePath,
			},
			{
				element: <StockTransferNewRoute />,
				path: StockTransferNewRoutePath,
			},
			{
				element: <StockTransferUpdateRoute />,
				path: StockTransferUpdateRoutePath,
			},

			{
				element: <ImportGoodsListRoute />,
				path: ImportGoodsListRoutePath,
			},
			{
				element: <ImportGoodsNewRoute />,
				path: ImportGoodsNewRoutePath,
			},
			{
				element: <ImportGoodsUpdateRoute />,
				path: ImportGoodsUpdateRoutePath,
			},
			{
				element: <ExportGoodsListRoute />,
				path: ExportGoodsListRoutePath,
			},
			{
				element: <ExportGoodsNewRoute />,
				path: ExportGoodsNewRoutePath,
			},
			{
				element: <ExportGoodsUpdateRoute />,
				path: ExportGoodsUpdateRoutePath,
			},
			{
				element: <DefectiveProductListRoute />,
				path: DefectiveProductListRoutePath,
			},
			{
				element: <DefectiveProductNewRoute />,
				path: DefectiveProductNewRoutePath,
			},
			{
				element: <DefectiveProductUpdateRoute />,
				path: DefectiveProductUpdateRoutePath,
			},
			{
				element: <VoucherListRoute />,
				path: VoucherListRoutePath,
			},
			{
				element: <VoucherNewRoute />,
				path: VoucherNewRoutePath,
			},
			{
				element: <VoucherUpdateRoute />,
				path: VoucherUpdateRoutePath,
			},
			{
				element: <AllowanceListRoute />,
				path: AllowanceListRoutePath,
			},
			{
				element: <AllowanceNewRoute />,
				path: AllowanceNewRoutePath,
			},
			{
				element: <AllowanceUpdateRoute />,
				path: AllowanceUpdateRoutePath,
			},
			{
				element: <AccountPerformanceListRoute />,
				path: AccountPerformanceListRoutePath,
			},
			{
				element: <AccountPerformanceSubListRoute />,
				path: AccountPerformanceSubListRoutePath,
			},
			{
				element: <SalaryListRoute />,
				path: SalaryListRoutePath,
			},
			{
				element: <AccountTypeRootRoute />,
				path: AccountTypeRootRoutePath,
			},
			{
				element: <AccountTypesRoute />,
				path: AccountTypesRoutePath,
			},
			{
				element: <AccountTypeSubRoute />,
				path: AccountTypeSubRoutePath,
			},
			{
				element: <ScheduleListRoute />,
				path: ScheduleListRoutePath,
			},
			{
				element: <ScheduleNewRoute />,
				path: ScheduleNewRoutePath,
			},
			{
				element: <ScheduleOneRoute />,
				path: ScheduleOneRoutePath,
			},
			{ element: <BlogListRoute />, path: BlogListRoutePath },
			{ element: <BlogNewRoute />, path: BlogNewRoutePath },
			{ element: <BlogOneRoute />, path: BlogOneRoutePath },

			{ element: <RoleListRoute />, path: RoleListRoutePath },
			{ element: <RoleNewRoute />, path: RoleNewRoutePath },
			{
				element: <RoleUpdateRoute />,
				path: RoleUpdateRoutePath,
			},
			{ element: <EmployeeListRoute />, path: EmployeeListPath },
			{ element: <EmployeeTypesRoute />, path: EmployeeTypesPath },
			{ element: <NewEmployeeRoute />, path: NewEmployeePath },
			{ element: <EditEmployeeRoute />, path: EditEmployeeRoutePath },
		],
	},
	{
		path: LoginPath,
		element: <LoginRoute />,
	},
]);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchOnMount: true,
		},
	},
});

function InnerComponents() {
	return (
		<MooNotificationProvider>
			<RouterProvider router={router} />
		</MooNotificationProvider>
	);
}

export function App() {
	const ajax = useAjax();

	useEffect(() => {
		const root = window.document.getElementById("root")!;
		const rootLoading = window.document.getElementById("root-loading")!;

		root.classList.remove("none");
		rootLoading.style.display = "none";
	});

	return (
		<QueryClientProvider client={queryClient}>
			<KodenCrudProvider ajax={ajax}>
				<MooGraphqlProvider url={GRAPHQL_API_URL!} ajax={ajax}>
					<KodenGcsProvider url={GCS_URL!} bucket={GCS_BUCKET!} ajax={ajax}>
						<KodenGcsPresignedUrlProvider url={GCS_PRESIGNED_URL!}>
							<InnerComponents />
						</KodenGcsPresignedUrlProvider>
					</KodenGcsProvider>
				</MooGraphqlProvider>
			</KodenCrudProvider>
		</QueryClientProvider>
	);
}
