import { Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./index";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();

	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		name: "",
		permissions: [],
	};
}
