import produce from "immer";
import type { StoreApi, UseBoundStore } from "zustand";
import create from "zustand";

export type QuerySlice<T> = {
	query: T;
	setQuery: (query: Partial<T>) => void;
	clear: () => void;
};

export type StoreType<T> = UseBoundStore<StoreApi<QuerySlice<T>>>;

export function useStore<T>(defaultQuery: T, initialQuery: T) {
	return create<QuerySlice<T>>((set, get) => ({
		query: defaultQuery,
		setQuery(partialQuery) {
			set(
				produce((draft) => {
					draft.query = { ...draft.query, ...partialQuery };
					return draft;
				}),
			);
		},
		clear() {
			get().setQuery(initialQuery);
		},
	}));
}
