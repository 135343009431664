import { LogisticsManufacturer } from "../../moo-manufacturer-logistics-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";

export function mapperInitialValues(record: Row): LogisticsManufacturer {
	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		type: record.type,
		name: record.name,
		contact: record.contact,
		tel: record.tel,
		fax: record.fax,
		email: record.email,
		drivers: record.drivers,
		accounts: record.accounts,
		note: record.note,
	};
}
