import { Status } from "../../moo-enums";

export enum Gender {
	None = "NONE",
	Male = "MALE",
	Female = "FEMALE",
}

export enum Vip {
	NORMAL = "NORMAL",
	KIND = "KIND",
	ACTIVE = "ACTIVE",
	SUPER = "SUPER",
}

export type Tel = {
	customer_tel_id: string;
	tel: string;
};

export type Customer = {
	create_date: string;
	update_date: string;
	status: Status;
	type_id: string;
	name: string;
	gender: Gender;
	birthday: string | null;
	email: string;
	tels: Tel[];
	line: string;
	job: string;
	vip: Vip | null;
	address: string;
	bank_account: string;
	bank: string;
	bank_account_number: string;
	tax_id: string;
	note: string;
};
