import { KodenButton } from "@yachelee/koden-components/koden-button";
import { Formik } from "formik";
import styled from "styled-components";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooSubmitButton } from "../moo-submit-button";
import { MooUseIsEditContext } from "../moo-use-is-edit";
import { Props } from "./@types/props";
import { BasicBlock, StateBlock } from "./blocks";
import { useForm } from "./hooks/useForm";
import { context } from "./hooks/useProps";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.form`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

function Form({ isEdit = false, isCancelDisabled, isOkDisabled, onCancel }: Omit<Props, "onSubmit" | "initialValues">) {
	const { handleSubmit } = useForm();

	return (
		<Container onSubmit={handleSubmit}>
			{isEdit && <StateBlock />}
			<BasicBlock />
			{!isOkDisabled && (
				<ButtonContainer>
					<KodenButton
						style={{ marginRight: "1rem" }}
						disabled={isCancelDisabled}
						onClick={async () => {
							if (window.confirm("確定取消並且回到上一頁？")) {
								onCancel();
							}
						}}
					>
						取消
					</KodenButton>
					<MooSubmitButton permission={Permission.DefectiveProductUpdate} />
				</ButtonContainer>
			)}
		</Container>
	);
}

export function MooDefectiveProductOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.DefectiveProductRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<MooUseIsEditContext.Provider value={{ isEdit: props.isEdit }}>
				<context.Provider value={{ ...props, initialValues, onSubmit }}>
					<Form {...props} />
				</context.Provider>
			</MooUseIsEditContext.Provider>
		</Formik>
	);
}
