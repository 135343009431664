import { FC } from "react";
import { DEFAULT_ONE, DomesticManufacturer, MooManufacturerDomesticOne } from "../moo-manufacturer-domestic-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooManufacturerDomesticAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooManufacturerDomesticAdd: FC<MooManufacturerDomesticAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<DomesticManufacturer>("manufacturer_domestics", onSuccess, (e) => e, false);
	const permissions = usePermissions();

	return (
		<MooManufacturerDomesticOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.OrderAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
