import produce from "immer";
import { Row as ItemRow } from "../../moo-dialog-item-choose";
import { ItemType } from "../../moo-enums";
import { DEFAULT_ONE as itemCustomDefault, One as ItemCustomOne } from "../../moo-item-custom-one";
import { DEFAULT_ONE as itemSpecDefault, One as ItemSpecOne } from "../../moo-item-spec-one";
import { Calculator } from "../../moo-quotation-one";
import { attachItems, getItemSpec, getModelItemId, useFindResourceListByIds } from "../../moo-shared";
import { Item } from "../@types/one";
import { useForm } from "./useForm";

type QueryItem = (ItemSpecOne | ItemCustomOne) & {
	id: string;
	manufacturer: { name: string } | null;
	category: { name: string } | null;
};

export function mapItem(
	e: QueryItem,
	create_date: string,
	priority: number,
	itemType: ItemType.Custom | ItemType.Spec,
): Item {
	const purchase_price = e.purchase_price;
	const count = 1;

	return {
		order_good_item_id: getModelItemId({ create_date, item_id: e.id }),
		id: e.id,
		manufacturer_type: e.manufacturer_type,
		manufacturer_id: e.manufacturer_id,
		manufacturer: e.manufacturer?.name || "",
		category: e.category?.name || "",
		type: itemType,
		name: e.name,
		cost: e.cost,
		spec: getItemSpec(e),
		material: e.material,
		color: e.color,
		size: e.size,
		count,
		price: e.price,
		price_final: 0,
		price_total: 0,
		purchase_price: purchase_price || 0,
		purchase_price_total: Calculator.getItemPurchasePriceTotal({ purchase_price: purchase_price || 0, count }),
		note: e.note,
		image: e.image,
		image_cut: e.image_cut,
		priority,
	};
}

const itemSpecQuery = produce(itemSpecDefault() as QueryItem, (draft) => {
	// @ts-ignore
	draft.manufacturer = {
		// @ts-ignore
		name: null,
	};
	draft.category = {
		// @ts-ignore
		name: null,
	};
});

const itemCustomQuery = produce(itemCustomDefault() as QueryItem, (draft) => {
	// @ts-ignore
	draft.manufacturer = {
		// @ts-ignore
		name: null,
	};
	draft.category = {
		// @ts-ignore
		name: null,
	};
});

export function useChooseItems() {
	const { initialValues, values, setFieldValue } = useForm();
	const findItemSpecListByIds = useFindResourceListByIds<QueryItem>("item_specs", itemSpecQuery, {});
	const findItemCustomListByIds = useFindResourceListByIds<QueryItem>("item_customs", itemCustomQuery, {});

	return async (rows: ItemRow[]) => {
		const itemSpecIds = rows.filter((e) => e.type === ItemType.Spec).map((e) => e.id);
		const itemCustomIds = rows.filter((e) => e.type === ItemType.Custom).map((e) => e.id);
		const itemSpecs: Item[] = (await findItemSpecListByIds(itemSpecIds)).map((e: QueryItem, index) =>
			mapItem(e, initialValues.create_date, index, ItemType.Spec),
		);
		const itemCustoms: Item[] = (await findItemCustomListByIds(itemCustomIds)).map((e, index) =>
			mapItem(e, initialValues.create_date, index, ItemType.Custom),
		);
		const toAddRows: Item[] = [...itemSpecs, ...itemCustoms];
		const newItems = attachItems<Item>(values.items, toAddRows);
		setFieldValue("items", newItems);
	};
}
