import { defectiveProduct, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();

	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		state: defectiveProduct.State.Value.MAINTAINING,
		found_date: now,
		owner_id: null,
		found_warehouse_id: null,
		handle_method: defectiveProduct.HandleMethod.Value.MANUFACTURER_MAINTAIN,
		note: "",
		items: [],
	};
}
