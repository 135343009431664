import { ReactNode, useState } from "react";
import { MooDialogLinkChoose, Row, useStore } from "../";

type ReturnType = {
	component: ReactNode;
	open: () => void;
	close: () => void;
};

export function useLinkDialogChoose(id: string, onOk: (rows: Row[]) => void, isMultiple = true): ReturnType {
	const { clearChoose } = useStore();
	const [open, setOpen] = useState(false);
	const close = () => setOpen(false);

	const component = (
		<MooDialogLinkChoose
			id={id}
			open={open}
			onClose={close}
			onOk={async (rows) => {
				onOk(rows);
				close();
			}}
			isMultiple={isMultiple}
		/>
	);

	return {
		component,
		open: () => {
			clearChoose();
			setOpen(true);
		},
		close,
	};
}
