import { ReactNode } from "react";
import { LinkContext } from "./context";
import { OnClick } from "./types";

export type MooLinkProviderProps = {
	children: ReactNode;
	onLinkClick: OnClick;
};

export function MooLinkProvider({ children, onLinkClick }: MooLinkProviderProps) {
	return <LinkContext.Provider value={{ onLinkClick }}>{children}</LinkContext.Provider>;
}
