import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    state_accountant
    type
    from_date
    to_date
    customer {
      id
      name
    }
    sales {
      id
      name
    }
    owner {
      id
      name
    }
    address
    description
    note
    driver_logistics_manufacturer_id
    manufacturer_logistic_driver {
      manufacturer_logistic_driver_id
      tel
    }
    price
    cost
    receivables
    received
    items {
      svc_item_id
      count
      name
      price
    }
`;

export const query = gql`
    query ($id: String!) {
        svcs_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
