import type { One } from "../moo-role-one";
import { MooRoleOne } from "../moo-role-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { RolePermissionTable } from "./updateOne/RolePermissionTable";
import { RoleTable } from "./updateOne/RoleTable";

export type MooRoleUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooRoleUpdate({ id, onCancel }: MooRoleUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="role-one"
			query={query}
			tableName="roles"
			mapperInitialValues={mapperInitialValues}
			Form={MooRoleOne}
			tables={[RolePermissionTable, RoleTable]}
		/>
	);
}
