import { Branch, MooBranchProvider } from "../moo-branch-provider";
import { MooGraphQLList } from "../moo-graphql-list";
import { QuerySlice } from "../moo-hooks";
import { ListSelectField } from "../moo-list-select-field";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { DollarNumber, useListGraphQLVariables } from "../moo-shared";
import { Query } from "./@types";
import { DateFilter } from "./DateFilter";
import { mapData } from "./mapData";
import { operationName } from "./moo-order-list";
import { useCells } from "./useCells";
import { useConditions } from "./useConditions";
import { useGraphQLQuery } from "./useGraphQLQuery";
import { useHeaders } from "./useHeaders";

export const rowsPerPage = 10;

type OrderListProps = {
	store: QuerySlice<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export function OrderList({ store, onAdd, onRowClick }: OrderListProps) {
	const { query, setQuery } = store;
	const cells = useCells({ crud: true });
	const headers = useHeaders();
	const graphQLQuery = useGraphQLQuery();
	const conditions = useConditions();
	const graphQLVariables = useListGraphQLVariables(rowsPerPage, query.page, conditions);
	const permissions = usePermissions();
	if (!permissions.includes(Permission.OrderListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooGraphQLList
			showAddButton={permissions.includes(Permission.OrderAdd)}
			filterNode={
				<>
					<MooBranchProvider value={Branch.ADMIN} setValue={() => {}}>
						<ListSelectField
							id="employees"
							resource="employees"
							label="業務"
							value={query.sales_id}
							onChange={(sales_id) => setQuery({ sales_id, page: 0 })}
						/>
					</MooBranchProvider>
					<ListSelectField
						id="customers"
						resource="customers"
						label="客戶"
						value={query.customer_id}
						onChange={(customer_id) => setQuery({ customer_id, page: 0 })}
					/>
					<DateFilter field="from_date" store={store} label="開始時間" />
					<DateFilter field="to_date" store={store} label="結束時間" />
				</>
			}
			clear={store.clear}
			query={query}
			setQuery={store.setQuery}
			onRowClick={onRowClick}
			addLabel="新增訂購單"
			onAdd={onAdd}
			headers={headers}
			cells={cells}
			rowsPerPage={rowsPerPage}
			operationName={operationName}
			mapData={mapData}
			graphQLQuery={graphQLQuery}
			variables={graphQLVariables}
			aggregateNode={(data) => {
				return (
					<div style={{ marginRight: 16 }}>
						總訂單金額: <DollarNumber>{data.orders_aggregate.aggregate.sum.total_price || 0}</DollarNumber>
					</div>
				);
			}}
		/>
	);
}
