import { useSnackbar } from "notistack";

export function useError() {
	const { enqueueSnackbar } = useSnackbar();

	return (message: string) => {
		enqueueSnackbar(message, {
			variant: "error",
		});
	};
}
