import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { MooWarehouseOne } from "../moo-warehouse-one";

type Props = {
	itemIds: string[];
	id: string | null;
	onIdChange: (id: string | null) => void;
	showItemIds?: boolean;
};

export function MooWarehouseStockList({ itemIds, id, onIdChange, showItemIds = true }: Props) {
	return (
		<div>
			<MooEnumTypeableSelectField id="warehouses" resource="warehouses" label="倉庫" value={id} onChange={onIdChange} />
			{id && (
				<div style={{ marginTop: 16 }}>
					<MooWarehouseOne showItemIds={showItemIds} id={id} itemIds={itemIds} />
				</div>
			)}
		</div>
	);
}
