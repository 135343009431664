import { gql } from "@apollo/client";

export const query = gql`
	query ($item_specs_where: item_specs_bool_exp, $item_customs_where: item_customs_bool_exp) {
		item_specs(where: $item_specs_where, order_by: [{ create_date: asc }]) {
			id
			name
		}
		item_customs(where: $item_customs_where, order_by: [{ create_date: asc }]) {
			id
			name
		}
	}
`;
