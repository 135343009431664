import { One } from "..";
import { returnOrder } from "../../moo-enums";
import { Permission, usePermissions } from "../../moo-me-provider";
import { useGetFieldError } from "../../moo-shared";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import FieldContainer from "../components/FieldContainer";
import { useForm } from "../useForm";

export function StateBlock() {
	const { values, setFieldValue } = useForm();
	const getFieldError = useGetFieldError<One>();
	const permissions = usePermissions();

	return (
		<FieldContainer>
			<MooTypeableSelect
				id="state_accountant"
				label="狀態"
				value={values.state_accountant as string}
				error={getFieldError("state_accountant")}
				disabled={!permissions.includes(Permission.ReturnOrderStateWrite)}
				options={[
					{ value: null, label: "請選擇" },
					{
						value: returnOrder.StateAccountant.Value.UnConfirmed,
						label: returnOrder.StateAccountant.Label.UnConfirmed,
					},
					{
						value: returnOrder.StateAccountant.Value.Confirmed,
						label: returnOrder.StateAccountant.Label.Confirmed,
					},
				]}
				onChange={(value) => {
					setFieldValue("state_accountant", value);
				}}
			/>
		</FieldContainer>
	);
}
