import { rowsPerPage } from "../config";
import { useStore } from "./useStore";
import { useWhere } from "./useWhere";

export function useVariables() {
	const { query } = useStore();
	const where = useWhere();
	return {
		limit: rowsPerPage,
		offset: query.page * rowsPerPage,
		where,
		order_by: [
			{
				voucher: {
					voucher_date: "desc",
				},
			},
		],
	};
}
