export { useImageMap, logoImageMap } from "./useImageMap";
export { useFindBranchWarehouses } from "./useFindBranchWarehouses";
export { MooPdfButton } from "./MooPdfButton";
export const pdfFonts = {
	chineseFont: {
		normal: "https://storage.googleapis.com/ya-che-moo-pdf/NotoSansTC-Regular.otf",
		bold: "https://storage.googleapis.com/ya-che-moo-pdf/NotoSansTC-Bold.otf",
		italics: "https://storage.googleapis.com/ya-che-moo-pdf/NotoSansTC-Black.otf",
		bolditalics: "https://storage.googleapis.com/ya-che-moo-pdf/NotoSansTC-Black.otf",
	},
};
export const defaultImage = "https://storage.googleapis.com/ya-che-moo-pdf/1x1_placeholder.png";
export const defaultLogoVertical = "https://storage.googleapis.com/moo-static/logo_v.svg";
