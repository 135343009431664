import styled from "@emotion/styled";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { KodenDropZone } from "@yachelee/koden-components/koden-dropzone";
import { FC, useState } from "react";
import { MooItemImage } from "../../../moo-item-image";

type Props = {
	value: string;
	onUploaded: (value: string) => void;
};

interface ImageContainerProps {
	hasImage: boolean;
}

const ImageContainer = styled.div<ImageContainerProps>`
	position: relative;
	border-radius: 0.25rem;
	border: 1px dotted #c4c4c4;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	& .MuiSvgIcon-root {
		cursor: pointer;
		color: ${({ hasImage }) => (hasImage ? "white" : "#C4C4C4")};
		filter: ${({ hasImage }) => (hasImage ? "drop-shadow(2px 2px 3px rgb(171,171,171))" : "none")};
	}

	& img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	& a {
		width: 100%;
		height: 100%;
	}
`;

export const ImageField: FC<Props> = ({ value, onUploaded }) => {
	const [, setLoading] = useState(false);
	return (
		<ImageContainer hasImage={value !== ""}>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
			>
				<KodenDropZone
					maxInMB={2000}
					image
					video={false}
					multiple={false}
					onFileTooBig={() => {
						window.alert("請上傳檔案小於2GB的圖片。");
					}}
					onFileUploadReady={() => {
						setLoading(true);
					}}
					onFileUploading={() => {
						setLoading(true);
					}}
					onFileUploaded={(obj) => {
						setLoading(false);
						onUploaded(obj.src);
					}}
					onFileUploadFailed={() => {
						setLoading(false);
						window.alert("上傳失敗");
					}}
					onDropRejected={() => {
						window.alert("目前不接受此檔案格式！");
					}}
				>
					{value === "" ? <AddPhotoAlternateOutlinedIcon fontSize="small" /> : <UploadOutlinedIcon fontSize="small" />}
				</KodenDropZone>
			</div>
			{value !== "" && <MooItemImage value={value} cut={value} />}
		</ImageContainer>
	);
};
