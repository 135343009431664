import { object, SchemaOf } from "yup";
import { manufacturer } from "../../moo-enums";
import { enumRequired, numberOptional, stringOptional, stringRequired } from "../../moo-schemas";
import { Item } from "../@types/one";

// @ts-ignore
export const itemSchema: SchemaOf<Item> = object().shape({
	request_buy_item_id: stringRequired("request_buy_item_id"),
	id: stringRequired("id"),
	name: stringOptional,
	image_cut: stringOptional,
	material: stringOptional,
	color: stringOptional,
	size: stringOptional,
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer_id: stringRequired("manufacturer_id"),
	manufacturer: stringOptional,
	count: numberOptional,
	purchase_price: numberOptional,
	purchase_price_total: numberOptional,
});
