import { One } from ".";
import { ItemType, manufacturer, returnGoods, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { Item, VoucherRow } from "./@types/one";

export const DEFAULT_VOUCHER_ROW: VoucherRow = {
	voucher_id: "",
};

export const DEFAULT_Item: Item = {
	return_good_item_id: "",
	id: "",
	manufacturer_type: manufacturer.Value.Value.Domestic,
	manufacturer_id: "",
	manufacturer: "",
	category: "",
	type: ItemType.Spec,
	name: "",
	cost: 0,
	spec: "",
	material: "",
	color: "",
	size: "",
	count: 0,
	price: 0,
	price_final: 0,
	price_total: 0,
	purchase_price: 0,
	purchase_price_total: 0,
	note: "",
	image: "",
	image_cut: "",
	priority: 0,
	return_count: 0,
	warehouse_id: "",
};

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();
	return {
		status: Status.Present,
		state_warehouse: returnGoods.StateWarehouse.Value.WaitToEnter,
		state_accountant: returnGoods.StateAccountant.Value.WaitToRefund,
		return_date: now,
		order_id: null,
		type_id: null,
		type_name: "",
		customer_id: null,
		customer_name: "",
		sales_id: null,
		sales_name: "",
		sales_tel: "",
		deliver_date: now,
		create_date: now,
		update_date: now,
		is_include_tax: false,
		payee_id: null,
		payee_name: "",
		payee_bank: "",
		payee_account_number: "",
		deliver_address: "",
		description: "",
		order_note: "",
		items: [],
		tax_price: 0,
		total_price: 0,
		received: 0,
		receivables: 0,
		handling_fee: 0,
		freight: 0,
		miscellaneous_charge: 0,
		commission_refund: 0,
		note: "",
		memo: "",
		voucher_rows: [],
	};
}
