import { object, SchemaOf } from "yup";
import { stringOptional, stringRequired } from "../../moo-schemas";
import type { Driver } from "../@types/logistics-manufacturer";

// @ts-ignore
export const driverSchema: SchemaOf<Driver> = object().shape({
	manufacturer_logistic_driver_id: stringRequired("manufacturer_logistic_driver_id"),
	name: stringRequired("name"),
	tel: stringOptional,
	line: stringOptional,
});
