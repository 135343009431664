export enum Value {
	Customizing = "CUSTOMIZING",
	Imported = "IMPORTED",
}

export enum Label {
	Customizing = "訂製中",
	Imported = "已進貨",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.Customizing:
			return Label.Customizing;
		case Value.Imported:
			return Label.Imported;
	}
	return Label.Customizing;
}
