import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { DEFAULT_ONE, MooQuotationOne, One } from "../moo-quotation-one";
import { useHasuraAdd } from "../moo-shared";

export type MooQuotationAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooQuotationAdd: FC<MooQuotationAddProps> = ({ onCancel, onSuccess }) => {
	const permissions = usePermissions();
	const onAdd = useHasuraAdd<One>(
		"quotations",
		onSuccess,
		(e) =>
			produce(e, (draft: Partial<One>) => {
				delete draft.type_name;
				delete draft.customer_name;
				delete draft.sales_name;
				delete draft.sales_tel;
				delete draft.payee_name;
				delete draft.payee_bank;
				delete draft.payee_account_number;
				delete draft.assistant_tel;
				delete draft.assistant_name;
				delete draft.price_total;
				delete draft.cost;
				delete draft.remaining_fee;

				draft.items!.forEach((item) => {
					// @ts-ignore
					delete item.type;
					// @ts-ignore
					delete item.category;
					// @ts-ignore
					delete item.manufacturer_type;
					// @ts-ignore
					delete item.manufacturer_id;
					// @ts-ignore
					delete item.manufacturer;
					// @ts-ignore
					delete item.name;
					// @ts-ignore
					delete item.image;
					// @ts-ignore
					delete item.image_cut;
					// @ts-ignore
					delete item.material;
					// @ts-ignore
					delete item.color;
					// @ts-ignore
					delete item.size;
					// @ts-ignore
					delete item.purchase_price;
					// @ts-ignore
					delete item.price;
					// @ts-ignore
					delete item.cost;
					// @ts-ignore
					delete item.spec;
					// @ts-ignore
					delete item.price_total;
				});
			}),
		true,
	);

	return (
		<MooQuotationOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.QuotationAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
		/>
	);
};
