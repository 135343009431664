import { object, SchemaOf } from "yup";
import { stringOptional, stringRequired } from "../../moo-schemas";
import { ItemInfo } from "../@types/one";

// @ts-ignore
export const itemInfoSchema: SchemaOf<ItemInfo> = object().shape({
	request_buy_item_info_id: stringRequired("request_buy_item_info_id"),
	website: stringOptional,
	image: stringOptional,
});
