import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "request_date",
			label: "請款日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "requester",
			label: "請款人",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
		{
			id: "reason",
			label: "用途說明",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "amount",
			label: "請款金額",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "accountant",
			label: "會計",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "state_accountant",
			label: "會計狀態",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state_manager",
			label: "主管狀態",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state_requester",
			label: "請款人簽收",
			align: "left",
			style: {
				minWidth: 110,
			},
		},
	];
}
