import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { One } from "..";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { MooEmployeeSelectField } from "../../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../../moo-enum-typeable-select-field";
import { defectiveProduct } from "../../moo-enums";
import { MooImageListCrud } from "../../moo-image-list-crud";
import { useGetFieldError } from "../../moo-shared";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import { Edit } from "../@types/props";
import FieldContainer from "../components/FieldContainer";
import ItemList from "../components/ItemList/ItemList";
import { useForm } from "../hooks/useForm";
import { useLockStock } from "../hooks/useLockStock";
import { useProps } from "../hooks/useProps";

export function BasicBlock() {
	const { isEdit, ...props } = useProps();
	const { values, setFieldValue } = useForm();
	const getFieldError = useGetFieldError<One>();
	const lockStock = useLockStock();

	return (
		<>
			<FieldContainer>
				<KodenDateField
					id="found_date"
					label="發現日期"
					error={getFieldError("found_date")}
					value={new Date(values.found_date)}
					onChange={(value) => {
						setFieldValue(
							"found_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						);
					}}
				/>
			</FieldContainer>
			<FieldContainer>
				<MooEmployeeSelectField
					id="owner_id"
					label="負責人"
					value={values.owner_id}
					error={getFieldError("owner_id")}
					onChange={(value) => {
						setFieldValue("owner_id", value);
					}}
				/>
				<MooEnumTypeableSelectField
					id="found_warehouse_id"
					resource="warehouses"
					label="發現倉庫"
					disabled={lockStock}
					value={values.found_warehouse_id}
					error={getFieldError("found_warehouse_id")}
					onChange={(value) => {
						setFieldValue("found_warehouse_id", value);
					}}
				/>
				<MooTypeableSelect
					id="handle_method"
					label="處理方式"
					value={values.handle_method}
					error={getFieldError("handle_method")}
					options={[
						{
							value: defectiveProduct.HandleMethod.Value.MANUFACTURER_MAINTAIN,
							label: defectiveProduct.HandleMethod.Label.MANUFACTURER_MAINTAIN,
						},
						{
							value: defectiveProduct.HandleMethod.Value.RETURN_TO_ORIGINAL_MANUFACTURER,
							label: defectiveProduct.HandleMethod.Label.RETURN_TO_ORIGINAL_MANUFACTURER,
						},
						{
							value: defectiveProduct.HandleMethod.Value.SCRAP,
							label: defectiveProduct.HandleMethod.Label.SCRAP,
						},
						{
							value: defectiveProduct.HandleMethod.Value.OTHERS,
							label: defectiveProduct.HandleMethod.Label.OTHERS,
						},
					]}
					onChange={(value) => setFieldValue("handle_method", value)}
				/>
				<ProxyKodenTextField
					id="note"
					label="備註"
					outlined
					margin="none"
					value={values.note}
					error={getFieldError("note")}
					onChange={(value) => {
						setFieldValue("note", value);
					}}
				/>
			</FieldContainer>
			<ItemList isEdit={isEdit} />
			{isEdit && (
				<FieldContainer>
					<div style={{ gridColumn: "1 / -1" }}>
						<h3>瑕疵圖片</h3>
						<MooImageListCrud parentId={(props as Edit).id} resource="defective_product_images" />
					</div>
				</FieldContainer>
			)}
		</>
	);
}
