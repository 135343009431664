import { Item } from "../../moo-import-goods-one";
import { Calculator } from "../../moo-quotation-one";
import { RowItem } from "./RowItem";

export function mapperItems(items: RowItem[]): Item[] {
	return items.map(({ import_good_item_id, id, count, item_spec, item_custom }, priority) => {
		const item = item_spec || item_custom;
		const purchase_price = item.purchase_price;

		return {
			import_good_item_id,
			id,
			manufacturer_type: item.manufacturer.type,
			manufacturer_id: item.manufacturer.id,
			manufacturer: item.manufacturer.name,
			name: item.name,
			material: item.material,
			color: item.color,
			size: item.size,
			count,
			purchase_price,
			purchase_price_total: Calculator.getItemPurchasePriceTotal({ purchase_price, count }),
			image: item.image,
			image_cut: item.image_cut,
			priority,
		};
	});
}
