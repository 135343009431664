import { FC, Fragment } from "react";
import { DEFAULT_ONE, MooEmployeeOne } from "../moo-employee-one";
import { Status } from "../moo-enums";
import { Permission, usePermissions } from "../moo-me-provider";
import { useSuccess } from "../moo-notification-provider";
import { mapEmployeeToForm } from "./mapEmployeeToForm";
import { useFindOne } from "./useFindOne";
import { useUpdateEmployeePassword } from "./useUpdateEmployeePassword";
import { useUpdateOne } from "./useUpdateOne";

export type MooEmployeeUpdateProps = {
	id: string;
	onCancel: () => void;
	onSuccess: () => void;
};

export const MooEmployeeUpdate: FC<MooEmployeeUpdateProps> = ({ id, onCancel, onSuccess }) => {
	const success = useSuccess();
	const record = useFindOne(id);
	const permissions = usePermissions();
	const updateEmployeePassword = useUpdateEmployeePassword();
	const queryOperationName = "employees_by_pk";
	const updateOne = useUpdateOne(DEFAULT_ONE(), id, onSuccess, queryOperationName);

	if (record.isLoading) {
		return <Fragment />;
	} else {
		// @ts-ignore
		if (record.data[queryOperationName] === null) {
			return <div>找不到這筆資料</div>;
		} else {
			// @ts-ignore
			if (record.data[queryOperationName].status !== Status.Present) {
				return <div>此資料已被刪除</div>;
			}
		}
	}
	// @ts-ignore
	const initialValues = mapEmployeeToForm(record.data[queryOperationName]);

	return (
		<MooEmployeeOne
			id={id}
			isEdit
			isCancelDisabled={record.isLoading}
			isOkDisabled={record.isLoading || !permissions.includes(Permission.EmployeeUpdate)}
			onCancel={onCancel}
			initialValues={initialValues}
			onSubmit={async (one, helpers) => {
				await updateOne(initialValues, one, helpers);
			}}
			onChangePassword={async (newPassword) => {
				await updateEmployeePassword(id, newPassword);
				success("密碼更新成功!");
			}}
			okText="儲存"
		/>
	);
};
