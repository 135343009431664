import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class StockTransferTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "stock_transfers";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "owner_id":
			case "state":
			case "source_warehouse_id":
			case "target_warehouse_id":
				return true;
		}
		return false;
	}
}
