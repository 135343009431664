import { gql } from "@apollo/client";
import { useGraphQLMutation, useGraphQLQuery as useGraphQLProviderQuery } from "../moo-graphql-provider";
import { useError, useSuccess } from "../moo-notification-provider";

export function useMutateGraphQLField(
	resource: string,
	operationName: string,
	query: any,
	variables: Record<string, any>,
	columnName: string,
) {
	const mutation = gql`
      mutation ($pk_columns: ${resource}_pk_columns_input!, $set: ${resource}_set_input) {
          update_${resource}_by_pk(pk_columns: $pk_columns, _set: $set) {
              id
          }
      }
	`;

	const success = useSuccess();
	const error = useError();
	const graphQLMutation = useGraphQLMutation({ mutation });
	const { refetch } = useGraphQLProviderQuery({ operationName, query, variables });

	return async ({ id, value }: { id: string; value: any }) => {
		graphQLMutation
			.mutateAsync({
				pk_columns: {
					id,
				},
				set: {
					[columnName]: value,
				},
			})
			.then(() => {
				success("更新成功！");
				refetch();
			})
			.catch(() => {
				error("更新失敗！");
			});
	};
}
