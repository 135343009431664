import styled from "@emotion/styled";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { useForm } from "../useForm";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const HighlightFieldOuterContainer = styled.div`
	background-color: #f2f3f5;
`;

const HighlightFieldInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};
	background: #f2f3f5;
	border-radius: 0.2rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.5rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: unset;
	}
`;

export function SummaryBlock() {
	const { values, setFieldValue } = useForm();

	return (
		<HighlightFieldOuterContainer>
			<HighlightFieldInnerContainer>
				<KodenNumberField
					id="total_price"
					value={values.total_price}
					label="合計"
					hasDollar
					outlined
					margin="none"
					error=""
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					readOnly
					disabled
					onChange={() => {}}
				/>
				<KodenNumberField
					id="tax"
					value={values.tax}
					label="稅金"
					hasDollar
					outlined
					margin="none"
					error=""
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					onChange={(tax) => {
						setFieldValue("tax", tax);
					}}
				/>
				<TextFieldEnd
					id="note_price"
					label="備註"
					outlined
					margin="none"
					value={values.note_price}
					error=""
					onChange={(note_price) => {
						setFieldValue("note_price", note_price);
					}}
				/>
			</HighlightFieldInnerContainer>
		</HighlightFieldOuterContainer>
	);
}
