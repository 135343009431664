import { One } from "../../moo-defective-product-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Row): One {
	const items = mapperItems(record.items);

	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		state: record.state,
		found_date: getLocaleString(new Date(record.found_date)),
		owner_id: record.owner_id,
		found_warehouse_id: record.found_warehouse_id,
		handle_method: record.handle_method,
		note: record.note,
		items,
	};
}
