import AddIcon from "@mui/icons-material/Add";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import * as _ from "lodash";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { SpecOrCustomTableRow, useItemDialogChoose } from "../../moo-dialog-item-choose";
import { useOrderDialogChoose } from "../../moo-dialog-order-choose";
import { useFindEmployeeById } from "../../moo-employee-one";
import { MooEmployeeSelectField } from "../../moo-employee-select-field";
import { manufacturer } from "../../moo-enums";
import { MooLinkField } from "../../moo-link-field";
import { useRequestById } from "../../moo-manufacturer-foreign-update";
import { attachItems, getModelItemId, ManufacturerIdSelectField, useGetFieldError } from "../../moo-shared";
import { MooVoucherLinkList } from "../../moo-voucher-link-list";
import { Item, One, OrderId } from "../@types/one";
import FieldContainer from "../components/FieldContainer";
import ImageField from "../components/ImageField";
import ItemList from "../components/ItemList";
import { useAddItemInfo } from "../hooks/useAddItemInfo";
import { useDelItemInfo } from "../hooks/useDelItemInfo";
import { useForm } from "../hooks/useForm";
import { useUpdateItemInfoImage } from "../hooks/useUpdateItemInfoImage";
import { useUpdateItemInfoWebsite } from "../hooks/useUpdateItemInfoWebsite";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dotted #c4c4c4;
	height: 100%;
	width: 100%;
	border-radius: 0.3rem;
	grid-row: 1 / 3;
	grid-column: 2 / 3;
	&:hover {
		background-color: #ebebeb;
		border-style: solid;
	}
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-row: auto;
		grid-column: 1 / 2;
	}
`;

const AddBtn = styled(AddIcon)`
	cursor: pointer;
`;

const WebsiteContainer = styled.div`
	display: grid;
	grid-gap: 0.5rem;
	align-items: center;
	margin-bottom: 1rem;
	grid-template-columns: 1fr 23px;
	grid-template-rows: 56px 100px;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-rows: 56px;
		grid-template-columns: 35px 1fr 100px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: 35px 1fr 100px;
	}
`;

const DeleteBtn = styled(CloseSharpIcon)`
	align-self: center;
	cursor: pointer;
`;

export function BasicBlock() {
	const { initialValues, values, setFieldValue, setValues, errors } = useForm();
	const getFieldError = useGetFieldError<One>();
	const findEmployeeById = useFindEmployeeById();
	const findForeignManufacturerOneById = useRequestById();
	const addItemInfo = useAddItemInfo();
	const delItemInfo = useDelItemInfo();
	const updateItemInfoImage = useUpdateItemInfoImage();
	const updateItemInfoWebsite = useUpdateItemInfoWebsite();
	const { component: orderDialogComponent, open: openOrderDialog } = useOrderDialogChoose(
		"order-dialog-choose",
		(rows) => {
			const ids: OrderId[] = rows.map((e) => ({
				request_buy_order_id_id: getModelItemId({ create_date: initialValues.create_date, item_id: e.id }),
				value: e.id,
			}));
			setFieldValue("order_ids", ids);
		},
		true,
	);
	const { component: itemDialogComponent, open: openItemDialog } = useItemDialogChoose("item-dialog-choose", (rows) => {
		const items: Item[] = attachItems(
			values.items,
			rows.map((e: SpecOrCustomTableRow) => {
				const id = e.id;
				const item: Item = {
					request_buy_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: id }),
					id,
					name: e.name,
					material: e.material,
					image_cut: e.image_cut,
					color: e.color,
					size: e.size,
					manufacturer_type: e.manufacturer_type,
					manufacturer_id: e.manufacturer_id,
					manufacturer: e.manufacturer,
					count: 1,
					purchase_price: e.purchase_price,
					purchase_price_total: e.purchase_price,
				};
				return item;
			}),
		);
		setFieldValue("items", items);
	});

	return (
		<>
			{orderDialogComponent}
			{itemDialogComponent}
			<FieldContainer>
				<KodenDateField
					id="propose_date"
					label="提出日期"
					error={getFieldError("propose_date")}
					value={values.propose_date === null ? null : new Date(values.propose_date)}
					onChange={(value) => {
						setFieldValue(
							"propose_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						);
					}}
				/>
				<MooLinkField
					clickButton={
						<KodenButton
							disabled={false}
							onClick={async () => {
								openOrderDialog();
							}}
						>
							連結訂購單
						</KodenButton>
					}
					label="已連結訂購單"
					values={values.order_ids.map((e) => e.value)}
				/>
			</FieldContainer>
			<FieldContainer>
				<MooEmployeeSelectField
					id="sales_id"
					label="業務"
					value={values.sales_id}
					error={getFieldError("sales_id")}
					onChange={async (sales_id) => {
						if (sales_id === null) {
							setValues({
								...values,
								sales_id,
								sales_tel: "",
							});
						} else {
							const sales = await findEmployeeById(sales_id);
							setValues({
								...values,
								sales_id,
								sales_tel: sales.tel ?? "",
							});
						}
					}}
				/>
				<ProxyKodenTextField
					id="sales_tel"
					label="業務電話"
					outlined
					margin="none"
					value={values.sales_tel}
					readOnly
					disabled
					error=""
				/>
			</FieldContainer>
			<FieldContainer>
				<ManufacturerIdSelectField
					id="foreign_manufacturer_name"
					type={manufacturer.Value.Value.Foreign}
					label="廠商"
					value={values.foreign_manufacturer_id}
					error={getFieldError("foreign_manufacturer_id")}
					onChange={async (foreign_manufacturer_id) => {
						if (foreign_manufacturer_id === null) {
							setValues({
								...values,
								foreign_manufacturer_id: null,
								foreign_manufacturer_tel: null,
								foreign_manufacturer_fax: null,
								foreign_manufacturer_email: null,
							});
						} else {
							const data = (await findForeignManufacturerOneById(foreign_manufacturer_id)).manufacturer_foreigns_by_pk;
							setValues({
								...values,
								foreign_manufacturer_id,
								foreign_manufacturer_tel: _.get(data, "tels.0.tel", ""),
								foreign_manufacturer_fax: data.fax,
								foreign_manufacturer_email: data.email,
							});
						}
					}}
				/>
				<ProxyKodenTextField
					id="foreign_manufacturer_tel"
					label="電話"
					outlined
					margin="none"
					value={values.foreign_manufacturer_tel}
					disabled
					error=""
				/>
				<ProxyKodenTextField
					id="manufacturer_fax"
					label="傳真"
					outlined
					margin="none"
					value={values.foreign_manufacturer_fax}
					disabled
					error=""
				/>
				<ProxyKodenTextField
					id="foreign_manufacturer_email"
					label="Email"
					outlined
					margin="none"
					value={values.foreign_manufacturer_email}
					disabled
					error=""
				/>
			</FieldContainer>
			<div style={{ marginBottom: "1.5rem" }}>
				{values.item_infos.map(({ request_buy_item_info_id, website, image }, index) => {
					const lastItemInfo = index === values.item_infos.length - 1;
					const websiteError = _.get(errors, `item_infos.${index}.website`, "");

					return (
						<WebsiteContainer key={request_buy_item_info_id}>
							<IconContainer>
								{lastItemInfo ? (
									<AddBtn fontSize="small" color="disabled" onClick={() => addItemInfo()} />
								) : (
									<DeleteBtn
										id={`${request_buy_item_info_id}_bin`}
										fontSize="small"
										color="disabled"
										onClick={() => delItemInfo(request_buy_item_info_id)}
									/>
								)}
							</IconContainer>
							<ProxyKodenTextField
								id={`${request_buy_item_info_id}_website`}
								label="商品網頁"
								margin="none"
								value={website}
								error={websiteError}
								outlined
								onChange={(website) => updateItemInfoWebsite(request_buy_item_info_id, website)}
							/>
							<ImageField value={image} onUploaded={(image) => updateItemInfoImage(request_buy_item_info_id, image)} />
						</WebsiteContainer>
					);
				})}
			</div>
			<FieldContainer>
				<ProxyKodenTextField
					id="note"
					label="備註"
					outlined
					value={values.note}
					error={getFieldError("note")}
					onChange={(value) => {
						setFieldValue("note", value);
					}}
				/>
			</FieldContainer>
			<MooVoucherLinkList values={values.voucher_rows.map((e) => e.voucher_id)} />
			<div style={{ marginBottom: "2rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>請購商品</h1>
					<KodenIconTextButton text="新增請購商品" onClick={() => openItemDialog()} />
				</header>
				<ItemList />
			</div>
		</>
	);
}
