import create from "zustand";

interface State {
	accountant: boolean;
	toggleAccountant: () => void;
	blog: boolean;
	toggleBlog: () => void;
	customer: boolean;
	toggleCustomer: () => void;
	paper: boolean;
	togglePaper: () => void;
	employee: boolean;
	toggleEmployee: () => void;
	item: boolean;
	toggleItem: () => void;
	manufacturer: boolean;
	toggleManufacturer: () => void;
	produce: boolean;
	toggleProduce: () => void;
	purchase: boolean;
	togglePurchase: () => void;
	svc: boolean;
	toggleSvc: () => void;
	stock: boolean;
	toggleStock: () => void;
}

export const useNavigationStore = create<State>((set) => ({
	customer: true,
	toggleCustomer: () => set((state) => ({ customer: !state.customer })),
	accountant: true,
	toggleAccountant: () => set((state) => ({ accountant: !state.accountant })),
	blog: true,
	toggleBlog: () => set((state) => ({ blog: !state.blog })),
	paper: true,
	togglePaper: () => set((state) => ({ paper: !state.paper })),
	employee: true,
	toggleEmployee: () => set((state) => ({ employee: !state.employee })),
	item: true,
	toggleItem: () => set((state) => ({ item: !state.item })),
	manufacturer: true,
	toggleManufacturer: () => set((state) => ({ manufacturer: !state.manufacturer })),
	produce: true,
	toggleProduce: () => set((state) => ({ produce: !state.produce })),
	purchase: true,
	togglePurchase: () => set((state) => ({ purchase: !state.purchase })),
	svc: true,
	toggleSvc: () => set((state) => ({ svc: !state.svc })),
	stock: true,
	toggleStock: () => set((state) => ({ stock: !state.stock })),
}));
