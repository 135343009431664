import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { Formik } from "formik";
import * as _ from "lodash";
import styled from "styled-components";
import { useItemDialogChoose } from "../moo-dialog-item-choose";
import { MooEmployeeSelectField } from "../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { useGetFieldError } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { MooUseIsEditContext } from "../moo-use-is-edit";
import { One } from "./@types/one";
import { Props } from "./@types/props";
import ItemList from "./ItemList";
import { PdfButton } from "./PdfButton";
import { StateBlock } from "./StateBlock";
import { useForm } from "./hooks/useForm";
import { useLockStock } from "./hooks/useLockStock";
import { useUpdate } from "./hooks/useUpdate";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.form`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

export const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	max-width: ${WEB_BREAKPOINT};
	margin-bottom: 30px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

function Form({ id, isEdit, isCancelDisabled, isOkDisabled, onCancel }: Omit<Props, "initialValues" | "onSubmit">) {
	const { handleSubmit, values, setFieldValue } = useForm();
	const { addItems } = useUpdate();
	const lockStock = useLockStock();
	const { component, open } = useItemDialogChoose("moo-stock-transfer-item-stock", addItems);
	const getFieldError = useGetFieldError<One>();

	return (
		<Container onSubmit={handleSubmit}>
			{component}
			{isEdit && <StateBlock />}
			<FieldContainer>
				<MooEmployeeSelectField
					id="owner_id"
					label="負責人"
					value={values.owner_id}
					error={getFieldError("owner_id")}
					onChange={(value) => setFieldValue("owner_id", value)}
				/>
				<MooEnumTypeableSelectField
					id="source_warehouse_id"
					resource="warehouses"
					label="發貨倉庫"
					value={values.source_warehouse_id}
					ignoreIds={values.target_warehouse_id ? [values.target_warehouse_id] : []}
					disabled={lockStock}
					error={getFieldError("source_warehouse_id")}
					onChange={(value) => setFieldValue("source_warehouse_id", value)}
				/>
				<MooEnumTypeableSelectField
					resource="warehouses"
					id="target_warehouse_id"
					label="收貨倉庫"
					disabled={lockStock}
					ignoreIds={values.source_warehouse_id ? [values.source_warehouse_id] : []}
					value={values.target_warehouse_id}
					error={getFieldError("target_warehouse_id")}
					onChange={(value) => setFieldValue("target_warehouse_id", value)}
				/>
			</FieldContainer>
			<div style={{ marginBottom: "2rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>庫存商品</h1>
					{!lockStock && (
						<KodenIconTextButton
							text="新增商品"
							disabled={_.isEmpty(values.source_warehouse_id) || _.isEmpty(values.target_warehouse_id)}
							onClick={open}
						/>
					)}
				</header>
				<ItemList />
			</div>
			<ButtonContainer>
				{isEdit && id && <PdfButton id={id} />}
				{!isOkDisabled && (
					<>
						<KodenButton
							style={{ marginRight: "1rem" }}
							disabled={isCancelDisabled}
							onClick={async () => {
								if (window.confirm("確定取消並且回到上一頁？")) {
									onCancel();
								}
							}}
						>
							取消
						</KodenButton>
						<MooSubmitButton permission={Permission.StockTransferUpdate} />
					</>
				)}
			</ButtonContainer>
		</Container>
	);
}

export function MooStockTransferOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.StockTransferRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<MooUseIsEditContext.Provider value={{ isEdit: props.isEdit || false }}>
				<Form {...props} />
			</MooUseIsEditContext.Provider>
		</Formik>
	);
}
