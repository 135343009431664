import * as _ from "lodash";
import { FC } from "react";
import { CellType, HeaderType, MooDialogChoose } from "../moo-dialog-choose";
import { purchaseOrder } from "../moo-enums";
import { getSearchCondition, graphQLColumns } from "../moo-purchase-order-list";
import { getDateFormat } from "../moo-shared";
import { CellRow, Row } from "./@types";
import { useStore } from "./stores/useStore";

export type MooDialogPurchaseOrderChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
};

const arr = [
	{ id: "id", label: "編號" },
	{ id: "owner.name", label: "承辦人" },
	{ id: "foreign_manufacturer_name", label: "國外廠商" },
	{ id: "purchase_deliver_date", label: "交貨日期" },
];

export const headers = arr
	.map((e) => {
		return { ...e, align: "left", style: { minWidth: 65 } };
	})
	.concat([
		{ id: "state", label: "狀態", align: "left", style: { minWidth: 65 } },
		{ id: "state_manager", label: "主管簽核", align: "left", style: { minWidth: 90 } },
		{ id: "state_accountant", label: "會計確認", align: "left", style: { minWidth: 90 } },
	]) as HeaderType[];

export const cells = [
	{
		id: "id",
		label: "編號",
		align: "left",
		component(row: CellRow) {
			return <div>{row.id}</div>;
		},
	},
	{
		id: "owner.name",
		label: "承辦人",
		align: "left",
		component(row: CellRow) {
			return <div>{_.get(row, "owner.name")}</div>;
		},
	},
	{
		id: "foreign_manufacturer_name",
		label: "國外廠商",
		align: "left",
		component(row: CellRow) {
			return <div>{row.foreign_manufacturer.name}</div>;
		},
	},
	{
		id: "purchase_deliver_date",
		label: "交貨日期",
		align: "left",
		component(row: CellRow) {
			return <div>{getDateFormat(row.deliver_date)}</div>;
		},
	},
	{
		id: "state",
		align: "left",
		component(row: CellRow) {
			return <div>{purchaseOrder.State.getLabel(row.state)}</div>;
		},
	},
	{
		id: "state_manager",
		align: "left",
		component(row: CellRow) {
			return <div>{purchaseOrder.StateManager.getLabel(row.state_manager)}</div>;
		},
	},

	{
		id: "state_accountant",
		align: "left",
		component(row: CellRow) {
			return <div>{purchaseOrder.StateAccountant.getLabel(row.state_accountant)}</div>;
		},
	},
] as CellType[];

export const MooDialogPurchaseOrderChoose: FC<MooDialogPurchaseOrderChooseProps> = ({ id, open, onClose, onOk }) => {
	const store = useStore();

	return (
		<MooDialogChoose
			id={id}
			label=""
			title="國外採購單"
			resource="purchase_orders"
			open={open}
			onOk={(dataSet) => {
				onOk(dataSet as Row[]);
				onClose();
			}}
			onClose={onClose}
			where={getSearchCondition(store.search)}
			headers={headers}
			cells={cells}
			store={store}
			graphQLColumns={graphQLColumns}
			isMultiple
		/>
	);
};
