import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../moo-graphql-provider";

type Data = {
	id: string;
	priority: number;
};

export function useUpdatePriorities(resource: string, idName: string) {
	const graphQLRequest = useGraphQLRequest();

	return async (dataList: Data[]) => {
		const parameters = dataList
			.map((_, index) => `$set_${index}: ${resource}_set_input, $where_${index}: ${resource}_bool_exp!`)
			.join(", ");
		const queryBody = dataList
			.map(
				(_, index) => `
			update_${index}:update_${resource}(_set: $set_${index}, where: $where_${index}) {
					affected_rows
			}
		`,
			)
			.join("\n");

		const query = gql`
        mutation (${parameters}){
            ${queryBody}
        }
		`;

		const variables = {};
		dataList.forEach(({ id, priority }, index) => {
			// @ts-ignore
			variables[`set_${index}`] = { priority };
			// @ts-ignore
			variables[`where_${index}`] = {
				[idName]: {
					_eq: id,
				},
			};
		});
		return graphQLRequest({ query, variables });
	};
}
