import { useFormikContext } from "formik";
import * as pdfMake from "pdfmake/build/pdfmake";
import { useCompanyInfo } from "../../../../hooks/useCompanyInfo";
import { pdfFonts, useImageMap } from "../../moo-config";
import { useMeNickName } from "../../moo-me-provider/hooks/useMeNickName";
import { getDateFormat, getDollarNumberWithCommas, getItemSpec } from "../../moo-shared";
import { One } from "../@types/one";

function useBody() {
	const { values } = useFormikContext<One>();

	const itemBody = useItemBody();
	let arr: any[][] = [
		[{ text: "退訂商品", style: "header", colSpan: 8 }, {}, {}, "", "", "", "", ""],
		["編號", "商品編號", "圖片", "品名", "規格", "數量", "售價", "總價"],
	];

	arr = arr.concat(itemBody);

	arr.push([
		{
			table: {
				body: [
					[
						{
							text: "備註",
							bold: true,
							margin: [0, 2],
						},
						{
							text: values.note,
							margin: [0, 2],
						},
					],
				],
			},
			colSpan: 8,
			layout: "noBorders",
		},
		{},
		{},
		"",
		"",
		"",
		"",
		"",
	]);

	arr.push([
		{},
		{},
		{},
		"",
		"",
		"",
		{
			table: {
				widths: ["*", "*"],
				body: [
					[
						{
							stack: [{ text: "合計" }, { text: "稅金" }],
						},
						{
							stack: [
								{ text: getDollarNumberWithCommas(values.total_price) },
								{ text: getDollarNumberWithCommas(values.tax) },
							],
						},
					],
				],
			},
			colSpan: 2,
			layout: "lightHorizontalLines",
		},
		"",
	]);

	arr.push([
		{
			table: {
				body: [
					[
						{
							text: "備註",
							bold: true,
							margin: [0, 2],
						},
						{
							text: values.note_price,
							margin: [0, 2],
						},
					],
				],
			},
			colSpan: 8,
			layout: "noBorders",
		},
		{},
		{},
		"",
		"",
		"",
		"",
		"",
	]);
	return arr;
}

function useItemBody(): any[][] {
	const { values } = useFormikContext<One>();
	return values.items.map((item, index) => {
		return [
			(index + 1).toString(),
			item.id,
			{ image: item.id, width: 80 },
			item.name,
			getItemSpec(item),
			item.count,
			getDollarNumberWithCommas(item.price_final),
			getDollarNumberWithCommas(item.price_total),
		];
	});
}

export function useOnPdfClick() {
	const body = useBody();
	const { values, isValid } = useFormikContext<One>();
	const imageMap = useImageMap({ items: values.items });
	const { address, tel, fax } = useCompanyInfo();
	const nickName = useMeNickName();

	return async (id: string) => {
		if (isValid) {
			const FILE_NAME = `退訂單-${id}`;
			const customer_name = values.order_customer_name;

			const content = [
				{
					style: "defaultTable",
					table: {
						headerRows: 1,
						widths: [80, "*", "auto"],
						body: [
							[
								{ image: "logo", width: 51, height: 64, margin: [0, 10, 0, 0] },
								[
									{ text: address, margin: [0, 18, 0, 3] },
									{ text: `TEL:${tel}`, margin: [0, 3] },
									{ text: `FAX:${fax}`, margin: [0, 3] },
								],
								[
									{
										text: `退訂單 ${id}${nickName}`,
										fontSize: 16,
										bold: true,
										margin: [0, 11, 0, 3],
									},
									{
										columns: [
											{ text: "業務", width: "auto", margin: [0, 3] },
											{ text: values.order_sales_name, width: "*", margin: [0, 3] },
										],
									},
									{
										columns: [
											{ text: "電話", width: "auto", margin: [0, 3] },
											{ text: values.order_sales_tel, width: "*", margin: [0, 3] },
										],
									},
								],
							],
							[
								{
									stack: [
										{ text: "客戶", margin: [0, 3, 0, 3] },
										{ text: "配送地址", margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{ text: customer_name, margin: [0, 3, 0, 3] },
										{ text: values.order_deliver_address, margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{
											columns: [
												{ text: "退訂日期", width: "auto", margin: [0, 3, 0, 3] },
												{ text: getDateFormat(values.return_date), width: "*", margin: [0, 3] },
											],
										},
									],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [{ text: "訂購單號", margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [{ text: values.order_id, margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
						],
					},
					layout: {
						defaultBorder: false,
						hLineColor: "silver",
					},
				},
				{
					style: "defaultTable",
					color: "#444",
					table: {
						widths: [20, 40, 80, 40, "*", 22, 50, 50],
						headerRows: 1,
						body,
					},
					layout: "lightHorizontalLines",
				},
			];

			pdfMake
				.createPdf(
					{
						content,
						images: imageMap,
						styles: {
							header: {
								fontSize: 16,
								bold: true,
							},
							warning: {
								color: "red",
							},
							bigger: {
								fontSize: 15,
								italics: true,
							},
							defaultTable: {
								margin: [0, 5, 0, 5],
							},
							tableHeader: {
								bold: true,
								fontSize: 13,
								color: "grey",
							},
						},
						defaultStyle: {
							columnGap: 15,
							fontSize: 10,
							font: "chineseFont",
						},
					},
					undefined,
					pdfFonts,
				)
				.download(FILE_NAME);
		}
	};
}
