import { object, SchemaOf } from "yup";
import { stringOptional, stringRequired } from "../../moo-schemas";
import type { Account } from "../@types/logistics-manufacturer";

// @ts-ignore
export const accountSchema: SchemaOf<Account> = object().shape({
	manufacturer_logistic_account_id: stringRequired("manufacturer_logistic_account_id"),
	name: stringRequired("name"),
	bank: stringOptional,
	bank_account: stringOptional,
	tax_id: stringOptional,
});
