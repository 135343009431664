import { useFormikContext } from "formik";
import * as pdfMake from "pdfmake/build/pdfmake";
import { useCompanyInfo } from "../../../../hooks/useCompanyInfo";
import { pdfFonts, useFindBranchWarehouses, useImageMap } from "../../moo-config";
import { Employee, useFindEmployeeById } from "../../moo-employee-one";
import { useMeNickName } from "../../moo-me-provider/hooks/useMeNickName";
import { getDateFormat } from "../../moo-shared";
import { One } from "../@types/one";

function useGetItemBody() {
	const { values } = useFormikContext<One>();

	return async (): Promise<any[][]> => {
		return values.items.map((item, index) => {
			return [
				(index + 1).toString(),
				item.id,
				{ image: item.id, width: 80 },
				item.name,
				`${item.material} ${item.color} ${item.size}`,
				item.transfer_count,
			];
		});
	};
}

function useGetBody() {
	const getItemBody = useGetItemBody();

	return async () => {
		let arr: any[][] = [
			[{ text: "調撥商品", style: "header", colSpan: 6 }, {}, "", "", "", ""],
			["編號", "商品編號", "圖片", "品名", "規格", "調撥數量"],
		];

		arr = arr.concat(await getItemBody());

		arr.push([{}, {}, {}, "", "", {}]);
		return arr;
	};
}

export function useOnPdfClick() {
	const getBody = useGetBody();
	const { values, isValid } = useFormikContext<One>();
	const imageMap = useImageMap({ items: values.items });
	const findBranchWarehouses = useFindBranchWarehouses();
	const employeeById = useFindEmployeeById();
	const { address, tel, fax } = useCompanyInfo();
	const nickName = useMeNickName();

	return async (id: string) => {
		const employee: Employee = await employeeById(values.owner_id);
		const warehouses = await findBranchWarehouses();
		if (isValid) {
			const FILE_NAME = `庫存調撥單-${id}`;

			const content = [
				{
					style: "defaultTable",
					table: {
						headerRows: 1,
						widths: [80, "*", "auto"],
						body: [
							[
								{ image: "logo", width: 51, height: 64, margin: [0, 10, 0, 0] },
								[
									{ text: address, margin: [0, 18, 0, 3] },
									{ text: `TEL:${tel}`, margin: [0, 3] },
									{ text: `FAX:${fax}`, margin: [0, 3] },
								],
								[
									{
										text: `庫存調撥單${id}${nickName}`,
										fontSize: 16,
										bold: true,
										margin: [0, 11, 0, 3],
									},
									{
										columns: [
											{ text: "承辦人", width: "auto", margin: [0, 3] },
											{ text: employee?.name || "", width: "*", margin: [0, 3] },
										],
									},
									{
										columns: [
											{ text: "電話", width: "auto", margin: [0, 3] },
											{ text: employee?.tel || "", width: "*", margin: [0, 3] },
										],
									},
								],
							],
							[
								{
									stack: [
										{ text: "調撥日期", margin: [0, 3, 0, 3] },
										{ text: "發貨倉庫", margin: [0, 3, 0, 3] },
										{ text: "收貨倉庫", margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{ text: getDateFormat(values.transfer_date), margin: [0, 3, 0, 3] },
										{
											text: warehouses.find((e) => e.id === values.source_warehouse_id)?.name || "",
											margin: [0, 3, 0, 3],
										},
										{ text: warehouses.find((e) => e.id === values.target_warehouse_id)?.name || "", margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
						],
					},
					layout: {
						defaultBorder: false,
						hLineColor: "silver",
					},
				},
				{
					style: "defaultTable",
					color: "#444",
					table: {
						widths: [20, 60, 80, 40, "*", "*"],
						headerRows: 1,
						body: await getBody(),
					},
					layout: "lightHorizontalLines",
				},
			];

			pdfMake
				.createPdf(
					{
						content,
						images: imageMap,
						styles: {
							header: {
								fontSize: 16,
								bold: true,
							},
							warning: {
								color: "red",
							},
							bigger: {
								fontSize: 15,
								italics: true,
							},
							defaultTable: {
								margin: [0, 5, 0, 5],
							},
							tableHeader: {
								bold: true,
								fontSize: 13,
								color: "grey",
							},
						},
						defaultStyle: {
							columnGap: 15,
							fontSize: 10,
							font: "chineseFont",
						},
					},
					undefined,
					pdfFonts,
				)
				.download(FILE_NAME);
		}
	};
}
