import { MooEmployeeSelectField } from "../moo-employee-select-field";
import { allowance } from "../moo-enums";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { One } from "./@types/one";
import { useForm } from "./hooks/useForm";
import { useGetError } from "./hooks/useGetError";
import { FieldContainer } from "./moo-allowance-one";

type ItemOption = {
	id: allowance.StateAccountant.Value | allowance.StateManager.Value | allowance.StateRequester.Value;
	label: allowance.StateAccountant.Label | allowance.StateManager.Label | allowance.StateRequester.Label;
};

type Item = {
	id: keyof One;
	label: string;
	permission: Permission;
	options: ItemOption[];
};

const arr: Item[] = [
	{
		id: "state_manager",
		label: "主管狀態",
		permission: Permission.AllowanceStateManagerWrite,
		options: [
			{
				id: allowance.StateManager.Value.UNAPPROVED,
				label: allowance.StateManager.Label.UNAPPROVED,
			},
			{
				id: allowance.StateManager.Value.APPROVED,
				label: allowance.StateManager.Label.APPROVED,
			},
		],
	},
	{
		id: "state_accountant",
		label: "會計狀態",
		permission: Permission.AllowanceStateAccountantWrite,
		options: [
			{
				id: allowance.StateAccountant.Value.UNAPPROVED,
				label: allowance.StateAccountant.Label.UNAPPROVED,
			},
			{
				id: allowance.StateAccountant.Value.APPROVED,
				label: allowance.StateAccountant.Label.APPROVED,
			},
		],
	},
	{
		id: "state_requester",
		label: "請款人簽收",
		permission: Permission.AllowanceStateRequesterWrite,
		options: [
			{
				id: allowance.StateRequester.Value.UNSIGNED,
				label: allowance.StateRequester.Label.UNSIGNED,
			},
			{
				id: allowance.StateRequester.Value.SIGNED,
				label: allowance.StateRequester.Label.SIGNED,
			},
		],
	},
];

export function StateBlock() {
	const { values, setFieldValue } = useForm();
	const permissions = usePermissions();
	const getError = useGetError();

	return (
		<FieldContainer>
			<MooEmployeeSelectField
				id="state_accountant_id"
				label="會計"
				value={values.state_accountant_id}
				error={getError("state_accountant_id")}
				onChange={(value) => setFieldValue("state_accountant_id", value)}
			/>
			{arr.map(({ id, label, options, permission }) => (
				<MooTypeableSelect
					key={id}
					id={id}
					label={label}
					value={(values[id] || null) as string | null}
					error={getError(id)}
					disabled={!permissions.includes(permission)}
					options={options.map((e) => {
						return { ...e, value: e.id };
					})}
					onChange={(value) => {
						setFieldValue(id, value);
					}}
				/>
			))}
		</FieldContainer>
	);
}
