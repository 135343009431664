import { MooGqlChildNewTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";

export class Tels extends MooGqlChildNewTable<FindOneRecord> {
	getIdName(): string {
		return "manufacturer_domestic_tel_id";
	}

	getTableName(): string {
		return "manufacturer_domestic_tels";
	}

	getPathName(): string {
		return "tels";
	}

	getParentIdName(): string {
		return "manufacturer_domestic_id";
	}

	getToUpdateColumns(): string[] {
		return ["tel"];
	}
}
