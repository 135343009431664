import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class PurchaseOrderTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "purchase_orders";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "state":
			case "state_manager":
			case "state_accountant":
			case "purchase_date":
			case "deliver_date":
			case "owner_id":
			case "foreign_manufacturer_id":
			case "deliver_method":
			case "warehouse_id":
			case "note":
			case "accountant_tax":
			case "accountant_received":
			case "accountant_freight":
			case "accountant_declare_fee":
			case "accountant_pickup_charge":
			case "accountant_miscellaneous_charge":
			case "accountant_note":
			case "mgt_fee":
				return true;
		}
		return false;
	}
}
