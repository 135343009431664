import { DocumentNode } from "@apollo/client";
import { useMutation } from "react-query";
import { useGraphQLRequest } from "./useGraphQLRequest";

type Props = {
	mutation: DocumentNode;
};

type Variables = Record<string, any>;

export function useGraphQLMutation({ mutation }: Props) {
	const graphQLRequest = useGraphQLRequest();

	return useMutation((variables: Variables) => {
		return graphQLRequest({ query: mutation, variables });
	});
}
