import { array, object, SchemaOf } from "yup";
import { defectiveProduct } from "../../moo-enums";
import { enumRequired, statusSchema, stringOptional, stringRequired } from "../../moo-schemas";
import { One } from "../@types/one";
import { itemSchema } from "./itemSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: statusSchema,
	state: enumRequired(defectiveProduct.State.Value),
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	found_date: stringOptional,
	owner_id: stringRequired("owner_id"),
	found_warehouse_id: stringRequired("found_warehouse_id"),
	handle_method: enumRequired(defectiveProduct.HandleMethod.Value),
	note: stringOptional,
	items: array(itemSchema).test("items", "尚未匯入商品", (items) => {
		// @ts-ignore
		return items.length >= 1;
	}) as any,
});
