import { ReactNode, useState } from "react";
import { MooDialogWeeklyScheduleChoose as MooDialogLinkChoose, Row, useStore } from "./";

type ReturnType = {
	component: ReactNode;
	open: () => void;
	close: () => void;
};

export function useChoose(id: string, onOk: (row: Row[]) => void): ReturnType {
	const { clearChoose } = useStore();
	const [open, setOpen] = useState(false);
	const close = () => setOpen(false);

	const component = (
		<MooDialogLinkChoose
			id={id}
			open={open}
			onClose={close}
			onOk={async (row) => {
				onOk(row);
				close();
			}}
		/>
	);

	return {
		component,
		open: () => {
			clearChoose();
			setOpen(true);
		},
		close,
	};
}
