import { ReactNode, useRef } from "react";
import Utils from "../../utils";
import { Branch, MooBranchProvider } from "../moo-components/moo-branch-provider";
import { getAttachedBranch } from "./getAttachedBranch";
import { useBranchInQuery } from "./hooks/useBranchInQuery";

function BranchProvider({ children }: { children: ReactNode }) {
	const branchInQuery = useBranchInQuery();
	const fromBranch = useRef<Branch | null>(branchInQuery);

	return (
		<MooBranchProvider
			value={branchInQuery}
			setValue={async (toBranch) => {
				if (toBranch === Branch.ADMIN) {
					//any route to admin branch, directly go to the admin home
					Utils.locationHref(`/?branch=${Branch.ADMIN}`);
				} else if (fromBranch.current === Branch.ADMIN) {
					//from admin route to some other route, directly go to the main home
					Utils.locationHref(`/?branch=${toBranch}`);
				} else {
					Utils.locationHref(getAttachedBranch(toBranch as Branch));
				}
				fromBranch.current = toBranch;
			}}
		>
			{children}
		</MooBranchProvider>
	);
}

export default BranchProvider;
