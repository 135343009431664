import { Permission } from "../moo-me-provider";

export const allAdminPermissions: Permission[] = [
	Permission.RoleListRead,
	Permission.RoleRead,
	Permission.RoleAdd,
	Permission.RoleUpdate,
	Permission.EmployeeTypeWrite,
	Permission.EmployeeListRead,
	Permission.EmployeeRead,
	Permission.EmployeeAdd,
	Permission.EmployeeUpdate,
	Permission.EmployeeSalaryRead,
	Permission.EmployeeSalaryWrite,
	Permission.EmployeeAssignRole,
];
