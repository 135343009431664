import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "number",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "type",
			label: "類型",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "name",
			label: "廠商名稱",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "tel",
			label: "電話",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "email",
			label: "Email",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "address",
			label: "地址",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "tax",
			label: "統編",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "delete",
			label: "刪除",
			align: "center",
		},
	];
}
