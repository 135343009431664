import { FC } from "react";
import { DEFAULT_ONE, MooEmployeeOne, useGetUsersByEmail } from "../moo-employee-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useError } from "../moo-notification-provider";
import { useOnAdd } from "./useOnAdd";

export type MooEmployeeAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooEmployeeAdd: FC<MooEmployeeAddProps> = ({ onCancel, onSuccess }) => {
	const permissions = usePermissions();
	const onAdd = useOnAdd({ onSuccess });
	const getUsersByEmail = useGetUsersByEmail();
	const notifyError = useError();

	return (
		<MooEmployeeOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.EmployeeAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={async (values, helpers) => {
				const users = await getUsersByEmail(values.email);
				if (users.length > 0) {
					notifyError("這組email已被使用！");
				} else {
					onAdd(values, helpers);
				}
			}}
			okText="送出"
		/>
	);
};
