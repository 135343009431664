import { object, SchemaOf } from "yup";
import { numberOptional, stringRequired } from "../../moo-schemas";
import { ServiceItem } from "../@types/one";

// @ts-ignore
export const itemSchema: SchemaOf<ServiceItem> = object().shape({
	svc_item_id: stringRequired("svc_item_id"),
	name: stringRequired("name"),
	count: numberOptional,
	price: numberOptional,
});
