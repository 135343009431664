import { useBranch } from "../../moo-branch-provider";
import { Query } from "../@types";

export function useConditions({ search }: Query) {
	const { value: branch } = useBranch();

	const conditions: Record<string, any> = {
		branch_id: {
			_eq: branch,
		},
	};

	if (search !== null && search.trim() !== "") {
		const _similar = `%(${search.trim().split(" ").join("|")})%`;
		conditions._or = [
			{
				id: {
					_similar,
				},
			},
		];
	}

	return conditions;
}
