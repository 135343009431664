import { object, SchemaOf } from "yup";
import { Branch } from "../../moo-branch-provider";
import { Permission } from "../../moo-me-provider";
import { enumRequired, stringRequired } from "../../moo-schemas";
import { RolePermission } from "../@types/one";

// @ts-ignore
export const permissionSchema: SchemaOf<RolePermission> = object().shape({
	role_permission_id: stringRequired("role_permission_id"),
	branch_id: enumRequired(Branch),
	permission: enumRequired(Permission),
});
