import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooCustomerList, Query, useCreateStore } from "../../components/moo-components/moo-customer-list";
import { useHistoryQuery } from "../../hooks/useHistoryQuery";
import useQuery from "../../hooks/useQuery";
import { path as NewCustomerRoutePath } from "./NewRoute";

export const path = "/customer";

export const ListRoute = () => {
	const branch = useBranchInQuery();
	const navigate = useNavigate();
	const defaultQuery = useQuery();
	const store = useCreateStore(defaultQuery, {
		search: null,
		type: null,
		page: 0,
	});
	useHistoryQuery<Query>({ path, store });

	return (
		<MooCustomerList
			store={store}
			onAdd={async () => {
				await navigate(`${NewCustomerRoutePath}?branch=${branch}`);
			}}
			onRowClick={async (record, event) => {
				event.preventDefault();
				await navigate(`${path}/${record.id}?branch=${branch}`);
			}}
		/>
	);
};

export default ListRoute;
