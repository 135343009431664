import { KodenButton } from "@yachelee/koden-components/koden-button";
import * as jsonPatch from "fast-json-patch";
import { useFormikContext } from "formik";
import { Permission, usePermissions } from "../moo-me-provider";

type Props = {
	disabled?: boolean;
	permission?: Permission;
};

export function MooSubmitButton({ disabled = false, permission }: Props) {
	const { isSubmitting, isValid, initialValues, values } = useFormikContext();
	// @ts-ignore
	const dataDiff = jsonPatch.compare(initialValues, values);
	const isDataDiff = dataDiff.length !== 0;
	const isSubmitDisabled = isSubmitting || !isValid || disabled || !isDataDiff;
	const permissions = usePermissions();
	if (permission && !permissions.includes(permission)) {
		return null;
	}

	return (
		<KodenButton type="submit" disabled={isSubmitDisabled}>
			{isSubmitting ? "儲存中..." : "儲存"}
		</KodenButton>
	);
}
