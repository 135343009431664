import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class RequestBuyTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "request_buys";
	}
	isColumn(column: string): boolean {
		switch (column) {
			case "state_purchase":
			case "sales_id":
			case "foreign_manufacturer_id":
			case "note":
				return true;
		}
		return false;
	}
}
