import { gql } from "@apollo/client";
import { FormikHelpers } from "formik";
import produce from "immer";
import * as _ from "lodash";
import { useBranch } from "../moo-branch-provider";
import { useGraphQLMutation } from "../moo-graphql-provider";
import { useMe } from "../moo-me-provider";
import { getLocaleString } from "./getLocaleString";

type onSuccess = (id: string) => void;
type ReturnType<T> = (one: T, helpers: FormikHelpers<T>) => void;

function getMutation(objectName: string, mutationName: string) {
	return gql`
      mutation($object: ${objectName}) {
          ${mutationName}(object: $object){
              id
          }
      }
	`;
}

function useGetVariables<T extends { create_date?: Date | string }>(includesEmployeeId: boolean = false) {
	const employeeId = useMe()!.employee.id;
	const { value: branch } = useBranch();

	return (toUpdateValue: T) => {
		const nowTime = getLocaleString(new Date());
		const object: Record<string, any> = {
			...toUpdateValue,
			branch_id: branch,
			update_date: nowTime,
			create_date: toUpdateValue.create_date || nowTime,
		};

		Object.keys(object).forEach((key) => {
			const data = object[key];
			if (_.isArray(data)) {
				object[key] = {
					data: produce(data, (draft) => {
						draft.forEach((arrayObject) => {
							Object.keys(arrayObject).forEach((key) => {
								if (_.isArray(arrayObject[key])) {
									arrayObject[key] = {
										data: arrayObject[key],
									};
								}
							});
						});
					}),
				};
			}
		});

		if (includesEmployeeId) {
			// @ts-ignore
			object.employee_id = employeeId;
		}

		return { object };
	};
}

export function useHasuraAdd<T extends { create_date?: Date | string }>(
	resource: string,
	onSuccess: onSuccess,
	transform: (one: T) => T = (e) => e,
	includesEmployeeId: boolean = false,
): ReturnType<T> {
	const inputType = `${resource}_insert_input!`;
	const mutationName = `insert_${resource}_one`;
	const mutation = getMutation(inputType, mutationName);
	const graphQLMutation = useGraphQLMutation({ mutation });
	const getVariables = useGetVariables(includesEmployeeId);

	return (values, helpers) => {
		if (window.confirm("是否確定送出?")) {
			helpers.setSubmitting(true);
			values = transform(values);
			graphQLMutation.mutate(getVariables(values), {
				async onSuccess(res) {
					const id = res[mutationName].id as string;
					onSuccess(id);
				},
				async onError(e) {
					console.error(e);
					window.alert("發生錯誤!請聯絡系統管理員");
				},
				onSettled() {
					helpers.setSubmitting(false);
				},
			});
		} else {
			helpers.setSubmitting(false);
		}
	};
}
