import { mapResults } from "./mapResults";

export function mapData({ orders_aggregate, orders }: any) {
	const results = mapResults(orders);

	return {
		results,
		total_count: orders_aggregate.aggregate.count,
	};
}
