import { ItemType, manufacturer, orderGoods, Status } from "../../moo-enums";

export enum Type {
	ImportFromOrder = "IMPORT_FROM_ORDER",
	Directly = "DIRECTLY",
}

export type Instruction = {
	order_good_instruction_id: string;
	value: string;
};

export type Item = {
	order_good_item_id: string;
	id: string;
	manufacturer_type: manufacturer.Value.Value;
	manufacturer_id: string | null;
	manufacturer: string;
	category: string;
	type: ItemType.Custom | ItemType.Spec;
	name: string;
	cost: number;
	spec: string;
	material: string;
	color: string;
	size: string;
	count: number;
	price: number;
	price_final: number;
	price_total: number;
	purchase_price: number;
	purchase_price_total: number;
	note: string;
	image: string | null;
	image_cut: string | null;
	priority: number;
};

export type VoucherRow = {
	voucher_id: string;
};

export type One = {
	status: Status;
	employee: {
		nick_name: string | null;
	} | null;
	create_date: string;
	update_date: string;
	state: orderGoods.State.Value;
	state_warehouse: orderGoods.StateWarehouse.Value;
	type: Type;
	order_goods_date: string | null;
	deliver_goods_date: string | null;
	order_id: string | null;
	order: {
		employee: {
			nick_name: string | null;
		} | null;
	} | null;
	sales_id: string | null;
	sales_name: string;
	sales_tel: string;
	manufacturer_type: manufacturer.Value.Value;
	manufacturer_id: string | null;
	manufacturer_name: string;
	manufacturer_tel: string;
	manufacturer_fax: string;
	manufacturer_email: string;
	warehouse_id: string | null;
	note: string;
	items: Item[];
	instructions: Instruction[];
	voucher_rows: VoucherRow[];
};
