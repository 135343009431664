import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class Rows extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "voucher_row_id";
	}

	getTableName(): string {
		return "voucher_rows";
	}

	getPathName(): string {
		return "rows";
	}

	getParentIdName(): string {
		return "voucher_id";
	}

	getToUpdateColumns(): string[] {
		return [
			"credit_amount",
			"debit_amount",
			"link_id",
			"account_type_id",
			"summary",
			"type",
			"manufacturer_type",
			"manufacturer_id",
			"order",
		];
	}
}
