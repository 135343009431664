import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as manufacturerDomesticListPath } from "../../../routes/DomesticManufacturerListRoute";
import { path as manufacturerForeignListPath } from "../../../routes/ForeignManufacturerListRoute";
import { path as manufacturerLogisticsListPath } from "../../../routes/LogisticsManufacturerListRoute";
import { path as manufacturerTypeOneRoutePath } from "../../../routes/ManufacturerTypeOneRoute";
import { path as manufacturerTypesPath } from "../../../routes/ManufacturerTypesRoute";
import { path as NewDomesticManufacturerRoutePath } from "../../../routes/NewDomesticManufacturerRoute";
import { path as NewForeignManufacturerRoutePath } from "../../../routes/NewForeignManufacturerRoute";
import { path as NewLogisticsManufacturerRoutePath } from "../../../routes/NewLogisticsManufacturerRoute";
import { path as manufacturerDomesticUpdatePath } from "../../../routes/UpdateDomesticManufacturerRoute";
import { path as manufacturerForeignUpdatePath } from "../../../routes/UpdateForeignManufacturerRoute";
import { path as manufacturerLogisticsUpdatePath } from "../../../routes/UpdateLogisticsManufacturerRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useManufacturers(): ListType[] {
	const historyPushWithBranch = useHistoryPushWithBranch();
	const permissions = usePermissions();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();
	const domesticList = permissions.includes(Permission.ManufacturerDomesticListRead);
	const foreignList = permissions.includes(Permission.ManufacturerForeignListRead);
	const logisticsList = permissions.includes(Permission.ManufacturerLogisticsListRead);
	const typeList = permissions.some(
		(e) => e === Permission.ManufacturerDomesticTypeWrite || e === Permission.ManufacturerForeignTypeWrite,
	);
	if (!domesticList && !foreignList && !logisticsList && !typeList) {
		return [];
	}

	const children: any[] = [];
	if (domesticList) {
		children.push({
			id: manufacturerDomesticListPath,
			txt: getPageTree()[manufacturerDomesticListPath].useText(),
			icon: "",
			isSelected: isSelected([
				manufacturerDomesticListPath,
				manufacturerDomesticUpdatePath,
				NewDomesticManufacturerRoutePath,
			]),
			async onClick() {
				await historyPushWithBranch(manufacturerDomesticListPath);
			},
		});
	}

	if (foreignList) {
		children.push({
			id: manufacturerForeignListPath,
			txt: getPageTree()[manufacturerForeignListPath].useText(),
			icon: "",
			isSelected: isSelected([
				manufacturerForeignListPath,
				manufacturerForeignUpdatePath,
				NewForeignManufacturerRoutePath,
			]),
			async onClick() {
				await historyPushWithBranch(manufacturerForeignListPath);
			},
		});
	}

	if (logisticsList) {
		children.push({
			id: manufacturerLogisticsListPath,
			txt: getPageTree()[manufacturerLogisticsListPath].useText(),
			icon: "",
			isSelected: isSelected([
				manufacturerLogisticsListPath,
				manufacturerLogisticsUpdatePath,
				NewLogisticsManufacturerRoutePath,
			]),
			async onClick() {
				await historyPushWithBranch(manufacturerLogisticsListPath);
			},
		});
	}

	if (typeList) {
		children.push({
			id: manufacturerTypesPath,
			txt: getPageTree()[manufacturerTypesPath].useText(),
			icon: "",
			isSelected: isSelected([manufacturerTypesPath, manufacturerTypeOneRoutePath]),
			async onClick() {
				await historyPushWithBranch(manufacturerTypesPath);
			},
		});
	}

	if (children.length === 0) {
		return [];
	}

	return [
		{
			id: "manufacturer",
			txt: "廠商",
			icon: Utils.getAbsoluteStaticUrl("icons/manufacturer.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleManufacturer();
			},
			open: true,
			openChildren: navigationStore.manufacturer,
			children,
		},
	];
}
