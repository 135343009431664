import { createContext } from "react";
import { OnClick } from "./types";

type Context = {
	onLinkClick: OnClick;
};

export const LinkContext = createContext<Context>({
	onLinkClick() {
		console.log("onLinkClick not implemented");
	},
});
