import { FC } from "react";
import { MooEnumTypeableSelectField } from "../../../moo-enum-typeable-select-field";
import { manufacturer } from "../../../moo-enums";

type Props = {
	id?: string;
	type: manufacturer.Value.Value;
	value: string | null;
	label?: string;
	error?: string;
	onChange: (value: string | null) => void;
};

export const ManufacturerIdSelectField: FC<Props> = ({
	id = "manufacturer_id",
	label = "廠商",
	type,
	value,
	error = "",
	onChange,
}) => {
	return (
		<MooEnumTypeableSelectField
			id={id}
			resource={type === manufacturer.Value.Value.Domestic ? "manufacturer_domestics" : "manufacturer_foreigns"}
			label={label}
			value={value}
			error={error}
			onChange={onChange}
		/>
	);
};
