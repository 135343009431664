import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { Formik } from "formik";
import * as _ from "lodash";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { useOrderDialogChoose } from "../moo-dialog-order-choose";
import { useFindEmployeeById } from "../moo-employee-one";
import { MooEmployeeSelectField } from "../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { manufacturer, orderGoods } from "../moo-enums";
import { MooLinkButton } from "../moo-link-button";
import { useRequestById as useFindDomesticById } from "../moo-manufacturer-domestic-update";
import { useRequestById as useFindForeignById } from "../moo-manufacturer-foreign-update";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { ManufacturerIdSelectField, ManufacturerTypeSelectField, useGetFieldError } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { MooVoucherLinkList } from "../moo-voucher-link-list";
import { One } from "./@types/one";
import { Props } from "./@types/props";
import { ChooseItemArea } from "./components/ChooseItemArea";
import FileUploadArea from "./components/FileUploadArea/FileUploadArea";
import { PdfButton } from "./components/PdfButton";
import { useChooseOrder } from "./hooks/useChooseOrder";
import { useForm } from "./hooks/useForm";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

const FileUpload = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 0.3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.6rem;
	align-items: center;
	padding: 14px;
	min-height: 56px;
	text-align: center;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto 1fr;
		grid-column-end: span 2;
		text-align: left;
		padding: 0 14px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	max-width: ${WEB_BREAKPOINT};
	margin-bottom: 30px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

function MooOrderGoodsOneForm({
	id,
	isEdit = false,
	isCancelDisabled,
	isOkDisabled,
	onCancel,
}: Omit<Props, "initialValues" | "onSubmit">) {
	const chooseOrder = useChooseOrder();
	const findEmployeeById = useFindEmployeeById();
	const findDomesticManufacturerOneById = useFindDomesticById();
	const findForeignManufacturerOneById = useFindForeignById();
	const { handleSubmit, values, setFieldValue, setValues } = useForm();
	const getFieldError = useGetFieldError<One>();
	const { open: openDialog, component } = useOrderDialogChoose("order-dialog-choose-2", chooseOrder, false);
	const orderImported = values.order_id !== null;

	return (
		<form onSubmit={handleSubmit}>
			{component}
			<Container>
				{isEdit && (
					<FieldContainer>
						<MooTypeableSelect
							id="state"
							label="訂貨單狀態"
							value={values.state}
							error={getFieldError("state")}
							options={[
								{
									label: orderGoods.State.Label.Customizing,
									value: orderGoods.State.Value.Customizing,
								},
								{
									label: orderGoods.State.Label.Imported,
									value: orderGoods.State.Value.Imported,
								},
							]}
							onChange={(value) => {
								setFieldValue("state", value);
							}}
						/>
						<MooTypeableSelect
							id="state_warehouse"
							label="倉管狀態"
							value={values.state_warehouse}
							error={getFieldError("state_warehouse")}
							options={[
								{
									label: orderGoods.StateWarehouse.Label.UnConfirmed,
									value: orderGoods.StateWarehouse.Value.UnConfirmed,
								},
								{
									label: orderGoods.StateWarehouse.Label.Confirmed,
									value: orderGoods.StateWarehouse.Value.Confirmed,
								},
							]}
							onChange={(value) => {
								setFieldValue("state_warehouse", value);
							}}
						/>
					</FieldContainer>
				)}
				<FieldContainer>
					<KodenDateField
						id="order_goods_date"
						label="訂貨日期"
						error={getFieldError("order_goods_date")}
						value={values.order_goods_date === null ? new Date() : new Date(values.order_goods_date)}
						onChange={(value) => {
							setFieldValue(
								"order_goods_date",
								value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
							);
						}}
					/>
					<KodenDateField
						id="deliver_goods_date"
						label="交貨日期"
						error={getFieldError("deliver_goods_date")}
						value={values.deliver_goods_date === null ? new Date() : new Date(values.deliver_goods_date)}
						onChange={(value) => {
							setFieldValue(
								"deliver_goods_date",
								value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
							);
						}}
					/>
					<FileUpload>
						<KodenButton onClick={openDialog}>{orderImported ? "重匯訂購單" : "匯入訂購單"}</KodenButton>
						<MooLinkButton id={values.order_id} name="訂購單" />
					</FileUpload>
				</FieldContainer>
				<FieldContainer>
					<MooEmployeeSelectField
						id="sales_id"
						label="業務"
						value={values.sales_id}
						error={getFieldError("sales_id")}
						onChange={async (sales_id) => {
							if (sales_id === null) {
								setValues({
									...values,
									sales_id,
									sales_name: "",
									sales_tel: "",
								});
							} else {
								const sales = await findEmployeeById(sales_id);
								setValues({
									...values,
									sales_id,
									sales_name: sales.name ?? "",
									sales_tel: sales.tel ?? "",
								});
							}
						}}
					/>
					<ProxyKodenTextField
						id="sales_tel"
						label="業務電話"
						outlined
						margin="none"
						value={values.sales_tel}
						readOnly
						disabled
						error=""
					/>
					<ManufacturerTypeSelectField
						id="manufacturer_type"
						value={values.manufacturer_type}
						error={getFieldError("manufacturer_type")}
						onChange={(manufacturer_type) => {
							setValues({
								...values,
								manufacturer_type,
								manufacturer_id: null,
								manufacturer_name: "",
								manufacturer_tel: "",
								manufacturer_email: "",
							});
						}}
					/>
					<ManufacturerIdSelectField
						id="manufacturer_name"
						type={values.manufacturer_type}
						label="廠商"
						value={values.manufacturer_id}
						error={getFieldError("manufacturer_id")}
						onChange={async (manufacturer_id) => {
							if (manufacturer_id === null) return;

							let data = null;
							switch (values.manufacturer_type) {
								case manufacturer.Value.Value.Domestic:
									data = (await findDomesticManufacturerOneById(manufacturer_id)).manufacturer_domestics_by_pk;
									break;
								case manufacturer.Value.Value.Foreign:
									data = (await findForeignManufacturerOneById(manufacturer_id)).manufacturer_foreigns_by_pk;
									break;
							}
							if (data != null) {
								setValues({
									...values,
									manufacturer_id,
									manufacturer_name: data.name,
									manufacturer_tel: _.get(data, "tels.0.tel", ""),
									manufacturer_fax: data.fax,
									manufacturer_email: data.email,
								});
							}
						}}
					/>
					<ProxyKodenTextField
						id="manufacturer_tel"
						label="電話"
						outlined
						margin="none"
						value={values.manufacturer_tel}
						disabled
						error=""
						onChange={(manufacturer_tel) => {
							setFieldValue("manufacturer_tel", manufacturer_tel);
						}}
					/>
					<ProxyKodenTextField
						id="manufacturer_fax"
						label="傳真"
						outlined
						margin="none"
						value={values.manufacturer_fax}
						disabled
						error=""
						onChange={(manufacturer_fax) => {
							setFieldValue("manufacturer_fax", manufacturer_fax);
						}}
					/>
					<ProxyKodenTextField
						id="manufacturer_email"
						label="Email"
						outlined
						margin="none"
						value={values.manufacturer_email}
						disabled
						error=""
						onChange={(manufacturer_email) => {
							setFieldValue("manufacturer_email", manufacturer_email);
						}}
					/>
					<MooEnumTypeableSelectField
						id="warehouses"
						resource="warehouses"
						label="收貨倉庫"
						value={values.warehouse_id}
						error={getFieldError("warehouse_id")}
						onChange={(warehouse_id) => {
							setFieldValue("warehouse_id", warehouse_id);
						}}
					/>
					<ProxyKodenTextField
						id="customer_name"
						outlined
						disabled
						readOnly
						label="創建人"
						value={values.employee?.nick_name || "未知"}
						error=""
					/>
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<ProxyKodenTextField
							id="note"
							label="備註"
							outlined
							margin="none"
							value={values.note}
							error=""
							onChange={(note) => {
								setFieldValue("note", note);
							}}
						/>
					</div>
				</FieldContainer>
				<MooVoucherLinkList values={values.voucher_rows.map((e) => e.voucher_id)} />
				<ChooseItemArea />
				<FileUploadArea />
				<ButtonContainer>
					{isEdit && id && <PdfButton id={id} />}
					{!isOkDisabled && (
						<>
							<KodenButton
								style={{ marginRight: "1rem" }}
								disabled={isCancelDisabled}
								onClick={async () => {
									if (window.confirm("確定取消並且回到上一頁？")) {
										onCancel();
									}
								}}
							>
								取消
							</KodenButton>
							<MooSubmitButton permission={Permission.OrderGoodsUpdate} />
						</>
					)}
				</ButtonContainer>
			</Container>
		</form>
	);
}

export function MooOrderGoodsOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.OrderGoodsRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<MooOrderGoodsOneForm {...props} />
		</Formik>
	);
}
