import { useContext } from "react";
import { LinkContext } from "./context";

export function useOnLinkClick() {
	const { onLinkClick } = useContext(LinkContext);

	return (link: string) => {
		onLinkClick(link);
	};
}
