import { One } from "../../moo-request-buy-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Row): One {
	const items = mapperItems(record.items);

	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		state_purchase: record.state_purchase,
		propose_date: record.propose_date,
		order_ids: record?.order_ids || [],
		sales_id: record.sales.id,
		sales_tel: record.sales.tel,
		foreign_manufacturer_id: record.foreign_manufacturer.id,
		foreign_manufacturer_tel:
			record.foreign_manufacturer.tels.length === 0 ? "" : record.foreign_manufacturer.tels[0].tel || "",
		foreign_manufacturer_fax: record.foreign_manufacturer.fax,
		foreign_manufacturer_email: record.foreign_manufacturer.email,
		item_infos: record.item_infos,
		note: record.note,
		items,
		voucher_rows: record.voucher_rows,
	};
}
