import { useFormikContext } from "formik";
import _ from "lodash";
import * as pdfMake from "pdfmake/build/pdfmake";
import { useCompanyInfo } from "../../../../hooks/useCompanyInfo";
import { pdfFonts, useImageMap } from "../../moo-config";
import { Customer, DEFAULT_ONE } from "../../moo-customer-one";
import { useMeNickName } from "../../moo-me-provider/hooks/useMeNickName";
import { getDateFormat, getDollarNumberWithCommas, useFindOneById } from "../../moo-shared";
import { One } from "../@types/one";

function useBody() {
	const { values } = useFormikContext<One>();

	const itemBody = useItemBody();
	let arr: any[][] = [
		[{ text: "報價商品", style: "header", colSpan: 8 }, {}, {}, "", "", "", "", ""],
		["編號", "圖片", "品名", "規格", "數量", "售價", "總價", "備註"],
	];

	arr = arr.concat(itemBody);

	arr.push([
		{
			table: {
				widths: ["auto", "*"],
				body: [
					[{ text: "請於匯訂金後通知我們，即刻為您確認訂單", style: "warning", colSpan: 2 }, {}],
					["收款戶名", values.payee_name],
					["收款銀行", values.payee_bank],
					["收款帳號", values.payee_account_number],
				],
			},
			colSpan: 6,
			layout: "noBorders",
		},
		{},
		{},
		"",
		"",
		"",
		{
			table: {
				widths: ["*", "*"],
				body: [
					[
						{
							stack: [{ text: "小計" }, { text: "稅金" }, { text: "成交價", bold: true }],
						},
						{
							stack: [
								{ text: getDollarNumberWithCommas(values.price_total) },
								{ text: getDollarNumberWithCommas(values.tax) },
								{ text: getDollarNumberWithCommas(values.price_discounted), bold: true },
							],
						},
					],
					[
						{
							stack: [
								{ text: "訂金", bold: true },
								{ text: "尾款", bold: true },
							],
						},
						{
							stack: [
								{ text: getDollarNumberWithCommas(values.deposit), bold: true },
								{ text: getDollarNumberWithCommas(values.remaining_fee), bold: true },
							],
						},
					],
				],
			},
			colSpan: 2,
			layout: "lightHorizontalLines",
		},
	]);

	arr.push([
		{
			table: {
				body: [
					[
						{
							text: "附註",
							bold: true,
							margin: [0, 2],
						},
					],
					[
						{
							text: `
								1.以上傢俱為台灣細工出貨,下單後工作期為30~45日,傢俱訂製品不可退換貨。
								2.訂製品需付總金額50%的訂金,並於交貨日收取尾款50%現金或現金支票。
								3.本交易為附條件買賣,依動產交易法第三章規定,在貨款未付清或票據未兌現償付之前,產品之所有權仍屬本公司所有,本公司無需經法律程序隨時取回貨品或以物易物清償,貨品由第三者佔有時亦同。
								4.傢俱訂製品不可退換貨。
								5.客戶應於交貨時確認產品無誤,商品如有問題請於現場送貨人員確認。
								6.以上報價均為現金價,有效期限為40天,逾期請重新詢價。
								7.布皮料及木器顏色不同批次施工會有輕微色差,含泡軟件誤差值±2cm為正常範圍；皮革產品皮面有傷殘為自然現象並不影響皮革品質；天然藤編產品使用後可能會有些許倒刺,斷裂情形；實木產品可能會有龜裂變形的狀況,無法人為控制,購買前請慎重考慮。
								8.客戶確認無誤後,請簽名回傳。
								`,
							style: "disclaimer",
						},
					],
				],
			},
			colSpan: 6,
			rowSpan: 3,
			layout: "noBorders",
		},
		{},
		{},
		"",
		"",
		"",
		{
			table: {
				widths: ["*"],
				body: [
					[
						{
							text: "客戶確認簽名",
							margin: [0, 3],
							bold: true,
							border: [false, false, false, false],
						},
					],
					[
						{
							text: "",
							margin: [0, 30],
							border: [false, false, false, true],
						},
					],
					[""],
				],
			},
			colSpan: 2,
			rowSpan: 3,
			layout: {
				defaultBorder: false,
			},
		},
	]);
	arr.push(["", "", "", "", "", "", "", ""]);
	arr.push(["", "", "", "", "", "", "", ""]);
	arr.push(["", "", "", "", "", "", "", ""]);
	return arr;
}

function useItemBody(): any[][] {
	const { values } = useFormikContext<One>();
	return values.items.map((item, index) => {
		return [
			(index + 1).toString(),
			{ image: item.id, width: 80 },
			item.name,
			item.size,
			item.count,
			getDollarNumberWithCommas(item.price_final),
			getDollarNumberWithCommas(item.price_total),
			item.note,
		];
	});
}

export function useOnPdfClick() {
	const body = useBody();
	const { values } = useFormikContext<One>();
	const imageMap = useImageMap({ items: values.items });
	const findCustomerById = useFindOneById<Customer>(DEFAULT_ONE(), "customers");
	const { address, tel, fax } = useCompanyInfo();
	const nickName = useMeNickName();

	return async (id: string) => {
		const FILE_NAME = `報價單-${id}`;
		const customer = await findCustomerById(values.customer_id);

		let contactLabelColumns: any[] = [
			{ text: "業務", width: "auto", margin: [0, 3] },
			{ text: values.sales_name, width: "*", margin: [0, 3] },
		];

		let contactTelColumns: any[] = [
			{ text: "電話", width: "auto", margin: [0, 3] },
			{ text: values.sales_tel, width: "*", margin: [0, 3] },
		];

		if (values.assistant_id !== null) {
			if (!_.isEmpty(values.assistant_name)) {
				contactLabelColumns = contactLabelColumns.concat([
					{ text: "助理", width: "auto", margin: [0, 3] },
					{ text: values.assistant_name, width: "*", margin: [0, 3] },
				]);
			}

			if (!_.isEmpty(values.assistant_tel)) {
				contactTelColumns = contactTelColumns.concat([
					{ text: "電話", width: "auto", margin: [0, 3] },
					{ text: values.assistant_tel, width: "*", margin: [0, 3] },
				]);
			}
		}

		const content = [
			{
				style: "defaultTable",
				table: {
					headerRows: 1,
					widths: [80, "*", "auto"],
					body: [
						[
							{ image: "logo", width: 51, height: 64, margin: [0, 10, 0, 0] },
							[
								{ text: address, margin: [0, 18, 0, 3] },
								{ text: `TEL:${tel}`, margin: [0, 3] },
								{ text: `FAX:${fax}`, margin: [0, 3] },
							],
							[
								{
									text: `報價單 ${id}${nickName}`,
									fontSize: 16,
									bold: true,
									margin: [0, 11, 0, 3],
								},
								{
									columns: contactLabelColumns,
								},
								{
									columns: contactTelColumns,
								},
							],
						],
						[
							{
								stack: [
									{ text: "客戶", margin: [0, 3, 0, 3] },
									{ text: "地址", margin: [0, 3] },
								],
								border: [false, true, false, true],
							},
							{
								stack: [
									{ text: customer!.name, margin: [0, 3, 0, 3] },
									{ text: values.deliver_address, margin: [0, 3] },
								],
								border: [false, true, false, true],
							},
							{
								stack: [
									{
										columns: [
											{ text: "報價日期", width: "auto", margin: [0, 3, 0, 3] },
											{ text: getDateFormat(values.quotation_date), width: "*", margin: [0, 3] },
										],
									},
									{
										columns: [
											{ text: "交貨日期", width: "auto", margin: [0, 3, 0, 3] },
											{ text: values.deliver_date, width: "*", margin: [0, 3] },
										],
									},
								],
								border: [false, true, false, true],
							},
						],
					],
				},
				layout: {
					defaultBorder: false,
					hLineColor: "silver",
				},
			},
			{
				style: "defaultTable",
				color: "#444",
				table: {
					widths: ["auto", "auto", "auto", "auto", 27, 50, 70, 50],
					headerRows: 1,
					body,
				},
				layout: "lightHorizontalLines",
			},
		];

		pdfMake
			.createPdf(
				{
					content,
					images: imageMap,
					styles: {
						header: {
							fontSize: 16,
							bold: true,
						},
						warning: {
							color: "red",
						},
						disclaimer: {
							fontSize: 8,
						},
						bigger: {
							fontSize: 15,
							italics: true,
						},
						defaultTable: {
							margin: [0, 5, 0, 5],
						},
						tableHeader: {
							bold: true,
							fontSize: 13,
							color: "grey",
						},
					},
					defaultStyle: {
						columnGap: 15,
						fontSize: 10,
						font: "chineseFont",
					},
				},
				undefined,
				pdfFonts,
			)
			.download(FILE_NAME);
	};
}
