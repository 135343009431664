import { MooSvcOne, One } from "../moo-svc-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { SvcItemsTable } from "./updateOne/SvcItemsTable";
import { SvcTable } from "./updateOne/SvcTable";

export type MooSvcUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooSvcUpdate({ id, onCancel }: MooSvcUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="svc-one"
			query={query}
			tableName="svcs"
			mapperInitialValues={mapperInitialValues}
			//@ts-ignore
			Form={MooSvcOne}
			tables={[SvcItemsTable, SvcTable]}
		/>
	);
}
