import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { DEFAULT_ONE, MooRoleOne, One } from "../moo-role-one";
import { useHasuraAdd } from "../moo-shared";

export type MooRoleAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooRoleAdd: FC<MooRoleAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("roles", onSuccess);
	const permissions = usePermissions();

	return (
		<MooRoleOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.RoleAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
		/>
	);
};
