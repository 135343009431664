import * as _ from "lodash";
import { CellType, HeaderType, MooDialogChoose } from "../moo-dialog-choose";
import { order } from "../moo-enums";
import { getSearchCondition } from "../moo-order-list";
import { DateFormat } from "../moo-shared";
import { Row } from "./@types";
import { useStore } from "./stores/useStore";

export type MooDialogOrderChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
	isMultiple?: boolean;
	states?: order.State.Value[];
	stateManagers?: order.StateManager.Value[];
	stateAccountants?: order.StateAccountant.Value[];
	stateWarehouses?: order.StateWarehouse.Value[];
};

const ORDER_DATE = "order_date";

const arr = [
	{ id: "id", label: "編號" },
	{ id: "quotation_type.name", label: "類型" },
	{ id: "customer.name", label: "客戶" },
	{ id: "sales.name", label: "業務" },
	{ id: ORDER_DATE, label: "訂單日期" },
];

export function getHeadersAndCells(): { headers: HeaderType[]; cells: CellType[] } {
	const headers = arr
		.map((e) => {
			return { ...e, align: "left", style: { minWidth: 65 } };
		})
		.concat([
			{ id: "state", label: "狀態", align: "left", style: { minWidth: 65 } },
			{
				id: "state_manager",
				label: "主管簽核",
				align: "left",
				style: { minWidth: 90 },
			},
			{
				id: "state_accountant",
				label: "會計確認",
				align: "left",
				style: { minWidth: 90 },
			},
		]) as HeaderType[];

	const cells = arr
		.map(({ id }) => {
			return {
				id,
				align: "left",
				component: (row: Row) => {
					if (id === ORDER_DATE) {
						return <DateFormat>{row[id]}</DateFormat>;
					} else {
						return <div>{_.get(row, id)}</div>;
					}
				},
			};
		})
		.concat([
			{
				id: "state",
				align: "left",
				component(row: Row) {
					return <div>{order.State.getLabel(row.state)}</div>;
				},
			},

			{
				id: "state_manager",
				align: "left",
				component(row: Row) {
					return <div>{order.StateManager.getLabel(row.state_manager)}</div>;
				},
			},

			{
				id: "state_accountant",
				align: "left",
				component(row: Row) {
					return <div>{order.StateAccountant.getLabel(row.state_accountant)}</div>;
				},
			},
		]) as CellType[];

	return { cells, headers };
}

export function MooDialogOrderChoose({
	id,
	open,
	onClose,
	onOk,
	isMultiple = true,
	states = order.State.getValues(),
	stateManagers = order.StateManager.getValues(),
	stateAccountants = order.StateAccountant.getValues(),
	stateWarehouses = order.StateWarehouse.getValues(),
}: MooDialogOrderChooseProps) {
	const store = useStore();
	const { headers, cells } = getHeadersAndCells();
	const where: Record<string, any> = {
		...getSearchCondition(store.search),
		state: {
			_in: states,
		},
		state_manager: {
			_in: stateManagers,
		},
		state_accountant: {
			_in: stateAccountants,
		},
		state_warehouse: {
			_in: stateWarehouses,
		},
	};

	return (
		<MooDialogChoose
			id={id}
			label=""
			title="訂購單"
			resource="orders"
			isMultiple={isMultiple}
			graphQLColumns={`{
				id
				quotation_type {
					name
				}
				customer {
					name
				}
				sales {
					name
				}
				order_date
				state
				state_manager
				state_accountant
			}`}
			where={where}
			open={open}
			onOk={(dataSet) => {
				onOk(dataSet as Row[]);
				onClose();
			}}
			onClose={onClose}
			headers={headers}
			cells={cells}
			store={store}
		/>
	);
}
