import produce from "immer";
import { MooFileList } from "../../../moo-file-list";
import { useForm } from "../../hooks/useForm";

export function Instructions() {
	const { values, setFieldValue } = useForm();

	return (
		<MooFileList
			files={values.instructions.map((e) => e.value)}
			onDel={(fileName) => {
				setFieldValue(
					"instructions",
					produce(values.instructions, (draft) => {
						const index = draft.findIndex((e) => e.value === fileName);
						if (index !== -1) {
							draft.splice(index, 1);
						}
					}),
				);
			}}
		/>
	);
}
