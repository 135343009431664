import { useNavigate, useParams } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooQuotationUpdate } from "../../components/moo-components/moo-quotation-update";
import { path as listPath } from "./ListRoute";

export const prefixPath = "/quotation";
export const path = prefixPath + "/:id";

export const UpdateRoute = () => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const branch = useBranchInQuery();

	return (
		<MooQuotationUpdate
			id={id!}
			onCancel={async () => {
				await navigate(`${listPath}?branch=${branch}`);
			}}
		/>
	);
};

export default UpdateRoute;
