export enum Value {
	UNAPPROVED = "UNAPPROVED",
	APPROVED = "APPROVED",
}

export enum Label {
	UNAPPROVED = "未核准",
	APPROVED = "核准",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.UNAPPROVED:
			return Label.UNAPPROVED;
		case Value.APPROVED:
			return Label.APPROVED;
	}
	return Label.UNAPPROVED;
}
