import { DocumentNode, gql } from "@apollo/client";
import { MooGraphqlSelectField } from "../moo-graphql-select-field";

type ValueType = string | null;

export type MooManufacturerLogisticDriverFieldProps = {
	id?: string;
	filter?: Record<string, any>;
	label: string;
	value: ValueType;
	onChange: (value: ValueType) => void;
	error?: string;
	manufacturer_logistic_id: string;
};

function getQuery(): DocumentNode {
	return gql`
		query ($where: manufacturer_logistic_drivers_bool_exp) {
			manufacturer_logistic_drivers(where: $where) {
				manufacturer_logistic_driver_id
				name
			}
		}
	`;
}

function useVariables(manufacturer_logistic_id: string): Record<string, any> {
	const where = {
		manufacturer_logistic_id: {
			_eq: manufacturer_logistic_id,
		},
	};

	return {
		where,
	};
}

export function MooManufacturerLogisticDriverField({
	id = "",
	label,
	error = "",
	value,
	onChange,
	manufacturer_logistic_id = "",
}: MooManufacturerLogisticDriverFieldProps) {
	const query = getQuery();
	const variables = useVariables(manufacturer_logistic_id);

	return (
		<MooGraphqlSelectField
			id={`${id}-${manufacturer_logistic_id}-select-field`}
			label={label}
			error={error}
			operationName="manufacturer_logistic_drivers"
			query={query}
			variables={variables}
			mapData={(data) => {
				const results: { label: string; value: string | null }[] = [{ value: null, label: "請選擇" }];
				(
					(data?.manufacturer_logistic_drivers || []) as { manufacturer_logistic_driver_id: string; name: string }[]
				).forEach(({ manufacturer_logistic_driver_id: id, name }) => {
					results.push({
						value: id,
						label: name,
					});
				});

				return {
					results,
				};
			}}
			value={value}
			onChange={onChange}
		/>
	);
}
