import CancelIcon from "@mui/icons-material/Cancel";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { Query } from "../@types";

type Props = {
	query: Query;
	setQuery: (query: Query) => void;
	field: keyof Query;
	label: string;
};

export function DateFilter({ query, setQuery, field, label }: Props) {
	if (query[field] === null) {
		return (
			<KodenButton
				onClick={() => {
					setQuery({
						...query,
						[field]: new Date(),
					});
				}}
			>
				{label}
			</KodenButton>
		);
	} else {
		return (
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<KodenDateField
					id={field}
					value={query[field] as Date | null}
					label={label}
					error=""
					onChange={(date) => {
						setQuery({
							...query,
							[field]: date,
						});
					}}
				/>
				<div
					style={{
						marginLeft: 10,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						cursor: "pointer",
					}}
					onClick={() => setQuery({ ...query, [field]: null })}
				>
					<CancelIcon fontSize="small" color="disabled" />
				</div>
			</div>
		);
	}
}
