import { One } from "../../moo-export-goods-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Row): One {
	const items = mapperItems(record.items);

	return {
		status: record.status,
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		state: record.state,
		type: record.type,
		export_date: getLocaleString(new Date(record.export_date)),
		order_id: record.order_id,
		type_id: record.type_id,
		type_name: record.quotation_type.name,
		customer_id: record.customer.id,
		customer_name: record.customer.name,
		sales_id: record.sales.id,
		sales_name: record.sales.name,
		sales_tel: record.sales.tel,
		address: record.address,
		order_description: record.order_description,
		order_note: record.order_note,
		driver_logistics_manufacturer_id: record.manufacturer_logistic.id,
		driver_id: record.driver.manufacturer_logistic_driver_id,
		driver_name: record.driver.name,
		driver_tel: record.driver.tel,
		export_goods_note: record.export_goods_note,
		items,
		voucher_rows: record.voucher_rows,
		shipping_fee: record.shipping_fee,
	};
}
