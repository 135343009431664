import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { DeleteButton } from "./DeleteButton";
import { List } from "./List";
import { UploadButton } from "./UploadButton";
import { context as useIdsContext } from "./hooks/useIds";
import { context } from "./hooks/useProps";

export type MooImageListCrudProps = {
	resource: string;
	parentId: string;
};

export function MooImageListCrud(props: MooImageListCrudProps) {
	const [ids, setIds] = useState<string[]>([]);
	return (
		<context.Provider value={{ ...props }}>
			<useIdsContext.Provider value={{ ids, setIds }}>
				<Box>
					<Stack direction="row" spacing={1} mb={2}>
						<UploadButton />
						<DeleteButton />
					</Stack>
					<List />
				</Box>
			</useIdsContext.Provider>
		</context.Provider>
	);
}
