import { KodenButton } from "@yachelee/koden-components/koden-button";
import { useFormikContext } from "formik";

type Props = {
	onClick: () => void;
	enabled?: boolean;
};

export function MooPdfButton({ onClick, enabled = false }: Props) {
	const { isValid } = useFormikContext();

	let buttonDisabled = !isValid;
	if (enabled) {
		buttonDisabled = false;
	}

	return (
		<KodenButton style={{ marginRight: "1rem" }} disabled={buttonDisabled} onClick={onClick}>
			下載PDF
		</KodenButton>
	);
}
