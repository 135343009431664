import { MooEnumCrud } from "../moo-enum-crud";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export function MooPayeeCrud() {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.PayeeWrite)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooEnumCrud
			resource="payees"
			title="收款人"
			addColumns={[
				{
					id: "name",
					defaultValue: "",
					label: "收款戶名",
					autoFocus: true,
					placeholder: "李大同",
					emptyError: "",
				},
				{
					id: "bank_name",
					defaultValue: "",
					label: "收款銀行",
					autoFocus: false,
					placeholder: "國泰世華",
					emptyError: "",
				},
				{
					id: "account_number",
					defaultValue: "",
					label: "收款帳號",
					autoFocus: false,
					placeholder: "2233-5555-1111-2222",
					emptyError: "",
				},
			]}
			listBackgroundColor="#EAEAEA"
			listColumns={[
				{
					id: "name",
					defaultValue: "",
				},
				{
					id: "bank_name",
					defaultValue: "",
				},
				{
					id: "account_number",
					defaultValue: "",
				},
			]}
		/>
	);
}
