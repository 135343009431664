import { HeaderType } from "../moo-table";

export const headers: HeaderType[] = [
	{ id: "item_id", align: "left", label: "商品編號", style: { width: 90 } },
	{ id: "item_name", align: "left", label: "商品名稱", style: { width: 90 } },
	{ id: "img", align: "left", label: "圖片", style: { width: 80 } },
	{ id: "imported_count", align: "left", label: "已入庫", style: { width: 80 } },
	{ id: "transferred_in_count", align: "left", label: "已調入貨", style: { width: 80 } },
	{ id: "returned_count", align: "left", label: "已退貨", style: { width: 80 } },
	{ id: "maintained_count", align: "left", label: "維修完已入庫", style: { width: 80 } },
	{ id: "maintaining_count", align: "left", label: "維修中", style: { width: 80 } },
	{ id: "exported_count", align: "left", label: "已出貨", style: { width: 80 } },
	{ id: "transferred_out_count", align: "left", label: "已調出貨", style: { width: 80 } },
	{
		id: "available_count",
		align: "left",
		label: "可售 (已入庫 + 已調入貨 + 已退貨 + 維修完已入庫 - 維修中 - 已出貨 - 已調出貨)",
		style: { width: 200 },
	},
];
