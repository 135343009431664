import { createContext, useContext } from "react";

type Props = {
	ids: string[];
	setIds: (ids: string[]) => void;
};
export const context = createContext<Props>({
	ids: [],
	setIds() {},
});

export function useIds() {
	return useContext(context);
}
