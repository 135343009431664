import produce from "immer";
import { uuidv4 } from "../../moo-shared";
import { Account } from "../@types/domestic-manufacturer";
import { useForm } from "./useForm";

export function useUpdate() {
	const { values, setFieldValue } = useForm();

	function addTel() {
		setFieldValue(
			"tels",
			produce(values.tels, (draft) => {
				draft.push({
					manufacturer_domestic_tel_id: uuidv4(),
					tel: "",
				});
			}),
		);
	}

	function delTel(index: number) {
		setFieldValue(
			"tels",
			produce(values.tels, (draft) => {
				draft.splice(index, 1);
			}),
		);
	}

	function updateTel(index: number, value: string) {
		setFieldValue(
			"tels",
			produce(values.tels, (draft) => {
				draft[index].tel = value;
			}),
		);
	}

	function addAccount() {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				draft.push({
					manufacturer_domestic_account_id: uuidv4(),
					bank_account: "",
					bank: "",
					bank_account_number: "",
					tax_id: "",
				});
			}),
		);
	}

	function delAccount(index: number) {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				draft.splice(index, 1);
			}),
		);
	}

	function updateAccount(id: Account["manufacturer_domestic_account_id"], field: keyof Account, value: string) {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				const index = draft.findIndex((e) => e.manufacturer_domestic_account_id === id);
				if (index !== -1) {
					draft[index][field] = value;
				}
			}),
		);
	}

	return {
		addTel,
		delTel,
		updateTel,
		addAccount,
		delAccount,
		updateAccount,
	};
}
