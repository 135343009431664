import { useParams } from "react-router-dom";
import { MooUserPerformanceSubList } from "../../components/moo-components/moo-user-performance-sub-list";
import useURLSearchParams from "../../hooks/useURLSearchParams";

export const prefixPath = "/account/performance/employee";
export const path = `${prefixPath}/:id`;

const useQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const { id } = useParams<{ id: string }>();

	return {
		year: parseInt(urlSearchParams.get("year") ?? "0"),
		month: parseInt(urlSearchParams.get("month") ?? "0"),
		employee_id: id,
	};
};

const AccountPerformanceSubListRoute = () => {
	const query = useQuery();

	return (
		<MooUserPerformanceSubList
			year={query.year}
			month={query.month}
			employee_id={query.employee_id!}
			onRowClick={() => {}}
		/>
	);
};

export default AccountPerformanceSubListRoute;
