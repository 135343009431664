import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { useGraphQLQuery } from "../../moo-graphql-provider";
import { MultiSelect } from "./MultiSelect";
import { query } from "./query";
import { GraphQLRequest } from "./type";

type Props = {
	itemIds: string[];
	onChange: (itemIds: string[]) => void;
};

export function QueryBoard({ itemIds, onChange }: Props) {
	const { value: branch } = useBranch();

	const { isLoading, data } = useGraphQLQuery<GraphQLRequest>({
		operationName: "warehouse-one-query",
		query,
		variables: {
			item_specs_where: {
				branch_id: {
					_eq: branch,
				},
				status: {
					_eq: Status.Present,
				},
			},
			item_customs_where: {
				branch_id: {
					_eq: branch,
				},
				status: {
					_eq: Status.Present,
				},
			},
		},
	});

	const options: { value: string; label: string }[] = [];
	if (!isLoading) {
		// @ts-ignore
		data.item_specs.forEach(({ id, name }) => {
			options.push({
				value: id,
				label: `${id}(${name})`,
			});
		});

		// @ts-ignore
		data.item_customs.forEach(({ id, name }) => {
			options.push({
				value: id,
				label: `${id}(${name})`,
			});
		});
	}

	return (
		<MultiSelect
			itemIds={itemIds}
			isLoading={isLoading}
			options={options}
			onChange={(e) => {
				onChange(e.map(({ value }) => value));
			}}
		/>
	);
}
