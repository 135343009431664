import { FC } from "react";
import { MooItemRowList } from "../moo-item-row-list";
import { FieldType } from "../moo-item-spec-and-custom-row";
import { ItemSpecOrCustomBound } from "../moo-types";
import { useForm } from "./hooks/useForm";
import { useLockStock } from "./hooks/useLockStock";
import { useUpdate } from "./hooks/useUpdate";

export const ItemList: FC = () => {
	const { updateItem, deleteItem } = useUpdate();
	const { values: one } = useForm();
	const lockStock = useLockStock();
	const specCustomFields = new Set<FieldType>([
		"id",
		"manufacturer_type",
		"manufacturer",
		"name",
		"material",
		"color",
		"size",
		"transfer_count",
		"remove",
	]);
	const disabledSpecCustomFields = new Set<FieldType>([]);

	if (lockStock) {
		specCustomFields.delete("remove");
		disabledSpecCustomFields.add("transfer_count");
	}

	return (
		<MooItemRowList
			emptyMessage="尚未匯入商品"
			specCustomFields={specCustomFields}
			disabledSpecCustomFields={disabledSpecCustomFields}
			items={one.items as unknown as ItemSpecOrCustomBound[]}
			onTransferCountChange={(id, value) => updateItem(id, "transfer_count", value)}
			onRemove={deleteItem}
		/>
	);
};

export default ItemList;
