import { Customer, MooCustomerOne } from "../moo-customer-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { CustomerTable } from "./updateOne/CustomerTable";
import { CustomerTelsTable } from "./updateOne/CustomerTelsTable";

export type MooCustomerUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooCustomerUpdate({ id, onCancel }: MooCustomerUpdateProps) {
	return (
		<MooUpdateFormNew<Row, Customer>
			id={id}
			onCancel={onCancel}
			operationName={`customer-one_${id}`}
			query={query}
			tableName="customers"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooCustomerOne}
			tables={[CustomerTelsTable, CustomerTable]}
		/>
	);
}
