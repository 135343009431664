import { gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { MooGraphqlSelectField } from "../moo-graphql-select-field";

type Value = string | null;

export type MooCustomerSelectFieldProps = {
	id: string;
	value: Value;
	label: string;
	error?: string;
	onChange: (value: Value) => void;
	variant?: "outlined" | "filled" | "standard";
};

export function MooCustomerSelectField({
	id,
	label,
	value,
	variant = "outlined",
	error = "",
	onChange,
}: MooCustomerSelectFieldProps) {
	const { value: branch } = useBranch();

	return (
		<MooGraphqlSelectField
			id={id}
			label={label}
			value={value || ""}
			error={error}
			onChange={onChange}
			operationName="customers"
			query={gql`
				query ($where: customers_bool_exp!) {
					customers(where: $where) {
						id
						name
					}
				}
			`}
			variables={{
				where: {
					status: {
						_eq: "PRESENT",
					},
					branch_id: {
						_eq: branch,
					},
				},
			}}
			mapData={(e) => {
				const results: { label: string; value: string | null }[] = [
					{
						label: "請選擇",
						value: null,
					},
				];

				((e?.customers || []) as { id: string; name: string }[]).forEach(({ id, name }) => {
					results.push({
						label: name,
						value: id,
					});
				});

				return {
					results,
				};
			}}
		/>
	);
}
