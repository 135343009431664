import { Row } from "./@types";

export function mapData({
	roles_aggregate,
	roles,
}: {
	roles_aggregate: { aggregate: { count: number } };
	roles: { id: string; name: string }[];
}) {
	const results = roles.map(({ id, name }) => {
		const row: Row = {
			id,
			name,
		};
		return row;
	});

	return {
		results,
		total_count: roles_aggregate.aggregate.count,
	};
}
