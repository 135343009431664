import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "date",
			label: "日期",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "content",
			label: "內容",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "message_count",
			label: "留言數量",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
	];
}
