import { manufacturer, Status } from "../moo-enums";
import { getNowLocaleString, uuidv4 } from "../moo-shared";
import { LogisticsManufacturer } from "./@types/logistics-manufacturer";

export function DEFAULT_ONE(): LogisticsManufacturer {
	const now = getNowLocaleString();
	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		type: manufacturer.Value.Value.Domestic,
		name: "",
		contact: "",
		tel: "",
		fax: "",
		email: "",
		drivers: [
			{
				manufacturer_logistic_driver_id: uuidv4(),
				name: "",
				tel: "",
				line: "",
			},
		],
		accounts: [],
		note: "",
	};
}
