import produce from "immer";
import { uuidv4 } from "../../moo-shared";
import { useForm } from "./useForm";

export function useUpdate() {
	const { values, setValues } = useForm();

	function addTel() {
		setValues(
			produce(values, (draft) => {
				draft.tels.push({
					customer_tel_id: uuidv4(),
					tel: "",
				});
			}),
		);
	}

	function delTel(id: string) {
		setValues(
			produce(values, (draft) => {
				const index = draft.tels.findIndex((e) => e.customer_tel_id === id);
				draft.tels.splice(index, 1);
			}),
		);
	}

	function updateTel(id: string, value: string) {
		setValues(
			produce(values, (draft) => {
				if (draft.tels) {
					const tel = draft.tels.find((e) => {
						if (!e) return false;
						return e.customer_tel_id === id;
					});
					if (tel) {
						tel.tel = value;
					}
				}
			}),
		);
	}

	return { addTel, delTel, updateTel };
}
