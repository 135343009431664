import { MooGraphQLList } from "../moo-graphql-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Row, VoucherRow } from "./@types";
import { FilterNode } from "./FilterNode";
import { operationName, rowsPerPage } from "./config";
import { getCreditAmountString } from "./getCreditAmountString";
import { getDebitAmountString } from "./getDebitAmountString";
import { getRemainingAmountString } from "./getRemainingAmountString";
import { graphQLQuery } from "./graphQLQuery";
import { useCells } from "./hooks/useCells";
import { useHeaders } from "./hooks/useHeaders";
import { useStore } from "./hooks/useStore";
import { useVariables } from "./hooks/useVariables";

export type MooVoucherListProps = {
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooVoucherList = ({ onAdd, onRowClick }: MooVoucherListProps) => {
	const { query, setQuery, clear } = useStore();
	const cells = useCells();
	const headers = useHeaders();
	const permissions = usePermissions();
	const variables = useVariables();

	if (!permissions.includes(Permission.VoucherListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooGraphQLList
			showAddButton={permissions.includes(Permission.VoucherAdd)}
			filterNode={<FilterNode query={query} setQuery={setQuery} />}
			clear={clear}
			query={query}
			setQuery={setQuery}
			// @ts-ignore
			onRowClick={({ voucher_id }) => {
				// @ts-ignore
				onRowClick({ id: voucher_id });
			}}
			addLabel="新增傳票"
			onAdd={onAdd}
			headers={headers}
			cells={cells}
			rowsPerPage={rowsPerPage}
			operationName={operationName}
			mapData={({ voucher_rows, voucher_rows_aggregate }) => {
				const results: Row[] = [];
				let count = variables.offset + 1;

				(voucher_rows as VoucherRow[]).forEach((voucher_row) => {
					const result: Row = {
						id: voucher_row.voucher_row_id,
						voucher_row_id: voucher_row.voucher_row_id,
						voucher_id: voucher_row.voucher.id,
						subject_id_number: voucher_row.account_type?.id_number || "",
						subject_name: voucher_row.account_type?.name || "",
						account_type: voucher_row.account_type,
						summary: voucher_row.summary,
						type: voucher_row.type,
						debit_amount: voucher_row.debit_amount,
						credit_amount: voucher_row.credit_amount,
						voucher_date: voucher_row.voucher.voucher_date,
						manufacturer_domestic: voucher_row.manufacturer_domestic,
						manufacturer_foreign: voucher_row.manufacturer_foreign,
						number: count++,
					};
					results.push(result);
				});

				return {
					results,
					total_count: voucher_rows_aggregate.aggregate.count,
				};
			}}
			graphQLQuery={graphQLQuery}
			variables={variables}
			sumNode={({ voucher_rows_aggregate }) => {
				if (query.account_type === null || !voucher_rows_aggregate?.aggregate?.sum) return null;

				const { debit_amount, credit_amount } = voucher_rows_aggregate.aggregate.sum;
				return (
					<div style={{ display: "flex", gap: 20 }}>
						<div>借方金額：{getDebitAmountString(debit_amount)}</div>
						<div>貸方金額：{getCreditAmountString(credit_amount)}</div>
						<div>餘額：{getRemainingAmountString({ debit_amount, credit_amount })}</div>
					</div>
				);
			}}
		/>
	);
};
