import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class RequestBuyItemInfosTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "request_buy_item_info_id";
	}

	getTableName(): string {
		return "request_buy_item_infos";
	}

	getPathName(): string {
		return "item_infos";
	}

	getParentIdName(): string {
		return "request_buy_id";
	}

	getToUpdateColumns(): string[] {
		return ["website", "image"];
	}
}
