export enum Value {
	UNSIGNED = "UNSIGNED",
	SIGNED = "SIGNED",
}

export enum Label {
	UNSIGNED = "未簽收",
	SIGNED = "簽收",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.UNSIGNED:
			return Label.UNSIGNED;
		case Value.SIGNED:
			return Label.SIGNED;
	}
	return Label.UNSIGNED;
}
