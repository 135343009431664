import { generatePath, useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooManufacturerDomesticAdd } from "../components/moo-components/moo-manufacturer-domestic-add";
import { path as listPath } from "./DomesticManufacturerListRoute";
import { path as onePath } from "./UpdateDomesticManufacturerRoute";

export const path = "/new-domestic-manufacturer";

const NewDomesticManufacturer = () => {
	const branch = useBranchInQuery();
	const navigate = useNavigate();

	return (
		<MooManufacturerDomesticAdd
			onSuccess={async (id) => {
				await navigate(`${generatePath(onePath, { id })}?branch=${branch}`);
			}}
			onCancel={async () => {
				await navigate(`${listPath}?branch=${branch}`);
			}}
		/>
	);
};

export default NewDomesticManufacturer;
