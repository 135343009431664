import { Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();
	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		voucher_date: new Date(),
		debit_total: 0,
		credit_total: 0,
		rows: [],
	};
}
