import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "type",
			label: "職務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "name",
			label: "姓名",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "nick_name",
			label: "暱稱",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "tel",
			label: "電話",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "line",
			label: "LINE",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "email",
			label: "Email",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "action",
			label: "",
			align: "center",
		},
	];
}
