import { FC } from "react";
import { MooItemRowList } from "../../../moo-item-row-list";
import { FieldType } from "../../../moo-item-spec-and-custom-row";
import { useGetFieldError } from "../../../moo-shared";
import { ItemBound } from "../../../moo-types";
import { One } from "../../@types/one";
import { useForm } from "../../hooks/useForm";
import { useLockStock } from "../../hooks/useLockStock";
import { useUpdate } from "../../hooks/useUpdate";

export const ItemList: FC = () => {
	const { updateItemCount, deleteItem } = useUpdate();
	const lockStock = useLockStock();
	const { values } = useForm();
	const getFieldError = useGetFieldError<One>();
	const customFields = new Set<FieldType>([
		"id",
		"manufacturer_type",
		"manufacturer",
		"name",
		"material",
		"color",
		"size",
		"count",
		"purchase_price",
		"purchase_price_total",
		"remove",
	]);

	const disabledFields = new Set<FieldType>([]);
	if (lockStock) {
		customFields.delete("remove");
		disabledFields.add("count");
	}

	return (
		<MooItemRowList
			emptyMessage=""
			specCustomFields={customFields}
			errors={getFieldError("items")}
			items={values.items as unknown as ItemBound[]}
			onRemove={deleteItem}
			onCountChange={updateItemCount}
			disabledSpecCustomFields={disabledFields}
		/>
	);
};

export default ItemList;
