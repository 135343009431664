import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";

export type MooBranchSelectFieldProps = {
	value: string | null;
	onChange: (value: string | null) => void;
	error?: string;
	label?: string;
};

export function MooBranchSelectField({ label = "店別", error = "", value, onChange }: MooBranchSelectFieldProps) {
	return (
		<MooEnumTypeableSelectField
			id="branches"
			resource="branches"
			label={label}
			value={value}
			error={error}
			onChange={onChange}
		/>
	);
}
