import { object, SchemaOf } from "yup";
import { stringRequired } from "../../moo-schemas";
import { File, ManufacturerFile, OtherFile } from "../@types/one";

// @ts-ignore
export const fileSchema: SchemaOf<File> = object().shape({
	purchase_order_file_id: stringRequired("purchase_order_file_id"),
	value: stringRequired("value"),
});

// @ts-ignore
export const manufacturerFileSchema: SchemaOf<ManufacturerFile> = object().shape({
	purchase_order_manufacturer_file_id: stringRequired("purchase_order_manufacturer_file_id"),
	value: stringRequired("value"),
});

// @ts-ignore
export const otherFileSchema: SchemaOf<OtherFile> = object().shape({
	purchase_order_other_file_id: stringRequired("purchase_order_other_file_id"),
	value: stringRequired("value"),
});
