import { MooGqlChildNewTable } from "../../moo-gql";
import { Quotation } from "../findOne/Quotation";

export class QuotationSignedFilesTable extends MooGqlChildNewTable<Quotation> {
	getIdName(): string {
		return "quotation_signed_file_id";
	}

	getTableName(): string {
		return "quotation_signed_files";
	}

	getPathName(): string {
		return "signed_files";
	}

	getParentIdName(): string {
		return "quotation_id";
	}

	getToUpdateColumns(): string[] {
		return ["value"];
	}
}
