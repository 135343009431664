import { MooItemCustomOne, One } from "../moo-item-custom-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { Table } from "./updateOne/Table";

export type MooItemCustomUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooItemCustomUpdate({ id, onCancel }: MooItemCustomUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="item-custom-one"
			query={query}
			tableName="item_customs"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooItemCustomOne}
			tables={[Table]}
		/>
	);
}
