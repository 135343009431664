import { gql } from "@apollo/client";

export const query = gql`
	query (
		$id: uuid!
		$imported_good_items_where: import_good_items_bool_exp
		$exported_good_item_stocks_where: export_good_item_stocks_bool_exp
		$stock_transfer_items_where: stock_transfer_items_bool_exp
		$returned_goods_items_where: return_good_items_bool_exp
		$maintaining_defective_items_where: defective_product_items_bool_exp
		$maintained_defective_items_where: defective_product_items_bool_exp
	) {
		warehouses_by_pk(id: $id) {
			branch_id
			branch {
				name
			}
			id
			name
			status
			branch_id
			branch {
				name
			}
		}
		imported_good_items: import_good_items(where: $imported_good_items_where) {
			id
			count
			item_spec {
				name
				image
				image_cut
			}
			item_custom {
				name
				image
				image_cut
			}
		}
		exported_good_item_stocks: export_good_item_stocks(where: $exported_good_item_stocks_where) {
			count
			export_good_item {
				id
				item_spec {
					name
					image
					image_cut
				}
				item_custom {
					name
					image
					image_cut
				}
			}
		}
		transferred_good_item_stocks: stock_transfer_items(where: $stock_transfer_items_where) {
			id
			item_spec {
				name
				image
				image_cut
			}
			item_custom {
				name
				image
				image_cut
			}
			transfer_count
			stock_transfer {
				source_warehouse_id
				target_warehouse_id
			}
		}
		returned_goods_items: return_good_items(where: $returned_goods_items_where) {
			id
			return_count
			item_spec {
				name
				image
				image_cut
			}
			item_custom {
				name
				image
				image_cut
			}
		}
		maintaining_defective_items: defective_product_items(where: $maintaining_defective_items_where) {
			id
			count
			item_spec {
				name
				image
				image_cut
			}
			item_custom {
				name
				image
				image_cut
			}
		}
		maintained_defective_items: defective_product_items(where: $maintained_defective_items_where) {
			id
			count
			item_spec {
				name
				image
				image_cut
			}
			item_custom {
				name
				image
				image_cut
			}
		}
	}
`;
