import { manufacturer } from "../../moo-enums";
import { useFindOneById } from "../../moo-shared";

export type Result = {
	sales: {
		id: string;
		tel: string;
	};
	manufacturer: {
		type: manufacturer.Value.Value;
		id: string;
		name: string;
	};
	warehouse_id: string;
	order_id: string;
	items: {
		id: string;
		item_spec: {
			name: string;
			material: string;
			color: string;
			size: string;
			image: string;
			image_cut: string;
			purchase_price: number;
			manufacturer: {
				type: manufacturer.Value.Value;
				id: string;
				name: string;
			};
		};
		item_custom: {
			name: string;
			material: string;
			color: string;
			size: string;
			image: string;
			image_cut: string;
			purchase_price: number;
			manufacturer: {
				type: manufacturer.Value.Value;
				id: string;
				name: string;
			};
		};
	}[];
};

export function useFindOrderGoodsById() {
	const findOneById = useFindOneById(
		{
			// @ts-ignore
			sales: {
				id: null,
				tel: null,
			},
			manufacturer: {
				type: null,
				id: null,
				name: null,
			},
			warehouse_id: null,
			order_id: null,
			items: {
				id: null,
				item_spec: {
					name: null,
					material: null,
					color: null,
					size: null,
					image: null,
					image_cut: null,
					purchase_price: null,
					manufacturer: {
						type: manufacturer.Value.Value.Domestic,
						id: null,
						name: null,
					},
				},
				item_custom: {
					name: null,
					material: null,
					color: null,
					size: null,
					image: null,
					image_cut: null,
					purchase_price: null,
					manufacturer: {
						type: manufacturer.Value.Value.Domestic,
						id: null,
						name: null,
					},
				},
			},
		},
		"order_goods",
	);

	return (id: string): Promise<Result> => {
		return findOneById(id) as unknown as Promise<Result>;
	};
}
