import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { DEFAULT_ONE, MooOrderGoodsOne, One } from "../moo-order-goods-one";
import { useHasuraAdd } from "../moo-shared";

export type MooOrderGoodsAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooOrderGoodsAdd: FC<MooOrderGoodsAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>(
		"order_goods",
		onSuccess,
		(e) =>
			produce(e, (draft) => {
				// @ts-ignore
				delete draft.sales_name;
				// @ts-ignore
				delete draft.sales_tel;
				// @ts-ignore
				delete draft.manufacturer_name;
				// @ts-ignore
				delete draft.manufacturer_tel;
				// @ts-ignore
				delete draft.manufacturer_fax;
				// @ts-ignore
				delete draft.manufacturer_email;

				draft.items.forEach((item, index) => {
					// @ts-ignore
					draft.items[index] = {
						order_good_item_id: item.order_good_item_id,
						id: item.id,
						count: item.count,
						price_final: item.price_final,
						priority: index,
					};
				});
			}),
		true,
	);
	const permissions = usePermissions();

	return (
		<MooOrderGoodsOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.OrderGoodsAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
