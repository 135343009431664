import { array, object, SchemaOf } from "yup";
import { svc } from "../../moo-enums";
import { enumRequired, numberOptional, statusSchema, stringOptional, stringRequired } from "../../moo-schemas";
import { One } from "../@types/one";
import { itemSchema } from "./itemSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: statusSchema,
	state_accountant: enumRequired(svc.StateAccountant.Value),
	type: enumRequired(svc.Type.Value),
	from_date: stringRequired("from_date"),
	to_date: stringRequired("to_date"),
	customer_id: stringRequired("sales_id", "請選擇客戶"),
	customer_name: stringOptional,
	sales_id: stringRequired("sales_id", "請選擇業務"),
	sales_name: stringOptional,
	owner_id: stringRequired("owner_id", "請選擇承辦人"),
	owner_name: stringOptional,
	address: stringOptional,
	description: stringOptional,
	note: stringOptional,
	driver_logistics_manufacturer_id: stringRequired("driver_logistics_manufacturer_id", "請選擇司機物流廠商"),
	driver_id: stringRequired("driver_id", "請選擇司機"),
	driver_tel: stringRequired("driver_tel", "沒有司機電話"),
	items: array(itemSchema).test("items", "尚未匯入維修品項", (items) => {
		// @ts-ignore
		return items.length >= 1;
	}),
	price: numberOptional,
	cost: numberOptional,
	receivables: numberOptional,
	received: numberOptional,
});
