import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../../moo-graphql-provider";
import { getDateString } from "../../moo-shared";
import { Type } from "../../moo-voucher-one";
import { useWhere } from "../hooks/useWhere";

type ReturnedData = {
	voucher_rows_aggregate: {
		aggregate: {
			sum: {
				debit_amount: number;
				credit_amount: number;
			};
		};
	};
	voucher_rows: {
		voucher: {
			voucher_date: string;
			id: string;
		};
		account_type: {
			id_number: string;
			name: string;
		};
		voucher_row_id: string;
		summary: string | null;
		type: Type;
		debit_amount: number;
		credit_amount: number;
	}[];
};

type TableRow = {
	date: string;
	account_type_id_number: string;
	subject_id_number: string;
	subject_name: string;
	summary: string;
	type: Type;
	amount: number;
};

const query = gql`
	query query($where: voucher_rows_bool_exp, $order_by: [voucher_rows_order_by!]) {
		voucher_rows_aggregate(where: $where) {
			aggregate {
				count
				sum {
					credit_amount
					debit_amount
				}
			}
		}

		voucher_rows(where: $where, order_by: $order_by) {
			voucher {
				voucher_date
				id
			}
			account_type {
				id_number
				name
			}
			voucher_row_id
			summary
			type
			debit_amount
			credit_amount
		}
	}
`;

export type ReturnData = {
	aggregate: {
		debit_amount: number;
		credit_amount: number;
	};
	rows: TableRow[];
};

export function useGetData() {
	const graphQLRequest = useGraphQLRequest();
	const where = useWhere();

	return async (): Promise<ReturnData> => {
		const data = (await graphQLRequest({
			query,
			variables: {
				where,
				order_by: [
					{
						voucher: {
							voucher_date: "asc",
						},
					},
				],
			},
		})) as ReturnedData;

		const rows = data.voucher_rows.map(({ voucher, account_type, summary, type, debit_amount, credit_amount }) => {
			const { voucher_date } = voucher;
			const row: TableRow = {
				date: getDateString(new Date(voucher_date)),
				account_type_id_number: voucher.id,
				subject_id_number: account_type?.id_number || "",
				subject_name: account_type?.name || "",
				summary: summary ?? "",
				type,
				amount: type === Type.Debit ? debit_amount : credit_amount,
			};

			return row;
		});

		const { debit_amount, credit_amount } = data.voucher_rows_aggregate.aggregate.sum;
		const aggregate = {
			debit_amount,
			credit_amount,
		};

		return {
			rows,
			aggregate,
		};
	};
}
