import { MooReturnGoodsOne, One } from "../moo-return-goods-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { ReturnGoodsItemsTable } from "./updateOne/ReturnGoodsItemsTable";
import { ReturnGoodsTable } from "./updateOne/ReturnGoodsTable";

export type MooReturnGoodsUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooReturnGoodsUpdate({ id, onCancel }: MooReturnGoodsUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="return-goods-one"
			query={query}
			tableName="return_goods"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooReturnGoodsOne}
			tables={[ReturnGoodsItemsTable, ReturnGoodsTable]}
		/>
	);
}
