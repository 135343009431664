export function mapData(e: any) {
	return {
		id: e.id,
		return_date: e.return_date,
		state_accountant: e.state_accountant,
		note: e.note,
		customer_name: e?.order?.customer?.name || "",
		sales_name: e?.order?.sales?.name || "",
		order_id: e.order_id || "",
	};
}
