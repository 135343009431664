import { useAjax } from "@yachelee/koden-components/koden-crud";
import { KodenDialog } from "@yachelee/koden-components/koden-dialog";
import { FC, useState } from "react";
import { MooGraphQLTable } from "../moo-graphql-table";
import { Query, Row } from "./@types";
import { FilterBar } from "./FilterBar";
import { constants } from "./constants";
import { useCells } from "./hooks/useCells";
import { useGraphQLMapData } from "./hooks/useGraphQLMapData";
import { useGraphQLQuery } from "./hooks/useGraphQLQuery/useGraphQLQuery";
import { useGraphQLVariables } from "./hooks/useGraphQLVariables";
import { useHeaders } from "./hooks/useHeaders";
import { useStore } from "./stores/useStore";

export type MooDialogItemChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
};

export const MooDialogItemChoose: FC<MooDialogItemChooseProps> = ({ id, open, onClose, onOk }) => {
	const ajax = useAjax();
	const headers = useHeaders();
	const cells = useCells();
	const [isLoading] = useState(false);
	const { query, setQuery, toggleChoose, getChooseSet } = useStore();
	const graphQLQuery = useGraphQLQuery();
	const graphQLVariables = useGraphQLVariables();
	const mapData = useGraphQLMapData();

	return (
		<KodenDialog
			id={id}
			title=""
			open={open}
			onClose={() => onClose()}
			disabledOk={false}
			isLoading={isLoading}
			onOk={async () => onOk(await getChooseSet(ajax))}
			maxWidth={false}
		>
			<FilterBar />
			<MooGraphQLTable
				headers={headers}
				cells={cells}
				page={query.page}
				rowsPerPage={constants.rowsPerPage}
				onPageChange={(page) => {
					setQuery({ page } as Query);
				}}
				operationName="query"
				//@ts-ignore
				onRowClick={toggleChoose}
				mapData={mapData}
				query={graphQLQuery}
				variables={graphQLVariables}
			/>
		</KodenDialog>
	);
};
