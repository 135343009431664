import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooItemSpecList, Query, useCreateStore } from "../../components/moo-components/moo-item-spec-list";
import { useHistoryQuery } from "../../hooks/useHistoryQuery";
import usePage from "../../hooks/usePage";
import useURLSearchParams from "../../hooks/useURLSearchParams";
import { path as NewItemSpecRoutePath } from "./NewRoute";
import { prefixPath as UpdateItemSpecRoutePath } from "./UpdateRoute";

export const path = "/item/spec";

const useQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();

	return {
		search: urlSearchParams.get("search") || null,
		material: urlSearchParams.get("material") || null,
		owner_id: urlSearchParams.get("owner_id") || null,
		color: urlSearchParams.get("color") || null,
		category: urlSearchParams.get("category") || null,
		sub_category: urlSearchParams.get("sub_category") || null,
		manufacturer_type: urlSearchParams.get("manufacturer_type") || null,
		manufacturer_id: urlSearchParams.get("manufacturer_id") || null,
		page,
	};
};

export const ListRoute = () => {
	const branch = useBranchInQuery();
	const navigate = useNavigate();
	const query = useQuery();
	const store = useCreateStore(query, {
		search: null,
		owner_id: null,
		material: null,
		color: null,
		category: null,
		sub_category: null,
		manufacturer_type: null,
		manufacturer_id: null,
		page: 0,
	});
	useHistoryQuery<Query>({ path, store });

	return (
		<MooItemSpecList
			store={store}
			onAdd={() => {
				navigate(`${NewItemSpecRoutePath}?branch=${branch}`);
			}}
			onRowClick={({ id }, event) => {
				event.preventDefault();
				navigate(`${UpdateItemSpecRoutePath}/${id}?branch=${branch}`);
			}}
		/>
	);
};

export default ListRoute;
