import { createContext, ReactNode, useContext } from "react";
import { QuerySlice } from "../../moo-hooks";
import { Query } from "../@types";

type ContextProps = {
	value: QuerySlice<Query>;
};
const TheContext = createContext<ContextProps>({
	value: {} as QuerySlice<Query>,
});

export function StoreProvider({ value, children }: { value: ContextProps["value"]; children: ReactNode }) {
	return <TheContext.Provider value={{ value }}>{children}</TheContext.Provider>;
}

export function useStore() {
	return useContext(TheContext).value;
}
