import { ReactNode } from "react";
import { ArchiveContext } from "./context";
import { OnArchived } from "./types";

export type MooArchiveProviderProps = {
	children: ReactNode;
	onArchived: OnArchived;
};

export function MooArchiveProvider({ children, onArchived }: MooArchiveProviderProps) {
	return <ArchiveContext.Provider value={{ onArchived }}>{children}</ArchiveContext.Provider>;
}
