import { FC } from "react";
import { DEFAULT_ONE, ForeignManufacturer, MooManufacturerForeignOne } from "../moo-manufacturer-foreign-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooManufacturerForeignAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooManufacturerForeignAdd: FC<MooManufacturerForeignAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<ForeignManufacturer>("manufacturer_foreigns", onSuccess, (e) => e, false);
	const permissions = usePermissions();

	return (
		<MooManufacturerForeignOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.ManufacturerForeignAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
