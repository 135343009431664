export function getDateFormat(s: string | Date, year = true): string {
	let options: Intl.DateTimeFormatOptions = {
		month: "2-digit",
		day: "2-digit",
	};

	if (year) {
		options = {
			...options,
			year: "numeric",
		};
	}

	return new Date(s).toLocaleDateString("zh-TW", options);
}
