import produce from "immer";
import { FC } from "react";
import { DEFAULT_ONE, MooImportGoodsOne, One } from "../moo-import-goods-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooImportGoodsAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooImportGoodsAdd: FC<MooImportGoodsAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("import_goods", onSuccess, (e) => {
		return produce(e, (draft) => {
			// @ts-ignore
			delete draft.sales_name;
			// @ts-ignore
			delete draft.sales_tel;
			// @ts-ignore
			delete draft.manufacturer_type;
			// @ts-ignore
			delete draft.manufacturer;
			// @ts-ignore
			delete draft.accountant_total_price;
			// @ts-ignore
			delete draft.accountant_payable;

			draft.items.forEach((e, index) => {
				// @ts-ignore
				draft.items[index] = {
					import_good_item_id: e.import_good_item_id,
					id: e.id,
					count: e.count,
				};
			});
		});
	});
	const permissions = usePermissions();

	return (
		<MooImportGoodsOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.ImportGoodsAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
		/>
	);
};
