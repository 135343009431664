import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class OrderGoodsTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "order_goods";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "state":
			case "state_warehouse":
			case "order_id":
			case "order_goods_date":
			case "deliver_goods_date":
			case "sales_id":
			case "manufacturer_type":
			case "manufacturer_id":
			case "warehouse_id":
			case "note":
				return true;
		}
		return false;
	}
}
