import { MooGqlChildNewTable } from "../../moo-gql";
import { ReturnOrder } from "../findOne/ReturnOrder";

export class ReturnOrderItemsTable extends MooGqlChildNewTable<ReturnOrder> {
	getIdName(): string {
		return "return_order_item_id";
	}

	getTableName(): string {
		return "return_order_items";
	}

	getPathName(): string {
		return "items";
	}

	getParentIdName(): string {
		return "return_order_id";
	}

	getToUpdateColumns(): string[] {
		return ["id", "count", "price_final"];
	}
}
