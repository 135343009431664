import { MooGqlChildNewTable } from "../../moo-gql";
import { Quotation } from "../findOne/Quotation";

export class QuotationItemsTable extends MooGqlChildNewTable<Quotation> {
	getIdName(): string {
		return "quotation_item_id";
	}

	getTableName(): string {
		return "quotation_items";
	}

	getPathName(): string {
		return "items";
	}

	getParentIdName(): string {
		return "quotation_id";
	}

	getToUpdateColumns(): string[] {
		return ["id", "count", "price_final", "note", "priority"];
	}
}
