import styled from "styled-components";

const A = styled.div`
	color: #4672e8;
	cursor: pointer;
	user-select: none;

	:hover {
		text-decoration: underline;
	}
`;

type Props = {
	onClick: () => void;
	children: string;
};

export function ClickableColumn({ children, onClick }: Props) {
	// @ts-ignore
	return (
		<A
			title={children}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onClick();
			}}
		>
			{children}
		</A>
	);
}
