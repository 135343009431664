import { object, SchemaOf } from "yup";
import { manufacturer } from "../../moo-enums";
import {
	arrayOptionalSchema,
	arrayRequiredSchema,
	enumRequired,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import type { LogisticsManufacturer } from "../@types/logistics-manufacturer";
import { accountSchema } from "./accountSchema";
import { driverSchema } from "./driverSchema";

// @ts-ignore
export const schema: SchemaOf<LogisticsManufacturer> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: statusSchema,
	type: enumRequired(manufacturer.Value.Value),
	name: stringOptional,
	contact: stringOptional,
	tel: stringOptional,
	fax: stringOptional,
	email: stringOptional,
	drivers: arrayRequiredSchema(driverSchema, "drivers", "請至少提供一位司機"),
	accounts: arrayOptionalSchema(accountSchema),
	note: stringOptional,
});
