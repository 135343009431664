import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../../moo-graphql-provider";

type User = {
	id: string;
	email: string;
	employee: {
		id: number;
	};
};

export function useGetUsersByEmail() {
	const graphQLRequest = useGraphQLRequest();

	const query = gql`
		query ($where: users_bool_exp) {
			users(where: $where) {
				id
				email
				employee {
					id
				}
			}
		}
	`;

	return async (email: string): Promise<User[]> => {
		const variables: Record<string, any> = {
			where: {
				email: {
					_eq: email,
				},
			},
		};

		const { users } = await graphQLRequest({ query, variables });
		return users;
	};
}
