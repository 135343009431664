import { useGraphQLRequest } from "../moo-graphql-provider";
import { GraphQLFindOneResult } from "./findOne/GraphQLFindOneResult";
import { query } from "./findOne/query";

export function useRequestById() {
	const graphQLRequest = useGraphQLRequest();

	return async (id: string): Promise<GraphQLFindOneResult> => {
		return (await graphQLRequest({
			query,
			variables: { id },
		})) as GraphQLFindOneResult;
	};
}
