import { DEFAULT_ONE } from "../";
import { Row } from "../../moo-dialog-order-choose";
import { returnGoods, Status } from "../../moo-enums";
import { useFindOrderById } from "../../moo-order-update";
import { Calculator } from "../../moo-quotation-one";
import { getItemSpec, getItemType, getModelItemId } from "../../moo-shared";
import { One } from "../@types/one";
import { getTotalPrice } from "../getTotalPrice";
import { useForm } from "./useForm";

export function useChooseOrder() {
	const { initialValues, setValues } = useForm();
	const findOneById = useFindOrderById();

	return async (rows: Row[]) => {
		if (rows.length > 0) {
			const order_id = rows[0].id;
			const order = (await findOneById(order_id)).orders_by_pk;

			const one: One = {
				...DEFAULT_ONE(),
				type_id: order.type_id,
				type_name: order.quotation_type.name,
				customer_id: order.customer_id,
				customer_name: order.customer.name,
				sales_id: order.sales_id,
				sales_name: order.sales.name,
				sales_tel: order.sales.tel,
				deliver_date: order.deliver_date,
				is_include_tax: order.is_include_tax,
				payee_id: order.payee_id,
				payee_name: order.payee.name,
				payee_bank: order.payee.bank_name,
				payee_account_number: order.payee.account_number,
				deliver_address: order.deliver_address,
				description: order.description,
				order_note: order.order_note,
				tax_price: order.tax_price,
				total_price: order.total_price,
				receivables: 0,
				handling_fee: order.handling_fee,
				freight: order.freight,
				miscellaneous_charge: order.miscellaneous_charge,
				commission_refund: order.commission_refund,
				note: order.note,
				memo: order.memo,
				status: Status.Present,
				order_id,
				received: 0,
				state_accountant: returnGoods.StateAccountant.Value.WaitToRefund,
				state_warehouse: returnGoods.StateWarehouse.Value.WaitToEnter,
				items: order.items.map((orderItem, priority) => {
					const item = (orderItem.item_spec || orderItem.item_custom)!;
					const id = orderItem.id;
					const count = orderItem.count;

					return {
						return_good_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: id }),
						id,
						manufacturer_type: item.manufacturer.type,
						manufacturer_id: item.manufacturer.id,
						manufacturer: item.manufacturer.name,
						category: item.category.name,
						type: getItemType(id),
						name: item.name,
						cost: item.cost,
						spec: getItemSpec(item),
						material: item.material,
						color: item.color,
						size: item.size,
						count,
						price: item.price,
						price_final: orderItem.price_final,
						price_total: Calculator.getItemPriceTotal({ price_final: orderItem.price_final, count }),
						purchase_price: item.purchase_price,
						purchase_price_total: Calculator.getItemPurchasePriceTotal({ purchase_price: item.purchase_price, count }),
						note: item.note,
						image: item.image,
						image_cut: item.image_cut,
						priority,
						return_count: 1,
						warehouse_id: null,
					};
				}),
			};
			one.total_price = getTotalPrice(one);
			setValues(one);
		}
	};
}
