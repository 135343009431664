import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    state_accountant
    state_warehouse
    return_date
    order_id
    type_id
    quotation_type {
        name
    }
    customer_id
    customer {
        name
    }
    sales_id
    sales {
        name
        tel
    }
    deliver_date
    is_include_tax
    payee_id
    payee {
        name
        account_number
        bank_name
    }
    deliver_address
    description
    order_note
    tax_price
    total_price
    received
    receivables
    handling_fee
    freight
    miscellaneous_charge
    commission_refund
    note
    memo
    voucher_rows {
      voucher_id
    }
    order{
      items{
        id
        count
        price_final
      }
    }
    items {
        return_good_item_id
        id
        return_count
        warehouse_id
        priority
        item_spec {
            name
            cost
            material
            color
            size
            price
            purchase_price
            note
            image
            image_cut
            manufacturer{
              name
              id
              type
            }
            category {
              name
            }
        }
        item_custom {
            name
            cost
            material
            color
            size
            price
            purchase_price
            note
            image
            image_cut
            manufacturer{
              name
              id
              type
            }
            category {
              name
            }
        }
    }
`;

export const query = gql`
    query ($id: String!) {
        return_goods_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
