import { FC } from "react";
import { MooDialogChoose } from "../moo-dialog-choose";
import { Row } from "./@types";
import { TypeSelect } from "./TypeSelect";
import { useCells } from "./hooks/useCells";
import { useGraphQLColumns } from "./hooks/useGraphQLColumns";
import { useHeaders } from "./hooks/useHeaders";
import { useMapResults } from "./hooks/useMapResults";
import useStore from "./stores/useStore";

export type MooDialogLinkChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (rows: Row[]) => void;
	isMultiple?: boolean;
};

export const MooDialogLinkChoose: FC<MooDialogLinkChooseProps> = ({ id, open, onClose, onOk, isMultiple = true }) => {
	const headers = useHeaders();
	const cells = useCells();
	const store = useStore();
	const graphQLColumns = useGraphQLColumns();
	const mapResults = useMapResults();

	return (
		<MooDialogChoose
			id={id}
			hasSearch={false}
			title=""
			titleActions={<TypeSelect />}
			open={open}
			onClose={onClose}
			onOk={onOk}
			headers={headers}
			//@ts-ignore
			mapResults={mapResults}
			isMultiple={isMultiple}
			cells={cells}
			graphQLColumns={graphQLColumns}
			label=""
			resource={store.type}
			store={store}
		/>
	);
};
