import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class OrderGoodsItemsTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "order_good_item_id";
	}

	getTableName(): string {
		return "order_good_items";
	}

	getPathName(): string {
		return "items";
	}

	getParentIdName(): string {
		return "order_good_id";
	}

	getToUpdateColumns(): string[] {
		return ["id", "count", "price_final", "priority"];
	}
}
