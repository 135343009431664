export enum Value {
	UnVerified = "UNVERIFIED",
	Verified = "VERIFIED",
}

export enum Label {
	UnVerified = "未驗收",
	Verified = "已驗收",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.UnVerified:
			return Label.UnVerified;
		case Value.Verified:
			return Label.Verified;
	}
	return Label.UnVerified;
}
