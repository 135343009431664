import { useNavigate, useParams } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooOrderGoodsUpdate } from "../../components/moo-components/moo-order-goods-update";
import { path as listPath } from "./ListRoute";

export const prefixPath = "/order_goods";
export const path = prefixPath + "/:id";

export const UpdateRoute = () => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const branch = useBranchInQuery();

	return (
		<MooOrderGoodsUpdate
			id={id!}
			onCancel={async () => {
				await navigate(`${listPath}?branch=${branch}`);
			}}
		/>
	);
};

export default UpdateRoute;
