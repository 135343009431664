import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import useStore from "../stores/useStore";

export function useGraphQLWhere(): Record<string, any> {
	const { value: branch } = useBranch();
	const {
		query: { owner_id, category, sub_category, material, color, search, manufacturer_id, manufacturer_type },
	} = useStore();
	let where: Record<string, any> = {
		branch_id: {
			_eq: branch,
		},
		status: {
			_eq: Status.Present,
		},
	};

	if (category !== null) {
		if (sub_category !== null) {
			where = {
				_and: {
					category_id: {
						_eq: category,
					},
					sub_category_id: {
						_eq: sub_category,
					},
				},
			};
		} else {
			where = {
				category_id: {
					_eq: category,
				},
			};
		}
	}

	if (owner_id !== null) {
		where.owner_id = {
			_eq: owner_id,
		};
	}

	if (material !== null) {
		where.material = {
			_eq: material,
		};
	}

	if (color !== null) {
		where.color = {
			_eq: color,
		};
	}

	if (manufacturer_type !== null) {
		where.manufacturer_type = {
			_eq: manufacturer_type,
		};
	}

	if (manufacturer_id !== null) {
		where.manufacturer_id = {
			_eq: manufacturer_id,
		};
	}

	if (search !== null && search.trim() !== "") {
		const _similar = `%(${search.trim().split(" ").join("|")})%`;
		where._or = [
			{
				id: {
					_similar,
				},
			},
			{
				name: {
					_similar,
				},
			},
			{
				color: {
					_similar,
				},
			},
			{
				material: {
					_similar,
				},
			},
			{
				size: {
					_similar,
				},
			},
			{
				category: {
					name: {
						_similar,
					},
				},
			},
			{
				manufacturer: {
					name: {
						_similar,
					},
				},
			},
		];

		if (search === "國內" || search === "國外") {
			where._or.push({
				manufacturer: {
					type: {
						_eq: search === "國內" ? "DOMESTIC" : "FOREIGN",
					},
				},
			});
		}
	}

	return where;
}
