import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooPurchaseOrderList, Query, useCreateStore } from "../../components/moo-components/moo-purchase-order-list";
import { useHistoryQuery } from "../../hooks/useHistoryQuery";
import usePage from "../../hooks/usePage";
import useURLSearchParams from "../../hooks/useURLSearchParams";
import { path as NewRoutePath } from "./NewRoute";
import { prefixPath as UpdateRoutePath } from "./UpdateRoute";

export const path = "/purchase_order";

const useQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();

	return {
		search: urlSearchParams.get("search") || null,
		page,
	};
};

export const ListRoute = () => {
	const query = useQuery();
	const store = useCreateStore(query, { search: null, page: 0 });
	const branch = useBranchInQuery();
	const navigate = useNavigate();

	useHistoryQuery<Query>({ path, store });

	return (
		<MooPurchaseOrderList
			store={store}
			onAdd={async () => {
				await navigate(`${NewRoutePath}?branch=${branch}`);
			}}
			onRowClick={async ({ id }, event) => {
				event.preventDefault();
				await navigate(`${UpdateRoutePath}/${id}?branch=${branch}`);
			}}
		/>
	);
};

export default ListRoute;
