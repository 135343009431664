import { gql } from "@apollo/client";
import { useFormikContext } from "formik";
import * as pdfMake from "pdfmake/build/pdfmake";
import { useCompanyInfo } from "../../../../hooks/useCompanyInfo";
import { useBranch } from "../../moo-branch-provider";
import { pdfFonts, useImageMap } from "../../moo-config";
import { useGraphQLRequest } from "../../moo-graphql-provider";
import { useFindEnumById } from "../../moo-hooks";
import { useMeNickName } from "../../moo-me-provider/hooks/useMeNickName";
import { getDateFormat, getDollarNumberWithCommas } from "../../moo-shared";
import { One } from "../@types/one";

function useGetManufacturerTelsById() {
	const branch = useBranch();
	const graphQLRequest = useGraphQLRequest();

	return async (id: string) => {
		const manufacturer_tels: { id: string; tel: string }[] = (
			await graphQLRequest({
				query: gql`
					query ($where: manufacturer_tels_bool_exp) {
						manufacturer_tels(where: $where) {
							id
							tel
						}
					}
				`,
				variables: {
					where: {
						branch_id: {
							_eq: branch.value,
						},
						id: {
							_eq: id,
						},
					},
				},
			})
		).manufacturer_tels;

		return manufacturer_tels;
	};
}

function useGetItemBody() {
	const { values } = useFormikContext<One>();

	return async (): Promise<any[][]> => {
		return values.items.map((item, index) => {
			return [
				(index + 1).toString(),
				item.id,
				{ image: item.id, width: 80 },
				item.name,
				`${item.material} ${item.color} ${item.size}`,
				item.count,
				getDollarNumberWithCommas(item.purchase_price),
				getDollarNumberWithCommas(item.purchase_price_total),
			];
		});
	};
}

function useGetBody() {
	const { values } = useFormikContext<One>();
	const getItemBody = useGetItemBody();

	return async () => {
		let arr: any[][] = [
			[{ text: "進貨商品", style: "header", colSpan: 8 }, {}, {}, "", "", "", "", ""],
			["編號", "商品編號", "圖片", "品名", "規格", "數量", "進貨成本", "總價"],
		];

		arr = arr.concat(await getItemBody());

		arr.push([
			{},
			{},
			{},
			"",
			"",
			"",
			{
				table: {
					widths: ["*", "*"],
					body: [
						[
							{
								stack: [
									{ text: "合計" },
									{ text: "稅金" },
									{ text: "運費" },
									{ text: "雜費" },
									{ text: "已付款" },
									{ text: "應付款" },
								],
							},
							{
								stack: [
									{ text: getDollarNumberWithCommas(values.accountant_total_price) },
									{ text: getDollarNumberWithCommas(values.accountant_tax) },
									{ text: getDollarNumberWithCommas(values.accountant_freight) },
									{ text: getDollarNumberWithCommas(values.accountant_miscellaneous_charge) },
									{ text: getDollarNumberWithCommas(values.accountant_received) },
									{ text: getDollarNumberWithCommas(values.accountant_payable) },
								],
							},
						],
					],
				},
				colSpan: 2,
				layout: "lightHorizontalLines",
			},
			"",
		]);

		arr.push([
			{
				table: {
					body: [
						[
							{
								text: "備註",
								bold: true,
								margin: [0, 2],
							},
						],
						[
							{
								text: values.accountant_note,
								border: [false, false, false, true],
							},
						],
					],
				},
				colSpan: 6,
				layout: "noBorders",
			},
			{},
			{},
			"",
			"",
			"",
			{
				table: {
					widths: ["*"],
					body: [
						[
							{
								text: "倉庫簽名",
								margin: [0, 3],
								bold: true,
								border: [false, false, false, false],
							},
						],
						[
							{
								text: "",
								margin: [0, 30],
								border: [false, false, false, true],
							},
						],
					],
				},
				colSpan: 2,
				layout: {
					defaultBorder: false,
				},
			},
			{},
		]);
		return arr;
	};
}

export function useOnPdfClick() {
	const getBody = useGetBody();
	const findWarehouseById = useFindEnumById("warehouses");
	const { values, isValid } = useFormikContext<One>();
	const imageMap = useImageMap({ items: values.items });
	const getManufacturerTelsById = useGetManufacturerTelsById();
	const { address, tel, fax } = useCompanyInfo();
	const nickName = useMeNickName();

	return async (id: string) => {
		if (isValid) {
			const FILE_NAME = `進貨單-${id}`;
			const content = [
				{
					style: "defaultTable",
					table: {
						headerRows: 1,
						widths: [80, "*", "auto"],
						body: [
							[
								{ image: "logo", width: 51, height: 64, margin: [0, 10, 0, 0] },
								[
									{ text: address, margin: [0, 18, 0, 3] },
									{ text: `TEL:${tel}`, margin: [0, 3] },
									{ text: `FAX:${fax}`, margin: [0, 3] },
								],
								[
									{
										text: `進貨單${id}${nickName}`,
										fontSize: 16,
										bold: true,
										margin: [0, 11, 0, 3],
									},
									{
										columns: [
											{ text: "承辦人", width: "auto", margin: [0, 3] },
											{ text: values.sales_name, width: "*", margin: [0, 3] },
										],
									},
									{
										columns: [
											{ text: "電話", width: "auto", margin: [0, 3] },
											{ text: values.sales_tel, width: "*", margin: [0, 3] },
										],
									},
								],
							],
							[
								{
									stack: [
										{ text: "廠商", margin: [0, 3, 0, 3] },
										{ text: "電話", margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{ text: values.manufacturer, margin: [0, 3, 0, 3] },
										{
											text: (await getManufacturerTelsById(values.manufacturer_id!))?.map((e) => e.tel).join(",") || "",
											margin: [0, 3],
										},
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{
											columns: [
												{ text: "進貨日期", width: "auto", margin: [0, 3, 0, 3] },
												{ text: getDateFormat(values.import_date), width: "*", margin: [0, 3] },
											],
										},
										{
											columns: [
												{ text: "收貨倉庫", width: "auto", margin: [0, 3, 0, 3] },
												{
													text: (await findWarehouseById(values.warehouse_id!))?.name || "",
													width: "*",
													margin: [0, 3],
												},
											],
										},
									],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [{ text: "訂購單號", margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [{ text: values.order_ids.map((e) => e.value).join(", "), margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [{ text: "訂貨單/國外採購單號", margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [{ text: values.link_id, margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [{ text: "備註", margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [{ text: values.note, margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
						],
					},
					layout: {
						defaultBorder: false,
						hLineColor: "silver",
					},
				},
				{
					style: "defaultTable",
					color: "#444",
					table: {
						widths: [20, 60, 80, "*", "*", 22, 50, 50],
						headerRows: 1,
						body: await getBody(),
					},
					layout: "lightHorizontalLines",
				},
			];

			pdfMake
				.createPdf(
					{
						content,
						images: imageMap,
						styles: {
							header: {
								fontSize: 16,
								bold: true,
							},
							warning: {
								color: "red",
							},
							bigger: {
								fontSize: 15,
								italics: true,
							},
							defaultTable: {
								margin: [0, 5, 0, 5],
							},
							tableHeader: {
								bold: true,
								fontSize: 13,
								color: "grey",
							},
						},
						defaultStyle: {
							columnGap: 15,
							fontSize: 10,
							font: "chineseFont",
						},
					},
					undefined,
					pdfFonts,
				)
				.download(FILE_NAME);
		}
	};
}
