import styled from "@emotion/styled";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { One } from "../..";
import { ProxyKodenTextField } from "../../../../ProxyKodenTextField";
import { useForm } from "../../hooks/useForm";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const HighlightFieldOuterContainer = styled.div`
	background-color: #f2f3f5;
`;

const HighlightFieldInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};
	background: #f2f3f5;
	border-radius: 0.2rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.5rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

export const SummaryArea = () => {
	const { values, setFieldValue, setValues } = useForm();
	const updateDirectly = (attr: keyof One, value: number) => {
		const _values = {
			...values,
			[attr]: value,
		};

		setValues({
			..._values,
			receivables: _values.total_price - _values.received,
		});
	};

	const arr: {
		id: keyof One;
		min: number;
		label: string;
		readOnly?: boolean;
		onChange: (attr: keyof One, value: number) => void;
	}[] = [
		{ id: "total_price", min: 0, label: "合計", onChange: updateDirectly },
		{ id: "tax_price", min: 0, label: "稅金", onChange: updateDirectly },
		{ id: "received", min: 0, label: "已退款", onChange: updateDirectly },
		{ id: "receivables", min: 0, label: "應退款", readOnly: true, onChange: updateDirectly },
		{ id: "handling_fee", min: 0, label: "手續費", onChange: updateDirectly },
		{ id: "freight", min: 0, label: "運費", onChange: updateDirectly },
		{ id: "miscellaneous_charge", min: 0, label: "雜項", onChange: updateDirectly },
	];

	return (
		<HighlightFieldOuterContainer>
			<HighlightFieldInnerContainer>
				{arr.map(({ id, label, min, readOnly = false, onChange }) => {
					return (
						<KodenNumberField
							id={id}
							key={id}
							error=""
							value={values[id] as number}
							label={label}
							outlined
							margin="none"
							hasDollar
							readOnly={readOnly}
							disabled={readOnly}
							step={1}
							min={min}
							max={Number.MAX_SAFE_INTEGER}
							onChange={(value) => {
								onChange(id, value);
							}}
						/>
					);
				})}
				<TextFieldEnd
					id="note"
					label="備註"
					outlined
					margin="none"
					value={values.note}
					error=""
					onChange={(note) => setFieldValue("note", note)}
				/>
			</HighlightFieldInnerContainer>
		</HighlightFieldOuterContainer>
	);
};

export default SummaryArea;
