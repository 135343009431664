import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "退貨單號",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "return_date",
			label: "退貨日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "customer_name",
			label: "客戶",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "sales_name",
			label: "業務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "state_warehouse",
			label: "倉管確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state_accountant",
			label: "會計確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "total_price",
			label: "退費金額",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "note",
			label: "備註",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "order_id",
			label: "原訂購單",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
	];
}
