import produce from "immer";
import { Query } from "../@types";
import { DEFAULT_QUERY } from "./DEFAULT_QUERY";
import { StoreSlice } from "./useStore";

export type QuerySlice = {
	query: Query;
	setQuery: (query: Partial<Query>) => void;
	clear: () => void;
};

export const createQuerySlice: StoreSlice<QuerySlice> = (set, get) => ({
	query: DEFAULT_QUERY,
	setQuery(partialQuery) {
		set(
			produce((draft: QuerySlice) => {
				draft.query = { ...draft.query, ...partialQuery };
				return draft;
			}),
		);
	},
	clear() {
		get().setQuery(DEFAULT_QUERY);
	},
});
