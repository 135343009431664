import styled from "@emotion/styled";

const Container = styled.ul`
	color: #d32f2f;
	font-size: 0.75rem;
`;

export function ErrorMessage({ values }: { values: string[] }) {
	const _values = values.filter((e) => e !== "");
	if (_values.length === 0) return null;

	return (
		<Container>
			{_values.map((e) => (
				<li key={e}>{e}</li>
			))}
		</Container>
	);
}
