import { FC, useState } from "react";
import type { StoreState } from "../moo-dialog-choose";
import { MooDialogChoose, Row } from "../moo-dialog-choose";
import {
	cells as ogCells,
	headers as ogHeaders,
	Row as DialogOrderGoodsRow,
	useStore as useOrderGoodsStore,
} from "../moo-dialog-order-goods-choose";
import {
	cells as poCells,
	headers as poHeaders,
	useStore as usePurchaseOrderStore,
} from "../moo-dialog-purchase-order-choose";
import { CellRow as DialogPurchaseOrderGoodsRow } from "../moo-dialog-purchase-order-choose/@types";
import { graphQLColumns as orderGoodsListColumns } from "../moo-order-goods-list";
import { GraphQLColumnsResult as OrderGoodsGraphQLColumnsResult } from "../moo-order-goods-list/graphQLColumns";
import { graphQLColumns as purchaseOrderListColumns } from "../moo-purchase-order-list";
import { GraphQLColumnsResult as PurchaseOrderGraphQLColumnsResult } from "../moo-purchase-order-list/graphQLColumns";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

export enum ChooseType {
	OrderGoods = "order_goods",
	PurchaseOrder = "purchase_orders",
}

export type MooDialogOrderAndPurchaseOrderChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (chooseType: ChooseType, data: Row[]) => void;
};

export const MooDialogOrderGoodsAndPurchaseGoodsChoose: FC<MooDialogOrderAndPurchaseOrderChooseProps> = ({
	id,
	open,
	onClose,
	onOk,
}) => {
	const [type, setType] = useState<ChooseType>(ChooseType.OrderGoods);
	const isOrderGoodsType = type === ChooseType.OrderGoods;
	const orderGoodsStore = useOrderGoodsStore();
	const purchaseOrderStore = usePurchaseOrderStore();
	// @ts-ignore
	const store: StoreState = isOrderGoodsType ? orderGoodsStore : purchaseOrderStore;

	return (
		<MooDialogChoose
			id={id}
			hasSearch={false}
			title=""
			titleActions={
				<MooTypeableSelect
					id="type"
					label="類別"
					value={type}
					options={[
						{ label: "訂貨單", value: ChooseType.OrderGoods },
						{ label: "國外採購單", value: ChooseType.PurchaseOrder },
					]}
					onChange={(e) => setType(e as ChooseType)}
				/>
			}
			open={open}
			onClose={onClose}
			onOk={(rows) => onOk(type, rows)}
			headers={isOrderGoodsType ? ogHeaders : poHeaders}
			isMultiple={false}
			cells={isOrderGoodsType ? ogCells : poCells}
			graphQLColumns={isOrderGoodsType ? orderGoodsListColumns : purchaseOrderListColumns}
			label=""
			resource={type}
			store={store}
			mapResults={(e) => {
				if (isOrderGoodsType) {
					const rows = e as unknown as OrderGoodsGraphQLColumnsResult[] | null;
					const results: DialogOrderGoodsRow[] =
						rows?.map((e) => ({
							id: e.id,
							sales: e?.sales?.name || "",
							manufacturer: e?.manufacturer?.name || "",
							order_goods_date: e.order_goods_date || "",
							state: e.state,
							state_warehouse: e.state_warehouse,
						})) || [];
					return results;
				} else {
					const rows = e as unknown as PurchaseOrderGraphQLColumnsResult[] | null;
					const results: DialogPurchaseOrderGoodsRow[] =
						rows?.map((e) => ({
							id: e.id,
							owner: {
								name: e?.owner?.name || "",
							},
							foreign_manufacturer: {
								name: e?.foreign_manufacturer?.name || "",
							},
							deliver_date: e.deliver_date || "",
							state: e.state,
							state_manager: e.state_manager,
							state_accountant: e.state_accountant,
						})) || [];
					return results;
				}
			}}
		/>
	);
};
