import { ReactNode } from "react";
import { Value } from "./@types/value";
import { MeContext } from "./context";

export type MooMeProviderProps = {
	children: ReactNode;
	value: Value;
};

export function MooMeProvider({ children, value }: MooMeProviderProps) {
	return <MeContext.Provider value={{ value }}>{children}</MeContext.Provider>;
}
