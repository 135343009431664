import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    state
    state_accountant
    type
    import_date
    link_id
    order_ids {
      import_good_order_id_id
      value
    }
    sales {
      id
      name
      tel
    }
    manufacturer {
      type
      id
      name
    }
    warehouse_id
    note
    accountant_tax
    accountant_freight
    accountant_miscellaneous_charge
    accountant_received
    accountant_note
    voucher_rows {
      voucher_id
    }
    items {
      import_good_item_id
      id
      count
      item_spec {
        manufacturer {
          type
          id
          name
        }
        image
        image_cut
        name
        material
        color
        size
        purchase_price
      }
      item_custom {
        manufacturer {
          type
          id
          name
        }
        image
        image_cut
        name
        material
        color
        size
        purchase_price
      }
    }
`;

export const query = gql`
    query ($id: String!) {
        import_goods_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
