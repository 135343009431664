import { useGetGcsUrl } from "@yachelee/koden-components/koden-gcs-provider";
import { defaultLogoVertical } from "../moo-config";

export type MooItemListImgProps = {
	image: string | null;
	image_cut: string | null;
	name: string;
};

export function MooItemListImg({ image, image_cut, name }: MooItemListImgProps) {
	const getGcsUrl = useGetGcsUrl();
	const href = image !== null ? getGcsUrl(image) : defaultLogoVertical;
	const src = image_cut !== null ? getGcsUrl(image_cut) : defaultLogoVertical;

	return (
		<a href={href} title={name} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
			<img style={{ maxWidth: 220, maxHeight: 100 }} src={src} alt={name} />
		</a>
	);
}
