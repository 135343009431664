import { ReactNode } from "react";
import styled from "styled-components";

export type MooTagsProps = {
	tags: string[];
	onClick: (tag: string) => void;
	emptyMsg?: string;
	tagIcon?: ReactNode;
};

const MooTag = styled.div`
	width: fit-content;
	color: #ffffff;
	background-color: #8c9fcb;
	font-size: 0.8rem;
	padding: 0.8rem;
	border-radius: 4px;
	user-select: none;
	cursor: pointer;
	display: flex;
	align-items: center;
`;

const Container = styled.div`
	width: 100%;
	display: inline-flex;
	flex-wrap: wrap;

	> div {
		margin-right: 6px;
		margin-bottom: 6px;
	}
`;

const EmptyMessage = styled.div`
	display: flex;
	justify-content: center;
	color: #c4c4c4;
`;

export function MooTags({ tags, onClick, emptyMsg, tagIcon }: MooTagsProps) {
	return (
		<Container>
			{(!tags || tags.length === 0) && emptyMsg ? (
				<EmptyMessage>{emptyMsg}</EmptyMessage>
			) : (
				tags.map((tag) => (
					<MooTag key={tag} onClick={() => onClick(tag)} title={tag}>
						{tagIcon || ""}
						{tag}
					</MooTag>
				))
			)}
		</Container>
	);
}
