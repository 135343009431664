import { useParams } from "react-router-dom";
import { MooWeeklyScheduleOne } from "../../components/moo-components/moo-weekly-schedule-one";

export const prefixPath = "/schedule";
export const path = prefixPath + "/:id";

export const OneRoute = () => {
	const { id } = useParams<{ id: string }>();

	return <MooWeeklyScheduleOne id={id!} />;
};

export default OneRoute;
