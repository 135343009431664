import { createContext, ReactNode, useContext, useState } from "react";
import { MooWarehouseFieldProps } from "./moo-warehouse-field";

export type Props = MooWarehouseFieldProps & {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
};

const context = createContext<Props>({
	warehouseId: null,
	itemIds: [],
	onChange: null as unknown as MooWarehouseFieldProps["onChange"],
	label: null as unknown as MooWarehouseFieldProps["label"],
	disabled: null as unknown as MooWarehouseFieldProps["disabled"],
	isOpen: false,
	setIsOpen() {},
});

export function Provider({
	warehouseId,
	itemIds,
	onChange,
	error,
	label,
	disabled,
	children,
}: MooWarehouseFieldProps & {
	children: ReactNode;
}) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<context.Provider
			value={{
				warehouseId,
				itemIds,
				onChange,
				label,
				error,
				disabled,
				isOpen,
				setIsOpen,
			}}
		>
			{children}
		</context.Provider>
	);
}

export function useInternalState() {
	return useContext(context);
}
