import { graphQLColumns as graphQLColumnsExportGoods } from "../../moo-export-goods-list";
import { graphQLColumns as graphQLColumnsImportGoods } from "../../moo-import-goods-list";
import { graphQLColumns as graphQLColumnsOrderGoods } from "../../moo-order-goods-list";
import { graphQLColumns as graphQLColumnsOrder } from "../../moo-order-list";
import { graphQLColumns as graphQLColumnsPurchaseOrders } from "../../moo-purchase-order-list";
import { graphQLColumns as graphQLColumnsQuotation } from "../../moo-quotation-list";
import { graphQLColumns as graphQLColumnsRequestBuy } from "../../moo-request-buy-list";
import { graphQLColumns as graphQLColumnsReturnGoods } from "../../moo-return-goods-list";
import { LinkType } from "../@types";
import useStore from "../stores/useStore";

export function useGraphQLColumns(): string {
	const { type } = useStore();
	switch (type) {
		case LinkType.Quotation:
			return graphQLColumnsQuotation;
		case LinkType.Order:
			return graphQLColumnsOrder;
		case LinkType.ImportGoods:
			return graphQLColumnsImportGoods;
		case LinkType.ExportGoods:
			return graphQLColumnsExportGoods;
		case LinkType.PurchaseOrder:
			return graphQLColumnsPurchaseOrders;
		case LinkType.ReturnGoods:
			return graphQLColumnsReturnGoods;
		case LinkType.OrderGoods:
			return graphQLColumnsOrderGoods;
		case LinkType.RequestBuy:
			return graphQLColumnsRequestBuy;
	}
}
