import { PdfStyle } from "./useOnBalanceSheetButtonClick";

export function updateBodyRatio({ bodyData, totalSum }: { bodyData: any[][]; totalSum: number }) {
	for (const body of bodyData) {
		const text = body[2].text as string;
		const number = Number(text.replace(/,/g, ""));
		const ratio = (number / totalSum) * 100;
		body[4] = {
			text: number === 0 ? "0%" : ratio.toFixed(2) + "%",
			style: [PdfStyle.AlignmentRight],
		};

		if (number < 0) {
			body[4].style.push(PdfStyle.ColorRed);
		}
	}
}
