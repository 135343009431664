import produce from "immer";
import { useForm } from "./useForm";

export function useDelItemInfo() {
	const { setFieldValue, values } = useForm();

	return (request_buy_item_info_id: string) => {
		setFieldValue(
			"item_infos",
			produce(values.item_infos, (draft) => {
				const index = draft.findIndex((e) => e.request_buy_item_info_id === request_buy_item_info_id);
				if (index !== -1) {
					draft.splice(index, 1);
				}
			}),
		);
	};
}
