import { DocumentNode, gql } from "@apollo/client";
import produce from "immer";
import { CSSProperties } from "react";
import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooTypeableSelect, Option } from "../moo-typable-select/MooTypeableSelect";

type ValueType = string | null;

type GraphQLResultRow = {
	id: string;
	name: string;
};

export type MooEnumTypeableSelectFieldProps = {
	id: string;
	resource: string;
	label: string;
	defaultLabel?: string;
	value: ValueType;
	onChange: (value: ValueType) => void;
	ignoreIds?: string[];
	error?: string;
	disabled?: boolean;
	style?: CSSProperties;
	filter?: Record<string, any>;
};

function getQuery(resource: string): DocumentNode {
	return gql`
    query ($where: ${resource}_bool_exp){
      ${resource}(where: $where) {
        id
        name
      }
    }
  `;
}

function useVariables({
	filter,
	ignoreIds,
}: {
	filter: Record<string, any>;
	ignoreIds: string[];
}): Record<string, any> {
	const { value } = useBranch();
	let where: Record<string, any> = {
		status: {
			_eq: Status.Present,
		},
		branch_id: {
			_eq: value,
		},
	};

	// @ts-ignore
	where = produce(where, (where) => {
		where = { ...where, ...filter };
		if (ignoreIds.length > 0) {
			where.id = {
				_nin: ignoreIds,
			};
		}
		return where;
	});

	return {
		where,
	};
}

export function MooEnumTypeableSelectField({
	id,
	resource,
	label,
	error = "",
	value,
	onChange,
	disabled = false,
	ignoreIds = [],
	filter = {},
	style = {},
	defaultLabel = "請選擇",
}: MooEnumTypeableSelectFieldProps) {
	const variables = useVariables({ filter, ignoreIds });
	const query = getQuery(resource);
	const { isLoading, data } = useGraphQLQuery({ operationName: id, query, variables });
	const options: Option[] = [
		{
			value: null as string | null,
			label: defaultLabel,
		},
	].concat(
		(((data as any)?.[resource] || []) as GraphQLResultRow[]).map((e) => ({
			value: e.id,
			label: e.name,
		})),
	);

	return (
		<MooTypeableSelect
			id={id}
			options={options}
			isLoading={isLoading}
			label={label}
			error={error}
			disabled={disabled}
			value={value?.toString() || null}
			onChange={onChange}
		/>
	);
}
