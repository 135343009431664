import produce from "immer";
import { MooItemRowList } from "../../../moo-item-row-list";
import { FieldType as SpecFieldType } from "../../../moo-item-spec-and-custom-row";
import { ItemBound } from "../../../moo-types";
import { Item } from "../../@types/one";
import { useForm } from "../../hooks/useForm";
import { useSyncItemPrice } from "../../hooks/useSyncItemPrice";

function mapItemsToItemBounds(items: Item[]): ItemBound[] {
	return items.map((item) => ({
		id: item.id,
		image: item.image,
		image_cut: item.image_cut,
		manufacturer_id: item.manufacturer_id,
		manufacturer_type: item.manufacturer_type,
		manufacturer: item.manufacturer,
		category: item.category,
		type: item.type,
		name: item.name,
		cost: item.cost,
		spec: item.spec,
		material: item.material,
		color: item.color,
		size: item.size,
		count: item.count,
		price: item.price,
		price_final: item.price_final,
		price_total: item.price_total,
		purchase_price: item.purchase_price,
		purchase_price_total: item.purchase_price_total,
		note: item.note,
		priority: item.priority,
	}));
}
const ItemList = () => {
	const { values, setFieldValue } = useForm();
	const syncItemPrice = useSyncItemPrice();

	return (
		<MooItemRowList
			emptyMessage="尚未匯入訂貨商品"
			specCustomFields={
				new Set<SpecFieldType>([
					"id",
					"manufacturer_type",
					"manufacturer",
					"type",
					"name",
					"category",
					"remove",
					"size",
					"count",
					"price",
					"price_final",
					"purchase_price",
					"price_total",
				])
			}
			items={mapItemsToItemBounds(values.items)}
			onCountChange={(id, value) => {
				setFieldValue(
					"items",
					produce(values.items, (items) => {
						const index = items.findIndex((e) => e.id === id);
						if (index !== -1) {
							const item = items[index];
							item.count = value;
							syncItemPrice(items);
						}
					}),
				);
			}}
			onPriceFinalChange={(id, value) => {
				setFieldValue(
					"items",
					produce(values.items, (items) => {
						const index = items.findIndex((e) => e.id === id);
						if (index !== -1) {
							const item = items[index];
							item.price_final = value;
							syncItemPrice(items);
						}
					}),
				);
			}}
			onRemove={(id) => {
				setFieldValue(
					"items",
					produce(values.items, (items) => {
						const index = items.findIndex((e) => e.id === id);
						if (index !== -1) {
							items.splice(index, 1);
							syncItemPrice(items);
						}
					}),
				);
			}}
		/>
	);
};

export default ItemList;
