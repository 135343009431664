export function getSearchCondition(search: string | null): Record<string, any> {
	if (search !== null && search.trim() !== "") {
		const _similar = `%(${search.trim().split(" ").join("|")})%`;
		return {
			_or: [
				{
					name: {
						_similar,
					},
				},
				{
					tels: {
						tel: {
							_similar,
						},
					},
				},
				{
					email: {
						_similar,
					},
				},
				{
					address: {
						_similar,
					},
				},
				{
					line: {
						_similar,
					},
				},
				{
					customer_type: {
						name: {
							_similar,
						},
					},
				},
			],
		};
	} else {
		return {};
	}
}
