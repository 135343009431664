import produce from "immer";
import { FC } from "react";
import { Permission, usePermissions } from "../moo-me-provider";
import { DEFAULT_ONE, MooPurchaseOrderOne, One } from "../moo-purchase-order-one";
import { useHasuraAdd } from "../moo-shared";

export type MooPurchaseOrderAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooPurchaseOrderAdd: FC<MooPurchaseOrderAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("purchase_orders", onSuccess, (e) => {
		return produce(e, (draft: Partial<One>) => {
			delete draft.foreign_manufacturer_tel;
			delete draft.foreign_manufacturer_fax;
			delete draft.foreign_manufacturer_email;
			delete draft.foreign_manufacturer_country;
			delete draft.foreign_manufacturer_currency;
			delete draft.foreign_manufacturer_bank_account;
			delete draft.foreign_manufacturer_swift_code;
			delete draft.foreign_manufacturer_code_iban;
			delete draft.accountant_total_price;

			draft.items!.forEach((item, index) => {
				// @ts-ignore
				draft.items[index] = {
					purchase_order_item_id: item.purchase_order_item_id,
					id: item.id,
					count: item.count,
					priority: index,
				};
			});
		});
	});
	const permissions = usePermissions();

	return (
		<MooPurchaseOrderOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.PurchaseOrderAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
