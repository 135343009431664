import { gql } from "@apollo/client";
import { Status } from "../moo-enums";
import { useGraphQLRequest } from "../moo-graphql-provider";

type Callback = {
	id: any;
	name?: string;
	idName?: string;
};

export function useRecoverResource(resource: string) {
	const graphQLRequest = useGraphQLRequest();

	return async ({ id, name = "這筆資料", idName = "id" }: Callback): Promise<boolean> => {
		if (window.confirm(`確定要恢復${name}?`)) {
			await graphQLRequest({
				query: gql`
            mutation($where: ${resource}_bool_exp!, $set:${resource}_set_input){
							update_${resource}(where: $where, _set: $set){
								affected_rows
							}
            }
				`,
				variables: {
					where: {
						[idName]: {
							_eq: id,
						},
					},
					set: {
						status: Status.Present,
					},
				},
			});
			return true;
		} else {
			return false;
		}
	};
}
