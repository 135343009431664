import { object, SchemaOf } from "yup";
import { ItemType, manufacturer } from "../../moo-enums";
import { enumRequired, numberOptional, numberRequired, stringOptional, stringRequired } from "../../moo-schemas";
import { Item } from "../@types/one";

// @ts-ignore
export const itemSchema: SchemaOf<Item> = object().shape({
	stock_transfer_item_id: stringRequired("stock_transfer_item_id"),
	id: stringRequired("id"),
	type: enumRequired(ItemType),
	category: stringOptional,
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer_id: stringOptional,
	manufacturer: stringRequired("id"),
	name: stringOptional,
	image: stringOptional,
	image_cut: stringOptional,
	material: stringOptional,
	color: stringOptional,
	size: stringOptional,
	purchase_price: numberOptional,
	price: numberOptional,
	cost: numberOptional,
	priority: numberOptional,
	transfer_count: numberRequired("transfer_count"),
});
