import produce from "immer";
import { One } from "../@types/one";
import { useChooseItems } from "./useChooseItems";
import { useForm } from "./useForm";

export function useUpdate() {
	const { values, setValues } = useForm();
	const chooseItems = useChooseItems();

	function updateItemCount(id: string, count: number) {
		setValues(
			produce(values, (one: One) => {
				const index = one.items.findIndex((e) => e.id === id);
				if (index !== -1) {
					one.items[index].count = count;
				}
			}),
		);
	}

	function deleteItem(id: string) {
		setValues(
			produce(values, (one: One) => {
				const index = one.items.findIndex((e) => e.id === id);
				if (index !== -1) {
					//remove
					one.items.splice(index, 1);
				}
			}),
		);
	}

	return {
		chooseItems(rows: any) {
			return chooseItems(rows);
		},
		updateItemCount,
		deleteItem,
	};
}
