import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";

type Value = string | null;

type Props = {
	parentId: Value;
	value: Value;
	error?: string;
	label: string;
	onChange: (value: Value) => void;
};

export function ItemCategory({ label, value, onChange, error = "", parentId }: Props) {
	const parent =
		parentId === null
			? {
					_is_null: true,
			  }
			: {
					_eq: parentId,
			  };
	return (
		<MooEnumTypeableSelectField
			id={label}
			resource="item_categories"
			label={label}
			value={value}
			error={error}
			filter={{
				parent,
			}}
			onChange={onChange}
		/>
	);
}

export default ItemCategory;
