import { useParams } from "react-router-dom";
import { IdType, MooManufacturerType } from "../components/moo-components/moo-manufacturer-type";

export const path = "/manufacturer/types/:id";

const ManufacturerTypeOneRoute = () => {
	const { id } = useParams<{ id: IdType }>();

	return <MooManufacturerType id={id!} />;
};

export default ManufacturerTypeOneRoute;
