import { gql } from "@apollo/client";

export const query = gql`
	query ($id: uuid!) {
		warehouses_by_pk(id: $id) {
			branch {
				id
				name
			}
			id
			name
			status
		}
	}
`;
