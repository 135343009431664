import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    
    type_id
    name
    fax
    email
    country
    currency
    address
    website
    note
    accounts {
        manufacturer_foreign_account_id
        bank_account
        swift_code
        code_iban
    }
    tels {
        manufacturer_foreign_tel_id
        tel
    }
`;

export const query = gql`
    query ($id: uuid!) {
        manufacturer_foreigns_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
