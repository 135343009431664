import { useSnackbar } from "notistack";

export function useSuccess() {
	const { enqueueSnackbar } = useSnackbar();

	return (message: string) => {
		enqueueSnackbar(message, {
			variant: "success",
		});
	};
}
