import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import type { CellType } from "@yachelee/koden-components/koden-table";
import produce from "immer";
import { useState } from "react";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { DollarNumber } from "../moo-shared";
import { Row } from "./@types";

const CANCEL_ROW = null;

export function useCells(onSubmit: (row: Row) => void): CellType[] {
	const [editRow, setEditRow] = useState<Row | null>(CANCEL_ROW);

	return [
		{
			id: "employee_name",
			align: "left",
			component: ({ employee_name }: Row) => {
				return <div>{employee_name}</div>;
			},
		},
		{
			id: "salary_basic",
			align: "left",
			component: ({ salary_basic }: Row) => {
				return <DollarNumber>{salary_basic}</DollarNumber>;
			},
		},
		{
			id: "perfect_attendance",
			align: "left",
			component: ({ id, perfect_attendance }: Row) => {
				if (editRow !== null && id === editRow.id) {
					return (
						<KodenNumberField
							id={`perfect_attendance_${id}`}
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							label=""
							error=""
							value={editRow.perfect_attendance}
							hasDollar
							onChange={(value) => {
								setEditRow(
									produce(editRow, (draft) => {
										draft.perfect_attendance = value;
									}),
								);
							}}
						/>
					);
				}
				return <DollarNumber>{perfect_attendance}</DollarNumber>;
			},
		},
		{
			id: "bonus",
			align: "left",
			component: ({ bonus }: Row) => {
				return <DollarNumber>{bonus}</DollarNumber>;
			},
		},
		{
			id: "gift_money",
			align: "left",
			component: ({ id, gift_money }: Row) => {
				if (editRow !== null && id === editRow.id) {
					return (
						<KodenNumberField
							id={`gift_money_${id}`}
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							label=""
							error=""
							value={editRow.gift_money}
							hasDollar
							onChange={(value) => {
								setEditRow(
									produce(editRow, (draft) => {
										draft.gift_money = value;
									}),
								);
							}}
						/>
					);
				}
				return <DollarNumber>{gift_money}</DollarNumber>;
			},
		},
		{
			id: "other_addition",
			align: "left",
			component: ({ id, other_addition }: Row) => {
				if (editRow !== null && id === editRow.id) {
					return (
						<KodenNumberField
							id={`other_addition_${id}`}
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							label=""
							error=""
							value={editRow.other_addition}
							hasDollar
							onChange={(value) => {
								setEditRow(
									produce(editRow, (draft) => {
										draft.other_addition = value;
									}),
								);
							}}
						/>
					);
				}
				return <DollarNumber>{other_addition}</DollarNumber>;
			},
		},
		{
			id: "addition_sum",
			align: "left",
			component: ({ addition_sum }: Row) => {
				return <DollarNumber>{addition_sum}</DollarNumber>;
			},
		},
		{
			id: "labor_fee",
			align: "left",
			component: ({ labor_fee }: Row) => {
				return <DollarNumber>{labor_fee}</DollarNumber>;
			},
		},
		{
			id: "healthcare_fee",
			align: "left",
			component: ({ healthcare_fee }: Row) => {
				return <DollarNumber>{healthcare_fee}</DollarNumber>;
			},
		},
		{
			id: "late",
			align: "left",
			component: ({ id, late }: Row) => {
				if (editRow !== null && id === editRow.id) {
					return (
						<KodenNumberField
							id={`late_${id}`}
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							label=""
							error=""
							value={editRow.late}
							hasDollar
							onChange={(value) => {
								setEditRow(
									produce(editRow, (draft) => {
										draft.late = value;
									}),
								);
							}}
						/>
					);
				}
				return <DollarNumber>{late}</DollarNumber>;
			},
		},
		{
			id: "pto",
			align: "left",
			component: ({ id, pto }: Row) => {
				if (editRow !== null && id === editRow.id) {
					return (
						<KodenNumberField
							id={`pto_${id}`}
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							label=""
							error=""
							value={editRow.pto}
							hasDollar
							onChange={(value) => {
								setEditRow(
									produce(editRow, (draft) => {
										draft.pto = value;
									}),
								);
							}}
						/>
					);
				}
				return <div>{pto}</div>;
			},
		},
		{
			id: "other_deduction",
			align: "left",
			component: ({ id, other_deduction }: Row) => {
				if (editRow !== null && id === editRow.id) {
					return (
						<KodenNumberField
							id={`other_deduction_${id}`}
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							label=""
							error=""
							value={editRow.other_deduction}
							hasDollar
							onChange={(value) => {
								setEditRow(
									produce(editRow, (draft) => {
										draft.other_deduction = value;
									}),
								);
							}}
						/>
					);
				}
				return <DollarNumber>{other_deduction}</DollarNumber>;
			},
		},
		{
			id: "deduction_sum",
			align: "left",
			component: ({ deduction_sum }: Row) => {
				return <DollarNumber>{deduction_sum}</DollarNumber>;
			},
		},
		{
			id: "monthly_salary",
			align: "left",
			component: ({ monthly_salary }: Row) => {
				return <DollarNumber>{monthly_salary}</DollarNumber>;
			},
		},
		{
			id: "bank",
			align: "left",
			component: ({ bank }: Row) => {
				return <div>{bank}</div>;
			},
		},
		{
			id: "insurance_day",
			align: "left",
			component: ({ insurance_day }: Row) => {
				return <div>{insurance_day}</div>;
			},
		},
		{
			id: "insurance_unit",
			align: "left",
			component: ({ insurance_unit }: Row) => {
				return <div>{insurance_unit}</div>;
			},
		},
		{
			id: "note",
			align: "left",
			component: ({ id, note }: Row) => {
				if (editRow !== null && id === editRow.id) {
					return (
						<ProxyKodenTextField
							id={`note_${id}`}
							label=""
							error=""
							value={editRow.note}
							onChange={(value) => {
								setEditRow(
									produce(editRow, (draft) => {
										draft.note = value;
									}),
								);
							}}
						/>
					);
				}
				return <div>{note}</div>;
			},
		},
		{
			id: "edit",
			align: "left",
			component: (row: Row) => {
				if (editRow !== null && row.id === editRow.id) {
					return (
						<>
							<KodenButton
								onClick={() => {
									if (window.confirm("確定要取消嗎?")) {
										setEditRow(CANCEL_ROW);
									}
								}}
							>
								取消
							</KodenButton>
							<KodenButton
								style={{ marginLeft: 5 }}
								onClick={() => {
									if (window.confirm("確定要送出嗎?")) {
										onSubmit(editRow);
									}
								}}
							>
								送出
							</KodenButton>
						</>
					);
				} else {
					return (
						<KodenButton
							onClick={() => {
								setEditRow(
									produce(row, (draft) => {
										return draft;
									}),
								);
							}}
						>
							編輯
						</KodenButton>
					);
				}
			},
		},
	];
}
