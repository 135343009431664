import { Status } from "../moo-enums";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	return {
		status: Status.Present,
		schedule_id: null,
		schedule_start_date: new Date(),
		schedule_end_date: new Date(),
		date: new Date(),
		links: [],
		attaches: [],
		content: "",
		create_date: new Date().toISOString(),
	};
}
