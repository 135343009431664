import { gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { useGraphQLRequest } from "../moo-graphql-provider";
import { Condition } from "./useFindOne";
import { useReadFields } from "./useReadFields";

export function useFindResourceListByIds<T extends { create_date?: Date | string }>(
	resource: string,
	one: T,
	readCondition: Condition<T>,
) {
	const { value: branch } = useBranch();
	const graphQLRequest = useGraphQLRequest();
	const readFields = useReadFields<T>(one, readCondition);

	const query = gql`
		query ($where: ${resource}_bool_exp) {
				${resource}(where: $where, order_by: [{ create_date: asc }]) {
					id
					${readFields}
				}
		}
	`;

	return async (ids: (string | number)[]): Promise<(T & { id: number | string })[]> => {
		if (ids.length === 0) return [];

		const variables = {
			where: {
				id: {
					_in: ids,
				},
				branch_id: {
					_eq: branch,
				},
			},
		};

		return graphQLRequest({ query, variables }).then((e) => e[resource] as (T & { id: number | string })[]);
	};
}
