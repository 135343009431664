import { useContext } from "react";
import { ArchiveContext } from "./context";

export function useOnArchived() {
	const { onArchived } = useContext(ArchiveContext);

	return (resource: string) => {
		onArchived(resource);
	};
}
