import styled from "@emotion/styled";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { One } from "../..";
import { ProxyKodenTextField } from "../../../../ProxyKodenTextField";
import { calReceivable } from "../../calReceivable";
import { useForm } from "../../hooks/useForm";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const HighlightFieldOuterContainer = styled.div`
	background-color: #f2f3f5;
`;

const HighlightFieldInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};
	background: #f2f3f5;
	border-radius: 0.2rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.5rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: unset;
	}
`;

export const SummaryArea = () => {
	const { values, setFieldValue, setValues } = useForm();
	const updateDirectly = (attr: keyof One, value: number) => {
		setFieldValue(attr, value);
	};

	const arr: {
		id: keyof One;
		min: number;
		label: string;
		readOnly?: boolean;
		onChange: (attr: keyof One, value: number) => void;
	}[] = [
		{
			id: "total_price",
			min: 0,
			label: "合計",
			onChange(_, value) {
				setValues({
					...values,
					total_price: value,
					receivables: calReceivable(values.total_price, values.tax_price, values.received),
				});
			},
		},
		{
			id: "tax_price",
			min: 0,
			label: "稅金",
			onChange(_, value) {
				setValues({
					...values,
					tax_price: value,
					receivables: calReceivable(values.total_price, values.tax_price, values.received),
				});
			},
		},
		{
			id: "received",
			min: 0,
			label: "已收款",
			onChange(_, value) {
				setValues({
					...values,
					received: value,
					receivables: calReceivable(values.total_price, values.tax_price, value),
				});
			},
		},
		{
			id: "receivables",
			min: 0,
			label: "應收款",
			readOnly: true,
			onChange() {},
		},
		{ id: "handling_fee", min: 0, label: "手續費", onChange: updateDirectly },
		{ id: "freight", min: 0, label: "運費", onChange: updateDirectly },
		{
			id: "miscellaneous_charge",
			min: 0,
			label: "雜項",
			onChange: updateDirectly,
		},
		{
			id: "commission_refund",
			min: 0,
			label: "退佣金",
			onChange: updateDirectly,
		},
	];

	return (
		<HighlightFieldOuterContainer>
			<HighlightFieldInnerContainer>
				{arr.map(({ id, label, min, readOnly = false, onChange }) => {
					return (
						<KodenNumberField
							id={id}
							key={id}
							error=""
							value={values[id] as number}
							label={label}
							hasDollar
							outlined
							readOnly={readOnly}
							disabled={readOnly}
							step={1}
							min={min}
							max={Number.MAX_SAFE_INTEGER}
							onChange={(value) => {
								onChange(id, value);
							}}
						/>
					);
				})}
				<TextFieldEnd
					id="note"
					label="備註"
					outlined
					margin="none"
					value={values.note}
					error=""
					onChange={(value) => setFieldValue("note", value)}
				/>
			</HighlightFieldInnerContainer>
		</HighlightFieldOuterContainer>
	);
};

export default SummaryArea;
