import { Permission } from "../moo-me-provider";

export const allBranchPermissions: Permission[] = [
	Permission.ItemSpecListRead,
	Permission.ItemSpecRead,
	Permission.ItemSpecAdd,
	Permission.ItemSpecUpdate,
	Permission.ItemSpecPurchasePriceRead,
	Permission.ItemSpecPurchasePriceAdd,
	Permission.ItemSpecPurchasePriceUpdate,
	Permission.ItemSpecCostRead,
	Permission.ItemSpecCostAdd,
	Permission.ItemSpecCostUpdate,
	Permission.ItemSpecPriceRead,
	Permission.ItemSpecPriceAdd,
	Permission.ItemSpecPriceUpdate,

	Permission.ItemCustomListRead,
	Permission.ItemCustomRead,
	Permission.ItemCustomAdd,
	Permission.ItemCustomUpdate,
	Permission.ItemCustomPurchasePriceRead,
	Permission.ItemCustomPurchasePriceAdd,
	Permission.ItemCustomPurchasePriceUpdate,
	Permission.ItemCustomCostRead,
	Permission.ItemCustomCostAdd,
	Permission.ItemCustomCostUpdate,
	Permission.ItemCustomPriceRead,
	Permission.ItemCustomPriceAdd,
	Permission.ItemCustomPriceUpdate,

	Permission.ItemBundleAdd,
	Permission.ItemBundleRead,
	Permission.ItemBundleUpdate,
	Permission.ItemBundleListRead,

	Permission.QuotationTypeWrite,
	Permission.QuotationListRead,
	Permission.QuotationRead,
	Permission.QuotationAdd,
	Permission.QuotationUpdate,
	Permission.QuotationStateWrite,
	Permission.QuotationStateManagerWrite,
	Permission.QuotationStateAccountantWrite,

	Permission.OrderListRead,
	Permission.OrderRead,
	Permission.OrderAdd,
	Permission.OrderUpdate,
	Permission.OrderStateWrite,
	Permission.OrderStateManagerWrite,
	Permission.OrderStateAccountantWrite,
	Permission.OrderStateWarehouseWrite,

	Permission.OrderGoodsListRead,
	Permission.OrderGoodsRead,
	Permission.OrderGoodsAdd,
	Permission.OrderGoodsUpdate,

	Permission.ReturnOrderListRead,
	Permission.ReturnOrderRead,
	Permission.ReturnOrderUpdate,
	Permission.ReturnOrderAdd,
	Permission.ReturnOrderStateWrite,

	Permission.ReturnGoodsListRead,
	Permission.ReturnGoodsRead,
	Permission.ReturnGoodsAdd,
	Permission.ReturnGoodsUpdate,

	Permission.PurchaseOrderListRead,
	Permission.PurchaseOrderRead,
	Permission.PurchaseOrderAdd,
	Permission.PurchaseOrderUpdate,
	Permission.PurchaseOrderStateWrite,
	Permission.PurchaseOrderStateManagerWrite,
	Permission.PurchaseOrderStateAccountantWrite,

	Permission.RequestBuyListRead,
	Permission.RequestBuyRead,
	Permission.RequestBuyAdd,
	Permission.RequestBuyUpdate,
	Permission.RequestBuyStatePurchaseWrite,

	Permission.SvcListRead,
	Permission.SvcRead,
	Permission.SvcAdd,
	Permission.SvcUpdate,
	Permission.SvcStateAccountantWrite,

	Permission.StockTransferListRead,
	Permission.StockTransferRead,
	Permission.StockTransferAdd,
	Permission.StockTransferUpdate,
	Permission.StockTransferStateWrite,

	Permission.DefectiveProductListRead,
	Permission.DefectiveProductRead,
	Permission.DefectiveProductAdd,
	Permission.DefectiveProductUpdate,
	Permission.DefectiveProductStateWrite,

	Permission.ImportGoodsListRead,
	Permission.ImportGoodsRead,
	Permission.ImportGoodsAdd,
	Permission.ImportGoodsUpdate,
	Permission.ImportGoodsStateWrite,
	Permission.ImportGoodsStateAccountantWrite,

	Permission.ExportGoodsListRead,
	Permission.ExportGoodsRead,
	Permission.ExportGoodsAdd,
	Permission.ExportGoodsUpdate,
	Permission.ExportGoodsStateWrite,

	Permission.VoucherListRead,
	Permission.VoucherRead,
	Permission.VoucherAdd,
	Permission.VoucherUpdate,
	Permission.BalanceSheetPdfDownload,

	Permission.AllowanceListRead,
	Permission.AllowanceRead,
	Permission.AllowanceAdd,
	Permission.AllowanceUpdate,
	Permission.AllowanceStateManagerWrite,
	Permission.AllowanceStateAccountantWrite,
	Permission.AllowanceStateRequesterWrite,

	Permission.SalaryRead,

	Permission.PerformanceRead,

	Permission.CustomerTypeWrite,
	Permission.CustomerListRead,
	Permission.CustomerRead,
	Permission.CustomerAdd,
	Permission.CustomerUpdate,
	Permission.ManufacturerDomesticTypeWrite,
	Permission.ManufacturerDomesticListRead,
	Permission.ManufacturerDomesticRead,
	Permission.ManufacturerDomesticAdd,
	Permission.ManufacturerDomesticUpdate,
	Permission.ManufacturerForeignTypeWrite,
	Permission.ManufacturerForeignListRead,
	Permission.ManufacturerForeignRead,
	Permission.ManufacturerForeignAdd,
	Permission.ManufacturerForeignUpdate,

	Permission.ManufacturerLogisticsListRead,
	Permission.ManufacturerLogisticsRead,
	Permission.ManufacturerLogisticsAdd,
	Permission.ManufacturerLogisticsUpdate,

	Permission.ItemCategoryWrite,
	Permission.PayeeWrite,
	Permission.AccountSubjectWrite,

	Permission.WarehouseWrite,
];
