import type { CellType } from "@yachelee/koden-components/koden-table";
import { returnOrder } from "../moo-enums";
import { useOnLinkClick } from "../moo-link-provider";
import { ClickableColumn, DateFormat } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	const onLinkClick = useOnLinkClick();
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "return_date",
			align: "left",
			component: ({ return_date }: Row) => {
				return <DateFormat>{return_date}</DateFormat>;
			},
		},
		{
			id: "customer_name",
			align: "left",
			component: ({ customer_name }: Row) => {
				return <div>{customer_name}</div>;
			},
		},
		{
			id: "sales_name",
			align: "left",
			component: ({ sales_name }: Row) => {
				return <div>{sales_name}</div>;
			},
		},
		{
			id: "state_accountant",
			align: "left",
			component: ({ state_accountant }: Row) => {
				return <div>{returnOrder.StateAccountant.getLabel(state_accountant)}</div>;
			},
		},
		{
			id: "note",
			align: "left",
			component: ({ note }: Row) => {
				return <div>{note}</div>;
			},
		},
		{
			id: "order_id",
			align: "left",
			component: ({ order_id }: Row) => {
				if (order_id === "") {
					return null;
				}
				return <ClickableColumn onClick={() => onLinkClick(order_id)}>{order_id}</ClickableColumn>;
			},
		},
	];
}
