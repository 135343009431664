import { Status, svc } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();
	return {
		status: Status.Present,
		state_accountant: svc.StateAccountant.Value.UnSettled,
		type: svc.Type.Value.MAINTAIN,
		from_date: now,
		create_date: now,
		update_date: now,
		to_date: now,
		customer_id: null,
		customer_name: "",
		sales_id: null,
		sales_name: "",
		owner_id: null,
		owner_name: "",
		address: "",
		description: "",
		note: "",
		driver_logistics_manufacturer_id: null,
		driver_id: null,
		driver_tel: "",
		items: [],
		price: 0,
		cost: 0,
		receivables: 0,
		received: 0,
	};
}
