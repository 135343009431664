import { gql } from "@apollo/client";
import { FormikHelpers } from "formik";
import * as _ from "lodash";
import { useQueryClient } from "react-query";
import { Employee, Form } from "../moo-employee-one";
import { getGraphQLQueryKey, useGraphQLRequest } from "../moo-graphql-provider";
import { useMe } from "../moo-me-provider";
import { useFindOneQuery } from "../moo-shared";
import { mapEmployeeToForm } from "./mapEmployeeToForm";
import { useUpdateUserRole } from "./useUpdateUserRole";

interface Dictionary<T> {
	[index: string]: T;
}

function getUpdatedFields<T>(initialValues: T, updatedValue: T): string[] {
	const changes = _.differenceWith(
		_.toPairs(updatedValue as unknown as Dictionary<T>),
		_.toPairs(initialValues as unknown as Dictionary<T>),
		_.isEqual,
	);
	return _.keys(_.fromPairs(changes));
}

function getPartialOne<T>(initialValues: T, toUpdateOne: T): Partial<T> {
	const fields = getUpdatedFields(toUpdateOne, initialValues);
	const one: Partial<T> = {};

	fields.forEach((field) => {
		// @ts-ignore
		one[field] = toUpdateOne[field];
	});

	return one;
}

export function useUpdateOne(shape: Form, id: string, onSuccess: () => void, queryOperationName: string) {
	const me = useMe()!;
	const updateUserRole = useUpdateUserRole();
	const query = useFindOneQuery(shape, "Int!", queryOperationName, {});
	const findOneKey = getGraphQLQueryKey({ operationName: queryOperationName, query, variables: { id } });
	const graphQLRequest = useGraphQLRequest();
	const queryClient = useQueryClient();

	return async (initialValues: Form, toUpdateOne: Form, helpers: FormikHelpers<Form>) => {
		const isSalaryDiff = initialValues.salary_basic !== toUpdateOne.salary_basic;
		const mutation = gql`
        mutation ($where: employees_bool_exp!, $set: employees_set_input ${
					isSalaryDiff ? ", $salary_log: employee_salary_logs_insert_input!" : ""
				}) {
						${
							isSalaryDiff
								? `
							insert_employee_salary_logs_one(object: $salary_log) {
									create_date
							}
						`
								: ""
						}
            
            update_employees(where: $where, _set: $set) {
                returning {
                    status
                    type_id
                    default_branch_id
                    name
                    nick_name
                    birthday
                    tel
                    email
                    line
                    contact_name
                    contact_tel
                    address
                    onboard_date
                    salary_basic
                    salary_increased_date
                    bank_account
                    bank
                    bank_account_number
                    labor_fee
                    healthcare_fee
                    insurance_day
                    insurance_unit
                    insurance_fee
                    note
                    user {
                        role_id
                    }
                }
            }
        }
		`;
		helpers.setSubmitting(true);
		const set = {
			...getPartialOne(initialValues, toUpdateOne),
			update_date: new Date().toISOString(),
		};
		if (set.role_id && set.role_id !== "") {
			await updateUserRole(initialValues.email, set.role_id);
			delete set.role_id;
		}

		let variables: Record<string, any> = {
			where: {
				id: {
					_eq: id,
				},
			},
			set,
		};

		if (isSalaryDiff) {
			variables = {
				...variables,
				salary_log: {
					creator_id: me.id,
					employee_id: id,
					from_salary_basic: initialValues.salary_basic,
					to_salary_basic: toUpdateOne.salary_basic,
				},
			};
		}

		try {
			const data = await graphQLRequest({ variables, query: mutation });
			const employee: Employee = data.update_employees.returning[0];
			queryClient.setQueryData(findOneKey, {
				[queryOperationName]: employee,
			});
			helpers.setValues(mapEmployeeToForm(employee));
			helpers.setSubmitting(false);
			onSuccess();
		} catch (e) {
			console.error(e);
			window.alert("遇到錯誤，請聯絡管理員");
			helpers.setSubmitting(false);
		}
	};
}
