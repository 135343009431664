import { MooOrderGoodsOne, One } from "../moo-order-goods-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { OrderGoodsInstructionsTable } from "./updateOne/OrderGoodsInstructionsTable";
import { OrderGoodsItemsTable } from "./updateOne/OrderGoodsItemsTable";
import { OrderGoodsTable } from "./updateOne/OrderGoodsTable";

export type MooOrderGoodsUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooOrderGoodsUpdate({ id, onCancel }: MooOrderGoodsUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="order-goods-one"
			query={query}
			tableName="order_goods"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooOrderGoodsOne}
			tables={[OrderGoodsInstructionsTable, OrderGoodsItemsTable, OrderGoodsTable]}
		/>
	);
}
