import { ForeignManufacturer, MooManufacturerForeignOne } from "../moo-manufacturer-foreign-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { Accounts } from "./updateOne/Accounts";
import { Table } from "./updateOne/Table";
import { Tels } from "./updateOne/Tels";

export type MooManufacturerForeignUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooManufacturerForeignUpdate({ id, onCancel }: MooManufacturerForeignUpdateProps) {
	return (
		<MooUpdateFormNew<Row, ForeignManufacturer>
			id={id}
			onCancel={onCancel}
			operationName="manufacturer-foreign-one"
			query={query}
			tableName="manufacturer_foreigns"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooManufacturerForeignOne}
			tables={[Accounts, Tels, Table]}
		/>
	);
}
