import { gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { useGraphQLRequest } from "../moo-graphql-provider";
import { StoreType } from "../moo-hooks";
import { One } from "../moo-item-custom-one";
import { MooItemList, Query } from "../moo-item-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export type MooItemCustomListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooItemCustomList = ({ store, onAdd, onRowClick }: MooItemCustomListProps) => {
	const branch_id = useBranch().value;
	const permissions = usePermissions();
	const graphQLRequest = useGraphQLRequest();
	if (!permissions.includes(Permission.ItemCustomListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooItemList
			showAddButton={permissions.includes(Permission.ItemSpecAdd)}
			store={store}
			type="custom"
			onAdd={onAdd}
			onRowClick={onRowClick}
			onClone={async ({ id }) => {
				const record = (await graphQLRequest({
					query: gql`
						query ($id: String!) {
							item_customs_by_pk(id: $id) {
								status
								manufacturer_type
								manufacturer_id
								category_id
								sub_category_id
								buy_year
								name
								material
								color
								size
								owner_id
								sell_deadline
								note
								purchase_price
								cost
								price
								original_code
								country
								item_custom_images {
									src
									thumbnail
									thumbnail_x
									thumbnail_y
									thumbnail_zoom
									thumbnail_rotation
								}
							}
						}
					`,
					variables: { id },
				})) as {
					item_customs_by_pk: {
						status: One["status"];
						manufacturer_type: One["manufacturer_type"];
						manufacturer_id: One["manufacturer_id"];
						category_id: One["category_id"];
						sub_category_id: One["sub_category_id"];
						buy_year: One["buy_year"];
						name: One["name"];
						material: One["material"];
						color: One["color"];
						size: One["size"];
						owner_id: One["owner_id"];
						sell_deadline: One["sell_deadline"];
						note: One["note"];
						purchase_price: One["purchase_price"];
						cost: One["cost"];
						price: One["price"];
						original_code: One["original_code"];
						country: One["country"];
						item_custom_images: {
							src: string;
							thumbnail: string;
							thumbnail_x: number;
							thumbnail_y: number;
							thumbnail_zoom: number;
							thumbnail_rotation: number;
						}[];
					};
				};
				if (record.item_customs_by_pk === null) {
					window.alert("找不到任何資料可以複製，請聯絡管理員");
					return;
				}

				await graphQLRequest({
					query: gql`
						mutation ($object: item_customs_insert_input!) {
							insert_item_customs_one(object: $object) {
								id
							}
						}
					`,
					variables: {
						object: {
							branch_id,
							buy_year: record.item_customs_by_pk.buy_year,
							category_id: record.item_customs_by_pk.category_id,
							color: record.item_customs_by_pk.color,
							cost: record.item_customs_by_pk.cost,
							country: record.item_customs_by_pk.country,
							manufacturer_id: record.item_customs_by_pk.manufacturer_id,
							manufacturer_type: record.item_customs_by_pk.manufacturer_type,
							material: record.item_customs_by_pk.material,
							name: record.item_customs_by_pk.name,
							note: record.item_customs_by_pk.note,
							original_code: record.item_customs_by_pk.original_code,
							owner_id: record.item_customs_by_pk.owner_id,
							price: record.item_customs_by_pk.price,
							purchase_price: record.item_customs_by_pk.purchase_price,
							sell_deadline: record.item_customs_by_pk.sell_deadline,
							size: record.item_customs_by_pk.size,
							status: record.item_customs_by_pk.status,
							sub_category_id: record.item_customs_by_pk.sub_category_id,
							item_custom_images: {
								data: record.item_customs_by_pk.item_custom_images,
							},
						},
					},
				});
				window.location.reload();
			}}
		/>
	);
};
