import { Fragment } from "react";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query } from "./@types";
import { useCells } from "./useCells";
import { useConditions } from "./useConditions";
import { useHeaders } from "./useHeaders";

export type MooSvcListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooSvcList = ({ onAdd, onRowClick, store }: MooSvcListProps) => {
	const itemStore = store();
	const cells = useCells();
	const headers = useHeaders();
	const permissions = usePermissions();
	const conditions = useConditions(itemStore.query);

	if (!permissions.includes(Permission.SvcListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooList
			showAddButton={permissions.includes(Permission.SvcAdd)}
			resource="svcs"
			graphQLColumns={`{
				id
				from_date
				to_date
				owner {
					name
				}
				sales {
					name
				}
				customer {
					name
				}
				type
				state_accountant
			}`}
			addLabel="新增服務"
			onAdd={onAdd}
			filterNode={<Fragment />}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
			conditions={conditions}
		/>
	);
};
