import { Query } from "../@types";

export const DEFAULT_QUERY: Query = {
	search: null,
	page: 0,
	sales_id: null,
	customer_id: null,
	from_date: null,
	to_date: null,
};
