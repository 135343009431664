import { FC } from "react";
import { DEFAULT_ONE, MooScheduleOne, One } from "../moo-schedule-one";
import { useHasuraAdd } from "../moo-shared";

export type MooScheduleAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooScheduleAdd: FC<MooScheduleAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("schedules", onSuccess, (e) => e, true);

	return (
		<MooScheduleOne
			isCancelDisabled={false}
			isOkDisabled={false}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
