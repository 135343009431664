import { MooEnumCrud } from "../moo-enum-crud";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export function MooCustomerType() {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.CustomerTypeWrite)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooEnumCrud
			resource="customer_types"
			title="類型"
			addColumns={[
				{
					id: "name",
					defaultValue: "",
					label: "類型",
					autoFocus: true,
					placeholder: "設計師",
					emptyError: "",
				},
			]}
			listBackgroundColor="#EAEAEA"
			listColumns={[
				{
					id: "name",
					defaultValue: "",
				},
			]}
		/>
	);
}
