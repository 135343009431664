import { MooEmployeeSelectField } from "../../moo-employee-select-field";
import useStore from "../stores/useStore";

export function OwnerSelect() {
	const {
		query: { owner_id },
		setQuery,
	} = useStore();

	return (
		<MooEmployeeSelectField
			id="owner_id"
			value={owner_id}
			label="負責人"
			onChange={(owner_id) => {
				setQuery({ owner_id, page: 0 });
			}}
		/>
	);
}
