import HistoryIcon from "@mui/icons-material/History";
import IconButton from "@mui/material/IconButton";
import { KodenBinIcon } from "@yachelee/koden-components/koden-bin-icon";
import type { CellType } from "@yachelee/koden-components/koden-table";
import { useOnArchived } from "../../moo-archive-provider";
import { QuerySlice } from "../../moo-hooks";
import { Query, Row as Employee } from "../@types";
import { useActivateUserAndEmployee } from "./useActivateUserAndEmployee";
import { useArchiveUserAndEmployee } from "./useArchiveUserAndEmployee";

export function useCells(resource: string, store: QuerySlice<Query>, onActivateEmployee: () => void): CellType[] {
	const onArchived = useOnArchived();
	const archiveUserAndEmployee = useArchiveUserAndEmployee();
	const activateUserAndEmployee = useActivateUserAndEmployee();

	return [
		{
			id: "number",
			align: "left",
			component: ({ number }: Employee) => {
				return <div>{number}</div>;
			},
		},
		{
			id: "type",
			align: "left",
			component: ({ type, status }: Employee) => {
				if (status === "ARCHIVED") {
					return <del>{type}</del>;
				} else {
					return <div>{type}</div>;
				}
			},
		},
		{
			id: "name",
			align: "left",
			component: ({ name, status }: Employee) => {
				if (status === "ARCHIVED") {
					return <del>{`${name}(已封存)`}</del>;
				} else {
					return <div>{name}</div>;
				}
			},
		},
		{
			id: "nick_name",
			align: "left",
			component: ({ nick_name }: Employee) => {
				return <div>{nick_name}</div>;
			},
		},
		{
			id: "tel",
			align: "left",
			component: ({ tel }: Employee) => {
				return <div>{tel}</div>;
			},
		},
		{
			id: "line",
			align: "left",
			component: ({ line }: Employee) => {
				return <div>{line}</div>;
			},
		},
		{
			id: "email",
			align: "left",
			component: ({ email }: Employee) => {
				return <div>{email}</div>;
			},
		},
		{
			id: "delete",
			align: "center",
			component: ({ id, name, status }: Employee) => {
				if (status === "PRESENT") {
					return (
						<KodenBinIcon
							id={`DELETE_${id}`}
							onClick={async (e) => {
								e.stopPropagation();
								if (await archiveUserAndEmployee(id, name)) {
									store.setQuery({ ...store.query, page: 0 });
									onArchived(resource);
								}
							}}
						/>
					);
				} else if (status === "ARCHIVED") {
					return (
						<IconButton>
							<HistoryIcon
								onClick={async (e) => {
									e.stopPropagation();
									if (await activateUserAndEmployee(id, name)) {
										store.setQuery({ ...store.query, page: 0 });
										onActivateEmployee();
									}
								}}
							>
								復原
							</HistoryIcon>
						</IconButton>
					);
				} else {
					return null;
				}
			},
		},
	];
}
