import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "出貨單號",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "export_date",
			label: "出貨日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "export_time",
			label: "時間",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "customer_name",
			label: "客戶",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "driver_name",
			label: "司機",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "sales_name",
			label: "業務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "address",
			label: "地址",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "warehouse_names",
			label: "出貨倉庫",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "order_id",
			label: "連結購單",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
	];
}
