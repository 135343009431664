import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooItemCustomList, Query, useCreateStore } from "../../components/moo-components/moo-item-custom-list";
import { useHistoryQuery } from "../../hooks/useHistoryQuery";
import usePage from "../../hooks/usePage";
import useURLSearchParams from "../../hooks/useURLSearchParams";
import { path as NewRoutePath } from "./NewRoute";
import { prefixPath as UpdateRoutePath } from "./UpdateRoute";

export const path = "/item/custom";

const useQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();

	return {
		search: urlSearchParams.get("search") || null,
		material: urlSearchParams.get("material") || null,
		color: urlSearchParams.get("color") || null,
		owner_id: urlSearchParams.get("owner_id") || null,
		category: urlSearchParams.get("category") || null,
		sub_category: urlSearchParams.get("sub_category") || null,
		manufacturer_type: urlSearchParams.get("manufacturer_type") || null,
		manufacturer_id: urlSearchParams.get("manufacturer_id") || null,
		page,
	};
};

export const ListRoute = () => {
	const query = useQuery();
	const store = useCreateStore(query, {
		owner_id: null,
		search: null,
		material: null,
		color: null,
		category: null,
		sub_category: null,
		manufacturer_type: null,
		manufacturer_id: null,
		page: 0,
	});
	const branch = useBranchInQuery();
	const navigate = useNavigate();
	useHistoryQuery<Query>({ path, store });

	return (
		<MooItemCustomList
			store={store}
			onAdd={async () => {
				await navigate(`${NewRoutePath}?branch=${branch}`);
			}}
			onRowClick={async ({ id }, event) => {
				event.preventDefault();
				await navigate(`${UpdateRoutePath}/${id}?branch=${branch}`);
			}}
		/>
	);
};

export default ListRoute;
