import useURLSearchParams from "./useURLSearchParams";

const defaultValue = 0;

function isNumericString(candidate: string): boolean {
	return candidate.trim() !== "" && Number.isFinite(Number(candidate));
}

const useParamNumber = (value: string) => {
	const str = useURLSearchParams().get(value);

	if (str == null || !isNumericString(str)) return defaultValue;

	return parseInt(str);
};

export default useParamNumber;
