export const graphQLColumns = `{
		id
    sales{
      name
    }
    foreign_manufacturer{
      name
    }
    state_purchase
    order_ids{
      request_buy_order_id_id
      value
    }
}`;
