import { Fragment } from "react";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query } from "./@types";
import { useCells } from "./useCells";
import { useConditions } from "./useConditions";
import { useHeaders } from "./useHeaders";

export type MooStockTransferListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooStockTransferList = ({ onAdd, onRowClick, store }: MooStockTransferListProps) => {
	const itemStore = store();
	const cells = useCells();
	const headers = useHeaders();
	const permissions = usePermissions();
	const conditions = useConditions(itemStore.query);

	if (!permissions.includes(Permission.StockTransferListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooList
			showAddButton={permissions.includes(Permission.StockTransferAdd)}
			resource="stock_transfers"
			graphQLColumns={`{
				id
				transfer_date
				owner{
					name
				}
				source_warehouse{
					name
				}
				target_warehouse{
					name
				}
				items {
					id
					item_spec {
						name
					}
					item_custom {
						name
					}
				}
			}`}
			mapData={(e) => {
				const item_names = (e?.items || [])
					// @ts-ignore
					.map((e) => e?.item_spec.name || e?.item_custom.name || "")
					// @ts-ignore
					.filter((e) => e !== "");
				return {
					id: e.id,
					transfer_date: e.transfer_date,
					owner_name: e?.owner?.name || "",
					source_warehouse: e?.source_warehouse?.name || "",
					target_warehouse: e?.target_warehouse?.name || "",
					item_names,
				};
			}}
			addLabel="新增調撥商品"
			onAdd={onAdd}
			filterNode={<Fragment />}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
			conditions={conditions}
		/>
	);
};
