import { ReactNode } from "react";
import { Branch } from "./@types";
import { BranchContext } from "./context";

export type Value = Branch | null;

export type MooBranchProviderProps = {
	children: ReactNode;
	value: Value;
	setValue: (value: Value) => void;
};

export function MooBranchProvider({ children, value, setValue }: MooBranchProviderProps) {
	return <BranchContext.Provider value={{ value, setValue }}>{children}</BranchContext.Provider>;
}
