export enum EnvEnum {
	Dev = "development",
	Stage = "stage",
	Prod = "production",
}
export const ENV: EnvEnum = process.env.REACT_APP_ENV as EnvEnum;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const API_URL = process.env.REACT_APP_API_URL;
export const IMG_PREFIX_URL = process.env.REACT_APP_IMG_PREFIX_URL;
export const STATIC_PREFIX_URL = process.env.REACT_APP_STATIC_PREFIX_URL;
export const GCS_URL = process.env.REACT_APP_GCS_URL;
export const GCS_PRESIGNED_URL = process.env.REACT_APP_GCS_PRESIGNED_URL;
export const GCS_BUCKET = process.env.REACT_APP_GCS_BUCKET;
export const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL;
export const REFRESH_TOKEN_KEY = "__MOO-RefreshToken";
