import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as ItemMainCategoryRoutePath } from "../../../routes/ItemMainCategoryRoute";
import { path as ItemSubCategoryRoutePath } from "../../../routes/ItemSubCategoryRoute";
import { path as ItemCustomListRoutePath } from "../../../routes/item_custom/ListRoute";
import { path as NewItemCustomRoutePath } from "../../../routes/item_custom/NewRoute";
import { path as UpdateItemCustomRoutePath } from "../../../routes/item_custom/UpdateRoute";
import { path as ItemSpecListRoutePath } from "../../../routes/item_spec/ListRoute";
import { path as NewItemSpecRoutePath } from "../../../routes/item_spec/NewRoute";
import { path as UpdateItemSpecRoutePath } from "../../../routes/item_spec/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Branch, useBranch } from "../../moo-components/moo-branch-provider";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useItems(): ListType[] {
	const { value: branch } = useBranch();
	const historyPushWithBranch = useHistoryPushWithBranch();
	const permissions = usePermissions();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();
	if (branch === Branch.ADMIN) {
		return [];
	}

	const children: any[] = [];
	if (permissions.includes(Permission.ItemSpecListRead)) {
		children.push({
			id: ItemSpecListRoutePath,
			txt: getPageTree()[ItemSpecListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([ItemSpecListRoutePath, NewItemSpecRoutePath, UpdateItemSpecRoutePath]),
			async onClick() {
				await historyPushWithBranch(ItemSpecListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.ItemCustomListRead)) {
		children.push({
			id: ItemCustomListRoutePath,
			txt: getPageTree()[ItemCustomListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([ItemCustomListRoutePath, NewItemCustomRoutePath, UpdateItemCustomRoutePath]),
			async onClick() {
				await historyPushWithBranch(ItemCustomListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.ItemCategoryWrite)) {
		children.push({
			id: ItemMainCategoryRoutePath,
			txt: getPageTree()[ItemMainCategoryRoutePath].useText(),
			icon: "",
			isSelected: isSelected([ItemMainCategoryRoutePath, ItemSubCategoryRoutePath]),
			async onClick() {
				await historyPushWithBranch(ItemMainCategoryRoutePath);
			},
		});
	}

	if (children.length === 0) {
		return [];
	}

	return [
		{
			id: "item",
			txt: "商品",
			icon: Utils.getAbsoluteStaticUrl("icons/item.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleItem();
			},
			open: true,
			openChildren: navigationStore.item,
			children,
		},
	];
}
