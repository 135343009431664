import { mixed, object, SchemaOf, string } from "yup";
import { manufacturer } from "../moo-enums";
import { numberOptional, numberRequired, statusSchema, stringOptional, stringRequired } from "../moo-schemas";
import { useIsSubCategoryIdValid } from "../moo-shared";
import { One } from "./@types/one";

export function useValidationSchema(): SchemaOf<One> {
	const isSubCategoryIdValid = useIsSubCategoryIdValid();

	// @ts-ignore
	return object().shape({
		create_date: stringRequired("create_date"),
		update_date: stringRequired("update_date"),
		status: statusSchema,
		manufacturer_type: mixed<manufacturer.Value.Value>().oneOf(Object.values(manufacturer.Value.Value)).required(),
		manufacturer_id: stringRequired("manufacturer_id"),
		category_id: stringRequired("category_id", "請選擇商品主類"),
		sub_category_id: string()
			.nullable(true)
			.test("sub_category_id", "請選擇商品次類", async (sub_category_id, { parent: { category_id } }) => {
				// @ts-ignore
				return await isSubCategoryIdValid(category_id, sub_category_id);
			}),
		buy_year: numberRequired("buy_year"),
		name: stringRequired("請輸入商品名稱"),
		material: stringOptional,
		color: stringOptional,
		size: stringOptional,
		note: stringOptional,
		image: stringOptional,
		image_cut: stringOptional,
		image_x: numberOptional,
		image_y: numberOptional,
		image_zoom: numberOptional,
		image_rotation: numberOptional,
		purchase_price: numberOptional,
		cost: numberOptional,
		price: numberOptional,
		original_code: stringOptional,
		country: stringOptional,
	});
}
