import { Customer } from "../../moo-customer-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";

export function mapperInitialValues(record: Row): Customer {
	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		type_id: record.type_id,
		name: record.name,
		gender: record.gender,
		birthday: record.birthday === null ? null : getLocaleString(new Date(record.birthday)),
		email: record.email,
		tels: record.tels,
		line: record.line,
		job: record.job,
		vip: record.vip,
		address: record.address,
		bank_account: record.bank_account,
		bank: record.bank,
		bank_account_number: record.bank_account_number,
		tax_id: record.tax_id,
		note: record.note,
	};
}
