import styled from "styled-components";
import { MooGraphQLTable } from "../moo-graphql-table";
import { MooGraphQLListProps, Query } from "./@types";
import { Filter } from "./Filter";
import { TABLET_BREAKPOINT } from "./config";

const Container = styled.div`
	& > * {
		margin-bottom: 1rem;
	}
`;

const ControlPanel = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	& .koden-search {
		margin-top: 5px;
		width: 100%;
		font-size: 0.875rem;
		@media screen and (min-width: ${TABLET_BREAKPOINT}) {
			margin-top: 0;
			height: 38px;
			padding: 0 1rem;
			font-size: 1rem;
			width: 240px;
		}
	}
`;

export function MooGraphQLList<T extends Query>({
	showAddButton,
	clear,
	filterNode,
	addLabel,
	query,
	setQuery,
	onAdd,
	headers,
	cells,
	onRowClick,
	rowsPerPage,
	operationName,
	mapData,
	graphQLQuery,
	variables,
	aggregateNode = () => null,
	sumNode = () => null,
}: MooGraphQLListProps<T>) {
	return (
		<Container>
			<ControlPanel>
				<Filter
					display={showAddButton}
					addLabel={addLabel}
					onAdd={onAdd}
					filterNode={filterNode}
					clear={clear}
					query={query}
					setQuery={setQuery}
				/>
			</ControlPanel>
			<MooGraphQLTable
				headers={headers}
				cells={cells}
				page={query.page}
				rowsPerPage={rowsPerPage}
				onPageChange={(page) => {
					setQuery({ page } as T);
				}}
				operationName={operationName}
				onRowClick={onRowClick}
				mapData={mapData}
				query={graphQLQuery}
				variables={variables}
				aggregateNode={aggregateNode}
				sumNode={sumNode}
			/>
		</Container>
	);
}
