import { Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooGraphQLTableProps } from "./@types/types";
import EllipsisText from "./EllipsisText";
import Loading from "./Loading";
import Pagination from "./Pagination";

const StyledTableCell = styled(TableCell)<{
	color: string;
}>`
	&.MuiTableCell-head {
		background-color: #eaeaea;
		color: ${(props) => props.color};
		font-weight: bold;
	}
`;

export function MooGraphQLTable({
	headers,
	headerColor = "black",
	cells,
	page,
	rowsPerPage,
	onPageChange,
	onRowClick = () => {},
	operationName,
	query,
	variables,
	mapData,
	aggregateNode = () => null,
	sumNode = () => null,
}: MooGraphQLTableProps) {
	const { isLoading, data } = useGraphQLQuery({ operationName, query, variables });
	if (isLoading) {
		return <Loading />;
	} else {
		const { results, total_count } = mapData(data);
		const isNotFound = total_count === 0 && results.length === 0;

		return (
			<>
				<TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 200px)" }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								{headers.map(({ id, align, label, style }) => {
									return (
										<StyledTableCell key={id} color={headerColor} align={align} style={style}>
											{typeof label === "string" ? label : label({})}
										</StyledTableCell>
									);
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={headers.length} style={{ display: "flex", justifyContent: "center" }}>
										<Loading />
									</TableCell>
								</TableRow>
							)}
							{!isLoading && isNotFound && (
								<TableRow>
									<TableCell colSpan={headers.length}>找不到資料</TableCell>
								</TableRow>
							)}
							{!isLoading &&
								results.map((result) => (
									<TableRow
										key={result.id}
										hover
										style={{ cursor: onRowClick !== undefined ? "pointer" : "" }}
										onClick={(e: unknown) => {
											if (onRowClick) {
												onRowClick(result, e as MouseEvent);
											}
										}}
									>
										{cells.map(({ align, style, isEllipsis = false, component }, index) => {
											return (
												<TableCell key={`${result.id}_${index}`} style={style} align={align}>
													{isEllipsis ? <EllipsisText>{component(result)}</EllipsisText> : component(result)}
												</TableCell>
											);
										})}
									</TableRow>
								))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell colSpan={headers.length}>
									<Pagination
										data={data}
										page={page}
										aggregateNode={aggregateNode}
										totalCount={total_count}
										rowsPerPage={rowsPerPage}
										onChange={onPageChange}
									/>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>
				{sumNode && (
					<div style={{ display: "flex", justifyContent: "flex-end", color: "#707070" }}>{sumNode(data)}</div>
				)}
			</>
		);
	}
}

export default MooGraphQLTable;
