import Select from "react-select";

export type Option = {
	readonly value: string | null;
	readonly label: string;
};

type Props = {
	id: string;
	options: Option[];
	label: string;
	value: string | null;
	error?: string;
	onChange: (value: string | null) => void;
	isLoading?: boolean;
	disabled?: boolean;
};

export function MooTypeableSelect({
	id,
	isLoading = false,
	disabled = false,
	options,
	label,
	error,
	value,
	onChange,
}: Props) {
	return (
		<div
			style={{
				position: "relative",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<label
				htmlFor={id}
				style={{
					position: "absolute",
					zIndex: 1,
					top: 0,
					left: 0,
					transform: "translate(3px, -11px) scale(.75)",
					userSelect: "none",
					color: "rgba(0, 0, 0, 0.6)",
					backgroundColor: "white",
					lineHeight: "1.4375em",
					letterSpacing: "0.00938em",
				}}
			>
				{label}
			</label>
			<Select
				id={id}
				isDisabled={disabled}
				styles={{
					control: (baseStyles) => ({
						...baseStyles,
						height: "56px",
						cursor: "pointer",
					}),
					menu: (baseStyles) => ({
						...baseStyles,
						zIndex: 4,
					}),
				}}
				placeholder="請選擇"
				noOptionsMessage={() => "沒有資料"}
				defaultValue={null}
				value={options.find((e) => e.value === value) || null}
				isOptionSelected={(option: Option) => option.value === value}
				isLoading={isLoading}
				options={options}
				onChange={(option) => {
					if (option) {
						onChange(option.value);
					}
				}}
			/>
			{error && (
				<div
					style={{
						color: "#d32f2f",
						fontSize: ".75rem",
						lineHeight: 1.66,
						letterSpacing: "0.03333em",
						marginTop: 3,
					}}
				>
					{error}
				</div>
			)}
		</div>
	);
}
