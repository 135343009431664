import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as WarehouseCrudRoutePath } from "../../../routes/WarehouseCrudRoute";
import { path as WarehouseStockListRoutePath } from "../../../routes/WarehouseStockListRoute";
import { path as DefectiveProductListRoutePath } from "../../../routes/defective_product/ListRoute";
import { path as DefectiveProductNewRoutePath } from "../../../routes/defective_product/NewRoute";
import { path as DefectiveProductUpdateRoutePath } from "../../../routes/defective_product/UpdateRoute";
import { path as ExportGoodsListRoutePath } from "../../../routes/export_goods/ListRoute";
import { path as ExportGoodsNewRoutePath } from "../../../routes/export_goods/NewRoute";
import { path as ExportGoodsUpdateRoutePath } from "../../../routes/export_goods/UpdateRoute";
import { path as ImportGoodsListRoutePath } from "../../../routes/import_goods/ListRoute";
import { path as ImportGoodsNewRoutePath } from "../../../routes/import_goods/NewRoute";
import { path as ImportGoodsUpdateRoutePath } from "../../../routes/import_goods/UpdateRoute";
import { path as StockTransferListRoutePath } from "../../../routes/stock_transfer/ListRoute";
import { path as StockTransferNewRoutePath } from "../../../routes/stock_transfer/NewRoute";
import { path as StockTransferUpdateRoutePath } from "../../../routes/stock_transfer/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Branch, useBranch } from "../../moo-components/moo-branch-provider";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useStocks(): ListType[] {
	const { value: branch } = useBranch();
	const permissions = usePermissions();
	const historyPushWithBranch = useHistoryPushWithBranch();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();
	if (branch === Branch.ADMIN) {
		return [];
	}

	let children: any[] = [];

	if (permissions.includes(Permission.WarehouseWrite)) {
		children = children.concat([
			{
				id: WarehouseCrudRoutePath,
				txt: getPageTree()[WarehouseCrudRoutePath].useText(),
				icon: "",
				isSelected: isSelected([WarehouseCrudRoutePath]),
				async onClick() {
					await historyPushWithBranch(WarehouseCrudRoutePath);
				},
			},
			{
				id: WarehouseStockListRoutePath,
				txt: getPageTree()[WarehouseStockListRoutePath].useText(),
				icon: "",
				isSelected: isSelected([WarehouseStockListRoutePath]),
				async onClick() {
					await historyPushWithBranch(WarehouseStockListRoutePath);
				},
			},
		]);
	}

	if (permissions.includes(Permission.StockTransferListRead)) {
		children.push({
			id: StockTransferListRoutePath,
			txt: getPageTree()[StockTransferListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([StockTransferListRoutePath, StockTransferNewRoutePath, StockTransferUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(StockTransferListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.DefectiveProductListRead)) {
		children.push({
			id: DefectiveProductListRoutePath,
			txt: getPageTree()[DefectiveProductListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([
				DefectiveProductListRoutePath,
				DefectiveProductNewRoutePath,
				DefectiveProductUpdateRoutePath,
			]),
			async onClick() {
				await historyPushWithBranch(DefectiveProductListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.ImportGoodsListRead)) {
		children.push({
			id: ImportGoodsListRoutePath,
			txt: getPageTree()[ImportGoodsListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([ImportGoodsListRoutePath, ImportGoodsNewRoutePath, ImportGoodsUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(ImportGoodsListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.ExportGoodsListRead)) {
		children.push({
			id: ExportGoodsListRoutePath,
			txt: getPageTree()[ExportGoodsListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([ExportGoodsListRoutePath, ExportGoodsNewRoutePath, ExportGoodsUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(ExportGoodsListRoutePath);
			},
		});
	}

	if (children.length === 0) {
		return [];
	}

	return [
		{
			id: "stock",
			txt: "庫存",
			icon: Utils.getAbsoluteStaticUrl("icons/stock.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleStock();
			},
			open: true,
			openChildren: navigationStore.stock,
			children,
		},
	];
}
