import { useGraphQLRequest } from "../moo-graphql-provider";
import { Row } from "./findOne/Row";
import { query } from "./findOne/query";

type GraphQLFindOneResult = {
	manufacturer_domestics_by_pk: Row;
};

export function useRequestById() {
	const graphQLRequest = useGraphQLRequest();

	return async (id: string): Promise<GraphQLFindOneResult> => {
		return (await graphQLRequest({
			query,
			variables: { id },
		})) as GraphQLFindOneResult;
	};
}
