import { createContext } from "react";
import { Query } from "./@types";
import { DEFAULT_QUERY } from "./stores/DEFAULT_QUERY";

type ContextType = {
	query: Query;
};

export const QueryContext = createContext<ContextType>({
	query: DEFAULT_QUERY,
});
