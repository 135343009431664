import { KodenBinIcon } from "@yachelee/koden-components/koden-bin-icon";
import { useRemoveMutation } from "@yachelee/koden-components/koden-crud";
import type { CellType } from "@yachelee/koden-components/koden-table";
import { FaRegClone } from "react-icons/fa6";
import { useOnArchived } from "../moo-archive-provider";
import { SpecOrCustomTableRow } from "../moo-dialog-item-choose";
import { manufacturer as manufacturerEnum } from "../moo-enums";
import { QuerySlice, useArchiveResource } from "../moo-hooks";
import { MooItemListImg } from "../moo-item-list-img";
import { DollarNumber } from "../moo-shared";
import { Query } from "./@types";
import { MooItemListProps } from "./moo-item-list";

export function useCells(
	store: QuerySlice<Query>,
	type: MooItemListProps["type"],
	resource: string,
	onClone: ({ id }: { id: string }) => void,
): CellType[] {
	const archiveResource = useArchiveResource(resource);
	const onArchived = useOnArchived();
	const { query } = store;
	const removeResource = "list/item/" + type;
	const removeMutation = useRemoveMutation(removeResource, query);

	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: SpecOrCustomTableRow) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "owner_id",
			align: "left",
			component: ({ owner }: SpecOrCustomTableRow) => {
				return <div>{owner?.name || "無"}</div>;
			},
		},
		{
			id: "image",
			align: "left",
			component: ({ name, image, image_cut }: SpecOrCustomTableRow) => {
				return <MooItemListImg name={name} image={image} image_cut={image_cut} />;
			},
		},
		{
			id: "manufacturer_type",
			align: "left",
			component: ({ manufacturer_type }: SpecOrCustomTableRow) => {
				return <div>{manufacturerEnum.Label.get(manufacturer_type as string)}</div>;
			},
		},
		{
			id: "manufacturer",
			align: "left",
			component: ({ manufacturer }: SpecOrCustomTableRow) => {
				return <div>{manufacturer}</div>;
			},
		},
		{
			id: "category",
			align: "left",
			component: ({ category }: SpecOrCustomTableRow) => {
				return <div>{category}</div>;
			},
		},
		{
			id: "name",
			align: "left",
			component: ({ name }: SpecOrCustomTableRow) => {
				return <div>{name}</div>;
			},
		},
		{
			id: "country",
			align: "left",
			component: ({ country }: SpecOrCustomTableRow) => {
				return <div>{country || ""}</div>;
			},
		},
		{
			id: "material",
			align: "left",
			component: ({ material }: SpecOrCustomTableRow) => {
				return <div>{material}</div>;
			},
		},
		{
			id: "size",
			align: "left",
			component: ({ size }: SpecOrCustomTableRow) => {
				return <div>{size}</div>;
			},
		},
		{
			id: "color",
			align: "left",
			component: ({ color }: SpecOrCustomTableRow) => {
				return <div>{color}</div>;
			},
		},
		{
			id: "purchase_price",
			align: "left",
			component: ({ purchase_price }: SpecOrCustomTableRow) => {
				return <DollarNumber>{purchase_price}</DollarNumber>;
			},
		},
		{
			id: "price",
			align: "left",
			component: ({ price }: SpecOrCustomTableRow) => {
				return <DollarNumber>{price}</DollarNumber>;
			},
		},
		{
			id: "delete",
			align: "center",
			component: ({ id, name }: SpecOrCustomTableRow) => {
				return (
					<KodenBinIcon
						id={`DELETE_${id}`}
						disabled={removeMutation.isLoading}
						onClick={async (e) => {
							e.stopPropagation();
							if (await archiveResource({ id, name })) {
								onArchived(resource);
							}
						}}
					/>
				);
			},
		},
		{
			id: "clone",
			align: "center",
			component: ({ id }: SpecOrCustomTableRow) => {
				return (
					<FaRegClone
						fontSize={20}
						id={`CLONE_${id}`}
						onClick={async (e) => {
							e.stopPropagation();
							if (window.confirm(`確定要複製${id}?`)) {
								onClone({ id });
							}
						}}
					/>
				);
			},
		},
	];
}
