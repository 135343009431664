import { Props as UseUploadToGcsProps, useUploadToGcs } from "./useUploadToGcs";

type Props = {
	fileList: FileList;
	onUploading: UseUploadToGcsProps["onUploading"];
	onUploaded: UseUploadToGcsProps["onUploaded"];
};

export function useOnSelectFiles() {
	const uploadToGcs = useUploadToGcs();

	return async ({ fileList, onUploaded, onUploading }: Props) => {
		for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
			const file = fileList[i];
			await uploadToGcs({
				file,
				onUploading,
				onUploaded,
			});
		}
	};
}
