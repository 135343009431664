import * as _ from "lodash";
import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";

export function useConditions(where: Record<string, any> = {}): Record<string, any> {
	const { value: branch } = useBranch();

	let conditions: Record<string, any> = {
		branch_id: {
			_eq: branch,
		},
		status: {
			_eq: Status.Present,
		},
	};

	if (!_.isEmpty(where)) {
		conditions = { ...conditions, ...where };
	}

	return conditions;
}
