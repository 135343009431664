import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { KodenSearch } from "@yachelee/koden-components/koden-search";
import styled from "styled-components";
import { FilterProps, Query } from "./@types";
import QueryBoard from "./QueryBoard";
import { FONT_SIZE_MOBILE, FONT_SIZE_TABLET, TABLET_BREAKPOINT } from "./config";

const FilterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		width: unset;
	}

	& .koden-select,
	& .top-bar {
		height: 28px;
		padding: 0 0.5rem;
		font-size: ${FONT_SIZE_MOBILE};

		@media screen and (min-width: ${TABLET_BREAKPOINT}) {
			height: 38px;
			padding: 0 1rem;
			font-size: ${FONT_SIZE_TABLET};
		}
	}

	& .koden-select-option-container {
		z-index: 1;
		margin-left: 3px;
		@media screen and (min-width: ${TABLET_BREAKPOINT}) {
			margin-left: 7px;
		}
	}

	& .koden-select-option {
		padding: 0.3rem 0.8rem;
		font-size: ${FONT_SIZE_MOBILE};

		@media screen and (min-width: ${TABLET_BREAKPOINT}) {
			padding: 0.8rem 1rem;
			font-size: ${FONT_SIZE_TABLET};
		}
	}

	& .clear-all {
		font-size: ${FONT_SIZE_MOBILE};
		@media screen and (min-width: ${TABLET_BREAKPOINT}) {
			font-size: ${FONT_SIZE_TABLET};
		}
	}

	& .add-icon {
		color: #ffffff;
		width: 20px;
		height: 20px;
		@media screen and (min-width: ${TABLET_BREAKPOINT}) {
			width: 32px;
			height: 32px;
		}
	}
`;

export function Filter<T extends Query>({
	display,
	addLabel,
	onAdd,
	filterNode,
	clear,
	query,
	setQuery,
}: FilterProps<T>) {
	return (
		<>
			<FilterContainer>
				{display && (
					<KodenIconTextButton text={addLabel} onClick={onAdd} backgroundColor="#79b256" textColor="#ffffff" />
				)}
				{filterNode}
				<QueryBoard clear={clear} />
			</FilterContainer>
			<KodenSearch
				borderColor="#EAEAEA"
				defaultValue={query.search || ""}
				onChange={(search) => {
					setQuery({ search, page: 0 } as T);
				}}
			/>
		</>
	);
}
