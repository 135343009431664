import { Calculator } from "../moo-quotation-one";
import { One } from "./@types/one";

export function useSyncPrices() {
	return (one: One) => {
		one.items.forEach((item) => {
			item.price_total = Calculator.getItemPriceTotal(item);
		});
		one.total_price = Calculator.getPriceTotal(one.items);
	};
}
