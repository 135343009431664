import { MooItemRowList } from "../../../moo-item-row-list";
import { FieldType } from "../../../moo-item-spec-and-custom-row";
import { useGetFieldError } from "../../../moo-shared";
import { ItemBound } from "../../../moo-types";
import { One } from "../../@types/one";
import { useDeleteItem } from "../../hooks/item/useDeleteItem";
import { useUpdateItemCount } from "../../hooks/item/useUpdateItemCount";
import { useForm } from "../../hooks/useForm";

export function ItemList() {
	const { values: one } = useForm();
	const updateItemCount = useUpdateItemCount();
	const deleteItem = useDeleteItem();
	const getFieldError = useGetFieldError<One>();

	return (
		<MooItemRowList
			emptyMessage=""
			errors={getFieldError("items")}
			specCustomFields={
				new Set<FieldType>([
					"id",
					"manufacturer_type",
					"manufacturer",
					"name",
					"material",
					"color",
					"count",
					"purchase_price",
					"purchase_price_total",
					"remove",
				])
			}
			items={one.items as unknown as ItemBound[]}
			onRemove={deleteItem}
			onCountChange={updateItemCount}
		/>
	);
}

export default ItemList;
