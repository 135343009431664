export enum Value {
	UnConfirmed = "UNCONFIRMED",
	Confirmed = "CONFIRMED",
}

export function getValues(): Value[] {
	return [Value.UnConfirmed, Value.Confirmed];
}

export enum Label {
	UnConfirmed = "未確認",
	Confirmed = "確認",
}

export function getLabel(value: Value): Label {
	switch (value) {
		case Value.UnConfirmed:
			return Label.UnConfirmed;
		case Value.Confirmed:
			return Label.Confirmed;
	}
	return Label.UnConfirmed;
}
