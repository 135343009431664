import { Fragment } from "react";
import { MooGraphQLList } from "../moo-graphql-list";
import { StoreType } from "../moo-hooks";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { useListGraphQLQuery } from "../moo-shared";
import { Query } from "./@types";
import { graphQLColumns } from "./graphQLColumns";
import { mapData } from "./mapData";
import { useCells } from "./useCells";
import { useConditions } from "./useConditions";
import { useGraphQLVariables } from "./useGraphQLVariables";
import { useHeaders } from "./useHeaders";

export type MooPurchaseOrderListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooPurchaseOrderList = ({ onAdd, onRowClick, store }: MooPurchaseOrderListProps) => {
	const itemStore = store();
	const cells = useCells();
	const headers = useHeaders();
	const graphQLQuery = useListGraphQLQuery("purchase_orders", graphQLColumns);
	const conditions = useConditions(itemStore.query);
	const variables = useGraphQLVariables(10, itemStore.query.page, conditions);
	const permissions = usePermissions();

	if (!permissions.includes(Permission.PurchaseOrderListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooGraphQLList
			showAddButton={permissions.includes(Permission.PurchaseOrderAdd)}
			addLabel="新增國外採購單"
			onAdd={onAdd}
			filterNode={<Fragment />}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
			graphQLQuery={graphQLQuery}
			mapData={mapData}
			operationName=""
			rowsPerPage={10}
			variables={variables}
		/>
	);
};
