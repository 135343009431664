import { useGetData } from "./useGetData";

export function useGetSum() {
	const getData = useGetData();

	return async ({ id_number }: { id_number: string }): Promise<{ sum: number }> => {
		const data = await getData({ id_number });
		const sum =
			(data.voucher_rows_aggregate.aggregate.sum.debit_amount || 0) -
			(data.voucher_rows_aggregate.aggregate.sum.credit_amount || 0);
		return { sum };
	};
}
