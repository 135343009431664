import { FC } from "react";
import { MooFileList } from "../moo-file-list";
import { useForm } from "./hooks/useForm";
import { useUpdate } from "./hooks/useUpdate";

export const Attaches: FC = () => {
	const { deleteAttach } = useUpdate();
	const { values } = useForm();

	return <MooFileList files={values.attaches.map((e) => e.value)} onDel={deleteAttach} />;
};
