import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../../moo-graphql-provider";
import { useSuccess } from "../../moo-notification-provider";
import { useIdName } from "./useIdName";
import { useIds } from "./useIds";
import { useListData } from "./useListData";
import { useProps } from "./useProps";

export function useOnDelete() {
	const success = useSuccess();
	const { refetch } = useListData();
	const { resource } = useProps();
	const idName = useIdName();
	const { ids, setIds } = useIds();
	const graphQLRequest = useGraphQLRequest();

	return async () => {
		if (window.confirm(`確定要刪除這${ids.length}個圖片嗎？`)) {
			const parameters = ids
				.map((_, index) => `$set_${index}: ${resource}_set_input, $where_${index}: ${resource}_bool_exp!`)
				.join(", ");
			const queryBody = ids
				.map(
					(_, index) => `
				update_${index}:update_${resource}(_set: $set_${index}, where: $where_${index}) {
						affected_rows
				}
		`,
				)
				.join("\n");

			const query = gql`
          mutation (${parameters}){
              ${queryBody}
          }
			`;

			const variables = {};
			ids.forEach((id, index) => {
				// @ts-ignore
				variables[`set_${index}`] = { status: "ARCHIVED" };
				// @ts-ignore
				variables[`where_${index}`] = {
					[idName]: {
						_eq: id,
					},
				};
			});

			await graphQLRequest({ query, variables });
			await refetch();
			setIds([]);
			success("刪除成功！");
		}
	};
}
