import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { Query } from "../@types";

export function useConditions({ type, search }: Query) {
	const { value: branch } = useBranch();
	const conditions: Record<string, any> = {
		status: {
			_eq: Status.Present,
		},
		branch_id: {
			_eq: branch,
		},
	};

	if (type !== null) {
		conditions.type_id = {
			_eq: type,
		};
	}

	if (search !== null && search.trim() !== "") {
		const _similar = `%(${search.trim().split(" ").join("|")})%`;
		conditions._or = [
			{
				name: {
					_similar,
				},
			},
			{
				email: {
					_similar,
				},
			},
			{
				address: {
					_similar,
				},
			},
			{
				fax: {
					_similar,
				},
			},
			{
				tels: {
					tel: {
						_similar,
					},
				},
			},
			{
				accounts: {
					_or: [
						{
							bank: {
								_similar,
							},
						},
						{
							bank_account: {
								_similar,
							},
						},
						{
							bank_account_number: {
								_similar,
							},
						},
						{
							tax_id: {
								_similar,
							},
						},
					],
				},
			},
			{
				manufacturer_domestic_type: {
					name: {
						_similar,
					},
				},
			},
		];
	}
	return conditions;
}
