import produce from "immer";
import { Account, Driver } from "..";
import { uuidv4 } from "../../moo-shared";
import { useForm } from "./useForm";

export function useUpdate() {
	const { values, setFieldValue } = useForm();

	function addDriver() {
		setFieldValue(
			"drivers",
			produce(values.drivers, (draft) => {
				draft.push({
					manufacturer_logistic_driver_id: uuidv4(),
					name: "",
					tel: "",
					line: "",
				} as Driver);
			}),
		);
	}

	function delDriver(id: string) {
		setFieldValue(
			"drivers",
			produce(values.drivers, (draft) => {
				const index = draft.findIndex((e) => e.manufacturer_logistic_driver_id === id);
				if (index !== -1) {
					draft.splice(index, 1);
				}
			}),
		);
	}

	function updateDriver(id: string, field: keyof Driver, value: any) {
		setFieldValue(
			"drivers",
			produce(values.drivers, (draft) => {
				const obj = draft.find((e) => e.manufacturer_logistic_driver_id === id);
				if (obj) {
					obj[field] = value;
				}
				return draft;
			}),
		);
	}

	function addAccount() {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				draft.push({
					manufacturer_logistic_account_id: uuidv4(),
					name: "",
					bank: "",
					bank_account: "",
					tax_id: "",
				} as Account);
			}),
		);
	}

	function delAccount(id: string) {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				const index = draft.findIndex((e) => e.manufacturer_logistic_account_id === id);
				if (index !== -1) {
					draft.splice(index, 1);
				}
				return draft;
			}),
		);
	}

	function updateAccount(id: string, field: keyof Account, value: any) {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				const obj = draft.find((e) => e.manufacturer_logistic_account_id === id);
				if (obj) {
					obj[field] = value;
				}
				return draft;
			}),
		);
	}

	return {
		addDriver,
		delDriver,
		updateDriver,
		addAccount,
		delAccount,
		updateAccount,
	};
}
