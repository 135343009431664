import styled from "styled-components";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { LinkType } from "./@types";
import useStore from "./stores/useStore";

const TABLET_BREAKPOINT = "768px";

const Filters = styled.div`
	width: 100%;
	display: flex;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		width: unset;
	}

	& .select-field {
		height: 28px;
		margin-right: 0.5rem;
		border-radius: 25px;
		font-size: 0.8rem;
		overflow: hidden;
	}
`;

export function TypeSelect() {
	const { type, setType } = useStore();
	return (
		<Filters>
			<MooTypeableSelect
				id="type"
				className="select-field"
				label="類型"
				options={[
					{ value: null, label: "請選擇" },
					{ value: LinkType.Quotation, label: "報價單" },
					{ value: LinkType.Order, label: "訂購單" },
					{ value: LinkType.ImportGoods, label: "進貨單" },
					{ value: LinkType.ExportGoods, label: "出貨單" },
					{ value: LinkType.PurchaseOrder, label: "國外採購單" },
					{ value: LinkType.RequestBuy, label: "國外請購單" },
					{ value: LinkType.ReturnGoods, label: "退貨單" },
					{ value: LinkType.OrderGoods, label: "訂貨單" },
				]}
				value={type}
				error=""
				//@ts-ignore
				onChange={setType}
			/>
		</Filters>
	);
}
