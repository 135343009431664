import produce from "immer";
import { useForm } from "./useForm";
import { useSyncPrices } from "./useSyncPrices";

export function useUpdateItemCount() {
	const syncPrices = useSyncPrices();
	const { setValues, values } = useForm();

	return (id: string, count: number) => {
		setValues(
			produce(values, (draft) => {
				draft.items.forEach((purchaseItem) => {
					if (purchaseItem.id === id) {
						purchaseItem.count = count;
						purchaseItem.purchase_price_total = purchaseItem.purchase_price * count;
					}
				});
				syncPrices(draft);
			}),
		);
	};
}
