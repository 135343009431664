import { manufacturer } from "../../moo-enums";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import { Query } from "../@types";
import useStore from "../stores/useStore";

export function ItemManufacturerTypeSelect() {
	const {
		query: { manufacturer_type },
		setQuery,
	} = useStore();

	return (
		<MooTypeableSelect
			id="item-manufacturer-type-field"
			label="廠商類型"
			value={manufacturer_type}
			options={[
				{ value: null, label: "請選擇" },
				{ value: manufacturer.Value.Value.Domestic, label: "國內" },
				{ value: manufacturer.Value.Value.Foreign, label: "國外" },
			]}
			onChange={(manufacturerType) =>
				setQuery({ manufacturer_type: manufacturerType as Query["manufacturer_type"], manufacturer_id: null, page: 0 })
			}
		/>
	);
}
