import { gql } from "@apollo/client";
import { Status } from "../../moo-enums";
import { useGraphQLRequest } from "../../moo-graphql-provider";

export function useArchiveUserAndEmployee() {
	const graphQLRequest = useGraphQLRequest();

	return async (employeeId: string, name: string): Promise<boolean> => {
		if (window.confirm(`確定要封存${name}嗎?`)) {
			await graphQLRequest({
				query: gql`
					mutation (
						$employee_where: employees_bool_exp!
						$employee_set: employees_set_input
						$user_where: users_bool_exp!
						$user_set: users_set_input
					) {
						update_employees(where: $employee_where, _set: $employee_set) {
							affected_rows
						}
						update_users(where: $user_where, _set: $user_set) {
							affected_rows
						}
					}
				`,
				variables: {
					employee_where: {
						id: {
							_eq: employeeId,
						},
					},
					employee_set: {
						status: Status.Archived,
					},
					user_where: {
						employee: {
							id: {
								_eq: employeeId,
							},
						},
					},
					user_set: {
						is_active: false,
					},
				},
			});
			return true;
		} else {
			return false;
		}
	};
}
