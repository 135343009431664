import type { CellType } from "@yachelee/koden-components/koden-table";
import { purchaseOrder } from "../moo-enums";
import { useOnLinkClick } from "../moo-link-provider";
import { ClickableColumn, DateFormat } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	const onLinkClick = useOnLinkClick();
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "owner",
			align: "left",
			component: ({ owner }: Row) => {
				return <div>{owner}</div>;
			},
		},
		{
			id: "manufacturer",
			align: "left",
			component: ({ manufacturer }: Row) => {
				return <div>{manufacturer}</div>;
			},
		},
		{
			id: "deliver_date",
			align: "left",
			component: ({ deliver_date }: Row) => {
				return <DateFormat>{deliver_date}</DateFormat>;
			},
		},
		{
			id: "state",
			align: "left",
			component: ({ state }: Row) => {
				return <div>{purchaseOrder.State.getLabel(state)}</div>;
			},
		},
		{
			id: "state_manager",
			align: "left",
			component: ({ state_manager }: Row) => {
				return <div>{purchaseOrder.StateManager.getLabel(state_manager)}</div>;
			},
		},
		{
			id: "state_accountant",
			align: "left",
			component: ({ state_accountant }: Row) => {
				return <div>{purchaseOrder.StateAccountant.getLabel(state_accountant)}</div>;
			},
		},
		{
			id: "warehouse",
			align: "left",
			component: ({ warehouse }: Row) => {
				return <div>{warehouse}</div>;
			},
		},
		{
			id: "request_buy_ids",
			align: "left",
			component: ({ request_buy_ids }: Row) => {
				if (request_buy_ids.length === 0) {
					return null;
				}
				return (
					<>
						{request_buy_ids.map((value) => (
							<ClickableColumn key={value} onClick={() => onLinkClick(value)}>
								{value}
							</ClickableColumn>
						))}
					</>
				);
			},
		},
	];
}
