import * as _ from "lodash";
import { getDateFormat, getTimeFormat } from "../moo-shared";
import { Row } from "./@types";

type Warehouse = { id: string; name: string };

export function mapData(e: Row, { warehouses }: { warehouses: Warehouse[] }) {
	const warehouseMap: Record<string, Warehouse> = warehouses.reduce((cur, next) => ({ ...cur, [next.id]: next }), {});
	let export_date = "";
	let export_time = "";
	const items = e.items || [];

	if (e.export_date !== null) {
		export_date = getDateFormat(e.export_date);
		export_time = getTimeFormat(e.export_date);
	}

	const warehouse_names = _.uniq(
		items
			.map((e) => e.stocks || [])
			// @ts-ignore
			.flat()
			.map((e) => e.warehouse_id)
			.map((e) => {
				return warehouseMap[e].name || "";
			})
			.filter((e) => e !== ""),
	);

	return {
		...e,
		export_date,
		export_time,
		warehouse_names,
	};
}
