import styled from "@emotion/styled";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { One } from "..";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { useGetFieldError } from "../../moo-shared";
import { useForm } from "../hooks/useForm";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const HighlightFieldOuterContainer = styled.div`
	background-color: #f2f3f5;
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const HighlightFieldInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};
	background: #f2f3f5;
	border-radius: 0.2rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.5rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export function AccountantBlock() {
	const { values: one, setFieldValue: updateOne } = useForm();
	const getFieldError = useGetFieldError<One>();

	const ids = [
		"accountant_total_price",
		"accountant_tax",
		"accountant_received",
		"accountant_freight",
		"accountant_declare_fee",
		"accountant_pickup_charge",
		"accountant_miscellaneous_charge",
	];
	const labels = ["合計", "稅金", "已付款", "運費", "報關費", "提貨費", "雜費"];
	const readOnlys = [true, false, false, false, false, false];

	return (
		<>
			<div style={{ marginBottom: "1rem" }}>
				<header style={{ borderBottom: "1px solid #EBEBEB" }}>
					<h1 style={{ fontSize: "1.5rem" }}>採購總覽</h1>
				</header>
			</div>
			<HighlightFieldOuterContainer>
				<HighlightFieldInnerContainer>
					{ids.map((id, index) => {
						const label = labels[index];
						return (
							<KodenNumberField
								id={id}
								key={id}
								error={getFieldError(id as keyof One)}
								// @ts-ignore
								value={one[id] as number}
								label={label}
								readOnly={readOnlys[index]}
								disabled={readOnlys[index]}
								hasDollar
								outlined
								step={1}
								min={0}
								max={Number.MAX_SAFE_INTEGER}
								onChange={(value) => {
									updateOne(id, value);
								}}
							/>
						);
					})}
					<TextFieldEnd
						id="accountant_note"
						label="備註"
						outlined
						margin="none"
						value={one.accountant_note}
						error={getFieldError("accountant_note")}
						onChange={(accountant_note) => {
							updateOne("accountant_note", accountant_note);
						}}
					/>
				</HighlightFieldInnerContainer>
			</HighlightFieldOuterContainer>
		</>
	);
}
