import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as BlogListRoutePath } from "../../../routes/blog/ListRoute";
import { path as BlogNewRoutePath } from "../../../routes/blog/NewRoute";
import { path as BlogOneRoutePath } from "../../../routes/blog/OneRoute";
import { path as ScheduleListRoutePath } from "../../../routes/schedule/ListRoute";
import { path as ScheduleNewRoutePath } from "../../../routes/schedule/NewRoute";
import { path as ScheduleOneRoutePath } from "../../../routes/schedule/OneRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Branch, useBranch } from "../../moo-components/moo-branch-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useBlogs(): ListType[] {
	const { value: branch } = useBranch();
	const historyPushWithBranch = useHistoryPushWithBranch();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();

	if (branch === Branch.ADMIN) {
		return [];
	}

	return [
		{
			id: "blog",
			txt: "日誌",
			icon: Utils.getAbsoluteStaticUrl("icons/blog.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleBlog();
			},
			open: true,
			openChildren: navigationStore.blog,
			children: [
				{
					id: ScheduleListRoutePath,
					txt: getPageTree()[ScheduleListRoutePath].useText(),
					icon: "",
					isSelected: isSelected([ScheduleListRoutePath, ScheduleOneRoutePath, ScheduleNewRoutePath]),
					async onClick() {
						await historyPushWithBranch(ScheduleListRoutePath);
					},
				},
				{
					id: BlogListRoutePath,
					txt: getPageTree()[BlogListRoutePath].useText(),
					icon: "",
					isSelected: isSelected([BlogListRoutePath, BlogOneRoutePath, BlogNewRoutePath]),
					async onClick() {
						await historyPushWithBranch(BlogListRoutePath);
					},
				},
			],
		},
	];
}
