import { getDateString } from "../../moo-shared";
import { useStore } from "../hooks/useStore";
import { PdfStyle } from "./useOnBalanceSheetButtonClick";

export function usePdfSubTitle() {
	const { query } = useStore();
	let text = "";

	// If the from_date and to_date are the same, only display one date
	if (query.from_date !== null && query.to_date !== null && query.from_date.getTime() === query.to_date.getTime()) {
		text = `日期: ${getDateString(query.from_date)}`;
	} else {
		// If from_date is not null, display it

		const dates = [];
		if (query.from_date !== null) {
			dates.push(getDateString(query.from_date));
		}

		if (query.to_date !== null) {
			dates.push(getDateString(query.to_date));
		}

		if (dates.length > 0) {
			text = `日期: ${dates.join(" ~ ")}`;
		}
	}

	return {
		text,
		fontSize: 16,
		style: PdfStyle.AlignmentCenter,
	};
}
