import { MooEnumCrud, MooEnumCrudProps } from "../moo-enum-crud";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export type MooAccountSubjectCrudProps = {
	category: string;
	parent: string | null;
	canClick?: boolean;
	onClick?: (recordId: string) => void;
};

export function MooAccountSubjectCrud({ category, parent, canClick = false, onClick }: MooAccountSubjectCrudProps) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.AccountSubjectWrite)) {
		return <MooNoAuthPage />;
	}
	const listColumns: MooEnumCrudProps["listColumns"] = [
		{
			id: "id_number",
			defaultValue: "",
		},
		{
			id: "name",
			defaultValue: "",
		},
	];

	if (canClick && onClick) {
		listColumns.push({
			id: "mgt_btn",
			type: "ComponentType",
			component({ record }) {
				return (
					<button
						onClick={() => {
							onClick(record.id as string);
						}}
					>
						細項
					</button>
				);
			},
		});
	}

	const filter = {
		category: {
			_eq: category,
		},
		parent: {
			_eq: parent,
		},
	};

	if (parent === null) {
		filter.parent = {
			// @ts-ignore
			_is_null: true,
		};
	}

	const props: MooEnumCrudProps = {
		resource: "account_types",
		filter,
		mutation: {
			category,
			parent,
		},
		title: "科目管理",
		addColumns: [
			{
				id: "id_number",
				defaultValue: "",
				label: "代號",
				autoFocus: true,
				placeholder: "1000",
				emptyError: "",
			},
			{
				id: "name",
				defaultValue: "",
				label: "名稱",
				autoFocus: false,
				placeholder: "資產總額",
				emptyError: "",
			},
		],
		listBackgroundColor: "#EAEAEA",
		listColumns,
	};

	return <MooEnumCrud {...props} />;
}
