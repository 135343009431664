import { DomesticManufacturer, MooManufacturerDomesticOne } from "../moo-manufacturer-domestic-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { Accounts } from "./updateOne/Accounts";
import { Table } from "./updateOne/Table";
import { Tels } from "./updateOne/Tels";

export type MooManufacturerDomesticUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooManufacturerDomesticUpdate({ id, onCancel }: MooManufacturerDomesticUpdateProps) {
	return (
		<MooUpdateFormNew<Row, DomesticManufacturer>
			id={id}
			onCancel={onCancel}
			operationName={`manufacturer-domestics-one-${id}`}
			query={query}
			tableName="manufacturer_domestics"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooManufacturerDomesticOne}
			tables={[Accounts, Tels, Table]}
		/>
	);
}
