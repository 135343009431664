import { MooBlogView } from "../moo-blog-view";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { useMe } from "../moo-me-provider";
import { MooMessageAdd } from "../moo-message-add";
import { getOperationName, getQuery, getVariables, MooMessageView } from "../moo-message-view";
import { useError } from "../moo-notification-provider";

export type MooWeeklyBlogOneProps = {
	id: string;
};

const messageResource = "blogs";
const messageIdName = "blog_message_id";

export function MooWeeklyBlogOne({ id }: MooWeeklyBlogOneProps) {
	const record = useGraphQLQuery({
		query: getQuery(messageResource, messageIdName),
		variables: getVariables(id),
		operationName: getOperationName(messageResource),
	});
	const me = useMe()!;
	const error = useError();

	return (
		<>
			<MooBlogView id={id} />
			<div style={{ marginTop: 16 }} />
			<MooMessageAdd
				id={id}
				resource="blog"
				userName={me.display_name}
				userBackgroundColor={me.color}
				onSuccess={async () => {
					await record.refetch();
				}}
				onError={error}
			/>
			<div style={{ marginTop: 16 }} />
			<MooMessageView resource={messageResource} id={id} messageIdName={messageIdName} />
		</>
	);
}
