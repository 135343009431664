import styled from "@emotion/styled";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { useFormikContext } from "formik";
import { ProxyKodenTextField } from "../../../../ProxyKodenTextField";
import { One } from "../../@types/one";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const HighlightFieldOuterContainer = styled.div`
	background-color: #f2f3f5;
`;

const HighlightFieldInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};
	background: #f2f3f5;
	border-radius: 0.2rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.5rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const SummaryArea = () => {
	const { values, setFieldValue, setValues } = useFormikContext<One>();

	return (
		<HighlightFieldOuterContainer>
			<HighlightFieldInnerContainer>
				<KodenNumberField
					id="price_total"
					error=""
					value={values.price_total}
					label="小計"
					hasDollar
					outlined
					disabled
					min={0}
					step={1}
					max={Number.MAX_SAFE_INTEGER}
					onChange={() => {}}
				/>
				<KodenNumberField
					id="price_discounted"
					error=""
					value={values.price_discounted}
					label="成交價"
					hasDollar
					outlined
					min={0}
					step={1}
					max={Number.MAX_SAFE_INTEGER}
					onChange={(value) => {
						setValues({
							...values,
							price_discounted: value,
							deposit: 0,
						});
					}}
				/>
				<ProxyKodenTextField
					id="note"
					label="備註"
					outlined
					margin="none"
					value={values.note}
					error=""
					onChange={(note) => setFieldValue("note", note)}
				/>
				<KodenNumberField
					id="cost"
					error=""
					value={values.cost}
					label="總成本"
					hasDollar
					outlined
					disabled
					min={0}
					step={1}
					max={Number.MAX_SAFE_INTEGER}
					onChange={() => {}}
				/>
				<KodenNumberField
					id="deposit"
					error=""
					value={values.deposit}
					label="訂金"
					hasDollar
					outlined
					min={0}
					step={1}
					max={Number.MAX_SAFE_INTEGER}
					onChange={(deposit) => {
						setValues({ ...values, deposit, remaining_fee: values.price_discounted - deposit });
					}}
				/>
				<KodenNumberField
					id="remaining_fee"
					error=""
					value={values.remaining_fee}
					label="尾款"
					hasDollar
					outlined
					disabled
					min={0}
					step={1}
					max={Number.MAX_SAFE_INTEGER}
					onChange={() => {}}
				/>
				<KodenNumberField
					id="tax"
					label="稅金"
					outlined
					hasDollar
					error=""
					value={values.tax}
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					onChange={(value) => {
						setFieldValue("tax", value);
					}}
				/>
			</HighlightFieldInnerContainer>
		</HighlightFieldOuterContainer>
	);
};

export default SummaryArea;
