import type { CellType } from "@yachelee/koden-components/koden-table";
import { requestBuy } from "../moo-enums";
import { useOnLinkClick } from "../moo-link-provider";
import { ClickableColumn } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	const onLinkClick = useOnLinkClick();
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "sales",
			align: "left",
			component: ({ sales }: Row) => {
				return <div>{sales}</div>;
			},
		},
		{
			id: "manufacturer",
			align: "left",
			component: ({ manufacturer }: Row) => {
				return <div>{manufacturer}</div>;
			},
		},
		{
			id: "state_purchase",
			align: "left",
			component: ({ state_purchase }: Row) => {
				return <div>{requestBuy.StatePurchase.getLabel(state_purchase)}</div>;
			},
		},
		{
			id: "order_ids",
			align: "left",
			component: ({ order_ids }: Row) => {
				if (order_ids.length === 0) {
					return null;
				}
				return (
					<>
						{order_ids.map((value) => (
							<ClickableColumn key={value} onClick={() => onLinkClick(value)}>
								{value}
							</ClickableColumn>
						))}
					</>
				);
			},
		},
	];
}
