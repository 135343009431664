import { KodenButton } from "@yachelee/koden-components/koden-button";
import { Formik } from "formik";
import styled from "styled-components";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooSubmitButton } from "../moo-submit-button";
import { Props } from "./@types/props";
import { BasicBlock, StateBlock } from "./blocks";
import { SummaryBlock } from "./blocks/SummaryBlock";
import { PdfButton } from "./components/PdfButton";
import { schema } from "./schemas/schema";
import { useForm } from "./useForm";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

function Form({ id, isEdit = false, isOkDisabled, isCancelDisabled, onCancel }: Partial<Props>) {
	const { values, handleSubmit } = useForm();

	return (
		<form onSubmit={handleSubmit}>
			<Container>
				{isEdit && <StateBlock />}
				<BasicBlock />
				{values.order_id !== null && <SummaryBlock />}
				<ButtonContainer>
					{isEdit && id && <PdfButton id={id} />}
					{!isOkDisabled && (
						<>
							<KodenButton
								style={{ marginRight: "1rem" }}
								disabled={isCancelDisabled}
								onClick={async () => {
									if (window.confirm("確定取消並且回到上一頁？")) {
										if (onCancel) {
											onCancel();
										}
									}
								}}
							>
								取消
							</KodenButton>
							<MooSubmitButton permission={Permission.ReturnOrderUpdate} />
						</>
					)}
				</ButtonContainer>
			</Container>
		</form>
	);
}

export function MooReturnOrderOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.ReturnOrderRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
