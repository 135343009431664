import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    
    state_accountant_id
    state_accountant
    state_manager
    state_requester
    request_date
    requester_id
    reason
    amount
    order_id
`;

export const query = gql`
    query ($id: String!) {
        allowances_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
