import { DocumentNode, gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooTypeableSelect, Option } from "../moo-typable-select/MooTypeableSelect";

type ValueType = string | null;

type Props = {
	id: string;
	resource: string;
	label: string;
	value: ValueType;
	onChange: (value: ValueType) => void;
	error?: string;
	disabled?: boolean;
};

function getQuery(resource: string): DocumentNode {
	return gql`
    query ($where: ${resource}_bool_exp){
      ${resource}(where: $where) {
        id
        name
      }
    }
  `;
}

function useVariables(): Record<string, any> {
	const { value } = useBranch();
	let where: Record<string, any> = {
		status: {
			_eq: Status.Present,
		},
		branch_id: {
			_eq: value,
		},
	};

	return {
		where,
	};
}

export function MooListTypeableSelectField({ id, resource, label, value, onChange, error, disabled = false }: Props) {
	const query = getQuery(resource);
	const variables = useVariables();
	const { isLoading, isError, data } = useGraphQLQuery<Record<string, { id: string; name: string }[]>>({
		operationName: id,
		query,
		variables,
	});
	let options: Option[] = [
		{
			value: null,
			label: "請選擇",
		},
	];
	if (!isLoading && !isError && data) {
		options = options.concat(
			(data[resource] || []).map(({ id, name }) => ({
				value: id,
				label: name,
			})),
		);
	}
	return (
		<MooTypeableSelect
			id={id}
			label={label}
			error={error || ""}
			disabled={disabled}
			options={options}
			isLoading={isLoading}
			value={value}
			onChange={(value) => {
				onChange(value || "");
			}}
		/>
	);
}
