import { useParams } from "react-router-dom";
import { MooWeeklyBlogOne } from "../../components/moo-components/moo-weekly-blog-one";

export const prefixPath = "/blog";
export const path = prefixPath + "/:id";

export const OneRoute = () => {
	const { id } = useParams<{ id: string }>();

	return <MooWeeklyBlogOne id={id!} />;
};

export default OneRoute;
