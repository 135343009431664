import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../../moo-graphql-provider";
import { useIdName } from "./useIdName";
import { useListData } from "./useListData";
import { useParentIdName } from "./useParentIdName";
import { useProps } from "./useProps";

export function useOnUploaded() {
	const { parentId, resource } = useProps();
	const idName = useIdName();
	const parentIdName = useParentIdName();
	const graphQLRequest = useGraphQLRequest();
	const { refetch } = useListData();

	return async (object: string) => {
		try {
			await graphQLRequest({
				query: gql`
            mutation ($object: ${resource}_insert_input!) {
            	insert_${resource}_one(object: $object) {
            		${idName}
            	}
            }
				`,
				variables: {
					object: {
						[parentIdName]: parentId,
						src: object,
						thumbnail: object,
						thumbnail_x: 0,
						thumbnail_y: 0,
						thumbnail_zoom: 1,
						thumbnail_rotation: 0,
					},
				},
			});
			await refetch();
		} catch (e) {
			console.error(e);
		}
	};
}
