import { Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { DomesticManufacturer } from "./@types/domestic-manufacturer";

export function DEFAULT_ONE(): DomesticManufacturer {
	const now = getNowLocaleString();
	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		type_id: "",
		name: "",
		fax: "",
		email: "",
		tels: [],
		accounts: [],
		address: "",
		website: "",
		note: "",
	};
}
