import type { CellType } from "@yachelee/koden-components/koden-table";
import { MooItemListImg } from "../../moo-item-list-img";
import { Row, SpecOrCustomTableRow } from "../@types";
import useStore from "../stores/useStore";
import { useArrayItems } from "./useArrayItems";

function useCheckbox(): CellType {
	const { hasChosen } = useStore();

	return {
		id: "checkbox",
		align: "left",
		component: (row: Row) => <input type="checkbox" checked={hasChosen(row)} onChange={(e) => e.stopPropagation()} />,
	};
}

export function useCells(): CellType[] {
	const checkbox = useCheckbox();

	return [
		checkbox,
		...useArrayItems().map(({ id, component }) => {
			return {
				id,
				align: "left",
				component,
			};
		}),
		{
			id: "image",
			align: "left",
			component: ({ name, image, image_cut }: SpecOrCustomTableRow) => {
				return <MooItemListImg name={name} image={image} image_cut={image_cut} />;
			},
		},
	] as CellType[];
}
