import { One } from "..";
import { importGoods } from "../../moo-enums";
import { Permission, usePermissions } from "../../moo-me-provider";
import { useGetFieldError } from "../../moo-shared";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import FieldContainer from "../components/FieldContainer";
import { useForm } from "../hooks/useForm";
import { useLockStock } from "../hooks/useLockStock";

type ItemOption = {
	id: importGoods.State.Value | importGoods.StateAccountant.Value;
	label: importGoods.State.Label | importGoods.StateAccountant.Label;
};

type Item = {
	id: keyof One;
	label: string;
	permission: Permission;
	options: ItemOption[];
};

const arr: Item[] = [
	{
		id: "state",
		label: "狀態",
		permission: Permission.ImportGoodsStateWrite,
		options: [
			{
				id: importGoods.State.Value.UnVerified,
				label: importGoods.State.Label.UnVerified,
			},
			{
				id: importGoods.State.Value.Verified,
				label: importGoods.State.Label.Verified,
			},
		],
	},
	{
		id: "state_accountant",
		label: "會計狀態",
		permission: Permission.ImportGoodsStateAccountantWrite,
		options: [
			{
				id: importGoods.StateAccountant.Value.UnSettled,
				label: importGoods.StateAccountant.Label.UnSettled,
			},
			{
				id: importGoods.StateAccountant.Value.Settled,
				label: importGoods.StateAccountant.Label.Settled,
			},
		],
	},
];

export function StateBlock() {
	const { values, setFieldValue } = useForm();
	const lockStock = useLockStock();
	const getFieldError = useGetFieldError<One>();
	const permissions = usePermissions();

	return (
		<FieldContainer>
			{arr.map(({ id, label, options, permission }) => (
				<MooTypeableSelect
					key={id}
					id={id}
					label={label}
					disabled={(id === "state" && lockStock) || !permissions.includes(permission)}
					value={values[id] as string}
					error={getFieldError(id)}
					options={options.map((e) => {
						return { ...e, value: e.id };
					})}
					onChange={(value) => {
						setFieldValue(id, value);
					}}
				/>
			))}
		</FieldContainer>
	);
}
