import { StoreType } from "../moo-hooks";
import { Query } from "./@types";
import { OrderList } from "./OrderList";
import { QueryContext } from "./QueryContext";

export type MooOrderListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const rowsPerPage = 10;
export const operationName = "orders";

export const MooOrderList = ({ onAdd, onRowClick, store }: MooOrderListProps) => {
	const theStore = store();
	const { query } = theStore;

	return (
		<QueryContext.Provider value={{ query }}>
			<OrderList store={theStore} onAdd={onAdd} onRowClick={onRowClick} />
		</QueryContext.Provider>
	);
};
