import { KodenLogin } from "@yachelee/koden-components/koden-login";
import Utils from "../../utils";
import { useLoginMutation } from "./useLoginMutation";

function Login() {
	const loginMutation = useLoginMutation();
	const logoComponent = (
		<img
			src={Utils.getAbsoluteStaticUrl("login/logo.svg")}
			style={{ fill: "black", width: 190, height: 75 }}
			alt="Moo's Logo"
		/>
	);
	return (
		<KodenLogin
			logo={logoComponent}
			themeColor="gold"
			backgroundImage="https://storage.googleapis.com/moo-static/login/bg.png"
			isLoading={loginMutation.isLoading}
			onLogin={({ email, password }) => {
				loginMutation.mutate({ email, password });
			}}
		/>
	);
}

export default Login;
