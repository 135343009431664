import { ForeignManufacturer } from "../../moo-manufacturer-foreign-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";

export function mapperInitialValues(record: Row): ForeignManufacturer {
	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,

		type_id: record.type_id,
		name: record.name,
		fax: record.fax,
		email: record.email,
		country: record.country,
		currency: record.currency,
		tels: record.tels,
		accounts: record.accounts,
		address: record.address,
		website: record.website,
		note: record.note,
	};
}
