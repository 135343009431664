import { Row } from "./@types";

export function mapResults(orders = []): Row[] {
	return orders.map(
		({
			id,
			customer,
			description,
			sales,
			state,
			state_accountant,
			state_manager,
			state_warehouse,
			total_price,
			quotation_type,
			return_goods,
			employee,
		}) => {
			// @ts-ignore
			const return_goods_ids = return_goods.map((e) => e.id);
			const row: Row = {
				id,
				// @ts-ignore
				type_name: quotation_type.name,
				// @ts-ignore
				customer_name: customer.name,
				// @ts-ignore
				sales_name: sales.name,
				description,
				state,
				state_manager,
				state_accountant,
				state_warehouse,
				total_price,
				return_goods_ids,
				// @ts-ignore
				creator_name: employee?.nick_name || "",
			};
			return row;
		},
	);
}
