import { Box, Button, Checkbox, Stack } from "@mui/material";
import { useGetGcsUrl } from "@yachelee/koden-components/koden-gcs-provider";
import { KodenImageEditor, Params } from "@yachelee/koden-components/koden-image-editor";
import produce from "immer";
import { useRef, useState } from "react";
import { BiFileBlank } from "react-icons/bi";
import { MdPhotoSizeSelectSmall } from "react-icons/md";
import { useError, useSuccess } from "../moo-notification-provider";
import { useIdName } from "./hooks/useIdName";
import { useIds } from "./hooks/useIds";
import { Image, useListData } from "./hooks/useListData";
import { useOnCheck } from "./hooks/useOnCheck";
import { useProps } from "./hooks/useProps";
import { useUpdateThumbnail } from "./hooks/useUpdateThumbnail";
import { useUploadToGcs } from "./hooks/useUploadToGcs";

type Props = {
	draggableProvided: any;
	image: Image;
};

function useSetDefaultParams(id: string) {
	const { resource } = useProps();
	const idName = useIdName();
	const { data, setQueryData } = useListData();

	return ({ thumbnail, rotation, zoom, positionX, positionY }: Params & { thumbnail: string }) => {
		// @ts-ignore
		const itemIndex = data[resource].findIndex((e) => e[idName] === id);
		if (itemIndex !== -1) {
			setQueryData(
				produce(data, (draft) => {
					// @ts-ignore
					const item = draft[resource][itemIndex];
					item.thumbnail = thumbnail;
					item.thumbnail_rotation = rotation;
					item.thumbnail_x = positionX;
					item.thumbnail_y = positionY;
					item.thumbnail_zoom = zoom;
				}),
			);
		}
	};
}

export function Item({ draggableProvided, image }: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const { ids } = useIds();
	const getGcsUrl = useGetGcsUrl();
	const onCheck = useOnCheck();
	const success = useSuccess();
	const error = useError();
	const updateThumbnail = useUpdateThumbnail();
	const uploadToGcs = useUploadToGcs();
	const thumbnailRef = useRef<string | null>(image.thumbnail);
	const paramsRef = useRef<Params>({
		rotation: image.thumbnail_rotation,
		zoom: image.thumbnail_zoom,
		positionX: image.thumbnail_x,
		positionY: image.thumbnail_y,
	});
	const setDefaultParams = useSetDefaultParams(image.id);

	return (
		<Stack
			position="relative"
			padding={0.5}
			marginBottom={1}
			width={360}
			alignItems="center"
			borderRadius={1}
			border="0.5px solid #bcbcbc"
			sx={{
				backgroundColor: "white",
			}}
		>
			<Box {...draggableProvided.dragHandleProps} display="flex" width="100%" justifyContent="flex-end">
				<Checkbox checked={ids.includes(image.id)} onChange={() => onCheck(image.id)} />
			</Box>
			{isLoading ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 270, height: 150 }}>
					儲存中...
				</div>
			) : (
				<KodenImageEditor
					src={getGcsUrl(image.src)}
					defaultParams={{
						rotation: image.thumbnail_rotation,
						zoom: image.thumbnail_zoom,
						positionX: image.thumbnail_x,
						positionY: image.thumbnail_y,
					}}
					onChangeParams={(params) => {
						paramsRef.current = params;
						thumbnailRef.current = params.cut;
					}}
					onReset={() => {
						const resetImage = {
							rotation: image.thumbnail_rotation,
							zoom: image.thumbnail_zoom,
							positionX: image.thumbnail_x,
							positionY: image.thumbnail_y,
						};
						paramsRef.current = { ...resetImage };
						thumbnailRef.current = image.thumbnail;
						setDefaultParams({ ...resetImage, thumbnail: image.thumbnail });
					}}
				/>
			)}
			<Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
				<a
					title="打開縮圖"
					style={{ display: "flex", color: "#1976d2", marginRight: 8 }}
					href={getGcsUrl(image.thumbnail)}
					target="_blank"
					rel="noreferrer"
				>
					<MdPhotoSizeSelectSmall />
				</a>
				<a
					title="打開原圖"
					style={{ display: "flex", color: "#1976d2" }}
					href={getGcsUrl(image.src)}
					target="_blank"
					rel="noreferrer"
				>
					<BiFileBlank />
				</a>
				<Button
					sx={{ marginLeft: 2 }}
					variant="contained"
					disabled={isLoading}
					onClick={async () => {
						if (!thumbnailRef.current?.startsWith("data")) {
							window.alert("請編輯圖片後再更新。");
							return;
						}
						try {
							setIsLoading(true);
							const { positionX, positionY, zoom, rotation } = paramsRef.current;

							// 1. upload the thumbnail to gcs
							const blob = await (await fetch(thumbnailRef.current)).blob();
							const file = new File([blob], "cut.png", {
								type: "image/png",
								lastModified: new Date().getTime(),
							});
							await uploadToGcs({
								file,
								onUploading() {
									console.log("onUploading");
								},
								async onUploaded(thumbnail) {
									// 2. save to db
									setIsLoading(false);
									await updateThumbnail({
										id: image.id,
										thumbnail,
										thumbnail_x: positionX,
										thumbnail_y: positionY,
										thumbnail_zoom: zoom,
										thumbnail_rotation: rotation,
									});

									//3. change to default
									setDefaultParams({ positionX, positionY, zoom, rotation, thumbnail });
									thumbnailRef.current = thumbnail;
									success("更新縮圖成功！");
									setIsLoading(false);
								},
							});
						} catch (e) {
							error(e as string);
						}
					}}
				>
					{isLoading ? "上傳中" : "更新縮圖"}
				</Button>
			</Box>
		</Stack>
	);
}
