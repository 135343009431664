import { exportGoods, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();

	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		state: exportGoods.State.Value.NotYetShipped,
		type: exportGoods.Type.Direct,
		export_date: now,
		order_id: "",
		type_id: "",
		type_name: "",
		customer_id: "",
		customer_name: "",
		sales_id: null,
		sales_name: "",
		sales_tel: "",
		address: "",
		order_description: "",
		order_note: "",
		driver_logistics_manufacturer_id: null,
		driver_id: "",
		driver_name: "",
		driver_tel: "",
		export_goods_note: "",
		items: [],
		voucher_rows: [],
		shipping_fee: 0,
	};
}
