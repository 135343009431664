import { useFormikContext } from "formik";
import * as _ from "lodash";
import * as pdfMake from "pdfmake/build/pdfmake";
import { useCompanyInfo } from "../../../../hooks/useCompanyInfo";
import { logoImageMap, pdfFonts } from "../../moo-config";
import { Customer, DEFAULT_ONE } from "../../moo-customer-one";
import { Employee, useFindEmployeeById } from "../../moo-employee-one";
import { svc } from "../../moo-enums";
import { useRequestById as useFindLogisticsManufacturerById } from "../../moo-manufacturer-logistics-update";
import { useMeNickName } from "../../moo-me-provider/hooks/useMeNickName";
import { getDateFormat, getDollarNumberWithCommas, useFindOneById } from "../../moo-shared";
import { One } from "../@types/one";

function useGetItemBody() {
	const { values } = useFormikContext<One>();

	return async (): Promise<any[][]> => {
		return values.items.map((item, index) => {
			return [
				{ text: (index + 1).toString(), margin: [0, 20] },
				{ text: item.name, margin: [0, 20] },
				{ text: item.count, margin: [0, 20] },
				{ text: getDollarNumberWithCommas(item.price), margin: [0, 20] },
				"",
			];
		});
	};
}

function useGetBody() {
	const { values } = useFormikContext<One>();
	const getItemBody = useGetItemBody();

	return async () => {
		let arr: any[][] = [
			[{ text: "服務商品", style: "header", colSpan: 5 }, {}, {}, "", ""],
			["編號", "品項", "數量", "報價", ""],
		];

		arr = arr.concat(await getItemBody());

		arr.push([
			{},
			{},
			{},
			{},
			{
				table: {
					widths: ["*", "*"],
					body: [
						[
							{
								stack: [{ text: "合計" }, { text: "已收費用" }, { text: "應收費用" }],
							},
							{
								stack: [
									{ text: getDollarNumberWithCommas(values.price) },
									{ text: getDollarNumberWithCommas(values.received) },
									{ text: getDollarNumberWithCommas(values.receivables) },
								],
							},
						],
					],
				},
				layout: "lightHorizontalLines",
			},
		]);

		arr.push([
			{},
			{},
			{},
			{},
			{
				table: {
					widths: ["*"],
					body: [
						[
							{
								text: "客戶簽名",
								margin: [0, 3],
								bold: true,
								border: [false, false, false, false],
							},
						],
						[
							{
								text: "",
								margin: [0, 30],
								border: [false, false, false, true],
							},
						],
					],
				},
				layout: {
					defaultBorder: false,
				},
			},
		]);
		return arr;
	};
}

export function useOnPdfClick() {
	const getBody = useGetBody();
	const { values, isValid } = useFormikContext<One>();
	const findCustomerById = useFindOneById<Customer>(DEFAULT_ONE(), "customers");
	const findEmployeeById = useFindEmployeeById();
	const findLogisticsManufacturerById = useFindLogisticsManufacturerById();
	const nickName = useMeNickName();
	const { address, tel, fax } = useCompanyInfo();

	return async (id: string) => {
		if (isValid) {
			const FILE_NAME = `服務單-${id}`;
			const customer = await findCustomerById(values.customer_id);
			const owner: Employee = await findEmployeeById(values.owner_id!);
			const logisticsManufacturer = (await findLogisticsManufacturerById(values.driver_logistics_manufacturer_id!))
				.manufacturer_logistics_by_pk;
			const logisticsManufacturerDriver = logisticsManufacturer.drivers.find(
				(e) => e.manufacturer_logistic_driver_id === values.driver_id,
			);

			const content = [
				{
					style: "defaultTable",
					table: {
						headerRows: 1,
						widths: [80, "*", "auto"],
						body: [
							[
								{ image: "logo", width: 51, height: 64, margin: [0, 10, 0, 0] },
								[
									{ text: address, margin: [0, 18, 0, 3] },
									{ text: `TEL:${tel}`, margin: [0, 3] },
									{ text: `FAX:${fax}`, margin: [0, 3] },
								],
								[
									{
										text: `服務單${id}${nickName}`,
										fontSize: 16,
										bold: true,
										margin: [0, 11, 0, 3],
									},
									{
										columns: [
											{ text: "業務", width: "auto", margin: [0, 3] },
											{ text: values.sales_name, width: "*", margin: [0, 3] },
										],
									},
									{
										columns: [
											{ text: "電話", width: "auto", margin: [0, 3] },
											{ text: _.get(customer, "tels.0.tel", "") || "", width: "*", margin: [0, 3] },
										],
									},
								],
							],
							[
								{
									stack: [
										{ text: "客戶", margin: [0, 3, 0, 3] },
										{ text: "地址", margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{ text: customer?.name || "", margin: [0, 3, 0, 3] },
										{ text: values.address, margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{
											columns: [
												{ text: "服務類型", width: "auto", margin: [0, 3, 0, 3] },
												{ text: svc.Type.get(values.type), width: "*", margin: [0, 3] },
											],
										},
										{
											columns: [
												{ text: "維修時間", width: "auto", margin: [0, 3, 0, 3] },
												{
													text: `${getDateFormat(values.from_date)} - ${getDateFormat(values.to_date)}`,
													width: "*",
													margin: [0, 3],
												},
											],
										},
										{
											columns: [
												{ text: "承辦人", width: "auto", margin: [0, 3, 0, 3] },
												{ text: owner.name, width: "*", margin: [0, 3] },
											],
										},
									],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [
										{ text: "服務簡述", margin: [0, 3, 0, 3] },
										{ text: "備註", margin: [0, 3, 0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{ text: values.description, margin: [0, 3, 0, 3] },
										{ text: values.note, margin: [0, 3, 0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [
										{ text: "司機物流廠商", margin: [0, 3, 0, 3] },
										{ text: "司機", margin: [0, 3, 0, 3] },
										{ text: "電話", margin: [0, 3, 0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{ text: logisticsManufacturer?.name || "", margin: [0, 3, 0, 3] },
										{ text: logisticsManufacturerDriver?.name || "", margin: [0, 3, 0, 3] },
										{ text: logisticsManufacturerDriver?.tel || "", margin: [0, 3, 0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
						],
					},
					layout: {
						defaultBorder: false,
						hLineColor: "silver",
					},
				},
				{
					style: "defaultTable",
					color: "#444",
					table: {
						widths: [20, 40, 80, "*", 100],
						headerRows: 1,
						body: await getBody(),
					},
					layout: "lightHorizontalLines",
				},
			];

			pdfMake
				.createPdf(
					{
						content,
						images: logoImageMap,
						styles: {
							header: {
								fontSize: 16,
								bold: true,
							},
							warning: {
								color: "red",
							},
							bigger: {
								fontSize: 15,
								italics: true,
							},
							defaultTable: {
								margin: [0, 5, 0, 5],
							},
							tableHeader: {
								bold: true,
								fontSize: 13,
								color: "grey",
							},
						},
						defaultStyle: {
							columnGap: 15,
							fontSize: 10,
							font: "chineseFont",
						},
					},
					undefined,
					pdfFonts,
				)
				.download(FILE_NAME);
		}
	};
}
