import styled from "@emotion/styled";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useGetGcsUrl } from "@yachelee/koden-components/koden-gcs-provider";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import * as _ from "lodash";
import { FC } from "react";
import { ProxyKodenTextField } from "../../../../ProxyKodenTextField";
import { defaultLogoVertical } from "../../../moo-config";
import { manufacturer as manufacturerEnum } from "../../../moo-enums";
import { useIsEdit } from "../../../moo-use-is-edit";
import { useForm } from "../../hooks/useForm";
import { useUpdate } from "../../hooks/useUpdate";
import Stocks from "./Stocks";

const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	position: relative;
	padding: 1rem 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 1rem;
	grid-template-areas:
		"image image image"
		". . ."
		". . ."
		". . ."
		". . ."
		"stock stock stock";
	border-bottom: 1px solid #ebebeb;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: 170px repeat(4, 1fr);
		grid-template-areas:
			"image . . . ."
			"image . . . ."
			"image . . . ."
			"image stock stock stock stock";
	}
`;

const Index = styled.div`
	position: absolute;
	left: 0.5rem;
	top: 1.1rem;
	color: white;
`;

const ImageContainer = styled.a`
	grid-area: image;
	height: 175px;
	overflow: hidden;
	width: 100%;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		height: 178px;
	}
`;

const DeleteBtn = styled(CloseSharpIcon)`
	position: absolute;
	top: 1rem;
	right: 0.2rem;
	cursor: pointer;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		top: 0.7rem;
		right: 0;
	}
`;

const EmptyMessage = (
	<div
		style={{
			display: "flex",
			justifyContent: "center",
			padding: "1.5rem 0 0 0",
			color: "#C4C4C4",
		}}
	>
		尚未匯入出貨商品
	</div>
);

export const ItemList: FC = () => {
	const { deleteItem } = useUpdate();
	const { values, errors } = useForm();
	const isEdit = useIsEdit();
	const getGcsUrl = useGetGcsUrl();

	return (
		<>
			{values.items && values.items.length > 0
				? values.items.map(
						(
							{
								id,
								manufacturer_type,
								manufacturer,
								name,
								material,
								color,
								size,
								count,
								stocks,
								image,
								image_cut,
								original_code,
							},
							index,
						) => {
							const itemError = {
								stocks: [],
							};

							if (_.isArray(errors.items)) {
								// @ts-ignore
								itemError.stocks = errors.items[index]?.stocks || [];
							}
							const key = `${manufacturer_type}_${id}`;
							return (
								<Container key={key}>
									<Index>{index + 1}</Index>
									<ProxyKodenTextField
										id={`id_${key}`}
										label="編號"
										margin="none"
										defaultValue={id}
										error=""
										readOnly
										disabled
									/>
									<ProxyKodenTextField
										id={`manufacturerType_${key}`}
										label="來源"
										margin="none"
										defaultValue={manufacturerEnum.Label.get(manufacturer_type)}
										error=""
										readOnly
										disabled
									/>
									<ProxyKodenTextField
										id={`manufacturer_${key}`}
										label="廠商"
										margin="none"
										defaultValue={manufacturer}
										error=""
										readOnly
										disabled
									/>
									<ProxyKodenTextField
										id={`name_${key}`}
										label="品名"
										margin="none"
										defaultValue={name}
										error=""
										readOnly
										disabled
									/>
									<ProxyKodenTextField
										id={`material_${key}`}
										label="材質"
										margin="none"
										defaultValue={material}
										error=""
										readOnly
										disabled
									/>
									<ProxyKodenTextField
										id={`color_${key}`}
										label="顏色"
										margin="none"
										defaultValue={color}
										error=""
										readOnly
										disabled
									/>
									<ProxyKodenTextField
										id={`size_${key}`}
										label="尺寸"
										margin="none"
										defaultValue={size}
										error=""
										readOnly
										disabled
									/>
									<ProxyKodenTextField
										id={`original_code_${key}`}
										label="原廠編碼"
										margin="none"
										defaultValue={original_code?.original_code || ""}
										error=""
										readOnly
										disabled
									/>
									<KodenNumberField
										id={`count_${key}`}
										label="數量"
										margin="none"
										value={count}
										min={1}
										max={1000000}
										step={1}
										error=""
										readOnly
										disabled
										onChange={() => {}}
									/>

									<ImageContainer
										href={image === null ? defaultLogoVertical : getGcsUrl(image)}
										target="_blank"
										rel="noreferrer"
									>
										<img
											alt={name}
											src={image_cut !== null ? getGcsUrl(image_cut) : defaultLogoVertical}
											style={{
												width: "100%",
												height: "100%",
												objectFit: "cover",
											}}
										/>
									</ImageContainer>
									<Stocks id={id} count={count} values={stocks} errors={itemError.stocks} />
									{!isEdit && (
										<DeleteBtn
											id={`bin_${key}`}
											fontSize="small"
											color="disabled"
											onClick={() => {
												if (window.confirm("確定要刪除此商品?")) {
													deleteItem(id);
												}
											}}
										>
											x
										</DeleteBtn>
									)}
								</Container>
							);
						},
				  )
				: EmptyMessage}
		</>
	);
};

export default ItemList;
