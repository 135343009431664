export enum Value {
	UnApproved = "UNAPPROVED",
	Approved = "APPROVED",
	Return = "RETURN",
}

export enum Label {
	UnApproved = "未批准",
	Approved = "批准",
	Return = "退回",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.UnApproved:
			return Label.UnApproved;
		case Value.Approved:
			return Label.Approved;
		case Value.Return:
			return Label.Return;
	}
	return Label.UnApproved;
}
