import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { MooDanLink } from "../moo-dan-link";
import { useLinkDialogChoose } from "../moo-dialog-link-choose";
import { manufacturer } from "../moo-enums";
import { ManufacturerTypeSelectField } from "../moo-shared";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import type { VoucherRow as VoucherRowType } from "./@types/one";
import { Type } from "./@types/one";
import { IdNumberField } from "./IdNumberField";
import { ManufacturerIdTypeableSelectField } from "./ManufacturerIdTypeableSelectField";
import { useUpdate } from "./hooks/useUpdate";

export type Props = VoucherRowType & {
	index: number;
	error: Record<keyof VoucherRowType, string>;
};

const StyledMooDanLink = styled(MooDanLink)`
	margin-top: 3px;
	margin-left: 14px;
	font-size: 0.75rem;
	line-height: 1.66;
	letter-spacing: 0.03333em;
`;

const WEB_BREAKPOINT = "1024px";

const Container = styled.div<{ hasManufacturerId: boolean }>`
	position: relative;
	grid-gap: 10px;
	border-radius: 3px;
	display: grid;
	grid-template-columns: auto 180px auto auto auto ${({ hasManufacturerId }) => (hasManufacturerId ? " auto" : "")} auto auto;
	grid-template-rows: repeat(2, auto);
	grid-template-areas:
		"id account_type_id type link_id manufacturer_type${({ hasManufacturerId }) =>
			hasManufacturerId ? " manufacturer_id" : ""} amount delete_btn"
		".  summary summary summary summary${({ hasManufacturerId }) => (hasManufacturerId ? " summary" : "")} summary .";

	@media screen and (max-width: 768px) {
		grid-template-columns: auto 120px auto auto;
		grid-template-rows: repeat(3, auto);
		grid-template-areas:
			"id     account_type_id type              delete_btn"
			".      link_id         manufacturer_type manufacturer_id"
			".      amount          amount            amount"
			".      summary         summary           summary";
	}

	#amount > * {
		width: 100%;
	}
`;

const DeleteBtn = styled(CloseSharpIcon)`
	position: absolute;
	top: 12px;
	right: 12px;
	cursor: pointer;
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		position: unset;
		top: unset;
		right: unset;
	}
`;

const SubTitle = styled.div`
	position: relative;
	font-size: 0.8rem;
	color: #858585;
	grid-column: 1/-1;
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column: unset;
	}
`;

export function VoucherRow({
	voucher_row_id,
	account_type_id,
	type,
	link_id,
	summary,
	debit_amount,
	credit_amount,
	index,
	error,
	manufacturer_type,
	manufacturer_id,
}: Props) {
	const { updateVoucherRowAccountTypeId, updateVoucherRow, updateVoucherRowType, removeVoucherRow } = useUpdate();
	const { component, open } = useLinkDialogChoose(
		`link-dialog-${voucher_row_id}`,
		(rows) => {
			if (rows.length > 0) {
				updateVoucherRow(voucher_row_id, { link_id: rows[0].id });
			}
		},
		false,
	);

	return (
		<>
			{component}
			<Container hasManufacturerId={manufacturer_type !== null}>
				<div style={{ gridArea: "id", display: "flex", justifyContent: "center" }}>
					<SubTitle>{index + 1}</SubTitle>
				</div>
				<div style={{ gridArea: "account_type_id" }}>
					<IdNumberField
						value={account_type_id!}
						error={error.account_type_id}
						onChange={(accountTypeId) => updateVoucherRowAccountTypeId(voucher_row_id, accountTypeId)}
					/>
				</div>
				<div style={{ gridArea: "type", display: "flex", justifyContent: "center" }}>
					<MooTypeableSelect
						id={`type_${voucher_row_id}`}
						label="類型"
						error={error.type}
						options={[
							{ value: "DEBIT", label: "借" },
							{ value: "CREDIT", label: "貸" },
						]}
						value={type}
						onChange={(type) => updateVoucherRowType(voucher_row_id, type as Type)}
					/>
				</div>
				<div style={{ gridArea: "link_id", display: "flex", flexDirection: "column" }}>
					<ProxyKodenTextField
						id={`link_id_${voucher_row_id}`}
						value={link_id}
						label="連結單號"
						outlined
						margin="none"
						error={error.link_id}
						readOnly
						onClick={open}
						onChange={() => {}}
					/>
					{link_id !== null && link_id !== "" && <StyledMooDanLink text="連結">{link_id}</StyledMooDanLink>}
				</div>
				<div style={{ gridArea: "manufacturer_type", display: "flex", justifyContent: "center" }}>
					<ManufacturerTypeSelectField
						id="manufacturer_type"
						label="廠商類型"
						value={manufacturer_type as manufacturer.Value.Value}
						onChange={(manufacturer_type) =>
							updateVoucherRow(voucher_row_id, {
								manufacturer_type: manufacturer_type as manufacturer.Value.Value,
								manufacturer_id: null,
							})
						}
					/>
				</div>
				{manufacturer_type !== null && (
					<div style={{ gridArea: "manufacturer_id" }}>
						<ManufacturerIdTypeableSelectField
							type={manufacturer_type}
							value={manufacturer_id}
							error=""
							onChange={async (manufacturer_id) => {
								updateVoucherRow(voucher_row_id, { manufacturer_id });
							}}
						/>
					</div>
				)}
				<div id="amount" style={{ gridArea: "amount", display: "flex", justifyContent: "center" }}>
					{type === Type.Debit && (
						<KodenNumberField
							id={`debit_amount_${voucher_row_id}`}
							hasDollar
							value={debit_amount}
							error={error.debit_amount}
							outlined
							margin="none"
							label="借方金額"
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							step={1}
							onChange={(value) => updateVoucherRow(voucher_row_id, { debit_amount: value })}
						/>
					)}
					{type === Type.Credit && (
						<KodenNumberField
							id={`credit_amount_${voucher_row_id}`}
							hasDollar
							value={credit_amount}
							error={error.credit_amount}
							outlined
							margin="none"
							label="貸方金額"
							min={0}
							max={Number.MAX_SAFE_INTEGER}
							step={1}
							onChange={(value) => updateVoucherRow(voucher_row_id, { credit_amount: value })}
						/>
					)}
				</div>
				<div style={{ gridArea: "delete_btn", display: "flex", justifyContent: "center" }}>
					<DeleteBtn
						id={`delete_btn_${voucher_row_id}`}
						fontSize="small"
						color="disabled"
						onClick={() => removeVoucherRow(voucher_row_id)}
					>
						x
					</DeleteBtn>
				</div>
				<div style={{ gridArea: "summary" }}>
					<ProxyKodenTextField
						id={`summary_${voucher_row_id}`}
						value={summary}
						label="摘要"
						outlined
						margin="none"
						multiline
						error={error.summary}
						onChange={(summary) => {
							updateVoucherRow(voucher_row_id, { summary });
						}}
					/>
				</div>
			</Container>
		</>
	);
}

export default VoucherRow;
