import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { Query } from "./@types";
import { getSearchCondition } from "./getSearchCondition";

export function useGraphQLVariables(query: Query) {
	const { value: branch } = useBranch();
	const where: Record<string, any> = {
		...getSearchCondition(query.search),
		branch_id: {
			_eq: branch,
		},
		status: {
			_eq: Status.Present,
		},
	};

	return {
		limit: 10,
		offset: query.page * 10,
		where,
		order_by: [
			{
				create_date: "desc",
			},
		],
	};
}
