import type { CellType } from "@yachelee/koden-components/koden-table";
import { DateFormat } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "transfer_date",
			align: "left",
			component: ({ transfer_date }: Row) => {
				return <DateFormat>{transfer_date}</DateFormat>;
			},
		},
		{
			id: "owner_name",
			align: "left",
			component: ({ owner_name }: Row) => {
				return <div>{owner_name}</div>;
			},
		},
		{
			id: "source_warehouse",
			align: "left",
			component: ({ source_warehouse }: Row) => {
				return <div>{source_warehouse}</div>;
			},
		},
		{
			id: "target_warehouse",
			align: "left",
			component: ({ target_warehouse }: Row) => {
				return <div>{target_warehouse}</div>;
			},
		},
		{
			id: "item_names",
			align: "left",
			component: ({ item_names }: Row) => {
				return <div>{item_names.join("/ ")}</div>;
			},
		},
	];
}
