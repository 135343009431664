import { number, object, SchemaOf } from "yup";
import { enumRequired, stringOptional, stringRequired } from "../../moo-schemas";
import { Type, VoucherRow } from "../@types/one";

function amountSchema(id: string, type: Type) {
	return number()
		.nullable(true)
		.test(id, "請輸入金額", (value, { parent }) => {
			const row: VoucherRow = parent;
			if (row.type !== type) return true;

			return value !== null && value !== 0;
		});
}

// @ts-ignore
export const rowSchema: SchemaOf<VoucherRow> = object().shape({
	voucher_row_id: stringRequired("voucher_row_id"),
	account_type_id: stringRequired("account_type_id"),
	subject_id_number: stringRequired("subject_id_number"),
	type: enumRequired(Type),
	link_id: stringOptional,
	summary: stringOptional,
	debit_amount: amountSchema("debit_amount", Type.Debit),
	credit_amount: amountSchema("debit_amount", Type.Credit),
	manufacturer_type: stringOptional,
	manufacturer_id: stringOptional,
});
