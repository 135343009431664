import { useBranch } from "../moo-branch-provider";
import { defectiveProduct, exportGoods, importGoods, returnGoods, Status, stockTransfer } from "../moo-enums";

export function useVariables({ id, itemIds = [] }: { id: string; itemIds?: string[] }) {
	const { value: branch } = useBranch();
	let itemIdVariables: Record<string, any> = {};

	if (itemIds.length > 0) {
		itemIdVariables = {
			id: {
				_in: itemIds,
			},
		};
	}

	return {
		id,
		imported_good_items_where: {
			...itemIdVariables,
			import_good: {
				status: {
					_eq: Status.Present,
				},
				branch_id: {
					_eq: branch,
				},
				warehouse_id: {
					_eq: id,
				},
				state: {
					_eq: importGoods.State.Value.Verified,
				},
			},
		},
		exported_good_item_stocks_where: {
			warehouse_id: {
				_eq: id,
			},
			export_good_item: {
				...itemIdVariables,
				export_good: {
					status: {
						_eq: Status.Present,
					},
					branch_id: {
						_eq: branch,
					},
					state: {
						_in: [exportGoods.State.Value.Delivering, exportGoods.State.Value.Delivered],
					},
				},
			},
		},
		stock_transfer_items_where: {
			...itemIdVariables,
			stock_transfer: {
				status: {
					_eq: Status.Present,
				},
				state: {
					_eq: stockTransfer.State.Value.Verified,
				},
				branch_id: {
					_eq: branch,
				},
				_or: [
					{
						source_warehouse_id: {
							_eq: id,
						},
					},
					{
						target_warehouse_id: {
							_eq: id,
						},
					},
				],
			},
		},
		returned_goods_items_where: {
			...itemIdVariables,
			return_good: {
				status: {
					_eq: Status.Present,
				},
				branch_id: {
					_eq: branch,
				},
				state_warehouse: {
					_eq: returnGoods.StateWarehouse.Value.Entered,
				},
			},
			warehouse_id: {
				_eq: id,
			},
		},
		maintaining_defective_items_where: {
			...itemIdVariables,
			defective_product: {
				status: {
					_eq: Status.Present,
				},
				branch_id: {
					_eq: branch,
				},
				state: {
					_eq: defectiveProduct.State.Value.MAINTAINING,
				},
				found_warehouse_id: {
					_eq: id,
				},
			},
		},
		maintained_defective_items_where: {
			...itemIdVariables,
			defective_product: {
				status: {
					_eq: Status.Present,
				},
				branch_id: {
					_eq: branch,
				},
				state: {
					_eq: defectiveProduct.State.Value.MAINTAINED_AND_IMPORTED,
				},
				found_warehouse_id: {
					_eq: id,
				},
			},
		},
	};
}
