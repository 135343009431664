export function useGraphQLQueryFields(): string {
	return `
			id
			manufacturer_type
			manufacturer_id
			owner {
				name
			}
			category_id
			sub_category_id
			name
			material
			size
			color
			cost
			purchase_price
			price
			original_code
			image
			image_cut
			country
		`;
}
