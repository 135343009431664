import { gql } from "@apollo/client";

const itemBlock = `
		id
		name
		image
		image_cut
		material
		color
		size
		purchase_price
		price
		note
		cost
		manufacturer{
			id
			type
			name
		}
		category {
			id
			name
		}
`;

export const queryField = `
    branch {
        id
        name
    }
    create_date
    update_date
    status
    employee_id
    state
    state_manager
    state_accountant
    quotation_date
    type_id
    type {
        id
        name
    }
    customer_id
    customer {
        id
        name
    }
    sales_id
    sales {
        id
        name
        tel
    }
    deliver_date
    is_include_tax
    tax
    payee_id
    payee {
        id
        name
        bank_name
        account_number
    }
    deliver_address
    price_discounted
    note
    deposit
    memo
    signed_files {
        quotation_signed_file_id
        value
    }
    voucher_rows {
        voucher_id
    }
    assistant_id
    assistant {
      name
      tel
    }
    items(order_by: {priority: asc}) {
        quotation_item_id
        id
        count
        price_final
        note
        item_spec {
            ${itemBlock}
        }
        item_custom {
            ${itemBlock}
        }
    }
`;

export const query = gql`
    query ($id: String!) {
        quotations_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
