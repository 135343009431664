import { KodenBinIcon } from "@yachelee/koden-components/koden-bin-icon";
import type { CellType } from "@yachelee/koden-components/koden-table";
import { useOnArchived } from "../../moo-archive-provider";
import { manufacturer } from "../../moo-enums";
import { useArchiveResource } from "../../moo-hooks";
import { Row as LogisticsManufacturer } from "../@types";

export function useCells(resource: string): CellType[] {
	const archiveResource = useArchiveResource(resource);
	const onArchived = useOnArchived();

	return [
		{
			id: "number",
			align: "left",
			component: ({ number }: LogisticsManufacturer) => {
				return <div>{number}</div>;
			},
		},
		{
			id: "type",
			align: "left",
			component: ({ type }: LogisticsManufacturer) => {
				return <div>{manufacturer.Label.get(type as string)}</div>;
			},
		},
		{
			id: "name",
			align: "left",
			component: ({ name }: LogisticsManufacturer) => {
				return <div>{name}</div>;
			},
		},
		{
			id: "contact",
			align: "left",
			component: ({ contact }: LogisticsManufacturer) => {
				return <div>{contact}</div>;
			},
		},
		{
			id: "tel",
			align: "left",
			component: ({ tel }: LogisticsManufacturer) => {
				return <div>{tel}</div>;
			},
		},
		{
			id: "email",
			align: "left",
			component: ({ email }: LogisticsManufacturer) => {
				return <div>{email}</div>;
			},
		},
		{
			id: "delete",
			align: "center",
			component: ({ id, name }: LogisticsManufacturer) => {
				return (
					<KodenBinIcon
						id={`DELETE_${id}`}
						onClick={async (e) => {
							e.stopPropagation();
							if (await archiveResource({ id, name })) {
								onArchived(id.toString());
							}
						}}
					/>
				);
			},
		},
	];
}
