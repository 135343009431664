import FormHelperText from "@mui/material/FormHelperText";
import { FC } from "react";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import ColumnType from "../@types/columnType";
import { ErrorsType } from "../getErrors";
import { SetStateMapType, StateMapType } from "../hooks/useStateMap";

type Props = {
	columns: ColumnType[];
	disabled: boolean;
	dirty: boolean;
	errors: ErrorsType;
	errorMsg: string;
	stateMap: StateMapType;
	setStateMap: SetStateMapType;
};

const DialogColumns: FC<Props> = ({ columns, disabled, dirty, errors, errorMsg, stateMap, setStateMap }) => {
	return (
		<>
			{columns.map((column) => {
				const { id, label, autoFocus, placeholder } = column;
				const value = stateMap[id];
				const error = !dirty ? "" : errors[id];

				return (
					<ProxyKodenTextField
						key={id}
						id={id}
						readOnly={false}
						label={label}
						autoFocus={autoFocus}
						placeholder={placeholder}
						disabled={disabled}
						value={value as string}
						error={error}
						onChange={(value) => {
							setStateMap((map) => {
								return { ...map, [id]: value };
							});
						}}
					/>
				);
			})}
			<FormHelperText error={errorMsg !== ""}>{errorMsg}</FormHelperText>
		</>
	);
};

export default DialogColumns;
