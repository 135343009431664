import getDollarNumberWithCommas from "../moo-shared/getDollarNumberWithCommas";

export function getRemainingAmountString({
	debit_amount,
	credit_amount,
}: {
	debit_amount: number;
	credit_amount: number;
}): string {
	return getDollarNumberWithCommas((debit_amount || 0) + (credit_amount === 0 ? 0 : credit_amount * -1));
}
