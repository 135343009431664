import { ItemType, manufacturer, quotation, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { Item, SignedFile, VoucherRow } from "./@types/one";
import { One } from "./index";

//this is for shape
export const DEFAULT_ITEM: Item = {
	quotation_item_id: "",
	id: "",
	type: ItemType.Spec,
	category: "",
	manufacturer_type: manufacturer.Value.Value.Domestic,
	manufacturer_id: "",
	manufacturer: "",
	name: "",
	image: "",
	image_cut: "",
	material: "",
	color: "",
	size: "",
	purchase_price: 0,
	price: 0,
	cost: 0,
	spec: "",
	count: 0,
	price_final: 0,
	price_total: 0,
	note: "",
	priority: 0,
};

//this is for shape
export const DEFAULT_SIGNED_FILE: SignedFile = {
	quotation_signed_file_id: "",
	value: "",
};

export const DEFAULT_VOUCHER_ROW: VoucherRow = {
	voucher_id: "",
};

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();

	return {
		status: Status.Present,
		employee_id: null,
		state: quotation.State.Value.WAIT_TO_PAY,
		state_manager: quotation.StateManager.Value.UNAPPROVED,
		state_accountant: quotation.StateAccountant.Value.PENDING,
		quotation_date: now,
		type_id: "",
		type_name: "",
		customer_id: null,
		customer_name: "",
		assistant_id: null,
		assistant_name: "",
		assistant_tel: "",
		sales_id: null,
		sales_name: "",
		sales_tel: "",
		deliver_date: "",
		tax: 0,
		payee_id: null,
		payee_name: "",
		payee_bank: "",
		payee_account_number: "",
		deliver_address: "",
		items: [],
		price_total: 0,
		price_discounted: 0,
		note: "",
		deposit: 0,
		cost: 0,
		remaining_fee: 0,
		memo: "",
		signed_files: [],
		voucher_rows: [],
		create_date: now,
		update_date: now,
	};
}
