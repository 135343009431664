import type { purchaseOrder } from "../moo-enums";

export type GraphQLColumnsResult = {
	id: string;
	owner: { name: string } | null;
	foreign_manufacturer: { name: string } | null;
	deliver_date: string | null;
	state: purchaseOrder.State.Value;
	state_manager: purchaseOrder.StateManager.Value;
	state_accountant: purchaseOrder.StateAccountant.Value;
	warehouse: { name: string };
	request_buy_ids: { value: string }[];
};

export const graphQLColumns = `{
		id
		owner{
      name
    }
    foreign_manufacturer{
      name
    }
    deliver_date
    state
    state_manager
    state_accountant
    warehouse{
      name
    }
    request_buy_ids{
      value
    }
}`;
