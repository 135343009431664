import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Employee } from "..";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { Permission, usePermissions } from "../../moo-me-provider";
import { useGetFieldError } from "../../moo-shared";
import { TABLET_BREAKPOINT, WEB_BREAKPOINT } from "../config";
import { SALARY_FIELDS as salaryFields } from "../fields";
import { useForm } from "../hooks/useForm";

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 2rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const Title = styled.h3`
	margin: 0;
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const NumberFieldEnd = styled(KodenNumberField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

export function Salary() {
	const { values, errors, setFieldValue } = useForm();
	const [isExpanded, setIsExpanded] = useState(false);
	const getFieldError = useGetFieldError<Employee>();
	const permissions = usePermissions();
	const disabled = !permissions.includes(Permission.EmployeeSalaryWrite);

	useEffect(() => {
		if (isExpanded) return;
		const fields = new Set(salaryFields);
		for (const field in errors) {
			if (!fields.has(field)) continue;
			return setIsExpanded(true);
		}
	}, [errors]);

	return (
		<Accordion
			disableGutters
			expanded={isExpanded}
			onChange={(e) => {
				if (e.type !== "click") return;
				setIsExpanded(!isExpanded);
			}}
		>
			<AccordionSummary expandIcon={<KeyboardArrowDown />} aria-controls="panel1a-content" id="panel1a-header">
				<Title>薪資</Title>
			</AccordionSummary>
			<AccordionDetails>
				<FieldContainer>
					<KodenDateField
						id="onboard_date"
						label="到職日"
						disabled={disabled}
						error={getFieldError("onboard_date")}
						value={values.onboard_date}
						onChange={(value) =>
							setFieldValue(
								"onboard_date",
								value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
							)
						}
					/>
					<KodenDateField
						id="salary_increased_date"
						label="調薪日期"
						disabled={disabled}
						error={getFieldError("salary_increased_date")}
						value={values.salary_increased_date}
						onChange={(value) =>
							setFieldValue(
								"salary_increased_date",
								value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
							)
						}
					/>
					<NumberFieldEnd
						id="salary_basic"
						label="底薪"
						disabled={disabled}
						hasDollar
						outlined
						margin="none"
						value={values.salary_basic}
						error={getFieldError("salary_basic")}
						step={1}
						min={0}
						max={Number.MAX_SAFE_INTEGER}
						onChange={(value) => {
							if (!disabled) {
								setFieldValue("salary_basic", value);
							}
						}}
					/>
					<ProxyKodenTextField
						id="bank_account"
						label="戶名"
						disabled={disabled}
						outlined
						margin="none"
						value={values.bank_account}
						error={getFieldError("bank_account")}
						onChange={(value) => setFieldValue("bank_account", value)}
					/>
					<ProxyKodenTextField
						id="bank"
						label="銀行"
						disabled={disabled}
						outlined
						margin="none"
						value={values.bank}
						error={getFieldError("bank")}
						onChange={(value) => setFieldValue("bank", value)}
					/>
					<TextFieldEnd
						id="bank_account_number"
						label="帳號"
						disabled={disabled}
						outlined
						margin="none"
						value={values.bank_account_number}
						error={getFieldError("bank_account_number")}
						onChange={(value) => setFieldValue("bank_account_number", value)}
					/>
					<KodenNumberField
						id="labor_fee"
						label="勞保金額"
						readOnly={disabled}
						hasDollar
						outlined
						margin="none"
						value={values.labor_fee}
						error={getFieldError("labor_fee")}
						step={1}
						min={0}
						max={100000000}
						onChange={(value) => {
							if (!disabled) {
								setFieldValue("labor_fee", value);
							}
						}}
					/>
					<KodenNumberField
						id="healthcare_fee"
						label="健保金額"
						readOnly={disabled}
						hasDollar
						outlined
						margin="none"
						value={values.healthcare_fee}
						error={getFieldError("healthcare_fee")}
						step={1}
						min={0}
						max={100000000}
						onChange={(value) => {
							if (!disabled) {
								setFieldValue("healthcare_fee", value);
							}
						}}
					/>
					<KodenNumberField
						id="insurance_day"
						label="投保天數"
						readOnly={disabled}
						outlined
						margin="none"
						value={values.insurance_day}
						error={getFieldError("insurance_day")}
						step={1}
						min={0}
						max={100000000}
						onChange={(value) => {
							if (!disabled) {
								setFieldValue("insurance_day", value);
							}
						}}
					/>
					<ProxyKodenTextField
						id="insurance_unit"
						label="投保單位"
						disabled={disabled}
						outlined
						margin="none"
						value={values.insurance_unit}
						error={getFieldError("insurance_unit")}
						onChange={(value) => setFieldValue("insurance_unit", value)}
					/>
					<KodenNumberField
						id="insurance_fee"
						label="投保金額"
						readOnly={disabled}
						hasDollar
						outlined
						margin="none"
						value={values.insurance_fee}
						error={getFieldError("insurance_fee")}
						step={1}
						min={0}
						max={Number.MAX_SAFE_INTEGER}
						onChange={(value) => {
							if (!disabled) {
								setFieldValue("insurance_fee", value);
							}
						}}
					/>
					<KodenMultilineField
						id="note"
						label="備註"
						disabled={disabled}
						style={{ gridColumn: "1 / -1" }}
						outlined
						margin="none"
						rows={2}
						value={values.note}
						error={getFieldError("note")}
						onChange={(value) => setFieldValue("note", value)}
					/>
				</FieldContainer>
			</AccordionDetails>
		</Accordion>
	);
}
