import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "owner",
			label: "承辦人",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
		{
			id: "manufacturer",
			label: "廠商",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "deliver_date",
			label: "交貨日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state",
			label: "狀態",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "state_manager",
			label: "主管簽核",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state_accountant",
			label: "會計確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "warehouse",
			label: "收貨倉庫",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "request_buy_ids",
			label: "連結請購單",
			align: "left",
			style: {
				minWidth: 100,
			},
		},
	];
}
