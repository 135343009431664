import { Row } from "./@types";

export function mapResults(purchase_orders = []) {
	return purchase_orders.map((data: any) => {
		const row: Row = {
			id: data.id,
			owner: data.owner?.name || "",
			manufacturer: data.foreign_manufacturer?.name || "",
			deliver_date: data.deliver_date,
			state: data.state,
			state_manager: data.state_manager,
			state_accountant: data.state_accountant,
			warehouse: data.warehouse?.name ?? "",
			// @ts-ignore
			request_buy_ids: data.request_buy_ids.map((e) => e.value),
		};
		return row;
	});
}
