import { Value } from "./Value";

export enum Label {
	Domestic = "國內",
	Foreign = "國外",
}

export function get(value: string | null): string {
	switch (value) {
		case Value.Foreign:
			return Label.Foreign;
		case Value.Domestic:
		default:
			return Label.Domestic;
	}
}
