export function getDollarNumberWithCommas(x: number): string {
	if (!x) return "$0";

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		maximumFractionDigits: 0,
	});

	return formatter.format(x);
}

export default getDollarNumberWithCommas;
