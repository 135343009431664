import { gql } from "@apollo/client";

const itemRecordFields = `
    name
    image
    image_cut
    material
    color
    size
    purchase_price
    price
    cost
    category {
      name
    }
    manufacturer {
      type
      id
      name
    }
`;

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    state
    transfer_date
    owner_id
    source_warehouse_id
    target_warehouse_id
    
    items {
      stock_transfer_item_id
      id
      priority
      transfer_count
      item_spec {
        ${itemRecordFields}
      }
      item_custom {
        ${itemRecordFields}
      }
    }
`;

export const query = gql`
    query ($id: String!) {
        stock_transfers_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
