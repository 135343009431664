import { MooOrderOne, One } from "../moo-order-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Order } from "./findOne/Order";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { OrderInstructionsTable } from "./updateOne/OrderInstructionsTable";
import { OrderItemsTable } from "./updateOne/OrderItemsTable";
import { OrderTable } from "./updateOne/OrderTable";

export type MooOrderUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooOrderUpdate({ id, onCancel }: MooOrderUpdateProps) {
	return (
		<MooUpdateFormNew<Order, One>
			id={id}
			onCancel={onCancel}
			operationName="order-one"
			query={query}
			tableName="orders"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooOrderOne}
			tables={[OrderInstructionsTable, OrderItemsTable, OrderTable]}
		/>
	);
}
