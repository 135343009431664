import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    type
    state
    state_warehouse
    order_id
    note
    order_goods_date
    deliver_goods_date
    warehouse_id
    order_id
    manufacturer_type
    manufacturer_foreign {
      id
      name
      fax
      tels {
        tel
      }
    }
    manufacturer_domestic {
      id
      name
      fax
      tels {
        tel
      }
    }
    sales {
      id
      name
      tel
    }
    voucher_rows {
      voucher_id
    }
    items {
      order_good_item_id
      order_good_id
      id
      count
      price_final
      priority
      item_spec {
        name
        cost
        material
        color
        size
        price
        purchase_price
        note
        image
        image_cut
        manufacturer{
          name
          id
          type
        }
        category {
          name
        }
      }
      item_custom {
        name
        cost
        material
        color
        size
        price
        purchase_price
        note
        image
        image_cut
        manufacturer{
          name
          id
          type
        }
        category {
          name
        }
      }
    }
    instructions {
      order_good_instruction_id
      value
    }
    employee {
      nick_name
    }
    order {
      employee {
        nick_name
      }
    }
`;

export const query = gql`
    query ($id: String!) {
        order_goods_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
