import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooAccountSubjectList } from "../components/moo-components/moo-account-subject-list";
import { prefixPath } from "./AccountTypeRootRoute";

export const path = "/account_types";

const AccountTypesRoute = () => {
	const navigate = useNavigate();
	const branch = useBranchInQuery();

	return (
		<MooAccountSubjectList
			onClick={(category) => {
				navigate(`${prefixPath}/${category}?branch=${branch}`);
			}}
		/>
	);
};

export default AccountTypesRoute;
