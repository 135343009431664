import { One } from "../../moo-role-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";

export function mapperInitialValues(record: Row): One {
	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		name: record.name,
		permissions: record.permissions,
	};
}
