import { CellType, HeaderType, MooDialogChoose } from "../moo-dialog-choose";
import { quotation } from "../moo-enums";
import { getSearchCondition } from "../moo-quotation-list";
import { DateFormat } from "../moo-shared";
import { GraphQLResultRow, Row } from "./@types";
import { useStore } from "./stores/useStore";

export type MooDialogQuotationChooseProps = {
	id: string;
	states?: quotation.State.Value[];
	stateManagers?: quotation.StateManager.Value[];
	stateAccountants?: quotation.StateAccountant.Value[];
	isMultiple?: boolean;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
};

const QUOTATION_DATE = "quotation_date";

const arr = [
	{ id: "id", label: "編號" },
	{ id: "type_name", label: "類型" },
	{ id: "customer_name", label: "客戶" },
	{ id: "sales_name", label: "業務" },
	{ id: QUOTATION_DATE, label: "報價日期" },
];

export function MooDialogQuotationChoose({
	id,
	states = quotation.State.getValues(),
	stateManagers = quotation.StateManager.getValues(),
	stateAccountants = quotation.StateAccountant.getValues(),
	isMultiple = true,
	open,
	onClose,
	onOk,
}: MooDialogQuotationChooseProps) {
	const store = useStore();
	const headers = arr
		.map((e) => {
			return { ...e, align: "left", style: { minWidth: 65 } };
		})
		.concat([
			{ id: "state", label: "狀態", align: "left", style: { minWidth: 65 } },
			{
				id: "state_manager",
				label: "主管簽核",
				align: "left",
				style: { minWidth: 90 },
			},
			{
				id: "state_accountant",
				label: "會計確認",
				align: "left",
				style: { minWidth: 90 },
			},
		]) as HeaderType[];

	const cells = arr
		.map(({ id }) => {
			return {
				id,
				align: "left",
				component: (row: Row) => (id === QUOTATION_DATE ? <DateFormat>{row[id]}</DateFormat> : <div>{row[id]}</div>),
			};
		})
		.concat([
			{
				id: "state",
				align: "left",
				component(row: Row) {
					return <div>{quotation.State.getLabel(row.state)}</div>;
				},
			},

			{
				id: "state_manager",
				align: "left",
				component(row: Row) {
					return <div>{quotation.StateManager.getLabel(row.state_manager)}</div>;
				},
			},

			{
				id: "state_accountant",
				align: "left",
				component(row: Row) {
					return <div>{quotation.StateAccountant.getLabel(row.state_accountant)}</div>;
				},
			},
		]) as CellType[];

	return (
		<MooDialogChoose
			id={id}
			label=""
			graphQLColumns={`{
				id
				sales{name}
				customer{name}
				type{name}
				quotation_date
				state
				state_manager
				state_accountant
			}`}
			where={{
				...getSearchCondition(store.search),
				state: {
					_in: states,
				},
				state_manager: {
					_in: stateManagers,
				},
				state_accountant: {
					_in: stateAccountants,
				},
			}}
			title="報價單"
			resource="quotations"
			open={open}
			onOk={(dataSet) => {
				onOk(dataSet as Row[]);
				onClose();
			}}
			onClose={onClose}
			headers={headers}
			cells={cells}
			store={store}
			isMultiple={isMultiple}
			mapResults={(results: GraphQLResultRow[]): Row[] => {
				return results.map((result) => ({
					...result,
					sales_name: result.sales.name,
					customer_name: result.customer.name,
					type_name: result.type.name,
				}));
			}}
		/>
	);
}
