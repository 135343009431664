import CancelIcon from "@mui/icons-material/Cancel";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import { Formik } from "formik";
import * as _ from "lodash";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooSubmitButton } from "../moo-submit-button";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { Gender, Vip } from "./@types/customer";
import { Props } from "./@types/props";
import { getGenderString } from "./getGenderString";
import { getVipString } from "./getVipString";
import { useForm } from "./hooks/useForm";
import { useGetError } from "./hooks/useGetError";
import { useUpdate } from "./hooks/useUpdate";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 2rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const TextFieldStart = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-start: 1;
	}
`;

const TelTextField = styled(ProxyKodenTextField)`
	& .MuiOutlinedInput-input {
		padding-right: 35px;
	}
`;

const TelDeleteBtn = styled(CancelIcon)`
	position: absolute;
	top: 35%;
	right: 8px;
	transform: translateY(-50%);
	cursor: pointer;
`;

const TextIconBtn = styled.div`
	border: 1px dashed #c4c4c4;
	border-radius: 4px;
	cursor: pointer;
	& .top-bar {
		width: 100%;
		padding: 10.5px 14px;
	}
`;

function Form({ isLoading = false, isCancelDisabled, onCancel }: Omit<Props, "initialValues" | "onSubmit">) {
	const { handleSubmit, errors, values, setFieldValue } = useForm();
	const { addTel, delTel, updateTel } = useUpdate();
	const getError = useGetError();

	return (
		<form onSubmit={handleSubmit}>
			<FieldContainer>
				<MooEnumTypeableSelectField
					id="customer_types"
					resource="customer_types"
					label="類型"
					value={values.type_id}
					error={getError("type_id")}
					onChange={(value) => setFieldValue("type_id", value)}
				/>
				<ProxyKodenTextField
					id="job"
					label="職業"
					outlined
					margin="none"
					value={values.job}
					error={getError("job")}
					onChange={(value) => setFieldValue("job", value)}
				/>
				<ProxyKodenTextField
					id="name"
					label="姓名"
					disabled={isLoading}
					outlined
					margin="none"
					value={values.name}
					error={getError("name")}
					onChange={(value) => setFieldValue("name", value)}
				/>
				<MooTypeableSelect
					id="gender"
					label="性別"
					value={values.gender}
					error={getError("gender")}
					options={[
						{ label: getGenderString(Gender.None), value: Gender.None },
						{ label: getGenderString(Gender.Male), value: Gender.Male },
						{ label: getGenderString(Gender.Female), value: Gender.Female },
					]}
					onChange={(value) => setFieldValue("gender", value)}
				/>
				<KodenDateField
					id="birthday"
					label="生日(月/日)"
					views={["month", "day"]}
					inputFormat="MM/dd"
					mask="__/__"
					error={getError("birthday")}
					value={values.birthday === null ? null : new Date(values.birthday)}
					onChange={(date) => {
						setFieldValue(
							"birthday",
							date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
						);
					}}
				/>
				<ProxyKodenTextField
					id="line"
					label="Line"
					outlined
					margin="none"
					value={values.line}
					error={getError("line")}
					onChange={(value) => setFieldValue("line", value)}
				/>
				<TextFieldEnd
					id="email"
					label="Email"
					outlined
					margin="none"
					value={values.email}
					error={getError("email")}
					onChange={(value) => setFieldValue("email", value)}
				/>
				<MooTypeableSelect
					id="vip"
					label="VIP"
					value={values.vip}
					error={getError("vip")}
					options={[
						{ label: "請選擇", value: null },
						{ label: getVipString(Vip.NORMAL), value: Vip.NORMAL },
						{ label: getVipString(Vip.KIND), value: Vip.KIND },
						{ label: getVipString(Vip.ACTIVE), value: Vip.ACTIVE },
						{ label: getVipString(Vip.SUPER), value: Vip.SUPER },
					]}
					onChange={(value) => setFieldValue("vip", value)}
				/>
				<ProxyKodenTextField
					id="tax_id"
					label="統編"
					outlined
					margin="none"
					value={values.tax_id}
					error={getError("tax_id")}
					onChange={(value) => setFieldValue("tax_id", value)}
				/>
				<TextFieldEnd
					id="address"
					label="地址"
					outlined
					margin="none"
					value={values.address}
					error={getError("address")}
					onChange={(value) => setFieldValue("address", value)}
				/>
				{values.tels.map(({ customer_tel_id, tel }, index) => {
					const error = _.get(errors, `tels.${index}.tel`, "");

					return (
						<div style={{ position: "relative" }} key={customer_tel_id}>
							<TelTextField
								id="tel"
								label={`電話${values.tels.length > 1 ? index + 1 : ""}`}
								outlined
								margin="none"
								value={tel}
								error={error}
								onChange={(value) => updateTel(customer_tel_id, value)}
							/>
							{values.tels.length > 1 && (
								<TelDeleteBtn fontSize="small" color="disabled" onClick={() => delTel(customer_tel_id)}>
									x
								</TelDeleteBtn>
							)}
						</div>
					);
				})}
				<TextIconBtn>
					<KodenIconTextButton textColor="#757575" text="新增電話" onClick={() => addTel()} />
				</TextIconBtn>
				<TextFieldStart
					id="bank_account"
					label="戶名"
					outlined
					margin="none"
					value={values.bank_account}
					error={getError("bank_account")}
					onChange={(value) => setFieldValue("bank_account", value)}
				/>
				<ProxyKodenTextField
					id="bank"
					label="銀行"
					outlined
					margin="none"
					value={values.bank}
					error={getError("bank")}
					onChange={(value) => setFieldValue("bank", value)}
				/>
				<TextFieldEnd
					id="bank_account_number"
					label="帳號"
					outlined
					margin="none"
					value={values.bank_account_number}
					error={getError("bank_account_number")}
					onChange={(value) => setFieldValue("bank_account_number", value)}
				/>
				<KodenMultilineField
					id="note"
					label="備註"
					disabled={isLoading}
					style={{ gridColumn: "1 / -1" }}
					outlined
					margin="none"
					rows={2}
					value={values.note}
					error={getError("note")}
					onChange={(value) => setFieldValue("note", value)}
				/>
			</FieldContainer>
			<ButtonContainer>
				<KodenButton
					style={{ marginRight: "1rem" }}
					disabled={isCancelDisabled}
					onClick={async () => {
						if (window.confirm("確定取消並且回到上一頁？")) {
							onCancel();
						}
					}}
				>
					取消
				</KodenButton>
				<MooSubmitButton permission={Permission.CustomerUpdate} />
			</ButtonContainer>
		</form>
	);
}

export function MooCustomerOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.CustomerRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
