import { MooItemRowList } from "../../../moo-item-row-list";
import { FieldType as SpecCustomField } from "../../../moo-item-spec-and-custom-row";
import { useDeleteItem } from "../../hooks/useDeleteItem";
import { useForm } from "../../hooks/useForm";
import { useLockStock } from "../../hooks/useLockStock";
import { useUpdateItemReturnCount } from "../../hooks/useUpdateItemReturnCount";
import { useUpdateWarehouseId } from "../../hooks/useUpdateWarehouseId";

const ItemList = () => {
	const { values, errors } = useForm();
	const lockStock = useLockStock();
	const deleteItem = useDeleteItem();
	const updateItemReturnCount = useUpdateItemReturnCount();
	const updateWarehouseId = useUpdateWarehouseId();
	const specCustomFields = new Set<SpecCustomField>([
		"id",
		"name",
		"type",
		"category",
		"spec",
		"count",
		"return_count",
		"warehouse_id",
		"price",
		"price_final",
		"purchase_price",
		"cost",
		"price_total",
		"remove",
	]);
	const disabledFields = new Set<SpecCustomField>(["count", "price_final", "price_total"]);
	if (lockStock) {
		specCustomFields.delete("remove");
		disabledFields.add("return_count");
		disabledFields.add("warehouse_id");
	}

	return (
		<div>
			<header
				style={{
					borderBottom: "1px solid #EBEBEB",
					display: "flex",
					justifyContent: "space-between",
					marginBottom: ".3rem",
				}}
			>
				<h1 style={{ fontSize: "1.5rem" }}>退貨商品</h1>
			</header>
			<div
				style={{
					marginBottom: "2rem",
					maxWidth: "1024px",
				}}
			>
				<MooItemRowList
					// @ts-ignore
					items={values.items}
					errors={errors.items as string | (string | undefined)[]}
					specCustomFields={specCustomFields}
					disabledSpecCustomFields={disabledFields}
					onRemove={deleteItem}
					onReturnCountChange={updateItemReturnCount}
					onWarehouseIdChange={updateWarehouseId}
				/>
			</div>
		</div>
	);
};

export default ItemList;
