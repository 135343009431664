export const accountTypes = [
	{
		id: "67d5ed0d-500d-4342-97be-fda31f984a3c",
		label: "資產類",
	},
	{
		id: "3df49de8-4324-4805-83dd-24077ed7d31e",
		label: "負債類",
	},
	{
		id: "4a6df21e-1408-4a1f-9a2a-ef50c18abffe",
		label: "淨值類",
	},
	{
		id: "3430cdfd-c9b3-4b9c-a373-370b70bc3eb2",
		label: "營收類",
	},
	{
		id: "4d65d917-b975-4434-ad3f-33ef8ab3de24",
		label: "成本類",
	},
	{
		id: "df77d2af-93fa-4ef6-bd8f-3d1058639204",
		label: "費用類",
	},
];
