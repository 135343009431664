import * as pdfMake from "pdfmake/build/pdfmake";
import { pdfFonts } from "../../moo-config";
import { updateBodyRatio } from "./updateBodyRatio";
import { updateHeaderRatio } from "./updateHeaderRatio";
import { useGetPdfHeaderAndBody } from "./useGetPdfHeaderAndBody";
import { usePdfSubTitle } from "./usePdfSubTitle";

export enum PdfStyle {
	AlignmentLeft = "alignmentLeft",
	AlignmentRight = "alignmentRight",
	AlignmentCenter = "alignmentCenter",
	ColorRed = "colorRed",
	Bold = "bold",
	MarginLeft = "marginLeft",
	MarginFarLeft = "marginFarLeft",
}

//table cell widths
const widths: (string | number)[] = ["auto", "*", "auto", "auto", "auto"];

export function useOnBalanceSheetButtonClick() {
	const getPdfHeaderAndBody = useGetPdfHeaderAndBody();
	const pdfSubTitle = usePdfSubTitle();

	return async () => {
		let body: any[] = [["科目代號", "科目名稱", "金額/小計", "合計", "比率"]];
		const oneOneHeaderAndBody = await getPdfHeaderAndBody({
			headerIdNumber: "1100",
			headerName: "流動資產",
			id_numbers: [
				"1111",
				"1112-001",
				"1112-002",
				"1112-003",
				"1112-004",
				"1112-005",
				"1112-006",
				"1112-007",
				"1112-008",
				"1112-009",
				"1112-010",
				"1112-011",
				"1112-012",
				"1121",
				"1123",
				"1129",
				"1131",
				"1141",
				"1143",
				"1191",
				"1192",
				"1193",
			],
		});

		const oneThreeZeroZero = await getPdfHeaderAndBody({
			headerIdNumber: "1300",
			headerName: "長期資產",
			id_numbers: ["1310"],
		});

		const oneFourZeroZero = await getPdfHeaderAndBody({
			headerIdNumber: "1400",
			headerName: "固定資產",
			id_numbers: ["1451", "1452", "1491", "1492"],
		});

		const oneNineZeroZero = await getPdfHeaderAndBody({
			headerIdNumber: "1900",
			headerName: "其他資產",
			id_numbers: ["1910"],
		});
		const oneThousandTotalSum =
			oneOneHeaderAndBody.totalSum + oneThreeZeroZero.totalSum + oneFourZeroZero.totalSum + oneNineZeroZero.totalSum;

		updateHeaderRatio({ headerData: oneOneHeaderAndBody.header, totalSum: oneThousandTotalSum });
		updateHeaderRatio({ headerData: oneOneHeaderAndBody.header, totalSum: oneThousandTotalSum });
		updateHeaderRatio({ headerData: oneThreeZeroZero.header, totalSum: oneThousandTotalSum });
		updateHeaderRatio({ headerData: oneFourZeroZero.header, totalSum: oneThousandTotalSum });
		updateHeaderRatio({ headerData: oneNineZeroZero.header, totalSum: oneThousandTotalSum });

		updateBodyRatio({ bodyData: oneOneHeaderAndBody.body, totalSum: oneThousandTotalSum });
		updateBodyRatio({ bodyData: oneOneHeaderAndBody.body, totalSum: oneThousandTotalSum });
		updateBodyRatio({ bodyData: oneThreeZeroZero.body, totalSum: oneThousandTotalSum });
		updateBodyRatio({ bodyData: oneFourZeroZero.body, totalSum: oneThousandTotalSum });
		updateBodyRatio({ bodyData: oneNineZeroZero.body, totalSum: oneThousandTotalSum });

		body.push([
			{
				text: "1000",
				style: [PdfStyle.Bold],
			},
			"資產總額",
			"",
			{
				text: oneThousandTotalSum.toLocaleString(),
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
			{
				text: "100%",
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
		]);
		body.push(oneOneHeaderAndBody.header);
		body.push(...oneOneHeaderAndBody.body);
		body.push(oneThreeZeroZero.header);
		body.push(...oneThreeZeroZero.body);
		body.push(oneFourZeroZero.header);
		body.push(...oneFourZeroZero.body);
		body.push(oneNineZeroZero.header);
		body.push(...oneNineZeroZero.body);

		body.push([
			{
				text: "資 產 總 額",
				colSpan: 3,
				style: [PdfStyle.MarginFarLeft],
			},
			"",
			"",
			{
				text: oneThousandTotalSum.toLocaleString(),
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
			"",
		]);

		const twoOneZeroZeroHeaderAndBody = await getPdfHeaderAndBody({
			headerIdNumber: "2100",
			headerName: "流動負債",
			id_numbers: [
				"2121",
				"2122",
				"2123",
				"2123-001",
				"2123-002",
				"2123-003",
				"2124",
				"2131",
				"2191",
				"2191-001",
				"2191-002",
				"2192",
				"2194",
			],
			isReverseSum: true,
		});

		body.push([
			{
				text: "2000",
				style: [PdfStyle.Bold],
			},
			{
				text: "負債總額",
				colSpan: 2,
			},
			"",
			{
				text: twoOneZeroZeroHeaderAndBody.totalSum.toLocaleString(),
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
			{
				text:
					twoOneZeroZeroHeaderAndBody.totalSum === 0
						? "0%"
						: ((twoOneZeroZeroHeaderAndBody.totalSum / oneThousandTotalSum) * 100).toFixed(2) + "%",
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
		]);

		updateHeaderRatio({ headerData: twoOneZeroZeroHeaderAndBody.header, totalSum: oneThousandTotalSum });
		updateBodyRatio({ bodyData: twoOneZeroZeroHeaderAndBody.body, totalSum: oneThousandTotalSum });
		body.push(twoOneZeroZeroHeaderAndBody.header);
		body.push(...twoOneZeroZeroHeaderAndBody.body);

		const threeOneZeroZero = await getPdfHeaderAndBody({
			headerIdNumber: "3100",
			headerName: "資本",
			id_numbers: ["3110"],
			isReverseSum: true,
		});
		const threeThreeZeroZero = await getPdfHeaderAndBody({
			headerIdNumber: "3300",
			headerName: "公積及盈虧",
			id_numbers: ["3430", "3440"],
			isReverseSum: true,
		});

		const threeZeroZeroZeroSum = threeOneZeroZero.totalSum + threeThreeZeroZero.totalSum;
		body.push([
			{
				text: "3000",
				style: [PdfStyle.Bold],
			},
			{
				text: "資本及淨值總額",
				colSpan: 2,
			},
			"",
			{
				text: threeZeroZeroZeroSum.toLocaleString(),
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
			{
				text: threeZeroZeroZeroSum === 0 ? "0%" : ((threeZeroZeroZeroSum / oneThousandTotalSum) * 100).toFixed(2) + "%",
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
		]);

		updateHeaderRatio({ headerData: threeOneZeroZero.header, totalSum: oneThousandTotalSum });
		updateBodyRatio({ bodyData: threeOneZeroZero.body, totalSum: oneThousandTotalSum });
		body.push(threeOneZeroZero.header);
		body.push(...threeOneZeroZero.body);

		updateHeaderRatio({ headerData: threeThreeZeroZero.header, totalSum: oneThousandTotalSum });
		updateBodyRatio({ bodyData: threeThreeZeroZero.body, totalSum: oneThousandTotalSum });
		body.push(threeThreeZeroZero.header);
		body.push(...threeThreeZeroZero.body);

		body.push([
			{
				text: "負 債 及 淨 值 總 額",
				colSpan: 3,
				style: [PdfStyle.MarginFarLeft],
			},
			"",
			"",
			{
				text: oneThousandTotalSum.toLocaleString(),
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
			"",
		]);

		pdfMake
			.createPdf(
				{
					content: [
						{
							text: "睦穆家具設計有限公司-資產負債表",
							fontSize: 20,
							style: PdfStyle.AlignmentCenter,
						},
						pdfSubTitle,
						{
							table: {
								headerRows: 1,
								widths,
								body,
							},
							layout: {
								hLineWidth: function (i, node) {
									return 0; // No horizontal border for the specific cell
								},
								vLineWidth: function (i, node) {
									return 0; // No vertical border for the specific cell
								},
							},
						},
					],
					images: {},
					styles: {
						[PdfStyle.AlignmentLeft]: {
							alignment: "left",
						},
						[PdfStyle.AlignmentRight]: {
							alignment: "right",
						},
						[PdfStyle.AlignmentCenter]: {
							alignment: "center",
						},
						[PdfStyle.ColorRed]: {
							color: "red",
						},
						[PdfStyle.Bold]: {
							bold: true,
						},
						[PdfStyle.MarginLeft]: {
							margin: [10, 0, 0, 0],
						},
						[PdfStyle.MarginFarLeft]: {
							margin: [20, 0, 0, 0],
						},
					},
					defaultStyle: {
						columnGap: 15,
						fontSize: 10,
						font: "chineseFont",
					},
				},
				undefined,
				pdfFonts,
			)
			.download("moos-資產負債表.pdf");
	};
}
