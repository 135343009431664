import { gql } from "@apollo/client";
import { useBranch } from "../../moo-branch-provider";
import { useGraphQLRequest } from "../../moo-graphql-provider";

type Customer = {
	id: string;
	email: string;
};

export function useGetCustomersByEmail() {
	const { value: branch } = useBranch();
	const graphQLRequest = useGraphQLRequest();

	const query = gql`
		query ($where: customers_bool_exp) {
			customers(where: $where) {
				id
				email
			}
		}
	`;

	return async (email: string): Promise<Customer[]> => {
		const variables: Record<string, any> = {
			where: {
				email: {
					_eq: email,
					_neq: "",
				},
				branch_id: {
					_eq: branch,
				},
			},
		};

		const { customers } = await graphQLRequest({ query, variables });
		return customers;
	};
}
