import { gql } from "@apollo/client";
import * as _ from "lodash";
import { useState } from "react";
import { useBranch } from "../moo-branch-provider";
import { useFindOne } from "../moo-employee-update";
import { MooGraphQLTable } from "../moo-graphql-table";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Row } from "./@types";
import { getHumanMonth } from "./getHumanMonth";
import { useCells } from "./useCells";
import { useHeaders } from "./useHeaders";

export type MooUserPerformanceSubListProps = {
	year: number;
	month: number;
	employee_id: string;
	onRowClick: (row: Row, e: MouseEvent) => void;
};

export const MooUserPerformanceSubList = ({ year, month, employee_id }: MooUserPerformanceSubListProps) => {
	const permissions = usePermissions();
	const [page, setPage] = useState(0);
	const { value: branch } = useBranch();
	const cells = useCells(year, month, employee_id);
	const headers = useHeaders();
	const record = useFindOne(employee_id);
	if (!permissions.includes(Permission.PerformanceRead)) {
		return <MooNoAuthPage />;
	}

	if (record.isLoading) {
		return null;
	}

	const variables = {
		offset: 0,
		where: {
			order_date_year: {
				_eq: year,
			},
			order_date_month: {
				_eq: month,
			},
			order_branch_id: {
				_eq: branch,
			},
			employee_id: {
				_eq: employee_id,
			},
		},
	};

	return (
		<div>
			<h3>
				{/*@ts-ignore*/}
				{record.data.employees_by_pk.name} - {getHumanMonth(month)}月份
			</h3>

			<MooGraphQLTable
				headers={headers}
				cells={cells}
				page={page}
				rowsPerPage={Number.MAX_SAFE_INTEGER}
				onPageChange={setPage}
				operationName=""
				onRowClick={() => {}}
				mapData={(e) => {
					const total_count = _.get(e, "account_performances_aggregate.aggregate.count") || 0;

					return {
						// @ts-ignore
						results: e.account_performances_aggregate.nodes.map((e) => {
							const row: Row = {
								id: e.link_id,
								link_id: e.link_id,
								total_price: e.total_price,
								cost: e.cost,
								driver_fee: e.driver_fee,
								commission_refund: e.commission_refund,
								tax_price: e.tax_price,
								mgt_cost: e.mgt_cost,
								handling_fee: e.handling_fee,
								miscellaneous_charge: e.miscellaneous_charge,
								employee_percentage: e.employee_percentage,
								bonus_percentage: e.bonus_percentage,
								bonus: e.bonus,
								custom_bonus: e.custom_bonus,
								revenue: e.revenue,
								note: e.note,
							};
							return row;
						}),
						total_count,
					};
				}}
				query={gql`
					query ($where: account_performances_bool_exp, $offset: Int) {
						account_performances_aggregate(where: $where, offset: $offset) {
							nodes {
								link_id
								bonus
								custom_bonus
								commission_refund
								cost
								driver_fee
								employee_id
								employee {
									name
								}
								handling_fee
								mgt_cost
								miscellaneous_charge
								employee_percentage
								bonus_percentage
								order_branch_id
								order_date_month
								order_date_year
								revenue
								tax_price
								total_price
								note
							}
							aggregate {
								count
							}
						}
					}
				`}
				variables={variables}
			/>
		</div>
	);
};
