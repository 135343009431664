import styled from "@emotion/styled";
import { ReactNode } from "react";
import { useOnLinkClick } from "../moo-link-provider";

export type MooLinkFieldProps = {
	clickButton: ReactNode;
	label: string;
	values?: string[];
};

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const LinkButton = styled.div`
	color: blue;
	cursor: pointer;
	:hover {
		text-decoration: underline;
	}
`;

const FileUpload = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 0.3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.6rem;
	align-items: center;
	padding: 14px;
	min-height: 56px;
	text-align: center;
	height: fit-content;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto 1fr;
		grid-column-end: span 2;
		text-align: left;
		padding: 0 14px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

export function MooLinkField({ clickButton, label, values = [] }: MooLinkFieldProps) {
	const onLinkClick = useOnLinkClick();
	return (
		<FileUpload>
			{clickButton}
			{values.length !== 0 && (
				<div>
					{label}:{" "}
					{values.map((id) => (
						<LinkButton key={id} style={{ marginRight: "1rem" }} onClick={() => onLinkClick(id)}>
							{id}
						</LinkButton>
					))}
				</div>
			)}
		</FileUpload>
	);
}
