import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class SvcItemsTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "svc_item_id";
	}

	getTableName(): string {
		return "svc_items";
	}

	getPathName(): string {
		return "items";
	}

	getParentIdName(): string {
		return "svc_id";
	}

	getToUpdateColumns(): string[] {
		return ["name", "count", "price"];
	}
}
