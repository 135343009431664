import type { CellType } from "@yachelee/koden-components/koden-table";
import { returnGoods } from "../moo-enums";
import { useOnLinkClick } from "../moo-link-provider";
import { ClickableColumn, DateFormat, DollarNumber } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	const onLinkClick = useOnLinkClick();
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "return_date",
			align: "left",
			component: ({ return_date }: Row) => {
				return <DateFormat>{return_date}</DateFormat>;
			},
		},
		{
			id: "customer_name",
			align: "left",
			component: ({ customer }: Row) => {
				return <div>{customer.name}</div>;
			},
		},
		{
			id: "sales_name",
			align: "left",
			component: ({ sales }: Row) => {
				return <div>{sales.name}</div>;
			},
		},
		{
			id: "state_warehouse",
			align: "left",
			component: ({ state_warehouse }: Row) => {
				return <div>{returnGoods.StateWarehouse.getLabel(state_warehouse)}</div>;
			},
		},
		{
			id: "state_accountant",
			align: "left",
			component: ({ state_accountant }: Row) => {
				return <div>{returnGoods.StateAccountant.getLabel(state_accountant)}</div>;
			},
		},
		{
			id: "total_price",
			align: "left",
			component: ({ total_price }: Row) => {
				return <DollarNumber>{total_price}</DollarNumber>;
			},
		},
		{
			id: "note",
			align: "left",
			component: ({ note }: Row) => {
				return <div>{note}</div>;
			},
		},
		{
			id: "order_id",
			align: "left",
			component: ({ order_id }: Row) => {
				return <ClickableColumn onClick={() => onLinkClick(order_id)}>{order_id}</ClickableColumn>;
			},
		},
	];
}
