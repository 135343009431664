export function convertArrayToKeyValues(obj: Record<string, any>, path: string, id: string) {
	return Object.keys(obj).reduce((acc, key) => {
		const prop = obj[key];
		if (Array.isArray(prop)) {
			// @ts-ignore
			acc[key] = prop.reduce((map, item) => {
				if (key !== path) return map;
				map[item[id]] = item;
				return map;
			}, {});
		} else {
			// @ts-ignore
			acc[key] = prop;
		}
		return acc;
	}, {});
}
