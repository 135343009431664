import { useMutation } from "react-query";
import { createAjax } from "../../hooks/useAjax";
import queryKeys from "../../queries/queryKeys";
import { path as HomePath } from "../../routes/HomeRoute";
import Utils from "../../utils";

const prefix_url = "/auth";

export const useLoginMutation = () => {
	const ajax = createAjax();

	return useMutation(
		queryKeys.AUTH_LOGIN,
		({ email, password }: { email: string; password: string }) => {
			return ajax.post(prefix_url, { email, password });
		},
		{
			async onSuccess({ data: { access_token, refresh_token } }) {
				Utils.setAccessToken(access_token);
				Utils.setRefreshToken(refresh_token);
				Utils.locationHref(HomePath);
			},
			onError({ response }) {
				window.alert(response?.data?.msg);
			},
		},
	);
};
