import { gql } from "@apollo/client";
import { useQueryClient } from "react-query";
import { getGraphQLQueryKey, useGraphQLQuery } from "../../moo-graphql-provider";
import { useIdName } from "./useIdName";
import { useParentIdName } from "./useParentIdName";
import { useProps } from "./useProps";

export type Image = {
	id: string;
	src: string;
	thumbnail: string;
	thumbnail_x: number;
	thumbnail_y: number;
	thumbnail_zoom: number;
	thumbnail_rotation: number;
};

export function useListData() {
	const queryClient = useQueryClient();
	const { parentId, resource } = useProps();
	const parentIdName = useParentIdName();
	const idName = useIdName();

	const operationName = `query_${resource}`;
	const query = gql`
      query ${operationName}($where: ${resource}_bool_exp) {
          ${resource}(order_by: [{ priority: asc }], where: $where) {
              ${idName}
              src
              thumbnail
              thumbnail_x
              thumbnail_y
              thumbnail_zoom
              thumbnail_rotation
          }
      }
	`;
	const variables = {
		where: {
			[parentIdName]: {
				_eq: parentId,
			},
			status: {
				_eq: "PRESENT",
			},
		},
	};
	const res = useGraphQLQuery({ operationName, query, variables });

	return {
		...res,
		setQueryData(data: any) {
			queryClient.setQueryData(getGraphQLQueryKey({ operationName, query, variables }), () => data);
		},
	};
}
