import type { HeaderType } from "@yachelee/koden-components/koden-table";
import { Status } from "../../moo-enums";
import { useStore } from "./useStore";

export function useHeaders(): HeaderType[] {
	const { query } = useStore();
	const isArchived = query.status === Status.Archived;

	return [
		{
			id: "number",
			label: "順序",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "voucher_date",
			label: "傳票日期",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "id",
			label: "傳票編號",
			align: "left",
			style: {
				minWidth: 100,
			},
		},
		{
			id: "subject_id_number",
			label: "科目代號",
			align: "left",
			style: {
				minWidth: 100,
			},
		},
		{
			id: "subject_name",
			label: "科目名稱",
			align: "left",
			style: {
				minWidth: 100,
			},
		},
		{
			id: "summary",
			label: "摘要",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "manufacturer",
			label: "廠商",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "type",
			label: "借貸",
			align: "left",
			style: {
				minWidth: 70,
			},
		},
		{
			id: "debit_amount",
			label: "借方金額",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "credit_amount",
			label: "貸方金額",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		isArchived
			? {
					id: "recover",
					label: "恢復",
					align: "center",
			  }
			: {
					id: "delete",
					label: "封存",
					align: "center",
			  },
	];
}
