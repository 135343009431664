import { useNavigate, useParams } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooEmployeeUpdate } from "../components/moo-components/moo-employee-update";
import { path as employeeListPath } from "./EmployeeListRoute";

export const prefixPath = "/employee";
export const path = prefixPath + "/:id";

const UpdateEmployeeRoute = () => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const branch = useBranchInQuery();

	return (
		<MooEmployeeUpdate
			id={id!}
			onSuccess={async () => {
				window.alert("更新成功");
				window.location.reload();
			}}
			onCancel={async () => {
				await navigate(`${employeeListPath}?branch=${branch}`);
			}}
		/>
	);
};

export default UpdateEmployeeRoute;
