import { CellType } from "../../moo-dialog-choose";
import { useCells as useExportGoodsCells } from "../../moo-export-goods-list";
import { useCells as useImportGoodsCells } from "../../moo-import-goods-list";
import { useCells as useOrderGoodsCells } from "../../moo-order-goods-list";
import { useCells as useOrderCells } from "../../moo-order-list";
import { useCells as usePurchaseOrderCells } from "../../moo-purchase-order-list";
import { useCells as useQuotationCells } from "../../moo-quotation-list";
import { useCells as useRequestBuyCells } from "../../moo-request-buy-list";
import { useCells as useReturnGoodsCells } from "../../moo-return-goods-list";
import { LinkType } from "../@types";
import useStore from "../stores/useStore";

export function useCells(): CellType[] {
	const query = useStore();
	const quotationCells = useQuotationCells();
	const orderCells = useOrderCells({ crud: false });
	const importGoodsCells = useImportGoodsCells({ crud: false });
	const exportGoodsCells = useExportGoodsCells();
	const purchaseOrderCells = usePurchaseOrderCells();
	const returnGoodsCells = useReturnGoodsCells();
	const orderGoodsCells = useOrderGoodsCells();
	const requestBuyCells = useRequestBuyCells();

	switch (query.type) {
		case LinkType.Quotation: {
			return quotationCells;
		}
		case LinkType.Order: {
			return orderCells;
		}
		case LinkType.ImportGoods: {
			return importGoodsCells;
		}
		case LinkType.ExportGoods: {
			return exportGoodsCells;
		}
		case LinkType.PurchaseOrder: {
			return purchaseOrderCells;
		}
		case LinkType.ReturnGoods: {
			return returnGoodsCells;
		}
		case LinkType.OrderGoods: {
			return orderGoodsCells;
		}
		case LinkType.RequestBuy: {
			return requestBuyCells;
		}
		default: {
			return [];
		}
	}
}
