import { Row } from "../../moo-dialog-order-choose";
import { useFindOrderById } from "../../moo-order-update";
import { Calculator } from "../../moo-quotation-one";
import { getItemSpec, getItemType, getModelItemId } from "../../moo-shared";
import { One, Type } from "../@types/one";
import { useForm } from "./useForm";

export function useChooseOrder() {
	const { initialValues, values, setValues } = useForm();
	const findOneById = useFindOrderById();

	return async (rows: Row[]) => {
		if (rows.length > 0) {
			const order_id = rows[0].id;
			const order = (await findOneById(order_id)).orders_by_pk;
			const one: One = {
				...values,
				sales_id: order.sales_id,
				sales_name: order.sales.name,
				sales_tel: order.sales.tel,
				note: order.note,
				type: Type.ImportFromOrder,
				order_id,
				items: order.items
					.filter((e) => {
						const item = e.item_spec || e.item_custom;
						if (item === null) return false;

						if (values.manufacturer_type === null || values.manufacturer_id === null) {
							return true;
						} else {
							return (
								item.manufacturer.id === values.manufacturer_id && item.manufacturer.type === values.manufacturer_type
							);
						}
					})
					.map((orderItem, priority) => {
						const item = (orderItem.item_spec || orderItem.item_custom)!;
						const id = item.id;

						return {
							order_good_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: id }),
							id,
							manufacturer_type: item.manufacturer.type,
							manufacturer_id: item.manufacturer.id,
							manufacturer: item.manufacturer.name,
							category: item.category.name,
							type: getItemType(id),
							name: item.name,
							cost: item.cost,
							spec: getItemSpec(item),
							material: item.material,
							color: item.color,
							size: item.size,
							count: orderItem.count,
							price: item.price,
							price_final: orderItem.price_final,
							price_total: Calculator.getItemPriceTotal(orderItem),
							purchase_price: item.purchase_price,
							purchase_price_total: item.purchase_price * orderItem.count,
							note: item.note,
							image: item.image,
							image_cut: item.image_cut,
							priority,
							return_count: 1,
							warehouse_id: null,
						};
					}),
			};
			setValues(one);
		}
	};
}
