import { gql } from "@apollo/client";
import { FormikHelpers } from "formik";
import { Form } from "../moo-employee-one";
import { Status } from "../moo-enums";
import { useGraphQLMutation } from "../moo-graphql-provider";
import { useMe } from "../moo-me-provider";

const mutation = gql`
	mutation ($object: users_insert_input!) {
		insert_users_one(object: $object) {
			id
			employee {
				id
			}
		}
	}
`;

function useGetVariables() {
	const me = useMe()!;
	const nowTime = new Date().toISOString();

	return (form: Form) => {
		return {
			object: {
				password: "PASSWORD",
				is_active: true,
				color: "#000000",
				display_name: form.name,
				role_id: form.role_id,
				create_date: nowTime,

				employee: {
					data: {
						creator_id: me.id,
						status: Status.Present,
						type_id: form.type_id,
						default_branch_id: form.default_branch_id,
						name: form.name,
						nick_name: form.nick_name,
						birthday: form.birthday,
						tel: form.tel,
						email: form.email,
						line: form.line,
						contact_name: form.contact_name,
						contact_tel: form.contact_tel,
						address: form.address,
						onboard_date: form.onboard_date,
						salary_basic: form.salary_basic,
						salary_increased_date: form.salary_increased_date,
						bank_account: form.bank_account,
						bank: form.bank,
						bank_account_number: form.bank_account_number,
						labor_fee: form.labor_fee,
						healthcare_fee: form.healthcare_fee,
						insurance_day: form.insurance_day,
						insurance_unit: form.insurance_unit,
						insurance_fee: form.insurance_fee,
						note: form.note,
						salary_logs: {
							data: {
								creator_id: me.id,
								from_salary_basic: null,
								to_salary_basic: form.salary_basic,
							},
						},
					},
					on_conflict: {
						constraint: "employees_email_key",
						update_columns: [],
					},
				},
			},
		};
	};
}

export function useOnAdd({ onSuccess }: { onSuccess: (id: string) => void }) {
	const graphQLMutation = useGraphQLMutation({ mutation });
	const getVariables = useGetVariables();

	return (form: Form, helpers: FormikHelpers<Form>) => {
		helpers.setSubmitting(true);
		if (window.confirm("是否確定送出?")) {
			graphQLMutation.mutate(getVariables(form), {
				async onSuccess(res) {
					const id = res.insert_users_one.employee.id as string;
					helpers.setSubmitting(false);
					onSuccess(id);
				},
				async onError(e) {
					console.error(e);
					helpers.setSubmitting(false);
					window.alert("發生錯誤!請聯絡系統管理員");
				},
			});
		}
	};
}
