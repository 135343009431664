import produce from "immer";
import { getTotalPrice } from "../getTotalPrice";
import { useForm } from "./useForm";

export function useDeleteItem() {
	const { values, setValues } = useForm();

	return (id: string) => {
		setValues(
			produce(values, (one) => {
				const index = one.items.findIndex((e) => e.id === id);
				if (index !== -1) {
					one.items.splice(index, 1);
				}
				one.total_price = getTotalPrice(one);
			}),
		);
	};
}
