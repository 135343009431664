import { Button } from "@mui/material";
import { useOnSelectFiles } from "./hooks/useOnSelectFiles";
import { useOnUploaded } from "./hooks/useOnUploaded";

export function UploadButton() {
	const onUploaded = useOnUploaded();
	const onSelectFiles = useOnSelectFiles();

	return (
		<Button variant="contained" component="label">
			上傳
			<input
				hidden
				accept="image/*"
				multiple
				type="file"
				onChange={async (e) => {
					//[uploading]
					// disabled button and show the uploading UI
					const fileList = e.target.files;
					if (!fileList) return;

					await onSelectFiles({
						fileList,
						onUploading() {
							// console.log("onUploading", object, progress);
						},
						onUploaded,
					});
				}}
			/>
		</Button>
	);
}
