import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    state
    state_manager
    state_accountant
    purchase_date
    deliver_date
    owner_id
    foreign_manufacturer {
      id
      tels {
        tel
      }
      fax
      email
      country
      currency
      accounts {
        bank_account
        swift_code
        code_iban
      }
    }
    deliver_method
    warehouse_id
    note
    accountant_tax
    accountant_received
    accountant_freight
    accountant_declare_fee
    accountant_pickup_charge
    accountant_miscellaneous_charge
    accountant_note
    mgt_fee
    items {
      id
      purchase_order_item_id
      count
      priority
      item_spec {
        name
        cost
        material
        color
        size
        price
        purchase_price
        note
        image
        image_cut
        manufacturer {
            name
            id
            type
        }
        category {
            name
        }
      }
      item_custom {
        name
        cost
        material
        color
        size
        price
        purchase_price
        note
        image
        image_cut
        manufacturer {
            name
            id
            type
        }
        category {
            name
        }
      }
    }
    files {
      purchase_order_file_id
      value
    }
    manufacturer_files {
      purchase_order_manufacturer_file_id
      value
    }
    other_files {
      purchase_order_other_file_id
      value
    }
    request_buy_ids {
      purchase_order_request_buy_id_id
      value
    }
    voucher_rows {
      voucher_id
    }
`;

export const query = gql`
    query ($id: String!) {
        purchase_orders_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
