import { FormikHelpers } from "formik";
import { Row } from "../moo-dialog-quotation-choose";
import { ItemType } from "../moo-enums";
import { Calculator } from "../moo-quotation-one";
import { useRequestById as useRequestQuotationById } from "../moo-quotation-update";
import { getItemSpec, getItemType, getModelItemId } from "../moo-shared";
import { Item } from "./@types/one";
import { DEFAULT_ONE } from "./DEFAULT_ONE";
import { calReceivable } from "./calReceivable";
import { useForm } from "./hooks/useForm";
import { One as Order } from "./index";

export function useChooseQuotation() {
	const { initialValues } = useForm();
	const requestQuotationById = useRequestQuotationById();

	return async (rows: Row[], setValues: FormikHelpers<Order>["setValues"]) => {
		if (rows.length > 0) {
			const quotation_id = rows[0].id;
			const quotation = (await requestQuotationById(quotation_id)).quotations_by_pk;

			const order: Order = {
				...DEFAULT_ONE(),
				quotation_id,
				create_date: initialValues.create_date,
				type_id: quotation.type.id,
				type_name: quotation.type.name,
				customer_id: quotation.customer.id,
				customer_name: quotation.customer.name,
				sales_id: quotation.sales.id,
				sales_name: quotation.sales.name,
				sales_tel: quotation.sales.tel,
				is_include_tax: quotation.tax > 0,
				payee_id: quotation.payee.id,
				payee_name: quotation.payee.name,
				payee_bank: quotation.payee.bank_name,
				payee_account_number: quotation.payee.account_number,
				deliver_address: quotation.deliver_address || "",
				items: quotation.items.map((quotationItem, index) => {
					const itemType = getItemType(quotationItem.id);
					const item = (itemType === ItemType.Spec ? quotationItem.item_spec : quotationItem.item_custom)!;
					const count = quotationItem.count;
					const order_item_id = getModelItemId({ create_date: initialValues.create_date, item_id: item.id });

					const row: Item = {
						order_item_id,
						id: item.id,
						type: itemType,
						category: item.category.name,
						manufacturer_type: item.manufacturer.type,
						manufacturer_id: item.manufacturer.id,
						manufacturer: item.manufacturer.name,
						name: item.name,
						image: item.image,
						image_cut: item.image_cut,
						material: item.material,
						color: item.color,
						size: item.size,
						purchase_price: item.purchase_price,
						price: item.price,
						cost: item.cost,
						spec: getItemSpec(item),
						count,
						price_final: quotationItem.price_final,
						price_total: Calculator.getItemPriceTotal({ price_final: quotationItem.price_final, count }),
						note: "",
						priority: index,
					};
					return row;
				}),
				total_price: quotation.price_discounted,
				tax_price: quotation.tax,
				received: quotation.deposit,
				note: quotation.note,
				memo: quotation.memo,
			};
			order.receivables = calReceivable(order.total_price, order.tax_price, order.received);
			setValues({ ...DEFAULT_ONE(), ...order });
		}
	};
}
