import { useContext } from "react";
import { useBranch } from "../moo-branch-provider";
import { getDateString } from "../moo-shared";
import { QueryContext } from "./QueryContext";
import { getSearchCondition } from "./getSearchCondition";

export function useConditions() {
	const { value: branch } = useBranch();
	const {
		query: { search, sales_id, customer_id, from_date, to_date },
	} = useContext(QueryContext);

	const conditions: Record<string, any> = {
		branch_id: {
			_eq: branch,
		},
	};

	if (sales_id !== null) {
		conditions.sales_id = { _eq: sales_id };
	}

	if (customer_id !== null) {
		conditions.customer_id = { _eq: customer_id };
	}

	if (from_date !== null && to_date !== null && from_date.getTime() === to_date.getTime()) {
		conditions.order_date = {
			_eq: getDateString(from_date),
		};
	} else {
		if (from_date !== null) {
			conditions.order_date = {
				_gte: getDateString(from_date),
			};
		}

		if (to_date !== null) {
			if (!conditions.order_date) {
				conditions.order_date = {};
			}
			conditions.order_date._lte = getDateString(to_date);
		}
	}
	return {
		...conditions,
		...getSearchCondition(search),
	};
}
