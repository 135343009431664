import { getLocaleString } from "../../moo-shared";
import { One } from "../../moo-voucher-one";
import { Row } from "./Row";

export function mapperInitialValues(record: Row): One {
	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		voucher_date: new Date(record.voucher_date),
		debit_total: record.debit_total,
		credit_total: record.credit_total,
		rows: record.rows.map((e) => {
			return {
				voucher_row_id: e.voucher_row_id,
				credit_amount: e.credit_amount,
				debit_amount: e.debit_amount,
				link_id: e.link_id,
				summary: e.summary,
				type: e.type,
				account_type_id: e.account_type?.id || "",
				subject_id_number: e.account_type?.id_number || "",
				subject_name: e.account_type?.name || "",
				manufacturer_type: e.manufacturer_type || null,
				manufacturer_id: e.manufacturer_id || null,
				order: e.order,
			};
		}),
	};
}
