import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { useItemDialogChoose } from "../../../moo-dialog-item-choose";
import { MooItemRowList } from "../../../moo-item-row-list";
import { FieldType } from "../../../moo-item-spec-and-custom-row";
import { ItemBound } from "../../../moo-types";
import { useForm } from "../../hooks/useForm";
import { useLockStock } from "../../hooks/useLockStock";
import { useUpdate } from "../../hooks/useUpdate";

export function ItemList({ isEdit }: { isEdit: boolean }) {
	const lockStock = useLockStock();
	const { chooseItems, updateItemCount, deleteItem } = useUpdate();
	const { values: one } = useForm();
	const { component: itemDialogComponent, open: openItemDialog } = useItemDialogChoose(
		"choose-item-dialog",
		chooseItems,
	);

	const fields = new Set<FieldType>([
		"id",
		"manufacturer_type",
		"manufacturer",
		"name",
		"material",
		"color",
		"size",
		"count",
	]);
	const disabledFields = new Set<FieldType>();

	if (lockStock) {
		disabledFields.add("count");
	}

	if (!isEdit) {
		fields.add("remove");
	}

	return (
		<>
			{itemDialogComponent}
			<div
				style={{
					marginBottom: "2rem",
					maxWidth: "1024px",
				}}
			>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>瑕疵商品</h1>
					{!lockStock && (
						<KodenIconTextButton
							text="新增商品"
							onClick={() => {
								openItemDialog();
							}}
						/>
					)}
				</header>
				<MooItemRowList
					specCustomFields={fields}
					disabledSpecCustomFields={disabledFields}
					items={one.items as unknown as ItemBound[]}
					onRemove={deleteItem}
					onCountChange={updateItemCount}
				/>
			</div>
		</>
	);
}

export default ItemList;
