import { gql } from "@apollo/client";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { KodenAvatar } from "@yachelee/koden-components/koden-avatar";
import { useState } from "react";
import styled from "styled-components";
import { useGraphQLQuery } from "../../moo-graphql-provider";
import { DateFormat, DollarNumber } from "../../moo-shared";
import { MooTable } from "../../moo-table";

const Title = styled.h3`
	margin: 0;
`;

type SalaryLogType = {
	employee_salary_log_id: string;
	from_salary_basic: number | null;
	to_salary_basic: number | null;
	create_date: string;
	creator: {
		id: string;
		color: string;
		display_name: string;
	};
};

export function SalaryLog({ id }: { id: string }) {
	const graphQLQuery = useGraphQLQuery<{ employee_salary_logs: SalaryLogType[] }>({
		operationName: "",
		query: gql`
			query ($where: employee_salary_logs_bool_exp) {
				employee_salary_logs(where: $where, order_by: { create_date: asc }) {
					employee_salary_log_id
					from_salary_basic
					to_salary_basic
					create_date
					creator {
						id
						color
						display_name
					}
				}
			}
		`,
		variables: {
			where: {
				employee_id: {
					_eq: id,
				},
			},
		},
	});
	const [isExpanded, setIsExpanded] = useState(false);

	if (graphQLQuery.isLoading || graphQLQuery.isError) {
		return null;
	}

	const employee_salary_logs = graphQLQuery.data?.employee_salary_logs || [];

	return (
		<Accordion
			disableGutters
			expanded={isExpanded}
			onChange={(e) => {
				if (e.type !== "click") return;
				setIsExpanded(!isExpanded);
			}}
		>
			<AccordionSummary expandIcon={<KeyboardArrowDown />} aria-controls="panel1a-content" id="panel1a-header">
				<Title>薪資歷史</Title>
			</AccordionSummary>
			<AccordionDetails>
				<MooTable
					headers={[
						{
							id: "creator",
							align: "center",
							label: "帳號",
						},
						{
							id: "create_date",
							align: "center",
							label: "日期",
						},
						{
							id: "from_salary_basic",
							align: "center",
							label: "起始薪水",
						},
						{
							id: "to_salary_basic",
							align: "center",
							label: "調整後薪水",
						},
					]}
					cells={[
						{
							id: "creator",
							align: "center",
							component({ creator }: SalaryLogType) {
								return (
									<div
										title={creator.display_name}
										style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
									>
										<KodenAvatar backgroundColor={creator.color} value={creator.display_name} />
									</div>
								);
							},
						},
						{
							id: "create_date",
							align: "center",
							component({ create_date }: SalaryLogType) {
								return <DateFormat>{create_date}</DateFormat>;
							},
						},
						{
							id: "from_salary_basic",
							align: "center",
							component({ from_salary_basic }: SalaryLogType) {
								return from_salary_basic === null ? "沒資料" : <DollarNumber>{from_salary_basic}</DollarNumber>;
							},
						},
						{
							id: "to_salary_basic",
							align: "center",
							component({ to_salary_basic }: SalaryLogType) {
								return to_salary_basic === null ? "沒資料" : <DollarNumber>{to_salary_basic}</DollarNumber>;
							},
						},
					]}
					results={employee_salary_logs.map((e) => ({ ...e, id: e.employee_salary_log_id }))}
				/>
			</AccordionDetails>
		</Accordion>
	);
}
