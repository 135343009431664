import produce from "immer";
import { useIds } from "./useIds";

export function useOnCheck() {
	const { ids, setIds } = useIds();

	return (id: string) => {
		const checked = !ids.includes(id);

		if (checked) {
			setIds(
				produce(ids, (draft) => {
					draft.push(id);
					return draft;
				}),
			);
		} else {
			setIds(
				produce(ids, (draft) => {
					draft.splice(draft.indexOf(id), 1);
					return draft;
				}),
			);
		}
	};
}
