import { Outlet } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import useIsLogin from "../hooks/useIsLogin";
import LoginRoute from "./LoginRoute";

export const path = "/";
export const title = "主頁";

const HomeRoute = () => {
	const isLogin = useIsLogin();

	if (!isLogin) {
		return <LoginRoute />;
	} else {
		return (
			<MainLayout>
				<Outlet />
			</MainLayout>
		);
	}
};

export default HomeRoute;
