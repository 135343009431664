import { object, SchemaOf, string } from "yup";
import { manufacturer } from "../moo-enums";
import {
	enumRequired,
	numberOptional,
	numberRequired,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../moo-schemas";
import { useIsSubCategoryIdValid } from "../moo-shared";
import { One } from "./@types/one";

export function useValidationSchema(): SchemaOf<One> {
	const issub_category_idValid = useIsSubCategoryIdValid();
	// @ts-ignore
	return object().shape({
		create_date: stringRequired("create_date"),
		update_date: stringRequired("update_date"),
		status: statusSchema,
		manufacturer_type: enumRequired(manufacturer.Value.Value),
		manufacturer_id: stringRequired("請選擇廠商"),
		category_id: stringRequired("請選擇商品主類"),
		sub_category_id: string()
			.nullable(true)
			.test("sub_category_id-test", "請選擇商品次類", (sub_category_id, { parent: { category_id } }) => {
				// @ts-ignore
				return issub_category_idValid(category_id, sub_category_id);
			}),
		buy_year: numberRequired("buy_year"),
		name: stringRequired("name", "請輸入商品名稱"),
		material: stringOptional,
		color: stringOptional,
		size: stringOptional,
		owner_id: stringRequired("owner_id", "請選擇負責人"),
		sell_deadline: stringOptional,
		note: stringOptional,
		image: stringOptional,
		image_cut: stringOptional,
		image_x: numberOptional,
		image_y: numberOptional,
		image_zoom: numberOptional,
		image_rotation: numberOptional,
		purchase_price: numberOptional,
		cost: numberOptional,
		price: numberOptional,
		original_code: stringOptional,
		country: stringOptional,
	});
}
