export enum Value {
	WAIT_TO_PAY = "WAIT_TO_PAY",
	PAID = "PAID",
	UNPAID_LET_GO = "UNPAID_LET_GO",
	SPECIAL = "SPECIAL",
}

export function getValues(): Value[] {
	return [Value.WAIT_TO_PAY, Value.PAID, Value.UNPAID_LET_GO, Value.SPECIAL];
}

export enum Label {
	WAIT_TO_PAY = "待付定",
	PAID = "已付定",
	UNPAID_LET_GO = "未付訂放行",
	SPECIAL = "特殊情形",
}

export function getLabel(value: Value): Label {
	switch (value) {
		case Value.WAIT_TO_PAY:
			return Label.WAIT_TO_PAY;
		case Value.PAID:
			return Label.PAID;
		case Value.UNPAID_LET_GO:
			return Label.UNPAID_LET_GO;
		case Value.SPECIAL:
			return Label.SPECIAL;
	}
	return Label.WAIT_TO_PAY;
}
