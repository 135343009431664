import { ItemType, manufacturer } from "../../moo-enums";
import { Calculator, Item as OneItem, One } from "../../moo-quotation-one";
import { GraphQLFindOneResult } from "./GraphQLFindOneResult";
import { Item } from "./Item";

export function mapperItem(quotationItems: GraphQLFindOneResult["quotations_by_pk"]["items"]): One["items"] {
	const oneItems: OneItem[] = [];

	quotationItems.forEach((quotationItem, priority) => {
		const id = quotationItem.id;
		const type = id.charAt(0) === "S" ? ItemType.Spec : ItemType.Custom;
		const item: Item = (quotationItem.item_spec || quotationItem.item_custom)!;
		const price_final = quotationItem.price_final;
		const count = quotationItem.count;

		const oneItem: OneItem = {
			quotation_item_id: quotationItem.quotation_item_id,
			id: quotationItem.id,
			type,
			category: item.category.name,
			manufacturer_type: item.manufacturer.type as manufacturer.Value.Value,
			manufacturer_id: item.manufacturer.id,
			manufacturer: item.manufacturer.name,
			name: item.name,
			image: item.image,
			image_cut: item.image_cut,
			material: item.material,
			color: item.color,
			size: item.size,
			purchase_price: item.purchase_price,
			price: item.price,
			cost: item.cost,
			spec: `${item.material} ${item.color} ${item.size}`,
			count,
			price_final,
			price_total: Calculator.getItemPriceTotal({ price_final, count }),
			note: quotationItem.note,
			priority,
		};
		oneItems.push(oneItem);
	});

	return oneItems;
}
