import { Calculator } from "../../moo-quotation-one";
import { ReturnOrderItem } from "../../moo-return-order-one";
import { ReturnOrder } from "./ReturnOrder";

export function mapperItems(items: ReturnOrder["items"]): ReturnOrderItem[] {
	return items.map((e) => {
		const item = e.item_spec || e.item_custom;
		return {
			return_order_item_id: e.return_order_item_id,
			id: e.id,
			name: item.name,
			spec: item.spec,
			material: item.material,
			color: item.color,
			size: item.size,
			manufacturer_type: item.manufacturer_type,
			manufacturer: item.manufacturer.name,
			count: e.count,
			price_final: e.price_final,
			price_total: Calculator.getItemPriceTotal({ price_final: e.price_final, count: e.count }),
			purchase_price: item.purchase_price,
			image: item.image,
			image_cut: item.image_cut,
			price: item.price,
		};
	});
}
