export enum Value {
	NO_NEED_TO_APPROVE = "NO_NEED_TO_APPROVE",
	UNAPPROVED = "UNAPPROVED",
	APPROVED = "APPROVED",
	RETURN = "RETURN",
}

export function getValues(): Value[] {
	return [Value.NO_NEED_TO_APPROVE, Value.UNAPPROVED, Value.APPROVED, Value.RETURN];
}

export enum Label {
	NO_NEED_TO_APPROVE = "毋需簽核",
	UNAPPROVED = "未批准",
	APPROVED = "批准",
	RETURN = "退回",
}

export function getLabel(value: Value): Label {
	switch (value) {
		case Value.NO_NEED_TO_APPROVE:
			return Label.NO_NEED_TO_APPROVE;
		case Value.UNAPPROVED:
			return Label.UNAPPROVED;
		case Value.APPROVED:
			return Label.APPROVED;
		case Value.RETURN:
			return Label.RETURN;
	}
	return Label.NO_NEED_TO_APPROVE;
}
