export const graphQLColumns = `
	{
			id
			export_date
			customer {
				name
			}
			driver {
				name
			}
			sales {
				name
			}
			address
			order_id
			items{
				id
				stocks {
					warehouse_id
				}
			}
	}
	warehouses{
		id
		name
	}
`;
