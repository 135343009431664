import produce from "immer";
import { useFindResourceListByIds } from "../moo-shared";
import { DEFAULT_ONE, One } from "./";

export type Row = One & {
	manufacturer: {
		name: string;
	};
	category: {
		name: string;
	};
	sub_category: {
		name: string;
	};
};

export function useFindItemSpecsByIds() {
	return useFindResourceListByIds<Row>(
		"item_specs",
		produce(DEFAULT_ONE() as Row, (draft) => {
			draft.manufacturer = {
				name: "",
			};
			draft.category = {
				name: "",
			};
			draft.sub_category = {
				name: "",
			};
		}),
		{},
	);
}
