import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { KodenTimeField } from "@yachelee/koden-components/koden-time-field";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { MooCustomerSelectField } from "../../moo-customer-select-field";
import { useItemDialogChoose } from "../../moo-dialog-item-choose";
import { useOrderDialogChoose } from "../../moo-dialog-order-choose";
import { MooEmployeeSelectField } from "../../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../../moo-enum-typeable-select-field";
import { exportGoods, order } from "../../moo-enums";
import { MooLinkButton } from "../../moo-link-button";
import { MooManufacturerLogisticDriverField } from "../../moo-manufacturer-logistic-driver-field";
import { MooVoucherLinkList } from "../../moo-voucher-link-list";
import FieldContainer from "../components/FieldContainer";
import ItemList from "../components/ItemList/ItemList";
import { useForm } from "../hooks/useForm";
import { useGetError } from "../hooks/useGetError";
import { useUpdate } from "../hooks/useUpdate";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const FileUpload = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 0.3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.6rem;
	align-items: center;
	padding: 14px;
	min-height: 56px;
	text-align: center;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto 1fr;
		grid-column-end: span 2;
		text-align: left;
		padding: 0 14px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const OrderItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		justify-content: flex-start;
	}
`;

export function BasicBlock() {
	const {
		updateDriverId,
		updateSalesId,
		updateCustomerId,
		chooseOrder,
		updateDriverLogisticsManufacturerId,
		updateTypeId,
		chooseItems,
	} = useUpdate();
	const { values, setFieldValue } = useForm();
	const getError = useGetError();
	const { component, open } = useOrderDialogChoose(
		"choose-order-dialog",
		chooseOrder,
		false,
		[order.State.Value.Shipped],
		order.StateManager.getValues(),
		order.StateAccountant.getValues(),
		order.StateWarehouse.getValues(),
	);
	const { component: itemDialogComponent, open: openItemDialog } = useItemDialogChoose(
		"choose-item-dialog",
		chooseItems,
	);

	const driverLogisticsManufacturerFilled = values.driver_logistics_manufacturer_id !== null;
	const importedOrder = values.order_id !== null;
	const salesFilled = values.sales_id !== null;

	return (
		<>
			{component}
			{itemDialogComponent}
			<FieldContainer>
				<KodenDateField
					id="export_date"
					label="出貨日期"
					error={getError("export_date")}
					value={new Date(values.export_date)}
					onChange={(value) => {
						setFieldValue(
							"export_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						);
					}}
				/>
				<KodenTimeField
					id="export_time"
					label="出貨時間"
					error={getError("export_date")}
					value={new Date(values.export_date)}
					onChange={(value) => {
						setFieldValue("export_date", value);
					}}
				/>
				<FileUpload>
					<KodenButton onClick={() => open()}>{importedOrder ? "重匯訂購單" : "匯入訂購單"}</KodenButton>
					<OrderItem>
						<MooLinkButton id={values.order_id} name="訂購單" />
					</OrderItem>
				</FileUpload>
			</FieldContainer>
			<FieldContainer>
				<MooEnumTypeableSelectField
					id="type_id"
					resource="quotation_types"
					label="類型"
					value={values.type_id}
					error={getError("type_id")}
					onChange={async (id) => {
						if (id) {
							await updateTypeId(id);
						}
					}}
				/>
				<MooCustomerSelectField
					id="customer_id"
					label="客戶"
					variant="outlined"
					value={values.customer_id}
					error={getError("customer_id")}
					onChange={async (id) => {
						if (id) {
							await updateCustomerId(id);
						}
					}}
				/>
				<MooEmployeeSelectField
					id="sales_id"
					label="業務"
					value={values.sales_id}
					error={getError("sales_id")}
					onChange={updateSalesId}
				/>
				{salesFilled && (
					<ProxyKodenTextField
						id="sales_tel"
						label="業務電話"
						outlined
						margin="none"
						value={values.sales_tel}
						readOnly
						disabled
						error=""
					/>
				)}
			</FieldContainer>
			<FieldContainer>
				<TextFieldEnd
					id="address"
					label="地址"
					outlined
					margin="none"
					value={values.address}
					error=""
					onChange={(value) => setFieldValue("address", value)}
				/>
			</FieldContainer>
			<FieldContainer>
				<TextFieldEnd
					id="order_description"
					label="訂購單簡述"
					outlined
					margin="none"
					value={values.order_description}
					error=""
					onChange={(value) => {
						setFieldValue("order_description", value);
					}}
				/>
				<TextFieldEnd
					id="order_note"
					label="訂購單備註"
					outlined
					margin="none"
					value={values.order_note}
					error=""
					onChange={(value) => {
						setFieldValue("order_note", value);
					}}
				/>
			</FieldContainer>
			<FieldContainer>
				<MooEnumTypeableSelectField
					id="driver_logistics_manufacturer_id"
					resource="manufacturer_logistics"
					label="司機物流廠商"
					value={values.driver_logistics_manufacturer_id}
					error={getError("driver_logistics_manufacturer_id")}
					onChange={updateDriverLogisticsManufacturerId}
				/>
				{driverLogisticsManufacturerFilled && values.driver_logistics_manufacturer_id && (
					<>
						<MooManufacturerLogisticDriverField
							id="driver_id"
							manufacturer_logistic_id={values.driver_logistics_manufacturer_id}
							label="司機"
							value={values.driver_id}
							error={getError("driver_id")}
							onChange={async (id) => {
								if (id) {
									await updateDriverId(id);
								}
							}}
						/>
						<ProxyKodenTextField
							id="driver_tel"
							label="電話"
							outlined
							margin="none"
							value={values.driver_tel}
							error=""
							disabled
							readOnly
							onChange={() => {}}
						/>
					</>
				)}
				<ProxyKodenTextField
					id="export_goods_note"
					label="出貨備註"
					outlined
					margin="none"
					value={values.export_goods_note}
					error=""
					onChange={(value) => {
						setFieldValue("export_goods_note", value);
					}}
				/>
				<KodenNumberField
					id="export_goods_shipping_fee"
					label="運費"
					outlined
					hasDollar
					margin="none"
					value={values.shipping_fee}
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					step={1}
					error=""
					onChange={(value) => {
						setFieldValue("shipping_fee", value);
					}}
				/>
			</FieldContainer>
			<MooVoucherLinkList values={values.voucher_rows.map((e) => e.voucher_id)} />
			<div
				style={{
					marginBottom: "2rem",
					maxWidth: "1024px",
				}}
			>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>出貨商品</h1>
					<KodenIconTextButton
						disabled={values.type === exportGoods.Type.ImportFromOrder}
						text="新增商品"
						onClick={() => {
							openItemDialog();
						}}
					/>
				</header>
				<ItemList />
			</div>
		</>
	);
}
