export enum Status {
	Present = "PRESENT",
	Archived = "ARCHIVED",
	Deleted = "DELETED",
}

export enum ItemType {
	Spec = "spec",
	Custom = "custom",
	Bundle = "bundle",
}

export function getItemTypeLabel(itemType: ItemType): string {
	switch (itemType) {
		case ItemType.Spec:
			return "規格品";
		case ItemType.Custom:
			return "訂製品";
		case ItemType.Bundle:
			return "販售組合";
	}
}

export * as order from "./order";
export * as quotation from "./quotation";
export * as manufacturer from "./manufacturer";
export * as returnGoods from "./return-goods";
export * as returnOrder from "./return-order";
export * as orderGoods from "./order-goods";
export * as purchaseOrder from "./purchase-order";
export * as deliverMethod from "./deliver-method";
export * as importGoods from "./import-goods";
export * as exportGoods from "./export-goods";
export * as stockTransfer from "./stock-transfer";
export * as defectiveProduct from "./defective-product";
export * as svc from "./svc";
export * as allowance from "./allowance";
export * as requestBuy from "./request-buy";
