import { createTheme, ThemeProvider } from "@mui/material/styles";
import { KodenButton } from "@yachelee/koden-components/koden-button";
import { Formik } from "formik";
import styled from "styled-components";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooEmployeeOneProps } from "./@types/mooEmployeeOneProps";
import { Basic } from "./components/Basic";
import { Salary } from "./components/Salary";
import { SalaryLog } from "./components/SalaryLog";
import { useForm } from "./hooks/useForm";
import { schema } from "./schemas/schema";

const theme = createTheme({
	components: {
		MuiAccordion: {
			styleOverrides: {
				root: {
					border: "none",
					borderRadius: "5px",
					boxShadow: "none",
					overflow: "hidden",
					"& > div:first-of-type": {
						padding: "0 1.5rem",
						display: "flex",
					},
					"&::before": {
						backgroundColor: "transparent",
					},
				},
			},
		},
		MuiCollapse: {
			styleOverrides: {
				root: {
					borderTop: "1px solid #F0F0F0",
				},
			},
		},
	},
});

const Container = styled.div`
	display: grid;
	grid-auto-rows: auto;
	grid-gap: 1.5rem;
	width: 100%;
	overflow: auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

function Form(props: MooEmployeeOneProps) {
	const { isSubmitting, isValid, handleSubmit } = useForm();
	const permissions = usePermissions();
	const isSubmitDisabled = isSubmitting || !isValid;

	return (
		<form onSubmit={handleSubmit}>
			<Container>
				<ThemeProvider theme={theme}>
					<Basic {...props} />
					{permissions.includes(Permission.EmployeeSalaryRead) && (
						<>
							<Salary />
							{props.isEdit && props.id && <SalaryLog id={props.id} />}
						</>
					)}
				</ThemeProvider>
			</Container>
			{!props.isOkDisabled && (
				<ButtonContainer>
					<KodenButton
						style={{ marginRight: "1rem" }}
						disabled={props.isCancelDisabled}
						onClick={async () => {
							if (window.confirm("確定取消並且回到上一頁？")) {
								props.onCancel();
							}
						}}
					>
						取消
					</KodenButton>
					<KodenButton type="submit" disabled={isSubmitDisabled}>
						{props.okText}
					</KodenButton>
				</ButtonContainer>
			)}
		</form>
	);
}

export function MooEmployeeOne(props: MooEmployeeOneProps) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.EmployeeRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={props.initialValues} onSubmit={props.onSubmit}>
			<Form {...props} />
		</Formik>
	);
}
