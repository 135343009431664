import styled from "@emotion/styled";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useGetGcsUrl } from "@yachelee/koden-components/koden-gcs-provider";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { defaultLogoVertical } from "../moo-config";
import { MooEnumTypeableSelectField } from "../moo-enum-typeable-select-field";
import { getItemTypeLabel, manufacturer as manufacturerTypeEnums } from "../moo-enums";
import { ItemSpecOrCustomBound } from "../moo-types";

const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	position: relative;
	padding: 1rem 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 1rem;
	grid-template-areas:
		"image image image"
		". . ."
		". . ."
		". . ."
		". . ."
		"note note note";
	border-bottom: 1px solid #ebebeb;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: 170px repeat(4, 1fr);
		grid-template-areas:
			"image . . . ."
			"image . . . ."
			"image . . . ."
			"image note note note note";
	}
`;

const Index = styled.div`
	position: absolute;
	left: 0.5rem;
	top: 1.1rem;
	color: white;
`;

const ImageContainer = styled.a`
	grid-area: image;
	height: 175px;
	overflow: hidden;
	width: 100%;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		height: 178px;
	}
`;

const DeleteBtn = styled(CloseSharpIcon)`
	position: absolute;
	top: 1rem;
	right: 0.2rem;
	cursor: pointer;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		top: 0.7rem;
		right: 0;
	}
`;

export type FieldType =
	| "id"
	| "name"
	| "purchase_price_total"
	| "manufacturer"
	| "manufacturer_type"
	| "type"
	| "category"
	| "color"
	| "size"
	| "spec"
	| "count"
	| "price"
	| "price_final"
	| "purchase_price"
	| "cost"
	| "price_total"
	| "note"
	| "return_count"
	| "warehouse_id"
	| "material"
	| "transfer_count"
	| "remove";

export const allFields = new Set<FieldType>([
	"id",
	"name",
	"manufacturer",
	"manufacturer_type",
	"type",
	"category",
	"color",
	"size",
	"spec",
	"count",
	"price",
	"price_final",
	"purchase_price",
	"cost",
	"price_total",
	"note",
	"return_count",
	"warehouse_id",
	"material",
	"transfer_count",
	"purchase_price_total",
	"remove",
]);

export type MooItemSpecAndCustomRowProps = ItemSpecOrCustomBound & {
	error?: Partial<Record<FieldType, string>>;
	index: number;
	return_count?: number;
	warehouse_id?: string;
	onCountChange?: (id: ItemSpecOrCustomBound["id"], count: number) => void;
	onNoteChange?: (id: ItemSpecOrCustomBound["id"], note: ItemSpecOrCustomBound["note"]) => void;
	onReturnCountChange?: (id: ItemSpecOrCustomBound["id"], returnCount: number) => void;
	onWarehouseIdChange?: (id: ItemSpecOrCustomBound["id"], warehouse_id: string) => void;
	onTransferCountChange?: (id: ItemSpecOrCustomBound["id"], value: ItemSpecOrCustomBound["transfer_count"]) => void;
	onPriceFinalChange?: (id: ItemSpecOrCustomBound["id"], value: ItemSpecOrCustomBound["price_final"]) => void;
	deleteItem?: (id: ItemSpecOrCustomBound["id"]) => void;
	onRemove?: (id: ItemSpecOrCustomBound["id"]) => void;
	fields?: Set<FieldType>;
	disabledFields?: Set<FieldType>;
};

export function MooItemSpecAndCustomRow({
	error = {},
	index,
	id,
	cost,
	note,
	price_total,
	price,
	price_final,
	purchase_price,
	purchase_price_total,
	name,
	material,
	color,
	size,
	type,
	category,
	spec,
	manufacturer_type,
	manufacturer,
	count,
	image,
	image_cut,
	onCountChange,
	onNoteChange,
	return_count,
	warehouse_id,
	transfer_count,
	onReturnCountChange,
	onWarehouseIdChange,
	onRemove,
	onTransferCountChange,
	onPriceFinalChange,
	fields = new Set(),
	disabledFields = new Set(),
}: MooItemSpecAndCustomRowProps) {
	const getGcsUrl = useGetGcsUrl();

	return (
		<Container>
			<Index>{index + 1}</Index>
			{fields.has("id") && (
				<ProxyKodenTextField id={`id_${id}`} label="編號" margin="none" defaultValue={id} error="" readOnly disabled />
			)}
			{fields.has("name") && (
				<ProxyKodenTextField
					id={`name_${id}`}
					label="品名"
					margin="none"
					defaultValue={name}
					error=""
					readOnly
					disabled
				/>
			)}
			{fields.has("material") && (
				<ProxyKodenTextField
					id={`material_${id}`}
					label="材質"
					margin="none"
					defaultValue={material}
					error=""
					readOnly
					disabled
				/>
			)}
			{fields.has("color") && (
				<ProxyKodenTextField
					id={`color_${id}`}
					label="顏色"
					margin="none"
					defaultValue={color}
					error=""
					readOnly
					disabled
				/>
			)}
			{fields.has("size") && (
				<ProxyKodenTextField
					id={`size_${id}`}
					label="尺寸"
					margin="none"
					defaultValue={size}
					error=""
					readOnly
					disabled
				/>
			)}
			{fields.has("type") && (
				<ProxyKodenTextField
					id={`type_${id}`}
					label="類型"
					margin="none"
					defaultValue={getItemTypeLabel(type)}
					error=""
					readOnly
					disabled
				/>
			)}
			{fields.has("manufacturer_type") && (
				<ProxyKodenTextField
					id={`manufacturerType_${id}`}
					label="來源"
					margin="none"
					defaultValue={manufacturerTypeEnums.Label.get(manufacturer_type)}
					error=""
					readOnly
					disabled
				/>
			)}

			{fields.has("manufacturer") && (
				<ProxyKodenTextField
					id={`manufacturer_${id}`}
					label="廠商"
					margin="none"
					defaultValue={manufacturer}
					error=""
					readOnly
					disabled
				/>
			)}
			{fields.has("category") && (
				<ProxyKodenTextField
					id={`category_${id}`}
					label="類別"
					margin="none"
					defaultValue={category}
					error=""
					readOnly
					disabled
				/>
			)}
			{fields.has("spec") && (
				<ProxyKodenTextField
					id={`spec_${id}`}
					label="規格"
					margin="none"
					defaultValue={spec}
					error=""
					readOnly
					disabled
				/>
			)}
			{fields.has("count") && (
				<KodenNumberField
					id={`count_${id}`}
					label="數量"
					margin="none"
					value={count}
					min={0}
					max={1000000}
					readOnly={disabledFields.has("count")}
					disabled={disabledFields.has("count")}
					step={1}
					error=""
					onChange={(count) => {
						if (onCountChange) {
							onCountChange(id, count);
						}
					}}
				/>
			)}
			{fields.has("purchase_price") && (
				<KodenNumberField
					id={`purchase_price_${id}`}
					label="進貨成本"
					margin="none"
					hasDollar
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					step={1}
					value={purchase_price}
					error=""
					readOnly
					disabled
					onChange={() => {}}
				/>
			)}
			{fields.has("purchase_price_total") && (
				<KodenNumberField
					id={`purchase_price_total_${id}`}
					label="總價"
					margin="none"
					value={purchase_price_total}
					min={0}
					hasDollar
					max={Number.MAX_SAFE_INTEGER}
					step={1}
					error=""
					readOnly
					disabled
					onChange={() => {}}
				/>
			)}
			{fields.has("cost") && (
				<KodenNumberField
					id={`cost_${id}`}
					label="總成本"
					margin="none"
					hasDollar
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					step={1}
					value={cost}
					error=""
					readOnly
					disabled
					onChange={() => {}}
				/>
			)}
			{fields.has("price") && (
				<KodenNumberField
					id={`price_${id}`}
					label="建議售價"
					margin="none"
					hasDollar
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					step={1}
					value={price}
					error=""
					readOnly
					disabled
					onChange={() => {}}
				/>
			)}
			{fields.has("price_final") && (
				<KodenNumberField
					id={`price_final_${id}`}
					label="售價"
					margin="none"
					hasDollar
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					step={1}
					value={price_final}
					error=""
					readOnly={disabledFields.has("price_final")}
					disabled={disabledFields.has("price_final")}
					onChange={(price_final) => {
						if (onPriceFinalChange) {
							onPriceFinalChange(id, price_final);
						}
					}}
				/>
			)}
			{fields.has("price_total") && (
				<KodenNumberField
					id={`price_total_${id}`}
					label="總價"
					margin="none"
					hasDollar
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					step={1}
					value={price_total}
					error=""
					readOnly
					disabled
					onChange={() => {}}
				/>
			)}
			{fields.has("note") && (
				<ProxyKodenTextField
					id={`note_${id}`}
					style={{ gridArea: "note" }}
					label="備註"
					margin="none"
					value={note}
					error=""
					onChange={(note) => {
						if (onNoteChange) {
							onNoteChange(id, note);
						}
					}}
				/>
			)}
			<ImageContainer href={image === null ? defaultLogoVertical : getGcsUrl(image)} target="_blank" rel="noreferrer">
				<img
					alt={name}
					src={image_cut !== null ? getGcsUrl(image_cut) : defaultLogoVertical}
					style={{
						width: "100%",
						height: "100%",
						objectFit: "cover",
					}}
				/>
			</ImageContainer>
			{fields.has("return_count") && (
				<KodenNumberField
					id={`return_count_${id}`}
					label="退貨數量"
					readOnly={disabledFields.has("return_count")}
					disabled={disabledFields.has("return_count")}
					margin="none"
					value={return_count || null}
					min={1}
					max={count}
					step={1}
					error=""
					onChange={(returnCount) => {
						if (onReturnCountChange) {
							onReturnCountChange(id, returnCount);
						}
					}}
				/>
			)}
			{fields.has("warehouse_id") && (
				<MooEnumTypeableSelectField
					id="warehouses"
					resource="warehouses"
					label="收貨倉庫"
					value={warehouse_id || null}
					disabled={disabledFields.has("warehouse_id")}
					error={error.warehouse_id || ""}
					onChange={(value) => {
						if (onWarehouseIdChange && value) {
							onWarehouseIdChange(id, value);
						}
					}}
				/>
			)}
			{fields.has("transfer_count") && (
				<KodenNumberField
					id={`transferCount_${id}`}
					label="調撥數量"
					margin="none"
					value={transfer_count || null}
					readOnly={disabledFields.has("transfer_count")}
					disabled={disabledFields.has("transfer_count")}
					min={1}
					max={Number.MAX_SAFE_INTEGER}
					step={1}
					error=""
					onChange={(value) => {
						if (onTransferCountChange) {
							onTransferCountChange(id, value);
						}
					}}
				/>
			)}
			{fields.has("remove") && (
				<DeleteBtn
					fontSize="small"
					color="disabled"
					onClick={() => {
						if (onRemove && window.confirm("確定要刪除此商品?")) {
							onRemove(id);
						}
					}}
				>
					x
				</DeleteBtn>
			)}
		</Container>
	);
}

export default MooItemSpecAndCustomRow;
