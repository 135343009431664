import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { ReactNode, useState } from "react";
import { Branch } from "../moo-branch-provider";
import { PermissionTree } from "./PermissionTree";

interface TabPanelProps {
	children?: ReactNode;
	value: Branch;
	hidden: boolean;
}

function TabPanel({ children, value, hidden }: TabPanelProps) {
	return (
		<div id={`tab-panel-${value}`} hidden={hidden}>
			{!hidden && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export function RoleTabs() {
	const [value, setValue] = useState(Branch.ADMIN);

	return (
		<>
			<Tabs
				value={value}
				onChange={(_, value) => setValue(value as Branch)}
				textColor="secondary"
				indicatorColor="secondary"
				aria-label=""
			>
				<Tab value={Branch.ADMIN} label="系統" />
				<Tab value={Branch.TPE} label="台北" />
				<Tab value={Branch.KAO} label="高雄" />
			</Tabs>
			<TabPanel value={Branch.ADMIN} hidden={value !== Branch.ADMIN}>
				<PermissionTree branch_id={Branch.ADMIN} />
			</TabPanel>
			<TabPanel value={Branch.TPE} hidden={value !== Branch.TPE}>
				<PermissionTree branch_id={Branch.TPE} />
			</TabPanel>
			<TabPanel value={Branch.KAO} hidden={value !== Branch.KAO}>
				<PermissionTree branch_id={Branch.KAO} />
			</TabPanel>
		</>
	);
}
