import { MooRequestBuyOne, One } from "../moo-request-buy-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { RequestBuyItemInfosTable } from "./updateOne/RequestBuyItemInfosTable";
import { RequestBuyItemsTable } from "./updateOne/RequestBuyItemsTable";
import { RequestBuyOrderIdsTable } from "./updateOne/RequestBuyOrderIdsTable";
import { RequestBuyTable } from "./updateOne/RequestBuyTable";

export type MooRequestBuyUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooRequestBuyUpdate({ id, onCancel }: MooRequestBuyUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="request-buy-one"
			query={query}
			tableName="request_buys"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooRequestBuyOne}
			tables={[RequestBuyOrderIdsTable, RequestBuyItemInfosTable, RequestBuyItemsTable, RequestBuyTable]}
		/>
	);
}
