import { MooGqlTable } from "../../moo-gql";
import { Quotation } from "../findOne/Quotation";
import { queryField } from "../findOne/query";

export class QuotationTable extends MooGqlTable<Quotation> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "quotations";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "state":
			case "state_manager":
			case "state_accountant":
			case "tax":
			case "type_id":
			case "quotation_date":
			case "customer_id":
			case "sales_id":
			case "assistant_id":
			case "deliver_date":
			case "is_include_tax":
			case "payee_id":
			case "deliver_address":
			case "price_discounted":
			case "note":
			case "deposit":
			case "memo":
				return true;
			default:
				return false;
		}
	}
}
