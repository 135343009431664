import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as PayeeCrudRoutePath } from "../../../routes/PayeeCrudRoute";
import { path as QuotationTypesRoutePath } from "../../../routes/QuotationTypesRoute";
import { path as OrderListRoutePath } from "../../../routes/order/ListRoute";
import { path as OrderNewRoutePath } from "../../../routes/order/NewRoute";
import { path as OrderUpdateRoutePath } from "../../../routes/order/UpdateRoute";
import { path as QuotationListRoutePath } from "../../../routes/quotation/ListRoute";
import { path as QuotationNewRoutePath } from "../../../routes/quotation/NewRoute";
import { path as QuotationUpdateRoutePath } from "../../../routes/quotation/UpdateRoute";
import { path as ReturnGoodsListRoutePath } from "../../../routes/return_goods/ListRoute";
import { path as ReturnGoodsNewRoutePath } from "../../../routes/return_goods/NewRoute";
import { path as ReturnGoodsUpdateRoutePath } from "../../../routes/return_goods/UpdateRoute";
import { path as ReturnOrderListRoutePath } from "../../../routes/return_order/ListRoute";
import { path as ReturnOrderNewRoutePath } from "../../../routes/return_order/NewRoute";
import { path as ReturnOrderUpdateRoutePath } from "../../../routes/return_order/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useDans(): ListType[] {
	const permissions = usePermissions();
	const historyPushWithBranch = useHistoryPushWithBranch();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();

	const children: any[] = [];

	if (permissions.includes(Permission.PayeeWrite)) {
		children.push({
			id: PayeeCrudRoutePath,
			txt: getPageTree()[PayeeCrudRoutePath].useText(),
			icon: "",
			isSelected: isSelected([PayeeCrudRoutePath]),
			async onClick() {
				await historyPushWithBranch(PayeeCrudRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.QuotationListRead)) {
		children.push({
			id: QuotationListRoutePath,
			txt: getPageTree()[QuotationListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([QuotationListRoutePath, QuotationNewRoutePath, QuotationUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(QuotationListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.OrderListRead)) {
		children.push({
			id: OrderListRoutePath,
			txt: getPageTree()[OrderListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([OrderListRoutePath, OrderNewRoutePath, OrderUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(OrderListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.ReturnOrderListRead)) {
		children.push({
			id: ReturnOrderListRoutePath,
			txt: getPageTree()[ReturnOrderListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([ReturnOrderListRoutePath, ReturnOrderNewRoutePath, ReturnOrderUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(ReturnOrderListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.ReturnGoodsListRead)) {
		children.push({
			id: ReturnGoodsListRoutePath,
			txt: getPageTree()[ReturnGoodsListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([ReturnGoodsListRoutePath, ReturnGoodsNewRoutePath, ReturnGoodsUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(ReturnGoodsListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.QuotationTypeWrite)) {
		children.push({
			id: QuotationTypesRoutePath,
			txt: getPageTree()[QuotationTypesRoutePath].useText(),
			icon: "",
			isSelected: isSelected([QuotationTypesRoutePath]),
			async onClick() {
				await historyPushWithBranch(QuotationTypesRoutePath);
			},
		});
	}

	if (children.length === 0) {
		return [];
	}

	return [
		{
			id: "dan",
			txt: "訂單",
			icon: Utils.getAbsoluteStaticUrl("icons/order.svg"),
			isLine: false,
			async onClick() {
				navigationStore.togglePaper();
			},
			open: true,
			openChildren: navigationStore.paper,
			children,
		},
	];
}
