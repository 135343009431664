import styled from "@emotion/styled";
import { ProxyKodenSelectField } from "../../ProxyKodenSelectField";
import { useInternalState } from "./useInternalState";

type Props = {
	id: string;
	warehouseName: string;
	onClick: () => void;
};

const StyledSelectField = styled(ProxyKodenSelectField)`
	width: 100%;
`;

export function FieldButton({ id, warehouseName, onClick }: Props) {
	const { error, label, disabled } = useInternalState();

	return (
		<StyledSelectField
			id={id}
			label={label}
			value={warehouseName}
			error={error}
			disabled={disabled}
			onChange={() => {}} // dummy
			onMouseDown={(e: any) => {
				e.preventDefault();
				onClick();
			}}
			options={[
				{
					id: warehouseName,
					value: warehouseName,
					label: warehouseName,
				},
			]}
		/>
	);
}
