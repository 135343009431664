import produce from "immer";
import { manufacturer } from "../moo-enums";
import { DEFAULT_ONE } from "./DEFAULT_ONE";

export const shape = produce(DEFAULT_ONE(), (draft) => {
	draft.items = [
		{
			request_buy_item_id: "",
			id: "",
			name: "",
			material: "",
			color: "",
			size: "",
			manufacturer_type: manufacturer.Value.Value.Domestic,
			manufacturer_id: "",
			manufacturer: "",
			count: 0,
			purchase_price: 0,
			image_cut: "",
			purchase_price_total: 0,
		},
	];
	draft.order_ids = [
		{
			request_buy_order_id_id: "",
			value: "",
		},
	];
	draft.item_infos = [
		{
			request_buy_item_info_id: "",
			website: "",
			image: "",
		},
	];
	draft.voucher_rows = [
		{
			voucher_id: "",
		},
	];
});
