import { useNavigate, useParams } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooCustomerUpdate } from "../../components/moo-components/moo-customer-update";
import { path as listPath } from "./ListRoute";

export const path = "/customer/:id";

export const UpdateRoute = () => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const branch = useBranchInQuery();

	return (
		<MooCustomerUpdate
			id={id!}
			onCancel={async () => {
				await navigate(`${listPath}?branch=${branch}`);
			}}
		/>
	);
};

export default UpdateRoute;
