import produce from "immer";
import { FC } from "react";
import { uuidv4 } from "../../../moo-shared";
import { MooUploadFileButton } from "../../../moo-upload-file-button";
import { useForm } from "../../hooks/useForm";

export const UploadButton: FC = () => {
	const { values, setFieldValue } = useForm();

	return (
		<MooUploadFileButton
			onUploaded={(fileName) => {
				setFieldValue(
					"instructions",
					produce(values.instructions, (draft) => {
						draft.push({ order_good_instruction_id: uuidv4(), value: fileName });
					}),
				);
			}}
		/>
	);
};

export default UploadButton;
