import type { AxiosInstance } from "axios";
import produce from "immer";
import * as _ from "lodash";
import { Row, SpecOrCustomTableRow } from "../@types";
import { StoreSlice } from "./useStore";

export type ChooseSlice = {
	chooseData: Record<string, Row>;
	getChooseSet: (ajax: AxiosInstance) => Promise<Row[]>;
	toggleChoose: (row: Row) => void;
	hasChosen: (row: Row) => boolean;
	clearChoose: () => void;
};

export const createChooseSlice: StoreSlice<ChooseSlice> = (set, get) => ({
	chooseData: {},
	getChooseSet: async () => {
		const chooseData = _.values(get().chooseData);
		const products: SpecOrCustomTableRow[] = [];

		chooseData.forEach((item) => {
			products.push(item);
		});
		return [...products];
	},
	toggleChoose: (row) =>
		set(
			produce((draft) => {
				const choosing = get().hasChosen(row);
				if (choosing) {
					delete draft.chooseData[row.id.toString()];
				} else {
					draft.chooseData[row.id.toString()] = row;
				}
			}),
		),
	hasChosen: (row) => _.has(get().chooseData, row.id.toString()),
	clearChoose: () =>
		set(
			produce((draft) => {
				draft.chooseData = {};
			}),
		),
});
