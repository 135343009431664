import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "employee_name",
			label: "姓名",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "total_price",
			label: "總金額",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "cost",
			label: "總成本",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "driver_fee",
			label: "司機",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "commission_refund",
			label: "退佣",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "tax_price",
			label: "發票稅",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "mgt_cost",
			label: "管銷",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "handling_fee",
			label: "刷卡扣%",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "miscellaneous_charge",
			label: "雜支",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "revenue",
			label: "利潤",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "bonus_percentage",
			label: "成數",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "bonus",
			label: "獎金",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "custom_bonus",
			label: "自訂獎金",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
	];
}
