import type { CellType } from "@yachelee/koden-components/koden-table";
import { order } from "../moo-enums";
import { useOnLinkClick } from "../moo-link-provider";
import { MooOrderStateAccountantSelect } from "../moo-order-state-accountant-select";
import { ClickableColumn, DollarNumber } from "../moo-shared";
import { Row } from "./@types";
import { useUpdateAccountantState } from "./useUpdateAccountantState";

export function useCells({ crud }: { crud: boolean }): CellType[] {
	const onLinkClick = useOnLinkClick();
	const updateAccountantState = useUpdateAccountantState();

	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "type_name",
			align: "left",
			component: ({ type_name }: Row) => {
				return <div>{type_name}</div>;
			},
		},
		{
			id: "nick_name",
			align: "left",
			component: ({ creator_name }: Row) => {
				return <div>{creator_name}</div>;
			},
		},
		{
			id: "customer_name",
			align: "left",
			component: ({ customer_name }: Row) => {
				return <div>{customer_name}</div>;
			},
		},
		{
			id: "sales_name",
			align: "left",
			component: ({ sales_name }: Row) => {
				return <div>{sales_name}</div>;
			},
		},
		{
			id: "description",
			align: "left",
			component: ({ description }: Row) => {
				return <div>{description}</div>;
			},
		},
		{
			id: "state",
			align: "left",
			component: ({ state }: Row) => {
				return <div>{order.State.getLabel(state)}</div>;
			},
		},
		{
			id: "state_manager",
			align: "left",
			component: ({ state_manager }: Row) => {
				return <div>{order.StateManager.getLabel(state_manager)}</div>;
			},
		},
		{
			id: "state_accountant",
			align: "left",
			component: ({ id, state_accountant }: Row) => {
				if (crud) {
					return (
						<div
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<MooOrderStateAccountantSelect
								value={state_accountant}
								onChange={(value) => updateAccountantState({ id, value })}
							/>
						</div>
					);
				} else {
					return <div>{order.StateAccountant.getLabel(state_accountant)}</div>;
				}
			},
		},
		{
			id: "state_warehouse",
			align: "left",
			component: ({ state_warehouse }: Row) => {
				return <div>{order.StateWarehouse.getLabel(state_warehouse)}</div>;
			},
		},
		{
			id: "total_price",
			align: "left",
			component: ({ total_price }: Row) => {
				return <DollarNumber>{total_price}</DollarNumber>;
			},
		},
		{
			id: "return_goods_ids",
			align: "left",
			component: ({ return_goods_ids }: Row) => {
				if (return_goods_ids.length === 0) {
					return null;
				}
				return (
					<>
						{return_goods_ids.map((id) => (
							<div key={id}>
								<ClickableColumn onClick={() => onLinkClick(id)}>{id}</ClickableColumn>
							</div>
						))}
					</>
				);
			},
		},
	];
}
