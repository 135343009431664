import { KodenCategoryList } from "@yachelee/koden-components/koden-category-list";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export type MooManufacturerTypeListProps = {
	onClick: (id: string) => void;
};

export function MooManufacturerTypeList({ onClick }: MooManufacturerTypeListProps) {
	const permissions = usePermissions();
	const categories = [];

	if (permissions.includes(Permission.ManufacturerDomesticTypeWrite)) {
		categories.push({
			id: "domestic",
			label: "國內廠商類型",
			engLabel: "",
		});
	}

	if (permissions.includes(Permission.ManufacturerForeignTypeWrite)) {
		categories.push({
			id: "foreign",
			label: "國外廠商類型",
			engLabel: "",
		});
	}

	if (categories.length === 0) {
		return <MooNoAuthPage />;
	}

	return <KodenCategoryList categories={categories} onClick={onClick} />;
}
