import { AttachItemRow } from "./@types/AttachItemRow";

export function attachItems<T extends AttachItemRow>(originalRows: T[], toAddRows: T[]): T[] {
	if (originalRows.length === 0) return toAddRows;

	let index = 0;
	const originalIndexMap: Record<string, number> = originalRows.reduce((prev, cur) => {
		return {
			...prev,
			[cur.id]: index++,
		};
	}, {});

	const finalRows: T[] = [...originalRows];
	toAddRows.forEach((toAddRow) => {
		//this row is already in the original
		if (toAddRow.id in originalIndexMap) {
			const index = originalIndexMap[toAddRow.id];
			const newRow = { ...finalRows[index] };
			newRow.count += toAddRow.count;
			finalRows[index] = newRow;
		} else {
			finalRows.push(toAddRow);
		}
	});

	return finalRows;
}
