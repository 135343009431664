import { object, SchemaOf } from "yup";
import { exportGoods, Status } from "../../moo-enums";
import {
	arrayOptionalSchema,
	arrayRequiredSchema,
	enumRequired,
	numberOptional,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { itemSchema } from "./itemSchema";
import { voucherRowSchema } from "./voucherRowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: enumRequired(Status),
	state: enumRequired(exportGoods.State.Value),
	type: enumRequired(exportGoods.Type),
	export_date: stringRequired("export_date"),
	order_id: stringOptional,
	type_id: stringRequired("type_id"),
	type_name: stringOptional,
	customer_id: stringRequired("customer_id"),
	customer_name: stringOptional,
	sales_id: stringRequired("sales_id"),
	sales_name: stringOptional,
	sales_tel: stringOptional,
	address: stringOptional,
	order_description: stringOptional,
	order_note: stringOptional,
	driver_logistics_manufacturer_id: stringRequired("driver_logistics_manufacturer_id"),
	driver_id: stringRequired("driver_id"),
	driver_name: stringOptional,
	driver_tel: stringOptional,
	export_goods_note: stringOptional,
	items: arrayRequiredSchema(itemSchema, "items", "尚未匯入出貨商品"),
	voucher_rows: arrayOptionalSchema(voucherRowSchema),
	shipping_fee: numberOptional,
});
