import { DocumentNode } from "@apollo/client";
import { useAjax } from "@yachelee/koden-components/koden-crud";
import { getDocumentNodeText } from "./getDocumentNodeText";
import { useGraphQLUrl } from "./useGraphQLUrl";

type Props = {
	query: DocumentNode;
	variables: Record<any, any>;
};

export function useGraphQLRequest() {
	const url = useGraphQLUrl();
	const ajax = useAjax();

	return ({ query, variables }: Props) => {
		const body = {
			query: getDocumentNodeText(query),
			variables,
		};
		return ajax.post(url, body).then((e) => e.data.data);
	};
}
