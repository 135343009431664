import { MooImportGoodsOne, One } from "../moo-import-goods-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { ImportGoodsItemsTable } from "./updateOne/ImportGoodsItemsTable";
import { ImportGoodsOrderIdsTable } from "./updateOne/ImportGoodsOrderIdsTable";
import { ImportGoodsTable } from "./updateOne/ImportGoodsTable";

export type MooImportGoodsUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooImportGoodsUpdate({ id, onCancel }: MooImportGoodsUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="import-goods-one"
			query={query}
			tableName="import_goods"
			mapperInitialValues={mapperInitialValues}
			Form={MooImportGoodsOne}
			tables={[ImportGoodsOrderIdsTable, ImportGoodsItemsTable, ImportGoodsTable]}
		/>
	);
}
