import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import { Status } from "../../moo-enums";
import { Query } from "../@types";

type Props = {
	query: Query;
	setQuery: (query: Partial<Query>) => void;
};

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;

	:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`;

export function StatusSelect({ query, setQuery }: Props) {
	return (
		<Container
			onClick={(e) => {
				e.preventDefault();
				if (query.status === Status.Present) {
					setQuery({
						status: Status.Archived,
						page: 0,
					});
				} else {
					setQuery({
						status: Status.Present,
						page: 0,
					});
				}
			}}
		>
			<Checkbox name="ahlel" id="status-field" checked={query.status === Status.Archived} />
			<div>已封存</div>
		</Container>
	);
}
