import { ReactNode } from "react";
import { LinkContext } from "./context";
import { OnClick } from "./types";

export type MooEmployeeIdProviderProps = {
	children: ReactNode;
	onLinkClick: OnClick;
};

export function MooEmployeeIdProvider({ children, onLinkClick }: MooEmployeeIdProviderProps) {
	return <LinkContext.Provider value={{ onLinkClick }}>{children}</LinkContext.Provider>;
}
