import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import {
	MooUserPerformanceList,
	Query,
	useCreateStore,
} from "../../components/moo-components/moo-user-performance-list";
import { useHistoryQuery } from "../../hooks/useHistoryQuery";
import usePage from "../../hooks/usePage";
import useURLSearchParams from "../../hooks/useURLSearchParams";
import Utils from "../../utils";
import { prefixPath } from "./AccountPerformanceSubListRoute";

export const path = "/account/performance";

const useUrlQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();

	return {
		search: urlSearchParams.get("search") || null,
		page,
		year: urlSearchParams.has("year") ? parseInt(urlSearchParams.get("year")!) : Utils.getTodayFullYear(),
		month: urlSearchParams.has("month") ? parseInt(urlSearchParams.get("month")!) : Utils.getTodayHumanMonth(),
	};
};

const AccountPerformanceListRoute = () => {
	const urlQuery = useUrlQuery();
	const store = useCreateStore(urlQuery, {
		search: null,
		page: 0,
		year: Utils.getTodayFullYear(),
		month: Utils.getTodayHumanMonth(),
	});
	const navigate = useNavigate();
	const branch = useBranchInQuery();
	useHistoryQuery<Query>({ path, store });

	return (
		<MooUserPerformanceList
			store={store}
			onRowClick={async (row, event) => {
				event.preventDefault();
				navigate(`${prefixPath}/${row.employee_id}?year=${row.year}&month=${row.month}&branch=${branch}`);
			}}
		/>
	);
};

export default AccountPerformanceListRoute;
