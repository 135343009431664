import produce from "immer";
import { useFindCustomerById } from "../../moo-customer-one";
import { Employee, useFindEmployeeById } from "../../moo-employee-one";
import { useRequestById as useFindLogisticsManufacturerById } from "../../moo-manufacturer-logistics-update";
import { uuidv4 } from "../../moo-shared";
import { One, ServiceItem } from "../@types/one";
import { DEFAULT_ONE } from "../DEFAULT_ONE";
import { useForm } from "./useForm";

export function useSyncPrices() {
	return (one: One) => {
		//報價 = 商品數量 * 商品價錢
		one.price = one.items.map((e) => e.price * e.count).reduce((a, b) => a + b, 0);
		//應收費用(receivables) = 報價(price) - 已收(received)
		one.receivables = one.price - one.received;
	};
}

export function useUpdateCustomerId() {
	const findCustomerById = useFindCustomerById();
	const { values, setValues } = useForm();
	return async (customer_id: string | null) => {
		if (customer_id === null) {
			setValues({
				...values,
				customer_id: "",
				customer_name: DEFAULT_ONE().customer_name,
			});
		} else {
			const record = await findCustomerById(customer_id);
			if (record) {
				setValues({
					...values,
					customer_id,
					customer_name: record.name,
				});
			}
		}
	};
}

export function useUpdateEmployeeId(idField: keyof One, nameField: keyof One) {
	const { values, setValues } = useForm();
	const findEmployeeById = useFindEmployeeById();

	return async (id: string) => {
		if (id === null) {
			setValues({
				...values,
				[idField]: id,
				[nameField]: DEFAULT_ONE()[nameField],
			});
		} else {
			const record: Employee = await findEmployeeById(id);

			setValues({
				...values,
				[idField]: id,
				[nameField]: record.name,
			});
		}
	};
}

export function useUpdateSalesId() {
	return useUpdateEmployeeId("sales_id", "sales_name");
}

export function useUpdateOwnerId() {
	return useUpdateEmployeeId("owner_id", "owner_name");
}

export function useUpdateDriverLogisticsManufacturerId() {
	const { values, setValues } = useForm();

	return async (driver_logistics_manufacturer_id: One["driver_logistics_manufacturer_id"]) => {
		setValues({
			...values,
			driver_logistics_manufacturer_id,
			driver_id: null,
			driver_tel: "",
		});
	};
}

export function useUpdateDriverId() {
	const { values, setValues } = useForm();
	const findLogisticsManufacturerById = useFindLogisticsManufacturerById();

	return async (driver_id: One["driver_id"]) => {
		if (driver_id === null) {
			setValues({
				...values,
				driver_id,
				driver_tel: "",
			});
		} else {
			const logisticsManufacturer = (await findLogisticsManufacturerById(values.driver_logistics_manufacturer_id!))
				.manufacturer_logistics_by_pk;
			if (logisticsManufacturer !== null) {
				// @ts-ignore
				const driver = logisticsManufacturer.drivers.find((e) => e.manufacturer_logistic_driver_id === driver_id);
				if (driver) {
					setValues({
						...values,
						driver_id,
						driver_tel: driver.tel,
					});
				}
			}
		}
	};
}

export function useAddItem() {
	const syncPrices = useSyncPrices();
	const { values, setValues } = useForm();

	return () => {
		setValues(
			produce(values, (one) => {
				const item: ServiceItem = {
					svc_item_id: uuidv4(),
					name: "",
					count: 1,
					price: 0,
				};
				one.items.push(item);
				syncPrices(one);
			}),
		);
	};
}

export function useUpdateItem() {
	const syncPrices = useSyncPrices();
	const { values, setValues } = useForm();

	return (id: ServiceItem["svc_item_id"], field: keyof ServiceItem, value: any) => {
		setValues(
			produce(values, (one) => {
				const item: ServiceItem = one.items.find((e) => e.svc_item_id === id)!;

				// @ts-ignore
				item[field] = value;
				syncPrices(one);
			}),
		);
	};
}

export function useDelItem() {
	const syncPrices = useSyncPrices();
	const { values, setValues } = useForm();

	return (id: ServiceItem["svc_item_id"]) => {
		setValues(
			produce(values, (one) => {
				const itemIndex = one.items.findIndex((e) => e.svc_item_id === id);
				if (itemIndex !== -1) {
					one.items.splice(itemIndex, 1);
				}
				syncPrices(one);
			}),
		);
	};
}
