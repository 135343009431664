export enum Value {
	UnSettled = "UNSETTLED",
	Settled = "SETTLED",
	DepositCancelled = "DEPOSIT_CANCELLED",
}

export function getValues(): Value[] {
	return [Value.UnSettled, Value.Settled, Value.DepositCancelled];
}

export enum Label {
	UnSettled = "未結清",
	Settled = "已結清",
	DepositCancelled = "退訂金",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.UnSettled:
			return Label.UnSettled;
		case Value.Settled:
			return Label.Settled;
		case Value.DepositCancelled:
			return Label.DepositCancelled;
	}
	return Label.UnSettled;
}
