import { KodenSearch } from "@yachelee/koden-components/koden-search";
import useStore from "../stores/useStore";

export function KeywordSearch() {
	const { query, setQuery } = useStore();

	return (
		<KodenSearch
			borderColor="#EAEAEA"
			defaultValue={query.search || ""}
			onChange={(search) => {
				setQuery({
					search,
					page: 0,
				});
			}}
		/>
	);
}
