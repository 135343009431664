import produce from "immer";
import { ReturnItemBound } from "../../moo-types";
import { useForm } from "./useForm";

export function useUpdateWarehouseId() {
	const { values, setFieldValue } = useForm();

	return (id: string, value: ReturnItemBound["warehouse_id"]) => {
		setFieldValue(
			"items",
			produce(values.items, (items) => {
				const item = items.find((e) => e.id === id);
				if (item) {
					item.warehouse_id = value;
				}
			}),
		);
	};
}
