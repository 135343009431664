import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { Formik } from "formik";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { useOrderDialogChoose } from "../moo-dialog-order-choose";
import { MooEmployeeSelectField } from "../moo-employee-select-field";
import { MooLinkButton } from "../moo-link-button";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooSubmitButton } from "../moo-submit-button";
import { Props } from "./@types/props";
import { StateBlock } from "./StateBlock";
import { useForm } from "./hooks/useForm";
import { useGetError } from "./hooks/useGetError";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.form`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

export const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	max-width: ${WEB_BREAKPOINT};
	margin-bottom: 30px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const LinkField = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 0.3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.6rem;
	align-items: center;
	padding: 14px;
	min-height: 56px;
	text-align: center;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto 1fr;
		text-align: left;
		padding: 0 14px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

function Form({
	isLoading = false,
	isEdit = false,
	isCancelDisabled,
	isOkDisabled,
	onCancel,
}: Omit<Props, "initialValues" | "onSubmit">) {
	const { values, setFieldValue, handleSubmit } = useForm();
	const getError = useGetError();
	const { open, component } = useOrderDialogChoose(
		"order-dialog-choose-allowance",
		(rows) => {
			if (rows.length > 0) {
				const order_id = rows[0].id;
				setFieldValue("order_id", order_id);
			}
		},
		false,
	);

	return (
		<>
			{component}
			<Container onSubmit={handleSubmit}>
				{isEdit && <StateBlock />}
				<FieldContainer>
					<KodenDateField
						id="request_date"
						label="請款日"
						disabled={isLoading}
						error={getError("request_date")}
						value={values.request_date === null ? null : new Date(values.request_date)}
						onChange={(date) =>
							setFieldValue(
								"request_date",
								date === null ? null : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
							)
						}
					/>
					<MooEmployeeSelectField
						id="requester_id"
						label="請款人"
						value={values.requester_id}
						error={getError("requester_id")}
						onChange={(value) => setFieldValue("requester_id", value)}
					/>
					<KodenNumberField
						id="amount"
						label="金額"
						outlined
						margin="none"
						hasDollar
						value={values.amount}
						min={0}
						max={Number.MAX_SAFE_INTEGER}
						step={1}
						error={getError("amount")}
						onChange={(amount) => setFieldValue("amount", amount)}
					/>
					<ProxyKodenTextField
						id="reason"
						label="用途說明"
						disabled={isLoading}
						outlined
						margin="none"
						value={values.reason}
						error={getError("reason")}
						onChange={(reason) => setFieldValue("reason", reason)}
					/>
					<LinkField>
						<KodenButton
							onClick={() => {
								open();
							}}
						>
							{values.order_id !== null ? "重匯訂購單" : "匯入訂購單"}
						</KodenButton>
						<MooLinkButton id={values.order_id} name="訂購單" />
					</LinkField>
				</FieldContainer>
				{!isOkDisabled && (
					<ButtonContainer>
						<KodenButton
							style={{ marginRight: "1rem" }}
							disabled={isCancelDisabled}
							onClick={async () => {
								if (window.confirm("確定取消並且回到上一頁？")) {
									onCancel();
								}
							}}
						>
							取消
						</KodenButton>
						<MooSubmitButton permission={Permission.AllowanceUpdate} />
					</ButtonContainer>
				)}
			</Container>
		</>
	);
}

export function MooAllowanceOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.AllowanceRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
