import { Query } from "./@types";

export function useWhere({ query }: { query: Query }): Record<string, any> {
	const where: Record<string, any> = {};
	if (query.search !== null && query.search.trim() !== "") {
		const _similar = `%(${query.search.trim().split(" ").join("|")})%`;
		where.name = {
			_similar,
		};
	}

	return where;
}
