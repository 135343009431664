import ColumnType, { ColumnIdType } from "./@types/columnType";
import { StateMapType } from "./hooks/useStateMap";

export type ErrorsType = Record<ColumnIdType, string>;

const getColumnMap = (columns: ColumnType[]): Record<ColumnIdType, ColumnType> => {
	const obj: Record<string, ColumnType> = {};

	columns.forEach((column) => {
		obj[column.id] = column;
	});
	return obj;
};

const getErrors = (stateMap: StateMapType, columns: ColumnType[]): ErrorsType => {
	const columnMap = getColumnMap(columns);
	const errors: ErrorsType = {};

	Object.keys(stateMap).forEach((columnId) => {
		const column = columnMap[columnId];
		const value = stateMap[columnId];

		if (value === column.defaultValue) {
			errors[columnId] = column.emptyError;
		}
	});
	return errors;
};

export default getErrors;
