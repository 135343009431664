import produce from "immer";
import { FC } from "react";
import { DEFAULT_ONE, MooItemCustomOne, One } from "../moo-item-custom-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooItemCustomAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooItemCustomAdd: FC<MooItemCustomAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<One>("item_customs", onSuccess, (e) =>
		produce(e, (draft) => {
			// @ts-ignore
			delete draft.image_cut;
			// @ts-ignore
			delete draft.image;
		}),
	);
	const permissions = usePermissions();

	return (
		<MooItemCustomOne
			isEdit={false}
			initialValues={DEFAULT_ONE()}
			isCancelDisabled={!permissions.includes(Permission.ItemCustomAdd)}
			isOkDisabled={false}
			onCancel={onCancel}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
