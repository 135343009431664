import { gql } from "@apollo/client";

export const graphQLQuery = gql`
	query query($where: voucher_rows_bool_exp, $order_by: [voucher_rows_order_by!], $limit: Int, $offset: Int) {
		voucher_rows_aggregate(where: $where) {
			aggregate {
				count
				sum {
					credit_amount
					debit_amount
				}
			}
		}
		voucher_rows(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
			voucher_row_id
			account_type {
				id
				id_number
				name
			}
			summary
			type
			debit_amount
			credit_amount
			voucher {
				voucher_date
				id
			}
			manufacturer_domestic {
				name
			}
			manufacturer_foreign {
				name
			}
		}
	}
`;
