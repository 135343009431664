export const graphQLColumns = `{
	id
	return_date
	customer {
		name
	}
	sales {
		name
	}
	state_warehouse
	state_accountant
	total_price
	note
	order_id
}`;
