import { KodenButton } from "@yachelee/koden-components/koden-button";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import produce from "immer";
import styled from "styled-components";
import { One } from "..";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { Row, useOrderDialogChoose } from "../../moo-dialog-order-choose";
import { useFindItemCustomsMapByIds } from "../../moo-item-custom-one";
import { useFindItemSpecsMapByIds } from "../../moo-item-spec-one";
import { MooLinkButton } from "../../moo-link-button";
import { useFindOrderById } from "../../moo-order-update";
import { Calculator } from "../../moo-quotation-one";
import { ErrorMessage, getItemSpec, getModelItemId, useGetFieldError } from "../../moo-shared";
import FieldContainer from "../components/FieldContainer";
import ItemList from "../components/ItemList/ItemList";
import { useForm } from "../useForm";
import { useSyncPrices } from "../useSyncPrices";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const FileUpload = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 0.3rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.6rem;
	align-items: center;
	padding: 14px;
	min-height: 56px;
	text-align: center;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto 1fr;
		text-align: left;
		padding: 0 14px;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

function useChooseOrder() {
	const { values, setValues, initialValues } = useForm();
	const syncPrices = useSyncPrices();
	const findOrderById = useFindOrderById();
	const findItemSpecsMapByIds = useFindItemSpecsMapByIds();
	const findItemCustomsMapByIds = useFindItemCustomsMapByIds();

	return async (orders: Row[]) => {
		if (orders.length !== 0) {
			const order_id = orders[0].id;
			const order = (await findOrderById(order_id)).orders_by_pk;
			if (order !== null) {
				const specItemMap = await findItemSpecsMapByIds(
					order.items.filter((e) => e.id.startsWith("S")).map((e) => e.id),
				);
				const customItemMap = await findItemCustomsMapByIds(
					order.items.filter((e) => e.id.startsWith("C")).map((e) => e.id),
				);

				const newValues = produce(values, (one: One) => {
					one.order_id = order_id;
					one.order_type_name = order.quotation_type.name;
					one.order_customer_name = order.customer.name;
					one.order_sales_name = order.sales.name;
					one.order_sales_tel = order.sales.tel;
					one.order_deliver_date = order.deliver_date;
					one.order_is_include_tax = order.is_include_tax;
					one.order_payee_name = order.payee.name;
					one.order_payee_bank_name = order.payee.bank_name;
					one.order_payee_account_number = order.payee.account_number;
					one.order_deliver_address = order.deliver_address;
					one.order_description = order.description;
					one.order_order_note = order.order_note;
					// @ts-ignore
					one.items = order.items
						.filter((e) => specItemMap.has(e.id) || customItemMap.has(e.id))
						.map(({ id, price_final: orderPriceFinal }, priority) => {
							const isSpecItem = id.startsWith("S");
							const item = (isSpecItem ? specItemMap.get(id) : customItemMap.get(id))!;
							const count = 1;

							return {
								return_order_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: id }),
								id,
								name: item.name,
								spec: getItemSpec({ material: item.material, color: item.color, size: item.size }),
								material: item.material,
								color: item.color,
								size: item.size,
								manufacturer_type: item.manufacturer_type,
								manufacturer: item.manufacturer.name,
								count,
								price_total: Calculator.getItemPriceTotal({ price_final: orderPriceFinal, count }),
								price_final: orderPriceFinal,
								purchase_price: item.purchase_price,
								price: item.price,
								image: item.image,
								image_cut: item.image_cut,
								priority,
							};
						});
					syncPrices(one);
				});
				setValues(newValues);
			}
		}
	};
}

export function BasicBlock() {
	const chooseOrder = useChooseOrder();
	const { values: one, setFieldValue: updateOne } = useForm();
	const getFieldError = useGetFieldError<One>();
	const { component, open } = useOrderDialogChoose("useOrderDialogChoose", chooseOrder, false);
	const orderImported = one.order_id !== null;

	return (
		<>
			{component}
			<FieldContainer>
				<KodenDateField
					id="return_date"
					label="退訂日期"
					error={getFieldError("return_date")}
					value={one.return_date === null ? null : new Date(one.return_date)}
					onChange={(value) => {
						updateOne(
							"return_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						);
					}}
				/>
				<FileUpload>
					<KodenButton style={{ marginRight: "1rem" }} onClick={open}>
						{!orderImported ? "匯入" : "重匯"}訂購單
					</KodenButton>
					<MooLinkButton id={one.order_id} name="訂購單" />
					<ErrorMessage values={[getFieldError("order_id")]} />
				</FileUpload>
			</FieldContainer>
			{orderImported && (
				<>
					<FieldContainer>
						<ProxyKodenTextField
							id="type_id"
							label="類型"
							outlined
							margin="none"
							value={one.order_customer_name}
							error=""
							disabled
							onChange={() => {}}
						/>
						<ProxyKodenTextField
							id="customer_name"
							label="客戶"
							outlined
							margin="none"
							value={one.order_customer_name}
							error=""
							disabled
							onChange={() => {}}
						/>
						<ProxyKodenTextField
							id="sales_name"
							label="業務"
							outlined
							margin="none"
							value={one.order_sales_name}
							error=""
							disabled
							onChange={() => {}}
						/>
						<ProxyKodenTextField
							id="sales_tel"
							label="業務電話"
							outlined
							margin="none"
							value={one.order_sales_tel}
							readOnly
							disabled
							error=""
						/>
					</FieldContainer>
					<FieldContainer>
						<KodenDateField
							id="deliver_date"
							label="交貨日期"
							value={new Date(one.order_deliver_date)}
							error=""
							disabled
							onChange={() => {}}
						/>
						<ProxyKodenTextField
							id="is_include_tax"
							label="稅金"
							outlined
							margin="none"
							value={one.order_is_include_tax ? "是" : "否"}
							readOnly
							disabled
							error=""
						/>
						<ProxyKodenTextField
							id="payee_name"
							label="收款戶名"
							outlined
							margin="none"
							value={one.order_payee_name}
							readOnly
							disabled
							error=""
						/>
						<ProxyKodenTextField
							id="payee_bank_name"
							label="收款銀行"
							outlined
							margin="none"
							value={one.order_payee_bank_name}
							readOnly
							disabled
							error=""
						/>
						<ProxyKodenTextField
							id="payee_account_number"
							label="收款帳號"
							outlined
							margin="none"
							value={one.order_payee_account_number}
							readOnly
							disabled
							error=""
						/>
						<ProxyKodenTextField
							id="deliver_address"
							label="配送地址"
							outlined
							margin="none"
							value={one.order_deliver_address}
							error=""
							disabled
							onChange={() => {}}
						/>
						<ProxyKodenTextField
							id="description"
							label="訂單簡述"
							outlined
							margin="none"
							value={one.order_description}
							error=""
							disabled
							onChange={() => {}}
						/>
						<ProxyKodenTextField
							id="order_note"
							label="備註"
							outlined
							margin="none"
							value={one.order_order_note}
							error=""
							disabled
							onChange={() => {}}
						/>
					</FieldContainer>
					<ItemList />
					<FieldContainer>
						<ProxyKodenTextField
							style={{ gridColumn: "1/-1" }}
							id="note"
							label="備註"
							outlined
							margin="none"
							value={one.note}
							error=""
							onChange={(note) => {
								updateOne("note", note);
							}}
						/>
					</FieldContainer>
				</>
			)}
		</>
	);
}
