import { MooGqlChildNewTable } from "../../moo-gql";
import { Row } from "../findOne/Row";

export class RequestBuyOrderIdsTable extends MooGqlChildNewTable<Row> {
	getIdName(): string {
		return "request_buy_order_id_id";
	}

	getTableName(): string {
		return "request_buy_order_ids";
	}

	getPathName(): string {
		return "order_ids";
	}

	getParentIdName(): string {
		return "request_buy_id";
	}

	getToUpdateColumns(): string[] {
		return ["value"];
	}
}
