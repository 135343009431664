import { CellType } from "../moo-graphql-list";
import { MooItemListImg } from "../moo-item-list-img";
import { useOnLinkClick } from "../moo-link-provider";
import { ClickableColumn } from "../moo-shared";
import { Cell } from "./Cell";

export function useCells(): CellType[] {
	const onLinkClick = useOnLinkClick();

	return [
		{
			id: "item_id",
			align: "left",
			style: {
				width: 10,
			},
			component({ id }: Cell) {
				return (
					<ClickableColumn
						onClick={() => {
							onLinkClick(id);
						}}
					>
						{id}
					</ClickableColumn>
				);
			},
		},
		{
			id: "item_name",
			align: "left",
			style: {
				minWidth: 100,
			},
			component({ item_name }: Cell) {
				return item_name;
			},
		},
		{
			id: "img",
			align: "left",
			style: {
				width: 200,
			},
			component({ image, image_thumbnail, item_name }: Cell) {
				return <MooItemListImg name={item_name} image_cut={image_thumbnail} image={image} />;
			},
		},
		{
			id: "imported_count",
			align: "left",
			style: {
				minWidth: 80,
			},
			component(cell: Cell) {
				return (cell.imported_count || 0).toLocaleString();
			},
		},
		{
			id: "transferred_in_count",
			align: "left",
			style: {
				minWidth: 90,
			},
			component(cell: Cell) {
				return (cell.transferred_in_count || 0).toLocaleString();
			},
		},
		{
			id: "returned_count",
			align: "left",
			style: {
				minWidth: 80,
			},
			component(cell: Cell) {
				return (cell.returned_count || 0).toLocaleString();
			},
		},
		{
			id: "maintained_count",
			align: "left",
			style: {
				minWidth: 120,
			},
			component(cell: Cell) {
				return (cell.maintained_count || 0).toLocaleString();
			},
		},
		{
			id: "maintaining_count",
			align: "left",
			style: {
				minWidth: 80,
			},
			component(cell: Cell) {
				return (cell.maintaining_count || 0).toLocaleString();
			},
		},
		{
			id: "exported_count",
			align: "left",
			style: {
				minWidth: 80,
			},
			component(cell: Cell) {
				return (cell.exported_count || 0).toLocaleString();
			},
		},
		{
			id: "transferred_out_count",
			align: "left",
			style: {
				minWidth: 100,
			},
			component(cell: Cell) {
				return (cell.transferred_out_count || 0).toLocaleString();
			},
		},
		{
			id: "available_count",
			align: "left",
			style: {
				minWidth: 200,
			},
			component(cell: Cell) {
				return (cell.available_count || 0).toLocaleString();
			},
		},
	];
}
