import { FC, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
	box-shadow:
		0 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 1px 3px 0px rgb(0 0 0 / 12%);
	width: fit-content;
	height: fit-content;
	border-radius: 8px;

	&:not(:last-child) {
		margin-bottom: 2.25rem;
	}
`;

const Title = styled.div<{ backgroundColor: string }>`
	height: 45px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-color: #c1c1c1;
	background-color: ${(props) => props.backgroundColor};

	display: flex;
	align-items: center;
	color: #000000;
	font-weight: bold;
	font-size: 1rem;
	transition: background-color 300ms linear;

	user-select: none;

	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
`;

const WhiteBoard = styled.div`
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: white;
	font-size: 0.875rem;
	color: #5c5a5a;
	overflow: auto;
`;

type Props = {
	children?: ReactNode;
	headerBackgroundColor: string;
	title: ReactNode;
	className?: string;
};

const Panel: FC<Props> = ({ className, headerBackgroundColor, title, children }) => {
	return (
		<Container className={className}>
			<Title backgroundColor={headerBackgroundColor}>{title}</Title>
			<WhiteBoard>{children}</WhiteBoard>
		</Container>
	);
};

export default Panel;
