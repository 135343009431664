import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    state_accountant
    return_date
    order_id
    order {
        deliver_date
        is_include_tax
        quotation_type {
            name
        }
        customer{
            name
        }
        sales{
            name
            tel
        }
        payee {
            name
            bank_name
            account_number
        }
        deliver_address
        description
        order_note
    }
    items {
        return_order_item_id
        id
        count
        price_final
        item_spec {
            name
            material
            color
            size
            purchase_price
            image
            image_cut
            price
            manufacturer_type
            manufacturer {
                name
            }
        }
        item_custom {
            name
            material
            color
            size
            purchase_price
            image
            image_cut
            price
            manufacturer_type
            manufacturer{ 
                name
            }
        }
    }
    note
    total_price
    tax
    note_price
    create_date
    update_date
`;

export const query = gql`
    query ($id: String!) {
        return_orders_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
