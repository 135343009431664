import { object, SchemaOf } from "yup";
import { ItemType, manufacturer } from "../../moo-enums";
import {
	enumRequired,
	numberAllowZeroRequired,
	numberOptional,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { Item } from "../@types/one";

// @ts-ignore
export const itemSchema: SchemaOf<Item> = object().shape({
	order_good_item_id: stringRequired("order_good_item_id"),
	id: stringRequired("id"),
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer_id: stringOptional,
	manufacturer: stringOptional,
	category: stringOptional,
	type: enumRequired(ItemType),
	name: stringRequired("name"),
	cost: numberOptional,
	spec: stringOptional,
	material: stringOptional,
	color: stringOptional,
	size: stringOptional,
	count: numberOptional,
	price: numberOptional,
	price_final: numberOptional,
	price_total: numberOptional,
	purchase_price: numberOptional,
	purchase_price_total: numberOptional,
	note: stringOptional,
	image: stringOptional,
	image_cut: stringOptional,
	priority: numberAllowZeroRequired("priority", "請提供", true),
	return_count: numberOptional,
	warehouse_id: stringOptional,
});
