import { Status } from "../moo-enums";
import { getNowLocaleString, uuidv4 } from "../moo-shared";
import { Customer, Gender } from "./@types/customer";

export function DEFAULT_ONE(): Customer {
	const now = getNowLocaleString();
	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		type_id: "",
		name: "",
		gender: Gender.None,
		birthday: null,
		email: "",
		tels: [
			{
				customer_tel_id: uuidv4(),
				tel: "",
			},
		],
		line: "",
		job: "",
		vip: null,
		address: "",
		bank_account: "",
		bank: "",
		bank_account_number: "",
		tax_id: "",
		note: "",
	};
}
