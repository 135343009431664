import { object, SchemaOf } from "yup";
import { importGoods, manufacturer } from "../../moo-enums";
import {
	arrayOptionalSchema,
	arrayRequiredSchema,
	enumRequired,
	numberOptional,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { itemSchema } from "./itemSchema";
import { orderIdSchema } from "./orderIdSchema";
import { voucherRowSchema } from "./voucherRowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: statusSchema,
	state: enumRequired(importGoods.State.Value),
	state_accountant: enumRequired(importGoods.StateAccountant.Value),
	type: enumRequired(importGoods.Type),
	import_date: stringRequired("import_date"),
	order_ids: arrayOptionalSchema(orderIdSchema),
	link_id: stringOptional,
	sales_id: stringRequired("sales_id"),
	sales_name: stringOptional,
	sales_tel: stringOptional,
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer_id: stringRequired("manufacturer_id"),
	manufacturer: stringRequired("manufacturer"),
	warehouse_id: stringRequired("warehouse_id"),
	note: stringOptional,
	items: arrayRequiredSchema(itemSchema, "items", "尚未匯入進貨商品"),
	accountant_tax: numberOptional,
	accountant_freight: numberOptional,
	accountant_miscellaneous_charge: numberOptional,
	accountant_total_price: numberOptional,
	accountant_received: numberOptional,
	accountant_payable: numberOptional,
	accountant_note: stringOptional,
	voucher_rows: arrayOptionalSchema(voucherRowSchema),
});
