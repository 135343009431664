import { ItemType } from "../../../moo-enums";
import useStore from "../../stores/useStore";

export function useGraphQLWhereName(): string {
	const {
		query: { type },
	} = useStore();
	switch (type) {
		case ItemType.Spec:
			return "item_specs_bool_exp";
		case ItemType.Custom:
			return "item_customs_bool_exp";
		case ItemType.Bundle:
			return "item_bundles_bool_exp";
	}
}
