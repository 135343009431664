import { gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { useGraphQLRequest } from "../moo-graphql-provider";
import { useReadFields } from "./useReadFields";

export function useFindOneById<T extends { create_date?: Date | string }>(shape: T, resource: string) {
	const { value: branch } = useBranch();
	const graphQLRequest = useGraphQLRequest();
	const readFields = useReadFields(shape, {});

	const query = gql`
      query ($where: ${resource}_bool_exp) {
      		${resource}(where: $where) {
              ${readFields}
          }
      }
	`;

	return async (id: any) => {
		const variables = {
			where: {
				id: {
					_eq: id,
				},
				branch_id: {
					_eq: branch,
				},
			},
		};

		const e: any = await graphQLRequest({ query, variables });
		const arr = e[resource];
		if (arr.length === 0) {
			return null;
		} else {
			return arr[0] as T;
		}
	};
}
