import styled from "@emotion/styled";
import { KodenBinIcon } from "@yachelee/koden-components/koden-bin-icon";
import { FC } from "react";
import { MooFileLink } from "../moo-file-link";
import { QueueRecordType } from "./moo-files-upload-field";

const TABLET_BREAKPOINT = "768px";

const Container = styled.div<{ hasItems: boolean }>`
	grid-area: file;
	border-top: ${(hasItems) => (hasItems ? "1px solid #c6c6c6" : "none")};
	border-left: none;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		border-top: none;
		padding-left: 1rem;
		border-left: ${(hasItems) => (hasItems ? "1px solid #c6c6c6" : "none")};
	}
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

type Props = {
	values: QueueRecordType;
	onDelete: (id: string) => void;
};

export const Files: FC<Props> = ({ values, onDelete }) => {
	return (
		<Container hasItems={Object.keys(values) && Object.keys(values).length > 0}>
			{Object.keys(values).map((id, index) => {
				const { loading, src: fileName } = values[id];
				if (loading) return <div key={id}>Loading</div>;

				return (
					<Row key={id}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: ".5rem" }}>{index + 1}.</div>
							<MooFileLink fileName={fileName} />
						</div>
						<KodenBinIcon
							id={`${fileName}_DEL`}
							onClick={() => {
								if (window.confirm("確定要刪除嗎？")) {
									onDelete(id);
								}
							}}
						/>
					</Row>
				);
			})}
		</Container>
	);
};
