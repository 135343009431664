import { useParams } from "react-router-dom";
import { MooAccountSubjectCrud } from "../components/moo-components/moo-account-subject-crud";

export const prefixPath = "/account_types";
export const path = prefixPath + "/:category/:id";

const AccountTypeSubRoute = () => {
	const { category, id } = useParams<{ category: string; id: string }>();

	return <MooAccountSubjectCrud category={category!} parent={id!} />;
};

export default AccountTypeSubRoute;
