import * as _ from "lodash";
import { getSearchCondition } from "../moo-customer-list";
import { CellType, HeaderType, MooDialogChoose, useStore } from "../moo-dialog-choose";
import { getMonthAndDayFormat } from "../moo-shared";
import { Row } from "./@types";

export type MooDialogCustomerChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
	isMultiple?: boolean;
};

const arr = [
	{ id: "number", label: "編號" },
	{ id: "type", label: "類型" },
	{ id: "name", label: "姓名" },
	{ id: "gender", label: "性別" },
	{ id: "birthday", label: "生日" },
	{ id: "vip", label: "VIP" },
	{ id: "tel", label: "電話" },
	{ id: "email", label: "Email" },
	{ id: "address", label: "地址" },
	{ id: "line", label: "Line" },
];

const headers = arr.map((e) => {
	return { ...e, align: "left", style: { minWidth: 65 } };
}) as HeaderType[];

const cells = arr.map(({ id }) => {
	return {
		id,
		align: "left",
		component: (row: Row) => <div>{row[id as keyof Row]}</div>,
	};
}) as CellType[];

export function MooDialogCustomerChoose({ id, open, onClose, onOk, isMultiple = false }: MooDialogCustomerChooseProps) {
	const store = useStore();
	const where: Record<string, any> = getSearchCondition(store.search);

	return (
		<MooDialogChoose
			id={id}
			label=""
			title="客戶"
			resource="customers"
			graphQLColumns={`{
			id
			number
			customer_type {
				id
				name
			}
			name
			gender
			vip
			email
			line
			address
			birthday
			tels{
				customer_tel_id
				tel
			}
		}`}
			mapResults={(results) => {
				return results.map((e) => ({
					...e,
					birthday: getMonthAndDayFormat(e.birthday),
					type: _.get(e, "customer_type.name", ""),
					tel: _.get(e, "tels.0.tel", ""),
				}));
			}}
			open={open}
			// @ts-ignore
			onOk={onOk}
			onClose={onClose}
			headers={headers}
			cells={cells}
			store={store}
			isMultiple={isMultiple}
			where={where}
		/>
	);
}
