import styled from "@emotion/styled";
import { KodenDialog } from "@yachelee/koden-components/koden-dialog";
import { KodenSearch } from "@yachelee/koden-components/koden-search";
import type { CellType, HeaderType } from "@yachelee/koden-components/koden-table";
import produce from "immer";
import * as _ from "lodash";
import { ReactNode, useState } from "react";
import { MooGraphQLTable } from "../moo-graphql-table";
import { useListGraphQLQuery, useListGraphQLVariables } from "../moo-shared";
import { Row } from "./@types";
import { useConditions } from "./useConditions";

export { CellType };
export { HeaderType };

const TABLET_BREAKPOINT = "768px";

const ControlPanel = styled.div`
	display: flex;
	width: 100%;
	margin-top: 0.2rem;

	& .koden-search {
		width: 100%;
		font-size: 0.875rem;

		input {
			width: 100%;
		}
	}

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		width: 150px;
		margin-top: 0;
	}
`;

interface Store<T extends Row> {
	chooseData: Record<string, T>;
	getChooseSet: () => T[];
	toggleChoose: (row: T) => void;
	clearChoose: () => void;
	hasChosen: (row: T) => boolean;
	search: string | null;
	setSearch: (search: string) => void;
	page: number;
	setPage: (page: number) => void;
}

export interface MooDialogChooseProps<T extends Row> {
	titleActions?: ReactNode;
	id: string;
	title?: string;
	label: string;
	resource: string;
	limit?: number;
	hasSearch?: boolean;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
	headers: HeaderType[];
	cells: CellType[];
	store: Store<T>;
	isMultiple?: boolean;
	graphQLColumns: string;
	mapResults?: (results: any[], e: any) => { id: string }[];
	where?: Record<string, any>;
}

export const MooDialogChoose = <T extends Row>({
	titleActions = null,
	hasSearch = true,
	id,
	title = "",
	graphQLColumns,
	label,
	resource,
	limit = 10,
	headers: propsHeaders,
	cells: propsCells,
	open,
	onClose,
	onOk,
	store,
	isMultiple = true,
	mapResults = (e) => e,
	where = {},
}: MooDialogChooseProps<T>) => {
	const { getChooseSet, search, setSearch, page, setPage, toggleChoose } = store;
	const [isLoading] = useState(false);
	const conditions = useConditions(where);
	const query = useListGraphQLQuery(resource, graphQLColumns);
	const variables = useListGraphQLVariables(limit, store.page, conditions);
	let headers = propsHeaders;
	let cells = propsCells;

	if (isMultiple) {
		headers = produce(headers, (draft) => {
			draft.unshift({
				id: "checkbox",
				label: "",
				align: "left",
				style: { minWidth: 30 },
			});
		});

		cells = produce(cells, (draft) => {
			draft.unshift({
				id: "checkbox",
				align: "left",
				component: (row: Row) => (
					<input
						type="checkbox"
						// @ts-ignore
						checked={store.hasChosen(row)}
						onChange={(e) => {
							e.stopPropagation();
						}}
					/>
				),
			});
		});
	}

	return (
		<KodenDialog
			id={id}
			title={title}
			showOkButton={isMultiple}
			showCancelButton={isMultiple}
			titleActions={
				<ControlPanel>
					<div>{label}</div>
					<div>{titleActions}</div>
					{hasSearch && <KodenSearch borderColor="#EAEAEA" defaultValue={search || ""} onChange={setSearch} />}
				</ControlPanel>
			}
			open={open}
			onClose={() => onClose()}
			disabledOk={false}
			isLoading={isLoading}
			onOk={() => {
				onOk(getChooseSet());
			}}
			maxWidth={false}
		>
			<MooGraphQLTable
				operationName={`moo-dialog-choose${_.upperFirst(resource)}`}
				// @ts-ignore
				onRowClick={(row: T) => {
					if (isMultiple) {
						toggleChoose(row);
					} else {
						onOk([row]);
						onClose();
					}
				}}
				query={query}
				variables={variables}
				mapData={(e) => {
					const results = mapResults(e[resource], e);

					return {
						results,
						total_count: _.get(e, `${resource}_aggregate.aggregate.count`, 0),
					};
				}}
				headers={headers}
				cells={cells}
				page={page}
				onPageChange={setPage}
				rowsPerPage={limit}
			/>
		</KodenDialog>
	);
};
