export enum Value {
	PENDING = "PENDING",
	CONFIRMED = "CONFIRMED",
}

export function getValues(): Value[] {
	return [Value.PENDING, Value.CONFIRMED];
}

export enum Label {
	PENDING = "未確認",
	CONFIRMED = "確認",
}

export function getLabel(value: Value): Label {
	switch (value) {
		case Value.PENDING:
			return Label.PENDING;
		case Value.CONFIRMED:
			return Label.CONFIRMED;
	}
	return Label.PENDING;
}
