import { Value } from "./Value";

export enum Label {
	Sea = "海運",
	Air = "空運",
}

export function get(value: Value): Label {
	switch (value) {
		case Value.Air:
			return Label.Air;
		case Value.Sea:
		default:
			return Label.Sea;
	}
}
