import { object, SchemaOf } from "yup";
import { arrayOptionalSchema, statusSchema, stringOptional, stringRequired } from "../../moo-schemas";
import { ForeignManufacturer } from "../@types/foreign-manufacturer";
import { accountSchema } from "./accountSchema";
import { telSchema } from "./telSchema";

// @ts-ignore
export const schema: SchemaOf<ForeignManufacturer> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: statusSchema,
	type_id: stringRequired("type_id"),
	name: stringRequired("name"),
	fax: stringOptional,
	email: stringOptional,
	tels: arrayOptionalSchema(telSchema),
	accounts: arrayOptionalSchema(accountSchema),
	address: stringOptional,
	website: stringOptional,
	note: stringOptional,
	country: stringOptional,
	currency: stringOptional,
});
