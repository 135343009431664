import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class SvcTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "svcs";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "state_accountant":
			case "type":
			case "from_date":
			case "to_date":
			case "customer_id":
			case "sales_id":
			case "owner_id":
			case "address":
			case "description":
			case "note":
			case "driver_logistics_manufacturer_id":
			case "driver_id":
			case "price":
			case "cost":
			case "receivables":
			case "received":
				return true;
		}
		return false;
	}
}
