import { ItemType } from "../../moo-enums";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import { DEFAULT_QUERY } from "../stores/DEFAULT_QUERY";
import useStore from "../stores/useStore";

export function ItemTypeSelect() {
	const { query, setQuery } = useStore();

	return (
		<MooTypeableSelect
			id="type"
			label="商品類型"
			options={[
				{ value: ItemType.Spec, label: "規格商品" },
				{ value: ItemType.Custom, label: "客製商品" },
			]}
			value={query.type}
			error=""
			// @ts-ignore
			onChange={(type: ItemType) => {
				setQuery({ ...DEFAULT_QUERY, type });
			}}
		/>
	);
}
