import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class Table extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "item_specs";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "manufacturer_type":
			case "manufacturer_id":
			case "owner_id":
			case "category_id":
			case "sub_category_id":
			case "buy_year":
			case "name":
			case "material":
			case "color":
			case "color_image":
			case "size":
			case "note":
			case "purchase_price":
			case "cost":
			case "price":
			case "original_code":
			case "country":
				return true;
		}
		return false;
	}
}
