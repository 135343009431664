import { gql } from "@apollo/client";
import { useGraphQLQuery } from "../../moo-graphql-provider";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";

type Props = {
	value: string | null;
	onChange: (value: string | null) => void;
};

export function ManufacturerSelect({ value, onChange }: Props) {
	const query = gql`
		query ($where: import_goods_bool_exp) {
			import_goods(where: $where) {
				manufacturer {
					id
					name
				}
			}
		}
	`;
	const { isLoading, data } = useGraphQLQuery<{ import_goods: { manufacturer: { id: string; name: string } }[] }>({
		operationName: "import_goods",
		query,
		variables: {
			where: {
				manufacturer_id: {
					_is_null: false,
				},
			},
		},
	});

	const options = [
		{
			value: null,
			label: "請選擇",
		},
	].concat(
		data?.import_goods.map((importGood) => ({
			value: importGood.manufacturer.id,
			label: importGood.manufacturer.name,
		})) || ([] as any),
	);

	return (
		<MooTypeableSelect
			id="manufacturer"
			isLoading={isLoading}
			disabled={isLoading}
			options={options}
			label="廠商"
			value={value}
			onChange={onChange}
		/>
	);
}
