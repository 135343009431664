import { FetchedRow, Row } from "./@types";

export function mapResults(fetchedRows: FetchedRow[] = []) {
	return fetchedRows.map((fetchedRow: FetchedRow) => {
		const row: Row = {
			id: fetchedRow.id,
			sales: fetchedRow.sales?.name || "",
			manufacturer: fetchedRow?.foreign_manufacturer?.name || "",
			state_purchase: fetchedRow.state_purchase,
			order_ids: fetchedRow.order_ids?.map((e) => e.value),
		};
		return row;
	});
}
