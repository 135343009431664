import { DocumentNode } from "@apollo/client";
import { useQuery } from "react-query";
import { useGraphQLRequest } from "./useGraphQLRequest";

type Props = {
	operationName: string;
	query: DocumentNode;
	variables: Record<any, any>;
};

export function getGraphQLQueryKey({ operationName, query, variables }: Props): string {
	return `${operationName}-${JSON.stringify(query)}-${JSON.stringify(variables)}`;
}

export function useGraphQLQuery<T>({ operationName, query, variables }: Props) {
	const graphQLRequest = useGraphQLRequest();
	const key = getGraphQLQueryKey({ operationName, query, variables });
	return useQuery<T>(key, () => graphQLRequest({ query, variables }));
}
