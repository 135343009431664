import { KodenUploadButton } from "@yachelee/koden-components/koden-upload-button";
import { FC } from "react";

type Props = {
	onFileUploaded: (id: string, src: string) => void;
	onFileUploading: (src: string, loading: boolean) => void;
	image: boolean;
	video: boolean;
	acceptAny: boolean;
	maxInMB: number;
	tooBigMessage: string;
};

export const UploadButton: FC<Props> = ({
	onFileUploaded,
	onFileUploading,
	image,
	video,
	acceptAny,
	maxInMB,
	tooBigMessage = "",
}) => {
	return (
		<KodenUploadButton
			maxInMB={maxInMB}
			image={image}
			video={video}
			acceptAny={acceptAny}
			multiple
			onFileTooBig={() => window.alert(tooBigMessage)}
			onFileUploadReady={({ id }) => {
				onFileUploading(id, true);
			}}
			onFileUploading={({ id }) => {
				onFileUploading(id, true);
			}}
			onFileUploaded={({ id, src }) => {
				onFileUploaded(id, src);
			}}
			onFileUploadFailed={(e) => {
				onFileUploading(e.id, false);
				window.alert("上傳失敗");
			}}
			onDropRejected={() => {
				window.alert("目前不接受此檔案格式！");
			}}
		>
			上傳檔案
		</KodenUploadButton>
	);
};

export default UploadButton;
