import { KodenAppProgressbar } from "@yachelee/koden-components/koden-app-progressbar";
import { KodenLayout } from "@yachelee/koden-components/koden-layout";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useOnLinkClick } from "../../hooks/useOnLinkClick";
import { path as homePath } from "../../routes/HomeRoute";
import Utils from "../../utils";
import Breadcrumbs from "../Breadcrumbs";
import { MooArchiveProvider } from "../moo-components/moo-archive-provider";
import { Branch } from "../moo-components/moo-branch-provider";
import { MooEmployeeIdProvider } from "../moo-components/moo-employee-id-provider";
import { MooLinkProvider } from "../moo-components/moo-link-provider";
import { MooMeBranchSelectField } from "../moo-components/moo-me-branch-select-field";
import { MooMeProvider, useMe } from "../moo-components/moo-me-provider";
import BranchProvider from "./BranchProvider";
import { useBranchInQuery } from "./hooks/useBranchInQuery";
import useNavigations from "./hooks/useNavigations";

const BranchSelectFieldContainer = styled.div`
	.MuiOutlinedInput-root {
		background-color: white;
	}
`;

const ChildrenContainer = styled.div`
	width: 100%;

	> *:first-child {
		margin-bottom: 2rem;
	}
`;

function Content({ children }: { children: ReactNode }) {
	const me = useMe()!;
	const branch = useBranchInQuery();
	const defaultBranch = me.employee.default_branch_id;
	const [drawerOpen, setDrawerOpen] = useState(false);
	const navigations = useNavigations();
	const navigate = useNavigate();

	if (branch === null || !(branch in Branch)) {
		Utils.locationHref(`/?branch=${defaultBranch}`);
	}

	return (
		<>
			<KodenAppProgressbar />
			<KodenLayout
				isWebIcon={false}
				headerBackgroundColor="#C4A470"
				avatarDisplayName={me.display_name}
				avatarColor={me.color}
				drawerOpen={drawerOpen}
				onDrawerClose={() => {
					setDrawerOpen(false);
				}}
				sidebarOpen
				onHamburgerClick={() => {
					setDrawerOpen(true);
				}}
				list={navigations}
				title="Moos"
				titleNode={
					<div
						title="Moo's ERP"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img width={49} height={49} src={Utils.getAbsoluteStaticUrl("icons/logo.svg")} alt="Moo's ERP" />
						<div style={{ marginLeft: 20 }}>
							<img width={128} height={15} src={Utils.getAbsoluteStaticUrl("icons/moo_text.svg")} alt="moo" />
						</div>
					</div>
				}
				branchNode={
					<BranchSelectFieldContainer>
						<MooMeBranchSelectField />
					</BranchSelectFieldContainer>
				}
				onTitleClick={async () => {
					await navigate(`${homePath}?branch=${branch}`);
				}}
			>
				<MooEmployeeIdProvider onLinkClick={console.log}>
					<MooArchiveProvider
						onArchived={() => {
							window.alert("封存成功");
							Utils.refreshPage();
						}}
					>
						<ChildrenContainer>
							<Breadcrumbs />
							{children}
						</ChildrenContainer>
					</MooArchiveProvider>
				</MooEmployeeIdProvider>
			</KodenLayout>
		</>
	);
}

function MainLayout({ children }: { children: ReactNode }) {
	const onLinkClick = useOnLinkClick();
	const me = Utils.prefetchedMe!;

	return (
		<MooLinkProvider onLinkClick={onLinkClick}>
			<MooMeProvider value={me}>
				{me.id !== "" && (
					<BranchProvider>
						<Content>{children}</Content>
					</BranchProvider>
				)}
			</MooMeProvider>
		</MooLinkProvider>
	);
}

export default MainLayout;
