import { getItemType } from "../../moo-shared";
import { Item } from "../../moo-stock-transfer-one";
import { RowItem } from "./RowItem";

export function mapperItems(items: RowItem[]): Item[] {
	return items.map(({ stock_transfer_item_id, id, item_spec, item_custom, transfer_count }, priority) => {
		const item = item_custom || item_spec;

		return {
			stock_transfer_item_id,
			id,
			type: getItemType(id),
			category: item.category.name,
			manufacturer_type: item.manufacturer.type,
			manufacturer_id: item.manufacturer.id,
			manufacturer: item.manufacturer.name,
			name: item.name,
			image: item.image,
			image_cut: item.image_cut,
			material: item.material,
			color: item.color,
			size: item.size,
			purchase_price: item.purchase_price,
			price: item.price,
			cost: item.cost,
			priority,
			transfer_count,
		};
	});
}
