import { MooGqlTable } from "../../moo-gql";
import { Row, Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class RoleTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "roles";
	}

	isColumn(column: keyof Row): boolean {
		switch (column) {
			case "name":
				return true;
		}
		return false;
	}
}
