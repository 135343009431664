import { order } from "../moo-enums";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

export type MooOrderStateAccountantSelectProps = {
	value: order.StateAccountant.Value;
	onChange: (value: order.StateAccountant.Value) => void;
	disabled?: boolean;
	label?: string;
	error?: string;
};

export function MooOrderStateAccountantSelect({
	value,
	label = "",
	error = "",
	onChange,
	disabled = false,
}: MooOrderStateAccountantSelectProps) {
	return (
		<MooTypeableSelect
			id="state_accountant"
			label={label}
			value={value}
			error={error}
			disabled={disabled}
			options={[
				{
					label: "請選擇",
					value: null,
				},
				{
					label: order.StateAccountant.Label.UnSettled,
					value: order.StateAccountant.Value.UnSettled,
				},
				{
					label: order.StateAccountant.Label.Settled,
					value: order.StateAccountant.Value.Settled,
				},
				{
					label: order.StateAccountant.Label.DepositCancelled,
					value: order.StateAccountant.Value.DepositCancelled,
				},
			]}
			onChange={(value) => {
				onChange(value as order.StateAccountant.Value);
			}}
		/>
	);
}
