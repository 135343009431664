import { Status } from "../moo-enums";
import { Form } from "./index";

export function DEFAULT_ONE(): Form {
	return {
		status: Status.Present,
		default_branch_id: null,
		type_id: null,
		role_id: null,
		name: "",
		nick_name: "",
		birthday: new Date(),
		tel: "",
		email: "",
		line: "",
		contact_name: "",
		contact_tel: "",
		address: "",
		onboard_date: new Date(),
		salary_basic: 0,
		salary_increased_date: new Date(),
		bank_account: "",
		bank: "",
		bank_account_number: "",
		labor_fee: 0,
		healthcare_fee: 0,
		insurance_day: 30,
		insurance_unit: "",
		insurance_fee: 0,
		note: "",
	};
}
