import type { CellType } from "@yachelee/koden-components/koden-table";
import { defectiveProduct } from "../moo-enums";
import { Row } from "./@types";

export function useCells(): CellType[] {
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "state",
			align: "left",
			component: ({ state }: Row) => {
				return <div>{defectiveProduct.State.getLabel(state)}</div>;
			},
		},
	];
}
