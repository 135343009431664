import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    
    employee_id
    voucher_date
    debit_total
    credit_total
    
    rows {
        voucher_row_id
        credit_amount
        debit_amount
        link_id
        manufacturer_type
        manufacturer_id
        account_type {
            id
            name
            id_number
        }
        summary
        type
        order
    }
`;

export const query = gql`
    query ($id: String!) {
        vouchers_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
