import { SnackbarProvider } from "notistack";
import { ReactNode } from "react";

export type MooNotificationProviderProps = {
	children: ReactNode;
};

export function MooNotificationProvider({ children }: MooNotificationProviderProps) {
	return (
		//@ts-ignore
		<SnackbarProvider
			maxSnack={3}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
		>
			{children}
		</SnackbarProvider>
	);
}
