import styled from "@emotion/styled";
import produce from "immer";
import { FC, useState } from "react";
import { Files } from "./Files";
import UploadButton from "./UploadButton";

export type Props = {
	values: string[];
	label: string;
	maxInMB?: number;
	image?: boolean;
	video?: boolean;
	acceptAny?: boolean;
	tooBigMessage?: string;
	onChange?: (values: string[]) => void;
};

const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	background-color: #f2f3f5;
	display: grid;
	grid-gap: 0.5rem;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		". ."
		"file file";
	align-items: center;
	padding: 1.5rem;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: auto auto 1fr;
		grid-template-areas: ". . file";
	}
`;

export type ItemType = {
	loading: boolean;
	src: string;
	done: boolean;
};
export type QueueRecordType = Record<string, ItemType>;

function toQueueRecord(values: Props["values"]): QueueRecordType {
	return values.reduce((prev, src) => {
		return { ...prev, [src]: { src, loading: false, done: true } };
	}, {});
}

export const MooFilesUploadField: FC<Props> = ({
	values: propValues,
	label,
	maxInMB = 100,
	image = true,
	video = true,
	acceptAny = true,
	tooBigMessage = "請上傳檔案小於100MB的檔案",
	onChange: propsOnChange,
}) => {
	const [values, setValues] = useState<QueueRecordType>(toQueueRecord(propValues));

	const onChange = (newValues: QueueRecordType) => {
		const hasLoadingFile = Object.values(newValues).find((e) => e.loading) !== undefined;
		const hasUndoneFile = Object.values(newValues).find((e) => !e.done) !== undefined;

		if (!hasLoadingFile && !hasUndoneFile && propsOnChange) {
			propsOnChange(Object.values(newValues).map((e) => e.src));
		}
	};

	return (
		<Container>
			<div style={{ marginRight: ".5rem" }}>{label}</div>
			<UploadButton
				tooBigMessage={tooBigMessage}
				maxInMB={maxInMB}
				video={video}
				image={image}
				acceptAny={acceptAny}
				onFileUploading={(id, loading) => {
					setValues((values) => {
						return produce(values, (draft) => {
							if (!draft[id]) {
								draft[id] = { loading: false, src: "", done: false };
							} else {
								draft[id].loading = loading;
								draft[id].done = false;
							}
						});
					});
				}}
				onFileUploaded={(id, src) => {
					setValues((values) => {
						const newValues = produce(values, (draft) => {
							draft[id] = { loading: false, done: true, src };
						});
						onChange(newValues);
						return newValues;
					});
				}}
			/>
			<Files
				values={values}
				onDelete={(id) => {
					setValues((values) => {
						const newValues = produce(values, (draft) => {
							delete draft[id];
						});
						onChange(newValues);
						return newValues;
					});
				}}
			/>
		</Container>
	);
};

export default MooFilesUploadField;
