import { Row } from "./@types";

export function mapResults(import_goods = []) {
	return import_goods.map(({ id, sales, import_date, manufacturer, state, state_accountant, link_id, warehouse }) => {
		const row: Row = {
			id,
			// @ts-ignore
			sales_name: sales.name || "",
			// @ts-ignore
			manufacturer: manufacturer?.name || "",
			import_date,
			state,
			state_accountant,
			// @ts-ignore
			name: warehouse.name,
			link_id,
		};
		return row;
	});
}
