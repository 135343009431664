import * as _ from "lodash";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { ListSelectField } from "../moo-list-select-field";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";
import { Query } from "./@types";
import { useCells } from "./hooks/useCells";
import { useConditions } from "./hooks/useConditions";
import { useHeaders } from "./hooks/useHeaders";

export type MooEmployeeListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
	onActivateEmployee: () => void;
};

const resource = "employees";

export const MooEmployeeList = ({ onAdd, onRowClick, store, onActivateEmployee }: MooEmployeeListProps) => {
	const dataStore = store();
	const cells = useCells(resource, dataStore, onActivateEmployee);
	const headers = useHeaders();
	const conditions = useConditions(dataStore.query);

	const permissions = usePermissions();
	if (!permissions.includes(Permission.EmployeeListRead)) {
		return <MooNoAuthPage />;
	}
	const { query, setQuery } = dataStore;

	return (
		<MooList
			showAddButton={permissions.includes(Permission.EmployeeAdd)}
			resource={resource}
			conditions={conditions}
			graphQLColumns={`{
				id
				number
				name
				nick_name
				tel
				line
				email
				status
				employee_type{
					name
				}
			}`}
			addLabel="新增員工"
			onAdd={onAdd}
			mapData={(e) => ({ ...e, type: _.get(e, "employee_type.name") })}
			filterNode={
				<>
					<ListSelectField
						id="employee_types"
						resource="employee_types"
						label="類型"
						value={query.type}
						onChange={(value) => setQuery({ type: value as string, page: 0 })}
					/>
					<MooTypeableSelect
						id="status"
						error=""
						label="狀態"
						options={[
							{ value: null, label: "請選擇" },
							{ value: "PRESENT", label: "使用中" },
							{ value: "ARCHIVED", label: "已封存" },
						]}
						value={query.status}
						onChange={(status) => setQuery({ status: status as Query["status"], page: 0 })}
					/>
				</>
			}
			headers={headers}
			cells={cells}
			clear={dataStore.clear}
			query={dataStore.query}
			setQuery={dataStore.setQuery}
			onRowClick={onRowClick}
		/>
	);
};
