import { FC } from "react";
import { DEFAULT_ONE, LogisticsManufacturer, MooManufacturerLogisticsOne } from "../moo-manufacturer-logistics-one";
import { Permission, usePermissions } from "../moo-me-provider";
import { useHasuraAdd } from "../moo-shared";

export type MooManufacturerLogisticsAddProps = {
	onCancel: () => void;
	onSuccess: (id: string) => void;
};

export const MooManufacturerLogisticsAdd: FC<MooManufacturerLogisticsAddProps> = ({ onCancel, onSuccess }) => {
	const onAdd = useHasuraAdd<LogisticsManufacturer>("manufacturer_logistics", onSuccess, (e) => e, false);
	const permissions = usePermissions();

	return (
		<MooManufacturerLogisticsOne
			isEdit={false}
			isCancelDisabled={false}
			isOkDisabled={!permissions.includes(Permission.ManufacturerLogisticsAdd)}
			onCancel={onCancel}
			initialValues={DEFAULT_ONE()}
			onSubmit={onAdd}
			okText="送出"
		/>
	);
};
