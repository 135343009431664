import { MooDialogChoose } from "../moo-dialog-choose";
import { getSearchCondition, graphQLColumns, mapResults, useCells, useHeaders } from "../moo-request-buy-list";
import { Row } from "./@types";
import { useStore } from "./stores/useStore";

export type MooDialogRequestBuyChooseProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	onOk: (data: Row[]) => void;
	isMultiple: boolean;
};

export function MooDialogRequestBuyChoose({ id, open, onClose, onOk, isMultiple }: MooDialogRequestBuyChooseProps) {
	const headers = useHeaders();
	const cells = useCells();
	const store = useStore();

	return (
		<MooDialogChoose
			id={id}
			label=""
			title="國外請購單"
			resource="request_buys"
			open={open}
			onOk={(dataSet) => {
				onOk(dataSet as Row[]);
				onClose();
			}}
			onClose={onClose}
			where={getSearchCondition(store.search)}
			headers={headers}
			cells={cells}
			store={store}
			mapResults={mapResults}
			graphQLColumns={graphQLColumns}
			isMultiple={isMultiple}
		/>
	);
}
