import { MooEnumCrud } from "../moo-enum-crud";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export function MooWarehouseCrud() {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.WarehouseWrite)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooEnumCrud
			resource="warehouses"
			title="倉庫"
			addColumns={[
				{
					id: "name",
					defaultValue: "",
					label: "倉庫名",
					autoFocus: true,
					placeholder: "內湖倉庫",
					emptyError: "",
				},
			]}
			listBackgroundColor="#EAEAEA"
			listColumns={[
				{
					id: "name",
					defaultValue: "",
				},
			]}
		/>
	);
}
