export enum Value {
	UnConfirmed = "UNCONFIRMED",
	Confirmed = "CONFIRMED",
}

export enum Label {
	UnConfirmed = "未確認",
	Confirmed = "已修改訂購單",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.UnConfirmed:
			return Label.UnConfirmed;
		case Value.Confirmed:
			return Label.Confirmed;
	}
	return Label.UnConfirmed;
}
