import { object, SchemaOf } from "yup";
import { ItemType, manufacturer } from "../../moo-enums";
import { enumRequired, numberOptional, stringOptional, stringRequired } from "../../moo-schemas";
import { Item } from "../@types/one";

// @ts-ignore
export const itemSchema: SchemaOf<Item> = object().shape({
	defective_product_item_id: stringRequired("defective_product_item_id"),
	id: stringRequired("id"),
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer_id: stringRequired("manufacturer_id"),
	manufacturer: stringRequired("manufacturer"),
	category: stringOptional,
	type: enumRequired(ItemType),
	name: stringOptional,
	cost: numberOptional,
	spec: stringOptional,
	material: stringOptional,
	color: stringOptional,
	size: stringOptional,
	count: numberOptional,
	price: numberOptional,
	price_final: numberOptional,
	price_total: numberOptional,
	purchase_price: numberOptional,
	purchase_price_total: numberOptional,
	note: stringOptional,
	image: stringOptional,
	image_cut: stringOptional,
	priority: numberOptional,
});
