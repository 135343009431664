import produce from "immer";
import { One } from "../../@types/one";
import { useForm } from "../useForm";

export function useDeleteItem() {
	const { setValues, values } = useForm();

	return (id: string) => {
		setValues(
			produce(values, (one: One) => {
				const index = one.items.findIndex((e) => e.id === id);
				if (index !== -1) {
					one.items.splice(index, 1);
				}
			}),
		);
	};
}
