import { MooGqlChildNewTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";

export class Accounts extends MooGqlChildNewTable<FindOneRecord> {
	getIdName(): string {
		return "manufacturer_foreign_account_id";
	}

	getTableName(): string {
		return "manufacturer_foreign_accounts";
	}

	getPathName(): string {
		return "accounts";
	}

	getParentIdName(): string {
		return "manufacturer_foreign_id";
	}

	getToUpdateColumns(): string[] {
		return ["bank_account", "swift_code", "code_iban"];
	}
}
