import { MooGqlChildNewTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";

export class CustomerTelsTable extends MooGqlChildNewTable<FindOneRecord> {
	getIdName(): string {
		return "customer_tel_id";
	}

	getTableName(): string {
		return "customer_tels";
	}

	getPathName(): string {
		return "tels";
	}

	getParentIdName(): string {
		return "customer_id";
	}

	getToUpdateColumns(): string[] {
		return ["tel"];
	}
}
