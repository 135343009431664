import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { FC } from "react";
import { useItemDialogChoose } from "../../../moo-dialog-item-choose";
import { useChooseItems } from "../../hooks/useChooseItems";
import ItemList from "./ItemList";

export const ChooseItemArea: FC = () => {
	const chooseItems = useChooseItems();
	const { open: openDialog, component } = useItemDialogChoose("order-dialog-choose-1", async (rows) => {
		await chooseItems(rows);
	});
	return (
		<>
			{component}
			<div style={{ marginBottom: "2rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>訂貨商品</h1>
					<KodenIconTextButton
						text="匯入訂貨商品"
						onClick={() => {
							openDialog();
						}}
					/>
				</header>
				<ItemList />
			</div>
		</>
	);
};
