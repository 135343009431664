import { StoreType } from "../moo-hooks";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query } from "./@types";
import { QueryContext } from "./QueryContext";
import { RoleList } from "./RoleList";

export type MooRoleListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const rowsPerPage = 10;
export const operationName = "roles";

export const MooRoleList = ({ onAdd, onRowClick, store }: MooRoleListProps) => {
	const theStore = store();
	const { query } = theStore;
	const permissions = usePermissions();

	if (!permissions.includes(Permission.RoleListRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<QueryContext.Provider value={{ query }}>
			<RoleList store={theStore} onAdd={onAdd} onRowClick={onRowClick} />
		</QueryContext.Provider>
	);
};
