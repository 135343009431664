import type { CellType } from "@yachelee/koden-components/koden-table";
import { Row } from "./@types";

export function useCells(): CellType[] {
	return [
		{
			id: "name",
			align: "left",
			component: ({ name }: Row) => {
				return <div>{name}</div>;
			},
		},
	];
}
