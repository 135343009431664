export enum Value {
	Unapproved = "UNAPPROVED",
	Approved = "APPROVED",
	Return = "RETURN",
}

export function getValues(): Value[] {
	return [Value.Unapproved, Value.Approved, Value.Return];
}

export enum Label {
	Unapproved = "未批准",
	Approved = "批准",
	Return = "退回",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.Unapproved:
			return Label.Unapproved;
		case Value.Approved:
			return Label.Approved;
		case Value.Return:
			return Label.Return;
	}
	return Label.Unapproved;
}
