import type { CellType } from "@yachelee/koden-components/koden-table";
import { DollarNumber } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	return [
		{
			id: "employee_name",
			align: "left",
			component: ({ employee_name }: Row) => {
				return <div>{employee_name}</div>;
			},
		},
		{
			id: "total_price",
			align: "left",
			component: ({ total_price }: Row) => {
				return <DollarNumber>{total_price}</DollarNumber>;
			},
		},
		{
			id: "cost",
			align: "left",
			component: ({ cost }: Row) => {
				return <DollarNumber>{cost}</DollarNumber>;
			},
		},
		{
			id: "driver_fee",
			align: "left",
			component: ({ driver_fee }: Row) => {
				return <DollarNumber>{driver_fee}</DollarNumber>;
			},
		},
		{
			id: "commission_refund",
			align: "left",
			component: ({ commission_refund }: Row) => {
				return <DollarNumber>{commission_refund}</DollarNumber>;
			},
		},
		{
			id: "tax_price",
			align: "left",
			component: ({ tax_price }: Row) => {
				return <DollarNumber>{tax_price}</DollarNumber>;
			},
		},
		{
			id: "mgt_cost",
			align: "left",
			component: ({ mgt_cost }: Row) => {
				return <DollarNumber>{mgt_cost}</DollarNumber>;
			},
		},
		{
			id: "handling_fee",
			align: "left",
			component: ({ handling_fee }: Row) => {
				return <DollarNumber>{handling_fee}</DollarNumber>;
			},
		},
		{
			id: "miscellaneous_charge",
			align: "left",
			component: ({ miscellaneous_charge }: Row) => {
				return <DollarNumber>{miscellaneous_charge}</DollarNumber>;
			},
		},
		{
			id: "revenue",
			align: "left",
			component: ({ revenue }: Row) => {
				return <DollarNumber>{revenue}</DollarNumber>;
			},
		},
		{
			id: "bonus_percentage",
			align: "left",
			component: ({ bonus_percentage }: Row) => {
				return bonus_percentage;
			},
		},
		{
			id: "bonus",
			align: "left",
			component: ({ bonus }: Row) => {
				return <DollarNumber>{bonus}</DollarNumber>;
			},
		},
		{
			id: "custom_bonus",
			align: "left",
			component: ({ custom_bonus }: Row) => {
				return <DollarNumber>{custom_bonus}</DollarNumber>;
			},
		},
	];
}
