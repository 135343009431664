import produce from "immer";
import { getModelItemId } from "../../moo-shared";
import { Item, One } from "../@types/one";
import { useForm } from "./useForm";

export function useUpdate() {
	const { initialValues, values, setValues } = useForm();

	// @ts-ignore
	async function addItems(rows) {
		setValues(
			produce(values, (one: One) => {
				// @ts-ignore
				one.items = rows.map((row, priority) => {
					const id = row.id;

					const stockItem: Item = {
						stock_transfer_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: id }),
						id,
						type: row.type,
						category: row.category,
						manufacturer_type: row.manufacturer_type,
						manufacturer_id: row.manufacturer_id,
						manufacturer: row.manufacturer,
						name: row.name,
						image: row.image,
						image_cut: row.image_cut,
						material: row.material,
						color: row.color,
						size: row.size,
						purchase_price: row.purchase_price,
						price: row.price,
						cost: row.cost,
						priority,
						transfer_count: 1,
					};
					return stockItem;
				});
				return one;
			}),
		);
	}

	function updateItem(id: string, field: keyof Item, value: any) {
		setValues(
			produce(values, (one: One) => {
				const item = one.items.find((e) => e.id === id);
				if (item) {
					// @ts-ignore
					item[field] = value;
				}
				return one;
			}),
		);
	}

	function deleteItem(id: string) {
		setValues(
			produce(values, (one: One) => {
				const index = one.items.findIndex((e) => e.id === id);
				if (index !== -1) {
					one.items.splice(index, 1);
				}
			}),
		);
	}

	return {
		addItems,
		updateItem,
		deleteItem,
	};
}
