import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../../components/MainLayout/hooks/useBranchInQuery";
import { MooOrderList, Query, useCreateStore } from "../../components/moo-components/moo-order-list";
import { useHistoryQuery } from "../../hooks/useHistoryQuery";
import usePage from "../../hooks/usePage";
import useURLSearchParams from "../../hooks/useURLSearchParams";
import Utils from "../../utils";
import { path as NewRoutePath } from "./NewRoute";
import { prefixPath as UpdateRoutePath } from "./UpdateRoute";

export const path = "/order";

const useQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();
	const fromDate = urlSearchParams.get("from_date");
	const toDate = urlSearchParams.get("to_date");

	return {
		search: urlSearchParams.get("search") || null,
		page,
		sales_id: urlSearchParams.get("sales_id") || null,
		customer_id: urlSearchParams.get("customer_id") || null,
		from_date: Utils.isDateString(fromDate) ? new Date(fromDate!) : null,
		to_date: Utils.isDateString(toDate) ? new Date(toDate!) : null,
	};
};

export const ListRoute = () => {
	const branch = useBranchInQuery();
	const query = useQuery();
	const store = useCreateStore(query, {
		search: null,
		page: 0,
		sales_id: null,
		customer_id: null,
		from_date: null,
		to_date: null,
	});
	const navigate = useNavigate();
	useHistoryQuery<Query>({ path, store });

	return (
		<MooOrderList
			store={store}
			onAdd={async () => {
				await navigate(`${NewRoutePath}?branch=${branch}`);
			}}
			onRowClick={async ({ id }, event) => {
				event.preventDefault();
				await navigate(`${UpdateRoutePath}/${id}?branch=${branch}`);
			}}
		/>
	);
};

export default ListRoute;
