import { createContext, useContext } from "react";
import { MooImageListCrudProps } from "../../moo-image-list-crud";

export const context = createContext<MooImageListCrudProps>({
	resource: "",
	parentId: "",
});

export function useProps() {
	return useContext(context);
}
