import { useGetGcsUrl } from "@yachelee/koden-components/koden-gcs-provider";
import { defaultImage } from "./index";

type Props = {
	items: {
		id: string;
		image_cut: string | null;
	}[];
};

export const MOO_LOGO = "https://storage.googleapis.com/ya-che-moo-pdf/moo-logo.png";

export const logoImageMap = {
	logo: MOO_LOGO,
};

export function useImageMap({ items }: Props) {
	const getGcsUrl = useGetGcsUrl();

	const itemMap: Record<string, string> = items
		.map((e) => ({
			[e.id]: !e.image_cut ? defaultImage : getGcsUrl(e.image_cut),
		}))
		.reduce((prev, cur) => {
			return { ...prev, ...cur };
		}, logoImageMap);

	return itemMap;
}
