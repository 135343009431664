import { useNavigate, useParams } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooManufacturerForeignUpdate } from "../components/moo-components/moo-manufacturer-foreign-update";
import { path as listPath } from "./ForeignManufacturerListRoute";

export const prefixPath = "/manufacturer/foreign";
export const path = prefixPath + "/:id";

const UpdateForeignManufacturerRoute = () => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const branch = useBranchInQuery();

	return (
		<MooManufacturerForeignUpdate
			id={id!}
			onCancel={async () => {
				await navigate(`${listPath}?branch=${branch}`);
			}}
		/>
	);
};

export default UpdateForeignManufacturerRoute;
