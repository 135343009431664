import type { CellType } from "@yachelee/koden-components/koden-table";
import { svc } from "../moo-enums";
import { DateFormat } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	return [
		{
			id: "id",
			align: "left",
			component: ({ id }: Row) => {
				return <div>{id}</div>;
			},
		},
		{
			id: "from_date",
			align: "left",
			component: ({ from_date }: Row) => {
				return <DateFormat>{from_date}</DateFormat>;
			},
		},
		{
			id: "to_date",
			align: "left",
			component: ({ to_date }: Row) => {
				return <DateFormat>{to_date}</DateFormat>;
			},
		},
		{
			id: "owner_name",
			align: "left",
			component: ({ owner }: Row) => {
				return <div>{owner.name}</div>;
			},
		},
		{
			id: "sales_name",
			align: "left",
			component: ({ sales }: Row) => {
				return <div>{sales.name}</div>;
			},
		},
		{
			id: "customer_name",
			align: "left",
			component: ({ customer }: Row) => {
				return <div>{customer.name}</div>;
			},
		},
		{
			id: "type",
			align: "left",
			component: ({ type }: Row) => {
				return <div>{svc.Type.get(type)}</div>;
			},
		},
		{
			id: "state_accountant",
			align: "left",
			component: ({ state_accountant }: Row) => {
				return <div>{svc.StateAccountant.getLabel(state_accountant)}</div>;
			},
		},
	];
}
