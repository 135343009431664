import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class Table extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "allowances";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "state_accountant_id":
			case "state_accountant":
			case "state_manager":
			case "state_requester":
			case "request_date":
			case "requester_id":
			case "reason":
			case "amount":
			case "order_id":
				return true;
		}
		return false;
	}
}
