import { KodenButton } from "@yachelee/koden-components/koden-button";
import { Formik } from "formik";
import styled from "styled-components";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { MooSubmitButton } from "../moo-submit-button";
import { Props } from "./@types/props";
import { AccountantBlock } from "./blocks/AccountantBlock";
import { BasicBlock } from "./blocks/BasicBlock";
import { DocumentsBlock } from "./blocks/DocumentsBlock";
import { ItemsBlock } from "./blocks/ItemsBlock";
import { StateBlock } from "./blocks/StateBlock";
import { useForm } from "./hooks/useForm";
import { schema } from "./schemas/schema";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.form`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

export function Form({ isEdit = false, isCancelDisabled, onCancel }: Omit<Props, "onSubmit" | "initialValues">) {
	const { handleSubmit } = useForm();

	return (
		<Container onSubmit={handleSubmit}>
			{isEdit && <StateBlock />}
			<BasicBlock />
			<ItemsBlock />
			<DocumentsBlock />
			<AccountantBlock />
			<ButtonContainer>
				<KodenButton
					style={{ marginRight: "1rem" }}
					disabled={isCancelDisabled}
					onClick={async () => {
						if (window.confirm("確定取消並且回到上一頁？")) {
							onCancel();
						}
					}}
				>
					取消
				</KodenButton>
				<MooSubmitButton permission={Permission.PurchaseOrderUpdate} />
			</ButtonContainer>
		</Container>
	);
}

export function MooPurchaseOrderOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();

	if (!permissions.includes(Permission.PurchaseOrderRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
