import styled from "@emotion/styled";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import produce from "immer";
import { useForm } from "../hooks/useForm";
import { useSyncPrices } from "../hooks/useUpdate";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const HighlightFieldOuterContainer = styled.div`
	background-color: #f2f3f5;
`;

const HighlightFieldInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};
	background: #f2f3f5;
	border-radius: 0.2rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.5rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export function SummaryBlock() {
	const { values, setValues, setFieldValue } = useForm();
	const syncPrices = useSyncPrices();

	return (
		<HighlightFieldOuterContainer>
			<HighlightFieldInnerContainer>
				<KodenNumberField
					id="cost"
					value={values.cost}
					label="總成本"
					hasDollar
					outlined
					margin="none"
					error=""
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					onChange={(cost) => setFieldValue("cost", cost)}
				/>
				<KodenNumberField
					id="price"
					value={values.price}
					label="報價"
					hasDollar
					outlined
					margin="none"
					error=""
					readOnly
					disabled
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					onChange={(price) => {
						setValues(
							produce(values, (values) => {
								const toValue = { ...values, price };
								syncPrices(toValue);
							}),
						);
					}}
				/>
				<KodenNumberField
					id="received"
					value={values.received}
					label="已收費用"
					outlined
					hasDollar
					margin="none"
					error=""
					min={0}
					max={values.price}
					onChange={(received) => {
						const toValues = produce(values, (values) => {
							values.received = received;
							syncPrices(values);
						});
						setValues(toValues);
					}}
				/>
				<KodenNumberField
					id="receivables"
					value={values.receivables}
					label="應收費用"
					hasDollar
					outlined
					margin="none"
					readOnly
					disabled
					error=""
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					onChange={(value) => setFieldValue("receivables", value)}
				/>
			</HighlightFieldInnerContainer>
		</HighlightFieldOuterContainer>
	);
}
