import { Fragment } from "react";
import { useBranch } from "../moo-branch-provider";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Query } from "./@types";
import { useCells } from "./useCells";
import { useHeaders } from "./useHeaders";

export type MooWeeklyScheduleListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const graphQLColumns = `{
	id
	start_date
	end_date
	content
	messages_aggregate(where: {status: {_eq: "PRESENT"}}) {
		aggregate{
			count
		}
	}
}`;

// @ts-ignore
export function mapData(e) {
	return {
		...e,
		message_count: e?.messages_aggregate?.aggregate?.count || 0,
	};
}

export const MooWeeklyScheduleList = ({ onAdd, onRowClick, store }: MooWeeklyScheduleListProps) => {
	const itemStore = store();
	const { value: branch } = useBranch();
	const cells = useCells();
	const headers = useHeaders();
	const conditions = {
		branch_id: {
			_eq: branch,
		},
	};

	return (
		<MooList
			showAddButton={true}
			resource="schedules"
			addLabel="新增週計畫"
			onAdd={onAdd}
			filterNode={<Fragment />}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
			conditions={conditions}
			graphQLColumns={graphQLColumns}
			mapData={mapData}
		/>
	);
};
