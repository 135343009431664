import { gql } from "@apollo/client";
import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { MooGraphqlSelect } from "../../moo-graphql-select";
import { Query } from "../@types";

type Props = {
	query: Query;
	setQuery: (query: Partial<Query>) => void;
};

export function AccountTypeSelect({ query, setQuery }: Props) {
	const { value: branch } = useBranch();
	return (
		<MooGraphqlSelect
			label="科目代號"
			value={query.account_type as string}
			query={gql`
				query ($where: account_types_bool_exp, $distinct_on: [account_types_select_column!]) {
					account_types(where: $where, distinct_on: $distinct_on) {
						id_number
						branch_id
					}
				}
			`}
			variables={{
				where: {
					branch_id: {
						_eq: branch,
					},
					status: {
						_eq: Status.Present,
					},
				},
				distinct_on: ["id_number"],
			}}
			operationName="account_types"
			id="account_types"
			//@ts-ignore
			mapData={({ account_types }) => {
				const results = [
					{
						id: "default",
						value: null,
						label: "請選擇",
					},
				].concat(
					// @ts-ignore
					account_types.map(({ id_number }) => ({
						id: id_number,
						value: id_number,
						label: id_number,
					})),
				);
				return {
					results,
				};
			}}
			onChange={(account_type) => setQuery({ account_type, page: 0 })}
		/>
	);
}
