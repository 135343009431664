import { gql } from "@apollo/client";
import { FormikHelpers } from "formik";
import { useGraphQLRequest } from "../moo-graphql-provider";
import { One } from "../moo-schedule-one";

type Args = {
	id: string;
	toUpdateOne: One;
	initialValues: One;
	helpers: FormikHelpers<One>;
};

export function useUpdateSchedule() {
	const graphQLRequest = useGraphQLRequest();

	return async ({ id, initialValues, toUpdateOne }: Args) => {
		const initialIds = new Set(initialValues.links.map((e) => e.value));
		const toUpdateValues = new Set(toUpdateOne.links.map((e) => e.value));
		const toInsertValues = [...toUpdateValues].filter((e) => !initialIds.has(e));
		const toDeleteValues = [...initialIds].filter((e) => !toUpdateValues.has(e));
		const map = new Map(initialValues.links.map((e) => [e.value, e.schedule_link_id]));
		const toDeleteScheduleLinkIds = toDeleteValues.map((id) => {
			return map.get(id);
		});
		const hasToDeleteLinks = toDeleteScheduleLinkIds.length > 0;
		const hasToInsertLinks = toInsertValues.length > 0;

		const variables = {
			set: {
				start_date: toUpdateOne.start_date,
				end_date: toUpdateOne.end_date,
				content: toUpdateOne.content,
			},
			pk_columns: {
				id,
			},
		};

		if (hasToDeleteLinks) {
			// @ts-ignore
			variables.where_delete = {
				schedule_link_id: {
					_in: toDeleteScheduleLinkIds,
				},
			};
		}

		if (hasToInsertLinks) {
			// @ts-ignore
			variables.objects_insert = toInsertValues.map((value) => {
				return {
					schedule_id: id,
					value,
				};
			});
		}

		const mutations: string[] = ["$set: schedules_set_input", "$pk_columns: schedules_pk_columns_input!"];
		if (hasToDeleteLinks) {
			mutations.push("$where_delete: schedule_links_bool_exp!");
		}

		if (hasToInsertLinks) {
			mutations.push("$objects_insert: [schedule_links_insert_input!]!");
		}

		const str = `
				mutation (${mutations.join(",")}) {
					update_schedules_by_pk(_set: $set, pk_columns: $pk_columns) {
						start_date
						end_date
						content
					}
					${
						hasToDeleteLinks
							? `
							delete_schedule_links (where: $where_delete){
								affected_rows
							}
					`
							: ""
					}
					${
						hasToInsertLinks
							? `
							insert_schedule_links(objects: $objects_insert){
								affected_rows
							}
					`
							: ""
					}
				}
			`;

		return await graphQLRequest({
			query: gql`
				${str}
			`,
			variables,
		});
	};
}
