import ReactDOM from "react-dom/client";
import { App } from "./components/App";
import { Me } from "./components/moo-components/moo-me-provider";
import { createAjax, getAuthorizationHeader } from "./hooks/useAjax";
import { initSentry } from "./initSentry";
import Utils from "./utils";

function renderApp() {
	ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
}

async function initMe() {
	const accessToken = Utils.getAccessToken()!;
	const ajax = createAjax();
	const me: Me = (
		await ajax.get("/me", {
			headers: getAuthorizationHeader(accessToken),
		})
	).data;
	Utils.setPrefetchedMe(me);
}

async function initBranchInQuery() {
	const me = Utils.prefetchedMe!;
	const urlSearchParams = new URLSearchParams(window.location.search);
	if (!urlSearchParams.has("branch")) {
		urlSearchParams.append("branch", me.employee.default_branch_id);
		window.history.pushState("", "", `?${urlSearchParams.toString()}`);
	}
}

async function initAccessToken() {
	const accessToken = await Utils.getNewAccessToken();
	Utils.setAccessToken(accessToken);
}

(async () => {
	try {
		const refreshToken = Utils.getRefreshToken();
		if (refreshToken) {
			await initAccessToken();
			await initMe();
			await initBranchInQuery();
		}
	} catch (e) {
		Utils.deleteRefreshToken();
		console.error(e);
	} finally {
		initSentry();
		renderApp();
	}
})();
