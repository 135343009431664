import * as _ from "lodash";
import { array, object, SchemaOf } from "yup";
import { enumRequired, statusSchema, stringOptional, stringRequired } from "../../moo-schemas";
import { Customer, Gender } from "../@types/customer";
import { telSchema } from "./telSchema";

// @ts-ignore
export const schema: SchemaOf<Customer> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: statusSchema,
	type_id: stringRequired("type_id"),
	name: stringRequired("name"),
	gender: enumRequired(Gender),
	birthday: stringOptional,
	email: stringOptional,
	tels: array(telSchema).test("tels", "請提供", (items) => {
		// @ts-ignore
		return !(items.length === 0 || _.isEmpty(items[0].tel));
	}),
	line: stringOptional,
	job: stringOptional,
	vip: stringOptional,
	address: stringRequired("address"),
	bank_account: stringOptional,
	bank: stringOptional,
	bank_account_number: stringOptional,
	tax_id: stringOptional,
	note: stringOptional,
});
