import { constants } from "../constants";
import useStore from "../stores/useStore";
import { useGraphQLOrderBy } from "./useGraphQLOrderBy";
import { useGraphQLWhere } from "./useGraphQLWhere";

export function useGraphQLVariables() {
	const {
		query: { page },
	} = useStore();
	const where = useGraphQLWhere();
	const order_by = useGraphQLOrderBy();

	return {
		limit: constants.rowsPerPage,
		offset: page * constants.rowsPerPage,
		where,
		order_by,
	};
}
