export const graphQLColumns = `{
	id
	customer {
		name
	}
	description
	sales {
		name
	}
	state
	state_accountant
	state_manager
	state_warehouse
	total_price
	quotation_type {
		name
	}
	return_goods {
		id
	}
	employee {
		nick_name
	}
}`;
