import { manufacturer } from "../moo-enums";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

type ManufacturerType = manufacturer.Value.Value;

export type Props = {
	value: ManufacturerType | null;
	error: string;
	onChange: (value: ManufacturerType | null) => void;
};

export function ManufacturerTypeField({ value, error, onChange }: Props) {
	return (
		<MooTypeableSelect
			id="type"
			label="來源"
			options={[
				{
					value: null,
					label: "請選擇",
				},
				{
					value: manufacturer.Value.Value.Domestic,
					label: manufacturer.Label.Label.Domestic,
				},
				{
					value: manufacturer.Value.Value.Foreign,
					label: manufacturer.Label.Label.Foreign,
				},
			]}
			value={value}
			error={error}
			onChange={(value) => {
				onChange(value as ManufacturerType);
			}}
		/>
	);
}
