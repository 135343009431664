import { useState } from "react";
import { MooWarehouseStockList } from "../components/moo-components/moo-warehouse-stock-list";

export const path = "/warehouse/stocks";

export function WarehouseStockListRoute() {
	const [id, setId] = useState<string | null>(null);
	return <MooWarehouseStockList id={id} itemIds={[]} showItemIds onIdChange={setId} />;
}

export default WarehouseStockListRoute;
