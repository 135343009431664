import { LogisticsManufacturer, MooManufacturerLogisticsOne } from "../moo-manufacturer-logistics-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { Accounts } from "./updateOne/Accounts";
import { Drivers } from "./updateOne/Drivers";
import { Table } from "./updateOne/Table";

export type MooManufacturerLogisticsUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooManufacturerLogisticsUpdate({ id, onCancel }: MooManufacturerLogisticsUpdateProps) {
	return (
		<MooUpdateFormNew<Row, LogisticsManufacturer>
			id={id}
			onCancel={onCancel}
			operationName="manufacturer-logistic-one"
			query={query}
			tableName="manufacturer_logistics"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooManufacturerLogisticsOne}
			tables={[Accounts, Drivers, Table]}
		/>
	);
}
