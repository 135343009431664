import { KodenButton } from "@yachelee/koden-components/koden-button";
import { Formik } from "formik";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../ProxyKodenTextField";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { useGetFieldError } from "../moo-shared";
import { MooSubmitButton } from "../moo-submit-button";
import { One } from "./@types/one";
import { Props } from "./@types/props";
import { RoleTabs } from "./RoleTabs";
import { schema } from "./schemas/schema";
import { useForm } from "./useForm";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		padding: 2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;

export function Form({ isCancelDisabled, isOkDisabled, onCancel }: Partial<Props>) {
	const { handleSubmit, values, setFieldValue } = useForm();
	const getFieldError = useGetFieldError<One>();

	return (
		<form onSubmit={handleSubmit}>
			<Container>
				<ProxyKodenTextField
					id="name"
					label="名稱"
					outlined
					margin="none"
					value={values.name}
					error={getFieldError("name")}
					onChange={(e) => setFieldValue("name", e)}
				/>
				<RoleTabs />
				{!isOkDisabled && (
					<ButtonContainer>
						<KodenButton
							style={{ marginRight: "1rem" }}
							disabled={isCancelDisabled}
							onClick={async () => {
								if (window.confirm("確定取消並且回到上一頁？")) {
									if (onCancel) {
										onCancel();
									}
								}
							}}
						>
							取消
						</KodenButton>
						<MooSubmitButton permission={Permission.RoleUpdate} />
					</ButtonContainer>
				)}
			</Container>
		</form>
	);
}

export function MooRoleOne({ initialValues, onSubmit, ...props }: Props) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.RoleRead)) {
		return <MooNoAuthPage />;
	}

	return (
		<Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			<Form {...props} />
		</Formik>
	);
}
