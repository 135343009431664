import { object, SchemaOf } from "yup";
import { dateRequired, numberOptional, statusSchema, stringOptional, stringRequired } from "../../moo-schemas";
import { Form } from "../@types/form";

// @ts-ignore
export const schema: SchemaOf<Form> = object().shape({
	status: statusSchema,
	default_branch_id: stringRequired("default_branch_id"),
	type_id: stringRequired("type_id"),
	role_id: stringOptional,
	name: stringRequired("name"),
	nick_name: stringOptional,
	birthday: dateRequired("birthday"),
	tel: stringOptional,
	email: stringRequired("email"),
	line: stringOptional,
	contact_name: stringOptional,
	contact_tel: stringOptional,
	address: stringOptional,
	onboard_date: dateRequired("onboard_date"),
	salary_basic: numberOptional,
	salary_increased_date: dateRequired("salary_increased_date"),
	bank_account: stringOptional,
	bank: stringOptional,
	bank_account_number: stringOptional,
	labor_fee: numberOptional,
	healthcare_fee: numberOptional,
	insurance_day: numberOptional,
	insurance_unit: stringOptional,
	insurance_fee: numberOptional,
	note: stringOptional,
});
