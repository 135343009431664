import { DomesticManufacturer } from "../../moo-manufacturer-domestic-one";
import { Row } from "./Row";

export function mapperInitialValues(record: Row): DomesticManufacturer {
	return {
		create_date: record.create_date,
		update_date: record.update_date,
		status: record.status,
		type_id: record.type_id,
		name: record.name,
		fax: record.fax,
		email: record.email,
		tels: record.tels,
		accounts: record.accounts,
		address: record.address,
		website: record.website,
		note: record.note,
	};
}
