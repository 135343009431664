import { getLocaleString } from "../../moo-shared";
import { One } from "../../moo-stock-transfer-one";
import { Row } from "./Row";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Row): One {
	const source_warehouse_id = record.source_warehouse_id;
	const items = mapperItems(record.items);

	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		state: record.state,
		status: record.status,
		transfer_date: getLocaleString(new Date(record.transfer_date)),
		owner_id: record.owner_id,
		source_warehouse_id,
		target_warehouse_id: record.target_warehouse_id,
		items,
	};
}
