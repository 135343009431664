export enum Value {
	MAINTAIN = "MAINTAIN",
	TRANSPORT = "TRANSPORT",
	LEND = "LEND",
}

export enum Label {
	MAINTAIN = "維修",
	TRANSPORT = "運送",
	LEND = "租借",
}

export function get(value: Value): Label {
	switch (value) {
		case Value.TRANSPORT:
			return Label.TRANSPORT;
		case Value.LEND:
			return Label.LEND;
		case Value.MAINTAIN:
		default:
			return Label.MAINTAIN;
	}
}
