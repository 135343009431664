import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    
    type_id
    name
    fax
    email
    address
    website
    note
    number
    accounts {
      manufacturer_domestic_account_id
      bank_account
      bank
      bank_account_number
      tax_id
    }
    tels {
      manufacturer_domestic_tel_id
      manufacturer_domestic_id
      tel
    }
`;

export const query = gql`
    query ($id: uuid!) {
        manufacturer_domestics_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
