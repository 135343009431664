import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as AccountTypeRootRoutePath } from "../../../routes/AccountTypeRootRoute";
import { path as AccountTypeSubRoutePath } from "../../../routes/AccountTypeSubRoute";
import { path as AccountTypesRoutePath } from "../../../routes/AccountTypesRoute";
import { path as SalaryListRoutePath } from "../../../routes/SalaryListRoute";
import { path as UserPerformanceListRoutePath } from "../../../routes/account/AccountPerformanceListRoute";
import { path as UserPerformanceSubListRoutePath } from "../../../routes/account/AccountPerformanceSubListRoute";
import { path as AllowanceListRoutePath } from "../../../routes/allowance/ListRoute";
import { path as AllowanceNewRoutePath } from "../../../routes/allowance/NewRoute";
import { path as AllowanceUpdateRoutePath } from "../../../routes/allowance/UpdateRoute";
import { path as VoucherListRoutePath } from "../../../routes/voucher/ListRoute";
import { path as VoucherNewRoutePath } from "../../../routes/voucher/NewRoute";
import { path as VoucherUpdateRoutePath } from "../../../routes/voucher/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useAccountants(): ListType[] {
	const permissions = usePermissions();
	const historyPushWithBranch = useHistoryPushWithBranch();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();

	const children: any[] = [];
	if (permissions.includes(Permission.VoucherListRead)) {
		children.push({
			id: VoucherListRoutePath,
			txt: getPageTree()[VoucherListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([VoucherListRoutePath, VoucherUpdateRoutePath, VoucherNewRoutePath]),
			async onClick() {
				await historyPushWithBranch(VoucherListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.AllowanceListRead)) {
		children.push({
			id: AllowanceListRoutePath,
			txt: getPageTree()[AllowanceListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([AllowanceListRoutePath, AllowanceNewRoutePath, AllowanceUpdateRoutePath]),
			async onClick() {
				await historyPushWithBranch(AllowanceListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.PerformanceRead)) {
		children.push({
			id: UserPerformanceListRoutePath,
			txt: getPageTree()[UserPerformanceListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([UserPerformanceListRoutePath, UserPerformanceSubListRoutePath]),
			async onClick() {
				await historyPushWithBranch(UserPerformanceListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.SalaryRead)) {
		children.push({
			id: SalaryListRoutePath,
			txt: getPageTree()[SalaryListRoutePath].useText(),
			icon: "",
			isSelected: isSelected([SalaryListRoutePath]),
			async onClick() {
				await historyPushWithBranch(SalaryListRoutePath);
			},
		});
	}

	if (permissions.includes(Permission.AccountSubjectWrite)) {
		children.push({
			id: AccountTypesRoutePath,
			txt: getPageTree()[AccountTypesRoutePath].useText(),
			icon: "",
			isSelected: isSelected([AccountTypesRoutePath, AccountTypeRootRoutePath, AccountTypeSubRoutePath]),
			async onClick() {
				await historyPushWithBranch(AccountTypesRoutePath);
			},
		});
	}

	if (children.length === 0) {
		return [];
	}

	return [
		{
			id: "accountant",
			txt: "會計",
			icon: Utils.getAbsoluteStaticUrl("icons/accountant.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleAccountant();
			},
			open: true,
			openChildren: navigationStore.accountant,
			children,
		},
	];
}
