import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooAccountSubjectCrud } from "../components/moo-components/moo-account-subject-crud";
import { path as subPath } from "./AccountTypeSubRoute";

export const prefixPath = "/account_types";
export const path = prefixPath + "/:category";

const AccountTypeRootRoute = () => {
	const navigate = useNavigate();
	const branch = useBranchInQuery();
	const { category } = useParams<{ category: string }>();

	return (
		<MooAccountSubjectCrud
			category={category || ""}
			parent={null}
			canClick
			onClick={(id) => navigate(`${generatePath(subPath, { category, id })}?branch=${branch}`)}
		/>
	);
};

export default AccountTypeRootRoute;
