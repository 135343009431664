import { useBranch } from "../moo-branch-provider";
import { Query } from "./@types";
import { getSearchCondition } from "./getSearchCondition";

export function useConditions({ search }: Query) {
	const { value: branch } = useBranch();

	const conditions: Record<string, any> = {
		...getSearchCondition(search),
		branch_id: {
			_eq: branch,
		},
	};
	return conditions;
}
