import { getDollarString } from "@yachelee/koden-components/koden-number-field/getDollarString";
import { Type } from "../../moo-voucher-one";
import { ReturnData } from "./useGetData";

export function getTableRows(rows: ReturnData["rows"]): any[][] {
	return rows.map(({ date, account_type_id_number, type, amount, subject_id_number, subject_name, summary }) => {
		const amountString = getDollarString(amount, 0);
		const isDebit = type === Type.Debit;
		const isCredit = type === Type.Credit;
		const margin = [isCredit ? 5 : 0, 0, 0, 0];

		return [
			date,
			account_type_id_number,
			{
				text: subject_id_number,
				margin,
			},
			{
				text: subject_name,
				margin,
			},
			summary,
			{
				text: isDebit ? "借" : "貸",
				alignment: "center",
			},
			isDebit
				? {
						text: amountString,
						alignment: "left",
				  }
				: "",
			isCredit
				? {
						text: amountString,
						alignment: "right",
				  }
				: "",
		];
	});
}
