import { HeaderType } from "../../moo-dialog-choose";
import { useHeaders as useExportGoodsHeaders } from "../../moo-export-goods-list";
import { useHeaders as useImportGoodsHeaders } from "../../moo-import-goods-list";
import { useHeaders as useOrderGoodsHeaders } from "../../moo-order-goods-list";
import { useHeaders as useOrderHeaders } from "../../moo-order-list";
import { useHeaders as usePurchaseOrderHeaders } from "../../moo-purchase-order-list";
import { useHeaders as useQuotationHeaders } from "../../moo-quotation-list";
import { useHeaders as useRequestBuyHeaders } from "../../moo-request-buy-list";
import { useHeaders as useReturnGoodsHeaders } from "../../moo-return-goods-list";
import { LinkType } from "../@types";
import useStore from "../stores/useStore";

export function useHeaders(): HeaderType[] {
	const query = useStore();
	const quotationHeaders = useQuotationHeaders();
	const orderHeaders = useOrderHeaders();
	const importGoodsHeaders = useImportGoodsHeaders();
	const exportGoodsHeaders = useExportGoodsHeaders();
	const purchaseOrderHeaders = usePurchaseOrderHeaders();
	const returnGoodsHeaders = useReturnGoodsHeaders();
	const orderGoodsHeaders = useOrderGoodsHeaders();
	const requestBuyHeaders = useRequestBuyHeaders();

	switch (query.type) {
		case LinkType.Quotation: {
			return quotationHeaders;
		}
		case LinkType.Order: {
			return orderHeaders;
		}
		case LinkType.ImportGoods: {
			return importGoodsHeaders;
		}
		case LinkType.ExportGoods: {
			return exportGoodsHeaders;
		}
		case LinkType.PurchaseOrder: {
			return purchaseOrderHeaders;
		}
		case LinkType.ReturnGoods: {
			return returnGoodsHeaders;
		}
		case LinkType.OrderGoods: {
			return orderGoodsHeaders;
		}
		case LinkType.RequestBuy: {
			return requestBuyHeaders;
		}
		default: {
			return [];
		}
	}
}
