import { gql } from "@apollo/client";
import * as _ from "lodash";
import { MooGraphqlSelect } from "../moo-graphql-select";

type Props = {
	label: string;
	resource: string;
	disabled?: boolean;
	error?: string;
	value: string | null;
	onChange: (value: string | null) => void;
	whereConditions: string;
	query: string;
	resultValue: string;
	resultLabel: string;
};

export function MooGraphQLDistinctSelect({
	label,
	resource,
	query,
	disabled = false,
	value,
	onChange,
	error = "",
	whereConditions,
	resultValue,
	resultLabel,
}: Props) {
	const gqlQuery = gql`
      query ($distinct: [${resource}_select_column!]) {
          ${resource}(where: ${whereConditions}) {
              ${query}
          }
      }
	`;

	return (
		<MooGraphqlSelect
			id={`${resource}_distinct`}
			operationName={`${resource}_distinct`}
			value={value}
			onChange={onChange}
			query={gqlQuery}
			disabled={disabled}
			variables={{}}
			label={label}
			error={error}
			mapData={(data) => {
				// @ts-ignore
				const results = data[resource].map((data) => {
					const value = _.get(data, resultValue);
					const label = _.get(data, resultLabel);
					return { id: value, value, label };
				});

				return {
					results,
				};
			}}
		/>
	);
}
