import { gql } from "@apollo/client";
import { Employee } from "../moo-employee-one";
import { useGraphQLQuery } from "../moo-graphql-provider";

export function useFindOne(id: string) {
	const query = gql`
		query ($id: uuid!) {
			employees_by_pk(id: $id) {
				status
				type_id
				branch_id
				default_branch_id
				name
				nick_name
				birthday
				tel
				email
				line
				contact_name
				contact_tel
				address
				onboard_date
				salary_basic
				salary_increased_date
				bank_account
				bank
				bank_account_number
				labor_fee
				healthcare_fee
				insurance_day
				insurance_unit
				insurance_fee
				note
				user {
					role_id
				}
			}
		}
	`;
	const variables = {
		id,
	};
	return useGraphQLQuery<{ employees_by_pk: Employee }>({
		operationName: "employees_by_pk",
		query,
		variables,
	});
}
