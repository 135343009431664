import { DocumentNode, gql } from "@apollo/client";
import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { MooGraphqlSelectField } from "../../moo-graphql-select-field";
import { useGraphQLDistinct } from "../hooks/useGraphQLDistinct";
import { useGraphQLResource } from "../hooks/useGraphQLResource";
import useStore from "../stores/useStore";

function useGraphQLQuery(): DocumentNode {
	const graphQLResource = useGraphQLResource();
	const graphQLDistinct = useGraphQLDistinct();

	return gql`
      query($distinct: ${graphQLDistinct}, $where: ${graphQLResource}_bool_exp) {
          ${graphQLResource}(distinct_on: $distinct, where: $where) {
						material
				}
      }
	`;
}

export function ItemMaterialSelect() {
	const { value: branch } = useBranch();
	const {
		query: { material },
		setQuery,
	} = useStore();
	const graphQLResource = useGraphQLResource();
	const graphQLQuery = useGraphQLQuery();

	return (
		<MooGraphqlSelectField
			id="item-material-select-field"
			label="材質"
			operationName={graphQLResource}
			query={graphQLQuery}
			variables={{
				distinct: ["material"],
				where: {
					color: { _neq: "" },
					status: { _eq: Status.Present },
					branch_id: { _eq: branch },
				},
			}}
			mapData={(data) => {
				const arr = (data[graphQLResource] || []) as { material: string | null }[];
				const results: { id: string; value: string | null; label: string }[] = [
					{
						id: "all",
						value: null,
						label: "請選擇",
					},
				].concat(
					arr.map(({ material }) => ({
						id: material || "",
						value: material,
						label: material || "",
					})) as any,
				);

				return {
					results,
				};
			}}
			value={material}
			onChange={(material) => setQuery({ material, page: 0 })}
		/>
	);
}
