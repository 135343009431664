import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "from_date",
			label: "日期(起)",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "to_date",
			label: "日期(迄)",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "owner_name",
			label: "承辦人",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
		{
			id: "sales_name",
			label: "業務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "customer_name",
			label: "客戶",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "type",
			label: "服務類型",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state_accountant",
			label: "會計確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
	];
}
