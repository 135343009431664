import { object, SchemaOf } from "yup";
import { order } from "../../moo-enums";
import {
	arrayOptionalSchema,
	booleanOptional,
	enumRequired,
	items,
	numberOptional,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { instructionSchema } from "./instructionSchema";
import { voucherRowSchema } from "./voucherRowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: statusSchema,
	state: enumRequired(order.State.Value),
	state_manager: enumRequired(order.StateManager.Value),
	state_accountant: enumRequired(order.StateAccountant.Value),
	state_warehouse: enumRequired(order.StateWarehouse.Value),
	order_date: stringRequired("order_date"),
	quotation_id: stringRequired("quotation_id"),
	type_id: stringRequired("type_id"),
	type_name: stringOptional,
	customer_id: stringRequired("customer_id"),
	customer_name: stringRequired("customer_name"),
	sales_id: stringRequired("sales_id"),
	sales_name: stringOptional,
	sales_tel: stringOptional,
	deliver_date: stringOptional,
	is_include_tax: booleanOptional,
	payee_id: stringRequired("payee_id"),
	payee_name: stringOptional,
	payee_bank: stringOptional,
	payee_account_number: stringOptional,
	deliver_address: stringOptional,
	description: stringOptional,
	order_note: stringOptional,
	items,
	tax_price: numberOptional,
	total_price: numberOptional,
	received: numberOptional,
	receivables: numberOptional,
	handling_fee: numberOptional,
	freight: numberOptional,
	miscellaneous_charge: numberOptional,
	commission_refund: numberOptional,
	note: stringOptional,
	memo: stringOptional,
	instructions: arrayOptionalSchema(instructionSchema),
	voucher_rows: arrayOptionalSchema(voucherRowSchema),
	create_date: stringOptional,
	update_date: stringOptional,
});
