import { MooEnumCrud } from "../moo-enum-crud";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";

export type MooItemCategorySubProps = {
	parent: string;
};

export function MooItemCategorySub({ parent }: MooItemCategorySubProps) {
	const permissions = usePermissions();
	if (!permissions.includes(Permission.ItemCategoryWrite)) {
		return <MooNoAuthPage />;
	}

	return (
		<MooEnumCrud
			resource="item_categories"
			filter={{
				parent: {
					_eq: parent,
				},
			}}
			mutation={{
				parent,
			}}
			title="商品次類"
			addColumns={[
				{
					id: "name",
					defaultValue: "",
					label: "名稱",
					autoFocus: true,
					placeholder: "沙發",
					emptyError: "",
				},
			]}
			listBackgroundColor="#EAEAEA"
			listColumns={[
				{
					id: "name",
					defaultValue: "",
				},
			]}
		/>
	);
}
