export const BASIC_FIELDS = [
	"default_branch_id",
	"type_id",
	"role_id",
	"name",
	"nick_name",
	"birthday",
	"tel",
	"email",
	"line",
	"contact_name",
	"contact_tel",
	"password",
	"address",
];

export const SALARY_FIELDS = [
	"onboard_date",
	"salary_increased_date",
	"salary_basic",
	"bank_account",
	"bank",
	"bank_account_number",
	"labor_fee",
	"healthcare_fee",
	"insurance_day",
	"insurance_unit",
	"note",
];
