import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { ENV, EnvEnum, SENTRY_DSN } from "./config";

export function initSentry() {
	if (ENV === EnvEnum.Stage || ENV === EnvEnum.Prod) {
		Sentry.init({
			dsn: SENTRY_DSN,
			environment: ENV,
			integrations: [
				new Sentry.BrowserTracing({
					routingInstrumentation: Sentry.reactRouterV6Instrumentation(
						useEffect,
						useLocation,
						useNavigationType,
						createRoutesFromChildren,
						matchRoutes,
					),
				}),
				new Sentry.Replay(),
			],
			tracesSampleRate: 1.0,
			tracePropagationTargets: [
				"https://moo-api.koden.tw/api/v1/graphql",
				"https://api.mooshome.com.tw/api/v1/graphql",
			],
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
		console.log("sentry init");
	}
}
