import { object, SchemaOf } from "yup";
import { manufacturer } from "../../moo-enums";
import {
	arrayRequiredSchema,
	enumRequired,
	numberAllowZeroRequired,
	numberRequired,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { Item } from "../@types/one";
import { originalCodeSchema } from "./originalCodeSchema";
import { stockSchema } from "./stockSchema";

// @ts-ignore
export const itemSchema: SchemaOf<Item> = object().shape({
	export_good_item_id: stringOptional,
	id: stringRequired("id"),
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer_id: stringRequired("manufacturer_id"),
	manufacturer: stringRequired("manufacturer"),
	name: stringOptional,
	material: stringOptional,
	color: stringOptional,
	size: stringOptional,
	count: numberRequired("count"),
	image: stringOptional,
	image_cut: stringOptional,
	original_code: originalCodeSchema,
	priority: numberAllowZeroRequired("priority", "請提供", true),
	stocks: arrayRequiredSchema(stockSchema, "stocks", "請提供出貨倉庫"),
});
