import { ItemType } from "../../moo-enums";
import useStore from "../stores/useStore";

export function useGraphQLDistinct(): string {
	const {
		query: { type },
	} = useStore();

	switch (type) {
		case ItemType.Spec:
			return "[item_specs_select_column!]";
		case ItemType.Custom:
			return "[item_customs_select_column!]";
		case ItemType.Bundle:
			return "[item_bundles_select_column!]";
	}
}
