import { MooGraphQLList } from "../moo-graphql-list";
import { QuerySlice } from "../moo-hooks";
import { Permission, usePermissions } from "../moo-me-provider";
import { Query } from "./@types";
import { useCells } from "./hooks/useCells";
import { useGraphQLQuery } from "./hooks/useGraphQLQuery";
import { useGraphQLVariables } from "./hooks/useGraphQLVariables";
import { useHeaders } from "./hooks/useHeaders";
import { mapData } from "./mapData";
import { AccountantStateSelect } from "./selects/AccountantStateSelect";
import { ManufacturerSelect } from "./selects/ManufacturerSelect";

export const rowsPerPage = 10;
export const operationName = "import_goods";

export type MooImportGoodsListProps = {
	store: QuerySlice<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const ImportGoodsList = ({ onAdd, onRowClick, store }: MooImportGoodsListProps) => {
	const { clear, query, setQuery } = store;
	const permissions = usePermissions();
	const cells = useCells({ crud: true });
	const headers = useHeaders();
	const graphQLQuery = useGraphQLQuery();
	const graphQLVariables = useGraphQLVariables();

	return (
		<MooGraphQLList
			showAddButton={permissions.includes(Permission.ImportGoodsAdd)}
			addLabel="新增進貨單"
			onAdd={onAdd}
			filterNode={
				<>
					<ManufacturerSelect
						value={query.manufacturer || ""}
						onChange={(manufacturer) => setQuery({ manufacturer, page: 0 })}
					/>
					<AccountantStateSelect
						value={query.accountant_state || ""}
						onChange={(accountant_state) => setQuery({ accountant_state, page: 0 })}
					/>
				</>
			}
			headers={headers}
			cells={cells}
			clear={clear}
			query={query}
			setQuery={setQuery}
			onRowClick={onRowClick}
			operationName={operationName}
			graphQLQuery={graphQLQuery}
			variables={graphQLVariables}
			mapData={mapData}
			rowsPerPage={rowsPerPage}
		/>
	);
};
