import { importGoods, manufacturer, Status } from "../moo-enums";
import { getNowLocaleString } from "../moo-shared";
import { One } from "./@types/one";

export function DEFAULT_ONE(): One {
	const now = getNowLocaleString();
	return {
		create_date: now,
		update_date: now,
		status: Status.Present,
		state: importGoods.State.Value.UnVerified,
		state_accountant: importGoods.StateAccountant.Value.UnSettled,
		type: importGoods.Type.Direct,
		import_date: now,
		order_ids: [],
		link_id: "",
		sales_id: null,
		sales_name: "",
		sales_tel: "",
		manufacturer_type: manufacturer.Value.Value.Domestic,
		manufacturer_id: null,
		manufacturer: "",
		warehouse_id: null,
		note: "",
		items: [],
		accountant_tax: 0,
		accountant_freight: 0,
		accountant_miscellaneous_charge: 0,
		accountant_total_price: 0,
		accountant_received: 0,
		accountant_payable: 0,
		accountant_note: "",
		voucher_rows: [],
	};
}
