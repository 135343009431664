import { useGetGcsUrl } from "@yachelee/koden-components/koden-gcs-provider";

export type MooItemImageProps = {
	name?: string;
	value: string;
	cut: string;
	width?: number;
	height?: number;
};

export function MooItemImage({ name = "", value, cut, width = 90, height = 60 }: MooItemImageProps) {
	const getGcsUrl = useGetGcsUrl();

	return (
		<a href={getGcsUrl(value)} target="_blank" rel="noreferrer">
			<img alt={name} src={getGcsUrl(cut)} width={width} height={height} />
		</a>
	);
}
