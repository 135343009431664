import { MooUploadFileButton } from "../moo-upload-file-button";
import { useUpdate } from "./hooks/useUpdate";

export function UploadButton() {
	const { addAttach } = useUpdate();

	return <MooUploadFileButton onUploaded={addAttach} />;
}

export default UploadButton;
