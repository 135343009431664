import { ReactNode, useState } from "react";
import { order } from "../moo-enums";
import { MooDialogOrderChoose, Row, useStore } from "./";

type ReturnType = {
	component: ReactNode;
	open: () => void;
	close: () => void;
};

export function useOrderDialogChoose(
	id: string,
	onOk: (rows: Row[]) => void,
	isMultiple = true,
	states: order.State.Value[] = order.State.getValues(),
	stateManagers: order.StateManager.Value[] = order.StateManager.getValues(),
	stateAccountants: order.StateAccountant.Value[] = order.StateAccountant.getValues(),
	stateWarehouses: order.StateWarehouse.Value[] = order.StateWarehouse.getValues(),
): ReturnType {
	const { clearChoose } = useStore();
	const [open, setOpen] = useState(false);
	const close = () => {
		setOpen(false);
	};

	const component = (
		<MooDialogOrderChoose
			id={id}
			open={open}
			onClose={close}
			onOk={async (rows) => {
				onOk(rows);
				close();
			}}
			isMultiple={isMultiple}
			states={states}
			stateManagers={stateManagers}
			stateAccountants={stateAccountants}
			stateWarehouses={stateWarehouses}
		/>
	);

	return {
		component,
		open: () => {
			clearChoose();
			setOpen(true);
		},
		close,
	};
}
