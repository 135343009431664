import { KodenUploadButton } from "@yachelee/koden-components/koden-upload-button";
import { FC, useState } from "react";

export type MooUploadFileButtonProps = {
	onUploaded: (file: string) => void;
};

export const MooUploadFileButton: FC<MooUploadFileButtonProps> = ({ onUploaded }) => {
	const [, setLoading] = useState(false);

	return (
		<KodenUploadButton
			maxInMB={100}
			acceptAny
			multiple
			onFileTooBig={() => {
				window.alert("請上傳檔案小於100MB的檔案。");
			}}
			onFileUploadReady={() => {
				setLoading(true);
			}}
			onFileUploading={() => {
				setLoading(true);
			}}
			onFileUploaded={(obj) => {
				setLoading(false);
				onUploaded(obj.src);
			}}
			onFileUploadFailed={() => {
				setLoading(false);
				window.alert("上傳失敗");
			}}
			onDropRejected={() => {
				window.alert("目前不接受此檔案格式！");
			}}
		>
			上傳檔案
		</KodenUploadButton>
	);
};

export default MooUploadFileButton;
