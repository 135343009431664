import { Gender } from "./@types/customer";

export function getGenderString(gender: Gender): string {
	switch (gender) {
		case Gender.Male.toString():
			return "男";
		case Gender.Female:
			return "女";
		case Gender.None:
		default:
			return "無";
	}
}
