import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../moo-graphql-provider";

type ReturnType = (categoryId: string, subCategoryId: string | null) => Promise<boolean>;

export function useIsSubCategoryIdValid(): ReturnType {
	const graphQLRequest = useGraphQLRequest();

	return async (categoryId, subCategoryId) => {
		if (categoryId === null) {
			return true;
		} else {
			const res = await graphQLRequest({
				query: gql`
					query ($where: item_categories_bool_exp) {
						item_categories(where: $where) {
							id
							name
						}
					}
				`,
				variables: {
					where: {
						parent: {
							_eq: categoryId,
						},
					},
				},
			});

			const subCategoryList = (res.item_categories || []) as any[];
			if (subCategoryList.length === 0) {
				return true;
			} else {
				return subCategoryId !== null;
			}
		}
	};
}
