import { MooReturnOrderOne, One } from "../moo-return-order-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { ReturnOrder } from "./findOne/ReturnOrder";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { ReturnOrderItemsTable } from "./updateOne/ReturnOrderItemsTable";
import { ReturnOrderTable } from "./updateOne/ReturnOrderTable";

export type MooReturnOrderUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooReturnOrderUpdate({ id, onCancel }: MooReturnOrderUpdateProps) {
	return (
		<MooUpdateFormNew<ReturnOrder, One>
			id={id}
			onCancel={onCancel}
			operationName="return-order-one"
			query={query}
			tableName="return_orders"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooReturnOrderOne}
			tables={[ReturnOrderItemsTable, ReturnOrderTable]}
		/>
	);
}
