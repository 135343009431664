import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { StoreType } from "../components/moo-components/moo-hooks";
import Utils from "../utils";

type Props<T> = {
	path: string;
	store: StoreType<T>;
};

export function useHistoryQuery<T extends Record<string, any>>({ path, store }: Props<T>) {
	const branch = useBranchInQuery();
	const navigate = useNavigate();

	useEffect(() => {
		const unsub = store.subscribe(({ query }: { query: T }) => {
			let newQuery = { ...query };
			Object.keys(query).forEach((key) => {
				if (query[key] === null || (key === "page" && query[key] === 0)) {
					delete newQuery[key];
				}
			});

			//normalize the date string
			Object.keys(newQuery).forEach((key) => {
				const value = newQuery[key];
				if (value instanceof Date) {
					if (Utils.isDateInvalid(value)) {
						delete newQuery[key];
					} else {
						newQuery = {
							...newQuery,
							[key]: Utils.toDateString(value),
						};
					}
				}
			});

			const urlSearchParams = new URLSearchParams({ ...newQuery, branch });
			navigate(`${path}?${urlSearchParams.toString()}`);
		});
		return () => {
			unsub();
		};
	}, [navigate, branch, path, store]);
}
