import produce from "immer";
import { Row as ItemRow, SpecOrCustomTableRow } from "../../moo-dialog-item-choose";
import { attachItems, getModelItemId } from "../../moo-shared";
import { Item } from "../@types/one";
import { useForm } from "./useForm";
import { useSyncPrices } from "./useSyncPrices";

export function useChooseItems() {
	const { setValues, values, initialValues } = useForm();
	const syncPrices = useSyncPrices();

	return (rows: ItemRow[]) => {
		setValues(
			produce(values, (one) => {
				one.items = attachItems(
					one.items,
					rows.map((item: SpecOrCustomTableRow, index) => {
						const data: Item = {
							purchase_order_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: item.id }),
							id: item.id,
							manufacturer_type: item.manufacturer_type,
							manufacturer_id: item.manufacturer_id,
							manufacturer: item.manufacturer,
							name: item.name,
							material: item.material,
							color: item.color,
							size: item.size,
							count: 1,
							image: item.image,
							image_cut: item.image_cut,
							priority: index,
							purchase_price: item.purchase_price,
							purchase_price_total: item.purchase_price,
						};
						return data;
					}),
				);
				syncPrices(one);
			}),
		);
	};
}
