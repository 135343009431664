import { KodenBinIcon } from "@yachelee/koden-components/koden-bin-icon";
import type { CellType } from "@yachelee/koden-components/koden-table";
import { GrRevert } from "react-icons/gr";
import Utils from "../../../../utils";
import { useOnArchived } from "../../moo-archive-provider";
import { Status } from "../../moo-enums";
import { useArchiveResource } from "../../moo-hooks";
import { useRecoverResource } from "../../moo-hooks/useRecoverResource";
import { DateFormat, DollarNumber } from "../../moo-shared";
import { Type } from "../../moo-voucher-one";
import { Row } from "../@types";
import { useStore } from "./useStore";

const resource = "vouchers";
export function useCells(): CellType[] {
	const { query, setQuery } = useStore();
	const isArchived = query.status === Status.Archived;
	const archiveResource = useArchiveResource(resource);
	const recoverResource = useRecoverResource(resource);
	const onArchived = useOnArchived();

	return [
		{
			id: "number",
			align: "left",
			component: ({ number }: Row) => {
				return number;
			},
		},
		{
			id: "voucher_date",
			align: "left",
			component: ({ voucher_date }: Row) => {
				return <DateFormat>{voucher_date}</DateFormat>;
			},
		},
		{
			id: "id",
			align: "left",
			component: (row: Row) => {
				return <div>{row.voucher_id}</div>;
			},
		},
		{
			id: "subject_id_number",
			align: "left",
			component: ({ subject_id_number }: Row) => {
				return subject_id_number;
			},
		},
		{
			id: "subject_name",
			align: "left",
			component: ({ subject_name }: Row) => {
				return subject_name;
			},
		},
		{
			id: "summary",
			align: "left",
			component: ({ summary }: Row) => {
				return summary;
			},
		},
		{
			id: "manufacturer",
			align: "left",
			component: ({ manufacturer_foreign, manufacturer_domestic }: Row) => {
				const manufacturer = manufacturer_domestic || manufacturer_foreign;
				return manufacturer?.name || "";
			},
		},
		{
			id: "type",
			align: "left",
			component: ({ type }: Row) => {
				if (type === Type.Debit) {
					return <div style={{ color: "green", textAlign: "left" }}>借</div>;
				} else {
					return <div style={{ color: "red", textAlign: "right" }}>貸</div>;
				}
			},
		},
		{
			id: "debit_amount",
			align: "left",
			component: ({ type, debit_amount }: Row) => {
				return type === Type.Debit ? <DollarNumber>{debit_amount}</DollarNumber> : null;
			},
		},
		{
			id: "credit_amount",
			align: "left",
			component: ({ type, credit_amount }: Row) => {
				return type === Type.Credit ? <DollarNumber>{credit_amount}</DollarNumber> : null;
			},
		},
		isArchived
			? {
					id: "recover",
					align: "center",
					component: ({ voucher_id }: Row) => {
						return (
							<div
								onClick={async (e) => {
									e.stopPropagation();
									if (await recoverResource({ id: voucher_id, name: voucher_id, idName: "id" })) {
										setQuery({ page: 0 });
										window.alert("恢復成功");
										Utils.refreshPage();
									}
								}}
							>
								<GrRevert />
							</div>
						);
					},
			  }
			: {
					id: "delete",
					align: "center",
					component: ({ voucher_row_id, voucher_id }: Row) => {
						return (
							<KodenBinIcon
								id={`DELETE_${voucher_row_id}`}
								disabled={false}
								onClick={async (e) => {
									e.stopPropagation();
									if (await archiveResource({ id: voucher_id, name: voucher_id, idName: "id", action: "封存" })) {
										setQuery({ page: 0 });
										onArchived(resource);
									}
								}}
							/>
						);
					},
			  },
	];
}
