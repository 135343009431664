import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { KodenChangePasswordDialog } from "@yachelee/koden-components/koden-change-password-dialog";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Form, MooEmployeeOneProps } from "..";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { MooBranchSelectField } from "../../moo-branch-select-field";
import { MooEnumTypeableSelectField } from "../../moo-enum-typeable-select-field";
import { Permission, usePermissions } from "../../moo-me-provider";
import { MooGraphQLDistinctSelect, useGetFieldError } from "../../moo-shared";
import { TABLET_BREAKPOINT, WEB_BREAKPOINT } from "../config";
import { BASIC_FIELDS as basicFields } from "../fields";
import { useForm } from "../hooks/useForm";

const FieldContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 2rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const Title = styled.h3`
	margin: 0;
`;

const Email = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const Address = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-column: 1 / -1;
	}
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column: 1 / span 2;
	}
`;

export const Basic: FC<MooEmployeeOneProps> = ({ isEdit = false, onChangePassword = () => {} }) => {
	const { values: one, errors, setFieldValue } = useForm();
	const getFieldError = useGetFieldError<Form>();
	const permissions = usePermissions();
	const [passwordOpen, setPasswordOpen] = useState(false);
	const [isExpanded, setIsExpanded] = useState(true);

	useEffect(() => {
		if (isExpanded) return;
		const fields = new Set(basicFields);
		for (const field in errors) {
			// @ts-ignore
			if (errors[field] === "" || !fields.has(field)) continue;
			return setIsExpanded(true);
		}
	}, [errors]);

	return (
		<Accordion
			disableGutters
			expanded={isExpanded}
			onChange={(e) => {
				if (e.type !== "click") return;
				setIsExpanded(!isExpanded);
			}}
		>
			<AccordionSummary expandIcon={<KeyboardArrowDown />}>
				<Title>基本資料</Title>
			</AccordionSummary>
			<AccordionDetails>
				<KodenChangePasswordDialog
					open={passwordOpen}
					onSubmit={(pw1) => {
						onChangePassword(pw1);
						setPasswordOpen(false);
					}}
					onClose={() => setPasswordOpen(false)}
				/>
				<FieldContainer>
					<MooBranchSelectField
						label="預設店別"
						value={one.default_branch_id}
						error={getFieldError("default_branch_id")}
						onChange={(value) => setFieldValue("default_branch_id", value)}
					/>
					<MooEnumTypeableSelectField
						id="employee_types"
						resource="employee_types"
						label="職務"
						value={one.type_id}
						error={getFieldError("type_id")}
						onChange={(value) => setFieldValue("type_id", value)}
					/>
					<ProxyKodenTextField
						id="name"
						label="姓名"
						outlined
						margin="none"
						value={one.name}
						error={getFieldError("name")}
						onChange={(value) => setFieldValue("name", value)}
					/>
					<ProxyKodenTextField
						id="nick_name"
						label="暱稱"
						outlined
						margin="none"
						value={one.nick_name}
						error={getFieldError("nick_name")}
						onChange={(value) => setFieldValue("nick_name", value)}
					/>
					<MooGraphQLDistinctSelect
						label="角色"
						resource="roles"
						query={`
							id
							name
						`}
						whereConditions={`{id: {_is_null: false}}`}
						resultValue="id"
						resultLabel="name"
						error={getFieldError("role_id")}
						disabled={!permissions.includes(Permission.EmployeeAssignRole)}
						value={one.role_id!}
						onChange={(value) => setFieldValue("role_id", value)}
					/>
					<KodenDateField
						id="birthday"
						label="生日"
						error={getFieldError("birthday")}
						value={one.birthday}
						onChange={(value) =>
							setFieldValue(
								"birthday",
								value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
							)
						}
					/>
					<ProxyKodenTextField
						id="tel"
						label="電話"
						outlined
						margin="none"
						value={one.tel}
						error={getFieldError("tel")}
						onChange={(value) => setFieldValue("tel", value)}
					/>
					<Email
						id="email"
						label="Email"
						outlined
						margin="none"
						value={one.email}
						type="email"
						disabled={isEdit}
						error={getFieldError("email")}
						onChange={(value) => setFieldValue("email", value)}
					/>
					<ProxyKodenTextField
						id="line"
						label="LINE"
						outlined
						margin="none"
						value={one.line}
						error={getFieldError("line")}
						onChange={(value) => setFieldValue("line", value)}
					/>
					<ProxyKodenTextField
						id="contact_name"
						label="緊急聯絡人"
						outlined
						margin="none"
						value={one.contact_name}
						error={getFieldError("contact_name")}
						onChange={(value) => setFieldValue("contact_name", value)}
					/>
					<ProxyKodenTextField
						id="contact_tel"
						label="緊急聯絡人電話"
						outlined
						margin="none"
						value={one.contact_tel}
						error={getFieldError("contact_tel")}
						onChange={(value) => setFieldValue("contact_tel", value)}
					/>
					{isEdit && (
						<div>
							<ProxyKodenTextField
								id="password"
								label="密碼"
								disabled
								outlined
								readOnly
								margin="none"
								type="password"
								value="*********"
								error=""
								onChange={() => {}}
							/>
							<div
								style={{ marginTop: 8, cursor: "pointer", fontSize: 12 }}
								onClick={() => {
									setPasswordOpen(true);
								}}
							>
								更改密碼
							</div>
						</div>
					)}
					<Address
						id="address"
						label="地址"
						outlined
						margin="none"
						value={one.address}
						error={getFieldError("address")}
						onChange={(value) => setFieldValue("address", value)}
					/>
				</FieldContainer>
			</AccordionDetails>
		</Accordion>
	);
};
