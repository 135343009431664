import { gql } from "@apollo/client";
import { useGraphQLQuery } from "../moo-graphql-provider";

export const useIsSubCategoryHide = (categoryId: string | null): boolean => {
	const query = gql`
		query ($where: item_categories_bool_exp) {
			item_categories(where: $where) {
				id
			}
		}
	`;

	const parent = categoryId === null ? { _is_null: true } : { _eq: categoryId };
	const variables = {
		where: {
			parent,
		},
	};

	const data = useGraphQLQuery({ operationName: "item_categories", query, variables });
	if (data.isLoading || categoryId === null) {
		return true;
	} else {
		// @ts-ignore
		return data?.data?.item_categories?.length === 0;
	}
};

export default useIsSubCategoryHide;
