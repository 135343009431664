import styled from "@emotion/styled";
import { ItemCategorySelect } from "./selects/ItemCategorySelect";
import { ItemColorSelect } from "./selects/ItemColorSelect";
import { ItemManufacturerSelect } from "./selects/ItemManufacturerSelect";
import { ItemManufacturerTypeSelect } from "./selects/ItemManufacturerTypeSelect";
import { ItemMaterialSelect } from "./selects/ItemMaterialSelect";
import { ItemTypeSelect } from "./selects/ItemTypeSelect";
import { KeywordSearch } from "./selects/KeywordSearch";
import { OwnerSelect } from "./selects/OwnerSelect";
import useStore from "./stores/useStore";

const TABLET_BREAKPOINT = "768px";

const Container = styled.div`
	padding-top: 0.4rem;
	margin-top: 4px;
	margin-bottom: 0.5rem;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	& .koden-search {
		margin-top: 5px;
		width: 100%;
		font-size: 0.875rem;
		@media screen and (min-width: ${TABLET_BREAKPOINT}) {
			margin-top: 0;
			width: 242px;
		}
	}
`;

const Filters = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		width: unset;
	}
	& .select-field {
		height: 28px;
		margin-right: 0.5rem;
		border-radius: 25px;
		font-size: 0.8rem;
		overflow: hidden;
	}
`;

export function FilterBar() {
	const { query } = useStore();
	return (
		<Container>
			<Filters>
				<OwnerSelect />
				<ItemTypeSelect />
				<ItemManufacturerTypeSelect />
				{query.manufacturer_type !== null && <ItemManufacturerSelect />}
				<ItemCategorySelect />
				<ItemMaterialSelect />
				<ItemColorSelect />
			</Filters>
			<KeywordSearch />
		</Container>
	);
}
