import { ItemType } from "../../../moo-enums";
import useStore from "../../stores/useStore";

export function useGraphQLOrderByName(): string {
	const {
		query: { type },
	} = useStore();
	switch (type) {
		case ItemType.Spec:
			return "[item_specs_order_by!]";
		case ItemType.Custom:
			return "[item_customs_order_by!]";
		case ItemType.Bundle:
			return "[item_bundles_order_by!]";
	}
}
