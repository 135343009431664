import { manufacturer as manufacturerEnum } from "../moo-enums";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Query } from "./@types";
import { Filter } from "./Filter";
import { useConditions } from "./hooks/useConditions";
import { useCells } from "./useCells";
import { useHeaders } from "./useHeaders";

export type MooItemListProps = {
	showAddButton: boolean;
	store: StoreType<Query>;
	type: "spec" | "custom";
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
	onClone: ({ id }: { id: string }) => void;
};

export const MooItemList = ({ showAddButton, type, onAdd, onRowClick, store, onClone }: MooItemListProps) => {
	const itemStore = store();
	const isSpec = type === "spec";
	const resource = isSpec ? "item_specs" : "item_customs";
	const headers = useHeaders();
	const cells = useCells(itemStore, type, resource, onClone);
	const graphQLColumns = `
	{
		id
    category {
      name
    }
    manufacturer_type
    manufacturer_id
    name
    image
    image_cut
    country
    owner {
    	name
    }
    material
    color
    size
    purchase_price
    price
    cost
	}
	
  manufacturer_foreigns {
    id
    name
  }
  
  manufacturer_domestics {
    id
    name
  }`;

	const conditions = useConditions(itemStore.query);
	return (
		<MooList
			showAddButton={showAddButton}
			resource={resource}
			conditions={conditions}
			graphQLColumns={graphQLColumns}
			mapData={(e, { manufacturer_domestics, manufacturer_foreigns }) => {
				let manufacturer = "";
				const manufacturer_id = e.manufacturer_id;
				if (e.manufacturer_type === manufacturerEnum.Value.Value.Domestic) {
					// @ts-ignore
					manufacturer = manufacturer_domestics.find((e) => e.id === manufacturer_id)?.name || "";
				} else if (e.manufacturer_type === manufacturerEnum.Value.Value.Foreign) {
					// @ts-ignore
					manufacturer = manufacturer_foreigns.find((e) => e.id === manufacturer_id)?.name || "";
				}

				return {
					...e,
					category: e.category.name,
					manufacturer,
				};
			}}
			addLabel="新增商品"
			onAdd={onAdd}
			filterNode={<Filter type={type} store={itemStore} />}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
			params={{ type }}
		/>
	);
};
