import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "./useBranchInQuery";

export function useHistoryPushWithBranch() {
	const navigate = useNavigate();
	const branch = useBranchInQuery();

	return async (path: string) => {
		await navigate(`${path}?branch=${branch}`);
	};
}
