import { gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

type Props = {
	value: string | null;
	error: string;
	onChange: (value: string | null) => void;
};

type RemoteData = {
	account_types: {
		name: string;
		id: string;
		id_number: string;
	}[];
};

type Option = {
	readonly value: string;
	readonly label: string;
};

const query = gql`
	query query_account_types($where: account_types_bool_exp) {
		account_types(where: $where, order_by: [{ id_number: asc }]) {
			id
			name
			id_number
		}
	}
`;

export function IdNumberField({ error, value, onChange }: Props) {
	const { value: branch } = useBranch();
	const variables = {
		where: {
			status: {
				_eq: "PRESENT",
			},
			branch_id: {
				_eq: branch,
			},
		},
	};
	const { isLoading, data } = useGraphQLQuery<RemoteData>({ query, variables, operationName: "a" });
	const options: Option[] = isLoading
		? []
		: // @ts-ignore
		  data.account_types.map(({ id, name, id_number }) => ({ value: id, label: `${name}(${id_number})` }));

	return (
		<MooTypeableSelect
			id="IdNumberField"
			options={options}
			label="科目代號"
			value={value}
			error={error}
			onChange={onChange}
			isLoading={isLoading}
		/>
	);
}
