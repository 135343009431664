import { object, SchemaOf } from "yup";
import { manufacturer } from "../../moo-enums";
import { enumRequired, numberOptional, stringOptional, stringRequired } from "../../moo-schemas";
import { Item } from "../@types/one";

// @ts-ignore
export const itemSchema: SchemaOf<Item> = object().shape({
	purchase_order_item_id: stringRequired("purchase_order_item_id"),
	id: stringOptional,
	manufacturer_type: enumRequired(manufacturer.Value.Value),
	manufacturer_id: stringOptional,
	manufacturer: stringOptional,
	name: stringOptional,
	material: stringOptional,
	color: stringOptional,
	size: stringOptional,
	count: numberOptional,
	image: stringOptional,
	image_cut: stringOptional,
	priority: numberOptional,
	purchase_price: numberOptional,
	purchase_price_total: numberOptional,
});
