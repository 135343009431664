import { gql } from "@apollo/client";
import { KodenYearAndMonthField } from "@yachelee/koden-components/koden-year-and-month-field";
import * as _ from "lodash";
import { MooGraphQLTable } from "../moo-graphql-table";
import { StoreType } from "../moo-hooks";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query, Result, Row } from "./@types";
import { useCells } from "./useCells";
import { useHeaders } from "./useHeaders";
import { useUpdateRow } from "./useUpdateRow";

export type MooUserSalaryListProps = {
	store: StoreType<Query>;
	onOk: () => void;
};

const rowsPerPage = 10;

export const MooUserSalaryList = ({ store, onOk }: MooUserSalaryListProps) => {
	const permissions = usePermissions();
	const theStore = store();
	const { query, setQuery } = theStore;
	// @ts-ignore
	const updateRow = useUpdateRow(query.year, query.month, onOk);

	const cells = useCells(updateRow);
	const headers = useHeaders();

	if (!permissions.includes(Permission.SalaryRead)) {
		return <MooNoAuthPage />;
	}

	const variables = {
		employees_offset: query.page * rowsPerPage,
		employees_limit: rowsPerPage,
		employee_performances_where: {
			year: {
				_eq: query.year,
			},
			month: {
				_eq: query.month,
			},
		},
		employee_salaries_where: {
			year: {
				_eq: query.year,
			},
			month: {
				_eq: query.month,
			},
		},
	};

	return (
		<>
			<div style={{ marginBottom: "1rem" }}>
				<KodenYearAndMonthField
					id="user_salary_list_year_and_month"
					// @ts-ignore
					value={query}
					label="日期"
					error=""
					onChange={setQuery}
				/>
			</div>
			<MooGraphQLTable
				headers={headers}
				cells={cells}
				page={theStore.query.page}
				rowsPerPage={rowsPerPage}
				onPageChange={(page) => {
					setQuery({ page });
				}}
				operationName=""
				onRowClick={() => {}}
				mapData={(e: Result) => {
					const total_count = _.get(e, "employees_aggregate.aggregate.count") || 0;

					return {
						results: e.employees.map((employee) => {
							let perfect_attendance = 0,
								bonus = 0,
								gift_money = 0,
								other_addition = 0,
								addition_sum = employee.salary_basic,
								late = 0,
								pto = 0,
								other_deduction = 0,
								note = "";
							let deduction_sum = employee.labor_fee + employee.healthcare_fee;

							if (employee.employee_salaries.length !== 0) {
								const employee_salaries = employee.employee_salaries[0];
								late = employee_salaries.late || 0;
								pto = employee_salaries.pto || 0;
								other_deduction = employee_salaries.other_deduction || 0;
								deduction_sum += late;
								deduction_sum += employee_salaries.other_deduction || 0;
								perfect_attendance = employee_salaries.perfect_attendance || 0;
								gift_money = employee_salaries.gift_money || 0;
								other_addition = employee_salaries.other_addition || 0;

								addition_sum += employee_salaries.perfect_attendance || 0;
								addition_sum += gift_money;
								addition_sum += other_addition;

								note = employee_salaries.note;
							}

							if (employee.employee_performances.length !== 0) {
								bonus = employee.employee_performances[0].custom_bonus;
								addition_sum += bonus;
							}
							const monthly_salary = addition_sum - deduction_sum;

							const row: Row = {
								id: employee.id,
								employee_id: employee.number,
								employee_name: employee.name,
								salary_basic: employee.salary_basic,
								perfect_attendance,
								bonus,
								gift_money,
								other_addition,
								addition_sum,
								late,
								pto,
								labor_fee: employee.labor_fee,
								healthcare_fee: employee.healthcare_fee,
								bank: employee.bank,
								insurance_day: employee.insurance_day,
								insurance_unit: employee.insurance_unit,
								other_deduction,
								deduction_sum,
								note,
								monthly_salary,
							};
							return row;
						}),
						total_count,
					};
				}}
				query={gql`
					query (
						$employees_offset: Int
						$employees_limit: Int
						$employee_performances_where: employee_performances_bool_exp
						$employee_salaries_where: employee_salaries_bool_exp
					) {
						employees_aggregate {
							aggregate {
								count
							}
						}
						employees(offset: $employees_offset, limit: $employees_limit) {
							id
							name
							salary_basic
							labor_fee
							healthcare_fee
							bank
							insurance_day
							insurance_unit
							employee_performances(where: $employee_performances_where) {
								custom_bonus
							}
							employee_salaries(where: $employee_salaries_where) {
								late
								other_deduction
								perfect_attendance
								gift_money
								other_addition
								note
								pto
							}
						}
					}
				`}
				variables={variables}
			/>
		</>
	);
};
