import { One } from "..";
import { defectiveProduct } from "../../moo-enums";
import { Permission, usePermissions } from "../../moo-me-provider";
import { useGetFieldError } from "../../moo-shared";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import FieldContainer from "../components/FieldContainer";
import { useForm } from "../hooks/useForm";
import { useLockStock } from "../hooks/useLockStock";

type ItemOption = {
	id: defectiveProduct.State.Value;
	label: defectiveProduct.State.Label;
};

type Item = {
	id: keyof One;
	label: string;
	options: ItemOption[];
	permission: Permission;
};

const arr: Item[] = [
	{
		id: "state",
		label: "狀態",
		permission: Permission.DefectiveProductStateWrite,
		options: [
			{
				id: defectiveProduct.State.Value.MAINTAINING,
				label: defectiveProduct.State.Label.MAINTAINING,
			},
			{
				id: defectiveProduct.State.Value.MAINTAINED_AND_IMPORTED,
				label: defectiveProduct.State.Label.MAINTAINED_AND_IMPORTED,
			},
			{
				id: defectiveProduct.State.Value.DONE,
				label: defectiveProduct.State.Label.DONE,
			},
		],
	},
];

export function StateBlock() {
	const lockStock = useLockStock();
	const { values: one, setFieldValue: updateOne } = useForm();
	const permissions = usePermissions();
	const getFieldError = useGetFieldError<One>();

	return (
		<FieldContainer>
			{arr.map(({ id, label, options, permission }) => (
				<MooTypeableSelect
					key={id}
					id={id}
					label={label}
					value={one[id] as string}
					disabled={(id === "state" && lockStock) || !permissions.includes(permission)}
					error={getFieldError(id)}
					options={options.map((e) => {
						return { ...e, value: e.id };
					})}
					onChange={(value) => {
						updateOne(id, value);
					}}
				/>
			))}
		</FieldContainer>
	);
}
