import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "調撥單號",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "transfer_date",
			label: "調撥日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "owner_name",
			label: "承辦人",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
		{
			id: "source_warehouse",
			label: "出貨倉庫",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "target_warehouse",
			label: "收貨倉庫",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "item_names",
			label: "商品名",
			align: "left",
			style: {
				minWidth: 80,
			},
		},
	];
}
