import { calReceivable, One } from "../../moo-order-one";
import { getLocaleString } from "../../moo-shared";
import { Order } from "./Order";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Order): One {
	const items = mapperItems(record.items);

	return {
		status: record.status,
		state: record.state,
		state_manager: record.state_manager,
		state_accountant: record.state_accountant,
		state_warehouse: record.state_warehouse,
		order_date: record.order_date,
		quotation_id: record.quotation.id,
		type_id: record.quotation_type.id,
		type_name: record.quotation_type.name || "",
		customer_id: record.customer.id,
		customer_name: record.customer.name || "",
		assistant_id: record.assistant_id,
		sales_id: record.sales.id,
		sales_name: record.sales.name,
		sales_tel: record.sales.tel,
		deliver_date: record.deliver_date,
		is_include_tax: record.is_include_tax,
		payee_id: record.payee.id,
		payee_name: record.payee.name,
		payee_bank: record.payee.bank_name,
		payee_account_number: record.payee.account_number,
		deliver_address: record.deliver_address,
		description: record.description,
		order_note: record.order_note,
		items,
		tax_price: record.tax_price,
		total_price: record.total_price,
		received: record.received,
		receivables: calReceivable(record.total_price, record.tax_price, record.received),
		handling_fee: record.handling_fee,
		freight: record.freight,
		miscellaneous_charge: record.miscellaneous_charge,
		commission_refund: record.commission_refund,
		note: record.note,
		memo: record.memo,
		instructions: record.instructions,
		voucher_rows: record.voucher_rows,
		employee: record.employee,
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
	};
}
