import { mapData as exportGoodsMapData } from "../../moo-export-goods-list";
import { mapResults as importGoodsMapResults } from "../../moo-import-goods-list";
import { mapData as orderGoodsMapData } from "../../moo-order-goods-list";
import { mapResults as orderMapResults } from "../../moo-order-list";
import { mapResults as purchaseOrdersMapResults } from "../../moo-purchase-order-list";
import { mapResults as quotationMapResults } from "../../moo-quotation-list";
import { mapResults as requestBuyMapResults } from "../../moo-request-buy-list";
import { mapData as returnGoodsMapData } from "../../moo-return-goods-list";
import { LinkType } from "../@types";
import useStore from "../stores/useStore";

export function useMapResults() {
	const { type } = useStore();

	switch (type) {
		case LinkType.Quotation:
			return quotationMapResults;
		case LinkType.Order:
			return orderMapResults;
		case LinkType.ImportGoods:
			return importGoodsMapResults;
		case LinkType.ExportGoods:
			// @ts-ignore
			return (results, { warehouses }) => {
				// @ts-ignore
				return results.map((e) => exportGoodsMapData(e, { warehouses }));
			};
		case LinkType.PurchaseOrder:
			return purchaseOrdersMapResults;
		case LinkType.RequestBuy:
			return requestBuyMapResults;
		case LinkType.ReturnGoods:
			return returnGoodsMapData;
		case LinkType.OrderGoods:
			// @ts-ignore
			return (results) => {
				return results.map(orderGoodsMapData);
			};
	}
}
