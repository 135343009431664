import { Item } from "../../moo-svc-one";
import { RowItem } from "./RowItem";

export function mapperItems(items: RowItem[]): Item[] {
	return items.map(({ svc_item_id, name, count, price }) => {
		return {
			svc_item_id,
			name,
			count,
			price,
		};
	});
}
