import { MooExportGoodsOne, One } from "../moo-export-goods-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { ExportGoodsTable } from "./updateOne/ExportGoodsTable";

export type MooExportGoodsUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooExportGoodsUpdate({ id, onCancel }: MooExportGoodsUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="export-goods-one"
			query={query}
			tableName="export_goods"
			mapperInitialValues={mapperInitialValues}
			Form={MooExportGoodsOne}
			tables={[ExportGoodsTable]}
		/>
	);
}
