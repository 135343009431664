import produce from "immer";
import { uuidv4 } from "../../moo-shared";
import { Account } from "../@types/foreign-manufacturer";
import { useForm } from "./useForm";

export function useUpdate() {
	const { values, setFieldValue } = useForm();

	function addTel() {
		setFieldValue(
			"tels",
			produce(values.tels, (draft) => {
				draft.push({ manufacturer_foreign_tel_id: uuidv4(), tel: "" });
			}),
		);
	}

	function delTel(index: number) {
		setFieldValue(
			"tels",
			produce(values.tels, (draft) => {
				draft.splice(index, 1);
			}),
		);
	}

	function updateTel(index: number, value: string) {
		setFieldValue(
			"tels",
			produce(values.tels, (draft) => {
				draft[index].tel = value;
			}),
		);
	}

	function addAccount() {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				draft.push({
					manufacturer_foreign_account_id: uuidv4(),
					bank_account: "",
					swift_code: "",
					code_iban: "",
				});
			}),
		);
	}

	function delAccount(id: string) {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				const index = draft.findIndex((e) => e.manufacturer_foreign_account_id === id);
				if (index !== -1) {
					draft.splice(index, 1);
				}
			}),
		);
	}

	function updateAccount(id: string, field: keyof Account, value: any) {
		setFieldValue(
			"accounts",
			produce(values.accounts, (draft) => {
				const account = draft.find((e) => e.manufacturer_foreign_account_id === id);
				if (account) {
					account[field] = value;
				}
			}),
		);
	}

	return {
		addTel,
		delTel,
		updateTel,
		addAccount,
		delAccount,
		updateAccount,
	};
}
