import { stockTransfer } from "../../moo-enums";
import { useIsEdit } from "../../moo-use-is-edit";
import { useForm } from "./useForm";

export function useLockStock(): boolean {
	const { initialValues } = useForm();
	const isEdit = useIsEdit();

	return isEdit && initialValues.state === stockTransfer.State.Value.Verified;
}
