import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "creator_name",
			label: "創建人",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "sales_name",
			label: "業務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "manufacturer",
			label: "廠商",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "order_goods_date",
			label: "訂貨日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "deliver_goods_date",
			label: "交貨日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state",
			label: "狀態",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "state_warehouse",
			label: "倉管確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "warehouse_name",
			label: "收貨倉庫",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "order_id",
			label: "連結訂購單",
			align: "left",
			style: {
				minWidth: 100,
			},
		},
	];
}
