import type { CellType } from "@yachelee/koden-components/koden-table";
import { DateFormat } from "../moo-shared";
import { Row } from "./@types";

export function useCells(): CellType[] {
	return [
		{
			id: "date",
			align: "left",
			component: ({ date }: Row) => {
				return <DateFormat>{date}</DateFormat>;
			},
		},
		{
			id: "content",
			align: "left",
			component: ({ content }: Row) => {
				return <>{content}</>;
			},
		},
		{
			id: "message_count",
			align: "left",
			component: ({ message_count }: Row) => {
				return <div>{message_count}</div>;
			},
		},
	];
}
