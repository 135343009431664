import Cookies from "js-cookie";
import queryString from "query-string";
import { Me } from "./components/moo-components/moo-me-provider";
import { IMG_PREFIX_URL, REFRESH_TOKEN_KEY, STATIC_PREFIX_URL } from "./config";
import { createAjax, getAuthorizationHeader } from "./hooks/useAjax";
import { path as LoginPath } from "./routes/LoginRoute";

class Utils {
	static accessToken: string | null = null;
	static isRefreshingToken: boolean = false;
	static prefetchedMe: Me | null = null;

	static setPrefetchedMe(me: Me | null) {
		this.prefetchedMe = me;
	}

	static alert(str = "") {
		window.alert(str);
	}

	static locationHref(href = "") {
		window.location.href = href;
	}

	static refreshPage() {
		window.location.reload();
	}

	static redirectToLoginPage() {
		this.locationHref(LoginPath);
	}

	static isHttps(): boolean {
		return window.location.protocol === "https:";
	}

	static getAbsoluteImageUrl(src: string): string {
		return `${IMG_PREFIX_URL}/${src}`;
	}

	static getAbsoluteStaticUrl(src: string): string {
		return `${STATIC_PREFIX_URL}/${src}`;
	}

	static removeAbsoluteImageUrl(src: string): string {
		if (src.startsWith(IMG_PREFIX_URL!, 0)) {
			return src.substring(IMG_PREFIX_URL!.length + 1, src.length);
		} else {
			return src;
		}
	}

	static getRefreshToken(): string | null {
		return Cookies.get(REFRESH_TOKEN_KEY) || null;
	}

	static deleteRefreshToken() {
		Cookies.remove(REFRESH_TOKEN_KEY);
	}

	static setRefreshToken(refreshToken: string) {
		const options: Cookies.CookieAttributes = {
			expires: 7,
		};
		if (window.location.protocol === "https:") {
			options.secure = true;
		}
		Cookies.set(REFRESH_TOKEN_KEY, refreshToken, options);
	}

	static setAccessToken(_accessToken: string | null) {
		this.accessToken = _accessToken;
	}

	static getAccessToken() {
		return this.accessToken;
	}

	static getNewAccessToken() {
		const failedToRefresh = async () => {
			return Promise.reject("Failed to get a new access token");
		};
		const refreshToken = this.getRefreshToken();

		if (!refreshToken) {
			Utils.isRefreshingToken = false;
			return failedToRefresh();
		} else {
			Utils.isRefreshingToken = true;
			return createAjax()
				.post("/auth/refresh_token", {}, { headers: getAuthorizationHeader(refreshToken) })
				.then((response) => {
					return response.data?.access_token;
				})
				.catch(failedToRefresh)
				.finally(() => {
					Utils.isRefreshingToken = false;
				});
		}
	}

	static mockHistoryPush(path: string, query: Record<string, never>) {
		window.history.pushState({}, "", `${path}?${queryString.stringify(query)}`);
	}

	static getTodayFullYear(): number {
		return new Date().getFullYear();
	}

	static getTodayHumanMonth(): number {
		return new Date().getMonth() + 1;
	}

	static isDateString(str: string | null): boolean {
		if (str === null) return false;

		const date = Date.parse(str);
		return !isNaN(date);
	}

	static isDateInvalid(date: Date): boolean {
		return isNaN(date.getTime());
	}

	static toDateString(date: Date): string {
		const newDate = new Date(date);
		const offset = newDate.getTimezoneOffset();
		newDate.setMinutes(newDate.getMinutes() - offset);
		return newDate.toISOString().substring(0, 10);
	}
}

export default Utils;
