export enum Value {
	Customizing = "CUSTOMIZING",
	ReadyToShip = "READY_TO_SHIP",
	Shipped = "SHIPPED",
	Returned = "RETURNED",
	ShippedPartially = "SHIPPED_PARTIALLY",
}

export function getValues(): Value[] {
	return [Value.Customizing, Value.ReadyToShip, Value.Shipped, Value.Returned, Value.ShippedPartially];
}

export enum Label {
	Customizing = "訂製中",
	ReadyToShip = "待出貨",
	Shipped = "已出貨",
	Returned = "已退單",
	ShippedPartially = "部分出貨",
}

export function getLabel(str: Value): Label {
	switch (str) {
		case Value.Customizing:
			return Label.Customizing;
		case Value.ReadyToShip:
			return Label.ReadyToShip;
		case Value.Shipped:
			return Label.Shipped;
		case Value.Returned:
			return Label.Returned;
		case Value.ShippedPartially:
			return Label.ShippedPartially;
	}
}
