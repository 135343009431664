import { gql } from "@apollo/client";

export const queryField = `
    branch {
      id
      name
    }
    status
    create_date
    update_date
    state_purchase
    propose_date
    note
    sales {
        id
        tel
    }
    order_ids {
        request_buy_order_id_id
        value
    }
    foreign_manufacturer {
        id
        name
        tels {
          tel
        }
        fax
        email
    }
    voucher_rows {
      voucher_id
    }
    
    item_infos {
      request_buy_item_info_id
      image
      website
    }
    items {
      request_buy_item_id
      id
      count
      item_spec {
        name
        cost
        material
        color
        size
        price
        purchase_price
        note
        image
        image_cut
        manufacturer{
          name
          id
          type
        }
        category {
          name
        }
      }
      item_custom {
        name
        cost
        material
        color
        size
        price
        purchase_price
        note
        image
        image_cut
        manufacturer{
          name
          id
          type
        }
        category {
          name
        }
      }
    }
`;

export const query = gql`
    query ($id: String!) {
        request_buys_by_pk(id: $id) {
            ${queryField}
        }
    }
`;
