import { gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useGraphQLRequest } from "../moo-graphql-provider";

export type Enum = {
	id: string;
	branch_id: string;
	status: Status;
	name: string;
	create_date: string;
	priority: number;
};

export function useFindEnumById<T extends Enum>(
	resource: string,
	additionalFields: string[] = [],
	idName: string = "id",
) {
	const { value: branch } = useBranch();
	const graphQLRequest = useGraphQLRequest();

	return (value: string): Promise<T | null> => {
		const query = gql`
        query ($where: ${resource}_bool_exp) {
        		${resource}(where: $where) {
								id
								branch_id
								status
								name
								${additionalFields.join("\n")}
								create_date
								priority
            }
        }
		`;
		const variables = {
			where: {
				[idName]: {
					_eq: value,
				},
				branch_id: {
					_eq: branch,
				},
			},
		};
		return graphQLRequest({ query, variables }).then((e) => {
			const arr = e[resource];
			if (arr.length === 0) {
				return null;
			} else {
				return arr[0];
			}
		});
	};
}
