import { MooDefectiveProductOne, One } from "../moo-defective-product-one";
import { MooUpdateFormNew } from "../moo-update-form-new";
import { Row } from "./findOne/Row";
import { mapperInitialValues } from "./findOne/mapperInitialValues";
import { query } from "./findOne/query";
import { DefectiveProductTable } from "./updateOne/DefectiveProductTable";
import { DetectiveProductItemTable } from "./updateOne/DetectiveProductItemTable";

export type MooDefectiveProductUpdateProps = {
	id: string;
	onCancel: () => void;
};

export function MooDefectiveProductUpdate({ id, onCancel }: MooDefectiveProductUpdateProps) {
	return (
		<MooUpdateFormNew<Row, One>
			id={id}
			onCancel={onCancel}
			operationName="defective-product-one"
			query={query}
			tableName="defective_products"
			mapperInitialValues={mapperInitialValues}
			// @ts-ignore
			Form={MooDefectiveProductOne}
			tables={[DetectiveProductItemTable, DefectiveProductTable]}
		/>
	);
}
