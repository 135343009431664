import { DocumentNode } from "@apollo/client";
import { FormikHelpers } from "formik";
import { ReactElement } from "react";
import { Branch, useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooLoading } from "../moo-loading/moo-loading";
import { Content } from "./Content";
import { useOnSubmit } from "./useOnSubmit";

export type ModelBase = {
	status: Status;
	branch: {
		id: Branch;
		name: string;
	};
};

export type FormData = {
	status: Status;
	create_date: string;
	update_date: string;
};

export type OnSubmitType<T extends ModelBase, U extends FormData> = (
	initialValue: U,
	updatedOne: U,
) => Promise<{ affected_rows: number; returning: T }>;

type FormProps<U extends FormData> = {
	id?: string;
	isLoading?: boolean;
	isEdit?: boolean;
	isCancelDisabled: boolean;
	isOkDisabled: boolean;
	onCancel: () => void;
	initialValues: U;
	onSubmit: (initialValue: U, helpers: FormikHelpers<U>) => void;
};

export type Form<U extends FormData> = (props: FormProps<U>) => ReactElement;

export type MapperInitialValues<T extends ModelBase, U extends FormData> = (model: T) => U;

export type MooUpdateFormNewProps<T extends ModelBase, U extends FormData> = {
	id: string;
	onCancel: () => void;
	operationName: string;
	query: DocumentNode;
	tableName: string;
	mapperInitialValues: MapperInitialValues<T, U>;
	Form: Form<U>;
	tables: unknown[];
};

export function MooUpdateFormNew<T extends ModelBase, U extends FormData>({
	id,
	onCancel,
	query,
	operationName,
	tableName,
	mapperInitialValues,
	Form,
	tables,
}: MooUpdateFormNewProps<T, U>) {
	const byPk = `${tableName}_by_pk`;
	const onSubmit = useOnSubmit<T>(id, tableName, tables);
	const { value: branch } = useBranch();
	const { isFetching, isLoading, data } = useGraphQLQuery({
		operationName,
		query,
		variables: {
			id,
		},
	});

	if (isLoading) {
		return null;
	}
	if (isFetching) {
		return <MooLoading />;
	}

	// @ts-ignore
	const record = data[byPk] as ModelBase;

	if (record === null) {
		return <div>找不到這筆資料</div>;
	} else if (record.status !== Status.Present) {
		return <div>此資料已被刪除</div>;
	} else if (record.branch.id !== branch) {
		return <div>此資料屬於{record.branch.name}</div>;
	}

	const initialValues = mapperInitialValues(record as T);

	return (
		<Content
			id={id}
			isLoading={isLoading}
			initialValues={initialValues}
			mapperInitialValues={mapperInitialValues}
			onCancel={onCancel}
			record={record as ModelBase}
			Form={Form}
			onSubmit={onSubmit}
		/>
	);
}
