import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { Query } from "../@types";
import { getSearchCondition } from "../getSearchCondition";

export function useConditions(query: Query) {
	const { value: branch } = useBranch();
	let conditions: Record<string, any> = {
		status: {
			_eq: Status.Present,
		},
		branch_id: {
			_eq: branch,
		},
	};

	if (query.type !== null) {
		conditions.type_id = {
			_eq: query.type,
		};
	}

	conditions = {
		...conditions,
		...getSearchCondition(query.search),
	};
	return conditions;
}
