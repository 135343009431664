import styled from "styled-components";

const Container = styled.a`
	color: #4672e8;
	font-family: Roboto, serif;
	font-size: 0.85rem;
	display: flex;
	align-items: center;

	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

type Props = {
	onClick: () => void;
};

const ClearAll = ({ onClick }: Props) => {
	return (
		<Container
			className="clear-all"
			onClick={(event) => {
				event.preventDefault();
				onClick();
			}}
		>
			清除
		</Container>
	);
};

export default ClearAll;
