import produce from "immer";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useOnArchived } from "../moo-archive-provider";
import { useBranch } from "../moo-branch-provider";
import { Status } from "../moo-enums";
import { useArchiveResource } from "../moo-hooks";
import { useMe } from "../moo-me-provider";
import { MooScheduleCard } from "../moo-schedule-card";
import { MooScheduleOne, One, shape } from "../moo-schedule-one";
import { useFindOne } from "../moo-shared";
import { useUpdateSchedule } from "./useUpdateSchedule";

export type MooScheduleViewProps = {
	id: string;
};

const resource = "schedules";
const operationName = `${resource}_by_pk`;

export function MooScheduleView({ id }: MooScheduleViewProps) {
	const newShape = produce(shape, (draft) => {
		// @ts-ignore
		draft.employee_id = null;
	});

	const { value: branch } = useBranch();
	const [isEditMode, setEditMode] = useState(false);
	const onArchived = useOnArchived();
	const updateSchedule = useUpdateSchedule();
	const me = useMe()!;
	const record = useFindOne<One>(id, "uuid!", newShape, operationName, {});
	const archiveResource = useArchiveResource(resource);

	useEffect(() => {
		record.refetch();
	}, [id]);

	if (record.isLoading || record.isError) {
		return null;
	} else {
		// @ts-ignore
		const initialValues = record.data![operationName];
		if (initialValues.status !== Status.Present) {
			return <div>此資料已被刪除</div>;
		} else if (initialValues.branch_id !== branch) {
			return <div>此資料屬於{_.get(record, `data.${operationName}.branch.name`, "")}</div>;
		}
		const owner = me.employee.id === initialValues.employee_id;

		if (isEditMode) {
			return (
				<MooScheduleOne
					isLoading={false}
					isCancelDisabled={false}
					isOkDisabled={false}
					okText="修改"
					initialValues={initialValues}
					onSubmit={async (toUpdateOne, helpers) => {
						await updateSchedule({ id, toUpdateOne, initialValues, helpers });
						await record.refetch();
						setEditMode(false);
					}}
					onCancel={() => {
						setEditMode(false);
					}}
				/>
			);
		} else {
			return (
				<MooScheduleCard
					{...initialValues}
					user_id={me.id}
					user_name={me.display_name}
					user_color={me.color}
					edit={owner}
					remove={owner}
					onEditClick={() => {
						setEditMode(true);
					}}
					onRemoveClick={async () => {
						if (await archiveResource({ id })) {
							onArchived(resource);
						}
					}}
				/>
			);
		}
	}
}
