import { object, SchemaOf } from "yup";
import { returnOrder } from "../../moo-enums";
import {
	arrayRequiredSchema,
	booleanOptional,
	enumRequired,
	numberOptional,
	statusSchema,
	stringOptional,
	stringRequired,
} from "../../moo-schemas";
import { One } from "../@types/one";
import { itemSchema } from "./itemSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	status: statusSchema,
	state_accountant: enumRequired(returnOrder.StateAccountant.Value),
	return_date: stringRequired("return_date"),
	order_id: stringRequired("order_id"),
	note: stringOptional,
	total_price: numberOptional,
	tax: numberOptional,
	order_type_name: stringOptional,
	order_customer_name: stringOptional,
	order_sales_name: stringOptional,
	order_sales_tel: stringOptional,
	order_deliver_date: stringOptional,
	order_is_include_tax: booleanOptional,
	order_payee_name: stringOptional,
	order_payee_bank_name: stringOptional,
	order_payee_account_number: stringOptional,
	order_deliver_address: stringOptional,
	order_description: stringOptional,
	order_order_note: stringOptional,
	note_price: stringOptional,
	create_date: stringOptional,
	update_date: stringOptional,
	// @ts-ignore
	items: arrayRequiredSchema(itemSchema, "items"),
});
