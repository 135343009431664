import * as pdfMake from "pdfmake/build/pdfmake";
import { pdfFonts } from "../../moo-config";
import { getCreditAmountString } from "../getCreditAmountString";
import { getDebitAmountString } from "../getDebitAmountString";
import { getRemainingAmountString } from "../getRemainingAmountString";
import { getTableRows } from "./getTableRows";
import { useGetData } from "./useGetData";

export function useDownloadVoucherPdf() {
	const getData = useGetData();

	return async () => {
		const {
			rows,
			aggregate: { debit_amount, credit_amount },
		} = await getData();
		const tableRows = getTableRows(rows);

		const debitAmountString = getDebitAmountString(debit_amount);
		const creditAmountString = getCreditAmountString(credit_amount);
		const remainAmountString = getRemainingAmountString({ debit_amount, credit_amount });

		pdfMake
			.createPdf(
				{
					content: [
						{
							text: "睦穆家具設計有限公司",
							fontSize: 20,
						},
						{
							table: {
								headerRows: 1,
								widths: ["auto", "auto", "auto", "*", "auto", "auto", "auto", "auto"],

								body: [["日期", "傳票編號", "科目代號", "科目名稱", "摘要", "借貸", "借方金額", "貸方金額"]].concat(
									tableRows,
								),
							},
						},
						{
							layout: "noBorders",
							table: {
								widths: ["*"],
								body: [
									[
										{
											text: `借方金額：${debitAmountString}`,
											alignment: "right",
										},
									],
									[
										{
											text: `貸方金額：${creditAmountString}`,
											alignment: "right",
										},
									],
									[
										{
											text: `餘額：${remainAmountString}`,
											alignment: "right",
										},
									],
								],
							},
						},
					],
					images: {},
					styles: {
						header: {
							fontSize: 16,
							bold: true,
						},
						warning: {
							color: "red",
						},
						bigger: {
							fontSize: 15,
							italics: true,
						},
						defaultTable: {
							margin: [0, 5, 0, 5],
						},
						tableHeader: {
							bold: true,
							fontSize: 13,
							color: "grey",
						},
					},
					defaultStyle: {
						columnGap: 15,
						fontSize: 10,
						font: "chineseFont",
					},
				},
				undefined,
				pdfFonts,
			)
			.download("moos-會計帳.pdf");
	};
}
