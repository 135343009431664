import { ItemType, manufacturer } from "../moo-enums";

export enum OrderBy {
	ID_ASC = "ID_ASC",
	ID_DESC = "ID_DESC",
}

export type Query = {
	type: ItemType;
	search: string | null;
	owner_id: string | null;
	category: string | null;
	sub_category: string | null;
	material: string | null;
	color: string | null;
	manufacturer_type: manufacturer.Value.Value | null;
	manufacturer_id: string | null;
	page: number;
	order_by: OrderBy[];
};

export type SpecOrCustomTableRow = {
	id: string;
	type: ItemType.Spec | ItemType.Custom;
	category: string;
	country: string;
	manufacturer_type: manufacturer.Value.Value;
	manufacturer_id: string;
	manufacturer: string;
	name: string;
	material: string;
	color: string;
	color_image: string;
	size: string;
	purchase_price: number;
	image_cut: string;
	image: string;
	price: number;
	cost: number;
	original_code: string | null;
	owner: {
		name: string | null;
	} | null;
};

export type Row = SpecOrCustomTableRow;
