import { object, SchemaOf } from "yup";
import { Status } from "../../moo-enums";
import { arrayRequiredSchema, enumRequired, numberOptional, stringRequired } from "../../moo-schemas";
import { One } from "../@types/one";
import { rowSchema } from "./rowSchema";

// @ts-ignore
export const schema: SchemaOf<One> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: enumRequired(Status),
	voucher_date: stringRequired("voucher_date"),
	debit_total: numberOptional,
	credit_total: numberOptional,
	rows: arrayRequiredSchema(rowSchema, "rows", "尚無傳票"),
});
