import { generatePath, useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooManufacturerForeignAdd } from "../components/moo-components/moo-manufacturer-foreign-add";
import { path as listPath } from "./ForeignManufacturerListRoute";
import { path as onePath } from "./UpdateForeignManufacturerRoute";

export const path = "/new-foreign-manufacturer";

const NewForeignManufacturer = () => {
	const navigate = useNavigate();
	const branch = useBranchInQuery();

	return (
		<MooManufacturerForeignAdd
			onSuccess={async (id) => {
				await navigate(`${generatePath(onePath, { id })}?branch=${branch}`);
			}}
			onCancel={async () => {
				await navigate(`${listPath}?branch=${branch}`);
			}}
		/>
	);
};

export default NewForeignManufacturer;
