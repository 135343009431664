import produce from "immer";
import { Item, One } from "./@types/one";

export class Calculator {
	static getItemTotalCost({ cost, count }: { cost: number; count: number }): number {
		return cost * count;
	}

	static getItemPriceTotal({ price_final, count }: { price_final: number; count: number }): number {
		return price_final * count;
	}

	static getItemPurchasePriceTotal({ purchase_price, count }: { purchase_price: number; count: number }): number {
		return purchase_price * count;
	}

	static getPriceTotal(items: Pick<Item, "price_final" | "count">[]): number {
		return items.map(this.getItemPriceTotal).reduce((a, b) => a + b, 0);
	}

	static getTotalCost(items: Pick<Item, "cost" | "count">[]): number {
		return items.map(this.getItemTotalCost).reduce((a, b) => a + b, 0);
	}

	static syncPrices(one: One): One {
		return produce(one, (draft) => {
			draft.items.forEach((item) => {
				item.price_total = this.getItemPriceTotal(item);
			});
			const totalCost = this.getTotalCost(draft.items);
			const priceTotal = this.getPriceTotal(draft.items);
			draft.cost = totalCost;
			draft.price_total = priceTotal;
			draft.price_discounted = priceTotal;
			draft.deposit = 0;
			draft.remaining_fee = draft.price_discounted - draft.deposit;
		});
	}
}
