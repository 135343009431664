import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "id",
			label: "報價單號",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "quotation_type",
			label: "類型",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "customer_name",
			label: "客戶",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "sales_name",
			label: "業務",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "quotation_date",
			label: "報價日期",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state",
			label: "狀態",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "state_manager",
			label: "主管簽核",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "state_accountant",
			label: "會計確認",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
		{
			id: "price_total",
			label: "金額",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "order_id",
			label: "連結訂單",
			align: "left",
			style: {
				minWidth: 90,
			},
		},
	];
}
