import styled from "@emotion/styled";
import { KodenMultilineField } from "@yachelee/koden-components/koden-multiline-field";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import { One } from "..";
import { useGetFieldError } from "../../moo-shared";
import { useForm } from "../hooks/useForm";

const WEB_BREAKPOINT = "1024px";
const TABLET_BREAKPOINT = "768px";

const HighlightFieldOuterContainer = styled.div`
	background-color: #f2f3f5;
`;

const HighlightFieldInnerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px 15px;
	grid-template-rows: auto;
	grid-auto-flow: row;
	padding: 1rem;
	max-width: ${WEB_BREAKPOINT};
	background: #f2f3f5;
	border-radius: 0.2rem;

	@media screen and (min-width: ${TABLET_BREAKPOINT}) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.5rem;
	}

	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export function AccountantBlock() {
	const { values, setValues, setFieldValue } = useForm();
	const getFieldError = useGetFieldError<One>();

	const ids: (keyof One)[] = [
		"accountant_total_price",
		"accountant_tax",
		"accountant_freight",
		"accountant_miscellaneous_charge",
		"accountant_received",
		"accountant_payable",
	];
	const labels = ["合計", "稅金", "運費", "雜費", "已付款", "應付款"];
	const readOnlys = [true, false, false, false, false, true];

	return (
		<HighlightFieldOuterContainer>
			<HighlightFieldInnerContainer>
				{ids.map((id, index) => {
					const label = labels[index];
					return (
						<KodenNumberField
							id={id}
							key={id}
							error={getFieldError(id)}
							value={values[id] as number}
							label={label}
							readOnly={readOnlys[index]}
							disabled={readOnlys[index]}
							hasDollar
							outlined
							step={1}
							min={0}
							max={id === "accountant_received" ? values.accountant_total_price : Number.MAX_SAFE_INTEGER}
							onChange={(value) => {
								const toValue: One = {
									...values,
									[id]: value,
								};

								if (id === "accountant_received") {
									toValue.accountant_payable = toValue.accountant_total_price - toValue.accountant_received;
								}
								setValues(toValue);
							}}
						/>
					);
				})}
				<KodenMultilineField
					id="accountant_note"
					label="備註"
					style={{ gridColumn: "1 / -1" }}
					outlined
					margin="none"
					rows={2}
					defaultValue={values.accountant_note}
					error={getFieldError("accountant_note")}
					onChange={(accountant_note) => {
						setFieldValue("accountant_note", accountant_note);
					}}
				/>
			</HighlightFieldInnerContainer>
		</HighlightFieldOuterContainer>
	);
}
