import { MooPdfButton } from "../moo-config";
import { useOnPdfClick } from "./hooks/useOnPdfClick";

type Props = {
	id: string;
};

export function PdfButton({ id }: Props) {
	const onPdfClick = useOnPdfClick();

	return <MooPdfButton onClick={() => onPdfClick(id)} />;
}
