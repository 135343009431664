import { gql } from "@apollo/client";
import { useGraphQLRequest } from "../moo-graphql-provider";
import { useSuccess } from "../moo-notification-provider";
import { Row } from "./@types";

export function useUpdateRow(year: number, month: number, onOk: () => void) {
	const success = useSuccess();
	const graphQLRequest = useGraphQLRequest();

	return async (row: Row) => {
		const variables = {
			objects: [
				{
					year,
					month,
					employee_id: row.id,
					perfect_attendance: row.perfect_attendance,
					gift_money: row.gift_money,
					other_addition: row.other_addition,
					late: row.late,
					other_deduction: row.other_deduction,
					note: row.note,
					pto: row.pto,
				},
			],
			onConflict: {
				constraint: "employee_salaries_pkey",
				update_columns: [
					"perfect_attendance",
					"gift_money",
					"other_addition",
					"late",
					"other_deduction",
					"pto",
					"note",
				],
			},
		};
		await graphQLRequest({
			query: gql`
				mutation ($objects: [employee_salaries_insert_input!]!, $onConflict: employee_salaries_on_conflict) {
					insert_employee_salaries(objects: $objects, on_conflict: $onConflict) {
						affected_rows
					}
				}
			`,
			variables,
		});
		success("編輯成功！");
		onOk();
	};
}
