import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { useItemDialogChoose } from "../../moo-dialog-item-choose";
import ItemList from "../components/ItemList";
import { useChooseItems } from "../hooks/useChooseItems";

export function ItemsBlock() {
	const chooseItems = useChooseItems();
	const { open: openItemChooseDialog, component: itemDialogChooseComponent } = useItemDialogChoose(
		"item-dialog-choose",
		chooseItems,
	);

	return (
		<>
			{itemDialogChooseComponent}
			<div style={{ marginBottom: "2rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>採購商品</h1>
					<KodenIconTextButton
						text="匯入採購商品"
						onClick={() => {
							openItemChooseDialog();
						}}
					/>
				</header>
				<ItemList />
			</div>
		</>
	);
}
