import * as _ from "lodash";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { MooListTypeableSelectField } from "../moo-list-typeable-select-field";
import { Permission, usePermissions } from "../moo-me-provider";
import { MooNoAuthPage } from "../moo-no-auth-page";
import { Query } from "./@types";
import { useCells } from "./hooks/useCells";
import { useConditions } from "./hooks/useConditions";
import { useHeaders } from "./hooks/useHeaders";

export type MooCustomerListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

const resource = "customers";

export const MooCustomerList = ({ onAdd, onRowClick, store }: MooCustomerListProps) => {
	const itemStore = store();
	const cells = useCells();
	const headers = useHeaders();
	const conditions = useConditions(itemStore.query);

	const permissions = usePermissions();
	if (!permissions.includes(Permission.CustomerListRead)) {
		return <MooNoAuthPage />;
	}

	const { query, setQuery } = itemStore;

	return (
		<MooList
			showAddButton={permissions.includes(Permission.CustomerAdd)}
			resource={resource}
			conditions={conditions}
			graphQLColumns={`{
				id
				number
				name
				customer_type {
					id
					name
				}
				gender
				vip
				tels{
					customer_tel_id
					tel
				}
				birthday
				email
				address
				line
			}`}
			mapData={(e) => {
				return {
					...e,
					type: _.get(e, "customer_type.name", ""),
					tel: _.get(e, "tels.0.tel", ""),
				};
			}}
			addLabel="新增客戶"
			onAdd={onAdd}
			filterNode={
				<MooListTypeableSelectField
					id="resource_types"
					resource="customer_types"
					label="類型"
					value={query.type}
					onChange={(value) => setQuery({ type: value as string, page: 0 })}
				/>
			}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
		/>
	);
};
