import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import produce from "immer";
import { useState } from "react";
import { MooDialogItemChoose, Row, useStore as useItemChooseStore } from "../../../moo-dialog-item-choose";
import { AttachItemRow, attachItems, getModelItemId } from "../../../moo-shared";
import { One } from "../../@types/one";
import { useForm } from "../../hooks/useForm";
import { useSyncTotalPrice } from "../../hooks/useSyncTotalPrice";
import ItemList from "./ItemList";

export function ChooseItemArea() {
	const syncTotalPrice = useSyncTotalPrice();
	const { values, setValues } = useForm();
	const [open, setOpen] = useState(false);
	const { clearChoose } = useItemChooseStore();

	return (
		<div style={{ marginBottom: "2rem" }}>
			<MooDialogItemChoose
				id="dialog-open-add-item"
				open={open}
				onClose={() => {
					setOpen(false);
					clearChoose();
				}}
				onOk={(rows: Row[]) => {
					setOpen(false);

					const selectedItems: (AttachItemRow & { order_item_id: string })[] = rows.map((e) => ({
						...e,
						order_item_id: getModelItemId({ create_date: values.create_date, item_id: e.id }),
						count: 1,
						price_final: 0,
					}));

					const newItems = attachItems(values.items, selectedItems);
					const newValues = produce(values, (draft: One) => {
						// @ts-ignore
						draft.items = newItems;
						syncTotalPrice(draft);
					});
					setValues(newValues);

					clearChoose();
				}}
			/>
			<header
				style={{
					borderBottom: "1px solid #EBEBEB",
					display: "flex",
					justifyContent: "space-between",
					marginBottom: ".3rem",
				}}
			>
				<h1 style={{ fontSize: "1.5rem" }}>訂購商品</h1>
				<KodenIconTextButton
					text="新增商品"
					onClick={() => {
						clearChoose();
						setOpen(true);
					}}
				/>
			</header>
			<ItemList />
		</div>
	);
}
