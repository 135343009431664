import { MooGraphQLList } from "../moo-graphql-list";
import { useListGraphQLQuery, useListGraphQLVariables } from "../moo-shared";
import { MooListProps, Query } from "./@types";

export function MooList<T extends Query>({
	showAddButton,
	clear,
	filterNode,
	addLabel,
	query,
	setQuery,
	onAdd,
	resource,
	headers,
	cells,
	onRowClick,
	mapData = (e) => e,
	graphQLColumns,
	conditions = {},
}: MooListProps<T>) {
	const graphQLQuery = useListGraphQLQuery(resource, graphQLColumns);
	const graphQLVariables = useListGraphQLVariables(10, query.page, conditions);

	return (
		<MooGraphQLList
			showAddButton={showAddButton}
			addLabel={addLabel}
			onAdd={onAdd}
			filterNode={filterNode}
			headers={headers}
			cells={cells}
			clear={clear}
			query={query}
			setQuery={setQuery}
			onRowClick={onRowClick}
			graphQLQuery={graphQLQuery}
			variables={graphQLVariables}
			mapData={(e) => {
				return {
					// @ts-ignore
					results: e[resource].map((item) => mapData(item, e)),
					total_count: e[`${resource}_aggregate`].aggregate.count,
				};
			}}
			operationName={resource}
			rowsPerPage={10}
		/>
	);
}
