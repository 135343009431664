import { useLocation, useParams } from "react-router-dom";

export function useMatchedPath(): string {
	const location = useLocation();
	const params = useParams();

	let res = location.pathname;

	for (const key in params) {
		const value = params[key]!;
		res = res.replace(value, `:${key}`);
	}
	return res;
}
