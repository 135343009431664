import { DocumentNode } from "@apollo/client";
import { useGraphQLQuery } from "../moo-graphql-provider";
import { MooTypeableSelect } from "../moo-typable-select/MooTypeableSelect";

type ValueType = string | null;

export type MooGraphqlSelectProps = {
	id: string;
	operationName: string;
	query: DocumentNode;
	variables: Record<any, any>;
	mapData: (data: any) => { results: { id: string; value: ValueType; label: string }[] };
	disabled?: boolean;
	label?: string;
	value: ValueType;
	onChange: (value: ValueType) => void;
	error?: string;
};

export function MooGraphqlSelect({
	id,
	label = "",
	disabled = false,
	value,
	onChange,
	operationName,
	query,
	variables,
	mapData,
	error = "",
}: MooGraphqlSelectProps) {
	const { isLoading, data } = useGraphQLQuery({ operationName, query, variables });
	if (isLoading) {
		return null;
	} else {
		const { results } = mapData(data);

		return (
			<MooTypeableSelect
				id={id}
				label={label}
				options={results}
				value={value}
				onChange={(value) => {
					onChange(value);
				}}
				disabled={disabled}
				error={error}
			/>
		);
	}
}
