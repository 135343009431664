import type { HeaderType } from "@yachelee/koden-components/koden-table";

export function useHeaders(): HeaderType[] {
	return [
		{
			id: "number",
			label: "編號",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "type",
			label: "類型",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "name",
			label: "姓名",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "gender",
			label: "性別",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "birthday",
			label: "生日",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "vip",
			label: "VIP",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "tel",
			label: "電話",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "email",
			label: "Email",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "address",
			label: "地址",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
		{
			id: "line",
			label: "LINE",
			align: "left",
			style: {
				minWidth: 65,
			},
		},
	];
}
