import { gql } from "@apollo/client";
import { useBranch } from "../moo-branch-provider";
import { useGraphQLRequest } from "../moo-graphql-provider";

export function useFindBranchWarehouses() {
	const branch = useBranch();
	const graphQLRequest = useGraphQLRequest();

	return async () => {
		const warehouses: { id: string; name: string }[] = (
			await graphQLRequest({
				query: gql`
					query ($where: warehouses_bool_exp) {
						warehouses(where: $where) {
							id
							name
						}
					}
				`,
				variables: {
					where: {
						branch_id: {
							_eq: branch.value,
						},
					},
				},
			})
		).warehouses;

		return warehouses;
	};
}
