import styled, { css } from "styled-components";
import { MooItemManufacturerSelect } from "../moo-item-manufacturer-select";

const TABLET_BREAKPOINT = "768px";
const FONT_SIZE_MOBILE = ".8rem";
const FONT_SIZE_TABLET = "1rem";

const selectFieldStyle = css`
	&.select-field {
		width: fit-content;
		color: #818080;
		font-weight: bold;
		border-radius: 25px;
		background-color: #ffffff;
		user-select: none;
		position: relative;

		display: flex;
		justify-content: space-between;

		height: 28px;
		padding: 0 0.5rem;
		font-size: ${FONT_SIZE_MOBILE};

		@media screen and (min-width: ${TABLET_BREAKPOINT}) {
			height: 38px;
			padding: 0 1rem;
			font-size: ${FONT_SIZE_TABLET};
		}

		& select.MuiNativeSelect-select {
			padding: 0 16px 0 0;
		}
	}
`;
export const MooStyledManufacturerSelect = styled(MooItemManufacturerSelect)`
	${selectFieldStyle};
`;
