import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { KodenDateField } from "@yachelee/koden-components/koden-date-field";
import { KodenIconTextButton } from "@yachelee/koden-components/koden-icon-text-button";
import { KodenNumberField } from "@yachelee/koden-components/koden-number-field";
import * as _ from "lodash";
import styled from "styled-components";
import { ProxyKodenTextField } from "../../../ProxyKodenTextField";
import { MooCustomerSelectField } from "../../moo-customer-select-field";
import { MooEmployeeSelectField } from "../../moo-employee-select-field";
import { MooEnumTypeableSelectField } from "../../moo-enum-typeable-select-field";
import { svc } from "../../moo-enums";
import { MooManufacturerLogisticDriverField } from "../../moo-manufacturer-logistic-driver-field";
import { useGetFieldError } from "../../moo-shared";
import { MooTypeableSelect } from "../../moo-typable-select/MooTypeableSelect";
import { One } from "../@types/one";
import FieldContainer from "../components/FieldContainer";
import { useForm } from "../hooks/useForm";
import {
	useAddItem,
	useDelItem,
	useUpdateCustomerId,
	useUpdateDriverId,
	useUpdateDriverLogisticsManufacturerId,
	useUpdateItem,
	useUpdateOwnerId,
	useUpdateSalesId,
} from "../hooks/useUpdate";

const WEB_BREAKPOINT = "1024px";

const TextFieldEnd = styled(ProxyKodenTextField)`
	@media screen and (min-width: ${WEB_BREAKPOINT}) {
		grid-column-end: span 2;
	}
`;

const Container = styled.div`
	position: relative;
	padding: 1rem 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr) auto;
	grid-gap: 1rem;
`;

const DeleteBtn = styled(CloseSharpIcon)`
	align-self: center;
	cursor: pointer;
`;

export function BasicBlock() {
	const { errors, values: one, setFieldValue } = useForm();
	const getFieldError = useGetFieldError<One>();
	const typeLabel = svc.Type.get(one.type);
	const updateCustomerId = useUpdateCustomerId();
	const updateSalesId = useUpdateSalesId();
	const updateOwnerId = useUpdateOwnerId();
	const updateDriverLogisticsManufacturerId = useUpdateDriverLogisticsManufacturerId();
	const addItem = useAddItem();
	const updateItem = useUpdateItem();
	const delItem = useDelItem();
	const updateDriverId = useUpdateDriverId();

	return (
		<>
			<FieldContainer>
				<MooTypeableSelect
					id="type"
					label="服務類型"
					options={[
						{ value: null, label: "請選擇" },
						{ value: svc.Type.Value.MAINTAIN, label: svc.Type.Label.MAINTAIN },
						{ value: svc.Type.Value.TRANSPORT, label: svc.Type.Label.TRANSPORT },
						{ value: svc.Type.Value.LEND, label: svc.Type.Label.LEND },
					]}
					value={one.type?.toString() || null}
					error={getFieldError("type")}
					onChange={(type) => {
						setFieldValue("type", type);
					}}
				/>
				<KodenDateField
					id="from_date"
					label={`${typeLabel}時間（起）`}
					error={getFieldError("from_date")}
					value={new Date(one.from_date)}
					onChange={(value) =>
						setFieldValue(
							"from_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						)
					}
				/>
				<KodenDateField
					id="to_date"
					label={`${typeLabel}時間（迄）`}
					error={getFieldError("to_date")}
					value={new Date(one.to_date)}
					onChange={(value) =>
						setFieldValue(
							"to_date",
							value === null ? null : `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
						)
					}
				/>
			</FieldContainer>
			<FieldContainer>
				<MooCustomerSelectField
					id="customer_id"
					label="客戶"
					variant="outlined"
					value={one.customer_id}
					error={getFieldError("customer_id")}
					onChange={updateCustomerId}
				/>
				<MooEmployeeSelectField
					id="sales_id"
					label="業務"
					value={one.sales_id}
					error={getFieldError("sales_id")}
					// @ts-ignore
					onChange={updateSalesId}
				/>
				<MooEmployeeSelectField
					id="owner_id"
					label="承辦人"
					value={one.owner_id}
					error={getFieldError("owner_id")}
					onChange={(e) => updateOwnerId(e as string)}
				/>
				<ProxyKodenTextField
					id="address"
					label="地址"
					outlined
					margin="none"
					value={one.address}
					error={getFieldError("address")}
					onChange={(value) => setFieldValue("address", value)}
				/>
			</FieldContainer>
			<FieldContainer>
				<TextFieldEnd
					id="description"
					label="服務簡述"
					outlined
					margin="none"
					value={one.description}
					error={getFieldError("description")}
					// @ts-ignore
					onChange={(value) => setFieldValue("description", value)}
				/>
				<TextFieldEnd
					id="note"
					label="備註"
					outlined
					margin="none"
					value={one.note}
					error={getFieldError("note")}
					// @ts-ignore
					onChange={(value) => setFieldValue("note", value)}
				/>
			</FieldContainer>
			<FieldContainer>
				<MooEnumTypeableSelectField
					id="driver_logistics_manufacturer_id"
					resource="manufacturer_logistics"
					label="司機物流廠商"
					value={one.driver_logistics_manufacturer_id}
					error={getFieldError("driver_logistics_manufacturer_id")}
					// @ts-ignore
					onChange={updateDriverLogisticsManufacturerId}
				/>
				{one.driver_logistics_manufacturer_id !== null && (
					<>
						<MooManufacturerLogisticDriverField
							id="driver_id"
							label="司機"
							manufacturer_logistic_id={one.driver_logistics_manufacturer_id}
							value={one.driver_id}
							error={getFieldError("driver_id")}
							onChange={updateDriverId}
						/>
						<TextFieldEnd
							id="driver_tel"
							label="電話"
							outlined
							margin="none"
							value={one.driver_tel}
							error=""
							disabled
							readOnly
							onChange={() => {}}
						/>
					</>
				)}
			</FieldContainer>
			<div style={{ marginBottom: "2rem" }}>
				<header
					style={{
						borderBottom: "1px solid #EBEBEB",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: ".3rem",
					}}
				>
					<h1 style={{ fontSize: "1.5rem" }}>{`${typeLabel}品項`}</h1>
					<KodenIconTextButton text="新增品項" onClick={() => addItem()} />
				</header>
				{one.items && one.items.length > 0 ? (
					one.items.map(({ svc_item_id, name, count, price }, index) => {
						const error = {
							name: _.get(errors, `items.${index}.name`, ""),
						};

						return (
							<Container key={svc_item_id}>
								<ProxyKodenTextField
									id={`${svc_item_id}_name`}
									label="品項"
									margin="none"
									value={name}
									error={error.name}
									onChange={(value) => updateItem(svc_item_id, "name", value)}
								/>
								<KodenNumberField
									id={`count_${svc_item_id}`}
									min={1}
									max={Number.MAX_SAFE_INTEGER}
									label="數量"
									margin="none"
									value={count}
									error=""
									onChange={(value) => updateItem(svc_item_id, "count", value)}
								/>
								<KodenNumberField
									id={`price_${svc_item_id}`}
									min={0}
									max={Number.MAX_SAFE_INTEGER}
									label="報價"
									hasDollar
									margin="none"
									value={price}
									error=""
									onChange={(value) => updateItem(svc_item_id, "price", value)}
								/>
								<DeleteBtn
									id={`bin_${svc_item_id}`}
									fontSize="small"
									color="disabled"
									onClick={() => {
										if (window.confirm("確定要刪除此商品?")) {
											delItem(svc_item_id);
										}
									}}
								>
									x
								</DeleteBtn>
							</Container>
						);
					})
				) : (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							padding: "1.5rem 0 0 0",
							color: "#C4C4C4",
						}}
					>{`尚未匯入${typeLabel}品項`}</div>
				)}
			</div>
		</>
	);
}
