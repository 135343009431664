import usePage from "./usePage";
import useURLSearchParams from "./useURLSearchParams";

const useQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();

	return {
		search: urlSearchParams.get("search") || null,
		type: urlSearchParams.get("type") || null,
		page,
	};
};

export default useQuery;
