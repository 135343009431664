import { useNavigate } from "react-router-dom";
import { useBranchInQuery } from "../components/MainLayout/hooks/useBranchInQuery";
import { MooItemCategoryMain } from "../components/moo-components/moo-item-category-main";
import { prefixPath } from "./ItemSubCategoryRoute";

export const path = "/item/category";

const ItemMainCategoryRoute = () => {
	const navigate = useNavigate();
	const branch = useBranchInQuery();

	return (
		<MooItemCategoryMain
			onClick={async (id) => {
				await navigate(`${prefixPath}/${id}?branch=${branch}`);
			}}
		/>
	);
};

export default ItemMainCategoryRoute;
