import { Fragment } from "react";
import { useBranch } from "../moo-branch-provider";
import { StoreType } from "../moo-hooks";
import { MooList } from "../moo-list";
import { Query } from "./@types";
import { useCells } from "./useCells";
import { useHeaders } from "./useHeaders";

export type MooWeeklyBlogListProps = {
	store: StoreType<Query>;
	onAdd: () => void;
	onRowClick: (row: { id: string }, e: MouseEvent) => void;
};

export const MooWeeklyBlogList = ({ onAdd, onRowClick, store }: MooWeeklyBlogListProps) => {
	const itemStore = store();
	const { value: branch } = useBranch();
	const cells = useCells();
	const headers = useHeaders();
	const conditions = {
		branch_id: {
			_eq: branch,
		},
	};

	return (
		<MooList
			showAddButton={true}
			resource="blogs"
			graphQLColumns={`{
				id
				content
				date
				messages_aggregate{
					aggregate{
						count
					}
				}
			}`}
			mapData={({ id, content, date, blog_messages_aggregate }) => {
				return {
					id,
					content,
					date,
					message_count: blog_messages_aggregate?.aggregate?.count || 0,
				};
			}}
			addLabel="新增日誌"
			onAdd={onAdd}
			filterNode={<Fragment />}
			conditions={conditions}
			headers={headers}
			cells={cells}
			clear={itemStore.clear}
			query={itemStore.query}
			setQuery={itemStore.setQuery}
			onRowClick={onRowClick}
		/>
	);
};
