import { ListType } from "@yachelee/koden-components/koden-layout/@types/listType";
import { path as SvcListRoutePath } from "../../../routes/svc/ListRoute";
import { path as SvcNewRoutePath } from "../../../routes/svc/NewRoute";
import { path as SvcUpdateRoutePath } from "../../../routes/svc/UpdateRoute";
import { useNavigationStore } from "../../../stores/useNavigationStore";
import Utils from "../../../utils";
import getPageTree from "../../Breadcrumbs/getPageTree";
import { Permission, usePermissions } from "../../moo-components/moo-me-provider";
import { useHistoryPushWithBranch } from "./useHistoryPushWithBranch";
import { useIsSelected } from "./useIsSelected";

export function useServices(): ListType[] {
	const permissions = usePermissions();
	const historyPushWithBranch = useHistoryPushWithBranch();
	const navigationStore = useNavigationStore();
	const isSelected = useIsSelected();

	if (!permissions.includes(Permission.SvcListRead)) return [];

	return [
		{
			id: "service",
			txt: "服務",
			icon: Utils.getAbsoluteStaticUrl("icons/svc.svg"),
			isLine: false,
			async onClick() {
				navigationStore.toggleSvc();
			},
			open: true,
			openChildren: navigationStore.svc,
			children: [
				{
					id: SvcListRoutePath,
					txt: getPageTree()[SvcListRoutePath].useText(),
					icon: "",
					isSelected: isSelected([SvcListRoutePath, SvcNewRoutePath, SvcUpdateRoutePath]),
					async onClick() {
						await historyPushWithBranch(SvcListRoutePath);
					},
				},
			],
		},
	];
}
