import * as jsonPatch from "fast-json-patch";
import type { Operation } from "fast-json-patch/module/core";

export type OperationType = Operation & { value: any };

export type OneBase = {
	update_date: string;
};

export abstract class MooGqlTable<One extends OneBase> {
	public readonly id: string | null = null;
	public readonly initialValues: One = null as unknown as One;
	public readonly toUpdateOne: One = null as unknown as One;
	public readonly operations: OperationType[] = [];

	constructor({ id, initialValues, toUpdateOne }: { id: string; initialValues: One; toUpdateOne: One }) {
		this.id = id;
		this.initialValues = initialValues;
		this.toUpdateOne = toUpdateOne;
		this.operations = jsonPatch.compare(initialValues, toUpdateOne) as OperationType[];
	}
	abstract getTableName(): string;
	abstract getQueryFields(): string;

	getQuery(): string {
		return `
			update_${this.getTableName()}(where: $${this.getTableName()}_where, _set: $${this.getTableName()}_set) {
					affected_rows
					returning {
							${this.getQueryFields()}
					}
			}
		`;
	}

	getMutationVariables(): string[] {
		return [
			`$${this.getTableName()}_where: ${this.getTableName()}_bool_exp!`,
			`$${this.getTableName()}_set: ${this.getTableName()}_set_input`,
		];
	}

	abstract isColumn(column: string): boolean;

	getVariables(): Record<string, any> {
		const set = {
			update_date: this.toUpdateOne.update_date,
		};

		this.operations
			.filter(({ path }) => path.split("/").slice(1).length === 1)
			.forEach((operation) => {
				const { op, path, value } = operation;
				const columns = path.split("/").slice(1);
				if (op === "replace") {
					const column = columns[0];

					//if the field isn't in the graphql result, do not update
					if (this.isColumn(column)) {
						// @ts-ignore
						set[column] = value;
					}
				}
			});

		return {
			[`${this.getTableName()}_where`]: {
				id: {
					_eq: this.id,
				},
				update_date: {
					_eq: this.initialValues.update_date,
				},
			},
			[`${this.getTableName()}_set`]: set,
		};
	}
}
