export function useListGraphQLVariables(rowsPerPage: number, page: number, conditions: Record<string, any>) {
	return {
		limit: rowsPerPage,
		offset: page * rowsPerPage,
		where: conditions,
		order_by: [
			{
				create_date: "desc",
			},
		],
	};
}
