import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class ReturnGoodsTable extends MooGqlTable<FindOneRecord> {
	isColumn(column: string): boolean {
		switch (column) {
			case "state_accountant":
			case "state_warehouse":
			case "return_date":
			case "order_id":
			case "order_note":
			case "tax_price":
			case "total_price":
			case "received":
			case "receivables":
			case "handling_fee":
			case "freight":
			case "miscellaneous_charge":
			case "commission_refund":
			case "note":
			case "memo":
				return true;
		}
		return false;
	}

	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "return_goods";
	}
}
