import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { useDownloadVoucherPdf } from "./useDownloadVoucherPdf";

export function PdfDownloadButton() {
	const downloadVoucherPdf = useDownloadVoucherPdf();

	return (
		<Tooltip title="下載pdf" onClick={downloadVoucherPdf}>
			<IconButton>
				<BsFileEarmarkPdf style={{ color: "red", fontSize: "28px", height: "100%", cursor: "pointer" }} />
			</IconButton>
		</Tooltip>
	);
}
