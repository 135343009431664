import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useUpdatePriorities } from "../moo-hooks";
import { useSuccess } from "../moo-notification-provider";
import { Item } from "./Item";
import { useIdName } from "./hooks/useIdName";
import { useListData } from "./hooks/useListData";
import { useProps } from "./hooks/useProps";

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

export function List() {
	const { resource } = useProps();
	const success = useSuccess();
	const idName = useIdName();
	const { isLoading, data, setQueryData } = useListData();
	const updatePriorities = useUpdatePriorities(resource, idName);
	if (isLoading) {
		return null;
	}
	return (
		<DragDropContext
			onDragEnd={async ({ destination, source }) => {
				if (destination && source) {
					// @ts-ignore
					const sourceRecord = data[resource][source.index];
					// @ts-ignore
					const destinationRecord = data[resource][destination.index];

					if (sourceRecord[idName] !== destinationRecord[idName]) {
						// @ts-ignore
						const images = reorder(data[resource] as any[], source.index, destination.index);
						setQueryData({
							[resource]: images,
						});
						await updatePriorities(
							images.map(({ [idName]: id }, priority) => ({
								id,
								priority,
							})),
						);
						await success("更新成功！");
					}
				}
			}}
		>
			<Droppable droppableId="droppable">
				{(provided) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
						style={{ display: "flex", flexDirection: "column" }}
					>
						{/*@ts-ignore*/}
						{data[resource].map((image, index) => {
							const image_id = image[idName];
							const key = `draggable_${image_id}`;
							return (
								// @ts-ignore
								<Draggable key={key} draggableId={key} index={index}>
									{(draggableProvided) => (
										<div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
											<Item
												draggableProvided={draggableProvided}
												image={{
													...image,
													id: image_id,
												}}
											/>
										</div>
									)}
								</Draggable>
							);
						})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}
