import { useParams } from "react-router-dom";
import { accountTypes } from "../../account_types";
import { path as AccountTypeRootRoutePath } from "../../routes/AccountTypeRootRoute";
import { path as AccountTypeSubRoutePath } from "../../routes/AccountTypeSubRoute";
import { path as AccountTypesRoutePath } from "../../routes/AccountTypesRoute";
import { path as domesticManufacturerListRoutePath } from "../../routes/DomesticManufacturerListRoute";
import { path as employeeListPath } from "../../routes/EmployeeListRoute";
import { path as employeeTypesRoutePath } from "../../routes/EmployeeTypesRoute";
import { path as foreignManufacturerListRoutePath } from "../../routes/ForeignManufacturerListRoute";
import { path as homePath } from "../../routes/HomeRoute";
import { path as ItemMainCategoryRoutePath } from "../../routes/ItemMainCategoryRoute";
import { path as ItemSubCategoryRoutePath } from "../../routes/ItemSubCategoryRoute";
import { path as logisticsManufacturerListRoutePath } from "../../routes/LogisticsManufacturerListRoute";
import { path as newDomesticManufacturerRoutePath } from "../../routes/NewDomesticManufacturerRoute";
import { path as newEmployeeListPath } from "../../routes/NewEmployeeRoute";
import { path as newForeignManufacturerRoutePath } from "../../routes/NewForeignManufacturerRoute";
import { path as newLogisticsManufacturerRoutePath } from "../../routes/NewLogisticsManufacturerRoute";
import { path as PayeeCrudRoutePath } from "../../routes/PayeeCrudRoute";
import { path as QuotationTypesRoutePath } from "../../routes/QuotationTypesRoute";
import { path as SalaryListRoutePath } from "../../routes/SalaryListRoute";
import { path as updateDomesticManufacturerRoutePath } from "../../routes/UpdateDomesticManufacturerRoute";
import { path as editEmployeeRoutePath } from "../../routes/UpdateEmployeeRoute";
import { path as updateForeignManufacturerRoutePath } from "../../routes/UpdateForeignManufacturerRoute";
import { path as updateLogisticsManufacturerRoutePath } from "../../routes/UpdateLogisticsManufacturerRoute";
import { path as WarehouseCrudRoutePath } from "../../routes/WarehouseCrudRoute";
import { path as WarehouseStockListRoutePath } from "../../routes/WarehouseStockListRoute";
import { path as UserPerformanceListRoutePath } from "../../routes/account/AccountPerformanceListRoute";
import { path as UserPerformanceSubListRoutePath } from "../../routes/account/AccountPerformanceSubListRoute";
import { path as AllowanceListRoutePath } from "../../routes/allowance/ListRoute";
import { path as AllowanceNewRoutePath } from "../../routes/allowance/NewRoute";
import { path as AllowanceUpdateRoutePath } from "../../routes/allowance/UpdateRoute";
import { path as BlogListRoutePath } from "../../routes/blog/ListRoute";
import { path as BlogNewRoutePath } from "../../routes/blog/NewRoute";
import { path as BlogOneRoutePath } from "../../routes/blog/OneRoute";
import { path as customerTypesRoutePath } from "../../routes/customer/CustomerTypesRoute";
import { path as customerListPath } from "../../routes/customer/ListRoute";
import { path as newCustomerListPath } from "../../routes/customer/NewRoute";
import { path as editCustomerRoutePath } from "../../routes/customer/UpdateRoute";
import { path as DefectiveProductListRoutePath } from "../../routes/defective_product/ListRoute";
import { path as DefectiveProductNewRoutePath } from "../../routes/defective_product/NewRoute";
import { path as DefectiveProductUpdateRoutePath } from "../../routes/defective_product/UpdateRoute";
import { path as ExportGoodsListRoutePath } from "../../routes/export_goods/ListRoute";
import { path as ExportGoodsNewRoutePath } from "../../routes/export_goods/NewRoute";
import { path as ExportGoodsUpdateRoutePath } from "../../routes/export_goods/UpdateRoute";
import { path as ImportGoodsListRoutePath } from "../../routes/import_goods/ListRoute";
import { path as ImportGoodsNewRoutePath } from "../../routes/import_goods/NewRoute";
import { path as ImportGoodsUpdateRoutePath } from "../../routes/import_goods/UpdateRoute";
import { path as ItemCustomListRoutePath } from "../../routes/item_custom/ListRoute";
import { path as ItemCustomNewRoutePath } from "../../routes/item_custom/NewRoute";
import { path as ItemCustomUpdateRoutePath } from "../../routes/item_custom/UpdateRoute";
import { path as ItemSpecListRoutePath } from "../../routes/item_spec/ListRoute";
import { path as NewItemSpecRoutePath } from "../../routes/item_spec/NewRoute";
import { path as UpdateItemSpecRoutePath } from "../../routes/item_spec/UpdateRoute";
import { path as OrderListRoutePath } from "../../routes/order/ListRoute";
import { path as OrderNewRoutePath } from "../../routes/order/NewRoute";
import { path as OrderUpdateRoutePath } from "../../routes/order/UpdateRoute";
import { path as OrderGoodsListRoutePath } from "../../routes/order_goods/ListRoute";
import { path as OrderGoodsNewRoutePath } from "../../routes/order_goods/NewRoute";
import { path as OrderGoodsUpdateRoutePath } from "../../routes/order_goods/UpdateRoute";
import { path as PurchaseOrderListRoutePath } from "../../routes/purchase_order/ListRoute";
import { path as PurchaseOrderNewRoutePath } from "../../routes/purchase_order/NewRoute";
import { path as PurchaseOrderUpdateRoutePath } from "../../routes/purchase_order/UpdateRoute";
import { path as QuotationListRoutePath } from "../../routes/quotation/ListRoute";
import { path as QuotationNewRoutePath } from "../../routes/quotation/NewRoute";
import { path as QuotationUpdateRoutePath } from "../../routes/quotation/UpdateRoute";
import { path as RequestBuyListRoutePath } from "../../routes/request_buy/ListRoute";
import { path as RequestBuyNewRoutePath } from "../../routes/request_buy/NewRoute";
import { path as RequestBuyUpdateRoutePath } from "../../routes/request_buy/UpdateRoute";
import { path as ReturnGoodsListRoutePath } from "../../routes/return_goods/ListRoute";
import { path as ReturnGoodsNewRoutePath } from "../../routes/return_goods/NewRoute";
import { path as ReturnGoodsUpdateRoutePath } from "../../routes/return_goods/UpdateRoute";
import { path as ReturnOrderListRoutePath } from "../../routes/return_order/ListRoute";
import { path as ReturnOrderNewRoutePath } from "../../routes/return_order/NewRoute";
import { path as ReturnOrderUpdateRoutePath } from "../../routes/return_order/UpdateRoute";
import { path as RoleListRoutePath } from "../../routes/role/ListRoute";
import { path as RoleNewRoutePath } from "../../routes/role/NewRoute";
import { path as RoleOneRoutePath } from "../../routes/role/UpdateRoute";
import { path as ScheduleListRoutePath } from "../../routes/schedule/ListRoute";
import { path as ScheduleNewRoutePath } from "../../routes/schedule/NewRoute";
import { path as ScheduleOneRoutePath } from "../../routes/schedule/OneRoute";
import { path as StockTransferListRoutePath } from "../../routes/stock_transfer/ListRoute";
import { path as StockTransferNewRoutePath } from "../../routes/stock_transfer/NewRoute";
import { path as StockTransferUpdateRoutePath } from "../../routes/stock_transfer/UpdateRoute";
import { path as SvcListRoutePath } from "../../routes/svc/ListRoute";
import { path as SvcNewRoutePath } from "../../routes/svc/NewRoute";
import { path as SvcUpdateRoutePath } from "../../routes/svc/UpdateRoute";
import { path as VoucherListRoutePath } from "../../routes/voucher/ListRoute";
import { path as VoucherNewRoutePath } from "../../routes/voucher/NewRoute";
import { path as VoucherUpdateRoutePath } from "../../routes/voucher/UpdateRoute";
import type { Customer } from "../moo-components/moo-customer-one";
import type { Employee } from "../moo-components/moo-employee-one";
import type { One as ItemSpec } from "../moo-components/moo-item-spec-one";
import type { DomesticManufacturer } from "../moo-components/moo-manufacturer-domestic-one";
import type { ForeignManufacturer } from "../moo-components/moo-manufacturer-foreign-one";
import type { LogisticsManufacturer } from "../moo-components/moo-manufacturer-logistics-one";
import { getDateFormat } from "../moo-components/moo-shared";

export type PageType = {
	useText: (data?: any) => string;
	parent: string | null;
	resource: string | null;
	idType?: string;
	fields?: string[];
};

type PageTree = Record<string, PageType>;

const idStringType = "String!";

export function getPageTree(): PageTree {
	return {
		[homePath]: {
			useText: () => "首頁",
			parent: null,
			resource: null,
		},
		[customerListPath]: {
			useText: () => "客戶列表",
			parent: homePath,
			resource: null,
		},
		[newCustomerListPath]: {
			useText: () => "新增客戶",
			parent: customerListPath,
			resource: null,
		},
		[customerTypesRoutePath]: {
			useText: () => "客戶類型",
			parent: homePath,
			resource: null,
		},
		[AccountTypesRoutePath]: {
			useText: () => "科目管理",
			parent: homePath,
			resource: null,
		},
		[AccountTypeRootRoutePath]: {
			useText: () => {
				const { category } = useParams<{ category: string }>();
				const accountType = accountTypes.find((e) => e.id === category);
				return accountType?.label ?? "";
			},
			parent: AccountTypesRoutePath,
			resource: null,
		},
		[AccountTypeSubRoutePath]: {
			fields: ["name"],
			useText: (data: any) => {
				return data.name;
			},
			parent: AccountTypeRootRoutePath,
			resource: "account_types",
		},
		[editCustomerRoutePath]: {
			useText: (data: Customer) => {
				return data.name;
			},
			parent: customerListPath,
			resource: "customers",
			idType: "uuid!",
			fields: ["name"],
		},
		[domesticManufacturerListRoutePath]: {
			useText: () => "國內廠商",
			parent: homePath,
			resource: null,
		},
		[newDomesticManufacturerRoutePath]: {
			useText: () => "新增國內廠商",
			parent: domesticManufacturerListRoutePath,
			resource: null,
		},
		[updateDomesticManufacturerRoutePath]: {
			useText: (data: DomesticManufacturer) => {
				return data.name;
			},
			parent: domesticManufacturerListRoutePath,
			resource: "manufacturer_domestics",
			idType: "uuid!",
			fields: ["name"],
		},
		[foreignManufacturerListRoutePath]: {
			useText: () => "國外廠商",
			parent: homePath,
			resource: null,
		},
		[newForeignManufacturerRoutePath]: {
			useText: () => "新增國外廠商",
			parent: foreignManufacturerListRoutePath,
			resource: null,
		},
		[updateForeignManufacturerRoutePath]: {
			useText: (data: ForeignManufacturer) => {
				return data.name;
			},
			parent: foreignManufacturerListRoutePath,
			resource: "manufacturer_foreigns",
			idType: "uuid!",
			fields: ["name"],
		},

		[logisticsManufacturerListRoutePath]: {
			useText: () => "物流廠商",
			parent: homePath,
			resource: null,
		},
		[newLogisticsManufacturerRoutePath]: {
			useText: () => "新增物流廠商",
			parent: logisticsManufacturerListRoutePath,
			resource: null,
		},
		[updateLogisticsManufacturerRoutePath]: {
			useText: (data: LogisticsManufacturer) => {
				return data.name;
			},
			parent: logisticsManufacturerListRoutePath,
			resource: "manufacturer_logistics",
			idType: "uuid!",
			fields: ["name"],
		},
		"/manufacturer/types": {
			useText: () => "廠商類型",
			parent: homePath,
			resource: null,
		},
		"/manufacturer/types/:id": {
			useText: () => {
				const { id } = useParams<{ id: string }>();
				return `國${id === "domestic" ? "內" : "外"}廠商類型`;
			},
			parent: "/manufacturer/types",
			resource: null,
		},
		[newEmployeeListPath]: {
			useText: () => "新增員工",
			parent: employeeListPath,
			resource: null,
		},
		[employeeListPath]: {
			useText: () => "員工列表",
			parent: homePath,
			resource: null,
		},
		[editEmployeeRoutePath]: {
			useText: (data: Employee) => {
				return data.name;
			},
			parent: employeeListPath,
			resource: "employees",
			idType: "uuid!",
			fields: ["name"],
		},
		[employeeTypesRoutePath]: {
			useText: () => "職務類型",
			parent: homePath,
			resource: null,
		},
		[ItemSpecListRoutePath]: {
			useText: () => "規格商品",
			parent: homePath,
			resource: null,
		},
		[NewItemSpecRoutePath]: {
			useText: () => "新增規格商品",
			parent: ItemSpecListRoutePath,
			resource: null,
		},
		[UpdateItemSpecRoutePath]: {
			idType: idStringType,
			useText: (data: ItemSpec) => {
				return data.name;
			},
			parent: ItemSpecListRoutePath,
			resource: "item_specs",
			fields: ["name"],
		},
		[ItemCustomListRoutePath]: {
			useText: () => "訂製商品",
			parent: homePath,
			resource: null,
		},
		[ItemCustomNewRoutePath]: {
			useText: () => "新增訂製商品",
			parent: ItemCustomListRoutePath,
			resource: null,
		},
		[ItemCustomUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: ItemSpec) => {
				return data.name;
			},
			parent: ItemCustomListRoutePath,
			resource: "item_customs",
			fields: ["name"],
		},
		[ItemMainCategoryRoutePath]: {
			useText: () => "商品類型",
			parent: homePath,
			resource: null,
		},
		[ItemSubCategoryRoutePath]: {
			useText: ({ name }) => {
				return name;
			},
			parent: ItemMainCategoryRoutePath,
			resource: "item_categories",
			idType: "uuid!",
			fields: ["name"],
		},
		[QuotationListRoutePath]: {
			useText: () => "報價單",
			parent: homePath,
			resource: null,
		},
		[QuotationNewRoutePath]: {
			useText: () => "新增報價單",
			parent: QuotationListRoutePath,
			resource: null,
		},
		[QuotationUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: QuotationListRoutePath,
			resource: "quotations",
			fields: ["id"],
		},
		[OrderListRoutePath]: {
			useText: () => "訂購單",
			parent: homePath,
			resource: null,
		},
		[OrderNewRoutePath]: {
			useText: () => "新增訂購單",
			parent: OrderListRoutePath,
			resource: null,
		},
		[OrderUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: OrderListRoutePath,
			resource: "orders",
			fields: ["id"],
		},
		[RoleListRoutePath]: {
			useText: () => "角色列表",
			parent: homePath,
			resource: null,
		},
		[RoleNewRoutePath]: {
			useText: () => "新增角色",
			parent: RoleListRoutePath,
			resource: null,
		},
		[RoleOneRoutePath]: {
			useText: (data: { name: string }) => {
				return data.name;
			},
			parent: RoleListRoutePath,
			resource: "roles",
			idType: "uuid!",
			fields: ["name"],
		},
		[QuotationTypesRoutePath]: {
			useText: () => "類型管理",
			parent: homePath,
			resource: null,
		},
		[ReturnGoodsListRoutePath]: {
			useText: () => "退貨單",
			parent: homePath,
			resource: null,
		},
		[ReturnGoodsNewRoutePath]: {
			useText: () => "新增退貨單",
			parent: ReturnGoodsListRoutePath,
			resource: null,
		},
		[ReturnGoodsUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: ReturnGoodsListRoutePath,
			resource: "return_goods",
			fields: ["id"],
		},
		[OrderGoodsListRoutePath]: {
			useText: () => "訂貨單",
			parent: homePath,
			resource: null,
		},
		[OrderGoodsNewRoutePath]: {
			useText: () => "新增訂貨單",
			parent: OrderGoodsListRoutePath,
			resource: null,
		},
		[OrderGoodsUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: OrderGoodsListRoutePath,
			resource: "order_goods",
			fields: ["id"],
		},
		[PurchaseOrderListRoutePath]: {
			useText: () => "國外採購單",
			parent: homePath,
			resource: null,
		},
		[PurchaseOrderNewRoutePath]: {
			useText: () => "新增國外採購單",
			parent: PurchaseOrderListRoutePath,
			resource: null,
		},
		[PurchaseOrderUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: PurchaseOrderListRoutePath,
			resource: "purchase_orders",
			fields: ["id"],
		},
		[RequestBuyListRoutePath]: {
			useText: () => "國外請購單",
			parent: homePath,
			resource: null,
		},
		[RequestBuyNewRoutePath]: {
			useText: () => "新增國外請購單",
			parent: RequestBuyListRoutePath,
			resource: null,
		},
		[RequestBuyUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: RequestBuyListRoutePath,
			resource: "request_buys",
			fields: ["id"],
		},
		[ReturnOrderListRoutePath]: {
			useText: () => "退訂單",
			parent: homePath,
			resource: null,
		},
		[ReturnOrderNewRoutePath]: {
			useText: () => "新增退訂單",
			parent: ReturnOrderListRoutePath,
			resource: null,
		},
		[ReturnOrderUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: ReturnOrderListRoutePath,
			resource: "return_orders",
			fields: ["id"],
		},
		[ImportGoodsListRoutePath]: {
			useText: () => "進貨單",
			parent: homePath,
			resource: null,
		},
		[ImportGoodsNewRoutePath]: {
			useText: () => "新增進貨單",
			parent: ImportGoodsListRoutePath,
			resource: null,
		},
		[ImportGoodsUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: ImportGoodsListRoutePath,
			resource: "import_goods",
			fields: ["id"],
		},
		[ExportGoodsListRoutePath]: {
			useText: () => "出貨單",
			parent: homePath,
			resource: null,
		},
		[ExportGoodsNewRoutePath]: {
			useText: () => "新增出貨單",
			parent: ExportGoodsListRoutePath,
			resource: null,
		},
		[ExportGoodsUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: ExportGoodsListRoutePath,
			resource: "export_goods",
			fields: ["id"],
		},
		[DefectiveProductListRoutePath]: {
			useText: () => "瑕疵品",
			parent: homePath,
			resource: null,
		},
		[DefectiveProductNewRoutePath]: {
			useText: () => "新增瑕疵品",
			parent: DefectiveProductListRoutePath,
			resource: null,
		},
		[DefectiveProductUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: DefectiveProductListRoutePath,
			resource: "defective_products",
			fields: ["id"],
		},
		[WarehouseCrudRoutePath]: {
			useText: () => "倉庫管理",
			parent: homePath,
			resource: null,
		},
		[WarehouseStockListRoutePath]: {
			useText: () => "倉庫列表",
			parent: homePath,
			resource: null,
		},
		[SvcListRoutePath]: {
			useText: () => "服務",
			parent: homePath,
			resource: null,
		},
		[SvcNewRoutePath]: {
			useText: () => "新增服務",
			parent: SvcListRoutePath,
			resource: null,
		},
		[SvcUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: SvcListRoutePath,
			resource: "svcs",
			fields: ["id"],
		},
		[StockTransferListRoutePath]: {
			useText: () => "庫存調撥",
			parent: homePath,
			resource: null,
		},
		[StockTransferNewRoutePath]: {
			useText: () => "新增庫存調撥",
			parent: StockTransferListRoutePath,
			resource: null,
		},
		[StockTransferUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: StockTransferListRoutePath,
			resource: "stock_transfers",
			fields: ["id"],
		},
		[UserPerformanceListRoutePath]: {
			useText: () => "業績表",
			parent: homePath,
			resource: null,
		},
		[UserPerformanceSubListRoutePath]: {
			useText: (data: { name: string }) => {
				return `${data.name}業績表`;
			},
			parent: UserPerformanceListRoutePath,
			resource: "employees",
			idType: "uuid!",
			fields: ["name"],
		},
		[AllowanceListRoutePath]: {
			useText: () => "零用金",
			parent: homePath,
			resource: null,
		},
		[AllowanceNewRoutePath]: {
			useText: () => "新增零用金",
			parent: AllowanceListRoutePath,
			resource: null,
		},
		[AllowanceUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: AllowanceListRoutePath,
			resource: "allowances",
			fields: ["id"],
		},
		[SalaryListRoutePath]: {
			useText: () => "薪資",
			parent: homePath,
			resource: null,
		},
		[VoucherListRoutePath]: {
			useText: () => "傳票",
			parent: homePath,
			resource: null,
		},
		[VoucherNewRoutePath]: {
			useText: () => "新增傳票",
			parent: VoucherListRoutePath,
			resource: null,
		},
		[VoucherUpdateRoutePath]: {
			idType: idStringType,
			useText: (data: { id: string }) => {
				return data.id;
			},
			parent: VoucherListRoutePath,
			resource: "vouchers",
			fields: ["id"],
		},
		[ScheduleListRoutePath]: {
			useText: () => "週計畫",
			parent: homePath,
			resource: null,
		},
		[ScheduleNewRoutePath]: {
			useText: () => "新增週計畫",
			parent: ScheduleListRoutePath,
			resource: null,
		},
		[ScheduleOneRoutePath]: {
			useText: ({ start_date, end_date }: { start_date: string; end_date: string }) => {
				return `${getDateFormat(start_date)} - ${getDateFormat(end_date)}`;
			},
			parent: ScheduleListRoutePath,
			resource: "schedules",
			idType: "uuid!",
			fields: ["start_date", "end_date"],
		},
		[BlogListRoutePath]: {
			useText: () => "日誌",
			parent: homePath,
			resource: null,
		},
		[BlogNewRoutePath]: {
			useText: () => "新增日誌",
			parent: BlogListRoutePath,
			resource: null,
		},
		[BlogOneRoutePath]: {
			useText: ({ date }: { date: string }) => {
				return getDateFormat(date);
			},
			parent: BlogListRoutePath,
			resource: "blogs",
			idType: "uuid!",
			fields: ["date"],
		},
		[PayeeCrudRoutePath]: {
			useText: () => "收款人",
			parent: homePath,
			resource: null,
		},
	};
}

export default getPageTree;
