import { ReactNode, useState } from "react";
import { quotation } from "../moo-enums";
import { MooDialogQuotationChoose, Row, useStore } from "./";

type ReturnType = {
	component: ReactNode;
	open: () => void;
	close: () => void;
	isMultiple?: boolean;
};

export function useQuotationDialogChoose(
	id: string,
	onOk: (rows: Row[]) => void,
	isMultiple = true,
	states: quotation.State.Value[] = quotation.State.getValues(),
	state_managers: quotation.StateManager.Value[] = quotation.StateManager.getValues(),
	state_accountants: quotation.StateAccountant.Value[] = quotation.StateAccountant.getValues(),
): ReturnType {
	const { clearChoose } = useStore();
	const [open, setOpen] = useState(false);
	const close = () => {
		setOpen(false);
	};

	const component = (
		<MooDialogQuotationChoose
			id={id}
			isMultiple={isMultiple}
			open={open}
			onClose={close}
			states={states}
			stateManagers={state_managers}
			stateAccountants={state_accountants}
			onOk={async (rows) => {
				onOk(rows);
				close();
			}}
		/>
	);

	return {
		component,
		open() {
			clearChoose();
			setOpen(true);
		},
		close,
	};
}
