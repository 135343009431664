import produce from "immer";
import { One } from "..";
import { useFindCustomerById } from "../../moo-customer-one";
import { Row as ItemRow } from "../../moo-dialog-item-choose";
import { useFindEmployeeById } from "../../moo-employee-one";
import { exportGoods } from "../../moo-enums";
import { useFindEnumById } from "../../moo-hooks";
import { useRequestById as useFindLogisticsManufacturerById } from "../../moo-manufacturer-logistics-update";
import { useFindOrderById } from "../../moo-order-update";
import { attachItems, getModelItemId, uuidv4 } from "../../moo-shared";
import { Item, Stock } from "../@types/one";
import { useForm } from "./useForm";

function createStock(count: Item["count"]): Stock {
	return {
		export_good_item_stock_id: uuidv4(),
		warehouse_id: null,
		count,
	};
}

export function useUpdate() {
	const { values, initialValues, setValues } = useForm();
	const findOrderById = useFindOrderById();
	const findLogisticsManufacturerById = useFindLogisticsManufacturerById();
	const findEmployeeById = useFindEmployeeById();
	const findCustomerById = useFindCustomerById();
	const findQuotationTypeById = useFindEnumById("quotation_types");

	async function chooseOrder(rows: any[]) {
		if (rows.length !== 0) {
			const order_id = rows[0].id;
			const order = (await findOrderById(order_id)).orders_by_pk;

			setValues(
				produce(values, (one: One) => {
					one.type = exportGoods.Type.ImportFromOrder;
					one.order_id = order_id;
					one.type_id = order.type_id;
					one.type_name = order.quotation_type.name;
					one.customer_id = order.customer_id;
					one.customer_name = order.customer.name;
					one.sales_id = order.sales_id;
					one.sales_name = order.sales.name;
					one.sales_tel = order.sales.tel;
					one.address = order.deliver_address;
					one.order_description = order.description;
					one.order_note = order.order_note;
					one.export_goods_note = "";
					one.driver_logistics_manufacturer_id = null;
					one.driver_name = "";
					one.driver_tel = "";
					one.items = order.items.map(({ id, item_spec, item_custom, count }, priority) => {
						const item = (item_spec || item_custom)!;

						return {
							id,
							export_good_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: id }),
							manufacturer_type: item.manufacturer.type,
							manufacturer_id: item.manufacturer.id,
							manufacturer: item.manufacturer.name,
							name: item.name,
							material: item.material,
							color: item.color,
							size: item.size,
							count,
							image: item.image,
							image_cut: item.image_cut,
							priority,
							stocks: [createStock(count)],
						} as Item;
					});
				}),
			);
		}
	}

	function updateDriverLogisticsManufacturerId(id: One["driver_logistics_manufacturer_id"]) {
		if (id !== null) {
			setValues(
				produce(values, (one) => {
					one.driver_logistics_manufacturer_id = id;
					one.driver_id = null;
					one.driver_name = "";
					one.driver_tel = "";
				}),
			);
		}
	}

	async function updateTypeId(id: string) {
		const record = await findQuotationTypeById(id);
		if (!record) return;

		setValues({
			...values,
			type_id: id,
			type_name: record.name ?? "",
		});
	}

	async function updateCustomerId(id: string) {
		const record = await findCustomerById(id);
		if (!record) return;

		setValues(
			produce(values, (one) => {
				one.customer_id = id;
				one.customer_name = record.name;
			}),
		);
	}

	async function updateSalesId(id: string | null) {
		if (id === null) {
			setValues({
				...values,
				sales_id: null,
				sales_name: "",
				sales_tel: "",
			});
		} else {
			const record = await findEmployeeById(id);

			setValues({
				...values,
				sales_id: id,
				sales_name: record.name,
				sales_tel: record.tel,
			});
		}
	}

	function chooseItems(rows: ItemRow[]) {
		setValues(
			produce(values, (one) => {
				one.items = attachItems(
					one.items,
					rows.map((item, priority) => {
						return {
							...item,
							export_good_item_id: getModelItemId({ create_date: initialValues.create_date, item_id: item.id }),
							count: 1,
							priority,
							stocks: [createStock(1)],
							original_code: {
								original_code: item.original_code,
							},
						} as Item;
					}),
				);
			}),
		);
	}

	async function updateWarehouseId(itemId: string, stockId: string, warehouseId: string) {
		setValues(
			produce(values, (one) => {
				const itemIndex = one.items.findIndex((e) => e.id === itemId);
				if (itemIndex !== -1) {
					const stockIndex = one.items[itemIndex].stocks.findIndex((e) => e.export_good_item_stock_id === stockId);
					if (stockIndex !== -1) {
						one.items[itemIndex].stocks[stockIndex].warehouse_id = warehouseId;
					}
				}
			}),
		);
	}

	function updateItemStock(
		id: Item["id"],
		stockId: Stock["export_good_item_stock_id"],
		field: keyof Stock,
		value: any,
	) {
		setValues(
			produce(values, (one) => {
				const itemIndex = one.items.findIndex((e) => e.id === id);
				if (itemIndex !== -1) {
					const stockIndex = one.items[itemIndex].stocks.findIndex((e) => e.export_good_item_stock_id === stockId);
					if (stockIndex !== -1) {
						const stock: any = one.items[itemIndex].stocks[stockIndex];
						stock[field] = value;
					}
				}
			}),
		);
	}

	function addItemStock(id: Item["id"], stockId: Stock["export_good_item_stock_id"]) {
		setValues(
			produce(values, (one) => {
				const itemIndex = one.items.findIndex((e) => e.id === id);
				if (itemIndex !== -1) {
					const stockIndex = one.items[itemIndex].stocks.findIndex((e) => e.export_good_item_stock_id === stockId);
					if (stockIndex !== -1) {
						one.items[itemIndex].stocks.push(createStock(1));
					}
				}
			}),
		);
	}

	function delItemStock(id: Item["id"], stockId: Stock["export_good_item_stock_id"]) {
		setValues(
			produce(values, (one) => {
				const itemIndex = one.items.findIndex((e) => e.id === id);
				if (itemIndex !== -1) {
					const stockIndex = one.items[itemIndex].stocks.findIndex((e) => e.export_good_item_stock_id === stockId);
					if (stockIndex !== -1) {
						one.items[itemIndex].stocks.splice(stockIndex, 1);
					}
				}
			}),
		);
	}

	function deleteItem(id: string) {
		setValues(
			produce(values, (one) => {
				const index = one.items.findIndex((e) => e.id === id);
				if (index !== -1) {
					one.items.splice(index, 1);
				}
			}),
		);
	}

	async function updateDriverId(id: string) {
		const { drivers } = (await findLogisticsManufacturerById(values.driver_logistics_manufacturer_id!))
			.manufacturer_logistics_by_pk;

		setValues(
			produce(values, (one) => {
				const driver = drivers.find((e) => e.manufacturer_logistic_driver_id === id);
				if (driver) {
					one.driver_id = id;
					one.driver_name = driver.name;
					one.driver_tel = driver.tel;
				}
			}),
		);
	}

	return {
		chooseOrder,
		updateDriverLogisticsManufacturerId,
		updateTypeId,
		updateCustomerId,
		updateSalesId,
		chooseItems,
		updateWarehouseId,
		updateItemStock,
		addItemStock,
		delItemStock,
		deleteItem,
		updateDriverId,
	};
}
