import { gql } from "@apollo/client";
import { useBranch } from "../../moo-branch-provider";
import { Status } from "../../moo-enums";
import { useGraphQLRequest } from "../../moo-graphql-provider";

type ReturnedData = {
	account_types: {
		id_number: string;
		name: string;
	}[];
};

const query = gql`
	query ($where: account_types_bool_exp, $orderBy: [account_types_order_by!]) {
		account_types(where: $where, order_by: $orderBy) {
			id_number
			name
		}
	}
`;

export function useGetAccountTypeNames() {
	const graphQLRequest = useGraphQLRequest();
	const { value: branchId } = useBranch();

	return async ({ id_numbers }: { id_numbers: string[] }) => {
		const data = (await graphQLRequest({
			query,
			variables: {
				where: {
					id_number: {
						_in: id_numbers,
					},
					branch_id: {
						_eq: branchId,
					},
					status: {
						_eq: Status.Present,
					},
				},
				orderBy: {
					id_number: "asc",
				},
			},
		})) as ReturnedData;
		return data;
	};
}
