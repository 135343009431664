import { MooUserSalaryList, Query, useCreateStore } from "../components/moo-components/moo-user-salary-list";
import { useHistoryQuery } from "../hooks/useHistoryQuery";
import usePage from "../hooks/usePage";
import useURLSearchParams from "../hooks/useURLSearchParams";
import utils from "../utils";
import Utils from "../utils";

export const path = "/salary";

const useUrlQuery = () => {
	const urlSearchParams = useURLSearchParams();
	const page = usePage();

	return {
		search: urlSearchParams.get("search") || null,
		page,
		year: urlSearchParams.has("year") ? parseInt(urlSearchParams.get("year")!) : Utils.getTodayFullYear(),
		month: urlSearchParams.has("month") ? parseInt(urlSearchParams.get("month")!) : Utils.getTodayHumanMonth(),
	};
};

const SalaryListRoute = () => {
	const urlQuery = useUrlQuery();
	const store = useCreateStore(urlQuery, {
		search: null,
		page: 0,
		year: Utils.getTodayFullYear(),
		month: Utils.getTodayHumanMonth(),
	});
	useHistoryQuery<Query>({ path, store });

	return (
		<MooUserSalaryList
			store={store}
			onOk={() => {
				utils.refreshPage();
			}}
		/>
	);
};

export default SalaryListRoute;
