import { ItemType, manufacturer } from "../../moo-enums";
import { Item as OneItem, One } from "../../moo-order-one";
import { Calculator } from "../../moo-quotation-one";
import { getItemSpec } from "../../moo-shared";
import { GraphQLFindOneResult } from "./GraphQLFindOneResult";
import { Item } from "./Item";

export function mapperItems(orderItems: GraphQLFindOneResult["orders_by_pk"]["items"]): One["items"] {
	const oneItems: OneItem[] = [];

	orderItems.forEach((orderItem, priority) => {
		const id = orderItem.id;
		const type = id.charAt(0) === "S" ? ItemType.Spec : ItemType.Custom;
		const item: Item = (orderItem.item_spec || orderItem.item_custom)!;
		const price_final = orderItem.price_final;
		const count = orderItem.count;

		const oneItem: OneItem = {
			order_item_id: orderItem.order_item_id,
			id: orderItem.id,
			type,
			category: item.category.name,
			manufacturer_type: item.manufacturer.type as manufacturer.Value.Value,
			manufacturer_id: item.manufacturer.id,
			manufacturer: item.manufacturer.name,
			name: item.name,
			image: item.image,
			image_cut: item.image_cut,
			material: item.material,
			color: item.color,
			size: item.size,
			purchase_price: item.purchase_price,
			price: item.price,
			cost: item.cost,
			spec: `${getItemSpec(item)}`,
			count,
			price_final,
			price_total: Calculator.getItemPriceTotal({ price_final, count }),
			note: orderItem.note,
			priority,
		};
		oneItems.push(oneItem);
	});

	return oneItems;
}
