import { MooGqlTable } from "../../moo-gql";
import { Row as FindOneRecord } from "../findOne/Row";
import { queryField } from "../findOne/query";

export class CustomerTable extends MooGqlTable<FindOneRecord> {
	getQueryFields(): string {
		return queryField;
	}

	getTableName(): string {
		return "customers";
	}

	isColumn(column: string): boolean {
		switch (column) {
			case "type_id":
			case "name":
			case "gender":
			case "birthday":
			case "email":
			case "line":
			case "job":
			case "vip":
			case "address":
			case "bank_account":
			case "bank":
			case "bank_account_number":
			case "tax_id":
			case "note":
				return true;
		}
		return false;
	}
}
