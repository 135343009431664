import { MooGqlTable } from "./moo-gql-table";

export type One = {
	update_date: string;
};

type Props<One> = {
	id: string;
	initialValues: One;
	toUpdateOne: One;
};

export class MooGqlBuilder {
	private readonly id: string = "";
	private readonly initialValues: One = null as unknown as One;
	private readonly toUpdateOne: One = null as unknown as One;
	private readonly tables: MooGqlTable<any>[] = [];

	constructor({ id, initialValues, toUpdateOne }: Props<One>) {
		this.id = id;
		this.initialValues = initialValues;
		this.toUpdateOne = toUpdateOne;
	}

	public addTable(table: typeof MooGqlTable): void {
		// @ts-ignore
		this.tables.push(new table({ id: this.id, initialValues: this.initialValues, toUpdateOne: this.toUpdateOne }));
	}

	private getMutationVariables(): string {
		return this.tables.map((e) => e.getMutationVariables()).join(", ");
	}

	private getQuery(): string {
		const query = this.tables.map((e) => e.getQuery()).join("\n");
		return `
        mutation (
        	${this.getMutationVariables()}
				) {
            ${query}
        }
		`;
	}

	private getVariables(): Record<string, any> {
		return this.tables.map((e) => e.getVariables()).reduce((prev, cur) => ({ ...prev, ...cur }), {});
	}

	public build(): { query: string; variables: Record<string, any> } {
		return {
			query: this.getQuery(),
			variables: this.getVariables(),
		};
	}
}
