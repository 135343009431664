import { manufacturer } from "../../moo-enums";
import { Calculator, One } from "../../moo-import-goods-one";
import { getLocaleString } from "../../moo-shared";
import { Row } from "./Row";
import { mapperItems } from "./mapperItems";

export function mapperInitialValues(record: Row): One {
	const items = mapperItems(record.items);
	const accountant_total_price = Calculator.getAccountantTotalPrice(items);
	const accountant_payable = Calculator.getAccountantPayable({
		accountant_total_price,
		accountant_tax: record.accountant_tax,
		accountant_freight: record.accountant_freight,
		accountant_miscellaneous_charge: record.accountant_miscellaneous_charge,
		accountant_received: record.accountant_received,
	});

	return {
		create_date: getLocaleString(new Date(record.create_date)),
		update_date: record.update_date,
		status: record.status,
		state: record.state,
		state_accountant: record.state_accountant,
		type: record.type,
		import_date: getLocaleString(new Date(record.import_date)),
		order_ids: record.order_ids,
		link_id: record.link_id,
		sales_id: record.sales.id,
		sales_name: record.sales.name,
		sales_tel: record.sales.tel,
		manufacturer_type: record.manufacturer?.type || manufacturer.Value.Value.Domestic,
		manufacturer_id: record.manufacturer?.id || null,
		manufacturer: record.manufacturer?.name || "",
		warehouse_id: record.warehouse_id,
		note: record.note,
		items,
		accountant_tax: record.accountant_tax,
		accountant_freight: record.accountant_freight,
		accountant_miscellaneous_charge: record.accountant_miscellaneous_charge,
		accountant_total_price,
		accountant_received: record.accountant_received,
		accountant_payable,
		accountant_note: record.accountant_note,
		voucher_rows: record.voucher_rows,
	};
}
